import { useEffect, useState } from 'react'
import { makeListarProdutos } from '../../../modules/produtos/factories'
import { Produto } from '../../../modules/produtos/models'
import { GetParams, HttpMetadata, HttpRequestError } from '../../../modules/_interfaces'
import { useHandleErrors } from '../app/useHandleErrors'

const listarProdutos = makeListarProdutos()

export type ExecProdutosModoLista = 'tabela' | 'card'

export function useProdutos (produtosParams: GetParams) {
  const [produtosList, setProdutosList] = useState<Produto[]>([])
  const [produtosListMetadata, setProdutosListMetadata] = useState<HttpMetadata>()
  const [produtosListError, setProdutosListError] = useState<HttpRequestError>()
  const [produtosListHasMore, setProdutosListHasMore] = useState(true)
  const [produtosListLoading, setProdutosListLoading] = useState(false)
  const { handleError } = useHandleErrors()

  useEffect(() => {
    setProdutosList([])
  }, [produtosParams.filter, produtosParams.filterObject, produtosParams.token])

  useEffect(() => {
    async function fetch () {
      setProdutosListLoading(true)
      let hasMore = false

      try {
        const response = await listarProdutos.execute(produtosParams)

        if (response.error) {
          setProdutosListError(response.error)
        }

        if (response.metadata?.skip && response.data?.length) {
          setProdutosList(old => [...old, ...response.data])
        } else {
          setProdutosList(response.data)
        }

        if (response.metadata?.limit !== undefined && response.metadata.count !== undefined && response.metadata.skip !== undefined) {
          if ((response.metadata.limit + response.metadata.skip) < response.metadata.count) {
            hasMore = true
          }
        }

        setProdutosListHasMore(hasMore)
        setProdutosListMetadata(response.metadata)
        setProdutosListLoading(false)
      } catch (error) {
        setProdutosListError(error)
        setProdutosListHasMore(false)
        setProdutosListLoading(false)
      }
    }
    if (produtosParams.filter !== '') {
      fetch()
    }
  }, [produtosParams])

  useEffect(() => {
    if (produtosListError) {
      handleError(produtosListError)
    }
  }, [handleError, produtosListError])

  return { produtosList, produtosListMetadata, produtosListHasMore, produtosListLoading }
}
