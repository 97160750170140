import { formatStringDate, formatStringHora, generateSearchQuery } from '../../../helpers'
import { replaceLineBreakFromApi, replaceRfromApi } from '../../../helpers/helperLineBreak'
import { GetParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { ListarPedidosService } from '../contracts'
import { GetPedido, GetPedidoListResponse } from '../models'
// import { transformaStatusPedido } from './helpers'
import { ListarPedidosRespostaApi } from './_interfaces'

export class ListarPedidosServiceRest implements ListarPedidosService {
  private readonly http: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.http = httpRequest
  }

  async listarPedidos (params: GetParams): Promise<GetPedidoListResponse> {
    let { filter, filterOptions, filterObject, token } = params
    const query = generateSearchQuery(filter, filterOptions, filterObject)

    const response = await this.http.get<ListarPedidosRespostaApi>({
      path: 'pedidos',
      token,
      query
    })

    const listaPedidos = response?.res?.data?.map(pedidoApi => {
      const historico = pedidoApi.historico.map(item => {
        return {
          ...item,
          data: formatStringDate(item.data, 'xxxxxxxx', 'completo'),
          descricao: replaceLineBreakFromApi(item.descricao)
        }
      })

      const pedido: GetPedido = {
        ...pedidoApi,
        cliente: {
          id: pedidoApi.cliente.id,
          nome_fantasia: pedidoApi.cliente.nome,
          razao_social: pedidoApi.cliente.razao_social,
          limite: pedidoApi.cliente.limite
        },
        contato: {
          id: pedidoApi.contato.id,
          nome: pedidoApi.contato.nome
        },
        deposito: {
          id: pedidoApi.deposito.id,
          nome: pedidoApi.deposito.nome,
          uf: pedidoApi.deposito.uf
        },
        funcionario: {
          id: pedidoApi.funcionario.id,
          nome: pedidoApi.funcionario.nome,
          empresa_id: pedidoApi.empresa.id
        },
        transportadora: {
          id: pedidoApi.transportadora.id,
          nome_fantasia: pedidoApi.transportadora.nome
        },
        funcionario2: {
          id: pedidoApi.funcionario2?.id as number,
          nome: pedidoApi.funcionario2?.nome as string
        },
        id: pedidoApi.id,
        empresa: {
          id: pedidoApi.empresa.id,
          nome: pedidoApi.empresa.nome,
          uf: pedidoApi.empresa.uf
        },
        subtotal: pedidoApi.subtotal,
        st: pedidoApi.st,
        difal: pedidoApi.difal,
        frete: pedidoApi.frete,
        frete_st: pedidoApi.frete_st,
        acrescimos: pedidoApi.acrescimo,
        descontos: pedidoApi.desconto,
        juros: pedidoApi.juros,
        prazo_dias: pedidoApi.prazo_dias,
        liberado: pedidoApi.liberado,
        status: pedidoApi.status,
        tipo: pedidoApi.tipo === 'orcamento' ? 'orçamento' : pedidoApi.tipo,
        data: pedidoApi.data && formatStringDate(pedidoApi.data, 'xxxxxxxx', 'completo'),
        hora: pedidoApi.hora && formatStringHora(pedidoApi.hora),
        historico: historico,
        pagamento_tipo: pedidoApi.pagamento_tipo === 'cobranca' ? 'cobrança' : pedidoApi.pagamento_tipo,
        pagamento_bloqueado: pedidoApi.pagamento_bloqueado,
        liberacao_bloqueada: pedidoApi.liberacao_bloqueada,
        pedido_desbloqueado: pedidoApi.pedido_desbloqueado,
        estoque_zerado: pedidoApi.estoque_zerado,
        solicitacao_vendedor_status: pedidoApi.solicitacao_vendedor_status,
        solicitacao_vendedor_observacao: replaceRfromApi(pedidoApi.solicitacao_vendedor_observacao),
        documento_fiscal: pedidoApi.documento_fiscal > 0 ? pedidoApi.documento_fiscal : undefined,
        linha_digitavel: pedidoApi.linha_digitavel,
        link_pagamento: pedidoApi.link_pagamento,
        link_pagamento_pix: pedidoApi.link_pagamento_pix,
        analise_credito: pedidoApi.analise_credito,
        crs_aberto: pedidoApi.crs_aberto,
        pedidos_credito_liberado: pedidoApi.pedidos_credito_liberado,
        cheques: pedidoApi.cheques,
        solar: pedidoApi.solar
      }
      return pedido
    })

    const result: GetPedidoListResponse = {
      data: listaPedidos,
      metadata: response.res?.metadata,
      error: response.error
    }

    return result
  }
}
