import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { SchemaValidator } from '../../../infra/validator/schemaValidator'
import { updateSchema } from '../validators'
import { AtualizarClienteServiceRest } from '../services'
import { AtualizarCliente } from '../useCases'

export function makeAtualizarCliente (): AtualizarCliente {
  const httpRequest = new HttpRequestImpl()
  const validator = new SchemaValidator(updateSchema)
  const atualizarClienteService = new AtualizarClienteServiceRest(httpRequest)

  return new AtualizarCliente(atualizarClienteService, validator)
}
