import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { SchemaValidator } from '../../../infra/validator/schemaValidator'
import { CadastrarClienteServiceRest } from '../services'
import { CadastrarCliente } from '../useCases'
import { schema } from '../validators'

export function makeCadastrarCliente (): CadastrarCliente {
  const httpRequest = new HttpRequestImpl()
  const validator = new SchemaValidator(schema)
  const cadastrarClienteService = new CadastrarClienteServiceRest(httpRequest)

  return new CadastrarCliente(cadastrarClienteService, validator)
}
