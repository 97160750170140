import { GetPedido } from '../modules/pedidos/models'

interface ReturnProps {
  bloquear_alteracoes: {
    status: boolean,
    message: string
  }
  bloquear_alteracao_frete: {
    status: boolean,
    message: string
  }
  bloquear_pagamento: {
    status: boolean,
    message: string
  }
}

export const handleGetPedidoBloqueios = (pedido: GetPedido): ReturnProps => {
  let bloquear_alteracoes = {
    status: false,
    message: ''
  }
  if (pedido.status === 'faturado' ||
    pedido.estoque_zerado === 'z' ||
    pedido.pedido_desbloqueado === 's' ||
    pedido.limite_bloqueado === 'n' ||
    pedido.bloqueio_cliente_desativado === 's' ||
    pedido.liberacao_bloqueada === 's' ||
    pedido.liberado === 's' ||
    (pedido.valor_adiantamento && pedido.valor_adiantamento > 0)
  ) {
    let message = ''
    if (pedido.status === 'faturado') {
      message = message === '' ? 'Pedido já Faturado' : '<br>Pedido já Faturado'
    }
    if (pedido.estoque_zerado === 'z') {
      message = message === '' ? 'Estoque zerado liberado pelo gerente' : message + '<br>Estoque zerado liberado pelo gerente'
    }
    if (pedido.pedido_desbloqueado === 's') {
      message = message === '' ? 'Pedido Desbloqueado' : message + '<br>Pedido Desbloqueado'
    }
    if (pedido.limite_bloqueado === 'n') {
      message = message === '' ? 'Limite Bloqueado' : message + '<br>Limite Bloqueado'
    }
    if (pedido.bloqueio_cliente_desativado === 's') {
      message = message === '' ? 'Bloqueio do Cliente foi Desativado' : message + '<br>Bloqueio do Cliente foi Desativado'
    }
    if (pedido.liberacao_bloqueada === 's') {
      message = message === '' ? 'Estoque esta Liberando o pedido' : message + '<br>Estoque esta Liberando o pedido'
    }
    if (pedido.liberado === 's') {
      message = message === '' ? 'Pedido já foi liberado para o estoque' : message + '<br>Pedido já foi liberado para o estoque'
    }
    if (pedido.valor_adiantamento && pedido.valor_adiantamento > 0) {
      message = message === '' ? 'Pedido possui adiantamento gerado' : message + '<br>Pedido possui adiantamento gerado'
    }

    bloquear_alteracoes = {
      status: true,
      message
    }
  }

  let bloquear_alteracao_frete = {
    status: false,
    message: ''
  }
  if (pedido.status === 'faturado' ||
    pedido.pedido_desbloqueado === 's' ||
    pedido.limite_bloqueado === 'n' ||
    pedido.bloqueio_cliente_desativado === 's' ||
    pedido.liberacao_bloqueada === 's' ||
    pedido.liberado === 's' ||
    (pedido.valor_adiantamento && pedido.valor_adiantamento > 0)
  ) {
    let message = ''
    if (pedido.status === 'faturado') {
      message = message === '' ? 'Pedido já Faturado' : '<br>Pedido já Faturado'
    }
    if (pedido.pedido_desbloqueado === 's') {
      message = message === '' ? 'Pedido Desbloqueado' : message + '<br>Pedido Desbloqueado'
    }
    if (pedido.limite_bloqueado === 'n') {
      message = message === '' ? 'Limite Bloqueado' : message + '<br>Limite Bloqueado'
    }
    if (pedido.bloqueio_cliente_desativado === 's') {
      message = message === '' ? 'Bloqueio do Cliente foi Desativado' : message + '<br>Bloqueio do Cliente foi Desativado'
    }
    if (pedido.liberacao_bloqueada === 's') {
      message = message === '' ? 'Estoque esta Liberando o pedido' : message + '<br>Estoque esta Liberando o pedido'
    }
    if (pedido.liberado === 's') {
      message = message === '' ? 'Pedido já foi liberado para o estoque' : message + '<br>Pedido já foi liberado para o estoque'
    }
    if (pedido.valor_adiantamento && pedido.valor_adiantamento > 0) {
      message = message === '' ? 'Pedido possui adiantamento gerado' : message + '<br>Pedido possui adiantamento gerado'
    }
    bloquear_alteracao_frete = {
      status: true,
      message
    }
  }

  let bloquear_pagamento = {
    status: false,
    message: ''
  }
  if (pedido.pagamento_gerente === 's') {
    bloquear_pagamento = {
      status: true,
      message: 'Condição de pagamento bloqueada pelo gerente.'
    }
  }
  return { bloquear_alteracoes, bloquear_alteracao_frete, bloquear_pagamento }
}
