import { Validator } from '../../_interfaces'
import { FinalizarChamadoService } from '../contracts'
import { Chamado } from '../models'
import { EncerrarChamadoParams } from '../services'

export class FinalizarChamado {
  private readonly finalizarChamadoService: FinalizarChamadoService
  private readonly validator: Validator

  constructor (finalizarChamadoService: FinalizarChamadoService, validator: Validator) {
    this.finalizarChamadoService = finalizarChamadoService
    this.validator = validator
  }

  async execute (postParams: EncerrarChamadoParams): Promise<Chamado> {
    // await this.validator.validate(postParams.body)
    const response = await this.finalizarChamadoService.finalizarChamado(postParams)
    return response
  }
}
