import { GetParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { TrazerEmpresaPorIdService } from '../contracts'
import { GetOneEmpresaResponse } from '../models'
import { TrazerEmpresaPorIdApiResp } from './_interfaces'

export class TrazerEmpresaPorIdServiceRest implements TrazerEmpresaPorIdService {
  private readonly http: HttpRequest

  constructor (http: HttpRequest) {
    this.http = http
  }

  async trazerEmpresaPorId (params: GetParams): Promise<GetOneEmpresaResponse> {
    if (!params.filter) {
      return {} as GetOneEmpresaResponse
    }

    let { filter: id, token } = params

    const response = await this.http.get<TrazerEmpresaPorIdApiResp>({
      path: `empresas/${id}`,
      token
    })

    return response?.res as GetOneEmpresaResponse
  }
}
