import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { ListarClientesServiceRest } from '../services'
import { ListarClientes } from '../useCases'

export function makeListarClientes (): ListarClientes {
  const httpRequest = new HttpRequestImpl()
  const listarClienteService = new ListarClientesServiceRest(httpRequest)

  return new ListarClientes(listarClienteService)
}
