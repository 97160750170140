import capitalize from 'capitalize-pt-br'
import { format } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FaCheck, FaPlus } from 'react-icons/fa'
import Swal from 'sweetalert2'
import { makeCadastrarChamado, makeFinalizarChamado } from '../../../modules/chamados/factories'
import { makeAtualizarChamado } from '../../../modules/chamados/factories/atualizar-chamados'
import { AtendimentoChamado, Chamado, ChamadoStatus, ChamadoTipo } from '../../../modules/chamados/models'
import { Cliente } from '../../../modules/clientes/models'
import { Produto } from '../../../modules/produtos/models'
import { PostParams } from '../../../modules/_interfaces'
import { Button, ButtonTable, Input, ListaClientes, ListaProdutosDetalhes, Loading, Modal, Select, Table, TableColumnProps, Textarea } from '../../components'
import { useChamado, useChamados, useCliente, useExecUseCase, useProduto, useUsuario } from '../../hooks'
import { MainLayout } from '../../layouts/MainLayout'
import { FormRow, TableActions, TableText } from '../../styles/global'
import { Container, ContainerModal, ContainerColumn, HeaderDiv, SearchDiv, ContainerAtendimentos, CardAtendimento, CardHeader, CardObservacao, WrapperAtendimento } from './styles'

export const Chamados = () => {
  const perPage = 20
  const [currentPage, setCurrentPage] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [showNotaModal, setShowNotaModal] = useState(false)
  const { data: usuarioData } = useUsuario()
  const chamadoInitialState = {
    data: '',
    status: 'aberto' as ChamadoStatus,
    funcionario_id: parseInt(usuarioData?.funcionario_id as string),
    funcionario_nome: usuarioData?.nome as string,
    produto_id: 0,
    produto_nome: '',
    cliente_id: 0,
    cliente_nome: '',
    tipo: 'duvida tecnica' as ChamadoTipo,
    projeto: '',
    assunto: '',
    contato: '',
    telefone: '',
    nota: 10,
    observacao: '',
    atendimentos: [] as AtendimentoChamado[]
  }
  const [chamadoModal, setChamadoModal] = useState<Chamado>(chamadoInitialState)
  const [notaChamado, setNotaChamado] = useState(10)
  const [obsChamado, setObsChamado] = useState('')
  const [filterStatus, setFilterStatus] = useState<string>('em andamento')
  const { fetcher: getListaChamados, data: dataChamados, loading } = useChamados()
  const { fetcher: getChamado, data: dataChamado, loading: loadingGetChaamdo } = useChamado()

  const { fetcher: getClientePorId, data: clienteData, loading: loadingCliente } = useCliente()
  const [produtoId, setProdutoId] = useState(0)
  const { data: produtoData, loading: loadingProduto } = useProduto(produtoId)

  const { executeUseCase, executing, success, error: useCaseError } = useExecUseCase()

  const optionTipo = ['instalacao', 'dimensionamento']
  const optionNota = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  useEffect(() => {
    getListaChamados({
      perPage,
      currentPage,
      status: filterStatus
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (clienteData && clienteData && clienteData.id && clienteData.id > 0) {
      setChamadoModal(old => {
        return {
          ...old,
          cliente_nome: clienteData?.razao_social
        }
      })
    }
  }, [clienteData])

  useEffect(() => {
    if (produtoData && produtoData.data && produtoData.data.id && produtoData.data.id > 0) {
      setChamadoModal(old => {
        return {
          ...old,
          produto_nome: produtoData?.data.nome_tecnico
        }
      })
    }
  }, [produtoData])

  useEffect(() => {
    if (dataChamado && dataChamado.id) {
      setChamadoModal(dataChamado)
      setShowModal(true)
    }
  }, [dataChamado])

  const finalizarChamado = (chamado: Chamado) => {
    if (chamado.status === 'aguard. avaliacao' || chamado.status === 'aguard. avaliao') {
      setChamadoModal(chamado)
      setShowNotaModal(true)
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Chamado não pode ser Finalizado!',
        showConfirmButton: true,
        confirmButtonText: 'Ok'
      })
    }
  }

  const handleSaveNotaModal = () => {
    const newChamado = {
      nota: notaChamado,
      observacao: obsChamado
    } as Chamado

    const params = {
      body: newChamado,
      token: usuarioData ? usuarioData.token : '',
      chamado_id: chamadoModal.id
    }

    const useCase = makeFinalizarChamado()
    executeUseCase<PostParams>(
      useCase,
      params,
      'Chamado atualizado com sucesso!',
      'Chamado será atualizado, deseja prosseguir?',
      reloadList,
      true
    )
    setNotaChamado(0)
    setObsChamado('')
  }

  useEffect(() => {
    if (success) {
      setShowModal(false)
    }
  }, [success])

  const columns: TableColumnProps[] = useMemo(() => [
    {
      Header: '#',
      accessor: 'id',
      minWidth: 15,
      Cell: ({ value }) => {
        return <TableText position='center'>{capitalize(value)}</TableText>
      }
    },
    {
      Header: 'Cliente',
      accessor: 'cliente_id',
      minWidth: 20,
      Cell: ({ value }) => {
        return <TableText position='center'>{value}</TableText>
      }
    },
    {
      Header: 'Produto',
      accessor: 'produto_id',
      minWidth: 20,
      Cell: ({ value }) => {
        return <TableText position='center'>{value}</TableText>
      }
    },
    {
      Header: 'Tipo',
      accessor: 'tipo',
      minWidth: 25,
      Cell: ({ value }) => {
        return <TableText position='center'>{value}</TableText>
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      minWidth: 15,
      Cell: ({ value }) => {
        return <TableText position='center'>{value}</TableText>
      }
    },
    {
      Header: 'Data',
      accessor: 'data',
      minWidth: 15,
      Cell: ({ value }) => {
        return <TableText position='center'>{value}</TableText>
      }
    },
    {
      Header: 'Ações',
      minWidth: 20,
      Cell: ({ row, index }) => {
        const chamado = row._original as Chamado
        return (
          <TableActions>
            <ButtonTable
              data-cy={'atenderCliente-' + index}
              typeButton='primary'
              title="Atender chamado"
              onClick={() => finalizarChamado(chamado)}
            >
              <FaCheck size='15px' />
            </ButtonTable>
          </TableActions>
        )
      }
    }
  ], [])

  const pagesNumber = () => {
    let pages = 1
    if (dataChamados && dataChamados.length > 0) {
      pages = Math.ceil(dataChamados.length / perPage)
    }
    return pages
  }

  const handleNovoChamado = () => {
    setChamadoModal(chamadoInitialState)
    setShowModal(true)
  }

  const handleOnClickItem = (chamado: Chamado) => {
    if (chamado) {
      getChamado({ chamado_id: chamado.id as number })
    }
  }

  const handleOnCloseModal = () => {
    setChamadoModal(chamadoInitialState)
    setShowModal(false)
    setShowNotaModal(false)
  }

  const reloadList = () => {
    getListaChamados({
      perPage,
      currentPage,
      status: 'em andamento'
    })
  }

  const handleSaveModal = () => {
    if (chamadoModal) {
      if (chamadoModal.id && chamadoModal.id > 0) {
        const newChamado: Chamado = {
          id: chamadoModal?.id,
          cliente_id: chamadoModal?.cliente_id,
          produto_id: chamadoModal?.produto_id,
          funcionario_id: chamadoModal?.funcionario_id,
          contato: chamadoModal?.contato,
          telefone: chamadoModal?.telefone,
          tipo: chamadoModal?.tipo,
          projeto: chamadoModal?.projeto,
          assunto: chamadoModal?.assunto
        }

        const params = {
          body: newChamado,
          token: usuarioData ? usuarioData.token : '',
          chamado_id: chamadoModal?.id
        }
        const useCase = makeAtualizarChamado()
        executeUseCase<PostParams>(
          useCase,
          params,
          'Chamado atualizado com sucesso!',
          'Chamado será atualizado, deseja prosseguir?',
          reloadList,
          true
        )
      } else {
        const newChamado: Chamado = {
          status: chamadoModal?.status,
          cliente_id: chamadoModal?.cliente_id,
          produto_id: chamadoModal?.produto_id,
          funcionario_id: chamadoModal?.funcionario_id,
          contato: chamadoModal?.contato,
          telefone: chamadoModal?.telefone,
          tipo: chamadoModal?.tipo,
          projeto: chamadoModal?.projeto,
          assunto: chamadoModal?.assunto
        }

        const params = {
          body: newChamado,
          token: usuarioData ? usuarioData.token : ''
        }
        const useCase = makeCadastrarChamado()
        executeUseCase<PostParams>(
          useCase,
          params,
          'Chamado cadastrado com sucesso!',
          'Chamado será cadastrado, deseja prosseguir?',
          reloadList,
          true
        )
      }
    }
  }

  const searchProduto = () => {
    if (chamadoModal && chamadoModal?.produto_id) {
      setProdutoId(chamadoModal?.produto_id as number)
    }
  }

  const handleEnterProduto = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchProduto()
    }
  }

  const handleProdutoSelecionado = (produto: Produto) => {
    setChamadoModal(old => {
      return {
        ...old,
        produto_id: produto.id,
        produto_nome: produto.nome_tecnico
      }
    })
    setProdutoId(produto.id as number)
  }

  const searchCliente = () => {
    if (chamadoModal && chamadoModal?.cliente_id) {
      const clienteParam = chamadoModal?.cliente_id
      getClientePorId({ clienteId: clienteParam })
    }
  }

  const handleEnterCliente = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchCliente()
    }
  }

  const handleClienteCallBack = (cliente: Cliente) => {
    setChamadoModal(old => {
      return {
        ...old,
        cliente_id: parseInt(cliente.id as string),
        cliente_nome: cliente.razao_social
      }
    })
    const clienteParam = parseInt(cliente.id as string)
    getClientePorId({ clienteId: clienteParam })
  }

  const handleInputOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value

    const key = e.target.name as keyof Chamado

    if (key === 'cliente_id' || key === 'produto_id') {
      value = value.replace(/[^0-9]/g, '')
    }

    const newChamadoState = {
      ...chamadoModal,
      [key]: value
    }

    setChamadoModal(newChamadoState as Chamado)
  }, [chamadoModal, setChamadoModal])

  const handleTextareaOnChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let value = e.target.value

    const key = e.target.name as keyof Chamado

    const newChamadoState = {
      ...chamadoModal,
      [key]: value
    }

    setChamadoModal(newChamadoState as Chamado)
  }, [chamadoModal, setChamadoModal])

  const handleSelectOnChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    let value = e.target.value

    const key = e.target.name as keyof Chamado

    const newChamadoState = {
      ...chamadoModal,
      [key]: value
    }

    setChamadoModal(newChamadoState as Chamado)
  }, [chamadoModal, setChamadoModal])

  const disableInputs = () => {
    if (chamadoModal && chamadoModal.status !== 'aberto') {
      return true
    } else {
      return false
    }
  }

  const isLoadingSometing = () => {
    if (executing || loading || loadingCliente || loadingProduto) {
      return true
    } else {
      return false
    }
  }

  const handleStatusOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      const valor = e.target.value.toString()
      setFilterStatus(valor)
    }
  }

  const handleChangeNota = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      const nota = parseInt(e.target.value)
      setNotaChamado(nota)
    }
  }

  const handleChangeObs = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value) {
      const text = e.target.value
      setObsChamado(text)
    }
  }

  const handleClickSearch = () => {
    getListaChamados({
      perPage,
      currentPage,
      status: filterStatus
    })
  }

  return (
    <MainLayout title="Chamados">
      {isLoadingSometing() && <Loading />}
      <h1>Chamados</h1>
      <Container>
        <HeaderDiv>
          <SearchDiv>
            <Select
              title='Status'
              onChange={handleStatusOnChange}
              value={filterStatus}
            >
              <option value='todos' selected>Todos</option>
              <option value='em andamento'>Em Andamento</option>
              <option value='aberto'>Aberto</option>
              <option value='aguard. avaliacao'>Aguardando Avaliação</option>
              <option value='em atendimento'>Em Atendimento</option>
              <option value='finalizado'>Finalizado</option>
            </Select>
            <Button
              type='button'
              mode='confirm'
              onClick={handleClickSearch}
            >Pesquisar</Button>
          </SearchDiv>
          <Button
            type='button'
            mode='primary'
            startIcon={FaPlus}
            onClick={handleNovoChamado}
          >Novo Chamado</Button>
        </HeaderDiv>
        <Table
          columns={columns}
          showPageJump={false}
          data={dataChamados}
          pageSize={perPage}
          page={currentPage}
          pages={pagesNumber()}
          manual
          loading={loading}
          sortable={false}
          showPageSizeOptions={false}
          noDataText="Nenhuma ocorrencia"
          nextText="Próximo"
          ofText="de"
          previousText="Anterior"
          showPagination={true}
          pageText="Página"
          dbClickCallback={handleOnClickItem}
          selectRow
          deleteRow
        />
      </Container>
      {showNotaModal && <Modal
        title={`Finalizar Chamado - ${chamadoModal?.id}`}
        close={handleOnCloseModal}
        mode='normal'
        onSave={handleSaveNotaModal}
        showButtonSave
      >
        <Select
          title='Nota'
          value={notaChamado}
          onChange={handleChangeNota}
        >
          {optionNota.map(element => {
            return <option key={element} value={element}>{element}</option>
          })}
        </Select>
        <Textarea
          title='Observação'
          label='Observação'
          value={obsChamado}
          onChange={handleChangeObs}
        />
      </Modal>}
      {showModal && <Modal
        title={chamadoModal && chamadoModal.id ? `Chamado Tecnico - ${chamadoModal?.id}` : 'Novo Chamado'}
        close={handleOnCloseModal}
        mode='fullscreen'
        onSave={handleSaveModal}
        closeOnSave={false}
        showButtonSave
      >
        {executing && <Loading />}

        <ContainerModal>
          <ContainerColumn>
            <FormRow className='chamados'>
              <Input
                name="id"
                label='Código'
                value={chamadoModal?.id}
                placeholder='Código'
                width='0 0 49%'
                disabled
              />
              <Input
                name="status"
                label='Status'
                value={chamadoModal?.status}
                placeholder='Status'
                width='0 0 49%'
                disabled
              />
            </FormRow>
            <FormRow className='chamados'>
              <Input
                name="produto_id"
                label='Código Produto'
                value={chamadoModal?.produto_id}
                placeholder='Código Produto'
                onChange={handleInputOnChange}
                onKeyPress={handleEnterProduto}
                onBlur={searchProduto}
                f2Content={<ListaProdutosDetalhes
                  handleProdutoSelecionado={handleProdutoSelecionado}
                />}
                f2Title='Lista de Produtos'
                f2ModalMode='fullscreen'
                width='1'
                disabled={disableInputs()}
              />
              <Input
                name="produto_nome"
                label='Nome Produto'
                value={chamadoModal?.produto_nome}
                placeholder='Nome Produto'
                width='3'
                disabled
              />
            </FormRow>
            <FormRow className='chamados'>
              <Input
                name="cliente_id"
                label='Código Cliente'
                value={chamadoModal?.cliente_id}
                onChange={handleInputOnChange}
                onKeyPress={handleEnterCliente}
                onBlur={searchCliente}
                placeholder='Código Cliente'
                width='1'
                f2Title="Lista do cliente da revenda"
                f2Content={
                  <ListaClientes isRevenda={false} modo='fidelizados' />
                }
                f2CallBack={handleClienteCallBack}
                f2ModalMode='wide'
                disabled={disableInputs()}
              />
              <Input
                name="cliente_nome"
                label='Nome Cliente'
                value={chamadoModal?.cliente_nome}
                placeholder='Nome Cliente'
                width='3'
                disabled
              />
            </FormRow>
            <FormRow>
              <Input
                name="contato"
                label='Contato'
                value={chamadoModal?.contato}
                onChange={handleInputOnChange}
                placeholder='Contato'
                width='1'
                disabled={disableInputs()}
                maxLength={100}
              />
            </FormRow>
          </ContainerColumn>
          <ContainerColumn>
            <FormRow className='chamados'>
              <Select
                name="tipo"
                title='Tipo'
                value={chamadoModal?.tipo}
                onChange={handleSelectOnChange}
                placeholder='Tipo'
                width='2'
                disabled={disableInputs()}
              >
                {optionTipo.map((item) => <option key={item} value={item}>{capitalize(item)}</option>)}
              </Select>
              <Input
                name="data"
                label='Data'
                value={chamadoModal?.data !== '' ? chamadoModal?.data : format(new Date(), 'dd/MM/yyyy')}
                placeholder='Data'
                width='1'
                disabled
              />
            </FormRow>
            <FormRow className='chamados'>
              <Input
                name="funcionario_id"
                label='Código Funcionario'
                value={chamadoModal?.funcionario_id}
                placeholder='Código Funcionario'
                width='1'
                disabled
              />
              <Input
                name="funcionario_nome"
                label='Nome Funcionario'
                value={chamadoModal?.funcionario_nome}
                placeholder='Nome Funcionario'
                width='3'
                disabled
              />
            </FormRow>
            <FormRow className='chamados'>
              <Input
                name="tecnico_id"
                label='Código Tecnico'
                value={chamadoModal?.tecnico_id}
                placeholder='Código Tecnico'
                width='1'
                disabled
              />
              <Input
                name="tecnico_nome"
                label='Nome Tecnico'
                value={chamadoModal?.tecnico_nome}
                placeholder='Nome Tecnico'
                width='3'
                disabled
              />
            </FormRow>
            <FormRow>
              <Input
                name="telefone"
                label='Telefone'
                value={chamadoModal?.telefone}
                onChange={handleInputOnChange}
                placeholder='Telefone'
                width='1'
                disabled={disableInputs()}
                maxLength={50}
              />
            </FormRow>
          </ContainerColumn>
        </ContainerModal>
        <FormRow>
          <Input
            name="projeto"
            label={'Projeto / Assunto (máximo 50 caracteres)'}
            value={chamadoModal?.projeto}
            onChange={handleInputOnChange}
            placeholder='Projeto'
            width='1'
            disabled={disableInputs()}
            maxLength={50}
          />
        </FormRow>
        <FormRow>
          <Textarea
            name="assunto"
            label='Descrição'
            value={chamadoModal?.assunto}
            onChange={handleTextareaOnChange}
            placeholder='Descrição'
            width='1'
            height='50px'
            disabled={disableInputs()}
          />
        </FormRow>
        {chamadoModal?.atendimentos &&
          <WrapperAtendimento>
            <span>Atendimentos:</span>
            <ContainerAtendimentos>
              {chamadoModal?.atendimentos?.map(atendimento => <CardAtendimento key={atendimento.id}>
                <CardHeader>
                  <div>
                    <span>{'Código: '}</span>
                    <span>{atendimento.id}</span>
                  </div>
                  <div>
                    <span>{'Tecnico: '}</span>
                    <span>{atendimento.tecnico_nome}</span>
                  </div>
                  <div>
                    <span>{'Data: '}</span>
                    <span>{atendimento.data}</span>
                  </div>
                </CardHeader>
                <CardObservacao>
                  <div>
                    <span>{'Observação: '}</span>
                    <span>{atendimento.observacao}</span>
                  </div>
                </CardObservacao>
              </CardAtendimento>)}
            </ContainerAtendimentos>
          </WrapperAtendimento>}
      </Modal>}
    </MainLayout>
  )
}
