import { useCallback, useState } from 'react'
import Axios from 'axios'
import { useUsuario } from '../usuario/useUsuario'
import { Cliente } from '../../../modules/clientes/models'

type UltimasComprasProps = {
  id: number
  nome_tecnico: string
  marca: string
  total: number
  data_ultima_compra: string
}

export function useUltimasCompras (cliente: Cliente) {
  const { data: usuarioData } = useUsuario()
  const [data, setData] = useState<UltimasComprasProps[]>([])
  const [loading, setLoading] = useState(false)

  const getUltimasCompras = useCallback(async () => {
    setLoading(true)

    try {
      const response = await Axios.get(`${process.env.REACT_APP_BASE_URL_API_VENDAS}/clientes/${cliente.id}/produtos_mais_vendidos`, {
        headers: {
          Authorization: `Bearer ${usuarioData?.token}`
        }
      })

      if (response) {
        setData(response.data.data)
      }
    } catch (error) {
      throw new Error('Ocorreu um erro ' + error)
    } finally {
      setLoading(false)
    }
  }, [cliente.id, usuarioData])

  return {
    data,
    loading,
    getUltimasCompras
  }
}
