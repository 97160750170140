import React, { useCallback } from 'react'
import { InputF2, Table, TableColumnProps } from '..'
import { Lote } from '../../../modules/produtos/models'
import { TableText } from '../../styles/global'

import { Container } from './styles'

const rowsPerPage = 10

interface ListaLotesProps extends InputF2 {
  lotes?: Lote[],
  depositoId?: number
}

export const ListaLotes = ({ lotes, close, callBack, depositoId }: ListaLotesProps) => {
  let lotesFiltrados = [] as Lote[]
  if (lotes) {
    lotesFiltrados = lotes.filter((lote) => lote.empresa_id === depositoId)
    lotesFiltrados.unshift({
      empresa_id: 0,
      lote: 'Adicionar sem Lote',
      quantidade: 0
    } as Lote)
  }

  const columns: TableColumnProps[] = [
    {
      Header: 'Empresa',
      accessor: 'empresa_id',
      minWidth: 20,
      Cell: ({ value }) => <TableText position='right'>{value}</TableText>
    },
    {
      Header: 'Lote',
      accessor: 'lote',
      minWidth: 60,
      Cell: ({ value }) => <TableText position='center'>{value}</TableText>
    },
    {
      Header: 'Quantidade',
      accessor: 'quantidade',
      minWidth: 40,
      Cell: ({ value }) => <TableText position='right'>{value}</TableText>
    }
    // {
    //   Header: 'Saldo',
    //   accessor: 'saldo',
    //   minWidth: 40,
    //   Cell: ({ value }) => <TableText position='right'>{value}</TableText>
    // }
  ]

  const dbClickTableRowOnclick = useCallback((lote: Lote) => {
    callBack && callBack(lote)
    close && close()
  }, [callBack, close])

  return (

    <Container>
      <Table
        tabIndex={0}
        columns={columns}
        showPageJump={false}
        data={lotesFiltrados}
        pageSize={rowsPerPage}
        sortable={true}
        showPageSizeOptions={false}
        loading={false}
        noDataText="Nenhum Lote cadastrado para esse produto"
        nextText='Próximo'
        ofText='de'
        previousText='Anterior'
        showPagination={lotesFiltrados.length >= rowsPerPage}
        pageText='Página'
        dbClickCallback={dbClickTableRowOnclick}
        selectRow
      />
    </Container>

  )
}
