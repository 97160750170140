import capitalize from 'capitalize-pt-br'
import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { FaSave } from 'react-icons/fa'
import { useHistory } from 'react-router'
import { Funcionario } from '../../../modules/funcionarios/models/funcionario'
import { Button, Input, ListaFuncionarios, Loading } from '../../components'
import { FormRow } from '../../styles/global'
import { Container, DivHeader, DivSubmit, Wrapper } from './styles'
import { useExecUseCase, useFuncionario } from '../../hooks'
import Logo from '../../assets/imgs/logo_brasao.png'
import { makeRegistrarUsuario } from '../../../modules/usuarios/factories'

interface FormStateProps {
  funcionario_id: number,
  nome: string,
  email: string,
  password: string,
  confirm_password: string
}
const initialState = {
  funcionario_id: 0,
  nome: '',
  email: '',
  password: '',
  confirm_password: ''
}

export const Registro = () => {
  const [formState, setFormState] = useState(initialState as FormStateProps)
  const [erros, setErros] = useState<any>({})
  const { data: funcionario } = useFuncionario(formState.funcionario_id)
  const routerHistory = useHistory()
  const { error, executeUseCase, executing } = useExecUseCase()

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value
    switch (name) {
      case 'funcionario_id': {
        let funcionario_id = 0
        if (value) {
          funcionario_id = parseInt(value)
        }
        setFormState((oldState: FormStateProps) => {
          const newState = { ...oldState, funcionario_id }
          return newState
        })
        break
      }
      case 'email': {
        setFormState((oldState: FormStateProps) => {
          const newState = { ...oldState, email: value }
          return newState
        })
        break
      }
      case 'password': {
        setFormState((oldState: FormStateProps) => {
          const newState = { ...oldState, password: value }
          return newState
        })
        break
      }
      case 'confirm_password': {
        setFormState((oldState: FormStateProps) => {
          const newState = { ...oldState, confirm_password: value }
          return newState
        })
        break
      }
    }
  }

  const handleFuncionarioF2CallBack = useCallback((funcionario: Funcionario) => {
    setFormState((oldState: FormStateProps) => {
      const newState = { ...oldState, funcionario_id: funcionario.id as number, nome: funcionario.nome as string }
      return newState
    })
  }, [])

  useEffect(() => {
    if (funcionario?.data.nome) {
      setFormState((oldState: FormStateProps) => {
        const newState = { ...oldState, nome: funcionario?.data.nome as string }
        return newState
      })
    }
  }, [funcionario])

  const handleOnSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      if (formState.password !== formState.confirm_password) {
        setErros((oldState: any) => {
          const newState = { ...oldState, confirm_password: 'A confirmação deve ser igual a senha!' }
          return newState
        })
        return false
      }
      const registroParams = {
        body: {
          funcionario_id: formState.funcionario_id,
          nome: formState.nome,
          email: formState.email,
          password: formState.password
        }
      }
      const makeRegistro = makeRegistrarUsuario()
      const redirectHome = () => {
        setTimeout(() => {
          routerHistory.push('/')
        }, 2500)
      }
      await executeUseCase(
        makeRegistro,
        registroParams,
        'Registro realizado com sucesso.',
        '',
        redirectHome,
        false
      )
    }, [executeUseCase, formState, routerHistory]
  )

  return (
    <Wrapper>
      <Container>
        {executing && <Loading />}
        <DivHeader>
          <img src={Logo} alt="" />
          <span>Cadastro do Usuario</span>
        </DivHeader>
        <form action="submit" onSubmit={handleOnSubmit}>
          <FormRow>
            <Input
              width='2'
              name="funcionario_id"
              title="Digite o código do funcionario ou F2 para buscar"
              label="Código funcionario"
              value={formState.funcionario_id || ''}
              placeholder='Cod. funcionario'
              type="text"
              f2Title="Lista de funcionarios"
              f2Content={<ListaFuncionarios />}
              f2CallBack={handleFuncionarioF2CallBack}
              onChange={handleInputOnChange}
              error={error?.data?.funcionario_id}
            />

            <Input
              width='5'
              name="nome"
              label="Nome Funcionario"
              title="Nome do Funcionario"
              value={capitalize(funcionario?.data.nome || '')}
              placeholder='Nome do funcionario'
              disabled
            />
          </FormRow>
          <FormRow>
            <Input
              width='1'
              name='email'
              title='Digite um E-mail (example@email.com)'
              label='E-mail'
              value={formState.email}
              placeholder='E-mail'
              type='email'
              onChange={handleInputOnChange}
              error={error?.data?.email}
            />
          </FormRow>
          <FormRow>
            <Input
              width='1'
              name='password'
              title='Digite uma Senha'
              label='Senha'
              value={formState.password}
              placeholder='Senha'
              type='password'
              onChange={handleInputOnChange}
              error={error?.data?.password}
            />
            <Input
              width='1'
              name='confirm_password'
              title='Confirme a Senha'
              label='Confirme a Senha'
              value={formState.confirm_password}
              placeholder='Confirme a Senha'
              type='password'
              onChange={handleInputOnChange}
              error={erros.confirm_password}
            />
          </FormRow>
          <DivSubmit>
            <Button startIcon={FaSave} mode='confirm' type='submit'>Salvar</Button>
          </DivSubmit>
        </form>
      </Container>
    </Wrapper>
  )
}
