import styled from 'styled-components'

export const Container = styled.div`
  display: block;
  position: absolute;
  top: 40px;
  right: 0;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 5px;
  background-color: ${props => props.theme.colors.backgroundLight};
  z-index: 500;
  width: fit-content;
  overflow:hidden;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  >ul{
    list-style: none;
    >li{
      display:flex;
      justify-content: flex-end;
      padding: 10px 15px;
      border-bottom: 1px solid ${props => props.theme.colors.border};
      font-size: 15px;
      :hover{
        background-color: ${props => props.theme.colors.primary};
        color: white;
        text-decoration: underline;
      }
    }
  }
`
