import { HttpRequestImpl } from '../../../infra/http'
import { SchemaValidator } from '../../../infra/validator/schemaValidator'
import { AtualizarPedidoServiceRest } from '../services'
import { AtualizarPedido } from '../useCases'
import { PedidoEmEdicaoValidator, updateSchema } from '../validators'

export function makeAtualizarPedido (): AtualizarPedido {
  const httpRequest = new HttpRequestImpl()
  const schemaValidator = new SchemaValidator(updateSchema)
  const validator = new PedidoEmEdicaoValidator(schemaValidator)
  const atualizarPedidoService = new AtualizarPedidoServiceRest(httpRequest)

  return new AtualizarPedido(atualizarPedidoService, validator)
}
