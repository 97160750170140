import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ContainerDiv = styled.div`
  display: flex;
  justify-content: space-between;
`
export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
`

export const Action = styled.div`
  display: flex;
  width: 20%;
  margin-bottom: 10px;
  justify-content: flex-end;
`

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
`
interface DivSkeletonProps {
  width?: string
}

export const DivSkeleton = styled.div<DivSkeletonProps>`
  display: 'flex';
  flex: ${props => props.width ? props.width : 1};
  flex-direction: 'column';
  margin-top: 10px;
  .itemSkeleton{
    display: block;
    margin-bottom: 10px;
  }
`
