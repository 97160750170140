import { handleErrors } from '../../../helpers/handleErrors'
import { PostParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { CadastrarPedidoService } from '../contracts'
import { Orcamento } from '../models'
import { PedidoApi } from './_interfaces'
export class CadastrarPedidoServiceRest implements CadastrarPedidoService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async cadastrarPedido (params: PostParams): Promise<Orcamento> {
    const response = await this.httpRequest.post<PedidoApi>({
      path: 'pedidos',
      body: params.body,
      token: params.token
    })

    handleErrors(response?.error)
    return response?.data as Orcamento
  }
}
