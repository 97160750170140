import React, { useCallback, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { Input, Select } from '..'
import DatePicker, { registerLocale } from 'react-datepicker'
import { Container, FieldData } from './styles'
import ptBR from 'date-fns/locale/pt-BR'
import { format, startOfMonth, subMonths } from 'date-fns'
import { FormRow } from '../../styles/global'
import { FilterPedidoProps } from '../../../modules/_interfaces/filtersProps'

registerLocale('ptBR', ptBR)

interface FilterPedidosProps {
  setFilters: React.Dispatch<React.SetStateAction<FilterPedidoProps>>,
  filters: FilterPedidoProps,
  widthTotal: string,
  widthCampos?: string
  handleEnterInput?: () => void
}

export const FiltroPedidos = ({ setFilters, filters, widthTotal, widthCampos, handleEnterInput }: FilterPedidosProps) => {
  // const [search, setSearch] = useState('')

  const [startDate, setStartDate] = useState<Date>(startOfMonth(subMonths(new Date(), 1)))
  const [startDateActive, setStartDateActive] = useState(false)

  const [endDate, setEndDate] = useState<Date>(new Date())
  const [endDateActive, setEndDateActive] = useState(false)

  // const handleOnChangeSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.value) {
  //     setFilters((oldState: FilterPedidoProps) => {
  //       const newState = { ...oldState, search: event.target.value }
  //       return newState
  //     })
  //   }
  // }, [setFilters])

  // const handleKeyPress = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (event.key === 'Enter') {
  //     handleEnterInput && handleEnterInput()

  //     if (filters.search !== '') {
  //       setFilters((oldState: FilterPedidoProps) => {
  //         const newState = { ...oldState, search: '' }
  //         return newState
  //       })

  //       handleEnterInput && handleEnterInput()
  //     }
  //   }
  // }, [handleEnterInput, filters, setFilters])

  const handleFilterSelectOnChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const name = event.target.name
    const value = event.target.value
    if (name !== '') {
      switch (name) {
        case 'exibir':
          setFilters((oldState: FilterPedidoProps) => {
            const newState = { ...oldState, exibir: value }
            return newState
          })
          break
        case 'tipo':
          setFilters((oldState: FilterPedidoProps) => {
            const newState = { ...oldState, tipo: value }
            return newState
          })
          break
        case 'liberadoEstoque':
          setFilters((oldState: FilterPedidoProps) => {
            const newState = { ...oldState, liberadoEstoque: value }
            return newState
          })
          break
        case 'estoque':
          setFilters((oldState: FilterPedidoProps) => {
            const newState = { ...oldState, estoque: value }
            return newState
          })
          break
        case 'liberacao':
          setFilters((oldState: FilterPedidoProps) => {
            const newState = { ...oldState, liberacao: value }
            return newState
          })
          break
      }
    }
  }, [setFilters])

  const handleChangeStart = (date: Date | null) => {
    if (date) {
      setStartDate(date)
      const formatData = format(date, 'yyyyMMdd')
      const dataInicial = formatData.toString()
      setFilters((oldState: FilterPedidoProps) => {
        const newState = {
          ...oldState,
          dataInicial
        }
        return newState
      })
    }
  }

  const handleChangeEnd = (date: Date | null) => {
    if (date) {
      setEndDate(date)
      const formatData = format(date, 'yyyyMMdd')
      const dataFinal = formatData.toString()
      setFilters((oldState: FilterPedidoProps) => {
        const newState = {
          ...oldState,
          dataFinal
        }
        return newState
      })
    }
  }

  const onBlurDataInicial = () => {
    setStartDateActive(false)
  }

  const onBlurDataFinal = () => {
    setEndDateActive(false)
  }

  return (
    <Container width={widthTotal} data-cy='divFiltroPedidos'>
      <FormRow className='filtroPedidos'>
        {/* <Input
          data-cy='inputSearchFiltroPedidos'
          type='text'
          name='search'
          startIcon={FaSearch}
          value={filters.search}
          onChange={handleOnChangeSearch}
          onKeyPress={handleKeyPress}
          placeholder='...'
          label='Pesquisa'
          width={widthCampos}
          maxLength={9}
        /> */}
        <Select
          data-cy='selectExibirFiltroPedidos'
          title='Equipe / Funcionário'
          className='selectFiltroPedidos'
          width={widthCampos}
          name='exibir'
          onChange={handleFilterSelectOnChange}
          value={filters.exibir}
        >
          <option value='funcionario'>Funcionário</option>
          <option value='equipe'>Equipe</option>
          <option value='todos'>Todos</option>
        </Select>
        <Select
          data-cy='selectTipoFiltroPedidos'
          title='Tipo'
          width={widthCampos}
          name='tipo'
          onChange={handleFilterSelectOnChange}
          value={filters.tipo}
        >
          <option value=''>Todos</option>
          <option value='orcamento'>Orçamento</option>
          <option value='pedido'>Pedido</option>
        </Select>
        <Select
          data-cy='inputLibEstoqueFiltroPedidos'
          title='Liberado p/ Estoque'
          width={widthCampos}
          name='liberadoEstoque'
          onChange={handleFilterSelectOnChange}
          value={filters.liberadoEstoque}
        >
          <option value=''>Todos</option>
          <option value='s'>Sim</option>
          <option value='n'>Não</option>
        </Select>
        <Select
          data-cy='inputSituacaoFiltroPedidos'
          title='Situação'
          width={widthCampos}
          name='estoque'
          onChange={handleFilterSelectOnChange}
          value={filters.estoque}
        >
          <option value=''>em Aberto</option>
          <option value='todos'>Todos</option>
          <option value='faturado'>Faturado</option>
          <option value='cancelado'>Cancelado</option>
        </Select>
        <Select
          data-cy='inputLiberacaoFiltroPedidos'
          title='Liberação'
          width={widthCampos}
          name='liberacao'
          onChange={handleFilterSelectOnChange}
          value={filters.liberacao}
        >
          <option value=''>Todos</option>
          <option value='nao_iniciado'>Não Iniciado</option>
          <option value='parcial'>Parcial</option>
          <option value='total'>Total</option>
        </Select>
      </FormRow>
      <FormRow className='filtroPedidosData'>
        <FieldData width='16%' isActive={startDateActive}>
          <label>Data Início {filters.estoque === 'faturado' ? 'Faturamento' : 'Pedido'}</label>
          <DatePicker
            id='dataInicialFiltroPedidos'
            locale='ptBR'
            dateFormat="dd/MM/yyyy"
            closeOnScroll={true}
            selected={startDate}
            onChange={handleChangeStart}
            onFocus={() => setStartDateActive(true)}
            onBlur={onBlurDataInicial}
            startDate={startDate}
            endDate={endDate}
          />
        </FieldData>
        <FieldData width='16%' isActive={endDateActive}>
          <label>Data Término {filters.estoque === 'faturado' ? 'Faturamento' : 'Pedido'}</label>
          <DatePicker
            id='dataFinalFiltroPedidos'
            locale='ptBR'
            dateFormat="dd/MM/yyyy"
            closeOnScroll={true}
            selected={endDate}
            onChange={handleChangeEnd}
            onFocus={() => setEndDateActive(true)}
            onBlur={onBlurDataFinal}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
        </FieldData>
      </FormRow>
    </Container>
  )
}
