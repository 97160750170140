import { generateSearchQuery } from '../../../helpers'
import { GetParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { ListarChamadosService } from '../contracts'
import { GetChamadosListResponse } from '../models'
import { ListarChamadosRespApi } from './'
export class ListarChamadosServiceRest implements ListarChamadosService {
  private readonly http: HttpRequest

  constructor (http: HttpRequest) {
    this.http = http
  }

  async listarChamados (params: GetParams): Promise<GetChamadosListResponse> {
    let { filter, filterOptions, filterObject, token } = params
    const query = generateSearchQuery(filter, filterOptions, filterObject)

    const response = await this.http.get<ListarChamadosRespApi>({
      path: 'chamados' + query,
      token
    })

    const retorno = {
      data: response.res,
      metadata: response.res?.metadata,
      error: response.error
    } as unknown as GetChamadosListResponse

    return retorno
  }
}
