import styled from 'styled-components'
import { darken } from 'polished'

interface ContainerProps {
  typeButton?: 'danger' | 'primary' | 'secondary' | 'success'
  size?: 'icon' | 'normal'
}

export const Container = styled.button<ContainerProps>`
  user-select: none;
  border: ${props => {
    if (props.typeButton === 'secondary') {
      return props.theme.colors.border
    } else {
      return props.theme.colors.borderLight
    }
  }};
  border-radius: 4px;
  background-color: ${props => {
    if (props.typeButton === 'primary') {
      return props.theme.colors.buttonPrimary
    } else if (props.typeButton === 'secondary') {
      return props.theme.colors.buttonSecondary
    } else if (props.typeButton === 'danger') {
      return props.theme.colors.danger
    } else if (props.typeButton === 'success') {
      return props.theme.colors.success
    } else {
      return 'transparent'
    }
  }};

  min-width: ${props => {
    let size = '50px'
    if (props.size === 'icon') {
      size = '30px'
    } else if (props.size === 'normal') {
      size = '50px'
    }
    return size
  }};
  padding: 4px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => {
    if (props.typeButton === 'secondary') {
      return props.theme.colors.buttonSecondaryText
    } else {
      return props.theme.colors.buttonPrimaryText
    }
  }};
  font-size: 12px;

  >svg {
    fill: ${props => {
      if (props.typeButton === 'secondary') {
        return props.theme.colors.buttonSecondaryText
      } else {
        return props.theme.colors.buttonPrimaryText
      }
    }};
  }

  @media screen and (max-width: 720px) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0px;
    min-width: 10px;
    width: 40px;
    height: 26px;

      > svg {
        width: 20px;
        height: 20px;
      }
    }

    :hover {
    background-color: ${props => {
    if (props.typeButton === 'primary') {
      return darken(0.05, props.theme.colors.buttonPrimary)
    } else if (props.typeButton === 'secondary') {
      return darken(0.05, props.theme.colors.buttonSecondary)
    } else if (props.typeButton === 'danger') {
      return darken(0.05, props.theme.colors.danger)
    } else if (props.typeButton === 'success') {
      return darken(0.05, props.theme.colors.success)
    }
  }};

  :disabled {
    background-color: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.primaryText};
    cursor: not-allowed;
  }
`
