import { UseCase } from '../../_interfaces'
import { TrazerPrecoService } from '../contracts'
import { GetPrecoParams, GetPrecoProdutoResponse } from '../models'

export class TrazerPreco implements UseCase<GetPrecoParams, GetPrecoProdutoResponse> {
  private readonly trazerPrecoService: TrazerPrecoService

  constructor (trazerPrecoService: TrazerPrecoService) {
    this.trazerPrecoService = trazerPrecoService
  }

  async execute (params: GetPrecoParams): Promise<GetPrecoProdutoResponse> {
    return await this.trazerPrecoService.trazerPreco(params)
  }
}
