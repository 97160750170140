import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const BoxDadosCobranca = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0.5rem 0 1rem;
  border-bottom: 1px solid rgba(200, 200, 200, 0.5);

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`
