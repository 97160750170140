import { handleErrors } from '../../../helpers/handleErrors'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { FinalizarChamadoService } from '../contracts'
import { Chamado } from '../models'
import { ChamadoApi, EncerrarChamadoParams } from './_interfaces'

export class FinalizarChamadoServiceRest implements FinalizarChamadoService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async finalizarChamado (params: EncerrarChamadoParams): Promise<Chamado> {
    let { body, token, chamado_id } = params

    const response = await this.httpRequest.put<ChamadoApi>({
      path: `chamados/${chamado_id}/encerrar`,
      body,
      token
    })

    handleErrors(response?.error)
    return response?.data as Chamado
  }
}
