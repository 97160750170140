import { Validator } from '../../_interfaces'
import { AtualizarChamadoService } from '../contracts'
import { Chamado } from '../models'
import { AtualizarChamadoParams } from '../services'

export class AtualizarChamado {
  private readonly atualizarChamadoService: AtualizarChamadoService
  private readonly validator: Validator

  constructor (atualizarChamadoService: AtualizarChamadoService, validator: Validator) {
    this.atualizarChamadoService = atualizarChamadoService
    this.validator = validator
  }

  async execute (putParams: AtualizarChamadoParams): Promise<Chamado> {
    const response = await this.atualizarChamadoService.atualizarChamado(putParams)
    return response
  }
}
