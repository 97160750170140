/* eslint-disable no-unused-vars */
import React, { createContext, useCallback, useEffect, useState } from 'react'
// import { useUsuario } from '../hooks'

interface Tab {
  title: string,
  clienteId: number,
  content: React.ReactNode
}

export interface MainTabsContextProps {
  tabs?: Tab[]
  addTab (tab: Tab): void,
  removeTab (clienteId: number): void,
  activeTab: number,
  setActiveTab (clienteId: number): void,
}

export const MainTabsContext = createContext<MainTabsContextProps>({} as MainTabsContextProps)

export const MainTabsProvider: React.FC = ({ children }) => {
  // const { data: dataUsuario } = useUsuario()
  const [activeTab, setActiveTab] = useState(-1)
  const [tabs, setTabs] = useState([] as Tab[])

  /* console.log('dataUsuario', dataUsuario) */

  useEffect(() => {
    setActiveTab(tabs.length - 1)
  }, [tabs.length])

  const addTab = useCallback((tab: Tab) => {
    const finded = tabs.findIndex((t) => t.clienteId === tab.clienteId)

    if (finded > -1) {
      setActiveTab(finded)
      return
    }

    setTabs(oldState => {
      const newState = [
        ...oldState,
        tab
      ]

      return newState
    })
  }, [tabs])

  const removeTab = useCallback((index: number) => {
    setTabs(tabs => tabs.filter((tab, i) => i !== index))
    setActiveTab(-1)
  }, [])

  return (
    <MainTabsContext.Provider value={{ tabs, addTab, removeTab, activeTab, setActiveTab }} >
      {children}
    </MainTabsContext.Provider>
  )
}
