import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { ListarPromocoesServiceRest } from '../services'
import { ListarPromocoes } from '../useCases'

export function makeListarPromocoes (): ListarPromocoes {
  const httpRequest = new HttpRequestImpl()
  const listarPromocoes = new ListarPromocoesServiceRest(httpRequest)

  return new ListarPromocoes(listarPromocoes)
}
