import { useEffect, useState } from 'react'
import { makeListarPromocoes } from '../../../modules/promocoes/factories'
import { GetPromocoesParams, Promocao } from '../../../modules/promocoes/models'
import { HttpRequestError } from '../../../modules/_interfaces'
import { useHandleErrors } from '../app/useHandleErrors'

const listarPromocoes = makeListarPromocoes()

export function usePromocoes (promocoesParams: GetPromocoesParams) {
  const [promocoesList, setPromocoesList] = useState<Promocao[]>([])
  const [promocoesListError, setPromocoesListError] = useState<HttpRequestError>()
  const [promocoesListLoading, setPromocoesListLoading] = useState(true)
  const { handleError } = useHandleErrors()

  useEffect(() => {
    async function fetch () {
      try {
        const response = await listarPromocoes.execute(promocoesParams)
        if (response.data) {
          setPromocoesList(response.data)
        }
        setPromocoesListLoading(false)
      } catch (error) {
        setPromocoesListError(error)
        setPromocoesListLoading(false)
      }
    }
    promocoesParams.body.produtos.length > 0 && fetch()
  }, [promocoesParams])

  useEffect(() => {
    if (promocoesListError) {
      handleError(promocoesListError)
    }
  }, [handleError, promocoesListError])

  return { promocoesList, promocoesListLoading }
}
