import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Filter = styled.div`
  display:flex;
  width: 100%;
  margin: 15px 0px;

  > div:first-of-type {
    margin-right: 15px;
  }

  > div + div {
    margin-right: 15px;
  }

  > button {
    margin-top: 36px;
  }

  @media (max-width: 690px) {
    flex-direction: column;

    > button {
      width: 50%;
      height: 50px;
      margin: 0.5rem auto;
    }

    > div:nth-child(2) {
      margin-top: 0;
    }
  }
`
interface FieldDataProps {
  width?: string
  isActive?: boolean
  marginRight?: string
}

export const FieldData = styled.div<FieldDataProps>`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  margin-right: ${props => props.marginRight ? props.marginRight : '0'};
  width: ${props => props.width ? props.width : '100%'};

  >label{
    display: flex;
    color: ${props => props.theme.colors.primaryText} !important;
    opacity: ${props => (props.isActive ? '1' : '0.5')};
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .react-datepicker__tab-loop{
    z-index: 1000;
  }

  .react-datepicker__input-container{
    > input{
      width: 100%;
      height: 36px;
      padding: 0px 10px;
      border-radius: 4px;
      border: 1px solid ${props => props.isActive ? props.theme.colors.primary : props.theme.colors.border}
    }
  }

  @media (max-width: 690px) {
    width: 100% !important;
  }
`
