import capitalize from 'capitalize-pt-br'
import React, { useCallback, useMemo, useState } from 'react'
import { ButtonTable, Button, Modal } from '..'
import { Cliente, Ocorrencia } from '../../../modules/clientes/models'
import { Table, TableColumnProps } from '../Table'
import { useOcorrencias } from '../../hooks/ocorrencia'
import { TableActions, TableText } from '../../styles/global'
import { useExecUseCase, useUsuario } from '../../hooks'
import { FaFolderOpen, FaPlus } from 'react-icons/fa'
import { BodyOcorrencia, Container, DivOcorrencia, FormOcorrencia, HeaderOcorrencia, NewButtonDiv, TextFormDiv } from './styles'
import { makeCadastrarOcorrencia } from '../../../modules/clientes/factories/cadastrar-ocorrencia'
import { Loading } from '../Loading'

const perPage = 20

interface FormStateProps {
  descricao: string
}

interface OcorrenciaProps {
  cliente: Cliente
}

const Ocorrencias = ({ cliente }: OcorrenciaProps) => {
  const [ocorrenciaPopOver, setOcorrenciaPopOver] = useState({} as Ocorrencia)
  const [isVisible, setIsVisible] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [textIsActive, setTextIsActive] = useState(false)
  const [formState, setFormState] = useState({} as FormStateProps)
  const [showForm, setShowForm] = useState(false)
  const { executeUseCase, executing } = useExecUseCase()
  const { data: usuario } = useUsuario()

  /*  const { data: dataOcorrencias, reload: reloadOcorrencias, loading: loadingOcorrencias } = useOcorrencias({
     cliente_id: Number(cliente?.id),
     currentPage: currentPage + 1,
     perPage
   }) */

  const { data: dataOcorrencias, reload: reloadOcorrencias, loading: loadingOcorrencias } = useOcorrencias(
    perPage,
    currentPage + 1,
    '',
    Number(cliente?.id)
  )

  const showPagination = useMemo(() => {
    let numItens = 0
    numItens = dataOcorrencias?.metadata?.count as unknown as number
    if (!!dataOcorrencias?.metadata?.count && numItens > perPage) {
      return true
    } else {
      return false
    }
  }, [dataOcorrencias])

  const handleOnPageChange = useCallback((page: number) => {
    if (!dataOcorrencias?.metadata?.count) {
      return
    }

    setCurrentPage(page)
  }, [dataOcorrencias])

  const handleOnClickItem = useCallback((ocorrencia: any) => {
    setOcorrenciaPopOver(ocorrencia)
    setIsVisible(true)
  }, [setOcorrenciaPopOver, setIsVisible])

  const columns: TableColumnProps[] = useMemo(() => [
    {
      Header: '#',
      accessor: 'id',
      minWidth: 10,
      Cell: ({ value }) => {
        return <TableText position='center'>{value}</TableText>
      }
    },
    {
      Header: 'Funcionario',
      minWidth: 20,
      Cell: ({ row }) => {
        const ocorrencia = row._original as Ocorrencia
        return <TableText position='left'>
          {`${ocorrencia.funcionario.id} - ${capitalize(ocorrencia.funcionario.nome)}`}
        </TableText>
      }
    },
    {
      Header: 'Tipo',
      accessor: 'tipo',
      minWidth: 15,
      Cell: ({ value }) => {
        return <TableText position='center'>{capitalize(value)}</TableText>
      }
    },
    {
      Header: 'Descrição',
      accessor: 'descricao',
      minWidth: 50,
      Cell: ({ value }) => {
        return <TableText position='left'>{value}</TableText>
      }
    },
    {
      Header: 'Data',
      accessor: 'data',
      minWidth: 15,
      Cell: ({ value }) => {
        return <TableText position='center'>{value}</TableText>
      }
    },
    {
      Header: 'Hora',
      accessor: 'hora',
      minWidth: 15,
      Cell: ({ value }) => {
        return <TableText position='center'>{value}</TableText>
      }
    },
    {
      Header: 'Ações',
      minWidth: 20,
      Cell: ({ row }) => {
        const ocorrencia = row._original
        return (
          <TableActions>
            <ButtonTable
              typeButton='primary'
              title="Atender ocorrencia"
              onClick={() => handleOnClickItem(ocorrencia)}
            >
              <FaFolderOpen size='15px' />
            </ButtonTable>
          </TableActions>
        )
      }
    }
  ], [handleOnClickItem])

  const handleClickNew = () => {
    setShowForm(true)
    setFormState({} as FormStateProps)
  }

  const handleCancel = () => {
    setShowForm(false)
    setFormState({} as FormStateProps)
  }

  const handleChangeText = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value
    if (value) {
      setFormState((oldState) => {
        const newState = { ...oldState, descricao: value }
        return newState
      })
    }
  }, [setFormState])

  const handleSave = async () => {
    const postParams = {
      body: {
        cliente_id: cliente.id,
        tipo: 'pessoal',
        descricao: formState.descricao,
        funcionario_id: usuario?.funcionario_id
      }
    }
    const cadastrarOcorrencia = makeCadastrarOcorrencia()
    handleCancel()
    executeUseCase(cadastrarOcorrencia, postParams, 'Ocorrência registrada', 'Salvar Ocorrência?', reloadOcorrencias)
  }

  return (
    <Container>
      {executing && <Loading></Loading>}
      <NewButtonDiv>
        <Button type='button' mode='primary' startIcon={FaPlus} onClick={handleClickNew}>Nova Ocorrencia</Button>
      </NewButtonDiv>
      <Table
        columns={columns}
        showPageJump={false}
        data={dataOcorrencias?.data}
        pageSize={perPage}
        page={currentPage}
        pages={dataOcorrencias?.metadata?.count && Math.ceil(dataOcorrencias?.metadata?.count / perPage)}
        onPageChange={handleOnPageChange}
        manual
        loading={!dataOcorrencias?.data && loadingOcorrencias}
        sortable={false}
        showPageSizeOptions={false}
        noDataText="Nenhuma ocorrencia"
        nextText="Próximo"
        ofText="de"
        previousText="Anterior"
        showPagination={showPagination}
        pageText="Página"
        dbClickCallback={handleOnClickItem}
        selectRow
        deleteRow
      />
      {isVisible && <Modal
        mode='normal'
        close={() => setIsVisible(false)}
        title='Ocorrencia'
      >
        <DivOcorrencia>
          <HeaderOcorrencia>
            <div><span>Tipo:</span><span>{ocorrenciaPopOver?.tipo}</span></div>
          </HeaderOcorrencia>
          <BodyOcorrencia>
            <div>
              <span>Descrição:</span>
              <p>{ocorrenciaPopOver?.descricao}</p>
            </div>
            <div><span>{ocorrenciaPopOver?.data + ' - ' + ocorrenciaPopOver?.hora}</span></div>
          </BodyOcorrencia>
        </DivOcorrencia>
      </Modal>
      }
      {showForm &&
        <Modal close={handleCancel} showButtonSave buttonSaveText='Salvar' onSave={handleSave} title='Nova Ocorrência'>
          <FormOcorrencia>
            <TextFormDiv isActive={textIsActive}>
              <label>Descrição</label>
              <textarea
                placeholder='Descrição da ocorrência ...'
                rows={4}
                onChange={handleChangeText}
                onFocus={() => setTextIsActive(true)}
                onBlur={() => setTextIsActive(false)}
              />
            </TextFormDiv>
          </FormOcorrencia>
        </Modal>
      }
    </Container>
  )
}

export default Ocorrencias
