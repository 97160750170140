import capitalize from 'capitalize-pt-br'
import { format } from 'date-fns'
import React, { useState, useCallback, useMemo } from 'react'
import { FaPlus } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, Loading } from '../..'
import { Cliente } from '../../../../modules/clientes/models'
import { makeCadastrarNegociacao, makeTrazerPedidoPorId } from '../../../../modules/pedidos/factories'
import { Negociacao } from '../../../../modules/pedidos/models'
import { useExecUseCase, useUsuario } from '../../../hooks'
import { AppState } from '../../../store'
import { setCampoValorPedidoEmAberto, setPedidoAtualizado } from '../../../store/atendimentos/actions'
import { AtendimentoType } from '../../../store/atendimentos/interfaces'
import { NovaNegociacao } from '../NovaNegociacao'

import { Container, HeaderNegociacoes, CardNegocicao, HeaderCard, ObservacaoCard, NoData, DataHeaderDiv, DadoHeaderDiv, IdHeaderDiv, BodyItem } from './styles'

interface NegociacoesProps {
  cliente: Cliente
  orcamentoIdEmEdicao?: number
  usuario: any
}

export const Negociacoes = ({ cliente, orcamentoIdEmEdicao, usuario }: NegociacoesProps) => {
  const dispatch = useDispatch()
  const atendimentoCliente = useSelector<AppState>(state => state.atendimentos.data[cliente.id as number]) as AtendimentoType
  const pedidoOriginal = atendimentoCliente?.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original
  const negociacoesOriginal = pedidoOriginal?.negociacoes
  const possibilidadeOriginal = pedidoOriginal.possibilidades
  const [date, setDate] = useState(format(new Date(), 'yyyyMMdd'))
  const [openPossibilidade, setOpenPossibilidade] = useState(false)
  const { executeUseCase, executing } = useExecUseCase()
  const { data: userData } = useUsuario()
  const [negociacoes, setNegociacoes] = useState(
    negociacoesOriginal ? [...negociacoesOriginal] : []
  )
  const [novaNegociacao, setNovaNegociacao] = useState<Negociacao>({
    funcionario_id: userData?.funcionario_id
  } as Negociacao)

  const handleOnSaveNegociacoes = async () => {
    const postParams = {
      body: {
        pedido_id: orcamentoIdEmEdicao,
        data: novaNegociacao.data || format(new Date(), 'yyyyMMdd'),
        possibilidades: novaNegociacao.possibilidades,
        negociacao: novaNegociacao
      }
    }

    const cadastrarNegociacao = makeCadastrarNegociacao()
    executeUseCase(cadastrarNegociacao, postParams, 'Negociação registrada', 'Salvar Negociação?', afterSaveNegociacao)
  }

  const afterSaveNegociacao = useCallback(async () => {
    const trazerPedidoPorId = makeTrazerPedidoPorId()
    const params = {
      filterObject: {
        id: orcamentoIdEmEdicao
      },
      token: usuario?.token
    }

    const { data } = await trazerPedidoPorId.execute(params)

    if (data) {
      setNegociacoes(data?.negociacoes as Negociacao[])
      dispatch(setCampoValorPedidoEmAberto({
        clienteId: cliente.id as number,
        pedidoId: orcamentoIdEmEdicao as number,
        campo: 'updated_at',
        valor: data?.updated_at
      }))

      dispatch(setCampoValorPedidoEmAberto({
        clienteId: cliente.id as number,
        pedidoId: orcamentoIdEmEdicao as number,
        campo: 'possibilidades',
        valor: novaNegociacao.possibilidades
      }))
    }
    setOpenPossibilidade(false)
  }, [cliente.id, dispatch, novaNegociacao.possibilidades, orcamentoIdEmEdicao, usuario.token])

  return (
    <Container>
      {executing && <Loading />}
      <HeaderNegociacoes>
        <Button
          type='button'
          mode='confirm'
          startIcon={FaPlus}
          onClick={() => setOpenPossibilidade(true)}
        >Nova Negociação</Button>
      </HeaderNegociacoes>
      {negociacoes?.length
        ? negociacoes.map((item, index) => <CardNegocicao key={index}>
          <HeaderCard>
            <IdHeaderDiv>
              <DadoHeaderDiv>
                <span>ID:</span>
                <span>{item.id}</span>
              </DadoHeaderDiv>
              <DadoHeaderDiv>
                <span>Nome:</span>
                <span>{capitalize(item.funcionario_nome as string)}</span>
              </DadoHeaderDiv>
              <DadoHeaderDiv>
                <span>Tipo:</span>
                <span>{capitalize(item.tipo as string)}</span>
              </DadoHeaderDiv>
            </IdHeaderDiv>
            <DataHeaderDiv>
              <DadoHeaderDiv>
                <span>Data:</span>
                <span>{item.data}</span>
              </DadoHeaderDiv>
              {/* <DadoHeaderDiv>
                <span>Hora:</span>
                <span>{item.hora}</span>
              </DadoHeaderDiv> */}
            </DataHeaderDiv>
          </HeaderCard>
          <ObservacaoCard>
            <BodyItem>
              <span>Observação:</span>
              <p>{item.observacao}</p>
            </BodyItem>
          </ObservacaoCard>
        </CardNegocicao>)
        : <NoData><span>Não há Negociações nesse pedido</span></NoData>
      }
      {openPossibilidade && <Modal
        mode='normal'
        close={() => setOpenPossibilidade(false)}
        showButtonSave
        closeOnSave={false}
        onSave={handleOnSaveNegociacoes}
        title='Nova Negociação'
      >
        <NovaNegociacao
          setNovaNegociacao={setNovaNegociacao}
          setDate={setDate}
        />
      </Modal>}
    </Container>
  )
}
