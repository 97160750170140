import { useCallback, useEffect, useMemo, useState } from 'react'
import { useUsuario } from '..'
import { getPrimeiroDiaMes } from '../../../helpers'
import { makeTrazerResumoCliente } from '../../../modules/clientes/factories'
import { GetResumoResponse } from '../../../modules/clientes/models'
import { GetParams } from '../../../modules/_interfaces'
import { useHandleErrors } from '../app/useHandleErrors'

export function useResumoCliente (cliente_id: number) {
  const { data: usuarioData } = useUsuario()
  const [isLoading, setIsLoading] = useState(false)
  const [isReloading, setIsReloading] = useState(false)
  const { handleError, executing } = useHandleErrors()
  const [data, setData] = useState<GetResumoResponse>()

  const params = useMemo(() => {
    let params: GetParams = {
      token: usuarioData?.token as string
    }

    if (cliente_id) {
      params.filterObject = { ...params.filterObject, cliente_id }
    }

    const dataPassada = getPrimeiroDiaMes()

    if (dataPassada) {
      params.filterObject = { ...params.filterObject, 'data[gte]': dataPassada }
    }

    return params
  }, [cliente_id, usuarioData])

  useEffect(() => {
    setIsLoading(true)
    async function fetcher () {
      const trazerResumoCliente = makeTrazerResumoCliente()
      const response = await trazerResumoCliente.execute(params)
      setData(response)
      setIsLoading(false)
      if (response.error && !executing) {
        handleError(response.error)
      }
    }

    fetcher()
  }, [handleError, params, executing, isReloading])

  const reload = useCallback(() => {
    setIsReloading(true)
  }, [])

  return { data, reload, isLoading }
}
