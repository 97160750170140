import { TrazerEnderecoClienteService } from '../contracts'
import { GetEnderecoPorCep } from '../models'

export class TrazerEnderecoCliente {
  private readonly trazerEndereco: TrazerEnderecoClienteService

  constructor (trazerEndereco: TrazerEnderecoClienteService) {
    this.trazerEndereco = trazerEndereco
  }

  async execute (cep: string): Promise<GetEnderecoPorCep> {
    const response = await this.trazerEndereco.trazerEnderecoCliente({
      filter: cep
    })

    return response
  }
}
