import { calcTotaisPedido, formatStringDate, formatStringHora, handleGetPedidoBloqueios } from '.'
import { GetPedidoResponse, Parcela, Transferencia } from '../modules/pedidos/models'
import { GetPedidoApiResponse } from '../modules/pedidos/services/_interfaces'
import { ModeHandleGet } from '../modules/_interfaces'
import { replaceLineBreakFromApi, replaceRfromApi } from './helperLineBreak'

const checkModoPagamento = (value?: string) => {
  if (!value) {
    return
  }
  const condicoes = ['d', 'c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8', 'c9', 'c10', 'c11', 'c12', 'c13',
    'c14', 'c15', 'c16', 'c17', 'c18', 'c19', 'c20', 'c21', 'c22', 'c23', 'c24']
  if (condicoes.includes(value)) {
    return 'cartao'
  } else {
    return ''
  }
}

export const handleGetPedido = (mode: ModeHandleGet, pedidoApi?: GetPedidoApiResponse) => {
  if (pedidoApi && pedidoApi.data) {
    const data = pedidoApi.data
    let result: GetPedidoResponse = { ...pedidoApi } as GetPedidoResponse
    if (data && result.data) {
      const cliente = data.cliente
      const revenda = data.revenda
      if (data.revenda && (data.revenda.id && data.revenda.id > 0)) {
        result.data.tipo_faturamento = 'direto'
        result.data.revenda = cliente
        result.data.cliente = revenda
      } else {
        result.data.tipo_faturamento = 'normal'
        delete result.data.revenda
      }

      if (result.data.cliente) {
        result.data.cliente.nome_fantasia = result.data.cliente.nome
      }
      if (result.data.revenda) {
        result.data.revenda.nome_fantasia = result.data.revenda.nome
      }

      if (data.historico) {
        result.data.historico = data.historico.map((item: any) => {
          return {
            ...item,
            data: formatStringDate(item.data, 'xxxxxxxx', 'completo'),
            descricao: replaceLineBreakFromApi(item.descricao)

          }
        })
      }

      const calcData = calcTotaisPedido(data.itens, data.juros)
      if (calcData) {
        result.data.itens = calcData.newItens
        result.data.subtotal = calcData.subTotalPedido - calcData.acrescimos + calcData.descontos
      }
      result.data.juros_valor = calcData?.jurosValorPedido ? calcData?.jurosValorPedido : 0
      result.data.qtdeItens = calcData?.qtdeItens ? calcData?.qtdeItens : 0
      result.data.acrescimos = calcData?.acrescimos ? calcData?.acrescimos : 0
      result.data.descontos = calcData?.descontos ? calcData?.descontos : 0
      result.data.difal = calcData?.difalPedido ? calcData?.difalPedido : 0
      result.data.st = calcData?.stPedido ? calcData?.stPedido : 0
      result.data.ipi = calcData?.ipiPedido ? calcData?.ipiPedido : 0
      result.data.peso_total = calcData?.peso_total ? calcData?.peso_total : 0
      result.data.total = calcData?.totalPedido ? calcData?.totalPedido : 0

      if (result.data.total && result.data.subtotal) {
        result.data.descontosPorcentagem = (100 * result.data.descontos) / result.data.subtotal
      }

      if (result.data.cliente) {
        if (result.data.cliente.frete_gratis === 'n') {
          result.data.total += data.frete_valor || 0
        }
        if (result.data.cliente.nome_fantasia !== '') {
          result.data.cliente.nome_fantasia = result.data.cliente.nome
        }
      }

      if (result.data.transportadora && data.transportadora) {
        result.data.transportadora.nome_fantasia = data.transportadora.nome
      }

      if (result.data.funcionario) {
        if (!result.data.funcionario.empresa_id) {
          if (result.data.empresa) {
            result.data.funcionario.empresa_id = result.data.empresa.id
          }
        }
      }

      const modoPagamento = checkModoPagamento(data.condicao_pagamento)
      result.data.pagamento_tipo = modoPagamento || result.data.pagamento_tipo

      if (data.status) result.data.status = data.status
      if (data.data) result.data.data = formatStringDate(data.data, 'xxxxxxxx', 'completo')
      if (data.hora) result.data.hora = formatStringHora(data.hora)
      if (data.solicitacao_vendedor_observacao) result.data.solicitacao_vendedor_observacao = replaceRfromApi(data.solicitacao_vendedor_observacao)

      if (data.negociacoes) {
        result.data.negociacoes = data.negociacoes.map((item: any) => {
          return {
            ...item,
            data: formatStringDate(item.data, 'xxxxxxxx', 'completo'),
            hora: formatStringHora(item.hora)
          }
        })
      }

      if (data.parcelas) {
        result.data.parcelas = data.parcelas.map((parcela: Parcela) => {
          return {
            ...parcela,
            vencimento: formatStringDate(parcela.vencimento as string, 'xxxxxxxx', 'completo')
          }
        })
      }

      if (data.transferencias) {
        result.data.transferencias = data.transferencias.map((transferencia: Transferencia) => {
          transferencia.status = transferencia.status?.toLowerCase()
          if (transferencia.status === 'c') {
            transferencia.status = 'Cancelado'
          } else if (transferencia.status === 'n') {
            transferencia.status = 'Aguardando envio'
          } else if (transferencia.status === 'e') {
            transferencia.status = 'Aguardando Envio'
          } else if (transferencia.status === 'r') {
            transferencia.status = 'Aguardando Recebimento'
          } else if (transferencia.status === 'f') {
            transferencia.status = 'Finalizado'
          }
          return {
            ...transferencia,
            data: formatStringDate(transferencia.data as string, 'xxxxxxxx', 'completo')
          }
        })
      }

      const bloqueios = handleGetPedidoBloqueios(result.data)
      if (bloqueios) {
        result.data.bloquear_alteracoes = bloqueios.bloquear_alteracoes
        result.data.bloquear_alteracao_frete = bloqueios.bloquear_alteracao_frete
        result.data.bloquear_pagamento = bloqueios.bloquear_pagamento
      }
    }
    return result
  } else {
    return pedidoApi
  }
}
