import { useState } from 'react'
import { makeListarChamados } from '../../../modules/chamados/factories'
import { Chamado } from '../../../modules/chamados/models'
import { GetParams } from '../../../modules/_interfaces'
import { useUsuario } from '../usuario/useUsuario'

const listarChamados = makeListarChamados()

interface FetcherProps {
  perPage: number
  currentPage: number
  status?: string
  search?: string
  order?: string
}

export function useChamados () {
  const { data: usuarioData } = useUsuario()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Chamado[]>()
  const [error, setError] = useState<any>()

  async function fetcher ({ perPage = 20, currentPage = 0, status, search = '', order = '-updated_at' }: FetcherProps) {
    setLoading(true)
    try {
      let params = {
        filterOptions: {
          limit: perPage,
          skip: (currentPage) * perPage,
          order: order || '-chamados.updated_at'
        },
        filterObject: {
          funcionario_id: usuarioData?.funcionario_id
        },
        token: usuarioData?.token
      } as GetParams

      if (status) {
        if (status === 'todos') {
          params.filterObject = {
            funcionario_id: usuarioData?.funcionario_id
          }
        } else if (status === 'em andamento') {
          params.filterObject = {
            funcionario_id: usuarioData?.funcionario_id,
            'status[not]': 'finalizado'
          }
        } else {
          params.filterObject = {
            funcionario_id: usuarioData?.funcionario_id,
            status: status
          }
        }
      }
      const response = await listarChamados.execute(params)
      setData(response.data)
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }

  return { fetcher, data, error, loading }
}
