import React, { createContext, useCallback, useState } from 'react'
import { lightTheme } from '../styles/themes/lightTheme'
import { darkTheme } from '../styles/themes/darkTheme'

export interface ThemeNameContextProps {
  theme?: any
  toogleTheme (): void
}

export const ThemeNameContext = createContext<ThemeNameContextProps>({} as ThemeNameContextProps)

export const ThemeNameProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState(lightTheme)

  const toogleTheme = useCallback(() => {
    setTheme(theme.title === 'lightTheme' ? darkTheme : lightTheme)
  }, [theme])

  return (
    <ThemeNameContext.Provider value={{ theme, toogleTheme }} >
      {children}
    </ThemeNameContext.Provider>
  )
}
