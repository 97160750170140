import { GetParams, UseCase } from '../../_interfaces'
import { ListarFuncionariosService } from '../contracts'
import { GetListFuncionarioResponse } from '../models'

export class ListarFuncionarios implements UseCase<GetParams, GetListFuncionarioResponse> {
  private readonly listarFuncionariosService: ListarFuncionariosService

  constructor (listarFuncionariosService: ListarFuncionariosService) {
    this.listarFuncionariosService = listarFuncionariosService
  }

  async execute (params: GetParams): Promise<GetListFuncionarioResponse> {
    return await this.listarFuncionariosService.listarFuncionarios(params)
  }
}
