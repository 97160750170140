/* eslint-disable eqeqeq */
import capitalize from 'capitalize-pt-br'
import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'
import { Atendimento, Tab } from '..'
import { Container, TabContent } from './styles'
import { AppState } from '../../store'
import { fecharAtendimento, setAtendimentoEmFoco } from '../../store/atendimentos/actions'
import { AtendimentosState } from '../../store/atendimentos/interfaces'
import Swal from 'sweetalert2'
import { TabMode } from '../Tab'
import { makeCadastrarOcorrencia } from '../../../modules/clientes/factories/cadastrar-ocorrencia'
import { useExecUseCase, useUsuario } from '../../hooks'

interface TabsAtendimentoProps {
  fixedContent?: React.ReactNode,
  titleFixedContent: string
}

export const TabsAtendimento = ({ fixedContent, titleFixedContent }: TabsAtendimentoProps) => {
  const atendimentosState = useSelector<AppState>(state => state.atendimentos) as AtendimentosState
  const [tabFixedTitle, setTabFixedTitle] = useState('')
  const { data: usuario } = useUsuario()
  const { executeUseCase, executing } = useExecUseCase()
  const dispatch = useDispatch()

  useEffect(() => {
    setTabFixedTitle(titleFixedContent)
  }, [titleFixedContent])

  const setModeTab = useCallback((clienteId: number): TabMode => {
    let retorno: TabMode = 'normal'
    const atendimentoCliente = atendimentosState.data[clienteId]
    let atendimentoEmEdicao = false
    if (Object.keys(atendimentoCliente.pedidosEmAberto.data).length) {
      Object.keys(atendimentoCliente.pedidosEmAberto.data).forEach(element => {
        if (atendimentoCliente.pedidosEmAberto.data[element as any].emEdicao && Object.keys(atendimentoCliente.pedidosEmAberto.data[element as any].emEdicao).length) {
          atendimentoEmEdicao = true
        }
      })
    }
    if (atendimentoCliente && (Object.keys(atendimentoCliente.pedidoEmAndamento).length || atendimentoEmEdicao)) {
      retorno = 'danger'
    }
    return retorno
  }, [atendimentosState.data])

  const criarOcorrencia = useCallback(async (clienteId: number) => {
    let retorno = false
    const { isConfirmed: confirmCriarOcorrencia } = await Swal.fire({
      title: 'Criar ocorrência ?',
      showCancelButton: true,
      cancelButtonText: 'Nao',
      showConfirmButton: true,
      confirmButtonText: 'Sim'
    })
    if (confirmCriarOcorrencia) {
      const swalResponse = await Swal.fire({
        title: 'Ocorrência:',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        showConfirmButton: true,
        confirmButtonText: 'Salvar'
      })
      if (swalResponse.isConfirmed === true && swalResponse.value.length >= 20) {
        const postParams = {
          body: {
            cliente_id: clienteId,
            tipo: 'atendimento',
            descricao: swalResponse.value,
            funcionario_id: usuario?.funcionario_id
          }
        }
        retorno = true
        const cadastrarOcorrencia = makeCadastrarOcorrencia()
        executeUseCase(cadastrarOcorrencia, postParams, '', '', undefined, false)
      } else {
        Swal.fire({
          title: 'Ocorrência',
          text: 'A descrição da ocorrência deve ter no minimo 20 caracters',
          icon: 'warning',
          timer: 2000,
          timerProgressBar: true
        })
      }
    } else {
      retorno = true
    }
    return retorno
  }, [executeUseCase, usuario])

  const handleCloseOnClick = useCallback(async (clienteId: number) => {
    // TODO criar funcao para fazer ocorrencia
    const tabMode = setModeTab(clienteId)
    if (tabMode && tabMode === 'danger') {
      let questionResponse = await Swal.fire({
        text: tabMode === 'danger'
          ? 'Esse Cliente possui um orçamento em edição não salvo. Fechar mesmo assim ?'
          : 'Esse Cliente possui um novo orçamento não salvo. Fechar mesmo assim ?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Fechar sem salvar',
        cancelButtonText: 'Cancelar'
      })
      if (questionResponse?.isConfirmed) {
        const resultOcorrencia = await criarOcorrencia(clienteId)
        resultOcorrencia && dispatch(fecharAtendimento({ clienteId }))
      }
    } else {
      const resultOcorrencia = await criarOcorrencia(clienteId)
      resultOcorrencia && dispatch(fecharAtendimento({ clienteId }))
    }
  }, [criarOcorrencia, dispatch, setModeTab])

  const handleTabOnClick = useCallback((clienteId: number | null) => {
    dispatch(setAtendimentoEmFoco({ clienteId }))
  }, [dispatch])

  return (
    <Container>
      <header>
        {/* Tab fixa */}
        <Tab key='fixed' title={tabFixedTitle} selected={atendimentosState.info.atendimentoClienteIdEmFoco === null} onClick={() => handleTabOnClick(null)} />
        {/* Tab fixa */}
        {
          Object.keys(atendimentosState.data)?.map(key => {
            const clienteId = Number(key)

            return (
              <Tab
                key={key}
                title={`${key} - ${capitalize(atendimentosState.data[clienteId]?.cliente.nome_fantasia as string)}`}
                selected={atendimentosState.info.atendimentoClienteIdEmFoco == clienteId}
                showCloseButton
                onClick={() => handleTabOnClick(Number(key))}
                onClose={() => handleCloseOnClick(atendimentosState.data[Number(key)].cliente.id as number)}
                mode={setModeTab(atendimentosState.data[Number(key)].cliente.id as number)}
              />
            )
          })
        }

      </header>

      <TabContent>
        {/*  conteúdo da tab fixa */}
        {!atendimentosState.info.atendimentoClienteIdEmFoco && fixedContent}
        {/*  conteúdo da tab fixa */}
        {
          Object.keys(atendimentosState.data)?.map(key => {
            const clienteId = Number(key)

            return (
              clienteId == atendimentosState.info.atendimentoClienteIdEmFoco && (
                <Atendimento key={key} cliente={atendimentosState.data[clienteId].cliente}></Atendimento>
              )
            )
          })
        }

      </TabContent>
    </Container>
  )
}
