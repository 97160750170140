/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useHistoricoCobranca } from '../../hooks/historicoCobranca/useHistoricoCobranca'
import { Container, BoxDadosCobranca } from './styles'

type HistoricoCobrancaProps = {
  clienteId?: number
}

export const HistoricoCobranca = ({ clienteId }: HistoricoCobrancaProps) => {
  const { fetcher, cobrancas } = useHistoricoCobranca()

  useEffect(() => {
    if (clienteId) {
      fetcher({ clienteId })
    }
  }, [clienteId])

  return (
    <Container>
      {cobrancas && cobrancas.map((cobranca) => (
        <BoxDadosCobranca key={cobranca.id}>
          <div>
            <h4>Vencimento: <strong>{cobranca.vencimento}</strong></h4>
            <h4>Baixa: <strong>{cobranca.baixa}</strong></h4>
          </div>
          <p>{cobranca.descricao}</p>
        </BoxDadosCobranca>
      ))}
    </Container>
  )
}
