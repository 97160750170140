import capitalize from 'capitalize-pt-br'
import React, { useCallback, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { Input, InputF2, Loading, Table } from '..'
import { Funcionario } from '../../../modules/funcionarios/models/funcionario'
import { SortTableProps } from '../../../modules/_interfaces/sortTable'
import { useFuncionarios } from '../../hooks'
import { TextStatus } from '../../styles/global'
import { TableColumnProps } from '../Table'
import { Container, Header } from './styles'

const perPage = 15

interface ListaFuncionariosProps extends InputF2 {
}

export const ListaFuncionarios = ({ close, callBack }: ListaFuncionariosProps) => {
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [sortTable, setSortTable] = useState<SortTableProps>({} as SortTableProps)
  const { fetcher: getListaFuncionarios, data: funcionarios, isLoading } = useFuncionarios()
  const filterObject = { status: 'ativo' }

  const columns: TableColumnProps[] = [
    {
      Header: '#',
      accessor: 'id',
      minWidth: 20
    },
    {
      Header: 'Nome',
      accessor: 'nome',
      Cell: ({ value }) => capitalize(value)
    },
    {
      Header: 'E-mail',
      accessor: 'email',
      Cell: ({ value }) => value.toString().toLowerCase()
    },
    {
      Header: 'Status',
      accessor: 'status',
      minWidth: 25,
      // eslint-disable-next-line react/display-name
      Cell: ({ value }) => <TextStatus status={value}>{value.toString().toUpperCase()}</TextStatus>
    }
  ]

  const handleCallbackTable = useCallback((funcionario: Funcionario) => {
    callBack && callBack(funcionario)
    close && close()
  }, [callBack, close])

  const handleOnPageChange = useCallback((page: number) => {
    if (!funcionarios?.metadata?.count) {
      return
    }

    setCurrentPage(page)

    getListaFuncionarios({
      perPage,
      currentPage: page + 1,
      search,
      filterObject,
      sortTable
    })
  }, [filterObject, funcionarios, getListaFuncionarios, search, sortTable])

  const handleEnterSearch = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setCurrentPage(0)
      getListaFuncionarios({
        perPage,
        currentPage: 1,
        search,
        filterObject,
        sortTable
      })
    }
  }, [filterObject, getListaFuncionarios, search, sortTable])

  // const handleFilterOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
  // }, [])

  const handleOnSortedChange = (header: any) => {
    const sort = {
      id: header[0].id,
      desc: header[0].desc
    }
    setSortTable(sort)
    getListaFuncionarios({
      perPage,
      currentPage: 1,
      search,
      filterObject,
      sortTable: sort
    })
  }

  return <Container>
    {isLoading && <Loading/>}
    <Header>
      <div>
        <Input
          type='text'
          startIcon={FaSearch}
          onChange={(event) => setSearch(event.target.value)}
          onKeyPress={handleEnterSearch}
          value={search}
          autoFocus
        />
      </div>

    </Header>

    <Table
      tabIndex={0}
      columns={columns}
      showPageJump={false}
      data={funcionarios?.data}
      pageSize={perPage}
      page={currentPage}
      pages={funcionarios?.metadata?.count && Math.ceil(funcionarios?.metadata?.count / perPage)}
      onPageChange={handleOnPageChange}
      manual
      loading={isLoading}
      sortable={true}
      showPageSizeOptions={false}
      onSortedChange={handleOnSortedChange}
      noDataText="Nenhum funcionário encontrado"
      nextText='Próximo'
      ofText='de'
      previousText='Anterior'
      showPagination={!!funcionarios?.metadata?.count && funcionarios?.metadata?.count >= perPage}
      pageText='Página'
      dbClickCallback={handleCallbackTable}
      selectRow
    />
  </Container>
}
