import { GetParams } from '../../_interfaces'
import { ListarClientesService } from '../contracts'
import { GetListClienteResponse } from '../models'

export class ListarClientes {
  private readonly listarClientesService: ListarClientesService

  constructor (listarClientesService: ListarClientesService) {
    this.listarClientesService = listarClientesService
  }

  async execute (params: GetParams): Promise<GetListClienteResponse> {
    const response = await this.listarClientesService.listarClientes(params)
    return response
  }
}
