import { handleErrors } from '../../../helpers/handleErrors'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { CancelarPedidoService } from '../contracts'

export interface CancelarPedidoParams {
  token: string
  id: number
}

export class CancelarPedidoServiceRest implements CancelarPedidoService {
  private readonly http: HttpRequest

  constructor (http: HttpRequest) {
    this.http = http
  }

  async cancelarPedido (pedidoId: number, token: string): Promise<boolean> {
    const response = await this.http.put({
      path: `pedidos/${pedidoId}/cancelar`,
      token
    })

    handleErrors(response?.error)
    return response?.status === 204
  }
}
