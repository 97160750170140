import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  overflow: hidden;
`

export const Header = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 66%;
  padding: 10px 5px;
  background-color: ${props => props.theme.colors.backgroundLight};
  border-bottom: 1px solid ${props => props.theme.colors.border};

  > div:first-of-type {
    width: 68%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;

      > div {
        display: flex;
        align-items: center;
        gap: 10px;

        > button {
          background-color: gray;
          padding: 0.3rem;
          max-width: 60px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
      }
    }
  }

  > div:last-of-type {
    width: 30%;
  }

  @media screen and (max-width: 1366px) {
    width: 64%;
  }
`

export const Depositos = styled.div`
  width: 100%;
  padding: 15px;
`

export const TableEstoque = styled.div`
  margin-top: 25px;
  width: 600px;

  > span {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
  }
`
export const BodyTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 5px;
`
export const BodyCardList = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 5px;
  margin-top: 180px;
  flex-direction: column;
`
export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`
