import React, { useCallback, useMemo, useState } from 'react'
import capitalize from 'capitalize-pt-br'
import { BiFilter } from 'react-icons/bi'
import { Button, Tab, Table, TableColumnProps } from '..'
import { Cliente, ContaReceber } from '../../../modules/clientes/models'
import { TableActions, TableText } from '../../styles/global'
import { Container, Content, Filtros, HeaderTableCrs, TabFixa } from './styles'
import { SortTableProps } from '../../../modules/_interfaces/sortTable'
import { useCrsCliente } from '../../hooks'
import { AcoesCrs } from '../AcoesCrs'
import { Select } from '../Select'
import { FiltersContasProps } from '../../../modules/_interfaces/filtersProps'
import { isBefore } from 'date-fns'
import { formatFloatToCurrency } from '../../../helpers'

const perPage = 20

interface CrsProps {
  cliente: Cliente
}

type ModeColorProps = 'danger' | 'success' | 'warning' | 'default'

export const ContasReceber = ({ cliente }: CrsProps) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [filterIsOpen, setFilterIsOpen] = useState(false)
  const [filters, setFilters] = useState<FiltersContasProps>({
    status: ''
  } as FiltersContasProps)
  const [sortTable, setSortTable] = useState<SortTableProps>({
    id: 'vencimento',
    desc: true
  } as SortTableProps)

  const { data: crsCliente, loading } = useCrsCliente(
    cliente.id as number,
    perPage,
    currentPage + 1,
    sortTable,
    filters
  )

  const handleAbrirCr = useCallback((cr: number) => {
    if (cr) {
      //console.log(cr)
    }
  }, [])

  const handleModeStatusText = (text: string) => {
    let mode: ModeColorProps = 'default'
    if (text === 'baixado') {
      mode = 'success'
    } else if (text === 'bloqueado') {
      mode = 'danger'
    } else if (text === 'cancelado') {
      mode = 'warning'
    }
    return mode
  }

  const columns: TableColumnProps[] = useMemo(() => [
    {
      Header: '#',
      accessor: 'id',
      minWidth: 5,
      Cell: ({ value }) => {
        return <TableText position='right'>{value}</TableText>
      }
    },
    {
      Header: 'Historico',
      accessor: 'historico',
      minWidth: 20,
      Cell: ({ value }) => {
        return <TableText position='left'>{capitalize(value)}</TableText>
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      minWidth: 5,
      Cell: ({ row }) => {
        const cr = row._original
        return <TableText position='left' color={handleModeStatusText(cr.status)}>
          {capitalize(cr.status)}
        </TableText>
      }
    },
    {
      Header: 'Documento',
      accessor: 'documento',
      minWidth: 7,
      Cell: ({ value }) => {
        return <TableText position='left'>{value}</TableText>
      }
    },
    {
      Header: 'Vencimento',
      accessor: 'vencimento',
      minWidth: 5,
      Cell: ({ row }) => {
        const cr = row._original
        const splitDate = cr?.vencimento?.split('/')
        const ISODate = new Date(splitDate[2], splitDate[1], splitDate[0])
        const result = isBefore(ISODate, new Date())
        let modeTextColor: ModeColorProps = 'default'

        if (result && cr.status !== 'baixado') {
          modeTextColor = 'danger'
        } else if (cr.status === 'baixado') {
          modeTextColor = 'success'
        }
        return <TableText position='left' color={modeTextColor}>
          {cr?.vencimento}
        </TableText >
      }
    },
    {
      Header: 'Tipo Baixa',
      accessor: 'tipo_baixa',
      minWidth: 5,
      Cell: ({ value }) => {
        return <TableText position='left'>{capitalize(value)}</TableText>
      }
    },
    {
      Header: 'Valor (R$)',
      accessor: 'valor',
      minWidth: 5,
      Cell: ({ value }) => {
        return <TableText position='right'>{formatFloatToCurrency(value)}</TableText>
      }
    },
    {
      Header: 'Baixado (R$)',
      accessor: 'valor_baixado',
      minWidth: 5,
      Cell: ({ value }) => {
        return <TableText position='right'>{formatFloatToCurrency(value)}</TableText>
      }
    },
    {
      Header: 'Status Baixa',
      accessor: 'status_baixa',
      minWidth: 5,

      Cell: ({ value }) => {
        let mode: ModeColorProps = 'default'

        if (value === 'cartorio' || value === 'bloqueado') {
          mode = 'warning'
        } else if (value === 'inadimplente' || value === 'protestado' || value === 'bloqueado' || value === 'negativado') {
          mode = 'danger'
        }
        return <TableText position='left' color={mode}>{capitalize(value)}</TableText>
      }
    },
    {
      Header: 'Data Baixa',
      accessor: 'data_baixa',
      minWidth: 5,
      Cell: ({ value }) => {
        return <TableText position='left'>{value}</TableText>
      }
    },
    {
      Header: 'Ações',
      minWidth: 6,
      Cell: ({ row }) => {
        const cr = row._original as ContaReceber
        return (
          <TableActions>
            <AcoesCrs cr={cr} />
          </TableActions>
        )
      }
    }
  ], [])

  const handleOnPageChange = useCallback((page: number) => {
    if (!crsCliente?.metadata?.count) {
      return
    }

    setCurrentPage(page)
  }, [crsCliente])

  const handleFilterOpen = () => {
    setFilterIsOpen(!filterIsOpen)
  }

  const handleOnSortedChange = (header: any) => {
    const sort = {
      id: header[0].id,
      desc: header[0].desc
    }
    if (sort.id) {
      setSortTable(sort)
    }
  }

  const handleFilterSelectOnChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const name = event.target.name
    const value = event.target.value
    if (name !== '') {
      switch (name) {
        case 'status':
          setFilters((oldState: FiltersContasProps) => {
            const newState = { ...oldState, status: value }
            return newState
          })
          break
      }
    }
  }, [setFilters])

  return (
    <Container>
      <TabFixa>
        <Tab title="Contas a Receber"
          selected={true} />
      </TabFixa>
      <Content>
        <HeaderTableCrs>
          <div>
            <Button mode='primary' startIcon={BiFilter} onClick={handleFilterOpen} > Filtros</Button>
          </div>
          {filterIsOpen && <Filtros>
            <Select className='contasReceber' title='Status' width='0 0 20%' onChange={handleFilterSelectOnChange} name='status'>
              <option value="">Todos</option>
              <option value="em aberto">Em Aberto</option>
              <option value="baixado">Baixado</option>
            </Select>
          </Filtros>}
        </HeaderTableCrs>
        <Table
          columns={columns}
          showPageJump={false}
          data={crsCliente?.data}
          pageSize={perPage}
          page={currentPage}
          pages={crsCliente?.metadata?.count && Math.ceil(crsCliente?.metadata?.count / perPage)}
          manual
          loading={loading}
          sortable={true}
          onSortedChange={handleOnSortedChange}
          nextText="Próximo"
          previousText="Anterior"
          pageText="Página"
          ofText="de"
          showPageSizeOptions={false}
          noDataText="Nenhuma CR encontrada"
          showPagination={!!crsCliente?.metadata?.count && crsCliente?.metadata?.count >= perPage}
          dbClickCallback={handleAbrirCr}
          selectRow
          onPageChange={handleOnPageChange}
        />
      </Content>
    </Container>
  )
}
