import { useEffect, useState } from 'react'
import { makeListarEmpresas } from '../../../modules/empresas/factories'
import { GetListEmpresaResponse } from '../../../modules/empresas/models'
import { GetParams } from '../../../modules/_interfaces'
import { useHandleErrors } from '../app/useHandleErrors'

export function useEmpresas (params: GetParams) {
  const [isLoading, setIsLoading] = useState(false)
  const { handleError, executing } = useHandleErrors()
  const [data, setData] = useState<GetListEmpresaResponse>()

  useEffect(() => {
    setIsLoading(true)
    async function fetcher () {
      const listarEmpresas = makeListarEmpresas()
      const response = await listarEmpresas.execute(params)

      setData(response)
      setIsLoading(false)

      if (response.error) {
        handleError(response.error)
      }
    }

    fetcher()
  }, [executing, handleError, params])
  return { data, isLoading }
}
