/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FaSearch, FaUser } from 'react-icons/fa'
import { MainLayout } from '../../layouts/MainLayout'
import { Button, ButtonTable, Input, ListaContatos, Modal, Loading, Select } from '../../components'
import { Container, DivButtonNewCliente, DivButtonSearch, Filter, FilterCliente } from './styles'
import { Cliente, Contato } from '../../../modules/clientes/models'
import { SortTableProps } from '../../../modules/_interfaces/sortTable'
import capitalize from 'capitalize-pt-br'
import { useHistory } from 'react-router-dom'
import { BsFillReplyFill } from 'react-icons/bs'
import { useClientes, useCliente, useFuncionario, useUsuario } from '../../hooks'
import { formatFloatToCurrency, formatStringDate, toCnpj } from '../../../helpers'
import { Table, TableColumnProps } from '../../components/Table'
import { AppState } from '../../store'
import { setAtendimentoEmFoco, iniciarAtendimento } from '../../store/atendimentos/actions'
import { AtendimentosType } from '../../store/atendimentos/interfaces'
import { TableActions, TableText } from '../../styles/global'
import { GetParams } from '../../../modules/_interfaces'
import { FilterOptions } from '../../../modules/_interfaces/filterOptions'
import { useSideBar } from '../../contexts'
import Swal from 'sweetalert2'

const perPage = 20

type PesquisaProps = {
  tipo: string
  mostrar: string
  pesquisa?: string
}

export const Clientes = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const atendimentos = useSelector<AppState>(state => state.atendimentos.data) as AtendimentosType

  const [filtersParams, setFiltersParams] = useState<PesquisaProps>({ mostrar: 'fidelizados', tipo: 'todos' })

  const [currentPage, setCurrentPage] = useState(0)
  const [selectShowClientes, setSelectShowClientes] = useState('fidelizados')
  // const { setCurrentTab, resetData } = useCadastroCliente()
  const [showListaContatos, setShowListaContatos] = useState(false)
  const { fetcher: getClientePorId, data: clienteComContatos, loading: loadingCliente } = useCliente()
  const { data: usuario } = useUsuario()
  const { data: funcionario } = useFuncionario(usuario?.funcionario_id as unknown as number)
  const camposSubQueryFilter = ['quantidade_contatos', 'quantidade_orcamentos', 'quantidade_vendas', 'valor_vendas', 'media', 'ultima_ocorrencia']
  const { isMobile } = useSideBar()

  const [sortTable, setSortTable] = useState<SortTableProps>({
    id: 'id',
    desc: false
  } as SortTableProps)

  const [clientesParams, setClientesParams] = useState(() => {
    let params: GetParams = {
      token: usuario?.token as string,
      filterOptions: {
        limit: perPage,
        skip: (currentPage) * perPage
      },
      filter: ''
    } as GetParams

    if (sortTable && sortTable.id) {
      if (sortTable.desc) {
        params.filterOptions = { ...params.filterOptions, order: '-clientes.' + sortTable.id }
      } else {
        params.filterOptions = { ...params.filterOptions, order: 'clientes.' + sortTable.id }
      }
    }

    return params
  })

  const { fetcher: getClientes, data: clientesFidelizados, loading: loadingListaClientes } = useClientes()

  const handleModalClose = useCallback(() => {
    getClientePorId({})
    setShowListaContatos(false)
  }, [getClientePorId])

  const changeFilter = useCallback(() => {
    let params = {
      ...clientesParams,
      filterOptions: {
        ...clientesParams.filterOptions,
        skip: 0
      }
    }

    if (filtersParams.pesquisa) {
      let text = filtersParams.pesquisa.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
      if (filtersParams.tipo === 'todos') {
        params = {
          ...clientesParams,
          filterOptions: {
            limit: perPage,
            skip: 0
          },
          filter: text,
          filterObject: {}
        }
        setClientesParams(params)
      } else if (filtersParams.tipo === 'codigo') {
        console.log('pesquisa por codigo')
        let text = filtersParams.pesquisa.replace(/[^0-9]/g, '')
        params = {
          ...clientesParams,
          filterOptions: {
            limit: perPage,
            skip: 0
          },
          filter: text,
          filterObject: {
            'clientes.id': text
          }
        }
      }
    } else {
      params = {
        token: usuario?.token as string,
        filterOptions: {
          limit: perPage,
          skip: 0
        },
        filter: '',
        filterObject: {}
      }
    }

    setCurrentPage(0)
    getClientes(params, filtersParams.mostrar, usuario?.funcionario_id as unknown as number)
    setSelectShowClientes(filtersParams.mostrar)
  }, [clientesParams, filtersParams, getClientes, usuario])

  const handleInputEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      changeFilter()
    }
  }

  const handleFilterOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFiltersParams({ ...filtersParams, pesquisa: event.target.value })
  }, [filtersParams])

  const handleAtenderOnClick = useCallback((cliente: Cliente) => {
    /**
     * verifica se já existe um atendimento aberto desse cliente
     * se já existir direciona para esse atendimento
     * se não existir abre a tela para escolher um contato para iniciar o atendimento
     */

    if (cliente.status === 'inativo' || cliente.status === 'off') {
      Swal.fire({
        title: 'Atenção',
        text: 'O cliente está inativo e não pode ser atendido.',
        icon: 'warning'
      })

      return false
    }

    if (cliente.status === 'internet') {
      Swal.fire({
        title: 'Atenção',
        text: 'O cliente ainda não foi aprovado e não pode ser atendido.',
        icon: 'warning'
      })

      return false
    }

    if (atendimentos[cliente?.id as number]) {
      dispatch(setAtendimentoEmFoco({
        clienteId: cliente.id as number
      }))
    } else {
      getClientePorId({ clienteId: cliente.id as number, revenda: false })
    }
  }, [atendimentos, dispatch, getClientePorId])

  useEffect(() => {
    if (clienteComContatos !== undefined) {
      setShowListaContatos(true)
    }
  }, [clienteComContatos])

  const handleOnPageChange = useCallback((page: number) => {
    if (!clientesFidelizados?.metadata?.count) {
      return
    }

    setCurrentPage(page)

    const newFilterOptions: FilterOptions = {
      ...clientesParams.filterOptions,
      skip: (page) * perPage
    }

    let params = {
      ...clientesParams,
      filterOptions: newFilterOptions
    }

    setClientesParams(params)
    getClientes(params, filtersParams.mostrar, usuario?.funcionario_id as unknown as number)
  }, [clientesFidelizados, clientesParams, filtersParams.mostrar, getClientes, usuario])

  const handleNovoClienteOnClick = useCallback(() => {
    history.push('/cadastro-cliente')
  }, [history])

  const handleCallbackListaContatos = useCallback(async (contato: Contato) => {
    setShowListaContatos(false)

    if (!clienteComContatos || !contato || !funcionario?.data) {
      return
    }

    dispatch(iniciarAtendimento({
      botaoAtendimentoAtivo: 'geral',
      cliente: clienteComContatos,
      contato,
      funcionario: funcionario?.data
    }))
  }, [clienteComContatos, dispatch, funcionario])

  const columns: TableColumnProps[] = useMemo(() => {
    if (isMobile) {
      return [
        {
          Header: '#',
          accessor: 'id',
          minWidth: 17,
          Cell: ({ value }) => {
            return <TableText position='right'>{value}</TableText>
          }
        },
        {
          Header: 'Cliente',
          Cell: ({ row }) => {
            if (row._original.razao_social) {
              return `${capitalize(row._original.razao_social)} - Cnpj: ${row._original.cnpj}`
            } else {
              return `${capitalize(row._original.nome_fantasia)} - Cnpj: ${row._original.cnpj}`
            }
          }
        },
        {
          Header: 'Ações',
          minWidth: 20,
          // eslint-disable-next-line react/display-name
          Cell: ({ row, index }) => {
            const cliente = row._original as Cliente
            return (
              <TableActions>
                <ButtonTable
                  data-cy={'atenderCliente-' + index}
                  typeButton='primary'
                  title="Atender cliente"
                  onClick={() => handleAtenderOnClick(cliente)}
                >
                  <BsFillReplyFill size='15px' />
                </ButtonTable>
                {/* <ButtonTable
                  title="Abrir cliente"
                  type="button"
                  typeButton='normal'
                  onClick={() => handleEditarClienteOnClick(cliente)}
                >
                  <FaFolderOpen size='15px' />
                </ButtonTable> */}
              </TableActions>
            )
          }
        }
      ]
    } else {
      return [
        {
          Header: '#',
          accessor: 'id',
          minWidth: 17,
          Cell: ({ value }) => {
            return <TableText position='right'>{value}</TableText>
          }
        },
        {
          Header: 'Razão Social',
          accessor: 'razao_social',
          Cell: ({ row }) => {
            if (row._original.razao_social) {
              return capitalize(row._original.razao_social)
            } else {
              return capitalize(row._original.nome_fantasia)
            }
          }
        },
        {
          Header: 'E-mail',
          accessor: 'email',
          minWidth: 35,
          Cell: ({ value }) => {
            return value
          }
        },
        {
          Header: 'CNPJ / CPF',
          accessor: 'cnpj',
          minWidth: 25,
          Cell: ({ value }) => {
            return <TableText position='right'>{toCnpj(value)}</TableText>
          }
        },
        {
          Header: 'Cidade',
          minWidth: 30,
          Cell: ({ row }) => <TableText position='left'>{capitalize(row._original.cidade) + '/' + row._original.uf.toString().toUpperCase()}</TableText>
        },
        {
          Header: 'Endereço',
          accessor: 'endereco',
          minWidth: 25,
          Cell: ({ value }) => capitalize(value)
        },
        {
          Header: 'Contatos',
          accessor: 'quantidade_contatos',
          minWidth: 20,
          Cell: ({ value }) => <TableText position='right'>{value}</TableText>
        },
        {
          Header: 'Orçamentos',
          accessor: 'quantidade_orcamentos',
          minWidth: 25,
          Cell: ({ value }) => <TableText position='right'>{value}</TableText>
        },
        {
          Header: 'N° Vendas',
          accessor: 'quantidade_vendas',
          minWidth: 20,
          Cell: ({ value }) => <TableText position='right'>{value}</TableText>
        },
        {
          Header: 'Média (R$)',
          accessor: 'media',
          minWidth: 30,
          Cell: ({ value }) => <TableText position='right'>{formatFloatToCurrency(value)}</TableText>
        },
        {
          Header: 'Vendas (R$)',
          accessor: 'valor_vendas',
          minWidth: 30,
          Cell: ({ value }) => <TableText position='right'>{formatFloatToCurrency(value)}</TableText>
        },
        {
          Header: 'Últ. Contato',
          accessor: 'ultima_ocorrencia',
          minWidth: 25,
          Cell: ({ value }) => <TableText position='center'>{selectShowClientes === 'equipe' && value !== undefined ? formatStringDate(value, 'xxxxxxxx', 'completo') : value}</TableText>
        },
        {
          Header: 'Ações',
          minWidth: 25,
          // eslint-disable-next-line react/display-name
          Cell: ({ row, index }) => {
            const cliente = row._original as Cliente
            return (
              <TableActions>
                <ButtonTable
                  data-cy={'atenderCliente-' + index}
                  typeButton='primary'
                  title="Atender cliente"
                  onClick={() => handleAtenderOnClick(cliente)}
                  icon={BsFillReplyFill}
                >
                  <BsFillReplyFill size='15px' />
                </ButtonTable>
                {/* <ButtonTable
                  title="Abrir cliente"
                  type="button"
                  typeButton='normal'
                  onClick={() => handleEditarClienteOnClick(cliente)}
                  icon={FaFolderOpen}
                >
                  <FaFolderOpen size='15px' />
                </ButtonTable> */}
              </TableActions>
            )
          }
        }
      ]
    }
  }, [handleAtenderOnClick, isMobile, selectShowClientes])

  const dbClickTableRowOnclick = (cliente: Cliente) => {
    handleAtenderOnClick(cliente)
  }

  const handleChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const name = event.target.name
    const value = event.target.value

    if (name === 'tipo') {
      setFiltersParams({ ...filtersParams, pesquisa: '', [name]: value })
    } else {
      setFiltersParams({ ...filtersParams, [name]: value })
    }

    setCurrentPage(0)
  }

  const handleOnSortedChange = (header: any) => {
    if (header && (clientesFidelizados && clientesFidelizados.data.length > 0)) {
      const sort = {
        id: header[0].id,
        desc: header[0].desc
      }
      setSortTable(sort)

      let newParams = clientesParams

      if (sort && sort.id) {
        newParams = {
          ...clientesParams,
          filterOptions: {
            ...clientesParams.filterOptions,
            skip: 0
          }
        }
        if (camposSubQueryFilter.includes(sort.id)) {
          newParams = {
            ...clientesParams,
            filterOptions: {
              ...clientesParams.filterOptions,
              order: sort.desc ? '-' + sort.id : sort.id
            }
          }
        } else {
          newParams = {
            ...clientesParams,
            filterOptions: {
              ...clientesParams.filterOptions,
              order: sort.desc ? '-clientes.' + sort.id : 'clientes.' + sort.id
            }
          }
        }
        setClientesParams(newParams)
      }

      setCurrentPage(0)
      getClientes(newParams, filtersParams.mostrar, usuario?.funcionario_id as unknown as number)
    }
  }

  return (
    <MainLayout title="Clientes">
      <h1>Clientes</h1>
      <Container>
        {loadingCliente && <Loading />}
        <Filter>
          <FilterCliente>
            <Select
              title='Pesquisar por:'
              name='tipo'
              onChange={handleChangeSelect}
              value={filtersParams.tipo}
              className='tipoPesquisaClienteSelect'
            >
              <option value="codigo">Código</option>
              <option value="todos">Todos os Campos</option>
            </Select>
            <Input
              data-cy='inputSearchCliente'
              label='Pesquisa'
              type='search'
              onKeyPress={handleInputEnter}
              startIcon={FaSearch}
              value={filtersParams.pesquisa}
              onChange={handleFilterOnChange}
              autoFocus
            />
            <Select
              title='Mostrar Clientes'
              name='mostrar'
              onChange={handleChangeSelect}
              value={filtersParams.mostrar}
              className='showClienteSelect'
            >
              <option value="fidelizados">Somente Fidelizados</option>
              <option value="equipe">Somente da equipe</option>
              <option value="todos">Todos os Clientes</option>
            </Select>
          </FilterCliente>
          <DivButtonSearch>
            <Button
              data-cy='buttonPesquisarCliente'
              mode='primary'
              className='btnClientes'
              startIcon={FaSearch}
              type="button"
              onClick={changeFilter}
              tabIndex={1}
            >Pesquisar</Button>
          </DivButtonSearch>
          <DivButtonNewCliente>
            <Button
              data-cy='buttonNovoCliente'
              mode="secondary"
              className='btnClientes'
              startIcon={FaUser}
              type="button"
              onClick={handleNovoClienteOnClick}
            >Novo cliente</Button>
          </DivButtonNewCliente>
        </Filter>

        <Table
          className='tableClientePage'
          tabIndex={2}
          columns={columns}
          showPageJump={false}
          data={clientesFidelizados?.data}
          pageSize={perPage}
          page={currentPage}
          // pages={filtersParams.mostrar === 'fidelizados' ? 1 : clientesFidelizados?.metadata && Math.ceil(clientesFidelizados?.metadata?.count || 0 / perPage)}
          pages={Math.ceil((clientesFidelizados?.metadata?.count || 1) / perPage)}
          onPageChange={handleOnPageChange}
          manual
          loading={loadingListaClientes}
          onSortedChange={handleOnSortedChange}
          sortable={true}
          nextText="Próximo"
          previousText="Anterior"
          pageText="Página"
          ofText="de"
          showPageSizeOptions={false}
          noDataText="Nenhum cliente encontrado"
          showPagination={!!clientesFidelizados?.metadata?.count && clientesFidelizados?.metadata?.count >= perPage}
          dbClickCallback={dbClickTableRowOnclick}
          selectRow
        />

      </Container>
      {showListaContatos && (
        <Modal title="Selecione um contato para continuar" close={handleModalClose}>
          <ListaContatos
            cliente={clienteComContatos as Cliente}
            callBack={handleCallbackListaContatos}
            loadingCliente={loadingCliente}
          />
        </Modal>
      )}
    </MainLayout>
  )
}
