import { handleErrors } from '../../../helpers/handleErrors'
import { PostParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { CadastrarClienteService } from '../contracts'
import { Cliente } from '../models'
import { ClienteApi } from './_interfaces'

export class CadastrarClienteServiceRest implements CadastrarClienteService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async cadastrarCliente (params: PostParams): Promise<Cliente> {
    let { body, token } = params

    const response = await this.httpRequest.post<ClienteApi>({
      path: 'clientes',
      body,
      token
    })

    handleErrors(response?.error)
    return response?.data as Cliente
  }
}
