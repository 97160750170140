import { useState } from 'react'
import { makeProcessarFretePedido } from '../../../modules/pedidos/factories'
import { GetPedido } from '../../../modules/pedidos/models'
import { PostParams } from '../../../modules/_interfaces'
import { useHandleErrors } from '../app/useHandleErrors'
import { useUsuario } from '../usuario'

export function usePedidoProcessarFrete () {
  const { data: usuarioData } = useUsuario()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<GetPedido>()
  const { handleError } = useHandleErrors()

  const processarFretePedido = makeProcessarFretePedido()

  async function fetcher (pedido_id: number, tipo?: string) {
    setData(undefined)
    setLoading(true)

    const params: PostParams = {
      token: usuarioData?.token as string,
      body: {
        pedido_id,
        tipo
      }
    }

    const response: any = await processarFretePedido.execute(params)

    if (response.data) {
      const pedido = response.data.data
      setData(pedido)
      setLoading(false)
      return pedido
    } else {
      handleError(response.error as Error)
    }
    setLoading(false)
  }

  return { fetcher, data, loading }
}
