import * as yup from 'yup'
import { HttpRequestImpl } from '../../../infra/http'
import { SchemaValidator } from '../../../infra/validator/schemaValidator'
import { NovaSolicitacaoServiceRest } from '../services'
import { NovaSolicitacao } from '../useCases/nova-solicitacao'
import { PedidoValidator } from '../validators'

const updateSchema = yup.object().shape({
  tipo: yup.string().required('tipo é um campo obrigatório'),
  observacao: yup.string().min(2).required('observacao  não pode estar vazia')
})

export function makeNovaSolicitacao (): NovaSolicitacao {
  const httpRequest = new HttpRequestImpl()
  const schemaValidator = new SchemaValidator(updateSchema)
  const validator = new PedidoValidator(schemaValidator)
  const novaSolicitacaoServiceRest = new NovaSolicitacaoServiceRest(httpRequest)

  return new NovaSolicitacao(novaSolicitacaoServiceRest, validator)
}
