import React from 'react'

import { Container } from './styles'

type InfiniteScrollMessageProps = {
  text: string
}

export const InfiniteScrollMessage = ({ text }: InfiniteScrollMessageProps) => {
  return (
    <Container>
      {text}
    </Container>
  )
}
