import { HttpRequest } from '../../_interfaces/httpRequest'
import { TrazerListaPrecoService } from '../contracts'
import { GetPrecosParams, GetPrecosProdutosResponse } from '../models'
import { TrazerListaPrecoResApi } from './_interfaces'

export class TrazerListaPrecoServiceRest implements TrazerListaPrecoService {
  private readonly http: HttpRequest

  constructor (http: HttpRequest) {
    this.http = http
  }

  async trazerListaPrecos (params: GetPrecosParams): Promise<GetPrecosProdutosResponse> {
    let body = {
      produtos: params.produtos,
      empresas: params.empresas
    }

    let url = `produtos/precos?cliente_id=${params.clienteId}`

    if (params.usoeconsumo) {
      url += `&uso=${params.usoeconsumo}`
    }

    if (params.solar) {
      url += `&solar=${params.solar}`
    }

    const response = await this.http.post<TrazerListaPrecoResApi>({
      path: url,
      token: params.token,
      body
    })

    return response?.data as GetPrecosProdutosResponse
  }
}
