import { formatStringDate, generateSearchQuery } from '../../../helpers'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { UltimasSaidasService } from '../../clientes/contracts'
import { GetUltimasSaidasParams, GetUltimasSaidasResponse, ItemUltimaSaida } from '../../clientes/models'
// import { transformaStatusPedido } from './helpers'
import { UltimasSaidasRespostaApi } from '../../clientes/services/_interfaces'

export class UltimasSaidasServiceRest implements UltimasSaidasService {
  private readonly http: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.http = httpRequest
  }

  async ultimasSaidas (params: GetUltimasSaidasParams): Promise<GetUltimasSaidasResponse> {
    let { filter, filterOptions, filterObject, token, cliente_id } = params
    const query = generateSearchQuery(filter, filterOptions, filterObject)

    const response = await this.http.get<UltimasSaidasRespostaApi>({
      path: `clientes/${cliente_id}/ultimas_saidas`,
      token,
      query
    })

    let listaReturn: ItemUltimaSaida[] = []
    let count = 0

    if (response && response.res) {
      response.res.forEach(item => {
        count++
        const total = item.valor && item.quantidade ? item.valor * item.quantidade : 0
        let newItem: ItemUltimaSaida = {
          ...item,
          data_faturamento: item.data_faturamento ? formatStringDate(item.data_faturamento, 'xxxxxxxx', 'completo') : '',
          total: parseFloat(total.toFixed(2))
        }
        listaReturn.push(newItem)
      })
    }

    const result: GetUltimasSaidasResponse = {
      data: listaReturn,
      metadata: {
        count,
        skip: 0,
        limit: 0
      },
      error: response.error
    }

    return result
  }
}
