import { GetParams, UseCase } from '../../_interfaces'
import { ListarPedidosService } from '../contracts'
import { GetPedidoListResponse } from '../models'

export class ListarPedidos implements UseCase<GetParams, GetPedidoListResponse> {
  private readonly listarPedidosService: ListarPedidosService

  constructor (listarPedidosService: ListarPedidosService) {
    this.listarPedidosService = listarPedidosService
  }

  async execute (params: GetParams): Promise<GetPedidoListResponse> {
    return await this.listarPedidosService.listarPedidos(params)
  }
}
