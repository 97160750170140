import { transparentize } from 'polished'
import styled, { css } from 'styled-components'
import { fadein } from '../../../styles/animations'

export const Container = styled.div`
  animation: ${fadein} 600ms forwards;
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 660px) {
    & {
      gap: 20px;
    }
  }
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 65px;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 0px;
  gap: 10px;

  > span {
    color: ${props => props.theme.colors.primaryText} !important;
    font-size: 14px;
    font-weight: bold;
  }

  @media (max-width: 660px) {
    & {
      height: auto;
      flex-direction: column;
    }
  }
`

export type SpanTableProps = {
  itemRemovido?: boolean
}

export const SpanTable = styled.span<SpanTableProps>`
  ${props => props.itemRemovido && css`
    text-decoration: line-through;
    color: ${props.itemRemovido && transparentize(0.80, 'black')};
  `}
`
