import React, { ReactNode } from 'react'
import { ThemeProvider } from 'styled-components'
import { SideMenu, Header, TabsAtendimento } from '../../components'
import { useSideBar } from '../../contexts'
import { useThemeName } from '../../hooks'
import { GlobalStyle } from '../../styles/global'
import { Container, Content } from './styles'

interface MainLayoutProps {
  title: string
  children: ReactNode
}

export const MainLayout = ({ title, children }: MainLayoutProps) => {
  const { isVisible, toogle, isMobile } = useSideBar()
  const { theme } = useThemeName()

  const handleClickContainer = () => {
    if (isVisible && isMobile) toogle()
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container onClick={handleClickContainer}>
        <SideMenu />
        <Content isVisible={isVisible}>
          <Header />
          <TabsAtendimento titleFixedContent={title} fixedContent={children} />
        </Content>
      </Container>
    </ThemeProvider>
  )
}
