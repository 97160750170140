export const darkTheme = {
  title: 'darkTheme',
  colors: {
    primary: '#0070E8',
    secondary: '#ced4da',
    background: '#212529',
    backgroundLight: '#495057',
    primaryText: '#FCFEFF',
    secondaryText: '#ccc',
    border: '#E3E4E6',
    borderLight: '#FCFEFF',
    info: '#0070E8',
    warning: '#FF6426',
    danger: '#FF3D51',
    success: '#18B569',
    buttonPrimary: '#006BDE',
    buttonSecondary: '#ced4da',
    buttonPrimaryText: '#FCFEFF',
    buttonSecondaryText: '#505050',
    chartOne: '#18B569',
    chartTwo: '#0070E8',
    highlightTable: 'rgba(0, 123, 255, 0.3)'
  }
}
