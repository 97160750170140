import styled from 'styled-components'
import { StyleSheet } from '@react-pdf/renderer'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0;

  >iframe{
    width: 100vw;
    height: 100vh;
  } 
`
export const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight
  },
  page: {
    flexDirection: 'column',
    /* justifyContent: 'space-between', */
    backgroundColor: '#ffffff',
    padding: '15px'
  },
  headerPdf: {
    borderBottom: '1px solid black',
    flexDirection: 'row',
    height: '80px',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: '15px'
  },
  headerEmpresaPdf: {
    flexDirection: 'column',
    height: 'auto',
    justifyContent: 'space-between'
  },
  headerDivPdf: {
    flexDirection: 'row',
    height: '50px',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  logoPdf: {
    height: '50px',
    width: '50px',
    marginRight: '10px'
  },
  headerEmpresaTextPdf: {
    fontSize: '12px',
    fontWeight: 600
  },
  headerTextPdf: {
    fontSize: '10px',
    fontWeight: 600
  },
  sectionPdf: {
    flexDirection: 'row',
    height: 'auto',
    padding: '10px 0',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid black',
    marginBottom: '5px'
  },
  sectionRevenda: {
    flexDirection: 'column'
  },
  sectionPdfChildren: {
    flexDirection: 'column'
  },
  sectionOrcamento: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  dadosCliente: {
    width: '50%',
    flexDirection: 'column'
  },
  sectionObservacao: {
    width: '50%',
    flexDirection: 'column'
  },
  dadosEmpresaLinePdf: {
    flexDirection: 'column',
    height: 'auto'
  },
  dadosEmpresaDivPdf: {
    flexDirection: 'row',
    height: 'auto',
    justifyContent: 'flex-start'
  },
  dadosDivPdf: {
    flexDirection: 'row',
    height: 'auto',
    width: '135px',
    justifyContent: 'space-between'
  },
  dadosTextPdf: {
    fontSize: '10px',
    fontWeight: 'normal',
    marginBottom: '3px'
  },
  dadosLabelPdf: {
    fontSize: '10px',
    fontWeight: 'bold'
  },
  dadosDivCondicaoPdf: {
    flexDirection: 'row',
    height: 'auto',
    width: 'auto'
  },
  dadosCondicaoTextPdf: {
    fontSize: '10px',
    fontWeight: 'normal'
  },
  dadosCondicaoLabelPdf: {
    fontSize: '10px',
    fontWeight: 'bold',
    margin: '0px 10px 0px 0px'
  },
  sectionCliente: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10px'
  },
  sectionProdutos: {
    flexDirection: 'column'
  },
  clienteHeaderTextPdf: {
    fontSize: '12px',
    fontWeight: 'bold',
    width: '100%',
    marginBottom: '5px'
  },
  headerListaProdutosPdf: {
    height: 'auto',
    borderBottom: '1px solid black',
    borderTop: '1px solid black',
    flexDirection: 'row'
  },
  colunaLista7CPdf: {
    width: '7.45%',
    textAlign: 'center'
  },
  colunaLista7RPdf: {
    width: '7.45%',
    textAlign: 'right'
  },
  colunaLista7LPdf: {
    width: '7.45%',
    textAlign: 'left'
  },
  colunaLista45Pdf: {
    width: '40%'
  },
  colunaLista80Pdf: {
    width: '80%'
  },
  colunaLista100Pdf: {
    width: '100%'
  },
  textHeaderListaPdf: {
    fontSize: '9px',
    fontWeight: 'bold',
    padding: '5px'
  },
  itemProdutoListaPdf: {
    height: 'auto',
    flexDirection: 'column',
    padding: '4px 0px',
    borderBottom: '1px dashed gray'
  },
  linhaItemProdutoListaPdf: {
    backgroundColor: 'rgba(200, 200, 200, 0.6)',
    height: 'auto',
    padding: '2px 0',
    flexDirection: 'row',
    marginBottom: '2px'
  },
  linha2ItemProdutoListaPdf: {
    height: 'auto',
    flexDirection: 'row',
    paddingLeft: '7.45%'
  },
  divItemProdutoListaPdf: {
    height: 'auto',
    flexDirection: 'row',
    width: '20%'
  },
  itemListaTextPdf: {
    fontSize: '8px',
    fontWeight: 'normal'
  },
  itemListaLabelPdf: {
    fontSize: '8px',
    fontWeight: 'bold',
    marginRight: '2px'
  },
  itemListaText2Pdf: {
    fontSize: '8px',
    fontWeight: 'normal'
  },
  sectionFooterPdf: {
    flexDirection: 'row',
    height: 'auto',
    justifyContent: 'space-between',
    marginTop: '50px'
  },
  condicaoViewHeaderPdf: {
    width: '69%',
    flexDirection: 'column',
    marginTop: '5px',
    padding: '5px'
  },
  condicaoTextHeaderPdf: {
    fontSize: '12px',
    fontWeight: 'bold',
    width: '100%',
    borderBottom: '1px solid black',
    marginBottom: '5px'
  },
  condicaoTextPdf: {
    fontSize: '10px',
    marginBottom: '3px'
  },
  assinaturaHeaderPdf: {
    fontSize: '10px',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  linhaAssinaturaPdf: {
    fontSize: '10px',
    margin: '15px 0px 0px 0px'
  },
  assinaturaFooterPdf: {
    fontSize: '8px',
    textAlign: 'center',
    margin: '5px 0px 0px 0px'
  },
  totaisViewHeaderPdf: {
    width: '29%',
    flexDirection: 'column',
    marginTop: '5px'
  },
  totaisDivPdf: {
    flexDirection: 'row',
    width: '100%',
    height: 'auto',
    justifyContent: 'space-between',
    marginBottom: '2px'
  },
  totalDivPdf: {
    flexDirection: 'row',
    height: 'auto',
    justifyContent: 'space-between',
    paddingTop: '5px',
    borderTop: '1px solid black'
  },
  difalDivPdf: {
    flexDirection: 'row',
    height: 'auto',
    justifyContent: 'space-between',
    paddingTop: '5px'

  },
  totaisLabelPdf: {
    fontSize: '10px',
    fontWeight: 600
  },
  totaisTextPdf: {
    fontSize: '10px'
  }
})
