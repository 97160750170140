import * as yup from 'yup'

export const schema = yup.object().shape({
  funcionario_id: yup.number().required('Funcionario ID e um campo obrigatório'),
  cliente_id: yup.number().required('Cliente ID e um campo obrigatório'),
  produto_id: yup.number().required('Produto ID e um campo obrigatório'),
  projeto: yup.string().required('Projeto/Assunto é um campo obrigatório'),
  assunto: yup.string().required('Descrição é um campo obrigatório'),
  tipo: yup.string().required('Tipo é um campo obrigatório')
})
