/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BodyCardList, Container, Header } from './styles'
import { useProdutos, useUsuario } from '../../hooks'
import { ModePedido } from '../Pedido'
import { ListaCardProdutos } from '../ListaCardProdutos'
import { GetParams } from '../../../modules/_interfaces'
import { FilterOptions } from '../../../modules/_interfaces/filterOptions'
import InfiniteScroll from 'react-infinite-scroll-component'
import { InfiniteScrollMessage } from '../InfiniteScrollMessage'
import { FaSearch } from 'react-icons/fa'
import { Input, InputF2 } from '..'
import { Produto } from '../../../modules/produtos/models'
import { setarPesquisaProduto } from '../../store/produtos/actions'
import { ProdutosState } from '../../store/produtos/interfaces'
import { AppState } from '../../store'
import { removerAcento } from '../../../helpers/removerAcentos'
import { AiOutlineColumnHeight, AiOutlineColumnWidth } from 'react-icons/ai'

const produtosLimit = 15

interface ListaProdutosProps extends InputF2 {
  mode?: ModePedido
  handleProdutoSelecionado: (produto: Produto) => void
}

export const ListaProdutosDetalhes = ({ mode = 'register', handleProdutoSelecionado, close }: ListaProdutosProps) => {
  const dispatch = useDispatch()
  const produtoState = useSelector<AppState>(state => state.produtos) as ProdutosState
  const { data: usuario } = useUsuario()
  const [searchText, setSearchText] = useState(produtoState.pesquisa)
  const [isCardRow, setIsCardRow] = useState<boolean>(true)
  const [isCardColumn, setIsCardColumn] = useState<boolean>(false)

  const [produtosParams, setProdutosParams] = useState(() => {
    let params: GetParams = {
      filter: produtoState.pesquisa.toLowerCase(),
      filterOptions: {
        skip: 0,
        limit: produtosLimit
      },
      filterObject: {
        'produtos.status': 'ativo',
        somente_promocoes: 'n',
        somente_com_estoque: 'n',
        funcionario_id: usuario?.funcionario_id
      }
    }

    if (usuario?.token) {
      params.token = usuario.token
    }

    return params
  })
  const {
    produtosList,
    produtosListHasMore,
    produtosListLoading,
    produtosListMetadata
  } = useProdutos(produtosParams)

  const handleChangeSearch = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !produtosListLoading) {
      const newFilterOptions: FilterOptions = {
        ...produtosParams.filterOptions,
        skip: 0
      }

      const newSearch = removerAcento(searchText)

      setProdutosParams(old => ({
        ...old,
        filterOptions: newFilterOptions,
        filter: newSearch
      }))
      dispatch(setarPesquisaProduto(searchText))
    }
  }, [searchText, produtosParams, setProdutosParams, produtosListLoading])

  const handleFilterOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }, [])

  const produtosFetchMore = () => {
    setProdutosParams(old => {
      let skip = 0

      if (produtosListMetadata?.skip !== undefined && produtosListMetadata?.count !== undefined && produtosListMetadata?.limit) {
        skip = produtosListMetadata?.skip + produtosListMetadata?.limit
      }

      return {
        ...old,
        filterOptions: {
          limit: produtosLimit,
          skip
        }
      }
    })
  }

  const infiniteScrollEndMessage = useMemo(() => {
    let text = 'Não há mais produtos'

    if (!produtosList.length) {
      if (produtosListLoading) {
        text = ''
      } else {
        text = 'Nenhum produto encontrado'
      }
    }
    return <InfiniteScrollMessage text={text} />
  }, [produtosList.length, produtosListLoading])

  const handleProdutoSeleted = (produto: Produto) => {
    close && close()
    handleProdutoSelecionado(produto)
  }

  return (
    <Container>
      <Header>
        <div>
          <Input
            label='Pesquisa:'
            type='filter'
            startIcon={FaSearch}
            onChange={handleFilterOnChange}
            onKeyPress={handleChangeSearch}
            value={searchText}
            autoFocus={true}
          />
          <div>
            <button title='Linhas' onClick={() => {
              setIsCardRow(true)
              setIsCardColumn(false)
            }}>
              <AiOutlineColumnWidth color="white" size={18} />
            </button>
            <button title='Colunas' onClick={() => {
              setIsCardColumn(true)
              setIsCardRow(false)
            }}>
              <AiOutlineColumnHeight color="white" size={18} />
            </button>
          </div>
        </div>
      </Header>
      <BodyCardList>
        <InfiniteScroll
          dataLength={produtosList.length || 0}
          endMessage={infiniteScrollEndMessage}
          next={produtosFetchMore}
          hasMore={produtosListHasMore}
          loader=''
          scrollableTarget="modalContent"
        >
          {<ListaCardProdutos
            data={produtosList}
            mode={mode}
            isLoading={produtosListLoading}
            cardMode='detalhes'
            handleSelectProduto={handleProdutoSeleted}
            isCardRow={isCardRow}
            isCardColumn={isCardColumn}
          />}
        </InfiniteScroll>
      </BodyCardList>
    </Container >
  )
}
