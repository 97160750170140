import packageJson from '../../../../package.json'
import React, { useCallback, useEffect, useState } from 'react'
import { FaHome, FaUserFriends, FaHandshake, FaBoxOpen, FaWpforms, FaMoneyBill } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import Logo from '../../assets/imgs/logo_brasao.png'
import { useSideBar } from '../../contexts'
import { setAtendimentoEmFoco } from '../../store/atendimentos/actions'
import { Container, DivLogo, ItemNav, Nav, SystemVersion } from './styles'

export const SideMenu = () => {
  const { push, location } = useHistory()
  const [itemSelected, setItemSelected] = useState('')
  const { isVisible } = useSideBar()
  const dispatch = useDispatch()

  const handleOnClickMenuItem = useCallback((item) => {
    push('/' + item)
  }, [push])

  useEffect(() => {
    dispatch(setAtendimentoEmFoco({
      clienteId: null
    }))
  }, [dispatch, location])

  useEffect(() => {
    const urlAtual = location.pathname

    if (urlAtual === '/') {
      setItemSelected('dashboard')
    } else if (urlAtual === '/clientes') {
      setItemSelected('clientes')
    } else if (urlAtual === '/pedidos') {
      setItemSelected('pedidos')
    } else if (urlAtual === '/produtos') {
      setItemSelected('produtos')
    } else if (urlAtual.includes('/edicao-cliente/')) {
      setItemSelected('clientes')
    } else if (urlAtual === '/cadastro-cliente') {
      setItemSelected('clientes')
    } else if (urlAtual === '/chamados') {
      setItemSelected('chamados')
    } else if (urlAtual === '/vendas') {
      setItemSelected('vendas')
    }
  }, [location])

  return (
    <Container isVisible={isVisible}>
      <DivLogo className={itemSelected === 'home' ? 'selected' : ''}>
        <Link to={'/'}><img src={Logo} alt="route66" /></Link>
      </DivLogo>
      <Nav>
        <ItemNav
          data-cy='liDashboard'
          className={itemSelected === 'dashboard' ? 'selected' : ''}
          onClick={() => handleOnClickMenuItem('')}>
          <FaHome size={27} />
          <span>DashBoard</span>
        </ItemNav>
        <ItemNav
          data-cy='liClientes'
          className={itemSelected === 'clientes' ? 'selected' : ''}
          onClick={() => handleOnClickMenuItem('clientes')}>
          <FaUserFriends size={27} />
          <span>Clientes</span>
        </ItemNav>
        <ItemNav
          data-cy='liPedidos'
          className={itemSelected === 'pedidos' ? 'selected' : ''}
          onClick={() => handleOnClickMenuItem('pedidos')}>
          <FaHandshake size={27} />
          <span>Pedidos</span>
        </ItemNav>
        <ItemNav
          data-cy='liProdutos'
          className={itemSelected === 'produtos' ? 'selected' : ''}
          onClick={() => handleOnClickMenuItem('produtos')}>
          <FaBoxOpen size={27} />
          <span>Produtos</span>
        </ItemNav>
        <ItemNav
          data-cy='liChamados'
          className={itemSelected === 'chamados' ? 'selected' : ''}
          onClick={() => handleOnClickMenuItem('chamados')}>
          <FaWpforms size={27} />
          <span>Chamados</span>
        </ItemNav>
        <ItemNav
          data-cy='liVendas'
          className={itemSelected === 'vendas' ? 'selected' : ''}
          onClick={() => handleOnClickMenuItem('vendas')}>
          <FaMoneyBill size={27} />
          <span>Vendas</span>
        </ItemNav>
      </Nav>
      <SystemVersion>{packageJson.version}</SystemVersion>
    </Container >
  )
}
