import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { SchemaValidator } from '../../../infra/validator/schemaValidator'
import { FinalizarChamadoServiceRest } from '../services'
import { FinalizarChamado } from '../useCases'
import { schema } from '../validators'

export function makeFinalizarChamado (): FinalizarChamado {
  const httpRequest = new HttpRequestImpl()
  const validator = new SchemaValidator(schema)
  const finalizarChamadoService = new FinalizarChamadoServiceRest(httpRequest)

  return new FinalizarChamado(finalizarChamadoService, validator)
}
