import { transparentize } from 'polished'
import styled from 'styled-components'

interface ContainerProps {
  mode: 'warning' | 'danger' | 'normal' | 'success' | 'info'
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  padding: 3px 10px;
  height: 45px;
  border-radius: 4px;
  margin-top: 15px;
  justify-content: center;
  background-color: ${props => transparentize(0.93, props.theme.colors.primaryText)};
  border: 1px solid ${props => {
    if (props.mode === 'danger') {
      return props.theme.colors.danger
    } else if (props.mode === 'warning') {
      return props.theme.colors.warning
    } else if (props.mode === 'normal') {
      return props.theme.colors.primaryText
    } else if (props.mode === 'success') {
      return props.theme.colors.success
    } else if (props.mode === 'info') {
      return props.theme.colors.info
    }
  }};


  strong {
    font-size: 12px;
    color: ${props => {
      if (props.mode === 'danger') {
        return props.theme.colors.danger
      } else if (props.mode === 'warning') {
        return props.theme.colors.warning
      } else if (props.mode === 'normal') {
        return props.theme.colors.primaryText
      } else if (props.mode === 'success') {
        return props.theme.colors.success
      } else if (props.mode === 'info') {
        return props.theme.colors.info
      }
    }};
  }

  p {
    letter-spacing: 0.075em;
    color: ${props => {
      if (props.mode === 'danger') {
        return props.theme.colors.danger
      } else if (props.mode === 'warning') {
        return props.theme.colors.warning
      } else if (props.mode === 'normal') {
        return props.theme.colors.primaryText
      } else if (props.mode === 'success') {
        return props.theme.colors.success
      } else if (props.mode === 'info') {
        return props.theme.colors.info
      }
    }};
    font-size: 12px;
  }
`
