import styled from 'styled-components'

export const Container = styled.header`
  display: flex;
  /* position: fixed; */
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  width: 100%;
  height: 60px;
  background: ${props => props.theme.colors.backgroundLight};
  border-bottom: 1px solid ${props => props.theme.colors.borderLight};
  z-index: 2;
`

export const LeftContainer = styled.div`
  display:flex;
  align-items: center;
`

interface DivLogoProps {
  isVisible: boolean
}

export const DivLogo = styled.div<DivLogoProps>`
  position: absolute;
  left: ${props => props.isVisible ? '-70px' : '20px'};
  transition: all 300ms;
  >a{
    >img{
      width: 45px;
      height: auto;
    }
  }
`

export const BoxFrontVersionNotification = styled.div`
  width: 50%;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  
  > div {
    background-color: yellow;
    border-radius: 10px;
    padding: 0.5rem;
    display: flex;
    align-items: center;
      > h3 {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-size: 0.8rem;
    }
  }
  > button {
    background-color: transparent;
    width: 130px;
    padding: 0.5rem;
    border: 1px solid rgba(200, 200, 200, 0.5);
    border-radius: 20px;
    font-weight: bold;
    transition: 400ms;
    font-size: 0.8rem;
    &:hover {
      transform: scale(1.1);
    }
  }
`

interface HamburgerStylesProps {
  mobileSideBarIsVisible?: boolean,
  sideBarIsVisible: boolean
}

export const Hamburger = styled.div<HamburgerStylesProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 300ms;
  margin-left: ${props => props.sideBarIsVisible ? '0px' : '80px'};

  &:hover {
    background-color: ${props => props.theme.colors.borderLight};
  }

  svg {
    width: 30px;
    height: 30px;
    fill: ${props => props.theme.colors.primaryText}
  }
`

export const RightContainer = styled.div`
  display:flex;
  align-items: center;
  height: 100%;
`

export const DivApps = styled.div`
  display: flex;
  align-items:center;
  width: 35px;
  height: 100%;
  margin-right: 15px;
  cursor: pointer;
`

export const DivNotificacoes = styled.div`
  position: relative;
  display: flex;
  align-items:center;
  width: 35px;
  height: 100%;
  margin-right: 15px;
  cursor: pointer;
  >span {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 12px;
    right: 6px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.danger};
    color: ${props => props.theme.colors.secondaryText};
    font-weight: bold;
  }
`

interface AppDropProps {
  showDrop?: boolean
}

export const AppsDrop = styled.div<AppDropProps>`
  display: ${props => props.showDrop ? 'block' : 'none'};
  position: absolute;
  top: 60px;
  right: 0;
  border: 1px solid ${props => props.theme.colors.borderLight};
  border-radius: 5px;
  background-color: ${props => props.theme.colors.backgroundLight};
  z-index: 500;
  width: 30%;
  height: 300px;
`

export const AvatarDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex:1;
  align-items: flex-end;
  padding: 0px 5px;
  font-size: 15px;
  >div:first-of-type{
    cursor: pointer;
    >span{
      margin-right: 10px;
    }
    :hover{
      color: ${props => props.theme.colors.primary};
      >span{
        text-decoration: underline;
      }
    }
    .UserAvatar{
      border: none;
      border-radius: 50%;
    }
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: 0.075em;
`

export const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;

   > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: contain;
  }
`

interface AvatarDropProps {
  showDrop?: boolean
}

export const AvatarDrop = styled.div<AvatarDropProps>`
  display: ${props => props.showDrop ? 'block' : 'none'};
  position: absolute;
  top: 60px;
  right: 0;
  border: 1px solid ${props => props.theme.colors.borderLight};
  border-radius: 5px;
  background-color: ${props => props.theme.colors.backgroundLight};
  z-index: 500;
  width: 15%;
  overflow:hidden;
  >ul{
    list-style: none;
    >li{
      cursor: pointer;
      display:flex;
      justify-content: flex-end;
      padding: 10px 15px;
      border-bottom: 1px solid ${props => props.theme.colors.borderLight};
      font-size: 15px;
      :hover{
        background-color: ${props => props.theme.colors.primary};
        color: white;
        text-decoration: underline;
      }
    }
  }
`
