import { FilterOptions } from '../modules/_interfaces/filterOptions'

export function generateSearchQuery (
  filter?: string | undefined,
  filterOptions?: FilterOptions,
  filterObject?: object): string {
  let result = ''

  if (filter) {
    result = `?search=${filter}`
  }

  if (filterOptions) {
    Object.keys(filterOptions).forEach(key => {
      result += `${result.length ? '&' : '?'}${key}=${filterOptions[key as keyof FilterOptions]}`
    })
  }

  if (filterObject) {
    Object.keys(filterObject).forEach(key => {
      const valor: any = filterObject[key as keyof object]
      if (Array.isArray(valor)) {
        valor.map(element => {
          result += `${result.length ? '&' : '?'}${key}=${element}`
        })
      } else {
        result += `${result.length ? '&' : '?'}${key}=${valor}`
      }
    })
  }

  return result
}
