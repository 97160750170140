export const lightTheme = {
  title: 'lightTheme',
  colors: {
    primary: '#005ABE',
    secondary: '#828282',
    background: '#DEE2E6',
    backgroundLight: '#FCFEFF',
    primaryText: '#505050',
    secondaryText: '#FCFEFF',
    border: '#BDBEBF',
    borderLight: '#E3E4E6',
    info: '#005ABE',
    warning: '#e25822',
    danger: '#dc3545',
    success: '#0D7140',
    buttonPrimary: '#005ABE',
    buttonSecondary: '#dee2e6',
    buttonPrimaryText: '#FCFEFF',
    buttonSecondaryText: '#505050',
    chartOne: '#0D7140',
    chartTwo: '#005ABE',
    highlightTable: 'rgba(0, 123, 255, 0.65)'
  }
}
