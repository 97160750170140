import { useState } from 'react'
import { useUsuario } from '..'
import { makeListarVendasFuncionario } from '../../../modules/funcionarios/factories'
import { GetListarVendasFuncionarios } from '../../../modules/funcionarios/models'
import { GetParams } from '../../../modules/_interfaces'
import { FilterVendaProps } from '../../../modules/_interfaces/filtersProps'
import { useHandleErrors } from '../app/useHandleErrors'

interface FetcherProps {
  filters: FilterVendaProps
}

export function useVendasFuncionario () {
  const { data: usuarioData } = useUsuario()
  const [loading, setLoading] = useState(false)
  const { handleError, executing } = useHandleErrors()
  const [data, setData] = useState<GetListarVendasFuncionarios>()

  async function fetcher ({ filters } : FetcherProps) {
    setLoading(true)
    const listarVendasFuncionarios = makeListarVendasFuncionario()

    const funcionario_id: number = usuarioData ? Number(usuarioData.funcionario_id) : 0

    let params: GetParams = {
      token: usuarioData?.token as string
    }

    if (filters) {
      if (filters.dataInicial && filters.dataFinal) {
        params.filterObject = { ...params.filterObject, 'data[between]': `${filters.dataInicial}|${filters.dataFinal}` }
      }

      if (filters.dataInicial && filters.dataFinal) {
        params.filterObject = { ...params.filterObject, 'data[between]': `${filters.dataInicial}|${filters.dataFinal}` }
      }
    }

    const response = await listarVendasFuncionarios.execute(funcionario_id, params)

    setData(response)

    if (response.error && !executing) {
      handleError(response.error)
    }

    setLoading(false)
  }

  return { fetcher, data, loading }
}
