import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { CadastrarNegociacaoServiceRest } from '../services/cadastra-negociacao'
import { CadastrarNegociacao } from '../useCases'

export function makeCadastrarNegociacao (): CadastrarNegociacao {
  const httpRequest = new HttpRequestImpl()
  const cadastrarNegociacaoService = new CadastrarNegociacaoServiceRest(httpRequest)

  return new CadastrarNegociacao(cadastrarNegociacaoService)
}
