type TipoTelefone = 'telefone' | 'celular'

export const formatStringTelefone = (txt: string, tipo: TipoTelefone) => {
  if (!txt) {
    return
  }
  let texto = '('
  let x = 10
  // 1436026666
  if (tipo === 'telefone') {
    x = 10
  } else if (tipo === 'celular') {
    x = 11
  }

  for (let index = 0; index < x; index++) {
    if (!txt[index]) {
      return ''
    }

    if (index < 1) {
      texto = texto + txt[index]
    } else if (index === 1) {
      texto = texto + txt[index] + ') - '
    } else if (index >= 2) {
      texto = texto + txt[index]
    }
  }

  return texto
}
