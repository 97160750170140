import { AppError, AppErrorData } from '../../../helpers'
import { Validator } from '../../_interfaces'

export class PedidoValidator implements Validator {
  private readonly schemaValidator: Validator

  constructor (validator: Validator) {
    this.schemaValidator = validator
  }

  async validate (object: any): Promise<void> {
    return await this.schemaValidator.validate(object)
  }
}

export class PedidoEmEdicaoValidator implements Validator {
  private readonly schemaValidator: Validator

  constructor (validator: Validator) {
    this.schemaValidator = validator
  }

  async validate (object: any): Promise<void> {
    let error = {
      type: 'validate',
      message: 'Erro de validação',
      data: {}
    } as AppErrorData

    const itensComQtdeDefinida = object.itens.filter((item: { quantidade: number }) => item.quantidade > 0) as []

    if (!itensComQtdeDefinida.length) {
      error.data = { ...error.data, itens: 'Pedido sem itens não é permitido' }
    }

    try {
      await this.schemaValidator.validate(object)
    } catch (error) {
      const err = error as AppError
      if (err.type === 'validade') {
        error.data = { ...error.data, ...err.data }
      }

      throw new AppError(error)
    }

    if (Object.keys(error.data)?.length) {
      throw new AppError(error)
    }
  }
}
