import { handleGetPedido } from '../../../helpers/handleGetPedido'
import { GetParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { TrazerPedidoPorIdService } from '../contracts'
import { GetPedidoResponse } from '../models'
import { GetPedidoApiResponse } from './_interfaces'
export class TrazerPedidoPorIdServiceRest implements TrazerPedidoPorIdService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async trazerPedidoPorId (params: GetParams): Promise<GetPedidoResponse> {
    let { filterObject, token } = params
    const { id } = filterObject as any
    if (!id) return {} as GetPedidoResponse

    const response = await this.httpRequest.get<GetPedidoApiResponse>({
      path: `pedidos/${id}`,
      token
    })

    const retorno = {
      data: response.res?.data,
      metadata: response.res?.metadata,
      error: response.error
    } as GetPedidoResponse

    return handleGetPedido('edit', retorno) as GetPedidoResponse
  }
}
