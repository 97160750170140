import { handleErrors } from '../../../helpers/handleErrors'
import { UpdateParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { AtualizarClienteService } from '../contracts'
import { Cliente } from '../models'
import { ClienteApi } from './_interfaces'

export class AtualizarClienteServiceRest implements AtualizarClienteService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async atualizarCliente (params: UpdateParams): Promise<Cliente> {
    let { id, body, token } = params

    const response = await this.httpRequest.put<ClienteApi>({
      path: `clientes/${id}`,
      body,
      token
    })

    handleErrors(response?.error)
    return response?.data as Cliente
  }
}
