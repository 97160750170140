import capitalize from 'capitalize-pt-br'
import React from 'react'
import { Table, TableColumnProps } from '..'
import { EstoqueAlternativo } from '../../../modules/pedidos/models'
import { TableText } from '../../styles/global'

import { Container } from './styles'

const rowsPerPage = 15

interface ListaEstoquesProps {
  estoques?: EstoqueAlternativo[],
  loading?: boolean
}

export const ListaEstoquesAlternativos = ({ estoques, loading }: ListaEstoquesProps) => {
  const columns: TableColumnProps[] = [
    {
      Header: 'Código',
      accessor: 'id',
      Cell: ({ value }) => <TableText position='right'>{`${value}`}</TableText>
    },
    {
      Header: 'Empresa',
      accessor: 'nome',
      Cell: ({ value }) => <TableText position='left'>{`${capitalize(value)}`}</TableText>
    },
    {
      Header: 'Disponivel',
      accessor: 'porcentagem',
      Cell: ({ value }) => <TableText position='right'>{`${value.toFixed(2)}%`}</TableText>
    }
  ]

  return (

    <Container>
      <Table
        tabIndex={0}
        columns={columns}
        showPageJump={false}
        data={estoques}
        pageSize={rowsPerPage}
        sortable={true}
        showPageSizeOptions={false}
        loading={loading}
        noDataText="Nenhum Estoque cadastrado para esse produto"
        nextText='Próximo'
        ofText='de'
        previousText='Anterior'
        showPagination={estoques && estoques.length >= rowsPerPage}
        pageText='Página'
        selectRow
      />
    </Container>

  )
}
