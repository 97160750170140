import capitalize from 'capitalize-pt-br'
import React from 'react'
import { ContaReceber } from '../../../modules/clientes/models'
import { FormRow } from '../../styles/global'
import { Input } from '../Input'

import { ContaBody, Container } from './styles'

interface ContasProps {
  cr?: ContaReceber
  cp?: any
}

const DetalhesContas = (props: ContasProps) => {
  return (
    <Container>
      {props.cr && <ContaBody>
        <FormRow>
          <Input
            label='Codigo'
            title='Codigo'
            type='text'
            value={props.cr.id}
            disabled
          />
          <Input
            label='Status'
            title='Status'
            type='text'
            value={capitalize(props.cr.status)}
            disabled
          />
          <Input
            label='Emissão'
            title='Emissão'
            type='text'
            value={props.cr.emissao}
            disabled
          />
        </FormRow>
        <FormRow>
          <Input
            label='Documento'
            title='Documento'
            type='text'
            value={props.cr.documento}
            disabled
          />
          <Input
            label='Vencimento'
            title='Vencimento'
            type='text'
            value={props.cr.vencimento}
            disabled
          />
          <Input
            label='Vencimento Original'
            title='Vencimento Original'
            type='text'
            value={props.cr.vencimento_original}
            disabled
          />
        </FormRow>
        <FormRow>
          <Input
            label='Digitavel'
            title='Digitavel'
            type='text'
            value={props.cr.digitavel}
            disabled
          />
        </FormRow>
        <FormRow>
          <Input
            label='Tipo Baixa'
            title='Tipo Baixa'
            type='text'
            value={capitalize(props.cr.tipo_baixa)}
            disabled
          />
          <Input
            label='Status Baixa'
            title='Status Baixa'
            type='text'
            value={capitalize(props.cr.status_baixa)}
            disabled
          />
          <Input
            label='Data Baixa'
            title='Data Baixa'
            type='text'
            value={props.cr.data_baixa}
            disabled
          />
        </FormRow>
        <FormRow>
          <Input
            label='Pedido'
            title='Pedido'
            type='text'
            value={props.cr.pedido_id}
            disabled
          />
          <Input
            label='Historico'
            title='Historico'
            type='text'
            value={props.cr.historico}
            disabled
          />
        </FormRow>
        <FormRow>
          <Input
            label='Cliente'
            title='Cliente'
            type='text'
            value={capitalize(props.cr.cliente.nome_fantasia)}
            disabled
          />
          <Input
            label='Empresa'
            title='Empresa'
            type='text'
            value={capitalize(props.cr.empresa.nome)}
            disabled
          />
          <Input
            label='Funcionario'
            title='Funcionario'
            type='text'
            value={capitalize(props.cr.funcionario.nome)}
            disabled
          />
        </FormRow>
      </ContaBody>}
      {props.cp && <ContaBody>
      </ContaBody>}
    </Container>
  )
}

export default DetalhesContas
