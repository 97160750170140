import React, {
  ChangeEvent,
  InputHTMLAttributes,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

// import CurrencyInput from 'react-currency-input-field'

import IntlCurrencyInput from 'react-intl-currency-input'
import { FaCopy, FaEye, FaInfoCircle } from 'react-icons/fa'
import InputMask from 'react-input-mask'
import { IconType } from 'react-icons'
import { Wrapper, Container, IconPassword, Label, IconError, Icon } from './styles'
import { getIEMask, IEType } from '../../../helpers/getIEMask'
import { Modal, ModalMode } from '../Modal'
import { ToolTip } from '../ToolTip'
import Swal from 'sweetalert2'
// import { ReadStream } from 'tty'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  startIcon?: IconType
  label?: string
  error?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  width?: string
  margin?: string
  marginRight?: string
  type?: 'cnpj' | 'cpf' | 'text' | 'password' | 'email' | 'cep' | 'ddd' | 'telefone' | 'celular' | 'number' | 'currency' | 'filter' | 'search' | IEType
  f2Title?: string
  f2Function?: () => void
  f2Content?: React.ReactNode
  f2ModalMode?: ModalMode
  f2CallBack?: (value: any) => void
  ref?: any,
  inputRef?: any,
  onCurrencyTypeValueChange?: (value?: string) => void
  onInputCurrencyChance?: (e: ChangeEvent<HTMLInputElement>, value: number, maskedValue: string) => void
  copyValue?: boolean
  color?: string
  backgroundColor?: string
}

const isIeType = (type: string): boolean => type?.includes('ie-')

const currencyConfig: any = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    }
  }
}

export interface InputF2 {
  close?: () => void
  callBack?: (data: any) => void
}

export const Input = ({
  startIcon: StartIcon,
  label,
  error,
  onChange,
  onBlur,
  width,
  margin,
  marginRight,
  disabled,
  f2Title,
  f2Function,
  f2Content,
  f2CallBack,
  f2ModalMode,
  type: typeProp,
  ref,
  inputRef,
  onCurrencyTypeValueChange,
  onInputCurrencyChance,
  copyValue,
  color,
  backgroundColor,
  ...rest
}: InputProps) => {
  const [isActive, setIsActive] = useState(false)
  const [hasContent, setHasContent] = useState(false)
  const [type, setType] = useState('text')
  const [showPassword, setShowPassword] = useState(false)
  const [inputError, setInputError] = useState('')
  const [showToolTip, setShowToolTip] = useState(false)
  const [mask, setMask] = useState('')
  const [f2ModalIsvisible, setF2ModalIsvisible] = useState(false)
  const inputEl = useRef<any>({})
  const inputElCurrency = useRef<any>({})

  useEffect(() => {
    if (isIeType(typeProp as string)) {
      setMask(getIEMask(typeProp as IEType))
    } else {
      if (typeProp === 'cnpj') {
        setMask('99.999.999/9999-99')
      } else if (typeProp === 'cpf') {
        setMask('999.999.999-99')
      } else if (typeProp === 'cep') {
        setMask('99999-999')
      } else if (typeProp === 'ddd') {
        setMask('99')
      } else if (typeProp === 'telefone') {
        setMask('9999-9999')
      } else if (typeProp === 'celular') {
        setMask('99999-9999')
      } else if (typeProp === 'password') {
        setType('password')
      } else if (typeProp === 'filter') {
        setType('text')
      } else if (typeProp === 'search') {
        setType('search')
      }
    }
  }, [typeProp])

  useEffect(() => {
    setInputError(error as string)
  }, [error])

  useEffect(() => {
    let value = inputEl?.current?.props?.value?.toString()

    if (typeProp !== 'currency' && value) {
      value = value.replace(/[^\w\s]/gi, '').replace(/_/g, '')
    }

    if (value && value.length > 0) {
      setHasContent(true)
    } else {
      setHasContent(false)
    }
  }, [inputEl.current.props, typeProp])

  useEffect(() => {
    let value = inputEl?.current?.props?.value?.toString()

    if (typeProp !== 'currency' && value) {
      value = value.replace(/[^\w\s]/gi, '').replace(/_/g, '')
    }

    if (value && value.length > 0) {
      setHasContent(true)
    } else {
      setHasContent(false)
    }
  }, [inputElCurrency.current.props, typeProp])

  useEffect(() => {
    if (typeProp === 'password') {
      setType(showPassword ? 'text' : 'password')
    } else if (typeProp === 'number') {
      setType('number')
    }
  }, [showPassword, typeProp])

  const handleOnMouseOver = useCallback(() => {
    setShowToolTip(true)
  }, [])

  const handleOnMouseOut = useCallback(() => {
    setShowToolTip(false)
  }, [])

  const handleOnKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'F2') {
      if (f2Content) {
        setF2ModalIsvisible(true)
      } else if (f2Function) {
        f2Function()
      }
    }
  }, [f2Content, f2Function])

  const F2Content = useCallback(() => {
    return React.cloneElement(f2Content as ReactElement, {
      close: () => setF2ModalIsvisible(false),
      callBack: f2CallBack
    })
  }, [f2CallBack, f2Content])

  const handleOnChangethrottle = useCallback((e) => {
    onChange && onChange(e)
  }, [onChange])

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select()
    setIsActive(true)
  }

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur()
    setIsActive(false)
  }

  const handleOnDoubleClick = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.preventDefault()
    if (copyValue && rest.value) {
      navigator.clipboard.writeText(rest.value.toString())
      Swal.fire({
        icon: 'success',
        title: 'Copiado!',
        position: 'top-end',
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true
      })
    }
  }

  return (
    <Wrapper className="wrapper-input" width={width} margin={margin} marginRight={marginRight}>
      {(!!label || (hasContent && !!label)) && <Label
        isActive={isActive || (!isActive && hasContent)}>
        {label}
        {copyValue && <FaCopy
          title={'Clique 2 vezes para copiar o campo'}
          onDoubleClick={handleOnDoubleClick} />}
      </Label>}
      <Container
        isActive={isActive}
        error={inputError}
        hasStartIcon={!!StartIcon}
        disabled={disabled}
        hasf2={!!f2Content}
        color={color}
        backgroundColor={backgroundColor}
      >
        {StartIcon && <Icon isActive={isActive} error={inputError} hasStartIcon={!!StartIcon}><StartIcon /></Icon>}

        {typeProp === 'currency'
          ? < IntlCurrencyInput
            ref={inputEl}
            {...rest}
            currency="BRL"
            config={currencyConfig}
            onChange={onInputCurrencyChance}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            value={rest.value}
            autoSelect={!disabled}
            disabled={disabled}
          />
          : <InputMask
            {...rest}
            disabled={disabled}
            type={type}
            mask={mask}
            onFocus={handleOnFocus}
            onKeyDown={handleOnKeyDown}
            onChange={typeProp === 'filter' ? handleOnChangethrottle : onChange}
            onBlur={handleOnBlur}
          >
            {(inputProps: any) => (
              <input
                ref={inputRef}
                {...rest}
                {...inputProps}

                onFocus={handleOnFocus}
                onKeyDown={handleOnKeyDown}
                onChange={typeProp === 'filter' ? handleOnChangethrottle : onChange}
                onBlur={handleOnBlur}
                disabled={disabled} />
            )}</InputMask>
        }

        {typeProp === 'password' && (
          <IconPassword
            showPassword={showPassword}
            inputError={!!inputError}
            onClick={() => setShowPassword(sp => !sp)}
          >
            <FaEye />
          </IconPassword>
        )}

        {!!inputError && (
          <IconError error={inputError} data-cy={'error' + rest.name} >
            <FaInfoCircle onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut} />
          </IconError>
        )}
        {showToolTip && !!error && <ToolTip>{error}</ToolTip>}
      </Container>

      {(!!f2Content && f2ModalIsvisible) &&
        <Modal mode={f2ModalMode} title={f2Title} close={() => setF2ModalIsvisible(false)} >{f2Content && <F2Content />} </Modal>}
    </Wrapper>
  )
}
