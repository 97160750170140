import { CancelarPedidoService } from '../contracts'
import { CancelarPedidoParams } from '../services'

export class CancelarPedido {
  private readonly cancelarPedidoService: CancelarPedidoService

  constructor (cancelarPedidoService: CancelarPedidoService) {
    this.cancelarPedidoService = cancelarPedidoService
  }

  async execute (params: CancelarPedidoParams): Promise<boolean> {
    if (!params?.id) {
      return false
    }

    let response = await this.cancelarPedidoService.cancelarPedido(params.id, params.token)

    if (response) {
      return true
    } else {
      return false
    }
  }
}
