import React, { useEffect, useState } from 'react'
import { formatFloatToCurrency } from '../../../helpers'

import { Wrapper, Container, Label, Bar, DivNumbers, NoContainer } from './styles'

interface BarProps {
  mensagem?: string
  label?: string,
  current?: number,
  total?: number
}

export const ProgressBar = ({ label, current, total, mensagem }: BarProps) => {
  const [percent, setPercent] = useState(0)

  useEffect(() => {
    if (current && total) {
      const calcPercent = (current * 100) / total

      setPercent(calcPercent)
    }
  }, [current, total])

  return (
    <Wrapper>
      <Label>{label}</Label>
      {(total)
        ? <Container>
          <Bar percent={percent}></Bar>
          <DivNumbers>
            <span>{formatFloatToCurrency(current || 0) + ' / ' + formatFloatToCurrency(total)}</span>
          </DivNumbers>
        </Container>
        : <NoContainer>
          {mensagem !== '' ? <span>{mensagem}</span> : <span>Dados não encontrados</span>}
        </NoContainer>
      }
    </Wrapper>
  )
}
