import styled from 'styled-components'

export const Container = styled.div`
  strong {
    display: flex;
    width: 100%;
    margin: 20px 0px 10px;
    font-size: 14px;
  }
`
