import { PostParams, Validator } from '../../_interfaces'
import { CadastrarChamadoService } from '../contracts'
import { Chamado } from '../models'

export class CadastrarChamado {
  private readonly cadastrarChamadoService: CadastrarChamadoService
  private readonly validator: Validator

  constructor (cadastrarChamadoService: CadastrarChamadoService, validator: Validator) {
    this.cadastrarChamadoService = cadastrarChamadoService
    this.validator = validator
  }

  async execute (postParams: PostParams): Promise<Chamado> {
    await this.validator.validate(postParams.body)
    const response = await this.cadastrarChamadoService.cadastrarChamado(postParams)
    return response
  }
}
