import styled from 'styled-components'
import { StatusPedido } from '../../../modules/pedidos/models'
import { fadein } from '../../styles/animations'

// type TextStatusPedidoProps = {
//   status: StatusPedido
// }

export const Container = styled.div`
  margin-top: 15px;
  animation: ${fadein} 0.6s forwards;

  header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 15px;
    >div{
      display: flex;
      /* width: 100%; */
      > button:first-of-type {
        margin-right: 20px;
      }
    }
  }
`
export const TotalVendas = styled.div`
  padding: 8px 0;
  font-size: 1.3em;
  font-weight: bold;
`

// export const TextStatusPedido = styled.span<TextStatusPedidoProps>`
//   display: flex;
//   justify-content: center;
//   font-weight: 600;
//   color: ${props => {
//     if (props.status === 'bloqueado') {
//       return props.theme.colors.danger
//     } else if (props.status === 'faturado') {
//       return props.theme.colors.success
//     } else {
//       return 'inherit'
//     }
//   }}
// `
