import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.border};
  margin-bottom: 15px;
`
export const BoxImg = styled.div`
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${props => props.theme.colors.borderLight};

  > button {
    width: 100%;
  }

  @media (max-width: 1100px) {
    & {
      width: 35%;
    }
  }
`

export const ImgDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content:center;
  width: 100%;
  border-right: 1px solid ${props => props.theme.colors.borderLight};
  margin-bottom: 10px;

  > img{
    width: auto;
    height: 112px;
  }
`
export const Content = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  font-size: 14px;
`
export const NameDiv = styled.div`
  display: flex;
  flex-wrap:wrap;
  width: 100%;
  margin-bottom: 5px;

  > div{
    width: 100% !important;
    margin-bottom: 5px;
  }
`
export const InfosDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`

export const InfoGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
`

export const ActionsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  margin-top: 15px;
  gap: 10px;

  > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`

interface InfoDivProps {
  width?: string
}

export const InfoDiv = styled.div<InfoDivProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => props.width ? props.width : '100%'};

  > span:first-of-type{
    font-weight:bold;
    margin-right: 5px;
  }

  @media (max-width: 1100px) {
    & {
      width: 100%;
      flex-direction: column;

      > span {
        width: 100%;
        word-break: break-all;
      }
    }
  }
`
