import { useEffect, useState } from 'react'
import { useUsuario } from '..'
import { makeTrazerFuncionarioPorId } from '../../../modules/funcionarios/factories/trazer-funcionario-por-id'
import { GetFuncionario } from '../../../modules/funcionarios/models'
import { useHandleErrors } from '../app/useHandleErrors'

export function useFuncionario (funcionarioId: number) {
  const { data: usuarioData } = useUsuario()
  const [isLoading, setIsLoading] = useState(false)
  const { handleError, executing } = useHandleErrors()
  const [data, setData] = useState<GetFuncionario>()

  useEffect(() => {
    async function fetcher () {
      const trazerFuncionarioPorId = makeTrazerFuncionarioPorId()
      setIsLoading(true)

      const response = await trazerFuncionarioPorId.execute({
        filter: funcionarioId as unknown as string,
        token: usuarioData?.token
      })

      setData(response)
      setIsLoading(false)
      if (response.error && !executing) {
        handleError(response.error)
      }
    }

    funcionarioId && fetcher()
  }, [executing, funcionarioId, handleError, usuarioData])

  return { data, isLoading }
}
