import React, { createContext, useCallback, useContext, useState } from 'react'

interface SideBarContextState {
  isVisible: boolean
  toogle (): void
  isMobile: boolean
}

const SideBarContext = createContext<SideBarContextState>({} as SideBarContextState)

export const useSideBar = (): SideBarContextState => {
  const context = useContext(SideBarContext)

  if (!context) {
    throw new Error('useSideBar deve ser usado com SideBarContext')
  }

  return context
}

export const SideBarProvider: React.FC = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 720)

  const toogle = useCallback(() => {
    setIsVisible(state => !state)
  }, [])

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 720)
  }

  window.addEventListener('resize', handleResize)

  return (
    <SideBarContext.Provider value={{ isVisible, toogle, isMobile }} >
      {children}
    </SideBarContext.Provider>
  )
}
