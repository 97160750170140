import { TrazerEstoqueAlternativoService } from '../contracts'
import { GetEstoqueAlternativoResponse, TrazerEstoqueAlternativoParams } from '../models'

export class TrazerEstoqueAlternativo {
  private readonly estoqueAlternativoService: TrazerEstoqueAlternativoService

  constructor (estoqueAlternativoService: TrazerEstoqueAlternativoService) {
    this.estoqueAlternativoService = estoqueAlternativoService
  }

  async execute (params: TrazerEstoqueAlternativoParams): Promise<GetEstoqueAlternativoResponse> {
    return await this.estoqueAlternativoService.trazerEstoqueAlternativo(params)
  }
}
