import { PostParams } from '../../_interfaces'
import { RegistrarUsuarioService } from '../contracts'
import { RegistroResp } from '../models/usuario'

export class RegistrarUsuario {
  private readonly registrarUsuarioService: RegistrarUsuarioService

  constructor (registrarUsuarioService: RegistrarUsuarioService) {
    this.registrarUsuarioService = registrarUsuarioService
  }

  async execute (params: PostParams): Promise<RegistroResp> {
    return await this.registrarUsuarioService.registrarUsuario(params)
  }
}
