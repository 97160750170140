import { useSelector } from 'react-redux'
import React from 'react'
import { ModePedido, Table, TableColumnProps } from '..'
import { Cliente } from '../../../modules/clientes/models'
import { Container, Label, Wrapper } from './styles'
import { AtendimentoType } from '../../store/atendimentos/interfaces'
import { AppState } from '../../store'
import { TableText } from '../../styles/global'

interface TransferenciasProps {
  cliente: Cliente
  mode: ModePedido
  orcamentoIdEmEdicao?: number
}

export const Transferencias = ({ cliente, orcamentoIdEmEdicao }: TransferenciasProps) => {
  const atendimentoCliente = useSelector<AppState>(state => state.atendimentos.data[cliente.id as number]) as AtendimentoType
  const emEdicao = atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.emEdicao
  const original = atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original

  const columns: TableColumnProps[] = [
    {
      Header: 'Data',
      accessor: 'data',
      Cell: ({ value }) => {
        return <TableText position='center'>{value}</TableText>
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => {
        return <TableText position='center'>{value}</TableText>
      }
    },
    {
      Header: 'Produto',
      accessor: 'produto_id',
      Cell: ({ value }) => {
        return <TableText position='right'>{value}</TableText>
      }
    },
    {
      Header: 'Quantidade',
      accessor: 'quantidade',
      Cell: ({ value }) => {
        return <TableText position='right'>{value}</TableText>
      }
    }
  ]

  const transferencias = () => {
    if (emEdicao.transferencias?.length) {
      return emEdicao.transferencias
    } else if (original.transferencias?.length) {
      return original.transferencias
    } else {
      return []
    }
  }

  return (
    <Wrapper>
      <Label>Transferências</Label>
      <Container>
        <Table
          columns={columns}
          showPageJump={false}
          data={transferencias()}
          loading={!transferencias()}
          pageSize={10}
          showPageSizeOptions={false}
          noDataText='Nenhuma transferência vinculada'
          showPagination={false}
        />
      </Container>
    </Wrapper>
  )
}
