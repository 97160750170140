/* eslint-disable react-hooks/exhaustive-deps */
import capitalize from 'capitalize-pt-br'
import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Cliente } from '../../../modules/clientes/models'
import { BodyCardList, Container, Header } from './styles'
import { Modal } from '../Modal'
import { useListaPrecosProdutos, useProdutos, useUsuario } from '../../hooks'
import { ModePedido } from '../Pedido'
import { ListaCardProdutos } from '../ListaCardProdutos'
import { GetParams } from '../../../modules/_interfaces'
import { FilterOptions } from '../../../modules/_interfaces/filterOptions'
import InfiniteScroll from 'react-infinite-scroll-component'
import { InfiniteScrollMessage } from '../InfiniteScrollMessage'
import { FaSearch } from 'react-icons/fa'
import { Input, CheckBox, InputF2, Loading } from '..'
import { Preco, Produto } from '../../../modules/produtos/models'
import { setarPesquisaProduto } from '../../store/produtos/actions'
import { ProdutosState } from '../../store/produtos/interfaces'
import { AppState } from '../../store'
import { GetPromocoesParams, Promocao } from '../../../modules/promocoes/models'
import { usePromocoes } from '../../hooks/promocoes'
import { AtendimentosState } from '../../store/atendimentos/interfaces'
import { Item } from '../../../modules/pedidos/models'
import { removerAcento } from '../../../helpers/removerAcentos'
import { AiOutlineColumnHeight, AiOutlineColumnWidth } from 'react-icons/ai'
import { AdicionarProduto } from '../Pedido/Produtos/AdicionarProduto'
import { DetalhePromocao } from '../Pedido/Produtos/DetalhePromocao'
import Swal from 'sweetalert2'
// import { DetalhePromocao } from '../Pedido/Produtos/DetalhePromocao'

const produtosLimit = 15

interface ListaProdutosProps extends InputF2 {
  cliente: Cliente
  revenda?: Cliente
  mode?: ModePedido
  pedidoIdEmEdicao?: number
  afterInsertItemOrcamento: () => void
  fakeItemId: number
  setFakeItemId: React.Dispatch<React.SetStateAction<number>>
  produtoSelected: Item
  setProdutoSelected: React.Dispatch<React.SetStateAction<Item>>
  handleAddItem: () => void
  setPromocaoSelected: React.Dispatch<React.SetStateAction<Item[]>>
  handleAddPromocaoOrcamento: () => void
}

export const ListaProdutos = ({
  cliente,
  revenda,
  mode = 'register',
  pedidoIdEmEdicao,
  fakeItemId,
  produtoSelected,
  setProdutoSelected,
  handleAddItem,
  handleAddPromocaoOrcamento,
  setPromocaoSelected,
  setFakeItemId
}: ListaProdutosProps) => {
  const dispatch = useDispatch()
  // const { handleError } = useHandleErrors()
  const produtoState = useSelector<AppState>((state) => state.produtos) as ProdutosState
  const atendimentosState = useSelector<AppState>((state) => state.atendimentos) as AtendimentosState
  const pedidoEmAndamento = atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento

  const { data: usuario } = useUsuario()

  const [searchText, setSearchText] = useState(produtoState.pesquisa)

  const [showModalPromocao, setShowModalPromocao] = useState(false)
  const [showModalPreco, setShowModalPreco] = useState(false)
  const [isCardRow, setIsCardRow] = useState<boolean>(true)
  const [isCardColumn, setIsCardColumn] = useState<boolean>(false)

  const [addProdutoId, setAddProdutoId] = useState<number>()

  const [disableButtonAdicionar, setDisableButtonAdicionar] = useState(false)

  const [produtoCompletoList, setProdutoCompletoList] = useState<Produto[]>([])

  const solar = useMemo(() => {
    if (mode === 'register') {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.solar
    } else {
      const depositoEdicao =
        atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number].emEdicao?.solar
      const depositoOriginal =
        atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number].original?.solar
      return depositoEdicao || depositoOriginal || 'n'
    }
  }, [atendimentosState])

  const usoeconsumo = useMemo(() => {
    if (mode === 'register') {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.usoeconsumo || 'n'
    } else {
      const depositoEdicao =
        atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number].emEdicao?.usoeconsumo
      const depositoOriginal =
        atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number].original?.usoeconsumo
      return depositoEdicao || depositoOriginal || 'n'
    }
  }, [atendimentosState])

  const deposito = useMemo(() => {
    if (mode === 'register') {
      return pedidoEmAndamento?.pedido?.deposito
    } else {
      const depositoEdicao = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number].emEdicao?.deposito
      const depositoOriginal = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number].original?.deposito
      return depositoEdicao || depositoOriginal
    }
  }, [atendimentosState])

  const [produtosParams, setProdutosParams] = useState(() => {
    let params: GetParams = {
      filter: produtoState.pesquisa.toLowerCase(),
      filterOptions: {
        skip: 0,
        limit: produtosLimit
      },
      filterObject: {
        'produtos.status': 'ativo',
        somente_promocoes: 'n',
        somente_com_estoque: 'n',
        funcionario_id: usuario?.funcionario_id,
        empresa_id: deposito?.id
      }
    }

    if (usuario?.token) {
      params.token = usuario.token
    }

    return params
  })

  const { produtosList, produtosListHasMore, produtosListLoading, produtosListMetadata } = useProdutos(produtosParams)

  const [promocaoParams, setPromocaoParams] = useState(() => {
    let params: GetPromocoesParams = {
      body: {
        produtos: []
      },
      filterObject: {
        funcionario_id: usuario?.funcionario_id,
        uf: deposito?.uf,
        status: 'em aberto',
        uf_cliente: cliente.uf,
        cliente_id: cliente.id,
        solar: solar
      }
    }
    if (usuario?.token) {
      params.token = usuario.token
    }
    return params
  })

  const [precoParams, setPrecoParams] = useState({
    produtos: [] as number[],
    empresas: [deposito?.id] as number[],
    clienteId: cliente.id as number,
    solar,
    usoeconsumo
  })

  const { promocoesList } = usePromocoes(promocaoParams)
  const { fetcher: getListaPrecoProdutos, data: precosList } = useListaPrecosProdutos()

  useEffect(() => {
    setProdutoCompletoList(produtosList)
    if (produtosList && produtosList.length > 0) {
      let listId: number[] = produtosList.map((element) => element.id as number)

      setPromocaoParams((old) => ({
        ...old,
        body: {
          produtos: listId
        }
      }))

      let newPrecoParams = {
        ...precoParams,
        produtos: listId
      }

      setPrecoParams(newPrecoParams)
      getListaPrecoProdutos(newPrecoParams)
    }
  }, [produtosList])

  useEffect(() => {
    if (produtosList && produtosList.length > 0) {
      const lista = produtosList.map((produto) => {
        if (promocoesList && promocoesList.length > 0) {
          let promocao: Promocao[] = []
          promocoesList.forEach((item) => {
            if (item.produto_id === produto.id) {
              promocao.push(item)
            }
          })
          produto.promocao = promocao
        }

        if (precosList && precosList.length > 0) {
          precosList.forEach((preco, index) => {
            if (preco.produto_id === produto.id) {
              produto.preco = preco
            }
          })
        }
        return produto
      })
      setProdutoCompletoList(lista)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promocoesList, precosList])

  const handleChangeSearch = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && !produtosListLoading) {
        if (searchText === '' || searchText === ' ') {
          Swal.fire(
            'Atenção',
            'A busca não pode ser vazia',
            'info'
          )

          return
        }

        const newFilterOptions: FilterOptions = {
          ...produtosParams.filterOptions,
          skip: 0
        }

        const newSearch = removerAcento(searchText)

        setProdutosParams((old) => ({
          ...old,
          filterOptions: newFilterOptions,
          filter: newSearch
        }))
        dispatch(setarPesquisaProduto(searchText))
      }
    },
    [searchText, produtosParams, setProdutosParams, produtosListLoading]
  )

  const handleFilterOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value)
    }, [])

  const produtosFetchMore = () => {
    setProdutosParams((old) => {
      let skip = 0

      if (
        produtosListMetadata?.skip !== undefined &&
        produtosListMetadata?.count !== undefined &&
        produtosListMetadata?.limit
      ) {
        skip = produtosListMetadata?.skip + produtosListMetadata?.limit
      }

      return {
        ...old,
        filterOptions: {
          limit: produtosLimit,
          skip
        }
      }
    })
  }

  const infiniteScrollEndMessage = useMemo(() => {
    let text = 'Não há mais produtos'

    if (!produtosList?.length) {
      if (produtosListLoading) {
        text = ''
      } else {
        text = 'Nenhum produto encontrado'
      }
    }
    return <InfiniteScrollMessage text={text} />
  }, [produtosList, produtosListLoading])

  // const temFreteValor = useCallback(() => {
  //   let retorno = false
  //   if (mode === 'register') {
  //     if (
  //       pedidoEmAndamento &&
  //       (pedidoEmAndamento.pedido.frete_cotacao ||
  //         pedidoEmAndamento.pedido.frete_valor)
  //     ) {
  //       retorno = true
  //     }
  //   } else {
  //     if (
  //       pedidoEmAberto &&
  //       (pedidoEmAberto.original.frete_cotacao ||
  //         pedidoEmAberto.original.frete_valor ||
  //         pedidoEmAberto.emEdicao.frete_cotacao ||
  //         pedidoEmAberto.emEdicao.frete_valor)
  //     ) {
  //       retorno = true
  //     }
  //   }

  //   return retorno
  // }, [mode, pedidoEmAberto, pedidoEmAndamento])

  const handleIsPrecoDefinido = (isPrecoDefinido: boolean) => {
    setDisableButtonAdicionar(isPrecoDefinido)
  }

  const handleCloseModal = (text: string) => {
    if (text === 'promocao') {
      setShowModalPromocao(false)
    } else if (text === 'produto') {
      setShowModalPreco(false)
    }
    setDisableButtonAdicionar(false)
  }

  const handleChangePromocao = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (produtosListLoading) {
      return
    }

    if (e.target.checked) {
      setProdutosParams((old: GetParams) => {
        const newParams = {
          ...old,
          filterObject: { ...old.filterObject, somente_promocoes: 's' }
        }
        return newParams
      })
    } else {
      setProdutosParams((old: GetParams) => {
        const newParams = {
          ...old,
          filterObject: { ...old.filterObject, somente_promocoes: 'n' }
        }
        return newParams
      })
    }
  }

  const checkedPromocao = () => {
    if (produtosParams && produtosParams.filterObject) {
      if (produtosParams.filterObject.somente_promocoes === 's') {
        return true
      }
    } else {
      return false
    }
  }

  const handleClickPromocao = () => {
    setShowModalPreco(false)
    setShowModalPromocao(true)
  }

  const handleCallbackPreco = (produto: Produto) => {
    if (produto) {
      setAddProdutoId(produto.id)
      setShowModalPreco(true)
    }
  }

  const handleCallbackPromocao = (produto: Produto) => {
    if (produto) {
      let item: Item = {
        id: fakeItemId * -1,
        acrescimo: 0,
        desconto: 0,
        valorUnitario: produto.preco?.valor || 0,
        valor: produto.preco?.valor,
        quantidade: 1,
        stTotal: produto.preco?.st || 0,
        ipiTotal: produto.preco?.ipi || 0,
        total: produto.preco?.valor || 0,
        produto: produto,
        preco: produto.preco as Preco,
        falta_liberar: 1
      }
      setProdutoSelected(item)
      setShowModalPromocao(true)
    }
  }

  return (
    <Container>
      {produtosListLoading && <Loading />}
      <Header>
        <div>
          <Input
            label="Pesquisa:"
            type="filter"
            startIcon={FaSearch}
            onChange={handleFilterOnChange}
            onKeyPress={handleChangeSearch}
            value={searchText}
            autoFocus={true}
            placeholder="Digite um texto ou codigo do produto"
          />
          <div>
            <button title='Linhas' onClick={() => {
              setIsCardRow(true)
              setIsCardColumn(false)
            }}>
              <AiOutlineColumnWidth color="white" size={18} />
            </button>
            <button title='Colunas' onClick={() => {
              setIsCardColumn(true)
              setIsCardRow(false)
            }}>
              <AiOutlineColumnHeight color="white" size={18} />
            </button>
          </div>
        </div>
        <div>
          <CheckBox
            label="Somente Promoções:"
            onChange={handleChangePromocao}
            checked={checkedPromocao()}
          />
        </div>
      </Header>
      <BodyCardList>
        <InfiniteScroll
          dataLength={produtosList.length || 0}
          endMessage={infiniteScrollEndMessage}
          next={produtosFetchMore}
          hasMore={produtosListHasMore}
          loader=""
          scrollableTarget="modalContent"
        >
          {
            <ListaCardProdutos
              data={produtoCompletoList}
              mode={mode}
              cliente={cliente}
              handleSelectProduto={handleCallbackPreco}
              handleSelectPromocao={handleCallbackPromocao}
              isLoading={produtosListLoading}
              pedidoIdEmEdicao={pedidoIdEmEdicao}
              cardMode="adicionar"
              isCardRow={isCardRow}
              isCardColumn={isCardColumn}
            />
          }
        </InfiniteScroll>
      </BodyCardList>
      {showModalPromocao && (
        <Modal
          title={'Promoções de ' + capitalize('')}
          close={() => handleCloseModal('promocao')}
          showButtonSave={disableButtonAdicionar}
          buttonSaveText="Adicionar"
          onSave={handleAddPromocaoOrcamento}
          mode="wide"
        >
          <DetalhePromocao
            cliente={cliente}
            revenda={revenda}
            produto={produtoSelected.produto}
            mode={mode}
            setPromocaoSelected={setPromocaoSelected}
            pedidoIdEmEdicao={pedidoIdEmEdicao}
            isPrecoDefininido={handleIsPrecoDefinido}
            depositoId={deposito?.id}
            fakeItemId={fakeItemId}
            solar={solar}
          />
        </Modal>
      )}
      {showModalPreco && (
        <Modal
          title={capitalize('')}
          close={() => handleCloseModal('produto')}
          showButtonSave={disableButtonAdicionar}
          buttonSaveText="Adicionar"
          onSave={handleAddItem}
          mode='wide'
        >
          <AdicionarProduto
            cliente={cliente}
            revenda={revenda}
            mode={mode}
            produtoId={addProdutoId}
            pedidoIdEmEdicao={pedidoIdEmEdicao}
            handleAddItem={handleAddItem}
            setProdutoSelected={setProdutoSelected}
            handleIsPrecoDefinido={handleIsPrecoDefinido}
            fakeItemId={fakeItemId}
            handleClickPromocao={handleClickPromocao}
            setFakeItemId={setFakeItemId}
          />
        </Modal>
      )}
    </Container>
  )
}
