import styled from 'styled-components'
import { fadein } from '../../styles/animations'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  width: 100%;
  margin-right: 15px;
  animation: ${fadein} 0.6s forwards;
`

export const HeaderAtendimento = styled.div`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  min-height: 65px;
  background: transparent;
  align-items: center;
  padding: 15px 0px;
`

export const ContentHeaderAtendimento = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  > div {
    display: flex;

    > button:first-of-type{
      margin-right: 7px;
    }

    > button + button {
      margin-right: 7px;
    }

    > button:last-of-type{
      margin-right: 0px;
    }
  }

  @media (max-width: 1300px) {
    & {
      flex-direction: column;
      gap: 20px;

      div:nth-child(2) {
        justify-content: flex-end;
      }
    }
  }

  @media (max-width: 700px) {
    & {
      > div {
        flex-direction: column;
        gap: 15px;

        > button:first-of-type{
          margin-right: 0px;
        }

        > button + button {
          margin-right: 0px;
        }
      }
    }
  }
`
