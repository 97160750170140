import { handleErrors } from '../../../helpers/handleErrors'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { BloquearDescontoService } from '../contracts'

export interface bloquearDescontoParams {
  token: string
  id: number
}

export class BloquearDescontoServiceRest implements BloquearDescontoService {
  private readonly http: HttpRequest

  constructor (http: HttpRequest) {
    this.http = http
  }

  async bloquearDesconto (pedidoId: number, token: string): Promise<boolean> {
    const response = await this.http.put({
      path: `pedidos/${pedidoId}/bloquear_desconto`,
      token
    })

    handleErrors(response?.error)
    return response?.status === 204
  }
}
