import { useState } from 'react'
import { useUsuario } from '..'
import { makeTrazerPedidoPorId } from '../../../modules/pedidos/factories'
import { GetPedidoResponse } from '../../../modules/pedidos/models'
import { useHandleErrors } from '../app/useHandleErrors'

const trazerPedidoPorId = makeTrazerPedidoPorId()

interface Error {
  status?: number,
  message?: string
}

export function usePedido () {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<GetPedidoResponse>()
  const { data: usuarioData } = useUsuario()
  const { handleError } = useHandleErrors()

  async function fetcher (pedidoId?: number) {
    setLoading(true)
    const params = {
      filterObject: {
        id: pedidoId
      },
      token: usuarioData?.token
    }
    const resp = await trazerPedidoPorId.execute(params)
    if (resp && resp.data !== undefined) {
      setData(resp)
      setLoading(false)
      return resp
    } else {
      handleError(resp.error as Error)
      setLoading(false)
    }
  }

  return { fetcher, data, loading }
}
