import { GetParams, UseCase } from '../../_interfaces'
import { TrazerPedidoPorIdService } from '../contracts'
import { GetPedidoResponse } from '../models'

export class TrazerPedidoPorId implements UseCase<GetParams, GetPedidoResponse> {
  private readonly trazerPedidoPorIdService: TrazerPedidoPorIdService

  constructor (trazerPedidoPorIdService: TrazerPedidoPorIdService) {
    this.trazerPedidoPorIdService = trazerPedidoPorIdService
  }

  async execute (params: GetParams): Promise<GetPedidoResponse> {
    return await this.trazerPedidoPorIdService.trazerPedidoPorId(params)
  }
}
