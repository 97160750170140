import { GetParams } from '../../_interfaces'
import { ListarContatosClienteService } from '../contracts'
import { GetContatosResponse } from '../models'

export class ListarContatos {
  private readonly listarContatosClienteService: ListarContatosClienteService

  constructor (listarContatosClienteService: ListarContatosClienteService) {
    this.listarContatosClienteService = listarContatosClienteService
  }

  async execute (params: GetParams): Promise<GetContatosResponse> {
    const response = await this.listarContatosClienteService.listarContatosCliente(params)
    return response
  }
}
