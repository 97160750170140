import capitalize from 'capitalize-pt-br'
import React, { useEffect, useMemo } from 'react'
import Table from 'react-table-6'
import { TableColumnProps } from '..'
import { usePedido } from '../../hooks'
import { TableText } from '../../styles/global'

import { Container } from './styles'

interface ResumoProps {
  pedidoId?: number
}

export const ResumoPedido = (props: ResumoProps) => {
  const { fetcher: getPedido, data } = usePedido()
  useEffect(() => {
    getPedido(props.pedidoId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: TableColumnProps[] = useMemo(() => {
    const result: TableColumnProps[] = [
      {
        Header: '#',
        minWidth: 15,
        Cell: ({ row }) => <TableText position='right'>{row._original.produto.id}</TableText>
      },
      {
        Header: 'Descrição',
        Cell: ({ row }) => <TableText position='left'>
          {row._original.produto.nome_popular === ''
            ? capitalize(row._original.produto.nome_popular)
            : capitalize(row._original.produto.nome_tecnico)
          }
        </TableText>
      },
      {
        Header: 'Qtde',
        accessor: 'quantidade',
        minWidth: 20,
        Cell: ({ value }) => <TableText position='right'>{value}</TableText>
      },
      {
        Header: 'Falta liberar',
        minWidth: 20,
        Cell: ({ row }) => <TableText position='right'>{row._original.falta_liberar}</TableText>
      }
    ]

    return result
  }, [])

  return (
    <Container>
      <Table
        columns={columns}
        showPageJump={false}
        data={data?.data?.itens}
        page={0}
        sortable={false}
        nextText="Próximo"
        previousText="Anterior"
        pageText="Página"
        ofText="de"
        showPageSizeOptions={false}
        noDataText="Nenhum produto no orçamento"
        manual={false}
        showPagination={false}
      />
    </Container>
  )
}
