import React, { ReactNode, SelectHTMLAttributes, useRef, useState } from 'react'

import { Wrapper, Container, Label } from './styles'

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  children?: ReactNode
  width?: string
  title?: string
  margin?: string
  marginRight?: string
  error?: string
  onBlur?: () => void
}

export const Select = ({ children, width, title, margin, marginRight, error, onBlur, ...rest }: SelectProps) => {
  const [isActive, setIsActive] = useState(false)
  const selectRef = useRef<HTMLSelectElement>({} as HTMLSelectElement)

  const handleOnBlur = () => {
    onBlur && onBlur()
    setIsActive(false)
  }

  return (
    <Wrapper width={width} margin={margin} marginRight={marginRight}>
      {title && <Label isActive={isActive}>{title}</Label>}
      <Container isActive={isActive} error={error}>
        <select
          title={error}
          {...rest} ref={selectRef}
          onFocus={() => setIsActive(true)}
          onBlur={handleOnBlur}
        >
          {children}
        </select>
      </Container>
    </Wrapper>
  )
}
