import { PostParams, UseCase, Validator } from '../../_interfaces'
import { AtualizarPedidoService } from '../contracts'
import { Orcamento } from '../models'
import { sanetizarPedidoAtualizacao } from '../services/helpers'

export class AtualizarPedido implements UseCase<PostParams, Orcamento> {
  private readonly atualizarPedidoService: AtualizarPedidoService
  private readonly validator: Validator

  constructor (atualizarPedidoService: AtualizarPedidoService, validator: Validator) {
    this.atualizarPedidoService = atualizarPedidoService
    this.validator = validator
  }

  async execute (params: PostParams): Promise<Orcamento> {
    const sanetized = sanetizarPedidoAtualizacao(params.body as Orcamento)

    // await this.validator.validate(sanetized)
    return await this.atualizarPedidoService.atualizarPedido({
      token: params.token,
      body: sanetized
    })
  }
}
