import styled from 'styled-components'
import { fadein } from '../../styles/animations'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  animation: ${fadein} 0.6s forwards;
`

export const Main = styled.div`
  display: flex;
  flex: 1;
  padding: 1px 15px 0px 0px;
  flex-direction: column;
`
export const Contato = styled.div`


`
