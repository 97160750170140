import { PostParams, UseCase, Validator } from '../../_interfaces'
import { DesmembarPedidoService } from '../contracts'
import { Orcamento } from '../models'
import { sanetizarPedidoAtualizacao } from '../services/helpers'

export class DesmembrarPedido implements UseCase<PostParams, Orcamento> {
  private readonly desmembarPedidoService: DesmembarPedidoService
  private readonly validator: Validator

  constructor (desmembrarPedidoService: DesmembarPedidoService, validator: Validator) {
    this.desmembarPedidoService = desmembrarPedidoService
    this.validator = validator
  }

  async execute (params: PostParams): Promise<Orcamento> {
    const sanetized = sanetizarPedidoAtualizacao(params.body as Orcamento)

    // await this.validator.validate(sanetized)
    return await this.desmembarPedidoService.desmembrarPedido({
      token: params.token,
      body: sanetized
    })
  }
}
