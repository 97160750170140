import { useSelector } from 'react-redux'
import React from 'react'
import { ModePedido, Table, TableColumnProps } from '..'
import { Cliente } from '../../../modules/clientes/models'
import { Container, Label, Wrapper } from './styles'
import { AtendimentoType } from '../../store/atendimentos/interfaces'
import { AppState } from '../../store'
import { TableText } from '../../styles/global'
import { formatFloatToCurrency } from '../../../helpers'

interface ParcelasProps {
  cliente: Cliente
  mode: ModePedido
  orcamentoIdEmEdicao?: number
}

export const Parcelas = ({ cliente, orcamentoIdEmEdicao }: ParcelasProps) => {
  const atendimentoCliente = useSelector<AppState>(state => state.atendimentos.data[cliente.id as number]) as AtendimentoType
  const emEdicao = atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.emEdicao
  const original = atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original

  const columns: TableColumnProps[] = [
    {
      Header: 'N°',
      accessor: 'numero',
      Cell: ({ value }) => {
        return <TableText position='right'>{value}</TableText>
      }
    },
    {
      Header: 'Vencimento',
      accessor: 'vencimento',
      Cell: ({ value }) => {
        return <TableText position='center'>{value}</TableText>
      }
    },
    {
      Header: 'Valor',
      accessor: 'valor',
      Cell: ({ value }) => {
        return <TableText position='right'>{formatFloatToCurrency(value)}</TableText>
      }
    },
    {
      Header: 'Tipo Pagamento',
      accessor: 'tipo',
      Cell: ({ value }) => {
        return <TableText position='left'>{value}</TableText>
      }
    },
    {
      Header: 'Banco',
      accessor: 'banco',
      Cell: ({ value }) => {
        return <TableText position='left'>{value}</TableText>
      }
    }
  ]

  const parcelas = () => {
    if (emEdicao.parcelas?.length) {
      return emEdicao.parcelas
    } else if (original.parcelas?.length) {
      return original.parcelas
    } else {
      return []
    }
  }

  return (
    <Wrapper>
      <Label>Parcelas</Label>
      <Container>
        <Table
          columns={columns}
          showPageJump={false}
          data={parcelas()}
          loading={!parcelas()}
          pageSize={parcelas().length}
          showPageSizeOptions={false}
          noDataText='Não foi gerado Parcelas'
          showPagination={false}
        />
      </Container>
    </Wrapper>
  )
}
