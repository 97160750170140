// import { handleErrors } from '../../../helpers/handleErrors'
import { HttpRequest, PostParams } from '../../_interfaces'
import { ValidarFreteGratisPedidoService } from '../contracts'

export class ValidarFreteGratisPedidoServiceRest implements ValidarFreteGratisPedidoService {
  private readonly http: HttpRequest

  constructor (http: HttpRequest) {
    this.http = http
  }

  async validarFreteGratis (params: PostParams): Promise<any> {
    const { pedidoId } = params.body
    const response = await this.http.post({
      path: `pedidos/${pedidoId}/validar_frete_gratis`,
      token: params.token,
      body: {}
    })

    // handleErrors(response?.error)
    return response?.status === 204
  }
}
