/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { ImageZoom } from '../ImageZoom'
import noImage from '../../assets/imgs/noImage.jpg'

type CarouselProps = {
  imageSrc?: string[];
}

export const Carousel = ({ imageSrc }: CarouselProps) => {
  return (
    <OwlCarousel className="owl-theme" items={1} loop autoPlay>
      {imageSrc?.reverse().map((url) => (
        <ImageZoom key={url}>
          <div className="item" style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={url} alt="" onError={(e) => { e.currentTarget.src = noImage }} style={{ width: '250px', height: '250px' }} />
          </div>
        </ImageZoom>
      ))}
    </OwlCarousel>
  )
}
