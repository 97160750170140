import styled from 'styled-components'
import { fadein } from '../../styles/animations'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 500px;
  width: 100%;
  margin-right: 15px;
  animation: ${fadein} 0.6s forwards;

  header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    height: 45px;

    > div {
      display: flex;
      width: 300px;
    }

    button + button {
      margin-left: 7px;
    }
  }

  .ReactTable{
    margin-top: -1px;
  }
`

export const Content = styled.div`
  margin-top: -1px;
  border: 1px solid ${props => props.theme.colors.border};
  padding: 10px;
  border-radius: 4px;
  border-top-left-radius: 0px;
`

export const PedidosAbertos = styled.div`
  margin-right: 30px;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
`

export const ResumoPedido = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`
export const HeaderTablePedidos = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;

  >div{
    display: flex;
    width: 100%;

    > button:first-of-type {
      margin-right: 15px;
    }
  }
  >div:last-of-type{
    position: relative;
    justify-content: flex-end;
    margin-top: 15px;
  }
`
export const Checkboxes = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 40px;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`
export const TabFixaPedidos = styled.div`
  animation: ${fadein} 600ms forwards;
`
