import React, { ChangeEvent, TextareaHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react'

import { Wrapper, Container, Label } from './styles'

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  error?: string
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  width?: string
  height?: string
  marginRight?: string
  enableAutoHeight?: boolean
}

export const Textarea = ({ label, error, onChange, width, height, marginRight, disabled, enableAutoHeight, ...rest }: TextAreaProps) => {
  const [isActive, setIsActive] = useState(false)
  const [hasContent, setHasContent] = useState(false)
  const [inputError, setInputError] = useState('')
  const [autoHeight, setAutoHeight] = useState<string>()
  const textAreaInputRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textAreaInputRef.current) {
      if (enableAutoHeight && autoHeight === undefined) {
        setAutoHeight(`${textAreaInputRef.current.scrollHeight}px`)
      }
    }
  }, [])

  const handleOnChangethrottle = useCallback((e) => {
    if (e.target.scrollHeight) {
      const scrollHeight = e.target.scrollHeight
      setAutoHeight(`${scrollHeight}px`)
    }

    if (e.target.value.length > 0) {
      setHasContent(true)
    } else {
      setHasContent(false)
    }
    onChange && onChange(e)
  }, [onChange])

  useEffect(() => {
    setInputError(error as string)
  }, [error])

  return (
    <Wrapper>
      {(!!label || (hasContent && !!label)) && <Label isActive={isActive || (!isActive && hasContent)}>{label}</Label>}
      <Container
        isActive={isActive}
        error={inputError}
        disabled={disabled}
        height={enableAutoHeight && autoHeight !== undefined ? autoHeight : height}
      >
        <textarea
          {...rest}
          onFocus={() => { !disabled && setIsActive(true) }}
          onBlur={() => setIsActive(false)}
          onChange={handleOnChangethrottle}
          className='textAreaInput'
          ref={textAreaInputRef}
        />
      </Container>
    </Wrapper>
  )
}
