import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeTrazerOcorrencias } from '../../../modules/clientes/factories'
import { GetOcorrenciasResponse } from '../../../modules/clientes/models'
import { useUsuario } from '../usuario/useUsuario'

const trazerOcorrencias = makeTrazerOcorrencias()

export function useOcorrencias (perPage = 20, currentPage = 1, search = '', cliente_id: number, order = '-created_at') {
  const { data: usuarioData } = useUsuario()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<GetOcorrenciasResponse>()
  const [error, setError] = useState<any>()
  const [isReloading, setIsReloading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    async function fetcher () {
      setLoading(true)
      try {
        const response = await trazerOcorrencias.execute({
          filterOptions: {
            limit: perPage,
            skip: (currentPage - 1) * perPage,
            order: order || '-ocorrencias.updated_at'
          },
          token: usuarioData?.token,
          cliente_id
        })

        setData(response)
        setIsReloading(false)
        setLoading(false)
      } catch (error) {
        setError(error)
        setIsReloading(false)
        setLoading(false)
      }
    }

    fetcher()
  }, [cliente_id, currentPage, order, perPage, usuarioData])

  const reload = useCallback(() => {
    setIsReloading(true)
  }, [])

  if (error?.type === 'auth') {
    history.push('/login')
  }

  return { data, error, reload, loading, isReloading }
}
