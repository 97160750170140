import React, { ChangeEvent, useCallback } from 'react'
import { FaEnvelope, FaMarker } from 'react-icons/fa'
import { Input, CheckBox, Select } from '..'
import { Contato } from '../../../modules/clientes/models'
import { FormRow } from '../../styles/global'
import { Container } from './styles'

interface CadastroContatoProps {
  contato?: Contato
  setFormContato: React.Dispatch<React.SetStateAction<Contato>>
}

export const CadastroContato = ({ contato, setFormContato }: CadastroContatoProps) => {
  const handleInputOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value

    if (e.target.type === 'checkbox') {
      value = e.target.checked ? 's' : 'n'
    }

    const key = e.target.name as keyof Contato

    const newContatoState = {
      ...contato,
      [key]: value
    }

    setFormContato(newContatoState as Contato)
  }, [contato, setFormContato])

  const handleSelectOnChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    let value = e.target.value

    const newContatoState = {
      ...contato,
      status: value
    }

    setFormContato(newContatoState as Contato)
  }, [contato, setFormContato])

  return (
    <>
      <Container>
        <FormRow>
          <Input
            name="nome"
            label="Nome"
            startIcon={FaMarker}
            type="text"
            placeholder="Nome do contato"
            value={contato?.nome}
            onChange={handleInputOnChange}
            data-cy='cadastroContatoNome'
          />
        </FormRow>
        <FormRow>
          <Input
            name="email"
            startIcon={FaEnvelope}
            label="E-mail"
            type="email"
            placeholder="E-mail do contato"
            value={contato?.email}
            onChange={handleInputOnChange}
            data-cy='cadastroContatoEmail'
          />
        </FormRow>
        {contato && contato.id && <div>
          <Select
            name="status"
            title="Status"
            value={contato.status}
            onChange={handleSelectOnChange}
          >
            <option value="ativo">Ativo</option>
            <option value="inativo">Inativo</option>
          </Select>
        </div>}
        <strong>
          Setor
        </strong>
        <FormRow>
          <CheckBox
            name="fiscal"
            checked={contato?.fiscal === 's'}
            onChange={handleInputOnChange}
            title="Fiscal"
            data-cy='cadastroContatoCbFiscal'
          />
          <CheckBox
            name="comercial"
            checked={contato?.comercial === 's'}
            onChange={handleInputOnChange}
            title="Comercial"
            data-cy='cadastroContatoCbComercial'
          />
          <CheckBox
            name="financeiro"
            checked={contato?.financeiro === 's'}
            onChange={handleInputOnChange}
            title="Financeiro"
            data-cy='cadastroContatoCbFinanceiro'
          />
        </FormRow>
      </Container>
    </>
  )
}
