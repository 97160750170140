import { combineReducers } from 'redux'
import atendimentosReducer from './atendimentos/reducer'
import produtosReducer from './produtos/reducer'
import usuariosReducer from './usuarios/reducer'

export default combineReducers({
  atendimentos: atendimentosReducer,
  produtos: produtosReducer,
  usuarios: usuariosReducer
})
