/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import capitalize from 'capitalize-pt-br'
import { Link, useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'
import Swal from 'sweetalert2'
import { useSideBar } from '../../contexts'
import { useNotificacao, useThemeName, useUsuario } from '../../hooks'
// import { AppsDropdown } from '..'
// import { BsGrid3X3Gap } from 'react-icons/bs'
import { BsList, BsPeopleCircle } from 'react-icons/bs'
import useOnClickOutside from 'react-cool-onclickoutside'
import Logo from '../../assets/imgs/logo_brasao.png'
import noImage from '../../assets/imgs/noImage.jpg'
import {
  Container,
  Hamburger,
  Content,
  Avatar,
  AvatarDiv,
  DivLogo,
  LeftContainer,
  // BoxFrontVersionNotification,
  RightContainer,
  AvatarDrop,
  DivNotificacoes
} from './styles'
// import { AppsDrop, DivApps } from './styles'
import { FaBell } from 'react-icons/fa'
import { useVersaoFrontend } from '../../hooks/versaoFrontend/useVersaoFrontend'
// import { AiFillWarning } from 'react-icons/ai'
import packageJson from '../../../../package.json'

export const Header = () => {
  const { logout, data: usuario } = useUsuario()
  const [openAvatarDropdown, setOpenAvatarDropdown] = useState(false)
  // const [openAppDropdown, setOpenAppDropdown] = useState(false)
  const refAvatar = useOnClickOutside(() => { setOpenAvatarDropdown(false) })
  // const refApps = useOnClickOutside(() => { setOpenAppDropdown(false) })
  const { atualizarVersaoFrontend, data } = useVersaoFrontend()
  const [versaoFrontend, setVersaoFrontend] = useState(false)
  const { data: dataNotificacoes } = useNotificacao({ lidas: false })
  const { colors } = useTheme()
  const { theme, toogleTheme } = useThemeName()
  const history = useHistory()
  const {
    toogle,
    isVisible: sideBarIsVisible
  } = useSideBar()

  const handleClickPopoverPerfil = useCallback(() => {
    setOpenAvatarDropdown(false)
    history.push('/perfil')
  }, [history])

  useEffect(() => {
    if (data) {
      if (data.versao !== packageJson.version) {
        Swal.fire({
          icon: 'error',
          title: 'Seu sistema está desatualizado',
          html: `Sua versão é ${packageJson.version} mas o esperado é ${data.versao}`,
          showConfirmButton: true,
          confirmButtonText: 'atualizar sistema',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showLoaderOnConfirm: true,
          preConfirm: function () {
            return new Promise(function (resolve) {
              window.location.reload()
              setTimeout(function () {
              }, 5000)
            })
          }
        })
      }
    }
  }, [data])

  const handleClickLogout = async () => {
    setOpenAvatarDropdown(false)

    const { isConfirmed } = await Swal.fire({
      title: 'Desconectar da aplicação?',
      icon: 'warning',
      focusCancel: true,
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Desconectar',
      confirmButtonColor: colors.danger
    })

    if (isConfirmed) {
      logout()
    }
  }

  const handleAvatarOnClick = useCallback(() => {
    setOpenAvatarDropdown(oldState => !oldState)
  }, [])

  // const handleClickApps = () => {
  //   setOpenAppDropdown(state => !state)
  // }

  const handleHamburgerOnClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    toogle()
  }, [toogle])

  const handleClickNotification = () => {
    history.push('/notificacoes')
  }

  const nmrNotificacoes = dataNotificacoes?.metadata ? dataNotificacoes.metadata.count : 0

  return (
    <Container>
      <LeftContainer>
        <DivLogo isVisible={sideBarIsVisible}><Link to={'/'}><img src={Logo} alt="route66" /></Link></DivLogo>
        <Hamburger
          onClick={handleHamburgerOnClick}
          sideBarIsVisible={sideBarIsVisible}
        >
          <BsList />
        </Hamburger>
      </LeftContainer>
      {/* {versaoFrontend && (
        <BoxFrontVersionNotification>
          <div>
            <h3>
              <AiFillWarning color="#333" size={20} />
              Seu sistema está desatualizado. Atualize para a versão mais recente.
            </h3>
          </div>
          <button onClick={() => atualizarVersaoFrontend(usuario?.token)}>Clique aqui</button>
        </BoxFrontVersionNotification>
      )} */}
      <RightContainer>
        {/* como n possui outros projetos no ar ainda, foi comentado por hora
        <DivApps onClick={handleClickApps} ref={refApps}>
          <BsGrid3X3Gap size='30px' />
          <AppsDrop showDrop={openAppDropdown}>
            <AppsDropdown />
          </AppsDrop>
        </DivApps> */}
        <DivNotificacoes onClick={handleClickNotification}>
          <FaBell size='20px' title={nmrNotificacoes ? 'Você possui novas notificações' : 'Notificações'} />
          {nmrNotificacoes > 0 && <span>{nmrNotificacoes < 99 ? nmrNotificacoes : '99+'}</span>}
        </DivNotificacoes>
        <AvatarDiv>
          <Content onClick={handleAvatarOnClick} data-cy='headerAvatarDiv'>
            {/* <span>{capitalize(usuario?.nome as string)}</span> */}
            <Avatar>
              {usuario?.avatar
                ? <img src={`${process.env.REACT_APP_BASE_USUARIO_IMG_URL}${usuario?.avatar}`} onError={(e) => { e.currentTarget.src = noImage }} alt={usuario?.nome} />
                : <BsPeopleCircle size={50}></BsPeopleCircle>
              }
            </Avatar>
          </Content>
          <AvatarDrop showDrop={openAvatarDropdown} ref={refAvatar}>
            <ul>
              <li onClick={handleClickPopoverPerfil} data-cy='liOpenPerfil'>Perfil</li>
              <li onClick={toogleTheme} data-cy='liToogleTheme'>{theme.title === 'lightTheme' ? 'Tema Escuro' : 'Tema Claro'}</li>
              <li onClick={handleClickLogout} data-cy='liLogout'>Logout</li>
            </ul>
          </AvatarDrop>
        </AvatarDiv>
      </RightContainer>
    </Container>
  )
}
