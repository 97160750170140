import { generateSearchQuery } from '../../../helpers'
import { GetParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { ListarEmpresasService } from '../contracts'
import { GetListEmpresaResponse } from '../models'
import { ListarEmpresasResApi } from './_interfaces'

export class ListarEmpresasServiceRest implements ListarEmpresasService {
  private readonly http: HttpRequest

  constructor (http: HttpRequest) {
    this.http = http
  }

  async listarEmpresas (params: GetParams): Promise<GetListEmpresaResponse> {
    let { filter, filterOptions, filterObject, token } = params
    const query = generateSearchQuery(filter, filterOptions, filterObject)

    const response = await this.http.get<ListarEmpresasResApi>({
      path: 'empresas',
      token,
      query
    })

    const retorno = {
      data: response.res?.data,
      metadata: response.res?.metadata,
      error: response.error
    } as GetListEmpresaResponse

    return retorno
  }
}
