import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { TrazerProdutoPorIdServiceRest } from '../services'
import { TrazerProdutoPorId } from '../useCases'

export function makeTrazerProdutoPorId (): TrazerProdutoPorId {
  const httpRequest = new HttpRequestImpl()
  const trazerProdutoPorId = new TrazerProdutoPorIdServiceRest(httpRequest)

  return new TrazerProdutoPorId(trazerProdutoPorId)
}
