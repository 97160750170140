import { ListarClientesEquipeService } from '../contracts'
import { GetListClienteResponse } from '../models'
import { ListarClientesEquipeParams } from '../_interfaces'

export class ListarClientesEquipe {
  private readonly listarClientesService: ListarClientesEquipeService

  constructor (listarClientesService: ListarClientesEquipeService) {
    this.listarClientesService = listarClientesService
  }

  async execute (params: ListarClientesEquipeParams): Promise<GetListClienteResponse> {
    const response = await this.listarClientesService.listarClientesEquipe(params)
    return response
  }
}
