import { handleErrors } from '../../../helpers/handleErrors'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { TransformarPedidoService } from '../contracts'

export interface TransformarPedidoParams {
  token: string
  id: number
}

export class TransformarPedidoServiceRest implements TransformarPedidoService {
  private readonly http: HttpRequest

  constructor (http: HttpRequest) {
    this.http = http
  }

  async transformarPedido (pedidoId: number, token: string): Promise<boolean> {
    const response = await this.http.put({
      path: `pedidos/${pedidoId}/transformar`,
      token
    })

    handleErrors(response?.error)
    return response?.status === 204
  }
}
