import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { TrazerEquipePorIdServiceRest } from '../services'
import { TrazerEquipePorId } from '../useCases'

export function makeTrazerEquipePorId (): TrazerEquipePorId {
  const httpRequest = new HttpRequestImpl()
  const trazerEquipePorIdService = new TrazerEquipePorIdServiceRest(httpRequest)

  return new TrazerEquipePorId(trazerEquipePorIdService)
}
