import { PostParams } from '../../_interfaces'
import { handleErrors } from '../../../helpers/handleErrors'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { NovaSolicitacaoService } from '../contracts'

export class NovaSolicitacaoServiceRest implements NovaSolicitacaoService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async novaSolicitacao (params: PostParams): Promise<{id: string}> {
    const { id, ...rest } = params.body

    const response = await this.httpRequest.post<string>({
      path: `pedidos/${id}/solicitacoes`,
      body: rest,
      token: params.token
    })

    handleErrors(response?.error)
    return response.data as any
  }
}
