import capitalize from 'capitalize-pt-br'
import React, { useCallback, useEffect, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { Input, InputF2, Table, TableColumnProps } from '..'
import { toCnpj } from '../../../helpers'
import { Cliente } from '../../../modules/clientes/models'
import { GetParams } from '../../../modules/_interfaces'
import { FilterOptions } from '../../../modules/_interfaces/filterOptions'
import { SortTableProps } from '../../../modules/_interfaces/sortTable'
import { useClientes, useUsuario } from '../../hooks'
import { TableText } from '../../styles/global'

import { Container, Header } from './styles'

const perPage = 12

interface ListaClientesProps extends InputF2 {
  isRevenda: boolean,
  modo: 'fidelizados' | 'todos' | 'revendas'
}

export const ListaClientes = ({ isRevenda = false, modo, close, callBack }: ListaClientesProps) => {
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const { data: usuario } = useUsuario()
  const [sortTable, setSortTable] = useState<SortTableProps>({
    id: 'id',
    desc: false
  } as SortTableProps)
  const [clientesParams, setClientesParams] = useState(() => {
    let params: GetParams = {
      token: usuario?.token as string,
      filterOptions: {
        limit: perPage,
        skip: (currentPage) * perPage
      },
      filter: search || ''
    } as GetParams

    if (sortTable && sortTable.id) {
      if (sortTable.desc) {
        params.filterOptions = { ...params.filterOptions, order: '-clientes.' + sortTable.id }
      } else {
        params.filterOptions = { ...params.filterOptions, order: 'clientes.' + sortTable.id }
      }
    }
    return params
  })

  const { fetcher: getClientes, data: clientesFidelizados } = useClientes()

  useEffect(() => {
    let params = {
      ...clientesParams,
      filterOptions: { ...clientesParams.filterOptions, skip: 0 },
      filter: search
    }
    getClientes(params, modo, usuario?.funcionario_id as unknown as number)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: TableColumnProps[] = [
    {
      Header: '#',
      accessor: 'id',
      minWidth: 8,
      Cell: ({ value }) => {
        return <TableText position='right'>{value}</TableText>
      }
    },
    {
      Header: 'Razão Social',
      accessor: 'razao_social',
      minWidth: 35,
      Cell: ({ row }) => {
        if (row._original.razao_social) {
          return capitalize(row._original.razao_social)
        } else {
          return capitalize(row._original.nome_fantasia)
        }
      }
    },
    {
      Header: 'CNPJ / CPF',
      accessor: 'cnpj',
      minWidth: 10,
      Cell: ({ value }) => {
        return <TableText position='right'>{toCnpj(value)}</TableText>
      }
    },
    {
      Header: 'UF',
      accessor: 'uf',
      minWidth: 7,
      Cell: ({ value }) => <TableText position='center'>{value ? value.toString().toUpperCase() : ''}</TableText>

    },
    {
      Header: 'Cidade',
      accessor: 'cidade',
      minWidth: 10,
      Cell: ({ value }) => capitalize(value)
    },
    {
      Header: 'Endereço',
      accessor: 'endereco',
      minWidth: 25,
      Cell: ({ value }) => capitalize(value)
    }
  ]

  const dbClickTableRowOnclick = useCallback((cliente: Cliente) => {
    callBack && callBack(cliente)
    close && close()
  }, [callBack, close])

  const handleSearch = useCallback(() => {
    let params = {
      ...clientesParams,
      filterOptions: { ...clientesParams.filterOptions, skip: 0 },
      filter: search
    }
    getClientes(params, modo, usuario?.funcionario_id as unknown as number)
  }, [clientesParams, getClientes, modo, search, usuario])

  const handleEnterSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleSearch()
  }

  const handleFilterOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }, [])

  const handleOnPageChange = useCallback((page: number) => {
    if (!clientesFidelizados?.metadata?.count) {
      return
    }

    setCurrentPage(page)

    const newFilterOptions: FilterOptions = {
      ...clientesParams.filterOptions,
      skip: (page) * perPage
    }

    setClientesParams(old => ({
      ...old,
      filterOptions: newFilterOptions
    }))
  }, [clientesFidelizados, clientesParams])

  const handleOnSortedChange = (header: any) => {
    const sort = {
      id: header[0].id,
      desc: header[0].desc
    }
    setSortTable(sort)

    if (sort && sort.id) {
      const newFilterOptions: FilterOptions = {
        ...clientesParams.filterOptions,
        skip: 0,
        order: sort.desc
          ? '-clientes.' + sort.id
          : 'clientes.' + sort.id
      }

      setClientesParams(old => ({
        ...old,
        filterOptions: newFilterOptions
      }))
    }
  }

  return (
    <Container>
      <Header>
        <div>
          <Input
            type='text'
            startIcon={FaSearch}
            onChange={handleFilterOnChange}
            value={search}
            autoFocus
            onKeyPress={handleEnterSearch}
            onBlur={handleSearch}
          />
        </div>
      </Header>
      <Table
        tabIndex={0}
        columns={columns}
        showPageJump={false}
        data={clientesFidelizados?.data}
        loading={!clientesFidelizados?.data}
        pageSize={perPage}
        page={currentPage}
        pages={clientesFidelizados?.metadata?.count && Math.ceil(clientesFidelizados?.metadata?.count / perPage)}
        onPageChange={handleOnPageChange}
        manual
        sortable={true}
        onSortedChange={handleOnSortedChange}
        showPageSizeOptions={false}
        noDataText="Nenhum Cliente"
        pageText='Página'
        ofText='de'
        previousText='Anterior'
        nextText='Próximo'
        showPagination={clientesFidelizados?.data && clientesFidelizados?.data.length >= perPage}
        dbClickCallback={dbClickTableRowOnclick}
        selectRow
      />
    </Container>

  )
}
