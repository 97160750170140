import capitalize from 'capitalize-pt-br'
import React from 'react'
import { FaCopy } from 'react-icons/fa'
import Swal from 'sweetalert2'
import { ButtonTable, Table, TableColumnProps } from '..'
import { Cliente } from '../../../modules/clientes/models'
import { TableActions, TableText } from '../../styles/global'

import { Container, Wrapper } from './styles'

const rowsPerPage = 20

interface ListaGrupoProps {
  cliente: Cliente
}

export const ListaGrupoCliente = ({ cliente }: ListaGrupoProps) => {
  const handleCopyText = (text?: string | number) => {
    if (text) {
      navigator.clipboard.writeText(text.toString())
      Swal.fire({
        icon: 'success',
        title: 'Copiado!',
        position: 'top-end'
      })
    }
  }

  const columns: TableColumnProps[] = [
    {
      Header: '#',
      accessor: 'cliente_id',
      minWidth: 10,
      Cell: ({ value }) => <TableText position='center'>{value}</TableText>
    },
    {
      Header: 'Nome',
      accessor: 'razao_social',
      minWidth: 30,
      Cell: ({ value }) => <TableText position='left'>{capitalize(value)}</TableText>
    },
    {
      Header: 'Usa limite',
      accessor: 'usa_limtie',
      minWidth: 10,
      Cell: ({ value }) => <TableText position='center'>{value === 's' ? 'Sim' : 'Não'}</TableText>
    },
    {
      Header: 'Ações',
      minWidth: 10,
      // eslint-disable-next-line react/display-name
      Cell: ({ row }) => {
        return (
          <TableActions>
            <ButtonTable
              typeButton='primary'
              title="Copiar código"
              onClick={() => handleCopyText(row._original.cliente_id)}
            >
              <FaCopy size='15px' />
            </ButtonTable>
          </TableActions>
        )
      }
    }
  ]

  return (
    <Wrapper>
      <span>Grupo</span>
      <Container>
        <Table
          tabIndex={0}
          columns={columns}
          showPageJump={false}
          data={cliente?.grupo}
          pageSize={rowsPerPage}
          sortable={true}
          showPageSizeOptions={false}
          // loading={loadingCliente}
          noDataText="Nenhum cadastrado de Grupo"
          nextText='Próximo'
          ofText='de'
          previousText='Anterior'
          showPagination={cliente && cliente.grupo && cliente.grupo?.length >= rowsPerPage}
          pageText='Página'
          selectRow
        />
      </Container>
    </Wrapper>
  )
}
