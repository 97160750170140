import styled from 'styled-components'
import { fadein } from '../../styles/animations'

type CorType = 'danger' | 'success' | 'warning' | 'info'
interface CardStylesProps {
  borderless?: boolean
  width: string
  cor?: CorType
}

export const Container = styled.div<CardStylesProps>`
  display: flex;
  width: ${props => props.width ? props.width : '100%'};
  padding: 15px 20px;
  line-height: 1.5;
  background-color: ${props => props.theme.colors.backgroundLight};
  border: 1px solid ${props => props.theme.colors.borderLight};
  border-bottom: 3px solid ${props => {
    if (props.cor === 'danger') {
      return props.theme.colors.danger
    } else if (props.cor === 'warning') {
      return props.theme.colors.warning
    } else if (props.cor === 'info') {
      return props.theme.colors.info
    } else if (props.cor === 'success') {
      return props.theme.colors.success
    } else {
      return props.theme.colors.primaryText
    }
  }};
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  animation: ${fadein} 0.4s ease-in forwards;

  @media screen and (max-width: 720px) {
    width: 49%;
    margin-bottom: 10px;
  }
`
interface ContentStylesProps {
  borderless?: boolean
}

export const Content = styled.div<ContentStylesProps>`
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    color: ${props => props.theme.colors.primaryText};
  }

  span {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    color: ${props => props.theme.colors.primaryText};
  }
`
interface IconProps {
  cor?: CorType
}

export const Icon = styled.div<IconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  svg {
    fill: ${props => {
    if (props.cor === 'danger') {
      return props.theme.colors.danger
    } else if (props.cor === 'warning') {
      return props.theme.colors.warning
    } else if (props.cor === 'info') {
      return props.theme.colors.info
    } else if (props.cor === 'success') {
      return props.theme.colors.success
    } else {
      return props.theme.colors.primaryText
    }
  }};
  }
`
