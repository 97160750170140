
import { AppError } from '../../../helpers'
import { GetParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { TrazerEnderecoClienteService } from '../contracts'
import { Endereco, GetEnderecoPorCep } from '../models'
import { EnderecoPorCepRespApi } from './_interfaces'

export class TrazerEnderecoClienteServiceRest implements TrazerEnderecoClienteService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async trazerEnderecoCliente (params: GetParams): Promise<GetEnderecoPorCep> {
    const response = await this.httpRequest.get<EnderecoPorCepRespApi>({
      url: 'https://viacep.com.br/ws/',
      path: `${params.filter}/json/`
    })

    if (response?.res?.erro === true) {
      throw new AppError({
        message: 'Cep não encontrado',
        type: 'validate'
      })
    }

    return {
      data: response.res as Endereco
    }
  }
}
