import React, { ReactNode } from 'react'
import useOnClickOutside from 'react-cool-onclickoutside'
import { Container } from './styles'

interface DropProps {
  children: ReactNode
  callbackClose: () => void
}

export const DropList = (props: DropProps) => {
  const ref = useOnClickOutside(props.callbackClose)
  return (
    <Container ref={ref}>
      {props.children}
    </Container>
  )
}
