import { PostParams, Validator } from '../../_interfaces'
import { CadastrarClienteService } from '../contracts'
import { Cliente } from '../models'

export class CadastrarCliente {
  private readonly cadastrarClienteService: CadastrarClienteService
  private readonly validator: Validator

  constructor (cadastrarClienteService: CadastrarClienteService, validator: Validator) {
    this.cadastrarClienteService = cadastrarClienteService
    this.validator = validator
  }

  async execute (postParams: PostParams): Promise<Cliente> {
    let params = postParams
    await this.validator.validate(params.body)
    delete params.body.pj
    const response = await this.cadastrarClienteService.cadastrarCliente(params)
    return response
  }
}
