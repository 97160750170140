import { AtendimentosState } from '../main/store/atendimentos/interfaces'
import { Cliente } from '../modules/clientes/models'

interface FunctionProps {
  cliente?: Cliente,
  revenda?: Cliente,
  atendimentosState?: AtendimentosState,
  pedidoIdEmEdicao?: number
  mode?: string
}

export const clienteParamsPreco = ({ cliente, atendimentosState, pedidoIdEmEdicao, mode, revenda }: FunctionProps) => {
  if (mode === 'register') {
    if (atendimentosState?.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.tipo_faturamento === 'direto' && revenda) {
      if (cliente?.uf !== revenda?.uf) {
        return revenda
      } else {
        return cliente
      }
    } else {
      return cliente
    }
  } else {
    if (atendimentosState?.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number].original?.tipo_faturamento === 'direto' && revenda) {
      if (cliente?.uf !== revenda?.uf) {
        return revenda
      } else {
        return cliente
      }
    } else {
      return cliente
    }
  }
}
