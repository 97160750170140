import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const NewButtonDiv = styled.div`
  display:flex;
  width: 100%;
  justify-content: flex-end;
  margin: 15px 0px;
`

export const FormOcorrencia = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
`

interface TextFormProps {
  isActive: boolean
}
export const TextFormDiv = styled.div<TextFormProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px 0px;
  >label{
    color: ${props => props.theme.colors.primaryText} !important;
    opacity: ${props => props.isActive ? '1' : '0.5'};
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  >textarea{
    resize: none;
    overflow: auto;
    border-radius: 4px;
    border: 1px solid ${props => props.isActive ? props.theme.colors.primary : props.theme.colors.border};
    padding: 5px;
  }
`
export const DivOcorrencia = styled.div`
  width: 100%;
  height: fit-content;
`
export const HeaderOcorrencia = styled.div`
  display:flex;
  flex-direction:column;
  >div{
    display:flex;
    width:100%;
    margin-bottom: 5px;
    >span:first-of-type{
      font-weight:bold;
      margin-right: 10px;
    }
  }
`
export const BodyOcorrencia = styled.div`
  display: flex;
  flex-direction:column;
  width: 100%;
  >div:first-of-type{
    width: 100%;
    margin-bottom: 5px;
    >span{
      margin-bottom: 5px;
      font-weight:bold;
    }
  }
  >div:nth-of-type(2){
    display: flex;
    justify-content: flex-end;
    width:100%;
  }
`
