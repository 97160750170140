import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { SchemaValidator } from '../../../infra/validator/schemaValidator'
import { CadastrarChamadoServiceRest } from '../services'
import { CadastrarChamado } from '../useCases'
import { schema } from '../validators'

export function makeCadastrarChamado (): CadastrarChamado {
  const httpRequest = new HttpRequestImpl()
  const validator = new SchemaValidator(schema)
  const cadastrarChamadoService = new CadastrarChamadoServiceRest(httpRequest)

  return new CadastrarChamado(cadastrarChamadoService, validator)
}
