import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { Routes } from './routes'
import { BrowserRouter } from 'react-router-dom'
import { Providers } from './contexts/provider'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store'

const App = () => {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Providers>
            <Routes />
          </Providers>
        </BrowserRouter>
      </PersistGate>
    </ReduxProvider>
  )
}

export default App
