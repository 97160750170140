import { useCallback, useEffect, useState } from 'react'
import { useUsuario } from '..'
import { makeTrazerResumoFuncionario } from '../../../modules/funcionarios/factories'
import { ResumoMes, Resumo, Meta } from '../../../modules/funcionarios/models/resumoFuncionario'
import { useHandleErrors } from '../app/useHandleErrors'

interface ChartValue {
  valor1?: number
  valor2?: number
  subtitle?: string
  ano?: string
  mes?: string
}

interface ChartsProps {
  legendas: {
    valor1?: string
    valor2?: string
  }
  valores?: ChartValue[]
}

export function useResumoFuncionario () {
  const [loading, setLoading] = useState(false)
  const [isReloading, setIsReloading] = useState(false)
  const { data: usuarioData } = useUsuario()
  const funcionarioId = usuarioData?.funcionario_id
  const { handleError } = useHandleErrors()
  const [valoresGrafico, setValoresGrafico] = useState<ChartsProps>({} as ChartsProps)
  const [resumoFuncionario, setResumoFuncionario] = useState<Resumo>({} as Resumo)
  const [metaFuncionario, setMetaFuncionario] = useState<Meta>({} as Meta)

  useEffect(() => {
    async function fetcher () {
      setLoading(true)
      const trazerResumoFuncionario = makeTrazerResumoFuncionario()

      const response = await trazerResumoFuncionario.execute({
        filter: funcionarioId as unknown as string,
        token: usuarioData?.token
      })

      const data = response.data

      if (data) {
        if (data.resumo) {
          setResumoFuncionario(data.resumo)
        }

        if (data.meta) {
          setMetaFuncionario(data.meta)
        }

        const resumoMes = data.fechamentos
        let valoresGrafico: ChartValue[] = Object.keys(resumoMes).map(item => {
          let retorno = {}
          if (item !== 'resumo') {
            let ano = ''
            let mes = ''
            for (let index = 0; index < item.length; index++) {
              if (index < 4) {
                ano += item[index]
              } else if (index >= 4) {
                mes += item[index]
              }
            }
            retorno = {
              mes,
              ano
            }
          }
          return retorno
        })

        // limita o grafico a 6 meses no front-end
        valoresGrafico = valoresGrafico.reverse().slice(0, 6).reverse()

        const legendas = [...new Set(valoresGrafico.map(item => item.ano))]
        valoresGrafico = valoresGrafico.map((element: ChartValue) => {
          let chart: ChartValue = {}
          if (element.ano && element.mes) {
            const key = element.ano + element.mes
            const valor: ResumoMes = resumoMes[key]
            // if (element.ano === legendas[0]) {
            chart = {
              ...element,
              valor1: valor.total_vendas,
              subtitle: element.mes + '/' + element.ano
            }
            // } else {
            //   chart = {
            //     ...element,
            //     valor2: valor.total_vendas,
            //     subtitle: element.mes + '/' + element.ano
            //   }
            // }
          }
          return chart
        })

        setValoresGrafico({
          legendas: {
            valor1: legendas[0]
            // valor2: legendas[1]
          },
          valores: valoresGrafico
        })
      }

      setLoading(false)
      if (response.error) {
        handleError(response.error)
        setLoading(false)
      }
    }

    fetcher()
  }, [funcionarioId, handleError, usuarioData, isReloading])

  const reload = useCallback(() => {
    setIsReloading(true)
  }, [])

  return { valoresGrafico, resumoFuncionario, metaFuncionario, reload, loading }
}
