import * as yup from 'yup'

type ie = number | 'isento'

export const schema = yup.object().shape({
  razao_social: yup.string().when('pj', {
    is: true,
    then: yup.string().required('razão social é um campo obrigatório'),
    otherwise: yup.string()
  }),
  nome_fantasia: yup.string().required('Nome fantasia é obrigatório'),
  email: yup.string().email().required('Email é um campo obrigatório'),
  email_nfe: yup.string().email().required('Email NFE é um campo obrigatório'),
  email_nfe2: yup.string().email(),
  cnpj: yup.string().when('pj', {
    is: true,
    then: yup.string().required('Informe o CNPJ do Cliente corretamente').length(14),
    otherwise: yup.string().required('Informe o CPF do Cliente corretamente').length(11)
  }),
  ie: yup.mixed<ie>(),
  cep: yup.string().required('Informe o CEP corretamente').length(8),
  endereco: yup.string().required('Endereço do Cliente é obrigatório'),
  complemento: yup.string(),
  numero: yup.string().required('Numero é obrigatório'),
  bairro: yup.string().required('Bairro é obrigatório'),
  cidade: yup.string().required('Cidade é um campo obrigatório'),
  regiao: yup.string(),
  uf: yup.string().required('Estado é um campo obrigatório').length(2),
  is_cliente_final: yup.string().length(1).required(),
  is_orgao_estadual: yup.string().length(1).required(),
  is_revenda: yup.string().length(1).required(),
  contatos: yup.array().min(1, 'O cliente deve ter ao menos 1 contato')
    .of(
      yup.object().shape({
        id: yup.string(),
        nome: yup.string().required(),
        email: yup.string().email().required(),
        fiscal: yup.mixed().oneOf(['s', 'n']),
        comercial: yup.mixed().oneOf(['s', 'n']),
        financeiro: yup.mixed().oneOf(['s', 'n']),
        status: yup.mixed().oneOf(['ativo', 'inativo']),
        telefones: yup.array()
          .of(
            yup.object().shape({
              ddd: yup.string(),
              numero: yup.string(),
              ramal: yup.string(),
              whatsapp: yup.mixed().oneOf(['s', 'n'])
            })
          )
      })
    )
})
