import { useState } from 'react'

export function useImagensProduto () {
  const [imagens, setImagens] = useState<string[]>()
  const alfabeto = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
    'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
  ]

  async function getImagens (produtoId: number) {
    let alfabetoArr: string[] = []

    // for (let i = 0; i < alfabeto.length; i++) {
    //   const img = new Image()

    //   img.src = `${process.env.REACT_APP_BASE_PRODUTO_IMG_URL}${produtoId}${alfabeto[i]}.jpg`

    //   if (img.complete) {
    //     alfabetoArr.push(img.src)
    //   } else {
    //     img.onload = () => {
    //       alfabetoArr.push(img.src)
    //     }

    //     setImagens(alfabetoArr)

    //     if (img.onerror) continue
    //   }
    // }
  }

  return {
    imagens,
    getImagens
  }
}
