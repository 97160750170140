import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 4px;
  margin: 10px 0px;
`

export const Label = styled.div`
  display: flex;
  padding: 15px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.4px;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  margin-bottom: 10px;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 15px;
  justify-content: space-between;
  align-content: center;
  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
`

export const NoContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: center;
  align-content: center;

  >span{
    font-size: 14px;
    font-weight: bold;
    color: inherit;
  }
`

interface BarProps {
  percent: number
}

export const Bar = styled.div<BarProps>`
  position: relative;
  display: flex;
  width: 79%;
  height: 20px;
  background-color: ${props => props.theme.colors.background};
  box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
  border-radius: 5px;
  overflow: hidden;

  :after{
    content: '';
    position: absolute;
    left: 0;
    width: ${props => props.percent ? props.percent + '%' : '0%'};
    height: 100%;
    border-radius: 5px;
    background: ${props => props.theme.colors.info};
  }

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`

export const DivNumbers = styled.div`
  display: flex;
  width: 19%;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: bold;
  @media screen and (max-width: 720px) {
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }
`
