import styled from 'styled-components'

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;
  color: ${props => props.theme.colors.primaryText};
`

export const ListaBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
  padding: 5px 10px;
  overflow: auto;
`

export const ItemHistorico = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 4px;
  padding: 10px;
  margin: 5px 0px;
`
export const HeaderItem = styled.div`
  display:flex;
  width: 100%;
  justify-content: space-between;
`

export const IdHeaderDiv = styled.div`
  display: flex;
  width:20%;
  justify-content: flex-start;
  >div{
    justify-content: flex-start;
  }
`

export const DataHeaderDiv = styled.div`
  display: flex;
  width:20%;
  justify-content: flex-end;
  >div{
    justify-content: flex-end;
  }
`

export const DadoHeaderDiv = styled.div`
  display: flex;
  width: 100%;
  >span:first-of-type{
    font-weight: bold;
    margin-right: 5px;
  }
`

export const BodyItem = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;
  >span:first-of-type{
    font-weight: bold;
  }
  >p:first-of-type{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    inline-size: 100%;
    line-break: normal;
    line-height: 16px;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
`
