import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
`

export const HeaderPromocao = styled.div`
  display: flex;
  width: 100%;
`
export const BodyPromocao = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const ErroQuantidade = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 15px;
  width: 48%;
  color: ${props => props.theme.colors.danger};
  >span:first-of-type{
    margin-right: 5px;
  }
`
