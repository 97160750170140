import React, { useState } from 'react'
import { FaCopy, FaRegListAlt } from 'react-icons/fa'
import { BsThreeDots } from 'react-icons/bs'
import { ButtonTable } from '..'
import { ContaReceber } from '../../../modules/clientes/models'
import { Container, ListaAcoes } from './styles'
import { Modal } from '../Modal'
import Swal from 'sweetalert2'
import DetalhesContas from '../DetalhesContas'

interface AcoesCrsProps {
  cr: ContaReceber
}

export const AcoesCrs = (props: AcoesCrsProps) => {
  const [openList, setOpenList] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const handleCopy = () => {
    setOpenList(false)
    navigator.clipboard.writeText(props.cr.digitavel)
    Swal.fire({
      icon: 'success',
      title: 'Copiado!',
      position: 'top-end'
    })
  }

  const handleDetalhes = () => {
    setOpenList(false)
    setModalOpen(true)
  }

  return (
    <Container>
      <ButtonTable
        title='Ações'
        type="button"
        typeButton='primary'
        onClick={() => setOpenList(state => !state)}
      ><BsThreeDots /></ButtonTable>
      {openList && <Modal close={() => setOpenList(false)} title='Ações da CR' mode='mini'>
        <ListaAcoes>
          <ul>
            <li onClick={handleDetalhes}><FaRegListAlt size='15px' /><span>Detalhes</span></li>
            <li onClick={handleCopy}><FaCopy size='15px' /><span>Copiar Digitável</span></li>
          </ul>
        </ListaAcoes>
      </Modal>
      }
      {modalOpen && <Modal close={() => setModalOpen(false)} title={`Detalhes da C.R. ${props.cr.id}`} mode='fullscreen'>
        <DetalhesContas cr={props.cr} />
      </Modal>
      }
    </Container>
  )
}
