import { PostParams } from '../../_interfaces'
import { LiberarEstoquePedidoService } from '../contracts'

export class LiberarEstoquePedido {
  private readonly liberarEstoquePedidoService: LiberarEstoquePedidoService

  constructor (liberarEstoquePedidoService: LiberarEstoquePedidoService) {
    this.liberarEstoquePedidoService = liberarEstoquePedidoService
  }

  async execute (params: PostParams): Promise<boolean> {
    return await this.liberarEstoquePedidoService.liberarEstoquePedido(params)
  }
}
