import styled from 'styled-components'
import { fadein } from '../../styles/animations'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding-bottom: 30px;
  animation: ${fadein} 0.6s forwards;

  >section {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  > section:last-of-type {
    @media screen and (max-width: 720px) {
      flex-direction: column;
    }
  }
`

export const HeaderAtendimento = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }
`

export const HeaderAtendimentoDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
  >span {
    border-bottom: 1px solid ${props => props.theme.colors.border};
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
    color: ${props => props.theme.colors.primaryText};
  }
  >div {
    display: flex;
    flex-direction: row;
    width: 100%;
    >div + div {
      margin-left: 7px;
    }
  }
  @media screen and (max-width: 1280px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    > div {
      flex-direction: column;

      > div + div {
        margin-left: 0;
      }
    }
  }
`

export const CardGroups = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 5px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 790px) {
    grid-template-columns: repeat(2, 1fr);

    > div {
      width: 100% !important;
    }
  }

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`
