import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import useSWR from 'swr'
import { useUsuario } from '..'
import { makeListarNotificacoesFuncionario } from '../../../modules/funcionarios/factories'
import { ListarNotificacoesFuncionarioParams } from '../../../modules/funcionarios/_interfaces'

const listarNotificacoes = makeListarNotificacoesFuncionario()

interface ListaNotificacoesParams {
  lidas?: boolean
  filterOptions?: {
    limit: number
    skip: number
  }
}

export function useNotificacao (params: ListaNotificacoesParams) {
  const { data: usuarioData } = useUsuario()
  const history = useHistory()

  let listaNotificacoesParams: ListarNotificacoesFuncionarioParams = {
    funcionarioId: parseInt(usuarioData?.funcionario_id as string),
    token: usuarioData?.token,
    filterOptions: params.filterOptions
  }

  if (params.lidas !== undefined) {
    listaNotificacoesParams.lidas = params.lidas
  }

  const { data, error, isValidating, mutate } = useSWR(JSON.stringify({
    useCase: 'listarNotificacoes',
    ...listaNotificacoesParams
  }), () => listarNotificacoes.execute(listaNotificacoesParams), { refreshInterval: 300000 })

  if (error) {
    if (error?.type === 'auth') {
      history.push('/login')
    } else {
      Swal.fire({
        title: 'Erro!',
        text: 'Erro no servidor ' + error.message,
        icon: 'error',
        timer: 2500,
        timerProgressBar: true
      })
    }
  }
  return { data, error, isValidating, mutate }
}
