export const replaceLineBreakFromApi = (text?: string) => {
  let retorno = ''
  if (text && text !== '') {
    retorno = text.replace(/\\r\\n/g, '\r\n')
    retorno = retorno.replace(/\\n/g, '\n')
  }
  return retorno
}

export const replaceLineBreakToApi = (text?: string) => {
  let retorno = ''
  if (text && text !== '') {
    retorno = text.replace(/\r\n/g, '\\r\\n')
    retorno = retorno.replace(/\n/g, '\\n')
  }
  return retorno
}

export const replaceRfromApi = (text?: string) => {
  if (text) {
    return text.replace(/\r/g, '\n')
  } else {
    return ''
  }
}
