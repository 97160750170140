import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeTrazerChamadoPorId } from '../../../modules/chamados/factories'
import { Chamado, GetChamadosListResponse } from '../../../modules/chamados/models'
import { GetParams } from '../../../modules/_interfaces'
import { useUsuario } from '../usuario/useUsuario'

const trazerChamadoPorId = makeTrazerChamadoPorId()

interface FetcherProps {
  chamado_id: number
}

export function useChamado () {
  const { data: usuarioData } = useUsuario()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Chamado>()
  const [error, setError] = useState<any>()

  async function fetcher ({ chamado_id }: FetcherProps) {
    setLoading(true)
    try {
      let params = {
        filter: chamado_id.toString(),
        token: usuarioData?.token
      } as GetParams

      const response = await trazerChamadoPorId.execute(params)
      setData(response)
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }

  return { fetcher, data, error, loading }
}
