import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { ListarProdutosServiceRest } from '../services'
import { ListarProdutos } from '../useCases/listar-produtos'

export function makeListarProdutos (): ListarProdutos {
  const httpRequest = new HttpRequestImpl()
  const listarProdutoService = new ListarProdutosServiceRest(httpRequest)

  return new ListarProdutos(listarProdutoService)
}
