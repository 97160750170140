import styled, { css } from 'styled-components'
import { shade } from 'polished'
import { fadein } from '../../styles/animations'

type CoordsPopover = {
  x: string
  y: string
}

interface WrapperProps {
  coords: CoordsPopover
  width?: string
  position?: string
}

export const Wrapper = styled.div<WrapperProps>`
  position: ${props => props.position ? props.position : 'relative'};
  ${props => {
    if (props.coords && props.position === 'absolute') {
      return css`
        top: ${props.coords.y};
        right: ${props.coords.x};
      `
    }
  }}
  background-color: ${props => props.theme.colors.backgroundLight};
  z-index: 900;
  width: ${props => props.width ? props.width : '100%'};
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.border};
  padding: 10px;
  box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.36);
  animation: ${fadein} 0.4s forwards;

  >div:first-of-type {
    position: absolute;
    right: -10px;
    top: -15px;
    background-color: ${props => props.theme.colors.backgroundLight};
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.border};
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ${fadein} 0.9s ease-out forwards;
    svg {
      width: 16px;
      height: 16px;
    }

    :hover {
      background-color: ${props => shade(0.04, props.theme.colors.backgroundLight)};

      width: 32px;
      height: 32px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`
export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`
export const DivEstoque = styled.div`
  width: 31%;
  margin-right: 10px;
  >span:first-of-type{
    font-weight: bold;
    margin-right: 5px;
  }
`
export const DivOcorrencia = styled.div`
  width: 100%;
  height: fit-content;
`
export const HeaderOcorrencia = styled.div`
  display:flex;
  flex-direction:column;
  >div{
    display:flex;
    width:100%;
    margin-bottom: 5px;
    >span:first-of-type{
      font-weight:bold;
      margin-right: 10px;
    }
  }
`
export const BodyOcorrencia = styled.div`
  display: flex;
  flex-direction:column;
  width: 100%;
  >div:first-of-type{
    width: 100%;
    margin-bottom: 5px;
    >span{
      margin-bottom: 5px;
      font-weight:bold;
    }
  }
  >div:nth-of-type(2){
    display: flex;
    justify-content: flex-end;
    width:100%;
  }
`
