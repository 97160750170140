/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { Produto } from '../../../modules/produtos/models'
import { Loading, Input, ListaProdutosDetalhes, Button, Modal, ListaEstoques, ListaComprasProdutos, Textarea, CheckBox } from '../../components'
import { useProduto } from '../../hooks/produto/useProduto'
import { MainLayout } from '../../layouts/MainLayout'
import { Container, FirstSection, MobileContent, Header, BoxImg, ImageDiv, InfosDiv, SecondSection, ThirdSection } from './styles'
import noImage from '../../assets/imgs/noImage.jpg'
import { FormRow } from '../../styles/global'
import capitalize from 'capitalize-pt-br'
import Swal from 'sweetalert2'
import { Carousel } from '../../components/Carousel'
import { useImagensProduto } from '../../hooks/produto/useImagensProduto'

export const Produtos = () => {
  const [inputId, setinputId] = useState<number>()
  const [produtoId, setProdutoId] = useState<number>()
  const [showListaModal, setShowListaModal] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900)
  const { data, loading: loadingProduto } = useProduto(produtoId)
  const produtoData = data?.data
  const urlImg = produtoData ? `${process.env.REACT_APP_BASE_PRODUTO_IMG_URL}${produtoData?.id}A.jpg` : noImage
  const { imagens: urlsImages, getImagens } = useImagensProduto()

  const handleAddProdutoById = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const value = parseInt(e.currentTarget.value)
      if (value > 0) {
        setProdutoId(value)
        getImagens(Number(value))
      }
    }
  }

  const handlechangeProdutoId = (e: React.ChangeEvent<HTMLInputElement>) => {
    let id = 0
    let value = e.target.value.replace(/[^0-9]/g, '')

    if (value) {
      id = parseInt(value)
    }

    setinputId(id)
  }

  const handleProdutoSelecionado = (produto: Produto) => {
    if (produto) {
      setProdutoId(produto.id)
      setinputId(produto.id)
      getImagens(Number(produto.id))
      setShowListaModal(false)
    }
  }

  useEffect(() => {
    if (produtoData?.descontinuado === 's' && produtoData.produto_filho !== undefined && produtoData.produto_filho > 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção!',
        html: `Esse produto foi descontinuado <br>(Não será mais comprado). <br>Será substituido pelo produto ${produtoData.produto_filho}`
      })
    }
  }, [produtoData])

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 900)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [isMobile])

  return (
    <MainLayout title="Produtos">
      {loadingProduto && <Loading />}
      <h1>Produtos</h1>
      {!isMobile && (
        <Header>
          <span>
            Adicionar Produto:
          </span>
          <Input
            title='Adicionar produto por ID'
            type='text'
            onKeyPress={handleAddProdutoById}
            onChange={handlechangeProdutoId}
            value={inputId || ''}
            width='0 0 25%'
            autoFocus
            f2Content={<ListaProdutosDetalhes
              handleProdutoSelecionado={handleProdutoSelecionado}
            />}
            f2Title='Lista de Produtos'
            f2ModalMode='fullscreen'
          />
          <Button
            tabIndex={1}
            mode="confirm"
            startIcon={FaPlus}
            type="button"
            onClick={() => setShowListaModal(true)}>
            Lista Produtos
          </Button>
        </Header>
      )}
      <Container>
        <FirstSection>
          {isMobile ? (
            <MobileContent>
              {urlsImages && urlsImages.length > 0 ? (
                <BoxImg>
                  <Carousel imageSrc={urlsImages} />
                </BoxImg>
            ) : (
              <ImageDiv>
                <img
                  src={urlImg}
                  onError={(e) => { e.currentTarget.src = noImage }}
                  alt="Sem imagem"
                />
              </ImageDiv>
            )}
              <Header>
                <span>
                  Adicionar Produto:
                </span>
                <Input
                  title='Adicionar produto por ID'
                  type='text'
                  onKeyPress={handleAddProdutoById}
                  onChange={handlechangeProdutoId}
                  value={inputId || ''}
                  width='0 0 25%'
                  autoFocus
                  f2Content={<ListaProdutosDetalhes
                    handleProdutoSelecionado={handleProdutoSelecionado}
                  />}
                  f2Title='Lista de Produtos'
                  f2ModalMode='fullscreen'
                />
                <Button
                  tabIndex={1}
                  mode="confirm"
                  startIcon={FaPlus}
                  type="button"
                  onClick={() => setShowListaModal(true)}>
                  Lista Produtos
                </Button>
              </Header>
            </MobileContent>
          ) : (urlsImages && urlsImages.length ? (
              <BoxImg>
                <Carousel imageSrc={urlsImages} />
              </BoxImg>
            ) : (
              <ImageDiv>
                <img
                  src={urlImg}
                  onError={(e) => { e.currentTarget.src = noImage }}
                  alt="Sem imagem"
                />
              </ImageDiv>
            )
          )}
          <InfosDiv>
            <FormRow className='produtos'>
              <Input
                label='Código'
                title='Código'
                name='produto.id'
                type='text'
                value={produtoData?.id || ''}
                width='0 0 24%'
                disabled
                copyValue
              />
              <Input
                label='Código de Fabrica'
                title='Código de Fabrica'
                name='produto.codigo_fabrica'
                type='text'
                value={produtoData?.codigo_fabrica || ''}
                width='0 0 24%'
                disabled
                copyValue
              />
              <Input
                label='Status'
                title='Status'
                name='produto.status'
                type='text'
                value={capitalize(produtoData?.status as string) || ''}
                width='0 0 24%'
                disabled
              />
              <Input
                label='Gerente do Produto'
                title='Gerente do Produto'
                name='produto.comprador_nome'
                type='text'
                value={capitalize(produtoData?.comprador_nome as string) || ''}
                width='0 0 24%'
                disabled
              />
            </FormRow>
            <FormRow className='produtos'>
              <Input
                label='Nome Técnico'
                title='Nome Técnico'
                name='produto.nome_tecnico'
                type='text'
                value={produtoData?.nome_tecnico || ''}
                width='0 0 49%'
                disabled
                copyValue
              />
              <Input
                label='Nome Popular'
                title='Nome Popular'
                name='produto.nome_popular'
                type='text'
                value={produtoData?.nome_popular || ''}
                width='0 0 49%'
                disabled
              />
            </FormRow>
            <FormRow className='produtos'>
              <Input
                label='Marca'
                title='Marca'
                name='produto.marca'
                type='text'
                value={capitalize(produtoData?.marca as string) || ''}
                width='0 0 24%'
                disabled
              />
              <Input
                label='Unidade'
                title='Unidade'
                name='produto.unidade'
                type='text'
                value={capitalize(produtoData?.unidade as string) || ''}
                width='0 0 14%'
                disabled
              />
              <Input
                label='Classifcação'
                title='Classifcação'
                name='produto.classificacao_fiscal'
                type='text'
                value={produtoData?.classificacao_fiscal || ''}
                width='0 0 24%'
                copyValue
                disabled
              />
              <Input
                label='Link'
                title='Link'
                name='produto.link'
                type='text'
                value={produtoData?.link || ''}
                width='0 0 34%'
                disabled
                copyValue
              />
            </FormRow>
            <FormRow>
              <CheckBox
                  name="registro_oportunidade"
                  checked={produtoData?.registro_oportunidade === 's'}
                  title="Registro de Oportunidade"
                />
            </FormRow>
          </InfosDiv>
        </FirstSection>
        <SecondSection>
          <span>Peso</span>
          <FormRow className='produtos'>
            <Input
              label='Peso Bruto'
              title='Peso Bruto'
              name='produto.peso_bruto'
              type='text'
              value={produtoData?.peso_bruto || ''}
              width='0 0 24%'
              disabled
            />
            <Input
              label='Peso Líquido'
              title='Peso Líquido'
              name='produto.peso_liquido'
              type='text'
              value={produtoData?.peso_liquido || ''}
              width='0 0 24%'
              disabled
            />
          </FormRow>
        </SecondSection>
        <SecondSection>
          <span>Caixa Individual</span>
          <FormRow className='produtos'>
            <Input
              label='Altura (cm)'
              title='Altura'
              name='produto.caixa_individual_altura'
              type='text'
              value={produtoData?.caixa_individual_altura || ''}
              width='0 0 24%'
              disabled
            />
            <Input
              label='Largura (cm)'
              title='Largura'
              name='produto.caixa_individual_largura'
              type='text'
              value={produtoData?.caixa_individual_largura || ''}
              width='0 0 24%'
              disabled
            />
            <Input
              label='Comprimento (cm)'
              title='Comprimento'
              name='produto.status'
              type='text'
              value={produtoData?.caixa_individual_comprimento || ''}
              width='0 0 24%'
              disabled
            />
          </FormRow>
        </SecondSection>
        <SecondSection>
          <span>Caixa Master</span>
          <FormRow className='produtos'>
            <Input
              label='Altura (cm)'
              title='Altura'
              name='produto.caixa_master_altura'
              type='text'
              value={produtoData?.caixa_master_altura || ''}
              width='0 0 24%'
              disabled
            />
            <Input
              label='Largura (cm)'
              title='Largura'
              name='produto.caixa_master_largura'
              type='text'
              value={produtoData?.caixa_master_largura || ''}
              width='0 0 24%'
              disabled
            />
            <Input
              label='Comprimento (cm)'
              title='Comprimento'
              name='produto.caixa_master_comprimento'
              type='text'
              value={produtoData?.caixa_master_comprimento || ''}
              width='0 0 24%'
              disabled
            />
            <Input
              label='Quantidade'
              title='Quantidade'
              name='produto.qtde_volume'
              type='text'
              value={produtoData?.qtde_volume || ''}
              width='0 0 24%'
              disabled
            />
          </FormRow>
        </SecondSection>
        <SecondSection>
          <span>Observações</span>
          <FormRow>
            <Textarea
              label='Aplicação'
              title='Aplicação'
              value={produtoData?.aplicacao}
              height='90px'
              disabled
            />
          </FormRow>
          <FormRow>
            <Textarea
              label='Observação'
              title='Observação'
              value={produtoData?.observacao}
              height='90px'
              disabled
            />
          </FormRow>
          <FormRow>
            <Textarea
              label='Observação Tela'
              title='Observação Tela'
              value={produtoData?.observacao_tela}
              height='90px'
              disabled
            />
          </FormRow>
        </SecondSection>
        <ThirdSection>
          <div>
            <span>Estoque</span>
            <ListaEstoques estoques={produtoData?.estoques} />
          </div>
          <div>
            <span>Compras</span>
            <ListaComprasProdutos compras={produtoData?.compras} />
          </div>
        </ThirdSection>
      </Container>
      {showListaModal && <Modal mode='fullscreen' close={() => setShowListaModal(false)}>
        <ListaProdutosDetalhes
          handleProdutoSelecionado={handleProdutoSelecionado}
        />
      </Modal>}
    </MainLayout>
  )
}
