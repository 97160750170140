import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
  margin: 10px 0px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.border};
  overflow: hidden;
  position: relative;

  > span:first-of-type {
    padding: 15px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.4px;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`

export const Container = styled.div`
`

export const Header = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  justify-content: flex-end;

  & > div {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
`
