import styled from 'styled-components'

export const Container = styled.div`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  min-height: 65px;
  background: transparent;
  align-items: center;
  padding: 15px;

  > div {
    display: flex;
    flex-direction: row;
    flex: 1;

    & + div {
      width: 200px;
      justify-content: flex-end;
    }
  }
`
