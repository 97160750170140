import styled, { css } from 'styled-components'
import { transparentize } from 'polished'
// import { fadein } from '../../styles/animations'

interface InputContainerProps {
  isActive: boolean;
  error?: string;
  hasStartIcon?: boolean;
  disabled?: boolean;
  hasf2?: boolean;
  color?: string;
  backgroundColor?: string;
}

interface WrapperProps {
  width?: string;
  margin?: string;
  marginRight?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: flex;
  flex: ${(props) => (props.width ? props.width : '1')};
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: ${(props) => (props.margin ? props.margin : '10px 0px')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0')};

  @media (max-width: 900px) {
    & {
      flex: 1;
    }
  }

  @media (max-width: 780px) {
    & {
      flex: 1;
    }
  }
`

export const Label = styled.span<InputContainerProps>`
  display: flex;
  color: ${(props) => props.theme.colors.primaryText} !important;
  opacity: ${(props) => (props.isActive ? '1' : '0.5')};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  > svg {
    height: 14px;
    width: 14px;
    margin-left: 5px;
  }
`

export const Container = styled.div<InputContainerProps>`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 36px;
  background: ${(props) => props.theme.colors.backgroundLight};
  border-radius: 4px;
  ${(props) => {
    let color = ''
    let borderColor = 'transparent'

    if (props.error) {
      color = props.theme.colors.danger
      borderColor = transparentize(0.9, props.theme.colors.danger)
    } else if (props.isActive) {
      color = props.theme.colors.info
      borderColor = transparentize(0.7, props.theme.colors.info)
    } else {
      color = props.theme.colors.border
    }

    return css`
      border: 1px solid ${color};
      box-shadow: inset 0px 0px 3px ${borderColor};
    `
  }};

  svg:first-of-type {
    fill: ${(props) => props.theme.colors.primaryText};
    width: 14px;
    height: 14px;
    transition: fill 0.4s;
  }

  input {
    display: flex;
    flex: 1;
    height: 100%;
    border: none;
    background: ${(props) => {
    if (props.backgroundColor) {
      if (props.backgroundColor === 'success') {
        return transparentize(0.7, props.theme.colors.success)
      } else if (props.backgroundColor === 'danger') {
        return transparentize(0.7, props.theme.colors.danger)
      } else if (props.backgroundColor === 'warning') {
        return transparentize(0.7, props.theme.colors.warning)
      }
    } else if (props.disabled) {
      return props.theme.colors.background
    } else {
      return props.theme.colors.backgroundLight
    }
  }
  };

    border-radius: 4px;
    padding: 0px 20px;
    width: 100%;
    border-left: ${(props) => props.hasStartIcon && 'none'};

    color: ${(props) => {
    if (props.isActive && !props.color) {
      return props.theme.colors.primaryText
    } else if (!props.isActive && !props.color) {
      return props.theme.colors.primaryText
    } else if (props.color) {
      switch (props.color) {
        case 'success':
          return props.theme.colors.success
        case 'danger':
          return props.theme.colors.danger
        case 'warning':
          return props.theme.colors.warning
        default:
          return props.theme.colors.secondary
      }
    }
  }};
  }
`

interface InputIconPasswordProps {
  showPassword?: boolean;
  inputError?: boolean;
}

export const IconPassword = styled.div<InputIconPasswordProps>`
  cursor: pointer;
  position: absolute;
  right: ${(props) => (props.inputError ? '30px' : '10px')};
  width: 20px;
  top: 30%;
  z-index: 8;

  > svg {
    fill: ${(props) =>
    props.showPassword
      ? props.theme.colors.primary
      : props.theme.colors.secondaryText} !important;
  }
`
interface IconError {
  error?: string;
}

export const IconError = styled.div<IconError>`
  position: absolute;
  display: flex;
  right: 5px;
  z-index: 8;
  width: 20px !important;

  > svg {
    fill: ${(props) => props.theme.colors.danger} !important;
  }
`
export const Icon = styled.div<InputContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 100%;
  background-color: ${(props) => props.theme.colors.background};
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;

  ${(props) => {
    let color = props.theme.colors.border

    if (props.error) {
      color = props.theme.colors.danger
    } else if (props.isActive) {
      color = props.theme.colors.info
    }

    return css`
      border-right: solid 1px ${color};
    `
  }}
`
