import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { MiniCard } from '..'
import { Cliente } from '../../../modules/clientes/models'
import { formatFloatToCurrency } from '../../../helpers'
import { AppState } from '../../store'
import { AtendimentosState } from '../../store/atendimentos/interfaces'
import { ModePedido } from '../Pedido'
import { Container } from './styles'

interface ResumoOrcamentoProps {
  cliente: Cliente,
  pedidoIdEmEdicao?: number
  mode: ModePedido
}

export const ResumoOrcamento = ({ cliente, pedidoIdEmEdicao, mode }: ResumoOrcamentoProps) => {
  const atendimentosState = useSelector<AppState>(state => state.atendimentos) as AtendimentosState

  const handleReturnEdit = (emEdicao?: number, original?: number) => {
    if (emEdicao !== undefined && emEdicao >= 0) {
      return emEdicao
    } else if (original !== undefined && original >= 0) {
      return original
    } else {
      return 0
    }
  }

  const subTotal = useMemo(() => {
    if (mode === 'register' && atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.subtotal) {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.subtotal || 0
    } else {
      const emEdicao = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.emEdicao?.subtotal
      const original = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.original?.subtotal
      return handleReturnEdit(emEdicao, original)
    }
  }, [atendimentosState.data, cliente, mode, pedidoIdEmEdicao])

  const itens = useMemo(() => {
    if (mode === 'register') {
      return (atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.qtdeItens || 0)
    } else {
      const emEdicao = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.emEdicao?.qtdeItens
      const original = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.original?.qtdeItens
      return handleReturnEdit(emEdicao, original)
    }
  }, [atendimentosState.data, cliente, mode, pedidoIdEmEdicao])

  const acrescimos = useMemo(() => {
    if (mode === 'register' && atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.acrescimos) {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.acrescimos || 0
    } else {
      const emEdicao = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.emEdicao?.acrescimos as number
      const original = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.original?.acrescimos as number

      return handleReturnEdit(emEdicao, original)
    }
  }, [atendimentosState.data, cliente, mode, pedidoIdEmEdicao])

  const descontos = useMemo(() => {
    if (mode === 'register' && atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.descontos) {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.descontos || 0
    } else {
      const emEdicao = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.emEdicao?.descontos as number
      const original = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.original?.descontos as number
      return handleReturnEdit(emEdicao, original)
    }
  }, [atendimentosState.data, cliente, mode, pedidoIdEmEdicao])

  const descontosPorcentagem = useMemo(() => {
    if (mode === 'register' && atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.descontosPorcentagem) {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.descontosPorcentagem || 0
    } else {
      const emEdicao = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.emEdicao?.descontosPorcentagem as number
      const original = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.original?.descontosPorcentagem as number
      return handleReturnEdit(emEdicao, original)
    }
  }, [atendimentosState.data, cliente, mode, pedidoIdEmEdicao])

  const st = useMemo(() => {
    if (mode === 'register' && atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.st) {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.st || 0
    } else {
      const emEdicao = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.emEdicao?.st as number
      const original = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.original?.st as number
      return handleReturnEdit(emEdicao, original)
    }
  }, [atendimentosState.data, cliente, mode, pedidoIdEmEdicao])

  const difal = useMemo(() => {
    if (mode === 'register' && atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.difal) {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.difal || 0
    } else {
      const emEdicao = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.emEdicao?.difal as number
      const original = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.original?.difal as number
      return handleReturnEdit(emEdicao, original)
    }
  }, [atendimentosState.data, cliente, mode, pedidoIdEmEdicao])

  const ipi = useMemo(() => {
    if (mode === 'register' && atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.ipi) {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.ipi || 0
    } else {
      const emEdicao = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.emEdicao?.ipi as number
      const original = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.original?.ipi as number
      return handleReturnEdit(emEdicao, original)
    }
  }, [atendimentosState.data, cliente, mode, pedidoIdEmEdicao])

  const juros = useMemo(() => {
    if (mode === 'register') {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.juros || 0
    } else {
      const emEdicao = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.emEdicao?.juros as number
      const original = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.original?.juros as number
      return handleReturnEdit(emEdicao, original)
    }
  }, [atendimentosState.data, cliente, mode, pedidoIdEmEdicao])

  const jurosValor = useMemo(() => {
    if (mode === 'register') {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.juros_valor || 0
    } else {
      const emEdicao = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.emEdicao?.juros_valor as number
      const original = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.original?.juros_valor as number
      return handleReturnEdit(emEdicao, original)
    }
  }, [atendimentosState.data, cliente, mode, pedidoIdEmEdicao])

  const valorTotal = useMemo(() => {
    let retorno = 0
    if (mode === 'register') {
      retorno = atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.total || 0
    } else {
      const emEdicao = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.emEdicao?.total as number
      const original = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.original?.total as number
      retorno = handleReturnEdit(emEdicao, original)
    }
    return retorno
  }, [atendimentosState.data, cliente, mode, pedidoIdEmEdicao])

  const status = useMemo(() => {
    if (mode === 'register') {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.status
    } else {
      const emEdicao = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.emEdicao?.status
      const original = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.original?.status
      return emEdicao || original
    }
  }, [atendimentosState.data, cliente, mode, pedidoIdEmEdicao])

  const saldoLimite = useMemo(() => {
    let saldo = atendimentosState.data[cliente?.id as number]?.cliente.saldo_limite || 0
    saldo = saldo - valorTotal
    return saldo
  }, [atendimentosState.data, cliente, valorTotal])

  const peso = useMemo(() => {
    if (mode === 'register') {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.peso_total || 0
    } else {
      const emEdicao = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.emEdicao?.peso_total as number
      const original = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.original?.peso_total as number
      return handleReturnEdit(emEdicao, original)
    }
  }, [atendimentosState.data, cliente, mode, pedidoIdEmEdicao])

  const textDesconto = () => {
    if (descontosPorcentagem > 0) {
      return `${formatFloatToCurrency(descontos)} (${formatFloatToCurrency(descontosPorcentagem)}%)`
    } else {
      return formatFloatToCurrency(descontos)
    }
  }

  const limiteBloqueado = useMemo(() => {
    if (mode === 'register') {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.limite_bloqueado
    } else {
      const original = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number]?.original?.limite_bloqueado
      return original
    }
  }, [atendimentosState.data, cliente, mode, pedidoIdEmEdicao])

  return (
    <Container>
      <MiniCard mode="normal" title="Itens:" text={itens as unknown as string} />
      <MiniCard mode="normal" title="Peso Total:" text={formatFloatToCurrency(peso)} />
      <MiniCard mode="normal" title="Sub total:" text={formatFloatToCurrency(subTotal)} />
      <MiniCard mode="normal" title="Acréscimos:" text={formatFloatToCurrency(acrescimos)} />
      <MiniCard mode="normal" title="Descontos:" text={textDesconto()} />
      <MiniCard mode="normal" title="Juros:" text={`${formatFloatToCurrency(jurosValor)} (${formatFloatToCurrency(juros)}%)`} />
      <MiniCard mode={difal > 0 ? 'danger' : 'normal'} title="DIFAL:" text={formatFloatToCurrency(difal)} />
      <MiniCard mode="normal" title="ST:" text={formatFloatToCurrency(st)} />
      <MiniCard mode="normal" title="IPI:" text={formatFloatToCurrency(ipi)} />
      <MiniCard mode="normal" title="Valor total:" text={formatFloatToCurrency(valorTotal)} />
      {limiteBloqueado === 'n' && <MiniCard mode='success' title='Limite' text='Aprovado' />}
      {(status !== 'faturado' && status !== 'cancelado' && limiteBloqueado === 's') &&
        <MiniCard mode={saldoLimite > 0 ? 'normal' : 'danger'} title="Saldo Limite:" text={formatFloatToCurrency(saldoLimite)} />
      }
    </Container>
  )
}
