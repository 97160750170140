import { RecalcularParams, UseCase, Validator } from '../../_interfaces'
import { RecalcularPedidoService } from '../contracts'
import { GetPedidoResponse, GetPedido } from '../models'
import { sanetizarPedidoCadastro } from '../services/helpers'

export class RecalcularPedido implements UseCase<RecalcularParams, GetPedidoResponse> {
  private readonly recalcularPedidoService: RecalcularPedidoService
  private readonly validator: Validator

  constructor (recalcularPedidoService: RecalcularPedidoService, validator: Validator) {
    this.recalcularPedidoService = recalcularPedidoService
    this.validator = validator
  }

  async execute (params: RecalcularParams): Promise<GetPedidoResponse> {
    const sanetized = sanetizarPedidoCadastro(params.body as GetPedido)
    await this.validator.validate(sanetized)

    return await this.recalcularPedidoService.recalcularPedido({
      body: sanetized,
      token: params.token,
      mode: params.mode
    })
  }
}
