import { GetParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { TrazerEquipePorIdService } from '../contracts/trazer-equipe-por-id-service'
import { GetEquipe } from '../models'

export class TrazerEquipePorIdServiceRest implements TrazerEquipePorIdService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async trazerEquipePorId (params: GetParams): Promise<GetEquipe> {
    let { filter: id, token } = params

    const url = `equipes/${id}`

    const response = await this.httpRequest.get<any>({
      path: url,
      token
    })

    const retorno = response.res?.data

    return retorno
  }
}
