import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
`

export const ContainerColumns = styled.div`
  width: 100%;
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @media (max-width: 1366px) {
    & {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 1000px) {
    & {
      grid-template-columns: 1fr;
    }
  }
`

export const CardProdutosSkeleton = styled.div`
  display: flex;
  width: 100%;
  height: 127px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.borderLight};
  margin-bottom: 15px;
`

export const CardItemSkeleton = styled.div`
  display: flex;
  width: 100%;
  height: 10px;

`
