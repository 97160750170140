import styled, { createGlobalStyle } from 'styled-components'
import { lighten } from 'polished'
import 'react-datepicker/dist/react-datepicker.css'

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;

     /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: ${props => props.theme.colors.backgroundLight};
      border-radius: 4px;
      margin-top: 3px;
      margin-bottom: 50px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${props => props.theme.colors.borderLight};
      border-radius: 4px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${props => props.theme.colors.border};
    }

    ::selection {
      color: ${props => props.theme.colors.info} !important;
      background: ${props => props.theme.colors.borderLight} !important;
    }
  }

  body {
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.primaryText};
    -webkit-font-smoothing: antialiased;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
  }

  button {
    cursor: pointer;
    border: none;
  }

  h2 {
    color: ${props => props.theme.colors.primaryText};
    font-size: 16px;
    letter-spacing: 0.075em;
    font-weight: normal;
  }

  .swal2-popup {
    display: none;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    width: 32em;
    max-width: 100%;
    padding: 1.25em;
    border: none;
    border-radius: 4px;
    background: ${props => props.theme.colors.backgroundLight};
    font-family: inherit;
    font-size: 14px;
  }

  .swal2-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1.8em;
  }

  .swal2-title {
    position: relative;
    max-width: 100%;
    margin: 0 0 .4em;
    padding: 0;
    color: ${props => props.theme.colors.primaryText};
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    word-wrap: break-word;
  }

  .swal2-styled.swal2-confirm {
    border: 0;
    border-radius: 4px;
    background: initial;
    background-color: ${props => props.theme.colors.primary};
    color: #fff;
    font-size: 14px;
  }

  .swal2-html-container {
    color: ${props => props.theme.colors.primaryText} ;
  }


  .swal2-styled.swal2-cancel {
    border: 0;
    border-radius: 4px;
    background: initial;
    background-color: ${props => props.theme.colors.buttonSecondary};
    color: ${props => props.theme.colors.buttonSecondaryText};
    font-size: 14px;
    border: 1px solid ${props => props.theme.colors.border};
  }

  .swal2-icon.swal2-error {
    border-color: ${props => lighten(0.2, props.theme.colors.danger)};
    color: ${props => lighten(0.1, props.theme.colors.danger)};
  }

  .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
    background-color: ${props => lighten(0.1, props.theme.colors.danger)};
  }

  .swal2-icon.swal2-question {
    border-color: ${props => lighten(0.4, props.theme.colors.info)};
    color: ${props => lighten(0.2, props.theme.colors.info)}
  }

  .swal2-icon.swal2-warning {
    border-color: ${props => lighten(0.08, props.theme.colors.warning)};
    color: ${props => lighten(0.04, props.theme.colors.warning)};
  }

  .swal2-close{
    transition: all 0.3s;
  }

  .swal2-close:hover {
    transform: none;
    background: 0 0;
    color: ${props => props.theme.colors.primaryText};
  }

  .swal2-input {
    height: 2.625em;
    padding: 0 .75em;
}

  .swal2-input {
    box-sizing: border-box;
    width: 100%;
    transition: all .3s;
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: 4px;
    background: ${props => props.theme.colors.backgroundLight};
    box-shadow: none;
    color: ${props => props.theme.colors.primaryText};
    font-size: 1.125em;
    height: 36px !important;
  }

  .swal2-input.percentage {
    width: 100px;
  }

  .swal2-input.danger {
    color: ${props => props.theme.colors.danger};
  }

  .swal2-popup.swal2-toast.swal2-show {
    animation: swal2-show .3s;
  }

  .swal2-popup.swal2-toast {
    flex-direction: row;
    align-items: center;
    width: auto;
    padding: 15px !important;
    overflow-y: hidden;
    border: solid 1px ${props => props.theme.colors.border} !important;
    box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.05);
  }

  .rt-tr-group{
    position: relative;
  }
`
interface FormRowProps {
  width?: string
}

export const FormRow = styled.div<FormRowProps>`
  display: flex;
  flex-direction: row;
  width: ${props => props.width ? props.width : '100%'};
  height: auto;
  flex-wrap: wrap;
  align-items: flex-end;

  & > div {
    & + div {
      margin-left: 7px;
    }
  }

  @media (max-width: 900px) {
    &.filtroPedidos {
      flex-direction: column;
      align-items: initial;
      flex-wrap: nowrap;
      & > div {
        & + div {
          margin-left: 0;
        }
      }
    }

    &.dadosGerais {
      flex-direction: column;
      align-items: initial;
      flex-wrap: nowrap;
      & > div {
        & + div {
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 720px) {
    &.mobile {
      flex-direction: column;
    }
    
    &.produtos {
      flex-direction: column;
      align-items: initial;
      & > div {
        & + div {
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 600px) {
    &.filtroPedidosData {
      flex-direction: column;
      align-items: initial;
      & > div {
        & + div {
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 500px) {
    &.chamados {
      flex-direction: column;
      & > div {
        & + div {
          margin-left: 0;
        }
      }
    }
  }

`

interface TextStatusProps {
  status?: 'ativo' | 'inativo' | 'sim' | 'nao' | 's' | 'n' | 'aprovado' | 'rejeitado'
}

export const TextStatus = styled.div<TextStatusProps>`
  width: 100%;
  text-align: center;
  color: ${props => {
    let color
    if (props.status === 'ativo' || props.status === 'sim' || props.status === 's' || props.status === 'aprovado') {
      color = props.theme.colors.success
    } else if (props.status === 'inativo' || props.status === 'nao' || props.status === 'n' || props.status === 'rejeitado') {
      color = props.theme.colors.danger
    } else {
      color = 'inherit'
    }

    return color
  }};

  font-weight: ${props => props.status ? '600' : 'normal'};
`
interface TablePossibilidadesProps {
  background?: string;
  position: 'center' | 'right' | 'left'
}

export const TablePossibilidades = styled.div<TablePossibilidadesProps>`
  background-color: ${props => {
    if (props.background === 'baixa') {
      return '#1f5ccc'
    } else if (props.background === 'media') {
      return '#ccb51f'
    } else {
      return '#c21919'
    }
  }};
  text-align: ${props => {
    if (props.position === 'center') {
      return 'center'
    } else if (props.position === 'right') {
      return 'right'
    } else if (props.position === 'left') {
      return 'left'
    }
  }};
  color: white;
  font-weight: bold;
`

interface TableTextProps {
  position: 'center' | 'right' | 'left'
  color?: string
  background?: string;
}

export const TableText = styled.div<TableTextProps>`
  width: 100%;
  text-align: ${props => {
    if (props.position === 'center') {
      return 'center'
    } else if (props.position === 'right') {
      return 'right'
    } else if (props.position === 'left') {
      return 'left'
    }
  }};
  color: ${props => {
    switch (props.color) {
      case 'success':
        return props.theme.colors.success
      case 'danger':
        return props.theme.colors.danger
      case 'warning':
        return props.theme.colors.warning
      default:
        return 'inherit'
    }
  }};
  font-weight: ${props => props.color ? '600' : 'inherit'};
  padding: 0px 5px;

`
export const TableActions = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  > button:first-of-type {
    margin-right: 5px;
  }

  > button + button{
    margin-right: 5px;
  }
`

// export type ModeColorProps = 'danger' | 'success' | 'warning' | 'default'

// type TextColorProps = {
//   mode: ModeColorProps
// }

// export const TextColor = styled.span<TextColorProps>`
//   color: ${props => {
//     if (props.mode === 'default') {
//       return 'inherit'
//     } else if (props.mode === 'success') {
//       return props.theme.colors.success
//     } else if (props.mode === 'danger') {
//       return props.theme.colors.danger
//     } else if (props.mode === 'warning') {
//       return props.theme.colors.warning
//     } else {
//       return 'inherit'
//     }
//   }};
//   font-weight: ${props => props.mode !== 'default' ? '600' : 'inherit'};
// `
