import { useState } from 'react'
import { useUsuario } from '..'
import { makeListarFuncionarios } from '../../../modules/funcionarios/factories'
import { GetListFuncionarioResponse } from '../../../modules/funcionarios/models'
import { GetParams } from '../../../modules/_interfaces'
import { SortTableProps } from '../../../modules/_interfaces/sortTable'
import { useHandleErrors } from '../app/useHandleErrors'

const listarFuncionarios = makeListarFuncionarios()

interface FetcherProps {
  perPage: number,
  currentPage: number,
  search: string,
  filterObject: object,
  sortTable?: SortTableProps
}

export function useFuncionarios () {
  const { data: usuarioData } = useUsuario()
  const [isLoading, setIsLoading] = useState(false)
  const { handleError, executing } = useHandleErrors()
  const [data, setData] = useState<GetListFuncionarioResponse>()

  async function fetcher ({ perPage = 20, currentPage, search, filterObject, sortTable }: FetcherProps) {
    let params = {
      token: usuarioData?.token as string,
      filterOptions: {
        limit: perPage,
        skip: (currentPage - 1) * perPage
      },
      filter: search,
      filterObject
    } as GetParams
    if (sortTable && sortTable.id) {
      if (sortTable.desc) {
        params.filterOptions = { ...params.filterOptions, order: '-funcionarios.' + sortTable.id }
      } else {
        params.filterOptions = { ...params.filterOptions, order: 'funcionarios.' + sortTable.id }
      }
    }
    const response = await listarFuncionarios.execute(params)

    if (response.error && !executing) {
      handleError(response.error)
    }

    setData(response)
    setIsLoading(false)
  }

  return { fetcher, data, isLoading }
}
