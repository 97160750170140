import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { BsPeopleCircle } from 'react-icons/bs'
import { Input, Button, Modal } from '../../components'
import { MainLayout } from '../../layouts/MainLayout'
import { FaEnvelope, FaLock, FaPen, FaSave, FaCheck } from 'react-icons/fa'
import { useDropzone } from 'react-dropzone'
import { Container, Avatar, Form, Data, SelectAvatar, ContentModal, Text } from './styles'
import { useExecUseCase, useUsuario } from '../../hooks'
import { PostParams } from '../../../modules/_interfaces'
import userIcon from '../../assets/imgs/userIcon.svg'
import capitalize from 'capitalize-pt-br'
import { formatRelative } from 'date-fns'
import pt from 'date-fns/locale/pt-BR'
import { makeAtualizarUsuario } from '../../../modules/usuarios/factories'

interface Body {
  id: number,
  image?: string,
  password?: string
}

export const Perfil = () => {
  const [showModal, setShowModal] = useState(false)
  const [avatarFile, setAvatarFile] = useState('')
  const [perfilAvatar, setPerfilAvatar] = useState('')
  const { data: usuario, setData: setUsuario } = useUsuario()
  const [originalAvatar, setOriginalAvatar] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    if (usuario?.avatar) {
      setOriginalAvatar(usuario?.avatar)
    }
  }, [usuario])

  const { executeUseCase, executing } = useExecUseCase()

  const onDrop = useCallback(avatarFiles => {
    setAvatarFile(avatarFiles[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleSenhaOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsEditing(true)
    setPassword(e.currentTarget.value)
  }, [])

  const handleConfirmSenhaOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setError('')
    setConfirmPassword(e.currentTarget.value)
  }, [])

  const handleOnSaveAvatar = useCallback(() => {
    setPerfilAvatar(avatarFile)
  }, [avatarFile])

  const getPreviewAvatar = useCallback((url) => {
    return url ? URL.createObjectURL(url as unknown as string) as unknown as string : undefined
  }, [])

  const editAvatarOnClick = () => {
    if (perfilAvatar) {
      setAvatarFile(perfilAvatar)
    }

    setShowModal(true)
  }

  const handleModalOnClose = () => {
    setAvatarFile('')
    setShowModal(false)
  }

  const handleSalvarAlteracoes = useCallback(async () => {
    if (password) {
      if (password !== confirmPassword) {
        setError('As senhas não conferem')
        return
      }
    }

    let body: Body = {
      id: usuario?.id as number
    }

    if (perfilAvatar) {
      body.image = perfilAvatar
    }

    if (password) {
      body.password = password
    }

    const atualizarUsuario = makeAtualizarUsuario()

    const response = await executeUseCase<PostParams>(
      atualizarUsuario,
      {
        body
      },
      'Usuário atualizado',
      'Atualizar seus dados de perfil?'
    )

    setIsEditing(false)
    setPerfilAvatar('')
    setUsuario(response)
  }, [confirmPassword, executeUseCase, password, perfilAvatar, setUsuario, usuario])

  const getDate = () => {
    const date = new Date(String(usuario?.updated_at))
    return formatRelative((new Date(), date), new Date(), { locale: pt })
  }

  return (
    <MainLayout title="Perfil">
      <Container>
        <h1>Perfil</h1>

        <header>
          <Avatar border={perfilAvatar ? 'danger' : 'normal'}>
            {(!!perfilAvatar || !!originalAvatar)
              ? <img
                src={getPreviewAvatar(perfilAvatar as string) || `${process.env.REACT_APP_BASE_USUARIO_IMG_URL}${usuario?.avatar}`}
                alt={usuario?.nome}
              />
              : <BsPeopleCircle size={100} />
            }
            <div onClick={editAvatarOnClick} ><FaPen /></div>
          </Avatar>
          <Data>
            <strong>{usuario?.nome ? capitalize(usuario?.nome as string) : usuario?.email}</strong>
            <span>Equipe {usuario?.equipe_id || ''} - {usuario?.equipe_nome && capitalize(usuario?.equipe_nome)}</span>
            <p>Última atualização: {getDate()}</p>
          </Data>
        </header>

        <Form>
          <Input
            startIcon={FaEnvelope}
            placeholder="E-mail"
            value={usuario?.email}
            title="E-mail"
            label="E-mail"
            disabled
          />
          <Input
            data-cy='perfilPasswordInput'
            startIcon={FaLock}
            placeholder="Nova senha"
            type="password"
            value={password}
            title="Nova senha"
            label="Nova senha"
            onChange={handleSenhaOnChange}
          />
          {isEditing && <Input
            data-cy='perfilConfirmPasswordInput'
            name='perfilConfirmPasswordInput'
            startIcon={FaCheck}
            placeholder="Confirme a senha"
            type="password"
            value={confirmPassword}
            title="Confirme a senha"
            label="Confirme a senha"
            onChange={handleConfirmSenhaOnChange}
            error={error}
          />}

          <footer>
            <Button
              data-cy='perfilSaveButton'
              disabled={!confirmPassword && executing}
              startIcon={FaSave}
              mode="confirm"
              onClick={handleSalvarAlteracoes}
            >Salvar Alterações</Button>
          </footer>
        </Form>

        {!!showModal && (
          <Modal
            title="Foto do perfil"
            showButtonSave={!!avatarFile}
            buttonSaveText="OK"
            close={handleModalOnClose}
            onSave={handleOnSaveAvatar}
          >
            <ContentModal>
              {
                <SelectAvatar {...getRootProps()}>
                  <img src={getPreviewAvatar(avatarFile) || userIcon} alt='avatarImg' />
                  <input {...getInputProps()} />
                </SelectAvatar>
              }

              <Text>
                {
                  isDragActive
                    ? <p>Solte a foto...</p>
                    : <p><strong>Arraste </strong>uma foto, <strong>ou clique</strong> para selecionar uma foto de seu computador.</p>
                }
              </Text>
            </ContentModal>
          </Modal>
        )}
      </Container>
    </MainLayout>

  )
}
