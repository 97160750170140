import { BloquearDescontoService } from '../contracts'
import { bloquearDescontoParams } from '../services'

export class BloquearDesconto {
  private readonly BloquearDescontoService: BloquearDescontoService

  constructor (BloquearDescontoService: BloquearDescontoService) {
    this.BloquearDescontoService = BloquearDescontoService
  }

  async execute (params: bloquearDescontoParams): Promise<boolean> {
    if (!params?.id) {
      return false
    }

    let response = await this.BloquearDescontoService.bloquearDesconto(params.id, params.token)

    if (response) {
      return true
    } else {
      return false
    }
  }
}
