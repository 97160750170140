import { generateSearchQuery } from '../../../helpers'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { ListarPromocoesService } from '../contracts'
import { GetPromocoesListResponse } from '../models'
import { GetPromocoesParams } from '../models/getPromocoes'
import { ListarPromocaoResApi } from './_interfaces'
export class ListarPromocoesServiceRest implements ListarPromocoesService {
  private readonly http: HttpRequest

  constructor (http: HttpRequest) {
    this.http = http
  }

  async listarPromocoes (params: GetPromocoesParams): Promise<GetPromocoesListResponse> {
    let { filter, filterOptions, filterObject, token, body } = params
    const query = generateSearchQuery(filter, filterOptions, filterObject)

    const response = await this.http.post<ListarPromocaoResApi>({
      path: 'produtos/promocoes' + query,
      body,
      token
    })

    const retorno = {
      data: response?.data?.data,
      metadata: response?.data?.metadata,
      error: response.error
    } as unknown as GetPromocoesListResponse

    return retorno
  }
}
