import React, { useCallback, useState } from 'react'
import { ButtonTable, Select, Table, TableColumnProps } from '../../components'
import { MainLayout } from '../../layouts/MainLayout'

import { Container, HeaderList } from './styles'

import { format } from 'date-fns'
import { FaEnvelope, FaEnvelopeOpen } from 'react-icons/fa'
import Swal from 'sweetalert2'
import { makeMarcarNotificacaoLida } from '../../../modules/notificacoes/factories'
import { Notificacao } from '../../../modules/notificacoes/models'
import { useNotificacao, useUsuario } from '../../hooks'
import { TableActions, TableText } from '../../styles/global'

const rowsPerPage = 20

export const Notificacoes = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [filter, setFilter] = useState({
    lidas: false,
    filterOptions: {
      limit: rowsPerPage,
      skip: currentPage * rowsPerPage,
      order: '-notificacoes.created_at'
    }
  })
  const { data: dataUser } = useUsuario()
  const { data: dataNotificacoes, isValidating, mutate } = useNotificacao(filter)
  const naoLidas = dataNotificacoes?.metadata ? dataNotificacoes.metadata.count : 0

  const marcarNotificao = makeMarcarNotificacaoLida()

  const handleChangeFiltro = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const key = e.target.value
    switch (key) {
      case 'l':
        setFilter({ ...filter, lidas: true })
        break
      case 'n':
        setFilter({ ...filter, lidas: false })
        break
    }
  }

  const handleOnPageChange = useCallback((page: number) => {
    if (!dataNotificacoes?.data) {
      return
    }

    setCurrentPage(page)

    const newFilterOptions = {
      ...filter.filterOptions,
      skip: (page) * rowsPerPage
    }

    setFilter({
      lidas: filter.lidas,
      filterOptions: newFilterOptions
    })
  }, [dataNotificacoes, filter])

  const handleMarcarLida = async (id?: string | number) => {
    if (id) {
      const noteId = typeof id === 'string' ? parseInt(id) : id
      const resp = await marcarNotificao.execute({
        token: dataUser?.token as string,
        id: noteId
      })
      if (resp) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Marcado como Lido!',
          showConfirmButton: false,
          timer: 1500
        })
        mutate()
      }
    }
  }

  const columns: TableColumnProps[] = [
    {
      Header: '#',
      accessor: 'id',
      minWidth: 15,
      Cell: ({ value }) => <TableText position='center'>{value}</TableText>
    },
    {
      Header: 'Data',
      accessor: 'updated_at',
      minWidth: 15,
      Cell: ({ value }) => <TableText position='center'>{format(new Date(value), 'dd/MM/yyyy')}</TableText>
    },
    {
      Header: 'Mensagem',
      accessor: 'mensagem',
      Cell: ({ value }) => <TableText position='left'>{value}</TableText>
    },
    {
      Header: 'Ações',
      minWidth: 20,
      Cell: ({ row }) => {
        const notificacao = row._original as Notificacao
        return (
          <TableActions>
            <ButtonTable
              typeButton='primary'
              title="Marcar como lida"
              onClick={() => handleMarcarLida(notificacao.id)}
              disabled={notificacao.lida}
            >{!notificacao.lida ? <FaEnvelope size='15px' /> : <FaEnvelopeOpen size='15px' /> }
            </ButtonTable>
          </TableActions>
        )
      }
    }
  ]

  return (
    <MainLayout title="Notificações">
      <h1>Notificações</h1>
      <Container>
        <HeaderList>
          <span>{naoLidas > 0
            ? 'Você possui ' + naoLidas + ' notificações não lidas.'
            : 'Nenhuma nova notificação'
          }</span>
          <Select title='Filtro' onChange={handleChangeFiltro} width='0 0 30%'>
            <option value="n">Somente Não Lidas</option>
            <option value="l">Somente Lidas</option>
          </Select>
        </HeaderList>
        <Table
          columns={columns}
          showPageJump={false}
          data={dataNotificacoes?.data}
          page={currentPage}
          pageSize={rowsPerPage}
          pages={dataNotificacoes?.metadata && Math.ceil(dataNotificacoes?.metadata?.count / rowsPerPage)}
          sortable={false}
          loading={isValidating}
          onPageChange={handleOnPageChange}
          showPageSizeOptions={false}
          noDataText="Nenhuma Notificação registrada"
          nextText='Próximo'
          ofText='de'
          previousText='Anterior'
          showPagination={dataNotificacoes?.metadata && dataNotificacoes?.metadata.count >= rowsPerPage}
          pageText='Página'
          selectRow
          manual
        />
        {/* <BodyList>{dataNotificacoes?.data
        ? dataNotificacoes?.data.map((notificacao, index) => <CardNotificao key={'ni' + index}>
          <span>{notificacao.mensagem}</span>
          <div>
            {!notificacao.lida && <Button mode='confirm' type='button' onClick={() => handleMarcarLida(notificacao.id)}>Marcar como Lida</Button>}
          </div>
        </CardNotificao>)
        : <NoData><span>Nenhuma Notificação Registrada</span></NoData>
      }
      </BodyList> */}
      </Container>
    </MainLayout>
  )
}
