import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  margin: 10px 0px;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 7px;
`

export const HeaderList = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  > span {
    margin: 10px 0px;
    font-size: 16px;
    font-weight: bold;
  }

  @media (max-width: 670px) {
    & {
      flex-direction: column;
    }
  }
`

export const BodyList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > div:first-of-type {
    margin-bottom: 10px;
  }
  > div + div {
    margin-bottom: 10px;
  }
`

export const CardNotificao = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 7px;
  justify-content: space-between;
  padding: 10px;
  > span {
    font-size: 16px;
    font-weight: bold;
  }
`

export const NoData = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 15px 0px;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 7px;
  > span {
    font-size: 16px;
    font-weight: bold;
  }
`
