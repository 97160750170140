import { handleErrors } from '../../../helpers/handleErrors'
import { PostParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { CancelarLiberarEstoquePedidoService } from '../contracts'

export class CancelarLiberarEstoquePedidoServiceRest implements CancelarLiberarEstoquePedidoService {
  private readonly http: HttpRequest

  constructor (http: HttpRequest) {
    this.http = http
  }

  async cancelarLiberarEstoquePedido (params: PostParams): Promise<boolean> {
    const { pedidoId } = params.body
    const response = await this.http.put({
      path: `pedidos/${pedidoId}/cancelar_liberacao_estoque`,
      token: params.token,
      body: {}
    })

    handleErrors(response?.error)
    return response?.status === 204
  }
}
