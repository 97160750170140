import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaPlus } from 'react-icons/fa'
import Swal from 'sweetalert2'
import {
  Button,
  Input,
  Modal,
  Table,
  TableColumnProps,
  Loading,
  ListaLotes,
  ListaProdutos
} from '../..'
import { AdicionarProduto } from './AdicionarProduto'
import { Cliente } from '../../../../modules/clientes/models'
import { formatFloatToCurrency } from '../../../../helpers'
import { TableActions, TableText } from '../../../styles/global'
import { ModePedido } from '../../Pedido'
import { Container, Header, Content } from './styles'
import capitalize from 'capitalize-pt-br'
import { AppState } from '../../../store'
import {
  AtendimentosState,
  ErrosPedido
} from '../../../store/atendimentos/interfaces'

import {
  setErroCampoValorPedidoEmAndamento,
  setPedidoRecalculado,
  setCampoValorPedidoEmAndamento,
  setCampoValorPedidoEmAberto
} from '../../../store/atendimentos/actions'
import { Item, GetPedido } from '../../../../modules/pedidos/models'
import { useCalcPedido } from '../../../hooks'
import { useHandleErrors } from '../../../hooks/app/useHandleErrors'
import { AcoesProdutos } from '../../AcoesProdutos'
import { Lote, Produto } from '../../../../modules/produtos/models'
import { DetalhePromocao } from './DetalhePromocao'

interface ProdutosProps {
  cliente: Cliente
  revenda?: Cliente
  mode: ModePedido
  orcamentoIdEmEdicao?: number,
  solar: 's' | 'n'
}

export const Produtos = ({ cliente, revenda, mode, orcamentoIdEmEdicao, solar }: ProdutosProps) => {
  const dispatch = useDispatch()
  const { handleError } = useHandleErrors()
  const atendimentosState = useSelector<AppState>((state) => state.atendimentos) as AtendimentosState

  const pedidoEmAndamento = atendimentosState.data[cliente.id as number]?.pedidoEmAndamento
  const pedidoEmAberto = atendimentosState.data[cliente.id as number]?.pedidosEmAberto.data[orcamentoIdEmEdicao as number]

  const inputBuscarProdutoPorId = useRef<HTMLInputElement>(null)

  const [showProdutoModal, setShowProdutoModal] = useState(false)
  const [showProdutoIdModal, setShowProdutoIdModal] = useState(false)

  const [showModalPromocao, setShowModalPromocao] = useState(false)
  const [promocaoSelected, setPromocaoSelected] = useState<Item[]>({} as Item[])

  const [showTrocaLote, setShowTrocaLote] = useState(false)
  const [indexTrocaLote, setIndexTrocaLote] = useState<number | undefined>()

  const [produtoId, setProdutoId] = useState<number | undefined>() // input value
  const [addProdutoId, setAddProdutoId] = useState<number | undefined>() // valor passado pro adicionar produto
  const [produtoSelected, setProdutoSelected] = useState<Item>({} as Item)

  const [disableButtonAdicionar, setDisableButtonAdicionar] = useState(false)
  const [loadingButtonAdicionar, setLoadingButtonAdicionar] = useState(false)
  const [fakeItemId, setFakeItemId] = useState(1)
  const [quantidadeInvalida, setQuantidadeInvalida] = useState(false)

  const {
    recalcularPedido,
    loading: loadingRecacular,
    buildPedidoNewItem,
    buildPedidoRemoveItem,
    buildPedidoNewArray,
    buildPedidoNewItemKit
  } = useCalcPedido()

  const deposito = useMemo(() => {
    if (mode === 'register') {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.deposito
    } else {
      const depositoEdicao = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[orcamentoIdEmEdicao as number].emEdicao?.deposito
      const depositoOriginal = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[orcamentoIdEmEdicao as number].original?.deposito
      return depositoEdicao || depositoOriginal
    }
  }, [atendimentosState, cliente, mode, orcamentoIdEmEdicao])

  const erros = (mode === 'register'
    ? atendimentosState.data[cliente.id as number]?.pedidoEmAndamento?.erros
    : atendimentosState.data[cliente.id as number]?.pedidosEmAberto.data[orcamentoIdEmEdicao as number].erros) as ErrosPedido

  const bloquearAlteracoes = useMemo(() => {
    if (mode === 'register') {
      return pedidoEmAndamento?.pedido?.bloquear_alteracoes
    } else if (mode === 'edit') {
      const original = pedidoEmAberto?.original?.bloquear_alteracoes
      return original
    }
  }, [pedidoEmAndamento, pedidoEmAberto, mode])

  const handleAdicionarProdutoOnCLick = useCallback(() => {
    if (bloquearAlteracoes?.status) {
      Swal.fire({
        icon: 'warning',
        title: 'Alterações Bloqueadas!',
        html: bloquearAlteracoes?.message
      })
      return
    }
    if (mode === 'register') {
      dispatch(
        setErroCampoValorPedidoEmAndamento({
          clienteId: cliente.id as number,
          campo: 'itens',
          valor: null
        })
      )
    }

    setShowProdutoModal(true)
  }, [bloquearAlteracoes, cliente.id, dispatch, mode])

  const itensPedido = useMemo(() => {
    if (mode === 'register') {
      return atendimentosState.data[cliente.id as number]?.pedidoEmAndamento?.pedido?.itens
    } else {
      let emEdicao = atendimentosState.data[cliente.id as number]?.pedidosEmAberto.data[orcamentoIdEmEdicao as number]?.emEdicao?.itens
      const original = atendimentosState.data[cliente.id as number]?.pedidosEmAberto.data[orcamentoIdEmEdicao as number]?.original?.itens

      // mesmo atualizando não atualiza abaixo
      if (emEdicao) {
        emEdicao = emEdicao?.map(i => {
          let falta_liberar = Number(i.falta_liberar || 0)

          const busca = original?.filter(o => o.id === i.id) || []

          if (busca.length > 0) {
            falta_liberar = falta_liberar - Number(busca[0].quantidade_liberada || 0)
          }

          return {
            ...i,
            falta_liberar: falta_liberar
          }
        })
      }

      return emEdicao || original
    }
  }, [atendimentosState.data, cliente.id, mode, orcamentoIdEmEdicao])

  const handleDeleteRowTable = useCallback(
    async (rowIndex: number) => {
      if (bloquearAlteracoes?.status) {
        Swal.fire({
          icon: 'warning',
          title: 'Alterações Bloqueadas!',
          html: bloquearAlteracoes?.message
        })
        return
      }
      const { isConfirmed } = await Swal.fire({
        icon: 'warning',
        title: 'Você deseja remover o item do pedido?',
        confirmButtonText: 'Sim, remover o item do pedido!',
        showCancelButton: true,
        cancelButtonText: 'Cancelar'
      })

      if (isConfirmed) {
        const deletedId = itensPedido ? itensPedido[rowIndex].produto?.id : undefined
        setProdutoId(deletedId)

        if (mode === 'register') {
          const newPedido = buildPedidoRemoveItem({
            mode,
            pedido: pedidoEmAndamento,
            itemIndex: rowIndex,
            zerarFrete: true
          })
          const resp = await recalcularPedido({ pedido: newPedido, mode })
          if (resp && resp.data) {
            dispatch(
              setPedidoRecalculado({
                cliente_id: cliente.id as number,
                data: resp.data.data as unknown as GetPedido
              })
            )
          } else if (resp && resp.error) {
            handleError(resp.error)
          }
        } else {
          if (itensPedido && itensPedido[rowIndex].falta_liberar === 0) {
            Swal.fire({
              icon: 'warning',
              title: 'Ação invalida!',
              text:
                'O item que está tentando remover já foi liberado e não pode mais ser alterado.',
              showConfirmButton: true
            })
            return
          }
          if (itensPedido) {
            const newPedido = buildPedidoRemoveItem({
              mode,
              pedido: pedidoEmAberto,
              itemId: itensPedido[rowIndex].id,
              itemIndex: rowIndex,
              zerarFrete: true
            })

            const resp = await recalcularPedido({ pedido: newPedido, mode })
            if (resp && resp.data) {
              dispatch(
                setPedidoRecalculado({
                  cliente_id: cliente.id as number,
                  data: resp.data.data as unknown as GetPedido,
                  pedido_id: newPedido.id as number
                })
              )
            } else if (resp && resp.error) {
              handleError(resp.error)
            }
          }
        }
        inputBuscarProdutoPorId.current && inputBuscarProdutoPorId.current.focus()
      }
    },
    [bloquearAlteracoes, buildPedidoRemoveItem, cliente, dispatch, handleError, itensPedido, mode, pedidoEmAberto, pedidoEmAndamento, recalcularPedido]
  )

  const handleDbClickCallback = (item: Item) => {
    if (item && item.produto) {
      const index =
        itensPedido && itensPedido.findIndex((x) => x.id === item.id)
      setProdutoId(item.produto.id)
      handleDeleteRowTable(index as number)
    }
  }

  const handleTrocaLoteItem = useCallback(
    (index: number) => {
      if (index !== undefined && deposito?.id) {
        setIndexTrocaLote(index)
        setShowTrocaLote(true)
      }
    },
    [deposito]
  )

  const handleCallbackTrocaLote = (lote: Lote) => {
    setShowTrocaLote(false)
    if (itensPedido && indexTrocaLote !== undefined) {
      const newsItems = itensPedido.map((item, index) => {
        if (indexTrocaLote === index) {
          return {
            ...item,
            lote: lote.lote
          }
        } else {
          return item
        }
      })

      if (mode === 'register') {
        dispatch(
          setCampoValorPedidoEmAndamento({
            clienteId: cliente.id as number,
            campo: 'itens',
            valor: newsItems
          })
        )
      } else {
        dispatch(
          setCampoValorPedidoEmAberto({
            clienteId: cliente.id as number,
            pedidoId: orcamentoIdEmEdicao as number,
            campo: 'itens',
            valor: newsItems
          })
        )
      }
    }
  }

  // const getTaxa = useCallback(() => {
  //   let taxa = 0
  //   if (mode === 'register') {
  //     taxa = pedidoEmAndamento?.pedido?.condicao_pagamento
  //       ? getJuros(pedidoEmAndamento?.pedido?.condicao_pagamento)
  //       : 0
  //   } else {
  //     if (
  //       pedidoEmAberto.emEdicao &&
  //       pedidoEmAberto.emEdicao.condicao_pagamento
  //     ) {
  //       taxa = pedidoEmAberto?.emEdicao?.condicao_pagamento
  //         ? getJuros(pedidoEmAberto?.emEdicao?.condicao_pagamento)
  //         : 0
  //     } else {
  //       taxa = pedidoEmAberto?.original?.condicao_pagamento
  //         ? getJuros(pedidoEmAberto?.original?.condicao_pagamento)
  //         : 0
  //     }
  //   }
  //   return taxa
  // }, [mode, pedidoEmAberto, pedidoEmAndamento])

  // const addJuros = useCallback(
  //   (valor: number) => {
  //     return valor + valor * getTaxa()
  //   },
  //   [getTaxa]
  // )

  const quantidadeXestoque = (item: Item) => {
    let retorno = ''
    if (item.estoque_atual !== undefined) {
      if (item.quantidade <= item.estoque_atual) {
        retorno = 'success'
      } else if (item.estoque_atual === 0) {
        retorno = 'danger'
      } else {
        retorno = 'danger'
      }
    }
    return retorno
  }

  const columns: TableColumnProps[] = useMemo(() => [
    {
      Header: '#',
      accessor: 'id',
      minWidth: 15,
      Cell: ({ row }) => (
        <TableText position="center">{row._original.produto.id}</TableText>
      )
    },
    {
      Header: 'Descrição',
      accessor: 'descricao',
      minWidth: 80,
      Cell: ({ row }) => (
        <TableText position="left">
          {row._original.lote !== ''
            ? `${capitalize(row._original.produto.nome_tecnico)} - Lote: ${row._original.lote
            }`
            : capitalize(row._original.produto.nome_tecnico)}
        </TableText>
      )
    },
    {
      Header: 'Qtde',
      accessor: 'quantidade',
      minWidth: 20,
      Cell: ({ row }) => (
        <TableText position="right" color={quantidadeXestoque(row._original)}>
          {row._original.quantidade}
        </TableText>
      )
    },
    {
      Header: 'Falta liberar',
      accessor: 'falta_liberar',
      minWidth: 20,
      Cell: ({ row }) => (
        <TableText position="right">{row._original.falta_liberar}</TableText>
      )
    },
    {
      Header: 'Preço',
      accessor: 'preco',
      minWidth: 20,
      Cell: ({ row }) => (
        <TableText position="right">
          {formatFloatToCurrency(row._original.preco.valor)}
        </TableText>
      )
    },
    {
      Header: 'Valor',
      accessor: 'valor',
      minWidth: 20,
      Cell: ({ row }) => (
        <TableText position="right">
          {formatFloatToCurrency(row._original.valor)}
        </TableText>
      )
    },
    {
      Header: 'Acres',
      accessor: 'acres',
      minWidth: 20,
      Cell: ({ row }) => (
        <TableText position="right">
          {formatFloatToCurrency(row._original.acrescimo)}
        </TableText>
      )
    },
    {
      Header: 'Desc',
      accessor: 'desc',
      minWidth: 20,
      Cell: ({ row }) => (
        <TableText position="right">
          {formatFloatToCurrency(row._original.desconto)}
        </TableText>
      )
    },
    {
      Header: 'ST',
      accessor: 'st',
      minWidth: 20,
      Cell: ({ row }) => (
        <TableText position="right">
          {formatFloatToCurrency(row._original.stTotal)}
        </TableText>
      )
    },
    {
      Header: 'IPI',
      accessor: 'ipi',
      minWidth: 20,
      Cell: ({ row }) => (
        <TableText position="right">
          {formatFloatToCurrency(row._original.ipiTotal)}
        </TableText>
      )
    },
    {
      Header: 'Valor unit.',
      accessor: 'valor_unit',
      minWidth: 20,
      Cell: ({ row }) => {
        return (
          <TableText position="right">
            {formatFloatToCurrency(row._original.valorUnitario)}
          </TableText>
        )
      }
    },
    {
      Header: 'Total',
      accessor: 'total',
      minWidth: 20,
      Cell: ({ row }) => (
        <TableText position="right">
          {formatFloatToCurrency(row._original.total)}
        </TableText>
      )
    },
    {
      Header: 'Ações',
      minWidth: 20,
      Cell: ({ row, index }) => {
        return (
          <TableActions>
            <AcoesProdutos
              item={row._original}
              index={index}
              handleDeleteProduto={handleDeleteRowTable}
              handleTrocaLote={handleTrocaLoteItem}
            />
          </TableActions>
        )
      }
    }
  ], [handleDeleteRowTable, handleTrocaLoteItem])

  const rowsPerPage = useMemo(() => {
    let rows = 30
    let numItens = 0

    if (mode === 'register') {
      numItens = atendimentosState?.data[cliente.id as number]?.pedidoEmAndamento?.pedido?.itens?.length as number
    } else {
      const numeItemOriginal = atendimentosState?.data[cliente.id as number]?.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original?.itens?.length as number
      const numeItemEmEdicao = atendimentosState?.data[cliente.id as number]?.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.emEdicao?.itens?.length as number
      numItens = (numeItemEmEdicao || numeItemOriginal) as number
    }

    if (numItens > rows) rows = numItens

    return rows
  }, [atendimentosState, cliente.id, mode, orcamentoIdEmEdicao])

  const handleAddProdutoById = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (bloquearAlteracoes?.status) {
        Swal.fire({
          title: 'Alterações Bloqueadas!',
          html: bloquearAlteracoes?.message,
          icon: 'error',
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      if (mode === 'register') {
        dispatch(
          setErroCampoValorPedidoEmAndamento({
            clienteId: cliente.id as number,
            campo: 'itens',
            valor: null
          })
        )
      }
      if (produtoId !== undefined && produtoId > 0) {
        setAddProdutoId(produtoId)
        setShowProdutoIdModal(true)
      }
    }
  }

  const temFreteValor = useCallback(() => {
    let retorno = false
    if (mode === 'register') {
      if (
        pedidoEmAndamento &&
        (pedidoEmAndamento.pedido.frete_cotacao ||
          pedidoEmAndamento.pedido.frete_valor)
      ) {
        retorno = true
      }
    } else {
      if (pedidoEmAberto) {
        if (
          pedidoEmAberto.emEdicao &&
          (pedidoEmAberto.emEdicao.frete_cotacao ||
            pedidoEmAberto.emEdicao.frete_valor)
        ) {
          retorno = true
        } else if (
          pedidoEmAberto.original.frete_cotacao ||
          pedidoEmAberto.original.frete_valor
        ) {
          retorno = true
        }
      }
    }

    return retorno
  }, [mode, pedidoEmAberto, pedidoEmAndamento])

  const gravaItemNoPedido = useCallback(
    async (zerarFrete: boolean) => {
      if (mode === 'register') {
        let newPedido = {} as GetPedido
        if (produtoSelected.tem_kit) {
          console.log('produto', produtoSelected)
          newPedido = await buildPedidoNewItemKit({
            mode,
            item: produtoSelected,
            pedido: pedidoEmAndamento,
            zerarFrete
          })
        } else {
          newPedido = buildPedidoNewItem({
            mode,
            item: produtoSelected,
            pedido: pedidoEmAndamento,
            zerarFrete
          })
        }

        const resp = await recalcularPedido({ pedido: newPedido, mode })
        if (resp && resp.data) {
          dispatch(
            setPedidoRecalculado({
              cliente_id: cliente.id as number,
              data: resp.data.data as unknown as GetPedido
            })
          )
        } else if (resp && resp.error) {
          handleError(resp.error)
        }
      } else {
        let newPedido = {} as GetPedido
        if (produtoSelected.tem_kit) {
          newPedido = await buildPedidoNewItemKit({
            mode,
            item: produtoSelected,
            pedido: pedidoEmAberto,
            zerarFrete
          })
        } else {
          newPedido = buildPedidoNewItem({
            mode,
            item: produtoSelected,
            pedido: pedidoEmAberto,
            zerarFrete
          })
        }
        const resp = await recalcularPedido({ pedido: newPedido, mode })
        if (resp && resp.data) {
          dispatch(
            setPedidoRecalculado({
              cliente_id: cliente.id as number,
              data: resp.data.data as unknown as GetPedido,
              pedido_id: newPedido.id as number
            })
          )
        } else if (resp && resp.error) {
          handleError(resp.error)
        }
      }
    },
    [
      mode,
      produtoSelected,
      recalcularPedido,
      buildPedidoNewItemKit,
      pedidoEmAndamento,
      buildPedidoNewItem,
      dispatch,
      cliente,
      handleError,
      pedidoEmAberto
    ]
  )

  const handleCloseModals = useCallback(() => {
    handleIsPrecoDefinido(false)
    setShowProdutoIdModal(false)
    setShowProdutoModal(false)
    setShowModalPromocao(false)
    inputBuscarProdutoPorId.current && inputBuscarProdutoPorId.current.focus()
  }, [])

  const handleAddItemOrcamento = useCallback(async () => {
    if (produtoSelected) {
      if (
        produtoSelected.produto?.controla_lote === 's' &&
        !produtoSelected.lote
      ) {
        Swal.fire({
          title: 'Lote não preenchido!',
          text:
            'O produto selecionado possui lote e precisa ser preenchido, adicione denovo corretamente.',
          icon: 'warning'
        })
        return
      }
      if (revenda && revenda.id && Number(revenda.id) > 0) {
        if (
          produtoSelected.preco?.valor_consumidor &&
          produtoSelected.valor &&
          produtoSelected.valor < produtoSelected.preco?.valor_consumidor
        ) {
          Swal.fire({
            title: 'Valor Invalido',
            text: `Valor do produto não pode ser menor que ${produtoSelected.preco.valor_consumidor}`,
            icon: 'warning'
          })
          return
        }
      }
      if (temFreteValor()) {
        const { isConfirmed } = await Swal.fire({
          title: 'Frete Preenchido!',
          text:
            'Esse pedido já possui valor e/ou cotação de frete, adicionar produtos irá zera-los, deseja prosseguir?',
          icon: 'warning',
          focusCancel: true,
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
        })

        if (isConfirmed) {
          gravaItemNoPedido(true)
        }
      } else {
        gravaItemNoPedido(false)
      }
    }

    setProdutoId(0)
    inputBuscarProdutoPorId?.current?.focus()
    handleCloseModals()
  }, [produtoSelected, handleCloseModals, revenda, temFreteValor, gravaItemNoPedido])

  const gravaArrayNoPedido = useCallback(
    async (zerarFrete: boolean) => {
      if (mode === 'register') {
        let newPedido = buildPedidoNewArray({
          mode,
          items: promocaoSelected,
          pedido: pedidoEmAndamento,
          zerarFrete
        })
        const resp = await recalcularPedido({ pedido: newPedido, mode })
        if (resp && resp.data) {
          dispatch(
            setPedidoRecalculado({
              cliente_id: cliente.id as number,
              data: resp.data.data as unknown as GetPedido
            })
          )
        } else if (resp && resp.error) {
          handleError(resp.error)
        }
      } else {
        let newPedido = buildPedidoNewArray({
          mode,
          items: promocaoSelected,
          pedido: pedidoEmAberto,
          zerarFrete
        })
        const resp = await recalcularPedido({ pedido: newPedido, mode })
        if (resp && resp.data) {
          dispatch(
            setPedidoRecalculado({
              cliente_id: cliente.id as number,
              data: resp.data.data as unknown as GetPedido,
              pedido_id: newPedido.id as number
            })
          )
        } else if (resp && resp.error) {
          handleError(resp.error)
        }
      }
    },
    [mode, buildPedidoNewArray, promocaoSelected, pedidoEmAndamento, recalcularPedido, dispatch, cliente.id, handleError, pedidoEmAberto]
  )

  const handleAddPromocaoOrcamento = useCallback(async () => {
    if (promocaoSelected) {
      if (temFreteValor()) {
        const { isConfirmed } = await Swal.fire({
          title: 'Frete Preenchido!',
          text:
            'Esse pedido já possui valor e/ou cotação de frete, adicionar produtos irá zera-los, deseja prosseguir?',
          icon: 'warning',
          focusCancel: true,
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
        })
        if (isConfirmed) {
          gravaArrayNoPedido(true)
        }
      } else {
        gravaArrayNoPedido(false)
      }
    }

    handleCloseModals()
  }, [gravaArrayNoPedido, handleCloseModals, promocaoSelected, temFreteValor])

  const handleIsPrecoDefinido = (value: boolean) => {
    setDisableButtonAdicionar(value)
  }

  const handlechangeProdutoId = (e: React.ChangeEvent<HTMLInputElement>) => {
    let id = 0
    let value = e.target.value.replace(/[^0-9]/g, '')

    if (value) {
      id = parseInt(value)
    }

    setProdutoId(id)
  }

  const handleClickPromocao = () => {
    setShowModalPromocao(true)
  }

  return (
    <Container>
      {loadingRecacular && <Loading />}
      <Header>
        <span>Adicionar Produto:</span>
        <Input
          inputRef={inputBuscarProdutoPorId}
          data-cy="inputBuscarProdutoPorId"
          title="Adicionar produto por ID"
          type="text"
          onKeyPress={handleAddProdutoById}
          onChange={handlechangeProdutoId}
          value={produtoId || ''}
          width="0 0 25%"
          autoFocus
          f2Function={handleAdicionarProdutoOnCLick}
        />
        <Button
          tabIndex={1}
          mode="confirm"
          startIcon={FaPlus}
          type="button"
          onClick={handleAdicionarProdutoOnCLick}
        >
          Lista Produtos
        </Button>
      </Header>
      <Content>
        <Table
          columns={columns}
          showPageJump={false}
          data={itensPedido}
          pageSize={rowsPerPage}
          page={0}
          sortable={false}
          nextText="Próximo"
          previousText="Anterior"
          pageText="Página"
          ofText="de"
          showPageSizeOptions={false}
          noDataText="Nenhum produto no orçamento"
          deleteFn={handleDeleteRowTable}
          dbClickCallback={handleDbClickCallback}
          manual={false}
          selectRow
          deleteRow
          showToolTip={!!erros?.itens?.length}
          toolTipText={erros?.itens ? erros.itens : ''}
          borderMode={erros?.itens ? 'danger' : 'normal'}
          showPagination={false}
        />
      </Content>
      {showProdutoModal && (
        <Modal
          title="Lista de produtos"
          buttonSaveText="Adicionar produto"
          mode="fullscreen"
          close={() => setShowProdutoModal(false)}
        >
          <ListaProdutos
            cliente={cliente}
            revenda={revenda}
            mode={mode}
            afterInsertItemOrcamento={() => setShowProdutoModal(false)}
            produtoSelected={produtoSelected}
            setProdutoSelected={setProdutoSelected}
            handleAddItem={handleAddItemOrcamento}
            setPromocaoSelected={setPromocaoSelected}
            handleAddPromocaoOrcamento={handleAddPromocaoOrcamento}
            pedidoIdEmEdicao={orcamentoIdEmEdicao}
            fakeItemId={fakeItemId}
            setFakeItemId={setFakeItemId}
          />
        </Modal>
      )}
      {showProdutoIdModal && (
        <Modal
          title="Adicionar Produto"
          buttonSaveText="Adicionar produto"
          mode="wide"
          close={handleCloseModals}
          showButtonSave={true}
          disableButtonSave={!disableButtonAdicionar || !loadingButtonAdicionar}
          onSave={handleAddItemOrcamento}
        >
          {!disableButtonAdicionar && (<Loading/>)}
          <AdicionarProduto
            cliente={cliente}
            revenda={revenda}
            mode={mode}
            pedidoIdEmEdicao={orcamentoIdEmEdicao}
            produtoId={addProdutoId}
            setProdutoSelected={setProdutoSelected}
            handleIsPrecoDefinido={handleIsPrecoDefinido}
            handleAddItem={handleAddItemOrcamento}
            handleClickPromocao={handleClickPromocao}
            close={handleCloseModals}
            fakeItemId={fakeItemId}
            setFakeItemId={setFakeItemId}
            loading={(e) => setLoadingButtonAdicionar(!e)}

          /> {!loadingButtonAdicionar && <div style={{ position: 'absolute', bottom: '25px' }}>Para confirmar, você deverá apertar o ENTER no campo alterado</div>}
        </Modal>
      )}
      {showModalPromocao && (
        <Modal
          title={'Promoções de ' + addProdutoId}
          close={() => setShowModalPromocao(false)}
          showButtonSave={!quantidadeInvalida}
          buttonSaveText="Adicionar"
          onSave={handleAddPromocaoOrcamento}
          mode="wide"
        >
          <DetalhePromocao
            cliente={cliente}
            revenda={revenda}
            produto={produtoSelected.produto as Produto}
            mode={mode}
            pedidoIdEmEdicao={orcamentoIdEmEdicao}
            isPrecoDefininido={handleIsPrecoDefinido}
            setPromocaoSelected={setPromocaoSelected}
            depositoId={deposito?.id}
            fakeItemId={fakeItemId}
            setQuantidadeInvalida={setQuantidadeInvalida}
            solar={solar}
          />
        </Modal>
      )}
      {showTrocaLote && (
        <Modal
          title="Troca de Lote"
          mode="normal"
          close={() => setShowTrocaLote(false)}
        >
          <ListaLotes
            callBack={handleCallbackTrocaLote}
            lotes={
              itensPedido &&
              itensPedido[indexTrocaLote as number].produto?.lotes
            }
            depositoId={deposito?.id}
          />
        </Modal>
      )}
    </Container>
  )
}
