import React, { createContext, useState } from 'react'

export type CurrentTabCadastroCliente = 'dados' | 'endereco' | 'contatos' | 'ocorrencias'

export const TabCadastroClienteContext = createContext<[CurrentTabCadastroCliente, React.Dispatch<React.SetStateAction<CurrentTabCadastroCliente>>]>([] as unknown as [CurrentTabCadastroCliente, React.Dispatch<React.SetStateAction<CurrentTabCadastroCliente>>])

export const TabCadastroClienteProvider: React.FC = ({ children }) => {
  const state = useState('dados' as CurrentTabCadastroCliente)

  return (
    <TabCadastroClienteContext.Provider value={state} >
      {children}
    </TabCadastroClienteContext.Provider>
  )
}
