import styled from 'styled-components'
import { fadein } from '../../styles/animations'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 50px 10px;
  width: 100%;
  animation: ${fadein} 0.6s forwards;

  >section {
    display: flex;
    align-items: center;
    width: 100%;
  }

  >section:first-of-type {
    justify-content: space-between;
    margin: 10px 0px;
    @media screen and (max-width: 720px) {
      flex-wrap: wrap;
    }
  }
`
