import { Item, ItemOrcamento } from '../modules/pedidos/models'
import { ItemPedidoApi } from '../modules/pedidos/services/_interfaces'

// foi deixado any[] pois a função filter apresentava erro que não foi encontrado solução
export const calcTotaisPedido = (items?: Item[], juros?: number) => {
  if (!items) {
    return
  }
  const calcDescontoAcrescimo = (item: ItemPedidoApi | Item): { desconto: number, acrescimo: number } => {
    let desconto = 0
    let acrescimo = 0

    if (item.valor && item.valor_tabela) {
      let result = item.valor_tabela - item.valor
      if (result > 0) {
        desconto = result
      } else {
        acrescimo = result * -1
      }
    }

    return {
      desconto,
      acrescimo
    }
  }

  const calcFaltaLiberar = (item: ItemPedidoApi | Item): number => {
    const faltaLiberar = item.quantidade - (item.quantidade_liberada as number || 0)
    return faltaLiberar
  }

  const calcTotalSt = (item: ItemPedidoApi | Item): number => {
    let result = 0

    if (item.st) {
      result = item.st * item.quantidade
    }

    if (item.frete_st) {
      result = result + item.frete_st
    }

    return result
  }

  const calcTotalDifal = (item: ItemPedidoApi | Item): number => {
    let result = 0

    if (item.difal) {
      result = item.difal * item.quantidade
    }

    return result
  }

  const calcTotalIpi = (item: ItemPedidoApi | Item): number => {
    let result = 0

    if (item.ipi && item.valor) {
      result = item.ipi * item.quantidade * item.valor / 100
    }

    return result
  }

  const calcTotalPorItem = (item: ItemPedidoApi | Item) => {
    const ipi = calcTotalIpi(item)
    const st = calcTotalSt(item)
    let total = item.valor ? item.valor : 0
    let subTotal = item.valor ? item.valor : 0
    let jurosTotal = 0
    total = item.quantidade * total
    subTotal = item.quantidade * subTotal
    if (juros && juros > 0) {
      jurosTotal = total * (juros / 100)
      total += jurosTotal
    }
    if (st > 0) {
      total += st
    }
    if (ipi > 0) {
      total += ipi
    }
    return { total, subTotal, jurosTotal }
  }

  let totalPedido = 0
  let subTotalPedido = 0
  let jurosValorPedido = 0
  let stPedido = 0
  let difalPedido = 0
  let ipiPedido = 0
  let qtdeItens = 0
  let acrescimos = 0
  let descontos = 0
  let peso_total = 0

  // remove os itens que estão com quantidade 0
  const itensFiltrados = items?.filter((item: ItemPedidoApi | Item) => item.quantidade > 0)

  const newItens = itensFiltrados?.map((item: ItemPedidoApi | Item) => {
    const total = calcTotalPorItem(item).total
    const descontoXacrescimo = calcDescontoAcrescimo(item)
    const newItem: ItemOrcamento = {
      ...item,
      id: item.id,
      acrescimo: descontoXacrescimo.acrescimo,
      quantidade: item.quantidade,
      desconto: descontoXacrescimo.desconto,
      valor: item.valor,
      valorUnitario: total / item.quantidade,
      total,
      stTotal: calcTotalSt(item),
      difalTotal: calcTotalDifal(item),
      ipiTotal: calcTotalIpi(item),
      falta_liberar: calcFaltaLiberar(item),
      promocao_id: item.promocao_id,
      data: item.data || '',
      lote: item.lote || '',
      estoque_atual: item.estoque_atual,
      item_promocao_id: item.item_promocao_id,
      preco: {
        valor_original: item.valor_tabela as unknown as number,
        baseIcms: item.base_icms as number,
        valor: item.valor_tabela as number,
        desconto_maximo: 0,
        baseSt: item.base_st as number,
        custo_medio: item.custo_medio as number,
        icms: item.icms as number,
        st: item.st as number,
        ipi: item.ipi as number
      },
      icms: item.icms,
      produto: item.produto
    }

    totalPedido += newItem.total as number
    subTotalPedido += calcTotalPorItem(item).subTotal as number
    jurosValorPedido += calcTotalPorItem(item).jurosTotal as number
    difalPedido += newItem.difalTotal as number
    stPedido += newItem.stTotal as number
    ipiPedido += newItem.ipiTotal as number
    qtdeItens += newItem.quantidade
    acrescimos += (newItem.acrescimo || 0) * newItem.quantidade
    descontos += (newItem.desconto || 0) * newItem.quantidade
    peso_total += (newItem.produto?.peso_bruto || 0) * newItem.quantidade
    return newItem
  })

  const retorno = { newItens, totalPedido, subTotalPedido, jurosValorPedido, stPedido, difalPedido, ipiPedido, qtdeItens, acrescimos, descontos, peso_total }

  return retorno
}
