import React from 'react'
import { ItemHistoricoPedido } from '../../../modules/pedidos/models'

import { BodyItem, Container, DadoHeaderDiv, DataHeaderDiv, HeaderItem, IdHeaderDiv, ItemHistorico, ListaBody } from './styles'
interface ListaProps {
  historico?: ItemHistoricoPedido[]
}

export const ListaHistorico = (props: ListaProps) => {
  console.log(props)
  return (
    <Container>
      <ListaBody>
        {props.historico
          ? props.historico.map(item => <ItemHistorico key={item.id}>
            <HeaderItem>
              <IdHeaderDiv>
                <DadoHeaderDiv>
                  <span>ID:</span>
                  <span>{item.id}</span>
                </DadoHeaderDiv>
                <DadoHeaderDiv>
                  <span>Usuário:</span>
                  <span>{item.usuario_id}</span>
                </DadoHeaderDiv>
              </IdHeaderDiv>
              <DataHeaderDiv>
                <DadoHeaderDiv>
                  <span>Data:</span>
                  <span>{item.data}</span>
                </DadoHeaderDiv>
                <DadoHeaderDiv>
                  <span>Hora:</span>
                  <span>{item.hora}</span>
                </DadoHeaderDiv>
              </DataHeaderDiv>
            </HeaderItem>
            <BodyItem>
              <span>Descrição:</span>
              <p>{item.descricao}</p>
            </BodyItem>
          </ItemHistorico>
          )
          : <p>Não há historico desse pedido</p>
        }
      </ListaBody>
    </Container>
  )
}
