import React, { ButtonHTMLAttributes, ReactNode } from 'react'
import { IconType } from 'react-icons'

import { Container } from './styles'

interface ButtonTableProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: IconType
  children?: ReactNode
  typeButton: 'danger' | 'primary' | 'secondary' | 'success'
  size?: 'icon' | 'normal'
}

export const ButtonTable = ({ icon: Icon, children, typeButton, color, disabled, size, ...rest }: ButtonTableProps) => {
  return (
    <Container {...rest} size={size} typeButton={typeButton} disabled={disabled}>{children && children}</Container>
  )
}
