import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { SchemaValidator } from '../../../infra/validator/schemaValidator'
import { CadastrarOcorrenciaServiceRest } from '../services/cadastra-ocorrencia'
import { CadastrarOcorrencia } from '../useCases'
import { ocorrenciaSchema } from '../validators'

export function makeCadastrarOcorrencia (): CadastrarOcorrencia {
  const httpRequest = new HttpRequestImpl()
  const validator = new SchemaValidator(ocorrenciaSchema)
  const cadastrarOcorrenciaService = new CadastrarOcorrenciaServiceRest(httpRequest)

  return new CadastrarOcorrencia(cadastrarOcorrenciaService, validator)
}
