import { makeTrazerPedidoPorId } from '../modules/pedidos/factories'
import { differenceInSeconds } from 'date-fns'

export const canUpdatePedido = async (id?: number, data?: string, token?: string) => {
  let retorno = true
  if (id && data && token) {
    const trazerPedidoPorId = makeTrazerPedidoPorId()
    const params = {
      filterObject: {
        id: id
      },
      token: token
    }
    const pedidoAtualBD = await trazerPedidoPorId.execute(params)
    if (pedidoAtualBD && pedidoAtualBD.data) {
      const datePedidoAtual = new Date(pedidoAtualBD.data?.updated_at as string)
      const dataParams = new Date(data)
      const diff = differenceInSeconds(datePedidoAtual, dataParams)
      if (diff > 0) {
        retorno = false
      }
    }
  }
  return retorno
}
