import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > section {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    margin-top: 20px;

    > span:first-of-type {
      margin: 10px 0px;
      padding-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid ${props => props.theme.colors.border};
    }
  }
`

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  > span:first-of-type {
    margin: 10px 0px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`

export const TipoCliente = styled.div`
  label {
    color: ${props => props.theme.colors.primaryText};
    font-weight: 600;
    text-transform: uppercase;
  }
`

export const Grupo = styled.div`
  display: block;
  label {
    display: inline-block;
    margin-bottom: 15px;
    color: ${props => props.theme.colors.primaryText};
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
  }
`

export const DivForm = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;

  @media screen and (max-width: 720px) {
    margin-top: 0px;
    flex-direction: column;
  }
`

export const RadioButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 10px;
  letter-spacing: 0.075em;
  user-select: none;
  margin-bottom: 10px;

  > span {
    display: flex;
    color: ${props => props.theme.colors.primaryText} !important;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
  }

`
export const Divider = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 860px) {
    flex-direction: column;
  }
`

export const Telefones = styled.div`
  width: 100%;
  padding: 15px;
`
