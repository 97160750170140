import { useState } from 'react'
import { makeListarClientes } from '../../../modules/clientes/factories'
import { GetListClienteResponse } from '../../../modules/clientes/models'
import { useHandleErrors } from '../app/useHandleErrors'
import { useUsuario } from '../usuario/useUsuario'

interface FetcherProps {
  perPage: number,
  currentPage: number,
  search: string
}

export function useTransportadoras () {
  const { data: usuarioData } = useUsuario()
  const [isLoading, setIsLoading] = useState(false)
  const { handleError, executing } = useHandleErrors()
  const [data, setData] = useState<GetListClienteResponse>()

  async function fetcher ({ perPage, currentPage, search }: FetcherProps) {
    setIsLoading(true)
    let params = {
      filter: search || '',
      filterOptions: {
        limit: perPage,
        skip: (currentPage - 1) * perPage
      },
      filterObject: {
        is_transportadora: 's',
        'clientes.status[not]': 'inativo' // liberado, bloquado, inativo, off
      },
      token: usuarioData?.token
    }
    const listarClientes = makeListarClientes()
    const response = await listarClientes.execute(params)
    setData(response)
    setIsLoading(false)
    if (response.error && !executing) {
      handleError(response.error)
    }
  }

  return { fetcher, data, isLoading }
}
