import Swal from 'sweetalert2'

export const checkPedidoStatus = (status: string) => {
  if (status) {
    if (status === 'cancelado' || status === 'faturado') {
      Swal.fire({
        icon: 'warning',
        title: 'Não pode ser Alterado!',
        text: 'Esse pedido não pode mais ser alterado pois esta ' + status,
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false
      })

      return false
    } else {
      return true
    }
  } else {
    return false
  }
}
