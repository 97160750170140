import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaRedoAlt, FaSave, FaTrash } from 'react-icons/fa'
import { ResumoOrcamento, Button, Loading, ListaHistorico, Parcelas, ListaAnaliseCredito, Transferencias } from '..'

import { Cliente } from '../../../modules/clientes/models'
import { PostParams } from '../../../modules/_interfaces'
import { Container, HeaderAtendimento, ContentHeaderAtendimento } from './styles'
import { useExecUseCase, useUsuario } from '../../hooks'
import { AppState } from '../../store'
import { setErrosPedidoEmAndamento, setErrosPedidoEmAberto, fecharPedido, setAbaAtivaPedido, setAbaAtivaPedidoAberto, setarBotaoAtivoCliente, setPedidoAtualizado } from '../../store/atendimentos/actions'
import { AtendimentoType, AbaPedido, ErrosPedido, PedidoEmFocoType, AtendimentosState } from '../../store/atendimentos/interfaces'
import { makeAtualizarPedido, makeCadastrarPedido, makeTrazerPedidoPorId } from '../../../modules/pedidos/factories'
import { checkPedidoStatus, getTabCadastroOrcamentoToRedirect } from '../../../helpers'
import { AtualizarPedido, CadastrarPedido } from '../../../modules/pedidos/useCases'
import { GetPedido } from '../../../modules/pedidos/models'
import Swal from 'sweetalert2'
import capitalize from 'capitalize-pt-br'
import { DadosGerais } from './DadosGerais'
import { Produtos } from './Produtos'
import { Negociacoes } from './Negociacoes'
import { Solicitacoes } from './Solicitacoes'

export type ModePedido = 'edit' | 'register'

export interface PedidoProps {
  cliente: Cliente
  mode: ModePedido
  orcamentoIdEmEdicao?: number
  handleReloadOnClose: () => void
  handleCancelar: (pedido: PedidoEmFocoType) => void
}

type Params = {
  body: any,
  pedidoId?: number
}

export const Pedido = ({ cliente, mode = 'edit', orcamentoIdEmEdicao, handleReloadOnClose, handleCancelar }: PedidoProps) => {
  const dispatch = useDispatch()
  const atendimentoCliente = useSelector<AppState>(state => state.atendimentos.data[cliente.id as number]) as AtendimentoType
  const pedidoEmAndamento = atendimentoCliente?.pedidoEmAndamento
  const pedidoOriginal = atendimentoCliente?.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original
  const [loadingAtualizar, setLoadingAtualizar] = useState(false)
  const { data: usuarioData } = useUsuario()
  // const pedidoEmEdicao = atendimentoCliente?.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.emEdicao
  const atendimentosState = useSelector<AppState>((state) => state.atendimentos) as AtendimentosState
  const {
    executeUseCase,
    executing: executingGravarOrcamento,
    error: useCaseError
  } = useExecUseCase()

  const handleMenuOnClick = useCallback((abaAtiva: AbaPedido) => {
    if (mode === 'register') {
      dispatch(setAbaAtivaPedido({
        abaAtiva,
        clienteId: cliente.id as number
      }))
    } else {
      dispatch(setAbaAtivaPedidoAberto({
        pedidoId: orcamentoIdEmEdicao as number,
        abaAtiva,
        clienteId: cliente.id as number
      }))
    }
  }, [cliente.id, dispatch, mode, orcamentoIdEmEdicao])

  const solar = useMemo(() => {
    if (mode === 'register') {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.solar
    } else {
      const depositoEdicao =
        atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[orcamentoIdEmEdicao as number].emEdicao?.solar
      const depositoOriginal =
        atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[orcamentoIdEmEdicao as number].original?.solar
      return depositoEdicao || depositoOriginal || 'n'
    }
  }, [atendimentosState.data, cliente.id, mode, orcamentoIdEmEdicao])

  useEffect(() => {
    if (cliente?.id && useCaseError.data) {
      if (mode === 'register') {
        dispatch(setErrosPedidoEmAndamento({
          clienteId: cliente.id as number,
          erros: useCaseError.data
        }))
      } else {
        dispatch(setErrosPedidoEmAberto({
          pedidoId: orcamentoIdEmEdicao as number,
          clienteId: cliente.id as number,
          erros: useCaseError.data
        }))
      }

      const tabToRedirect = getTabCadastroOrcamentoToRedirect(useCaseError.data)

      dispatch(setAbaAtivaPedido({
        abaAtiva: tabToRedirect,
        clienteId: cliente.id as number
      }))
      handleMenuOnClick(tabToRedirect)
    }
  }, [cliente, dispatch, mode, orcamentoIdEmEdicao, useCaseError, handleMenuOnClick])

  const afterSavePedido = useCallback(() => {
    handleReloadOnClose()
    dispatch(fecharPedido({
      pedido: orcamentoIdEmEdicao || 'em_andamento',
      clienteId: cliente.id as number
    }))

    // dispatch(setAtendimentoEmFoco({
    //   clienteId: null
    // }))

    dispatch(setarBotaoAtivoCliente({
      botao: 'pedidos',
      clienteId: cliente.id as number
    }))
  }, [cliente.id, dispatch, handleReloadOnClose, orcamentoIdEmEdicao])

  const handleErrosPedido = useCallback(() => {
    let text = ''
    if (mode === 'register') {
      Object.keys(atendimentoCliente.pedidoEmAndamento.erros).forEach(key => {
        const inputErrorMessage = atendimentoCliente.pedidoEmAndamento.erros[key as keyof ErrosPedido]
        if (inputErrorMessage && inputErrorMessage !== '') {
          text += inputErrorMessage + '<br>'
        }
      })
    } else {
      Object.keys(atendimentoCliente?.pedidosEmAberto.data[orcamentoIdEmEdicao as number].erros).forEach(key => {
        const inputErrorMessage = atendimentoCliente?.pedidosEmAberto.data[orcamentoIdEmEdicao as number].erros[key as keyof ErrosPedido]
        if (inputErrorMessage && inputErrorMessage !== '') {
          text += inputErrorMessage + '<br>'
        }
      })
    }

    if (text !== '') {
      Swal.fire({
        html: text,
        icon: 'warning',
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false
      })
      return false
    } else {
      return true
    }
  }, [atendimentoCliente, mode, orcamentoIdEmEdicao])

  const handleFinalizarOnClick = useCallback(async () => {
    let orcamento: GetPedido
    let useCase: CadastrarPedido | AtualizarPedido

    if (!handleErrosPedido()) {
      return
    }

    if (mode === 'register') {
      orcamento = atendimentoCliente.pedidoEmAndamento.pedido
      useCase = makeCadastrarPedido()
    } else {
      const status = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original.status
      const podeSalvarPedidoStatus = checkPedidoStatus(status as string)
      if (!podeSalvarPedidoStatus) {
        return
      }
      orcamento = {
        ...atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original,
        ...atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao
      }
      useCase = makeAtualizarPedido()
      // const canUpdate = await canUpdatePedido(orcamentoIdEmEdicao, orcamento.updated_at, usuarioData?.token)
      // if (!canUpdate) {
      //   const { isConfirmed } = await Swal.fire({
      //     title: 'Dados Atualizados!',
      //     text: 'Esse pedido possui dados mais novos salvos, salvar irá sobrescrevê los, deseja prosseguir?',
      //     icon: 'warning'
      //     focusCancel: true,
      //     showCancelButton: true,
      //     showCloseButton: true,
      //     cancelButtonText: 'Não',
      //     confirmButtonText: 'Sim'
      //   })
      //   if (!isConfirmed) {
      //     return
      //   }
      // }
    }

    const params: Params = {
      body: {
        ...orcamento,
        id: orcamentoIdEmEdicao
      }
    }
    executeUseCase<PostParams>(
      useCase,
      params,
      `${mode === 'register' ? 'Orçamento finalizado com sucesso!' : `${capitalize(pedidoOriginal.tipo as string)} atualizado com sucesso!`} `,
      `${mode === 'register' ? 'Deseja finalizar o orçamento?' : `Deseja salvar as alterações do ${pedidoOriginal.tipo}?`} `,
      afterSavePedido,
      true
    )
  }, [handleErrosPedido, mode, orcamentoIdEmEdicao, executeUseCase, pedidoOriginal, afterSavePedido, atendimentoCliente])

  const isButtonProdutosDisabled = useMemo(() => {
    let retorno = false

    if (mode === 'register') {
      if (!atendimentoCliente.pedidoEmAndamento?.pedido?.deposito?.nome || !atendimentoCliente.pedidoEmAndamento?.pedido?.transportadora?.nome_fantasia) {
        retorno = true
      }
    } else if (mode === 'edit' && orcamentoIdEmEdicao) {
      const depositoOriginal = atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao].original?.deposito?.nome
      const transportadoraOriginal = atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao].original?.transportadora?.nome_fantasia
      const depositoEdicao = atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao].emEdicao?.deposito?.nome
      const transportadoraEdicao = atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao].emEdicao?.transportadora?.nome_fantasia
      if (!depositoOriginal && !depositoEdicao) {
        retorno = true
      } else if (depositoEdicao === '') {
        retorno = true
      }
      if (!transportadoraOriginal && !transportadoraEdicao) {
        retorno = true
      } else if (transportadoraEdicao === '') {
        retorno = true
      }
    }
    return retorno
  }, [atendimentoCliente.pedidoEmAndamento, atendimentoCliente.pedidosEmAberto, mode, orcamentoIdEmEdicao])

  const isButtonPagamentoDisabled = useMemo(() => {
    let retorno = false

    if (mode === 'register') {
      if (atendimentoCliente.pedidoEmAndamento?.pedido?.pagamento_tipo === '' || atendimentoCliente.pedidoEmAndamento?.pedido?.condicao_pagamento === '') {
        retorno = true
      }
    }
    return retorno
  }, [atendimentoCliente.pedidoEmAndamento, mode])

  const getButtonActiveHeaderAtendimento = useCallback(() => {
    if (mode === 'register') {
      return atendimentoCliente.pedidoEmAndamento?.abaAtiva
    } else {
      return atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number].abaAtiva
    }
  }, [atendimentoCliente.pedidoEmAndamento, atendimentoCliente.pedidosEmAberto, mode, orcamentoIdEmEdicao])

  const buttonSaveIsDisabled = useMemo(() => {
    let retorno = true
    if (mode === 'edit' && orcamentoIdEmEdicao) {
      const emEdicao = atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao].emEdicao
      if (emEdicao && Object.keys(emEdicao).length > 0) {
        retorno = false
      }
    } else if (mode === 'register') {
      retorno = false
    }
    return retorno
  }, [atendimentoCliente.pedidosEmAberto, mode, orcamentoIdEmEdicao])

  const pedidoAgenciado = useMemo(() => {
    if (mode === 'register') {
      return atendimentoCliente.pedidoEmAndamento?.pedido?.revenda?.id as number
    } else {
      const emEdicao = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.emEdicao?.revenda?.id as number
      const original = atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original?.revenda?.id as number
      return emEdicao || original
    }
  }, [atendimentoCliente.pedidoEmAndamento, atendimentosState.data, cliente.id, mode, orcamentoIdEmEdicao])

  // const getCliente = () => {
  //   let retorno = cliente
  //   // no cadastrar a revenda (josir) e o cliente do pedido e a revenda o cliente final (js), ao salvar os ids sao invertidos
  //   if (mode === 'register') {
  //     if (pedidoEmAndamento.pedido.tipo_faturamento === 'direto') {
  //       if (pedidoEmAndamento.pedido.revenda && (pedidoEmAndamento.pedido.revenda.uf !== cliente.uf)) {
  //         retorno = pedidoEmAndamento.pedido.revenda
  //       }
  //     }
  //   } else if (mode === 'edit') {
  //     if (pedidoOriginal.tipo_faturamento === 'direto') {
  //       if (pedidoOriginal.revenda && (pedidoOriginal.revenda.uf === cliente.uf)) {
  //         retorno = pedidoOriginal.revenda
  //       }
  //     }
  //   }
  //   return retorno
  // }

  const getRevenda = () => {
    // no cadastrar a revenda (josir) e o cliente do pedido e a revenda o cliente final (js), ao salvar os ids sao invertidos
    if (mode === 'register') {
      if (pedidoEmAndamento.pedido && pedidoEmAndamento.pedido.tipo_faturamento === 'direto') {
        return pedidoEmAndamento.pedido.revenda
      }
    } else if (mode === 'edit') {
      if (pedidoOriginal && pedidoOriginal.tipo_faturamento === 'direto') {
        return pedidoOriginal.revenda
      }
    }
  }

  const handleAtualizarOnClick = useCallback(async () => {
    setLoadingAtualizar(true)
    const trazerPedidoPorId = makeTrazerPedidoPorId()
    const params = {
      filterObject: {
        id: orcamentoIdEmEdicao
      },
      token: usuarioData?.token
    }
    const pedidoAtualBD = await trazerPedidoPorId.execute(params)
    if (pedidoAtualBD && pedidoAtualBD.data) {
      dispatch(setPedidoAtualizado({
        data: pedidoAtualBD?.data,
        cliente_id: cliente.id as number,
        pedido_id: pedidoAtualBD?.data?.id
      }))
    }
    setLoadingAtualizar(false)
  }, [cliente, dispatch, orcamentoIdEmEdicao, usuarioData])

  const handleClickProdutos = () => {
    if (isButtonProdutosDisabled || isButtonPagamentoDisabled) {
      if (isButtonProdutosDisabled) {
        Swal.fire({
          icon: 'warning',
          title: 'Campos não Preenchidos',
          text: 'Selecione um depósito e uma transportadora!'
        })
      } else if (isButtonPagamentoDisabled) {
        Swal.fire({
          icon: 'warning',
          title: 'Campos não Preenchidos',
          text: 'Selecione uma forma e condição de pagamento!'
        })
      }
    } else {
      handleMenuOnClick('produtos')
    }
  }

  return (
    < Container >
      {(loadingAtualizar || executingGravarOrcamento) && <Loading />}
      <HeaderAtendimento>
        <ContentHeaderAtendimento>
          <div>
            <Button
              name='dadosGerais'
              data-cy='buttonDadosGerais'
              mode='secondary'
              active={getButtonActiveHeaderAtendimento() === 'dadosgerais'}
              onClick={() => handleMenuOnClick('dadosgerais')}
            >
              Dados gerais
            </Button>
            <Button
              name='produtos'
              data-cy='buttonProdutos'
              mode='secondary'
              title='Produtos do orçamento'
              active={getButtonActiveHeaderAtendimento() === 'produtos'}
              // disabled={isButtonProdutosDisabled}
              onClick={handleClickProdutos}>Produtos
            </Button>
            {mode === 'edit' && <>
              <Button
                name="solicitacoes"
                data-cy="buttonSolicitacoes"
                mode="secondary"
                title='Solicitações para o gerente'
                active={getButtonActiveHeaderAtendimento() === 'solicitacoes'}
                disabled={mode !== 'edit'}
                onClick={() => handleMenuOnClick('solicitacoes')}>Solicitações
              </Button>
              <Button
                name="parcelas"
                data-cy="buttonParcelas"
                mode="secondary"
                title='Parcelas do Pedido'
                active={getButtonActiveHeaderAtendimento() === 'parcelas'}
                disabled={mode !== 'edit'}
                onClick={() => handleMenuOnClick('parcelas')}>Parcelas
              </Button>
              <Button
                name='negociacoes'
                data-cy='buttonProdutos'
                mode='secondary'
                title='Negociações com o cliente'
                active={getButtonActiveHeaderAtendimento() === 'negociacoes'}
                onClick={() => handleMenuOnClick('negociacoes')}>Negociações
              </Button>
              <Button
                name="analise"
                data-cy="buttonAnalise"
                mode="secondary"
                title='Análise de Crédito'
                active={getButtonActiveHeaderAtendimento() === 'analise'}
                disabled={mode !== 'edit'}
                onClick={() => handleMenuOnClick('analise')}>Analise Cred.
              </Button>
              <Button
                name="transferencias"
                data-cy="buttonTransferencia"
                mode="secondary"
                title='Transferências'
                active={getButtonActiveHeaderAtendimento() === 'transferencias'}
                disabled={mode !== 'edit'}
                onClick={() => handleMenuOnClick('transferencias')}>Transferências
              </Button>
              <Button
                name="historico"
                data-cy="buttonHistorico"
                mode="secondary"
                title='Histórico de Alterações do Pedido'
                active={getButtonActiveHeaderAtendimento() === 'historico'}
                disabled={mode !== 'edit'}
                onClick={() => handleMenuOnClick('historico')}>Histórico
              </Button>
            </>}
          </div>
          <div>
            <Button
              mode="danger"
              startIcon={FaTrash}
              onClick={() => handleCancelar(mode === 'register' ? 'em_andamento' : pedidoOriginal.id as number)}
              data-cy='buttonCancelarPedido'
            >Cancelar
            </Button>
            {mode === 'edit' && <Button
              mode="success"
              startIcon={FaRedoAlt}
              onClick={handleAtualizarOnClick}
              disabled={!buttonSaveIsDisabled}
            >Atualizar
            </Button>}
            <Button
              mode="success"
              startIcon={FaSave}
              onClick={handleFinalizarOnClick}
              disabled={buttonSaveIsDisabled}
              data-cy='buttonSalvarPedido'
            >Salvar
            </Button>
          </div>
        </ContentHeaderAtendimento>
      </HeaderAtendimento>

      { pedidoAgenciado > 0 && (
        <div style={{ backgroundColor: '#fff937', padding: '15px', fontSize: '18px', marginTop: '5px', borderRadius: '5px' }}>Pedido agenciado</div>
      )}

      <ResumoOrcamento cliente={cliente} pedidoIdEmEdicao={orcamentoIdEmEdicao as number} mode={mode} />
      {
        (mode === 'register' && atendimentoCliente.pedidoEmAndamento?.abaAtiva === 'dadosgerais') && (
          <DadosGerais solar={solar} cliente={cliente} mode={mode} />
        )
      }

      {
        (mode === 'register' && atendimentoCliente.pedidoEmAndamento?.abaAtiva === 'produtos') && (
          <Produtos solar={solar} mode={mode} cliente={cliente} revenda={getRevenda()} />)
      }

      {
        (mode === 'edit' && atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number].abaAtiva === 'dadosgerais') && (
          <DadosGerais solar={solar} cliente={cliente} mode={mode} orcamentoIdEmEdicao={orcamentoIdEmEdicao} />
        )
      }

      {
        (mode === 'edit' && atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number].abaAtiva === 'produtos') && (
          <Produtos solar={solar} orcamentoIdEmEdicao={orcamentoIdEmEdicao as number} mode={mode} cliente={cliente} revenda={getRevenda()} />)
      }

      {
        (mode === 'edit' && atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number].abaAtiva === 'solicitacoes') &&
        <Solicitacoes mode={mode} cliente={cliente} orcamentoIdEmEdicao={orcamentoIdEmEdicao} />
      }

      {
        (mode === 'edit' && atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number].abaAtiva === 'negociacoes') &&
        <Negociacoes cliente={cliente} orcamentoIdEmEdicao={orcamentoIdEmEdicao} usuario={usuarioData} />
      }

      {
        (mode === 'edit' && atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number].abaAtiva === 'parcelas') && (
          <Parcelas cliente={cliente} mode={mode} orcamentoIdEmEdicao={orcamentoIdEmEdicao} />
        )
      }

      {
        (mode === 'edit' && atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number].abaAtiva === 'transferencias') && (
          <Transferencias cliente={cliente} mode={mode} orcamentoIdEmEdicao={orcamentoIdEmEdicao} />
        )
      }

      {
        (mode === 'edit' && atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number].abaAtiva === 'historico') && (
          <ListaHistorico historico={pedidoOriginal.historico} />)
      }

      {
        (mode === 'edit' && atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number].abaAtiva === 'analise') && (
          <ListaAnaliseCredito status={pedidoOriginal.analise_credito} descricao={pedidoOriginal.analise_credito_descricao} />)
      }

    </Container >
  )
}
