import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100;
  height: auto;
`

export const ListaAcoes = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 5px;
  width: 100%;
  margin: 10px 0px;
  >ul{
    display: flex;
    flex-direction: column;
    width: 100%;
    list-style: none;
    >li{
      display:flex;
      justify-content: flex-start;
      padding: 10px 15px;
      border-bottom: 1px solid ${props => props.theme.colors.borderLight};
      font-size: 15px;
      color: ${props => props.theme.colors.primaryText};
      :hover{
        background-color: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.backgroundLight};
        text-decoration: underline;
      }
      >span{
        margin-left: 15px;
      }
    }
  }
`
