import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useUsuario } from '..'

interface Error {
  status?: number,
  message?: string
}

export const useHandleErrors = () => {
  const { data: usuarioData, login, logout } = useUsuario()
  const [autoLogin] = useState<boolean>(true)
  const history = useHistory()
  const [executing, setExecuting] = useState(false)

  const handleError = useCallback(async (error: Error) => {
    if (error) {
      setExecuting(true)
      switch (error.status) {
        case 400:
          Swal.fire({
            icon: 'error',
            title: 'Erro!',
            text: `${error.message}`,
            timer: 3500,
            timerProgressBar: true
          })
          break
        case 401:
          Swal.queue([{
            title: usuarioData?.email,
            confirmButtonText: 'Autenticar',
            cancelButtonText: 'Cancelar',
            text: 'Seu acesso ao sistema expirou! Por favor, informe sua senha novamente.',
            input: 'password',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showLoaderOnConfirm: true,
            showCancelButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didClose: () => {
              setExecuting(false)
            },
            preConfirm: async (result) => {
              try {
                const token = await login({
                  email: usuarioData?.email as string,
                  password: result as string,
                  manterConectado: autoLogin
                })

                if (token) {
                  setExecuting(false)
                }
              } catch (error) {
                Swal.insertQueueStep({
                  icon: 'error',
                  title: error.message || 'Erro desconhecido',
                  showConfirmButton: false,
                  timer: 3500,
                  timerProgressBar: true
                })
                setTimeout(() => {
                  setExecuting(false)
                  history.push('/login')
                }, 3500)
              }
            },
            preDeny: () => {
              logout()
            }
          }])
          break
        case 403:
          Swal.fire({
            icon: 'error',
            title: 'Acesso Restrito!',
            html: error.message !== '' ? error.message?.replaceAll('\n', '<br>') : 'Você não possui autorização para acessar essa pagina!',
            timer: 3500,
            timerProgressBar: true
          })
          setExecuting(false)
          // setTimeout(() => {
          //   history.push('/')
          // }, 3500)
          break
        case 500:
          Swal.fire({
            icon: 'error',
            title: 'Erro interno!',
            text: 'Esta havendo um erro interno no servidor, tente novamente mais tarde!',
            timer: 3500,
            timerProgressBar: true
          })
          setExecuting(false)
          break
      }
    }
  }, [autoLogin, history, login, logout, usuarioData])

  return { handleError, executing }
}
