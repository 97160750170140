import capitalize from 'capitalize-pt-br'
import React, { useCallback, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { Input, InputF2, Loading, Table } from '..'
import { Empresa } from '../../../modules/empresas/models/empresa'
import { GetParams } from '../../../modules/_interfaces'
import { SortTableProps } from '../../../modules/_interfaces/sortTable'
import { useEmpresas, useUsuario } from '../../hooks'
import { TableColumnProps } from '../Table'
import { Container, Header } from './styles'

const rowsPerPage = 10

interface ListaEmpresasProps extends InputF2 {
  uf?: string
}

export const ListaEmpresas = ({ close, callBack, uf }: ListaEmpresasProps) => {
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [sortTable, setSortTable] = useState<SortTableProps>({} as SortTableProps)
  const { data: usuario } = useUsuario()
  const [empresaParams, setEmpresaParams] = useState(() => {
    let params: GetParams = {
      token: usuario?.token as string,
      filterOptions: {
        limit: rowsPerPage,
        skip: (currentPage) * rowsPerPage
      },
      filter: search || ''
    } as GetParams

    if (sortTable && sortTable.id) {
      if (sortTable.desc) {
        params.filterOptions = { ...params.filterOptions, order: '-empresas.' + sortTable.id }
      } else {
        params.filterOptions = { ...params.filterOptions, order: 'empresas.' + sortTable.id }
      }
    }

    if (uf && uf !== '') {
      params.filterObject = { ...params.filterObject, 'empresas.uf': uf }
    }
    return params
  })
  const { data: empresas, isLoading } = useEmpresas(empresaParams)

  const columns: TableColumnProps[] = [
    {
      Header: '#',
      accessor: 'id',
      minWidth: 20
    },
    {
      Header: 'Nome',
      accessor: 'nome',
      Cell: ({ value }) => capitalize(value)
    },
    {
      Header: 'Cidade',
      accessor: 'cidade',
      Cell: ({ value }) => capitalize(value)
    },
    {
      Header: 'UF',
      accessor: 'uf',
      minWidth: 20,
      Cell: ({ value }) => value.toString().toUpperCase()
    }
  ]

  const dbClickTableCallback = useCallback((empresa: Empresa) => {
    callBack && callBack(empresa)
    close && close()
  }, [callBack, close])

  const handleOnPageChange = useCallback((page: number) => {
    if (!empresas?.metadata?.count) {
      return
    }

    setCurrentPage(page)
    setEmpresaParams(old => {
      return {
        ...old,
        filterOptions: {
          limit: rowsPerPage,
          skip: (page) * rowsPerPage
        }
      }
    })
  }, [empresas])

  const handleSearch = useCallback(() => {
    const newFilterOptions = {
      ...empresaParams.filterOptions,
      skip: 0
    }

    setEmpresaParams(old => ({
      ...old,
      filterOptions: newFilterOptions,
      filter: search
    }))
  }, [empresaParams, search])

  const handleEnterSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleFilterOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }, [])

  const handleOnSortedChange = (header: any) => {
    const sort = {
      id: header[0].id,
      desc: header[0].desc
    }
    setSortTable(sort)
  }

  return <Container>
    {isLoading && <Loading/>}
    <Header>
      <div>
        <Input
          type='text'
          startIcon={FaSearch}
          onChange={handleFilterOnChange}
          onBlur={handleSearch}
          onKeyPress={handleEnterSearch}
          value={search}
          autoFocus
        />
      </div>

    </Header>
    <Table
      tabIndex={0}
      page={currentPage}
      pages={empresas?.metadata?.count && Math.ceil(empresas?.metadata?.count / rowsPerPage)}
      onPageChange={handleOnPageChange}
      manual
      loading={!empresas?.data}
      columns={columns} showPageJump={false}
      data={empresas?.data}
      pageSize={rowsPerPage}
      sortable={true}
      showPageSizeOptions={false}
      onSortedChange={handleOnSortedChange}
      noDataText="Nenhum depósito encontrado"
      nextText='Próximo'
      ofText='de'
      previousText='Anterior'
      showPagination={!!empresas?.metadata?.count && empresas?.metadata?.count >= rowsPerPage}
      pageText='Página'
      dbClickCallback={dbClickTableCallback}
      selectRow
    />
  </Container>
}
