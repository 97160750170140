import styled from 'styled-components'
import { fadein, increasingHeight } from '../../styles/animations'

interface BarStylesProps {
  percent: number
  color: 'chartOne' | 'chartTwo' | 'danger'
}

interface SquareSubtitleStylesProps {
  color: 'chartOne' | 'chartTwo'
}

interface ChartsHoverProps {
  percent: number

}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 492px;
  width: 49%;
  margin: 10px 0px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.border};
  overflow: hidden;
  position: relative;

  > span:first-of-type {
    padding: 15px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.4px;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
  }
  @media screen and (max-width: 720px) {
    width: 100%;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SemFechamentos = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  >span{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
  }
`

export const Charts = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  justify-content: center;

  &:hover {
    > label {
      display: block;
      white-space: nowrap;
    }
  }

`

export const ChartsHover = styled.label<ChartsHoverProps>`
    display: none;
    position: absolute;
    bottom: ${(props) => `${props.percent - 20}%`};
    white-space: nowrap;
    left: -50%;
    background: white;
    border: 1px solid;
    padding: 10px;
    border-radius: 5px; 
    z-index: 5
`

export const Bar = styled.div<BarStylesProps>`
  padding: 1px;
  position: relative;
  width: 18px;
  border-top-right-radius : 2px;
  border-top-left-radius : 2px;
  height: ${(props) => `${props.percent}%`};
  background-color: ${(props) => props.theme.colors[props.color]};
  transition-delay: 0.04s;

  /* &:hover {
    > div {
      opacity: 1;
    }
  } */

  animation: ${increasingHeight} 500ms cubic-bezier(0.68, -0.05, 0.27, 1.05) forwards;
  transform-origin: bottom center;
`

export const Info = styled.div`
  display: flex;
  width: auto;
  position: absolute;
  top: -35px;
  left: -20px;
  z-index: 3;
  padding: 7px;
  font-size: 15px;
  /* font-weight: bold; */
  /* transform: rotate(-15deg); */
  transition-delay: 0.04s;
`

export const Content = styled.div`
  display: flex;
  position: relative;
  padding: 30px 30px;
  align-items: flex-end;
  width: 100%;
  flex: 1;
`

export const Sections = styled.div`
  position: absolute;
  z-index: 2;
  left: 70px;
  bottom: 85px;
  width: calc(100% - 100px);
  display: flex;
  justify-content: space-between;
  height: 62.5%;

  > section {
    text-align: center;
    width: auto;
    position: relative;

    & + section {
      margin-left: 15px;
    }

    & > span {
      display: block;
      font-size: 12px;
      color: ${(props) => props.theme.colors.primaryText};
      margin-top: 15px;
    }
  }
`

export const CartesianPlane = styled.div`
  width: 100%;
  padding: 1px;
  height: 100%;

  & > div {
    height: 46px;
    width: 100%;
    border-bottom: dashed  1px #ccc;
    position: relative;

    h2 {
      display: block;
      position: absolute;
      left: -10px;
      min-width: 60px;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 1.1px;
      color: ${(props) => props.theme.colors.primaryText};
    }

    > span {
      position: absolute;
      left: -10px;
      top: -10px;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 1.1px;
      padding-right: 10px;
      background-color: ${props => props.theme.colors.backgroundLight}
    }
  }

  & > div:nth-last-of-type(1) {
    border-bottom: none;
  }
`

export const SubTitles = styled.div`
  position: absolute;
  top: 65px;
  right: 60px;

  > div + div {
    margin-top: 10px;
  }

  > div {
    display: flex;
    align-items: center;

    > span {
      margin-left: 8px;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 1.1px;
      color: ${(props) => props.theme.colors.primaryText};
    }
  }

  animation:  ${fadein} 0.6s forwards;
`

export const SquareSubtitle = styled.div<SquareSubtitleStylesProps>`
  background-color: ${(props) => props.theme.colors[props.color]};
  width: 12px;
  height: 12px;
  border-radius: 2px;
`
