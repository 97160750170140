import capitalize from 'capitalize-pt-br'
import React, { useCallback, useState } from 'react'
import { Button, InputF2, Loading, Table, TableColumnProps } from '..'
import { formatFloatToCurrency } from '../../../helpers'
import { makeAtualizarPedido } from '../../../modules/pedidos/factories'
import { GetPedido, GetPedidoCotacoesFretes } from '../../../modules/pedidos/models'
import { ItemPedidoApi } from '../../../modules/pedidos/services/_interfaces'
import { PostParams } from '../../../modules/_interfaces'
import { useExecUseCase } from '../../hooks'
import { TableText } from '../../styles/global'
import { TableCenterContent } from '../Contatos/styles'

import { Container } from './styles'

const rowsPerPage = 10

interface ListaContatosProps extends InputF2 {
  pedido?: GetPedido,
}

export const ListaFretes = ({ pedido, close, callBack }: ListaContatosProps) => {
  const [loadingFrete, setLoadingFrete] = useState(false)

  const {
    executeUseCase,
    executing: executingGravarOrcamento,
    error: useCaseError
  } = useExecUseCase()

  const fretes = pedido ? pedido.cotacoes_fretes : []

  const columns: TableColumnProps[] = [
    {
      Header: 'Transportadora',
      accessor: 'nome_fantasia',
      minWidth: 50,
      Cell: ({ value }) => capitalize(value)
    },
    {
      Header: 'Prazo',
      accessor: 'prazo',
      minWidth: 5,
      Cell: ({ value }) => <TableCenterContent>{value}</TableCenterContent>
    },
    {
      Header: 'Frete',
      accessor: 'frete',
      minWidth: 15,
      Cell: ({ value }) => <TableCenterContent>R$ {formatFloatToCurrency(Number(value))}</TableCenterContent>
    },
    {
      Header: 'Frete Pago',
      accessor: 'frete_pago',
      minWidth: 10,
      Cell: ({ value }) => {
        return <TableText position='center'><input type='checkbox' defaultChecked={value === 's'} readOnly style={{ pointerEvents: 'none' }} /></TableText>
      }
    }
  ]

  const handleSalvarFrete = useCallback(async (pedido?: any, frete?: GetPedidoCotacoesFretes) => {
    const atualizarPedidoUseCase = makeAtualizarPedido()

    if (!pedido) {
      return null
    }

    const pedidoItens = pedido.itens?.map((i: ItemPedidoApi) => {
      return {
        produto: i.produto,
        quantidade: i.quantidade,
        valor: i.valor,
        promocao_id: i.promocao_id,
        item_promocao_id: i.promocao_id,
        id: i.id,
        data: pedido.updated_at
      }
    }) || []

    const pedidoNovo: any = {
      id: pedido.id,
      funcionario: pedido.funcionario,
      transportadora: {
        id: frete?.transportadora_id
      },
      pedido_id: pedido.id,
      cliente: pedido.cliente,
      revenda: pedido.revenda,
      empresa: pedido.empresa,
      deposito: pedido.deposito,
      contato: pedido.contato,
      liberado: pedido.liberado,
      condicao_pagamento: pedido.condicao_pagamento,
      pagamento_tipo: pedido.pagamento_tipo,
      frete_por_conta: frete?.frete_pago === 's' ? 'emitente' : 'destinatario',
      frete_valor: frete?.frete_pago === 's' ? 0 : frete?.frete,
      frete_cotacao: frete?.frete,
      bndes: pedido.bndes,
      bndes_codigo: pedido.bndes_codigo,
      possibilidades: pedido.possibilidades,
      observacao: pedido.observacao,
      observacao_nota_fiscal: pedido.observacao_nota_fiscal,
      status: pedido.status,
      solicitacao_vendedor_status: pedido.solicitacao_vendedor_status,
      usoeconsumo: pedido.usoeconsumo,
      pagamento_gerente: pedido.pagamento_gerente,
      juros_forcado: pedido.juros_forcado,
      pedido_desbloqueado: pedido.juros_forcado,
      solicitacao_vendedor_observacao: pedido.solicitacao_vendedor_observacao,
      solar: pedido.solar,
      data_edicao: pedido.updated_at,
      updated_at: pedido.updated_at,
      itens: pedidoItens
    }

    if (pedidoNovo.frete_valor > 0 && pedidoNovo.frete_valor === pedidoNovo.frete_cotacao) {
      pedidoNovo.frete_por_conta = 'emitente'
    }

    if (pedido.revenda && (pedido.revenda.id && pedido.revenda.id > 0)) {
      pedidoNovo.tipo_faturamento = 'direto'
      pedidoNovo.revenda = pedido.cliente
      pedidoNovo.cliente = pedido.revenda
    } else {
      pedidoNovo.tipo_faturamento = 'normal'
      delete pedidoNovo.revenda
    }

    const params: any = {
      body: {
        ...pedidoNovo,
        id: pedido?.id
      }
    }

    await executeUseCase<PostParams>(
      atualizarPedidoUseCase,
      params,
      'Atualizado com sucesso!',
      `Deseja salvar as alterações do ${pedido?.id}?`,
      undefined,
      true
    )

    setLoadingFrete(false)

    callBack && callBack(frete)
    close && close()
  }, [callBack, close, executeUseCase])

  const handleChangeTransportadoraRetira = () => {
    setLoadingFrete(true)

    const newFrete = {
      frete: 0,
      frete_pago: 'n',
      transportadora_id: pedido?.cliente?.id
    } as GetPedidoCotacoesFretes

    handleSalvarFrete(pedido, newFrete)
  }

  const dbClickTableRowOnclick = useCallback(async (frete: GetPedidoCotacoesFretes) => {
    setLoadingFrete(true)

    handleSalvarFrete(pedido, frete)
  }, [handleSalvarFrete, pedido])

  return (
    <Container>
      {loadingFrete ? <Loading /> : ''}
      <Table
        className='tableContatosCliente'
        tabIndex={0}
        columns={columns}
        showPageJump={false}
        data={fretes && fretes.length > 0 ? fretes : []}
        pageSize={rowsPerPage}
        sortable={false}
        showPageSizeOptions={false}
        // loading={loadingFrete}
        noDataText="Nenhum frete localizdo"
        nextText='Próximo'
        ofText='de'
        previousText='Anterior'
        showPagination={fretes && fretes?.length >= rowsPerPage}
        pageText='Página'
        dbClickCallback={dbClickTableRowOnclick}
        selectRow
      />
      <Button
        mode='confirm'
        style={{ position: 'absolute', bottom: '50px' }}
        onClick={handleChangeTransportadoraRetira}>Cliente Retira</Button>
    </Container>
  )
}
