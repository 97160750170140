import { UseCase } from '../../_interfaces'
import { UltimasSaidasService } from '../../clientes/contracts'
import { GetUltimasSaidasParams, GetUltimasSaidasResponse } from '../../clientes/models'

export class UltimasSaidas implements UseCase<GetUltimasSaidasParams, GetUltimasSaidasResponse> {
  private readonly ultimasSaidasService: UltimasSaidasService

  constructor (ultimasSaidasService: UltimasSaidasService) {
    this.ultimasSaidasService = ultimasSaidasService
  }

  async execute (params: GetUltimasSaidasParams): Promise<GetUltimasSaidasResponse> {
    return await this.ultimasSaidasService.ultimasSaidas(params)
  }
}
