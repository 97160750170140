import { format } from 'date-fns'
import { formatStringDate, generateSearchQuery } from '../../../helpers'
import { ListarPedidosRespostaApi } from '../../pedidos/services/_interfaces'
import { GetParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { TrazerResumoClienteService } from '../contracts'
import { GetResumoResponse } from '../models'

export class TrazerResumoClienteServiceRest implements TrazerResumoClienteService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async trazerResumoCliente (params: GetParams): Promise<GetResumoResponse> {
    let { filter, filterOptions, filterObject, token } = params
    const query = generateSearchQuery(filter, filterOptions, filterObject)

    const response = await this.httpRequest.get<ListarPedidosRespostaApi>({
      path: 'pedidos',
      token,
      query
    })

    let retorno = {
      data: {
        pedidos: {
          total: 0,
          emAberto: 0,
          faturado: 0
        }
      }
    } as GetResumoResponse

    const mesAtual = format(new Date(), 'MM')
    if (response.res?.data) {
      const data = response.res.data
      let qtdeTotal = 0
      let qtdeAberto = 0
      let qtdeFaturado = 0
      data.forEach(element => {
        const dataElement = formatStringDate(element.data, 'xxxxxxxx', 'mes')
        if (dataElement === mesAtual) {
          qtdeTotal += 1
          if (element.status === 'faturado') {
            qtdeFaturado += 1
          } else if (element.status === 'normal' || element.status === 'estoque liberado') {
            qtdeAberto += 1
          }
        }
      })
      retorno.data.pedidos.total = qtdeTotal
      retorno.data.pedidos.faturado = qtdeFaturado
      retorno.data.pedidos.emAberto = qtdeAberto
    }

    retorno.metadata = response.res?.metadata
    retorno.error = response.error

    return retorno
  }
}
