import { PostParams } from '../../_interfaces'
import { AtualizarUsuarioService } from '../contracts'
import { Usuario } from '../models/usuario'

export class AtualizarUsuario {
  private readonly atualizarUsuarioService: AtualizarUsuarioService

  constructor (atualizarUsuarioService: AtualizarUsuarioService) {
    this.atualizarUsuarioService = atualizarUsuarioService
  }

  async execute (params: PostParams): Promise<Usuario> {
    return await this.atualizarUsuarioService.atualizarUsuario(params)
  }
}
