import React from 'react'
import { Table, TableColumnProps } from '..'
import { Compra } from '../../../modules/produtos/models'
import { TableText } from '../../styles/global'

import { Container } from './styles'

const rowsPerPage = 15

interface ListaComprasProps {
  compras?: Compra[],
}

export const ListaComprasProdutos = ({ compras }: ListaComprasProps) => {
  const sortedCompras = compras?.sort(function (a, b) {
    let x = a.empresa
    let y = b.empresa
    return ((x < y) ? -1 : ((x > y) ? 1 : 0))
  })
  const columns: TableColumnProps[] = [
    {
      Header: 'Empresa',
      accessor: 'empresa',
      Cell: ({ value }) => <TableText position='right'>{value}</TableText>
    },
    {
      Header: 'Doc. Fiscal',
      accessor: 'documento_fiscal',
      Cell: ({ value }) => <TableText position='center'>{value}</TableText>
    },
    {
      Header: 'Previsão',
      accessor: 'previsao',
      Cell: ({ value }) => <TableText position='center'>{value}</TableText>
    },
    {
      Header: 'Quantidade',
      accessor: 'quantidade',
      Cell: ({ value }) => <TableText position='right'>{value}</TableText>
    }
  ]

  return (
    // TODO DoubleClick copiar o nmr documento Fiscal
    <Container>
      <Table
        tabIndex={0}
        columns={columns}
        showPageJump={false}
        data={sortedCompras}
        pageSize={rowsPerPage}
        sortable={true}
        showPageSizeOptions={false}
        loading={false}
        noDataText="Nenhum Compra cadastrado para esse produto"
        nextText='Próximo'
        ofText='de'
        previousText='Anterior'
        showPagination={compras && compras.length >= rowsPerPage}
        pageText='Página'
        selectRow
      />
    </Container>

  )
}
