import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { TrazerPrecoServiceRest } from '../services'
import { TrazerPreco } from '../useCases/trazer-preco'

export function makeTrazerPreco (): TrazerPreco {
  const httpRequest = new HttpRequestImpl()
  const trazerPrecoService = new TrazerPrecoServiceRest(httpRequest)

  return new TrazerPreco(trazerPrecoService)
}
