import { PostParams } from '../../_interfaces'
import { ValidarFreteGratisPedidoService } from '../contracts'

export class ValidarFreteGratisPedido {
  private readonly validarFreteGratisPedidoService: ValidarFreteGratisPedidoService

  constructor (validarFreteGratisPedidoService: ValidarFreteGratisPedidoService) {
    this.validarFreteGratisPedidoService = validarFreteGratisPedidoService
  }

  async execute (params: PostParams): Promise<void> {
    return await this.validarFreteGratisPedidoService.validarFreteGratis(params)
  }
}
