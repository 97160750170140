import React, { ReactNode } from 'react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

type ImageZoomProps = {
  children: ReactNode;
}

export const ImageZoom = ({ children }: ImageZoomProps) => {
  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Zoom wrapStyle={{ width: '100%', position: 'inherit' }}>{children}</Zoom>
    </div>
  )
}
