import { transparentize } from 'polished'
import styled from 'styled-components'
import ArrowDown from '../../assets/imgs/down-arrow.png'
import ArrowUp from '../../assets/imgs/up-arrow.png'

interface WrapperProps {
  width?: string;
  margin?: string;
  marginRight?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.width ? props.width : '1')};
  justify-content: flex-start;
  align-items: flex-start;
  margin: ${(props) => (props.margin ? props.margin : '10px 0px')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0')};

  @media (max-width: 720px) {
    & {
      width: 100%;
    }
  }

  @media (max-width: 800px) {
    & .contasReceber {
      width: 150px;
    }
  }

  @media (max-width: 900px) {
    &.selectFiltroPedidos {
      width: 100%;
    }
  }
`

interface LabelProps {
  isActive?: boolean;
}

export const Label = styled.span<LabelProps>`
  display: flex;
  color: ${(props) => props.theme.colors.primaryText} !important;
  opacity: ${(props) => (props.isActive ? '1' : '0.5')};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
`

interface InputContainerProps {
  isActive: boolean;
  error?: string;
}

export const Container = styled.div<InputContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid
    ${(props) => {
    if (props.isActive) {
      return props.theme.colors.primary
    } else if (props.error) {
      return props.theme.colors.danger
    } else {
      return props.theme.colors.border
    }
  }};
  box-shadow: ${(props) =>
    props.isActive
      ? 'inset 0px 0px 5px' + transparentize(0.7, props.theme.colors.primary)
      : 'none'};

  select {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    border: none;
    padding: 0px 20px;
    color: ${(props) => props.theme.colors.primaryText};
    background-color: ${(props) => props.theme.colors.backgroundLight};
    appearance: none;
    background-image: url(${(props) => (props.isActive ? ArrowUp : ArrowDown)});
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 15px 15px;
  }
`
