/* eslint-disable react-hooks/exhaustive-deps */
import capitalize from 'capitalize-pt-br'
import Swal from 'sweetalert2'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, CheckBox, Input, InputF2, Loading, Modal, TableColumnProps, Textarea } from '..'
import { makeAtualizarPedido, makeValidarFreteGratisPedido } from '../../../modules/pedidos/factories'
import { GetPedido } from '../../../modules/pedidos/models'
import { PostParams } from '../../../modules/_interfaces'
import { useCalcPedido, useCliente, useExecUseCase, usePedido } from '../../hooks'
import { FormRow, TableText } from '../../styles/global'
import { Container, DivButton, FreteModalDivs } from './styles'
import { ListaTransportadoras } from '../ListaTransportadoras'
import { Empresa } from '../../../modules/empresas/models'
import { useHandleErrors } from '../../hooks/app/useHandleErrors'
import { Cliente } from '../../../modules/clientes/models'

interface ListaContatosProps extends InputF2 {
  pedidoId?: number,
}

export const ListaFretesManual = ({ pedidoId, close, callBack }: ListaContatosProps) => {
  const { fetcher: fetcherPedido, data: dataPedido, loading: loadingPedido } = usePedido()
  const { fetcher: getTransportadoraPorId, data: transportadoraData, loading: loadingTransportadora } = useCliente()
  const { recalcularPedido, loading: loadingRecacular } = useCalcPedido()
  const { handleError } = useHandleErrors()
  const [pedido, setPedido] = useState<GetPedido>()
  const [loadingFrete, setLoadingFrete] = useState(false)
  const [transportadoraId, setTransportadoraId] = useState(0)
  const [freteValorInput, setFreteValorInput] = useState<number>()
  const [freteCotacaoInput, setFreteCotacaoInput] = useState<number>()
  const [validarFreteAutomatico, setValidarFreteAutomatico] = useState()
  const [showTransportadora, setShowTransportadora] = useState(false)
  const [showAddFrete, setShowAddFrete] = useState(false)

  const {
    executeUseCase,
    executing: executingGravarOrcamento,
    error: useCaseError
  } = useExecUseCase()

  useEffect(() => {
    fetcherPedido(pedidoId)
  }, [])

  useEffect(() => {
    if (dataPedido?.data) {
      setPedido(dataPedido.data)
    }
  }, [dataPedido])

  useEffect(() => {
    callBack && callBack(pedido)
  }, [callBack, pedido])

  const transportadoraIdInputValue = useMemo(() => {
    if (transportadoraId > 0) {
      return transportadoraId
    } else {
      const emEdicao = pedido?.transportadora?.id
      return emEdicao || ''
    }
  }, [pedido])

  const transportadoraNomeInputValue = useMemo(() => {
    const emEdicao = pedido?.transportadora?.nome_fantasia as string
    return capitalize(emEdicao || '')
  }, [pedido])

  const getFreteCotacao = () => {
    const emEdicao = pedido?.frete_cotacao
    return emEdicao || 0
  }

  const getFreteValor = () => {
    const emEdicao = pedido?.frete_valor
    return emEdicao || 0
  }

  const fretePagoInputValue = useMemo(() => {
    const emEdicao = pedido?.frete_por_conta
    return emEdicao
  }, [pedido])

  const bloqueios = useMemo(() => {
    return {
      status_pedido: pedido?.status,
      pagamento_gerente: pedido?.pagamento_gerente,
      juros_forcado: pedido?.juros_forcado,
      estoque_zerado: pedido?.estoque_zerado,
      limite_bloqueado: pedido?.limite_bloqueado,
      pedido_desbloqueado: pedido?.pedido_desbloqueado
    }
  }, [pedido])

  const bloquearAlteracoesFrete = useMemo(() => {
    return pedido?.bloquear_alteracao_frete
  }, [pedido])

  const setFreteInputs = (tipo: string) => {
    if (tipo === 'pedido') {
      const freteCotacaoPedido = getFreteCotacao()
      const freteValorPedido = getFreteValor()

      setFreteCotacaoInput(freteCotacaoPedido)
      setFreteValorInput(freteValorPedido)
    }
  }

  const valorPagoAdiantamento = () => {
    const emEdicao = pedido?.valor_pago_adiantamento
    return emEdicao || 0
  }

  const handleVerificaFrete = async () => {
    let validar = validarFreteAutomatico || undefined

    if (!validarFreteAutomatico) {
      const useCase = makeValidarFreteGratisPedido()
      const frete = await executeUseCase<PostParams>(
        useCase,
        {
          body: {
            pedidoId: pedidoId as number
          }
        },
        '',
        '',
        undefined,
        false
      )

      validar = frete
      setValidarFreteAutomatico(frete)
    }

    if (validar) {
      Swal.fire({
        icon: 'warning',
        title: 'Frete Indisponível',
        text: 'Frete automatizado',
        showConfirmButton: true,
        showCancelButton: false
      })
      return false
    }

    return true
  }

  const handleClickTrocaTransportadora = async () => {
    // const frete = await handleVerificaFrete()
    // if (frete) {
    setShowTransportadora(true)
    // }
  }

  const handleChangeTransportadora = async (empresa: Empresa) => {
    const transportadora = {
      id: empresa.id,
      nome: empresa.razao_social,
      nome_fantasia: empresa.razao_social
    }

    const newPedido = {
      ...pedido,
      transportadora: transportadora
    } as GetPedido

    setPedido(newPedido)
    setShowTransportadora(false)
  }

  const handleChangeTransportadoraRetira = () => {
    const newPedido = {
      ...pedido,
      frete_cotacao: 0,
      frete_valor: 0,
      transportadora: pedido?.cliente
    } as GetPedido

    setPedido(newPedido)
    setShowTransportadora(false)
  }

  const handleCloseAddFrete = () => {
    setFreteInputs('undefined')
    setShowAddFrete(false)
  }

  const handleFreteCotacaoOnChange = useCallback((e: ChangeEvent<HTMLInputElement>, value: number, maskedValue: string) => {
    setFreteCotacaoInput(value)
    if (freteValorInput !== undefined) {
      setFreteValorInput(0)
    }
  }, [freteValorInput, setFreteValorInput, setFreteCotacaoInput])

  const handleFreteValorOnChange = useCallback((e: ChangeEvent<HTMLInputElement>, value: number, maskedValue: string) => {
    setFreteValorInput(value)
  }, [setFreteValorInput])

  const handleClickAddFrete = async () => {
    if (bloquearAlteracoesFrete?.status) {
      Swal.fire({
        icon: 'warning',
        title: 'Alterações Bloqueadas!',
        html: bloquearAlteracoesFrete?.message
      })
      return
    }

    // const verifica = await handleVerificaFrete()

    // if (verifica !== false) {
    // handleRemoveErroInput('frete_cotacao')
    // handleRemoveErroInput('frete_valor')
    setFreteInputs('pedido')
    setShowAddFrete(true)
    // }
  }

  const handleSaveAddFrete = () => {
    setShowAddFrete(false)

    const newPedido = {
      ...pedido,
      frete_cotacao: freteCotacaoInput !== undefined ? freteCotacaoInput : getFreteCotacao(),
      frete_valor: freteValorInput !== undefined ? freteValorInput : getFreteValor(),
      frete_por_conta: (freteCotacaoInput && freteCotacaoInput > 0) ? 'emitente' : 'destinatario'
    } as GetPedido

    callRecalc(newPedido, 'frete')
  }

  // const handleFocusTransportadora = async (event: React.FocusEvent<HTMLInputElement>) => {
  //   if (event.type === 'focus') {
  //     await handleVerificaFrete()
  //   }
  // }

  const changeTransportadora = useCallback(async (transportadora?: Cliente) => {
    if (!transportadoraData && !transportadora) {
      return
    }

    const newPedido = {
      ...pedido,
      transportadora: transportadora || transportadoraData
    } as GetPedido

    setPedido(newPedido)
  }, [transportadoraData])

  const handleEnterTransportadora = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const transportadora = await getTransportadoraPorId({
        clienteId: transportadoraId,
        revenda: false
      })
      changeTransportadora(transportadora)
    }
  }

  const handleTabTransportadora = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab') {
      const transportadora = await getTransportadoraPorId({
        clienteId: transportadoraId,
        revenda: false
      })
      changeTransportadora(transportadora)
    }
  }

  const handleTransportadoraIdOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let id = 0

    if (!!e.target.value && e.target.value as unknown as number > 0) {
      id = e.target.value as unknown as number
    }

    if (!e.target.value) {
      const transportadora = {
        id: '',
        nome_fantasia: ''
      } as Cliente

      const newPedido = {
        ...pedido,
        transportadora: transportadora || transportadoraData
      } as GetPedido

      setPedido(newPedido)

      // handleRemoveErroInput('transportadora_id')
    }

    setTransportadoraId(id)
  }, [pedido])

  const handleFretePagoOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let frete_por_conta = ''
    if (e.target.checked && freteCotacaoInput! > 0) {
      frete_por_conta = 'emitente'
    } else {
      frete_por_conta = 'destinatario'
    }

    const newPedido = {
      ...pedido,
      frete_por_conta: frete_por_conta
    } as GetPedido

    setPedido(newPedido)
  }, [freteCotacaoInput, pedido])

  const callRecalc = useCallback(async (pedido: GetPedido, nome?: string) => {
    if (!pedido) {
      return
    }

    const resp = await recalcularPedido({ pedido: pedido, mode: 'edit' })

    if (resp && resp.data) {
      const newPedido = resp.data.data as unknown as GetPedido
      setPedido({
        ...pedido,
        ...newPedido
      })
    } else if (resp && resp.error) {
      handleError(resp.error)
      // handleCleanAndSetErroInput(nome, resp.error.message)
    }
  }, [recalcularPedido])

  return (
    <Container>
      {loadingPedido || loadingFrete || loadingRecacular ? <Loading /> : ''}

      <FormRow className='dadosGerais'>
        <Input
          width='1'
          name="transportadora.id"
          data-cy='inputTransportadoraDadosGerais'
          title={'Código transportadora ou F2 para buscar'}
          label="Cod. Transp."
          placeholder='Cod. transportadora'
          value={transportadoraIdInputValue}
          disabled={true}
          // onFocusCapture={handleFocusTransportadora}
          onChange={handleTransportadoraIdOnChange}
          onKeyPress={handleEnterTransportadora}
          onKeyDownCapture={handleTabTransportadora}
          onBlur={changeTransportadora}
          type="text"
          f2Title="Lista de transportadoras"
          f2ModalMode="wide"
          // error={erros.transportadora_id}
        />
        <Input
          width='5'
          name="transportadora.nome"
          label="Transportadora"
          value={transportadoraNomeInputValue}
          placeholder='Transportadora'
          disabled
        />
        <DivButton width='10%'>
          <Button
            mode='confirm'
            type='button'
            onClick={handleClickTrocaTransportadora}
          >Trocar Transp.</Button>
        </DivButton>
      </FormRow>

      <FormRow className='dadosGerais'>
        <Input
          label='Peso'
          width='0 0 20%'
          name='Peso'
          type='text'
          value={pedido?.peso_total}
          disabled
        />
        <CheckBox
          label='Frete Pago'
          title='Sim'
          width='0 0 13.5%'
          onChange={handleFretePagoOnChange}
          checked={fretePagoInputValue === 'emitente' && pedido?.frete_cotacao! > 0}
          disabled={valorPagoAdiantamento() > 0 || bloqueios?.status_pedido === 'faturado' || freteCotacaoInput! >= 0 || freteCotacaoInput === undefined}
        />
        <Input
          label='Cotação do Frete'
          width='0 0 25%'
          name='Cotação Frete'
          type='currency'
          value={getFreteCotacao()}
          disabled
        />
        <Input
          label='Valor do Frete'
          width='0 0 25%'
          name='frete_valor'
          type='currency'
          value={getFreteValor()}
          // error={erros.frete_valor}
          disabled
        />
        <DivButton width='11.5%'>
          <Button
            mode='confirm'
            type='button'
            onClick={handleClickAddFrete}
            disabled={bloqueios?.status_pedido === 'faturado'}
          >Adicionar Frete</Button>
        </DivButton>
      </FormRow>
      <FormRow>
        <Textarea
          label='Observação'
          value={pedido?.observacao}
          height='75px'
          disabled={true}
        />
      </FormRow>

      {showAddFrete && <Modal
        title='Adicionar Frete'
        mode='normal'
        close={handleCloseAddFrete}
        onSave={handleSaveAddFrete}
        showButtonSave
      >
        <FreteModalDivs>
          <Input
            label='Cotação do Frete'
            name='Cotação Frete'
            type='currency'
            value={freteCotacaoInput}
            onInputCurrencyChance={handleFreteCotacaoOnChange}
            autoFocus
          />
          <Input
            label='Valor do Frete'
            name='frete_valor'
            type='currency'
            value={freteValorInput}
            onInputCurrencyChance={handleFreteValorOnChange}
            disabled={freteCotacaoInput === undefined || freteCotacaoInput <= 0}
          />
        </FreteModalDivs>
      </Modal>
      }

      {showTransportadora && <Modal
        title='Trocar Transportadora'
        mode='normal'
        close={() => setShowTransportadora(false)}
      >
        <ListaTransportadoras callBack={handleChangeTransportadora}/>
        <Button
          mode='confirm'
          style={{ position: 'absolute', bottom: '12px' }}
          onClick={handleChangeTransportadoraRetira}>Cliente Retira</Button>
      </Modal>
      }

    </Container>
  )
}
