import { generateSearchQuery } from '../../../helpers'
import { handleErrors } from '../../../helpers/handleErrors'
import { GetParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { ListarClientesService } from '../contracts'
import { GetListClienteResponse } from '../models'
import { ListarClientesRespApi } from './_interfaces'

export class ListarClientesServiceRest implements ListarClientesService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async listarClientes (params: GetParams): Promise<GetListClienteResponse> {
    let { filter, filterOptions, filterObject, token } = params
    const query = generateSearchQuery(filter, filterOptions, filterObject)

    const response = await this.httpRequest.get<ListarClientesRespApi>({
      path: 'clientes',
      token,
      query
    })

    handleErrors(response?.error)
    return response?.res as GetListClienteResponse
  }
}
