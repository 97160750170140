import { handleErrors } from '../../../helpers/handleErrors'
import { PostParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { AtualizarPedidoService } from '../contracts'
import { Orcamento } from '../models'
import { PedidoApi } from './_interfaces'

export class AtualizarPedidoServiceRest implements AtualizarPedidoService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async atualizarPedido (params: PostParams): Promise<Orcamento> {
    const { id, ...rest } = params.body

    const response = await this.httpRequest.put<PedidoApi>({
      path: `pedidos/${id}`,
      body: rest,
      token: params.token
    })

    handleErrors(response?.error)
    return response?.data as Orcamento
  }
}
