import { formatStringDate } from '../../../helpers'
import { Cliente } from '../../clientes/models'
import { UseCase } from '../../_interfaces'
import { ListarClientesService } from '../contracts'
import { GetClientesFuncionarioResponse } from '../models'
import { ListarClientesFuncionarioParams } from '../_interfaces/listClienteFuncParams'

export class ListarClientes implements UseCase<ListarClientesFuncionarioParams, GetClientesFuncionarioResponse> {
  private readonly listarClientesFuncionarioService: ListarClientesService

  constructor (listarClientesFuncionarioService: ListarClientesService) {
    this.listarClientesFuncionarioService = listarClientesFuncionarioService
  }

  async execute (params: ListarClientesFuncionarioParams): Promise<GetClientesFuncionarioResponse> {
    let response = await this.listarClientesFuncionarioService.listarClientes(params)

    if (response && response.data) {
      response.data = response.data.map((cliente: Cliente) => {
        return {
          ...cliente,
          pj: cliente.cnpj?.length === 14,
          ultima_ocorrencia: (cliente.ultima_ocorrencia !== undefined && cliente.ultima_ocorrencia !== '19900101') ? formatStringDate(cliente.ultima_ocorrencia, 'xxxxxxxx', 'completo') : ''
        }
      })
    }

    return response
  }
}
