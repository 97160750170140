import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > div:last-of-type {
    margin-top: 25px;
  }
`

export const BoxInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const BoxTelefone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  > div {
    margin-top: 0px;
    margin-bottom: 5px;
  }
`

export const BoxCheckboxCelular = styled.div`
  > div {
    margin: 0px;
  }
`
