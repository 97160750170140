import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { TrazerChamadoPorIdServiceRest } from '../services'
import { TrazerChamadoPorId } from '../useCases'

export function makeTrazerChamadoPorId (): TrazerChamadoPorId {
  const httpRequest = new HttpRequestImpl()
  const listarChamados = new TrazerChamadoPorIdServiceRest(httpRequest)

  return new TrazerChamadoPorId(listarChamados)
}
