import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
`

export const ContainerDiv = styled.div`
  display: flex;
  justify-content: space-between;
`
export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
`

export const DadosDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 5px 0px;
`

export const DadosDivButton = styled.div`
  display: flex;
  width: 100%;
  margin: 5px 0px;

  >button {
    margin-top: 37px;
    margin-left: 10px;
  }
  >div:first-of-type {
    margin-right: 10px;
  }

  >div + div {
    margin-right: 10px;
  }
`

interface DivSkeletonProps {
  width: string
}

export const DivSkeleton = styled.div<DivSkeletonProps>`
  display: 'flex';
  width: ${props => props.width ? props.width : '100%'};
  flex-direction: 'column';
  margin-top: 10px;
  .itemSkeleton{
    display: block;
    margin-bottom: 10px;
  }
`

export const DivButton = styled.div`
  display: flex;
  padding-top: 36px;
`

export const DivButtonSale = styled.div`
  display: flex;
  width: 100%;
  padding-top: 10px;
  justify-content: flex-end;
  align-content: center;
  > span:first-of-type{
    color: ${props => props.theme.colors.danger};
    font-size: 16px;
    padding: 10px 15px;
  }
`
