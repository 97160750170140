import capitalize from 'capitalize-pt-br'
import React from 'react'
import { Table, TableColumnProps } from '..'
import { Estoque } from '../../../modules/produtos/models'
import { TableText } from '../../styles/global'

import { Container } from './styles'

const rowsPerPage = 15

interface ListaEstoquesProps {
  estoques?: Estoque[],
}

export const ListaEstoques = ({ estoques }: ListaEstoquesProps) => {
  const sortedEstoques = estoques?.sort(function (a, b) {
    let x = a.empresa.id
    let y = b.empresa.id
    return ((x < y) ? -1 : ((x > y) ? 1 : 0))
  })

  //console.log(estoques)
  const columns: TableColumnProps[] = [
    {
      Header: 'Código',
      accessor: 'empresa.id',
      Cell: ({ row }) => <TableText position='right'>{`${row._original.empresa.id}`}</TableText>
    },
    {
      Header: 'Empresa',
      accessor: 'empresa.cidade',
      Cell: ({ row }) => <TableText position='left'>{`${capitalize(row._original.empresa.cidade)}`}</TableText>
    },
    {
      Header: 'Retido',
      accessor: 'retido',
      Cell: ({ value }) => <TableText position='right'>{value}</TableText>
    },
    {
      Header: 'Retido Entrada',
      accessor: 'retido_entrada',
      Cell: ({ value }) => <TableText position='right'>{value}</TableText>
    },
    {
      Header: 'Disponivel',
      accessor: 'saldo',
      Cell: ({ value }) => <TableText position='right'>{value}</TableText>
    }
  ]

  return (

    <Container>
      <Table
        tabIndex={0}
        columns={columns}
        showPageJump={false}
        data={sortedEstoques}
        pageSize={rowsPerPage}
        sortable={true}
        showPageSizeOptions={false}
        loading={false}
        noDataText="Nenhum Estoque cadastrado para esse produto"
        nextText='Próximo'
        ofText='de'
        previousText='Anterior'
        showPagination={estoques && estoques.length >= rowsPerPage}
        pageText='Página'
        selectRow
      />
    </Container>

  )
}
