import { GetParams, UseCase } from '../../_interfaces'
import { ListarProdutosService } from '../contracts'
import { GetProdutosListResponse } from '../models'

export class ListarProdutos implements UseCase<GetParams, GetProdutosListResponse> {
  private readonly listarProdutosService: ListarProdutosService

  constructor (listarProdutosService: ListarProdutosService) {
    this.listarProdutosService = listarProdutosService
  }

  async execute (params: GetParams): Promise<GetProdutosListResponse> {
    return await this.listarProdutosService.listarProdutos(params)
  }
}
