import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 5px;
  border-bottom: solid 1px ${props => props.theme.colors.borderLight};
  padding-bottom: 15px;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`
