import capitalize from 'capitalize-pt-br'
import { format } from 'date-fns'
import React, { useMemo, useState } from 'react'
import { FaPlus, FaTrash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { Button, Input, Modal, Textarea } from '../..'
import { Cliente } from '../../../../modules/clientes/models'
import { useUsuario } from '../../../hooks'
import { AppState } from '../../../store'
import { setCampoValorPedidoEmAberto } from '../../../store/atendimentos/actions'
import { AtendimentoType } from '../../../store/atendimentos/interfaces'

import { Container, DivButton, HeaderSolucoes, TextSolucoes, DivText } from './styles'

interface SolicitacoesProps {
  mode: string
  cliente: Cliente
  orcamentoIdEmEdicao?: number
}

export const Solicitacoes = ({ mode, cliente, orcamentoIdEmEdicao }: SolicitacoesProps) => {
  const dispatch = useDispatch()
  const atendimentoCliente = useSelector<AppState>(state => state.atendimentos.data[cliente.id as number]) as AtendimentoType
  const pedidoOriginal = atendimentoCliente?.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original
  const ObsOriginal = pedidoOriginal.solicitacao_vendedor_observacao
  const StatusOriginal = pedidoOriginal.solicitacao_vendedor_status
  const pedidoEmEdicao = atendimentoCliente?.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.emEdicao
  const ObsEdicao = pedidoEmEdicao.solicitacao_vendedor_observacao
  const StatusEdicao = pedidoEmEdicao.solicitacao_vendedor_status
  const [showModal, setShowModal] = useState(false)
  const [newObs, setNewObs] = useState('')
  const { data: userData } = useUsuario()

  const getObservacaotValue = () => {
    let retorno = ''
    if (ObsEdicao) {
      retorno = ObsEdicao
    } else if (ObsOriginal) {
      retorno = ObsOriginal
    }
    return retorno
  }

  const getStatusValue = () => {
    let retorno = ''
    if (StatusEdicao) {
      retorno = StatusEdicao
    } else if (StatusOriginal) {
      retorno = StatusOriginal
    }
    return capitalize(retorno)
  }

  const handleNewSolicitacao = () => {
    if (StatusOriginal === 'pendente') {
      Swal.fire({
        icon: 'warning',
        title: 'Não é possivel Solicitar!',
        text: 'Esse pedido está com uma solicitção com status ' + StatusOriginal,
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false
      })
      return
    }
    setShowModal(true)
  }

  const handleCancelSolicitacao = () => {
    let condition = false
    if (StatusEdicao) {
      condition = StatusEdicao !== 'nenhum'
    } else {
      condition = StatusOriginal !== 'nenhum'
    }
    if (condition) {
      dispatch(setCampoValorPedidoEmAberto({
        clienteId: cliente.id as number,
        pedidoId: orcamentoIdEmEdicao as number,
        campo: 'solicitacao_vendedor_status',
        valor: 'nenhum'
      }))
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Não é possivel Cancelar!',
        text: 'Esse pedido está sem status ativo ou já foi cancelado anteriormente',
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false
      })
    }
  }

  const onChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value) {
      setNewObs(e.target.value)
    }
  }

  const handleSave = () => {
    let stringToSave = ''
    const data = new Date()
    const newText = 'Solicitado por ' + userData?.nome + ' em ' + format(data, 'dd/MM/yyyy HH:mm:ss') + '\r\nObs.:' + newObs
    if (ObsEdicao && ObsEdicao !== '') {
      stringToSave = ObsEdicao + '\r\n' + newText
    } else {
      stringToSave = newText
    }

    dispatch(setCampoValorPedidoEmAberto({
      clienteId: cliente.id as number,
      pedidoId: orcamentoIdEmEdicao as number,
      campo: 'solicitacao_vendedor_status',
      valor: 'solicitado'
    }))

    dispatch(setCampoValorPedidoEmAberto({
      clienteId: cliente.id as number,
      pedidoId: orcamentoIdEmEdicao as number,
      campo: 'solicitacao_vendedor_observacao',
      valor: stringToSave
    }))
  }

  const handleClose = () => {
    setNewObs('')
    setShowModal(false)
  }

  // const bloquearAlteracoes = useMemo(() => {
  //   if (mode === 'register') {
  //     return atendimentoCliente.pedidoEmAndamento.pedido.bloquear_alteracoes
  //   } else if (mode === 'edit') {
  //     const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.bloquear_alteracoes
  //     return original
  //   }
  // }, [atendimentoCliente, mode, orcamentoIdEmEdicao])

  const bloqueios = useMemo(() => {
    if (mode === 'register') {
      return {
        status_pedido: atendimentoCliente.pedidoEmAndamento?.pedido?.status,
        pagamento_gerente: atendimentoCliente.pedidoEmAndamento?.pedido?.pagamento_gerente,
        juros_forcado: atendimentoCliente.pedidoEmAndamento?.pedido?.juros_forcado,
        estoque_zerado: atendimentoCliente.pedidoEmAndamento?.pedido?.estoque_zerado,
        limite_bloqueado: atendimentoCliente.pedidoEmAndamento?.pedido?.limite_bloqueado,
        pedido_desbloqueado: atendimentoCliente.pedidoEmAndamento?.pedido?.pedido_desbloqueado
      }
    } else if (mode === 'edit') {
      return {
        status_pedido: atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original?.status,
        pagamento_gerente: atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original?.pagamento_gerente,
        juros_forcado: atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original?.juros_forcado,
        estoque_zerado: atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original?.estoque_zerado,
        limite_bloqueado: atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original?.limite_bloqueado,
        pedido_desbloqueado: atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original?.pedido_desbloqueado
      }
    }
  }, [atendimentoCliente, mode, orcamentoIdEmEdicao])

  return (
    <Container>
      <TextSolucoes>
        <span>Observações</span>
        <DivText>
          <p>{getObservacaotValue()}</p>
        </DivText>
      </TextSolucoes>

      <HeaderSolucoes>
        <Input
          label='Status'
          name='status'
          value={getStatusValue()}
          width='0 0 49%'
          disabled
        />
        <DivButton>
          <Button
            mode='danger'
            type='button'
            onClick={handleCancelSolicitacao}
            startIcon={FaTrash}
            disabled={bloqueios?.status_pedido === 'faturado'}
          >Cancelar Ultima</Button>
          <Button
            mode='confirm'
            type='button'
            onClick={handleNewSolicitacao}
            startIcon={FaPlus}
            disabled={bloqueios?.status_pedido === 'faturado'}
          >Fazer Nova</Button>
        </DivButton>
      </HeaderSolucoes>

      {showModal && <Modal
        close={handleClose}
        onSave={handleSave}
        title='Nova Solicitação'
        showButtonSave
        buttonSaveText='Salvar'
      > <Textarea onChange={onChangeTextArea} value={newObs} />
      </Modal>
      }
    </Container>
  )
}
