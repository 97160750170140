import capitalize from 'capitalize-pt-br'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, ButtonTable, Input } from '..'
import { Cliente, ItemUltimaSaida } from '../../../modules/clientes/models'
import { Table, TableColumnProps } from '../Table'
import { TableActions, TableText } from '../../styles/global'
import { usePedido, useUltimasSaidas } from '../../hooks'
import { FaFolderOpen, FaSearch } from 'react-icons/fa'
import { Container, FieldData, Filter } from './styles'
import { Loading } from '../Loading'
import { differenceInDays, format, subMonths } from 'date-fns'
import { FilterUltimasSaidas } from '../../../modules/_interfaces/filtersProps'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { SortTableProps } from '../../../modules/_interfaces/sortTable'
import Swal from 'sweetalert2'
import { abrirPedido, setarBotaoAtivoCliente, setFiltroUltimasSaidas } from '../../store/atendimentos/actions'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store'
import { convertStringToDate } from '../../../helpers'

registerLocale('ptBR', ptBR)

interface UltimasSaidasProps {
  cliente: Cliente
}

export const UltimasSaidas = ({ cliente }: UltimasSaidasProps) => {
  const dispatch = useDispatch()
  const filtroRedux = useSelector<AppState>(state => state?.atendimentos?.data[cliente.id as number].filtroUltimasSaidas) as FilterUltimasSaidas
  const { fetcher: getPedido, data: pedidoSelecionado, loading: loadingPedido } = usePedido()
  const [startDate, setStartDate] = useState<Date>(subMonths(new Date(), 3))
  const [endDate, setEndDate] = useState<Date>(new Date())

  const [filters, setFilters] = useState<FilterUltimasSaidas>({
    search: '',
    dataInicial: format(subMonths(new Date(), 3), 'yyyyMMdd'),
    dataFinal: format(new Date(), 'yyyyMMdd')
  })

  const [sortTable, setSortTable] = useState<SortTableProps>({
    id: 'id',
    desc: false
  } as SortTableProps)

  const { fetcher: getUltimasSaidas, loading, data: dataUltimasSaidas } = useUltimasSaidas()

  useEffect(() => {
    if (filtroRedux && Object.keys(filtroRedux).length > 0) {
      setFilters(filtroRedux)
      const dataInicial = convertStringToDate(filtroRedux.dataInicial)
      const dataFinal = convertStringToDate(filtroRedux.dataFinal)
      setStartDate(dataInicial)
      setEndDate(dataFinal)
      getUltimasSaidas({
        sortTable,
        cliente_id: parseInt(cliente.id as string),
        filters: filtroRedux
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAbrirPedido = useCallback((item: ItemUltimaSaida) => {
    if (item) {
      getPedido(item.pedido_id)
    }
  }, [getPedido])

  const columns: TableColumnProps[] = useMemo(() => [
    {
      Header: 'Cod. Produto',
      accessor: 'produto_id',
      minWidth: 10,
      Cell: ({ value }) => {
        return <TableText position='right'>{value}</TableText>
      }
    },
    {
      Header: 'Produto',
      accessor: 'produto_nome',
      minWidth: 45,
      Cell: ({ value }) => {
        return <TableText position='left'>
          {`${capitalize(value)}`}
        </TableText>
      }
    },
    {
      Header: 'Pedido',
      accessor: 'pedido_id',
      minWidth: 15,
      Cell: ({ value }) => {
        return <TableText position='center'>{capitalize(value)}</TableText>
      }
    },
    {
      Header: 'Data',
      accessor: 'data_faturamento',
      minWidth: 15,
      Cell: ({ value }) => {
        return <TableText position='center'>{value}</TableText>
      }
    },
    {
      Header: 'Valor',
      accessor: 'valor',
      minWidth: 15,
      Cell: ({ value }) => {
        return <TableText position='right'>{value}</TableText>
      }
    },
    {
      Header: 'Quantidade',
      accessor: 'quantidade',
      minWidth: 15,
      Cell: ({ value }) => {
        return <TableText position='right'>{value}</TableText>
      }
    },
    {
      Header: 'Total',
      accessor: 'total',
      minWidth: 15,
      Cell: ({ value }) => {
        return <TableText position='right'>{value}</TableText>
      }
    },
    {
      Header: 'Ações',
      minWidth: 10,
      Cell: ({ row }) => {
        return (
          <TableActions>
            <ButtonTable
              typeButton='primary'
              title="Abrir Pedido"
              onClick={() => handleAbrirPedido(row)}
            >
              <FaFolderOpen size='15px' />
            </ButtonTable>
          </TableActions>
        )
      }
    }
  ], [handleAbrirPedido])

  useEffect(() => {
    if (pedidoSelecionado?.data?.id) {
      dispatch(abrirPedido({
        pedido: pedidoSelecionado?.data
      }))
      dispatch(setarBotaoAtivoCliente({
        clienteId: cliente.id as number,
        botao: 'pedidos'
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pedidoSelecionado])

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    let text = ''
    if (e.target.value) {
      // eslint-disable-next-line no-useless-escape
      text = e.target.value.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
    }
    setFilters(old => {
      return {
        ...old,
        search: text
      }
    })
  }

  const erroDiffDays = () => {
    Swal.fire({
      title: 'Periodo muito Longo!',
      text: 'O periodo escolhido deve se menor ou igual a 90 dias, tente novamente.',
      icon: 'warning'
    })
    setStartDate(subMonths(new Date(), 3))
    setEndDate(new Date())
  }

  const handleChangeStart = (date: Date | null) => {
    if (date) {
      setStartDate(date)
      const formatData = format(date, 'yyyyMMdd')
      const dataInicial = formatData.toString()
      setFilters((oldState: FilterUltimasSaidas) => {
        const newState = {
          ...oldState,
          dataInicial
        }
        return newState
      })
    }
  }

  const handleChangeEnd = (date: Date | null) => {
    if (date) {
      setEndDate(date)
      const formatData = format(date, 'yyyyMMdd')
      const dataFinal = formatData.toString()
      setFilters((oldState: FilterUltimasSaidas) => {
        const newState = {
          ...oldState,
          dataFinal
        }
        return newState
      })
    }
  }

  const handleSearch = () => {
    const diffDays = differenceInDays(endDate, startDate)
    if (diffDays > 100) {
      erroDiffDays()
      return
    }

    dispatch(setFiltroUltimasSaidas({
      cliente_id: cliente.id as number,
      filter: filters
    }))

    getUltimasSaidas({
      sortTable,
      cliente_id: parseInt(cliente.id as string),
      filters
    })
  }

  const handleEnterSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  return (
    <Container>
      {(loading || loadingPedido) && <Loading></Loading>}
      <Filter>
        <Input
          label='Pesquisa'
          title='Pesquisa'
          value={filters.search}
          onChange={handleChangeSearch}
          onKeyPress={handleEnterSearch}
          placeholder='Pesquisa ...'
          width='0 0 25%'
        />
        <FieldData width='16%'>
          <label>Data Início</label>
          <DatePicker
            id='dataInicialFiltroPedidos'
            locale='ptBR'
            dateFormat="dd/MM/yyyy"
            closeOnScroll={true}
            selected={startDate}
            onChange={handleChangeStart}
            startDate={startDate}
            endDate={endDate}
          />
        </FieldData>
        <FieldData width='16%'>
          <label>Data Término</label>
          <DatePicker
            id='dataFinalFiltroPedidos'
            locale='ptBR'
            dateFormat="dd/MM/yyyy"
            closeOnScroll={true}
            selected={endDate}
            onChange={handleChangeEnd}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
        </FieldData>
        <Button
          data-cy='buttonPesquisarCliente'
          mode='confirm'
          startIcon={FaSearch}
          type="button"
          onClick={handleSearch}
          tabIndex={1}
        >Pesquisar</Button>
      </Filter>
      <Table
        columns={columns}
        showPageJump={false}
        data={dataUltimasSaidas?.data}
        manual
        loading={!dataUltimasSaidas?.data && loading}
        sortable={false}
        showPageSizeOptions={false}
        noDataText="Nenhuma ocorrencia"
        nextText="Próximo"
        ofText="de"
        previousText="Anterior"
        showPagination={false}
        pageText="Página"
        dbClickCallback={handleAbrirPedido}
        selectRow
        deleteRow
      />
    </Container>
  )
}
