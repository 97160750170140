import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  margin-top: 30px;

  > section {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    margin-top: 20px;
    height: auto;
  }
`

export const TipoCliente = styled.div`
  label {
    color: ${props => props.theme.colors.primaryText};
    font-weight: 600;
    text-transform: uppercase;
  }
`

export const Grupo = styled.div`
  display: block;
  label {
    display: inline-block;
    margin-bottom: 15px;
    color: ${props => props.theme.colors.primaryText};
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
  }
`

export const DivForm = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;

  @media screen and (max-width: 720px) {
    margin-top: 0px;
    flex-direction: column;
  }
`

export const RadioButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 15px;
  letter-spacing: 0.075em;
  user-select: none;
  >span {
    display: flex;
    color: ${props => props.theme.colors.primaryText} !important;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 720px) {
    margin-bottom: 30px;
    width: 100%;
  }

`
export const Divider = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
