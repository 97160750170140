import { useState } from 'react'
import { useUsuario } from '..'
import { makeTrazerClientePorId } from '../../../modules/clientes/factories'
import { Cliente, GetOneClienteResponse } from '../../../modules/clientes/models'
import { useHandleErrors } from '../app/useHandleErrors'

interface FetcherProps {
  clienteId?: number,
  revenda?: boolean
}

export function useCliente () {
  const { data: usuarioData } = useUsuario()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Cliente>()
  const { handleError, executing } = useHandleErrors()

  async function fetcher ({ clienteId, revenda = false }: FetcherProps) {
    setLoading(true)
    if (clienteId) {
      const trazerClientePorId = makeTrazerClientePorId()

      const response:GetOneClienteResponse = await trazerClientePorId.execute({
        id: clienteId,
        token: usuarioData?.token as string
      })

      if (revenda && response?.data?.is_revenda_cliente === 'n') {
        setLoading(false)

        return
      } else if (response.data) {
        setData(response.data)
        setLoading(false)
        return response.data
      }

      if (response.error && !executing) {
        handleError(response.error)
      }
    } else {
      setData(undefined)
    }
    setLoading(false)
  }

  return { fetcher, data, loading }
}
