import styled, { css } from 'styled-components'
import { darken, transparentize } from 'polished'
import { fadein } from '../../styles/animations'

export const Container = styled.div`
  border-radius: 7px;
  border: solid 1px ${props => props.theme.colors.borderLight};
  padding: 20px;
  animation: ${fadein} 0.6s forwards;

  header {
    display: flex;
    align-items: center;
    width: 400px;
    height: 100%;
    margin-top: 20px;
    margin-left: 15px;
  }

  footer {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
`

export const Data = styled.div`
  padding: 15px;

  strong {
    font-size: 14px;
    letter-spacing: 1px;
  }

  span {
    display: block;
    color: ${props => props.theme.colors.secondaryText};
    letter-spacing: 1px;
    margin-top: 9px;
    font-size: 12px;
  }

  p {
    color: ${props => props.theme.colors.secondaryText};
    margin-top: 7px;
  }
`

interface AvatarProps {
  border?: 'danger' | 'normal'
}

export const Avatar = styled.div<AvatarProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 15px 0px;
  background-color: ${props => props.theme.colors.borderLight};
  border: solid 2px ${props => props.border === 'danger' ? props.theme.colors.warning : props.theme.colors.borderLight};
  border-radius: 50%;
  height: 102px;
  width: 102px;
  overflow: hidden;

  ${props => props.border === 'danger' && css`
    box-shadow: 0px 0px 0px 3px ${transparentize(0.7, props.theme.colors.warning)};
  `}

  > svg {
    fill: ${props => props.theme.colors.border};
  }

  img {
    width: auto;
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
  }

  div {
    position: absolute;
    display: none;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    background-color: rgba(0,0,0, 0.3);
    border-radius: 50%;
    width: 100%;
    height: 100%;

    svg {
     font-size: 25px;
     fill: ${props => props.theme.colors.background};
    }
  }
  &:hover{
    >div{
      display:flex;
      transition: all 0.2s;
    }
  }

`

export const Form = styled.div`
  width: 400px;

  button {
    align-self: flex-end;
    margin-top: 15px;
  }
`

export const SelectAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 15px 0px;
  background-color: ${props => props.theme.colors.borderLight};
  border: solid 4px ${props => props.theme.colors.border};
  border-radius: 50%;
  height: 150px;
  width: 150px;
  overflow: hidden;

  > img {
    height: 100%;
    width: auto;
    object-fit: contain;
  }

  cursor: pointer;

  &:hover {
    background-color: ${props => darken(0.1, props.theme.colors.border)};
    border: solid 4px ${props => darken(0.1, props.theme.colors.border)};
  }

  transition: all 0.3s;
`

export const ContentModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Text = styled.div`
  display: flex;
  flex: 1;
  margin-left: 15px;
  font-size: 14px;
`
