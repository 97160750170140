import { useState } from 'react'
import { makeTrazerListaPreco } from '../../../modules/produtos/factories'
import { GetPrecosParams, GetPrecosProdutosResponse, Preco } from '../../../modules/produtos/models'
import { useHandleErrors } from '../app/useHandleErrors'
import { useUsuario } from '../usuario/useUsuario'

const trazerPreco = makeTrazerListaPreco()

interface UsePrecoProdutoParams {
  produtos: number[]
  empresas: number[]
  clienteId: number
  solar: 's' | 'n'
  usoeconsumo: 's' | 'n'
}

export function useListaPrecosProdutos () {
  const { data: usuarioData } = useUsuario()
  const [data, setData] = useState<Preco[]>()
  const [loading, setLoading] = useState(false)
  const { handleError, executing } = useHandleErrors()

  async function fetcher (params: UsePrecoProdutoParams) {
    setLoading(true)

    let usePrecoProdutoParams: GetPrecosParams = {
      empresas: params.empresas,
      produtos: params.produtos,
      clienteId: params.clienteId,
      token: usuarioData?.token as string,
      solar: params.solar,
      usoeconsumo: params.usoeconsumo
    }

    const response: GetPrecosProdutosResponse = await trazerPreco.execute(usePrecoProdutoParams)

    if (response) {
      if (response.data) {
        setData(response.data)
        setLoading(false)
      }
      if (response.error && !executing) {
        handleError(response.error)
      }
    } else {
      handleError({ status: 400, message: 'Ocorreu um erro ao trazer o preço, por favor tente novamente.' })
    }

    setLoading(false)
  }

  return { fetcher, data, loading }
}
