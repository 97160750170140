import { Produto } from '../../../produtos/models'
import { GetPedido } from '../../models'
import { PedidoApi, ItemPedidoApi } from '../_interfaces'

interface ItemCadastro {
  id?: number,
  data?: string,
  total?: number,
  stTotal?: any,
  ipiTotal?: any,
  quantidade?: number,
  valor?: number,
  promocao_id?: number,
  item_promocao_id?: number,
  lote?: string,
  produto?: Produto
}

export function sanetizarPedidoCadastro (pedido: GetPedido): PedidoApi {
  const itens = pedido?.itens?.map((item: ItemCadastro) => {
    let itemOrcamento: ItemPedidoApi = {
      produto_id: item?.produto?.id as number,
      quantidade: item?.quantidade as number,
      valor: item?.valor,
      data: item?.data as string,
      promocao_id: item?.promocao_id as number,
      item_promocao_id: item?.item_promocao_id as number,
      st: item.stTotal as number,
      ipi: item.ipiTotal as number
    }

    if (item.id !== undefined) {
      itemOrcamento.id = item.id
    }

    if (item.lote) {
      itemOrcamento.lote = item?.lote
    }

    return itemOrcamento
  })

  let data = {} as PedidoApi

  if (pedido?.funcionario?.empresa_id && pedido?.funcionario?.empresa_id > 0) {
    data.empresa_id = pedido.funcionario.empresa_id
  } else if (pedido.empresa && pedido.empresa.id && pedido.empresa.id > 0) {
    data.empresa_id = pedido.empresa.id as number
  }

  if (pedido.tipo) {
    data.tipo = pedido.tipo
  }

  if (pedido?.deposito) {
    data.deposito_id = pedido.deposito.id as number
  }

  if (pedido?.tipo_faturamento === 'direto') {
    if (pedido?.cliente?.id && pedido.revenda) {
      data.revenda_id = pedido.cliente.id as number
      data.cliente_id = pedido.revenda.id as number
    }
  } else {
    if (pedido?.cliente?.id) {
      data.cliente_id = pedido.cliente.id as number
    }
  }

  if (pedido?.id) {
    data.pedido_id = pedido.id
  } else if (pedido.empresa) {
    data.pedido_id = null
  }

  if (pedido?.funcionario?.id) {
    data.funcionario_id = pedido.funcionario.id as number
  }

  if (pedido?.funcionario2?.id) {
    data.funcionario2_id = pedido.funcionario2.id as number
  }

  if (pedido?.transportadora?.id) {
    data.transportadora_id = pedido?.transportadora?.id as number
  }

  if (pedido?.contato?.id) {
    data.contato_id = pedido.contato.id as number
  }

  if (pedido?.condicao_pagamento) {
    data.condicao_pagamento = pedido.condicao_pagamento
  }

  if (pedido?.pagamento_tipo) {
    if (pedido.pagamento_tipo === 'cartao') {
      data.pagamento_tipo = 'carteira'
    } else {
      data.pagamento_tipo = pedido.pagamento_tipo
    }
  }

  if (pedido.frete_por_conta) {
    data.frete_por_conta = pedido.frete_por_conta
  }

  if (pedido.frete_valor !== undefined) {
    data.frete_valor = pedido.frete_valor
  }

  if (pedido.frete_cotacao !== undefined) {
    data.frete_cotacao = pedido.frete_cotacao
  }

  if (pedido.bndes) {
    data.bndes = pedido.bndes
  }

  if (pedido.bndes_codigo) {
    data.bndes_codigo = pedido.bndes_codigo
  }

  if (pedido.possibilidades) {
    data.possibilidades = pedido.possibilidades
  }

  if (pedido.observacao) {
    data.observacao = pedido.observacao
  }

  if (pedido.observacao_nota_fiscal) {
    data.observacao_nota_fiscal = pedido.observacao_nota_fiscal
  }

  if (pedido.juros) {
    data.juros = pedido.juros
  }

  data.data_edicao = pedido.updated_at

  data.usoeconsumo = pedido.usoeconsumo ? pedido.usoeconsumo : 'n'
  data.pagamento_gerente = pedido.pagamento_gerente ? pedido.pagamento_gerente : 'n'
  data.juros_forcado = pedido.juros_forcado ? pedido.juros_forcado : 'n'
  data.pedido_desbloqueado = pedido.pedido_desbloqueado ? pedido.pedido_desbloqueado : 'n'

  data.solar = pedido.solar ? pedido.solar : 'n'

  // TODO corrigir
  // if (pedido.solar) {
  //   data.solar = pedido.solar
  // }

  data.itens = itens as ItemPedidoApi[]
  return data
}
