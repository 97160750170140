import * as yup from 'yup'

export const updateSchema = yup.object().shape({
  email: yup.string().email(),
  email_nfe: yup.string().email(),
  email_nfe2: yup.string().email(),
  contatos: yup.array()
    .of(
      yup.object().shape({
        nome: yup.string(),
        email: yup.string().email(),
        fiscal: yup.mixed().oneOf(['s', 'n']),
        comercial: yup.mixed().oneOf(['s', 'n']),
        financeiro: yup.mixed().oneOf(['s', 'n']),
        status: yup.mixed().oneOf(['ativo', 'inativo']),
        telefones: yup.array()
          .of(
            yup.object().shape({
              ddd: yup.string(),
              numero: yup.string(),
              ramal: yup.string(),
              whatsapp: yup.mixed().oneOf(['s', 'n'])
            })
          )
      })
    )
})
