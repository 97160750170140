import { GetParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { TrazerChamadoPorIdService } from '../contracts'
import { Chamado } from '../models'

export class TrazerChamadoPorIdServiceRest implements TrazerChamadoPorIdService {
  private readonly http: HttpRequest

  constructor (http: HttpRequest) {
    this.http = http
  }

  async trazerChamadoPorId (params: GetParams): Promise<Chamado> {
    if (!params.filter) {
      return {} as Chamado
    }

    let { filter: id, token } = params

    const response = await this.http.get<Chamado>({
      path: `chamados/${id}`,
      token
    })

    return response?.res as Chamado
  }
}
