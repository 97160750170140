import { generateSearchQuery } from '../../../helpers'
import { handleErrors } from '../../../helpers/handleErrors'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { TrazerOcorrenciasClienteService } from '../contracts'
import { GetOcorrenciasResponse, GetParamsOcorrencia } from '../models'
import { ListarClientesRespApi } from './_interfaces'

export class TrazerOcorrenciasClienteServiceRest implements TrazerOcorrenciasClienteService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async trazerOcorrenciasCliente (params: GetParamsOcorrencia): Promise<GetOcorrenciasResponse> {
    let { filter, filterOptions, filterObject, token, cliente_id } = params
    const query = generateSearchQuery(filter, filterOptions, filterObject)

    const response = await this.httpRequest.get<ListarClientesRespApi>({
      path: '/clientes/' + cliente_id + '/ocorrencias',
      token,
      query
    })

    handleErrors(response?.error)
    return response?.res as GetOcorrenciasResponse
  }
}

/* return new Promise((resolve, reject) => {
      resolve(
        {
          data: [
            {
              cliente: {
                id: '1',
                nome: 'Cliente 1'
              },
              contato: {
                id: '1',
                nome: 'Contato 1'
              },
              data: '2021-10-05',
              hora: '12:45:30',
              descricao: 'Essa é uma ocorrencia de teste, uma ocorrencia de teste, uma ocorrencia de teste, uma ocorrencia de teste, uma ocorrencia de teste, uma ocorrencia de teste, uma ocorrencia de teste',
              tipo: 'teste'
            },
            {
              cliente: {
                id: '1',
                nome: 'Cliente 1'
              },
              contato: {
                id: '1',
                nome: 'Contato 1'
              },
              data: '2021-10-05',
              hora: '12:45:30',
              descricao: 'Essa é uma ocorrencia de teste',
              tipo: 'teste'
            },
            {
              cliente: {
                id: '1',
                nome: 'Cliente 1'
              },
              contato: {
                id: '1',
                nome: 'Contato 1'
              },
              data: '2021-10-05',
              hora: '12:45:30',
              descricao: 'Essa é uma ocorrencia de teste',
              tipo: 'teste'
            },
            {
              cliente: {
                id: '1',
                nome: 'Cliente 1'
              },
              contato: {
                id: '1',
                nome: 'Contato 1'
              },
              data: '2021-10-05',
              hora: '12:45:30',
              descricao: 'Essa é uma ocorrencia de teste',
              tipo: 'teste'
            }
          ]
        }
      )
    }) */
