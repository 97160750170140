/* eslint-disable no-unused-vars */
import { action } from 'typesafe-actions'
import { Usuario } from '../../../modules/usuarios/models/usuario'

export enum UsuariosActions {
  SET_USUARIO_ID = '@produtos/SETAR_USUARIO_ID',
  SET_USUARIO = '@produtos/SETAR_USUARIO'
}

export const setarUserId = (data: number) => action(UsuariosActions.SET_USUARIO_ID, data)
export const setarUser = (data: Usuario) => action(UsuariosActions.SET_USUARIO, data)
