import { HttpRequestImpl } from '../../../infra/http'
import { SchemaValidator } from '../../../infra/validator/schemaValidator'
import { CadastrarPedidoServiceRest } from '../services'
import { CadastrarPedido } from '../useCases'
import { createSchema } from '../validators'

export function makeCadastrarPedido (): CadastrarPedido {
  const httpRequest = new HttpRequestImpl()
  const schemaValidator = new SchemaValidator(createSchema)
  const cadastrarPedidoService = new CadastrarPedidoServiceRest(httpRequest)

  return new CadastrarPedido(cadastrarPedidoService, schemaValidator)
}
