
import React, { useState, useCallback, useEffect, ChangeEvent } from 'react'
import { Input, Select, Textarea } from '..'
import { FormRow } from '../../styles/global'

import { Container } from './styles'

type SolicitacaoProps = {
  tipo: string
  observacao: string
}

interface Props {
  pedidoId?: number,
  close?: () => void
  callBack: (data: any) => void
}

export const NovaSolicitacao = ({ pedidoId, close, callBack }: Props) => {
  const [solicitacao, setSolicitacao] = useState<SolicitacaoProps>({ tipo: 'frete' } as SolicitacaoProps)

  useEffect(() => {
    callBack(solicitacao)
  }, [callBack, solicitacao])

  const handleInputChange = useCallback((e: ChangeEvent<HTMLSelectElement|HTMLTextAreaElement>) => {
    const { currentTarget: { name, value } } = e

    const newSolicitacao: SolicitacaoProps = {
      ...solicitacao,
      [name]: value
    }

    setSolicitacao(newSolicitacao)
  }, [solicitacao])

  return (
    <Container>
      <FormRow>
        <Input
          name="pedido_id"
          label='Pedido'
          value={pedidoId}
          width='1'
          disabled
        />
        <Select
          name="tipo"
          title='Tipo'
          value={solicitacao?.tipo}
          onChange={handleInputChange}
          placeholder='Tipo'
          width='2'
        >
          <option value="frete">Frete</option>
        </Select>
      </FormRow>
      <FormRow>
        <Textarea
          name="observacao"
          label='Solicitação'
          value={solicitacao?.observacao}
          onChange={handleInputChange}
          width='1'
          height='50px'
        />
      </FormRow>
    </Container>
  )
}
