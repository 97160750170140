type tipoHora = 'resumo' | 'completo'

type formatos = 'xxxxxx'

export const formatStringHora = (txt: string, tipo?: tipoHora) => {
  let hora = ''
  let minutos = ''
  let segundos = ''

  for (let index = 0; index < 6; index++) {
    if (!txt[index]) {
      return ''
    }

    if (index <= 1) {
      hora = hora + txt[index]
    } else if (index > 1 && index <= 3) {
      minutos = minutos + txt[index]
    } else if (index > 3) {
      segundos = segundos + txt[index]
    }
  }

  let retorno = ''

  switch (tipo) {
    case 'resumo':
      retorno = hora + ':' + minutos
      break
    default:
      retorno = hora + ':' + minutos + ':' + segundos
  }

  return retorno
}
