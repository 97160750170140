import { handleErrors } from '../../../helpers/handleErrors'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { TrazerEstoqueAlternativoService } from '../contracts'
import { GetEstoqueAlternativoResponse, TrazerEstoqueAlternativoParams } from '../models'

export class TrazerEstoqueAlternativoServiceRest implements TrazerEstoqueAlternativoService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  // eslint-disable-next-line no-empty-pattern
  async trazerEstoqueAlternativo (params: TrazerEstoqueAlternativoParams): Promise<GetEstoqueAlternativoResponse> {
    let { pedidoId, token } = params
    const response = await this.httpRequest.get<GetEstoqueAlternativoResponse>({
      path: `pedidos/${pedidoId}/estoque_alternativo`,
      token
    })

    handleErrors(response?.error)
    const result: GetEstoqueAlternativoResponse = {
      data: response.res?.data,
      metadata: response.res?.metadata,
      error: response.error
    }

    return result
  }
}
