import { TransformarPedidoService } from '../contracts'
import { TransformarPedidoParams } from '../services'

export class TransformarPedido {
  private readonly transformarPedidoService: TransformarPedidoService

  constructor (transformarPedidoService: TransformarPedidoService) {
    this.transformarPedidoService = transformarPedidoService
  }

  async execute (params: TransformarPedidoParams): Promise<boolean> {
    if (!params?.id) {
      return false
    }

    let response = await this.transformarPedidoService.transformarPedido(params.id, params.token)

    if (response) {
      return true
    } else {
      return false
    }
  }
}
