import React, { ChangeEvent, InputHTMLAttributes } from 'react'
import { Container, Wrapper, Label } from './styles'

interface CheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  title?: string
  label?: string
  width?: string
  marginRight?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const CheckBox = ({ title, label, width, marginRight, onChange, ...rest }: CheckBoxProps) => {
  return (
    <Wrapper width={width} marginRight={marginRight}>
      {label && <Label>{label}</Label>}
      <Container>
        <input {...rest} type="checkbox" onChange={onChange} />
        {title && <span>{title}</span>}
      </Container>
    </Wrapper>
  )
}
