import styled from 'styled-components'
import { fadein } from '../../styles/animations'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 500px;
  width: 100%;
  margin-top: 15px;
  animation: ${fadein} 0.6s forwards;
`

export const Filter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 20px 0px 10px 0px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`

export const FilterCliente = styled.div`
  display: flex;
  width: 70%;
  /* >div{
    width: 30%;
  } */
  >div:first-of-type {
    margin-right: 15px;
  }

  >div + div {
    margin-right: 15px;
  }

  >div:last-of-type {
    margin-right: 0px;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 720px) {
    flex-direction: column;

    >div:first-of-type {
    margin-right: 0;
  }
  }
`

export const DivButtonSearch = styled.div`
  display: flex;
  align-items: flex-end;
  width: 10%;
  margin-bottom: 10px;

  @media screen and (max-width: 900px) {
    width: 32%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`

export const DivButtonNewCliente = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;

  @media screen and (max-width: 900px) {
    width: 32%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`
