import { PostParams } from '../../_interfaces'
import { ProcessarFreteService } from '../contracts'
import { GetPedido } from '../models'

export class ProcessarFretePedido {
  private readonly processarFreteService: ProcessarFreteService

  constructor (processarFreteService: ProcessarFreteService) {
    this.processarFreteService = processarFreteService
  }

  async execute (params: PostParams): Promise<GetPedido> {
    return await this.processarFreteService.processarFretePedido(params)
  }
}
