import { BloquearEstoqueZeradoService } from '../contracts'
import { bloquearEstoqueZeradoParams } from '../services'

export class BloquearEstoqueZerado {
  private readonly BloquearEstoqueZeradoService: BloquearEstoqueZeradoService

  constructor (BloquearEstoqueZeradoService: BloquearEstoqueZeradoService) {
    this.BloquearEstoqueZeradoService = BloquearEstoqueZeradoService
  }

  async execute (params: bloquearEstoqueZeradoParams): Promise<boolean> {
    if (!params?.id) {
      return false
    }

    let response = await this.BloquearEstoqueZeradoService.bloquearEstoqueZerado(params.id, params.token)

    if (response) {
      return true
    } else {
      return false
    }
  }
}
