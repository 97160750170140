import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { ListarFuncionariosServiceRest } from '../services'
import { ListarFuncionarios } from '../useCases'

export function makeListarFuncionarios (): ListarFuncionarios {
  const httpRequest = new HttpRequestImpl()
  const listarFuncionariosService = new ListarFuncionariosServiceRest(httpRequest)

  return new ListarFuncionarios(listarFuncionariosService)
}
