import { handleErrors } from '../../../helpers/handleErrors'
import { PostParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { CadastrarChamadoService } from '../contracts'
import { Chamado } from '../models'
import { ChamadoApi } from './_interfaces'

export class CadastrarChamadoServiceRest implements CadastrarChamadoService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async cadastrarChamado (params: PostParams): Promise<Chamado> {
    let { body, token } = params

    const response = await this.httpRequest.post<ChamadoApi>({
      path: 'chamados',
      body,
      token
    })

    handleErrors(response?.error)
    return response?.data as Chamado
  }
}
