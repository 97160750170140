import capitalize from 'capitalize-pt-br'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { Select, Textarea } from '../..'
import { Negociacao, PossibilidadeNegociacao, TipoNegociacao } from '../../../../modules/pedidos/models'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { Container, FieldData } from './styles'
import { FormRow } from '../../../styles/global'

registerLocale('ptBR', ptBR)

interface NovaNegociacaoProps {
  setNovaNegociacao: React.Dispatch<React.SetStateAction<Negociacao>>
  setDate: React.Dispatch<React.SetStateAction<string>>
}

const possibilidades = [
  {
    label: 'baixa',
    value: 'baixa'
  },
  {
    label: 'média',
    value: 'media'
  },
  {
    label: 'alta',
    value: 'alta'
  }
]

const tipoNegociacao = [
  {
    value: 'aguardando cliente revenda',
    label: 'aguardando cliente revenda'
  },
  {
    value: 'faltando estoque',
    label: 'faltando estoque'
  },
  {
    value: 'frete',
    label: 'frete'
  },
  {
    value: 'limite de credito',
    label: 'limite de credito'
  },
  {
    value: 'prazo',
    label: 'prazo'
  },
  {
    value: 'preco',
    label: 'preço'
  }
]

export const NovaNegociacao = ({ setNovaNegociacao, setDate }: NovaNegociacaoProps) => {
  const [tipo, setTipo] = useState('')
  const [possibilidade, setPossibilidade] = useState('')
  const [observacao, setObservacao] = useState('')
  const [data, setData] = useState(new Date())

  const handleChangeDate = (date: Date | null) => {
    if (date) {
      setData(date)
      const formatData = format(date, 'yyyyMMdd')
      setNovaNegociacao((oldState: Negociacao) => {
        return { ...oldState, data: formatData }
      })
    }
  }

  const handleTipoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newTipo = e.target.value
    if (newTipo) {
      setTipo(newTipo)
      setNovaNegociacao((oldState: Negociacao) => {
        return { ...oldState, tipo: newTipo as TipoNegociacao }
      })
    }
  }

  const handlePossibilidadeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newPossibilidade = e.target.value
    if (newPossibilidade) {
      setPossibilidade(newPossibilidade)
      setNovaNegociacao((oldState: Negociacao) => {
        return { ...oldState, possibilidades: newPossibilidade as PossibilidadeNegociacao }
      })
    }
  }

  const handleObsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newObs = e.target.value
    setObservacao(newObs)
    setNovaNegociacao((oldState: Negociacao) => {
      return { ...oldState, observacao: newObs }
    })
  }

  return <Container>
    <FormRow>
      <Select
        title='Possibilidades'
        name='possibilidade'
        onChange={handlePossibilidadeChange}
        value={possibilidade}
      >
        <option value='' disabled>Selecione uma Possibilidade</option>
        {possibilidades.map(tipo =>
          <option key={tipo.value} value={tipo.value}>{capitalize(tipo.label)}</option>
        )}
      </Select>
      <Select
        title='Tipo'
        name='tipo'
        onChange={handleTipoChange}
        value={tipo}
      >
        <option value=''>Selecione um Tipo</option>
        {tipoNegociacao.map(tipo =>
          <option key={tipo.value} value={tipo.value}>{capitalize(tipo.label)}</option>
        )}
      </Select>

    </FormRow>
    {/* <FieldData>
      <span>Data</span>
      <DatePicker
        id='dataInicialNegociacao'
        locale='ptBR'
        dateFormat="dd/MM/yyyy"
        closeOnScroll={true}
        selected={data}
        onChange={handleChangeDate}
        shouldCloseOnSelect={true}
      />
    </FieldData> */}
    <Textarea
      label='Observação'
      value={observacao}
      onChange={handleObsChange}
    />
  </Container>
}
