import { transparentize } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 700px) {
    & {
      flex-direction: column;
    }
  }
`

export const HeaderSolucoes = styled.div`
  display: flex;
  width: 49%;
  height: auto;
  justify-content: space-between;

  @media (max-width: 1100px) {
    & {
      flex-direction: column;
    }
  }

  @media (max-width: 700px) {
    & {
      width: 100%;
    }
  }
`

export const DivButton = styled.div`
  display: flex;
  padding-top: 37px;
  width: 49%;
  justify-content: flex-end;

  > button:first-of-type {
    margin-right: 10px;
  }

  @media (max-width: 1100px) {
    & {
      width: 100%;

      > button {
        width: 260px;
      }
    }
  }  

  @media (max-width: 700px) {
    & {
      justify-content: center;

      > button {
        margin-right: 0;
      }
    }
  }
`

export const TextSolucoes = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
  margin: 10px 0px;

  > span {
    display: flex;
    color: ${props => props.theme.colors.primaryText} !important;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  @media (max-width: 700px) {
    & {
      width: 100%;
    }
  }
`

export const DivText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 5px;
  background-color: ${props => transparentize(0.2, props.theme.colors.borderLight)};
  > p {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    inline-size: 100%;
    line-break: normal;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
`
