import { generateSearchQuery } from '../../../helpers'
import { handleErrors } from '../../../helpers/handleErrors'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { ListarClientesEquipeService } from '../contracts'
import { GetListClienteResponse } from '../models'
import { ListarClientesEquipeParams } from '../_interfaces'
import { ListarClientesRespApi } from './_interfaces'

export class ListarClientesEquipeServiceRest implements ListarClientesEquipeService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async listarClientesEquipe (params: ListarClientesEquipeParams): Promise<GetListClienteResponse> {
    let { equipe_id, filter, filterOptions, filterObject, token } = params
    const query = generateSearchQuery(filter, filterOptions, filterObject)

    const response = await this.httpRequest.get<ListarClientesRespApi>({
      path: `equipes/${equipe_id}/clientes`,
      token,
      query
    })

    handleErrors(response?.error)
    return response?.res as GetListClienteResponse
  }
}
