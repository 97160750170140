/* eslint-disable indent */
import React, { ChangeEvent, useCallback, useState } from 'react'
import { FaPhone, FaSlackHash, FaNetworkWired } from 'react-icons/fa'
import { Input, CheckBox } from '..'
import { Telefone } from '../../../modules/clientes/models'
import { FormRow } from '../../styles/global'
import { Container, BoxInput, BoxTelefone, BoxCheckboxCelular } from './styles'

interface CadastroTelefoneProps {
  formTelefone?: Telefone
  setFormTelefone: React.Dispatch<React.SetStateAction<Telefone>>
}

export const CadastroTelefone = ({ setFormTelefone, formTelefone }: CadastroTelefoneProps) => {
  const [isCelular, setIsCelular] = useState(false)

  const handleInputOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value

    if (e.target.type === 'checkbox') {
      value = e.target.checked ? 's' : 'n'
    }

    const key = e.target.name as keyof Telefone

    const newTelefoneState = {
      ...formTelefone,
      [key]: value
    }

    setFormTelefone(newTelefoneState as Telefone)
  }, [formTelefone, setFormTelefone])

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setIsCelular(true)
    } else {
      setIsCelular(false)
    }
  }

  return (
    <Container>
      <BoxInput>
        <Input
          width="1"
          name="ddd"
          title="DDD"
          startIcon={FaSlackHash}
          type="ddd"
          placeholder="DDD"
          value={formTelefone?.ddd}
          onChange={handleInputOnChange}
        />
        <BoxTelefone>
          {!isCelular ? (
            <Input
              width="3"
              name="telefone"
              title="Telefone"
              startIcon={FaPhone}
              type='telefone'
              placeholder="Telefone"
              value={formTelefone?.telefone}
              onChange={handleInputOnChange}
            />
          ) : (
            <Input
              width="3"
              name="telefone"
              title="Celular"
              startIcon={FaPhone}
              type='celular'
              placeholder="Celular"
              value={formTelefone?.telefone}
              onChange={handleInputOnChange}
            />
          )}
          <BoxCheckboxCelular>
            <CheckBox name="isCelular" title="Celular" checked={isCelular} onChange={handleCheckboxChange} />
          </BoxCheckboxCelular>
        </BoxTelefone>
      </BoxInput>

      <FormRow width={'50%'}>
        <Input
          startIcon={FaNetworkWired}
          name="ramal"
          title="Ramal"
          type="text"
          placeholder="Ramal"
          value={formTelefone?.ramal}
          onChange={handleInputOnChange}
        />
      </FormRow>

      <FormRow width={'50%'}>
        <CheckBox
          name="whatsapp"
          title="WhatsApp"
          checked={formTelefone?.whatsapp === 's'}
          onChange={handleInputOnChange}
        />
      </FormRow>
    </Container>
  )
}
