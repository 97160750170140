import { formatStringDate } from '.'

export const convertStringToDate = (dateString: string): Date => {
  const formatedDate = formatStringDate(dateString, 'xxxxxxxx', 'completo').split('/')
  const ano = parseInt(formatedDate[2])
  const mes = parseInt(formatedDate[1]) - 1
  const dia = parseInt(formatedDate[0])

  return new Date(ano, mes, dia)
}
