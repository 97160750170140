import React, { useState } from 'react'
import { FaExchangeAlt, FaTrash } from 'react-icons/fa'
import { BsThreeDots } from 'react-icons/bs'
import { ButtonTable } from '..'
import { Container, ListaAcoes } from './styles'
import { Modal } from '../Modal'
import { Item } from '../../../modules/pedidos/models'

interface AcoesProdutosProps {
  item: Item
  index: number
  handleDeleteProduto: (index: number) => void
  handleTrocaLote: (index: number) => void
}

export const AcoesProdutos = ({ item, index, handleDeleteProduto, handleTrocaLote }: AcoesProdutosProps) => {
  const [openList, setOpenList] = useState(false)

  const trocaLote = () => {
    setOpenList(false)
    handleTrocaLote(index)
  }

  const deleteProduto = () => {
    setOpenList(false)
    handleDeleteProduto(index)
  }

  return (
    <Container>
      <ButtonTable
        title='Ações'
        type="button"
        typeButton='primary'
        onClick={() => setOpenList(state => !state)}
      ><BsThreeDots /></ButtonTable>
      {openList && <Modal close={() => setOpenList(false)} title='Ações de Pedido' mode='mini'>
        <ListaAcoes>
          <ul>
            <li onClick={deleteProduto}><FaTrash size='15px' /><span>Deletar</span></li>
            {item.produto?.controla_lote === 's' && <li onClick={trocaLote}><FaExchangeAlt size='15px' /><span>Trocar Lote</span></li>}
          </ul>
        </ListaAcoes>
      </Modal>
      }
    </Container>
  )
}
