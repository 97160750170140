import { useState } from 'react'
import { makeTrazerEstoqueAlternativo } from '../../../modules/pedidos/factories'
import { EstoqueAlternativo } from '../../../modules/pedidos/models'
import { useUsuario } from '../../hooks'
import { useHandleErrors } from '../app/useHandleErrors'

const getEstoquesAlternativos = makeTrazerEstoqueAlternativo()

interface useEstoquesAlternativosProps {
  loading: boolean
  data: EstoqueAlternativo[]
  fetcher: (pedidoId: number) => void
}

export function useEstoquesAlternativos (): useEstoquesAlternativosProps {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<EstoqueAlternativo[]>([])
  const { data: dataUsuario } = useUsuario()
  const { handleError } = useHandleErrors()

  async function fetcher (pedidoId: number) {
    if (pedidoId && pedidoId > 0 && dataUsuario) {
      const resp = await getEstoquesAlternativos.execute({ pedidoId, token: dataUsuario.token as string })
      if (resp && resp.data !== undefined) {
        const listaFiltrada = resp.data.filter(element => { return element.porcentagem > 0 })
        setData(listaFiltrada)
        setLoading(false)
        return resp
      } else {
        handleError(resp.error as Error)
        setLoading(false)
      }
    }
  }

  return { fetcher, data, loading }
}
