import { PostParams } from '../../_interfaces'
import { CadastrarNegociacaoService } from '../contracts'
import { Negociacao } from '../models'

export class CadastrarNegociacao {
  private readonly cadastrarNegociacaoService: CadastrarNegociacaoService

  constructor (cadastrarNegociacaoService: CadastrarNegociacaoService) {
    this.cadastrarNegociacaoService = cadastrarNegociacaoService
  }

  async execute (params: PostParams): Promise<Negociacao> {
    const response = await this.cadastrarNegociacaoService.cadastrarNegociacao(params)
    return response
  }
}
