import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { ListarContatosClienteServiceRest } from '../services/listar-contatos-cliente'
import { ListarContatos } from '../useCases'

export function makeListarContatosCliente (): ListarContatos {
  const httpRequest = new HttpRequestImpl()
  const listarContatosClienteService = new ListarContatosClienteServiceRest(httpRequest)

  return new ListarContatos(listarContatosClienteService)
}
