import { useState } from 'react'
import { useUsuario } from '../usuario'
import { CobrancasCliente } from '../../../modules/clientes/models/cliente'
import { formatStringDate } from '../../../helpers/formatStringDate'
import { useHandleErrors } from '../app/useHandleErrors'
import { makeTrazerClientePorId } from '../../../modules/clientes/factories'
import { GetOneClienteResponse } from '../../../modules/clientes/models'

interface FetcherProps {
  clienteId?: number
}

export function useHistoricoCobranca () {
  const { data: usuarioData } = useUsuario()
  const [loading, setLoading] = useState(false)
  const { handleError, executing } = useHandleErrors()
  const [cobrancas, setCobrancas] = useState<CobrancasCliente[]>()

  async function fetcher ({ clienteId }: FetcherProps) {
    setLoading(true)
    if (clienteId) {
      const trazerClientePorId = makeTrazerClientePorId()

      const response: GetOneClienteResponse = await trazerClientePorId.execute({
        id: clienteId,
        token: usuarioData?.token as string
      })

      if (response.error && !executing) {
        handleError(response.error)
      }

      if (response.data.cobrancas) {
        const data = response.data?.cobrancas.map((cobranca: CobrancasCliente) => ({
          ...cobranca,
          baixa: formatStringDate(cobranca.baixa, 'xxxxxxxx', 'completo'),
          vencimento: formatStringDate(cobranca.vencimento, 'xxxxxxxx', 'completo')
        }))

        setCobrancas(data)
      } else {
        setCobrancas(undefined)
      }
    }
    setLoading(false)
  }

  return { fetcher, cobrancas, loading }
}
