/* eslint-disable no-unused-vars */
import { action } from 'typesafe-actions'
// import { Contato } from '../../../modules/clientes/models'

import {
  IniciarAtendimentoParams,
  FecharAtendimentoParams,
  SetAtendimentoEmFoco,
  SetarBotaoAtivoCliente,
  IniciarPedidoParams,
  SetAbaAtivaPedidoParams,
  SetCampoValorPedidoEmAndamentoParams,
  SetCampoValorPedidoEmAbertoParams,
  SetErrorCampoValorPedidoEmAndamentoParams,
  SetErrosPedidoEmAndamentoParams,
  SetPedidoEmFocoParams,
  RemoverItemPedidoEmAndamentoProps,
  AddItemPedidoEmAndamentoParams,
  SetPedidoEmAbertoParams,
  FecharPedidoParams,
  AbrirPedidoParams,
  SetAbaAtivaPedidoAbertoParams,
  AddItemPedidoEmAbertoParams,
  RemoverItemPedidoEmAbertoParams,
  SetErrorCampoValorPedidoEmAbertoParams,
  SetErrosPedidoEmAbertoParams,
  AlterarContatoAtendimentoParams,
  RestaurarItemPedido,
  RecalcularPedidoParams,
  SetFiltroUltimasSaidasParams,
  AtualizarClienteParams
} from './interfaces'

export enum AtendimentosActions {
  SET_INITIAL_STATE = '@atendimentos/SETAR_INITIAL_STATE',
  INICIAR_ATENDIMENTO = '@atendimentos/INICIAR',
  SET_ATENDIMENTO_EM_FOCO = '@atendimentos/SET_ATENDIMENTO_EM_FOCO',
  ATUALIZAR_CLIENTE = '@atendimentos/SET_ATUALIZAR_CLIENTE',
  FECHAR_ATENDIMENTO = '@atendimentos/FECHAR_ATENDIMENTO',
  SETAR_BOTAO_ATIVO_CLIENTE = '@atendimentos/SETAR_BOTAO_ATIVO_CLIENTE',
  INICIAR_PEDIDO = '@atendimentos/INICIAR_PEDIDO',
  SET_ABA_ATIVA_PEDIDO = '@atendimentos/SETAR_ABA_ATIVA_PEDIDO',
  SET_CAMPO_VALOR_PEDIDO_EM_ANDAMENTO = '@atendimentos/SET_CAMPO_VALOR_PEDIDO_EM_ANDAMENTO',
  SET_CAMPO_VALOR_PEDIDO_EM_ABERTO = '@atendimentos/SET_CAMPO_VALOR_PEDIDO_EM_ABERTO',
  SET_PEDIDO_EM_ABERTO = '@atendimentos/SET_PEDIDO_EM_ABERTO',
  SET_ERRO_CAMPO_VALOR_PEDIDO_EM_ANDAMENTO = '@atendimentos/SET_ERRO_CAMPO_VALOR_PEDIDO_EM_ANDAMENTO',
  SET_ERROS_PEDIDO_EM_ANDAMENTO = '@atendimentos/SET_ERROS_PEDIDO_EM_ANDAMENTO',
  SET_PEDIDO_EM_FOCO = '@atendimentos/SET_PEDIDO_EM_FOCO',
  REMOVER_ITEM_PEDIDO_EM_ANDAMENTO = '@atendimentos/REMOVER_ITEM_PEDIDO_EM_ANDAMENTO',
  ADD_ITEM_PEDIDO_EM_ANDAMENTO = '@atendimentos/ADD_ITEM_PEDIDO_EM_ANDAMENTO',
  FECHAR_PEDIDO = '@atendimentos/FECHAR_PEDIDO',
  ABRIR_PEDIDO = '@atendimentos/ABRIR_PEDIDO',
  SET_ABA_ATIVA_PEDIDO_ABERTO = '@atendimentos/SET_ABA_ATIVA_PEDIDO_ABERTO',
  ADD_ITEM_PEDIDO_EM_ABERTO = '@atendimentos/ADD_ITEM_PEDIDO_EM_ABERTO',
  REMOVER_ITEM_PEDIDO_EM_ABERTO = '@atendimentos/REMOVER_ITEM_PEDIDO_EM_ABERTO',
  SET_ERRO_CAMPO_VALOR_PEDIDO_EM_ABERTO = '@atendimentos/SET_ERRO_CAMPO_VALOR_PEDIDO_EM_ABERTO',
  SET_ERROS_PEDIDO_EM_ABERTO = '@atendimentos/SET_ERROS_PEDIDO_EM_ABERTO',
  ALTERAR_CONTATO_ATENDIMENTO = '@atendimentos/ALTERAR_CONTATO_ATENDIMENTO',
  RESTATURAR_ITEM_PEDIDO_EM_ANDAMENTO = '@atendimentos/RESTATURAR_ITEM_PEDIDO_EM_ANDAMENTO',
  SET_PEDIDO_RECALCULADO = '@atendimentos/SET_PEDIDO_RECALCULADO',
  SET_FILTRO_ULTIMAS_SAIDAS = '@atendimentos/SET_FILTRO_ULTIMAS_SAIDAS',
  SET_PEDIDO_ATUALIZADO = '@atendimentos/SET_PEDIDO_ATUALIZADO'
}

export const setarInitialState = () => action(AtendimentosActions.SET_INITIAL_STATE)
export const iniciarAtendimento = (data: IniciarAtendimentoParams) => action(AtendimentosActions.INICIAR_ATENDIMENTO, data)
export const fecharAtendimento = (data: FecharAtendimentoParams) => action(AtendimentosActions.FECHAR_ATENDIMENTO, data)
export const setarAtualizarCliente = (data: AtualizarClienteParams) => action(AtendimentosActions.ATUALIZAR_CLIENTE, data)

export const setAtendimentoEmFoco =
  (data: SetAtendimentoEmFoco) => action(AtendimentosActions.SET_ATENDIMENTO_EM_FOCO, data)

export const setarBotaoAtivoCliente =
  (data: SetarBotaoAtivoCliente) => action(AtendimentosActions.SETAR_BOTAO_ATIVO_CLIENTE, data)

export const iniciarPedido = (data: IniciarPedidoParams) => action(AtendimentosActions.INICIAR_PEDIDO, data)
export const setAbaAtivaPedido = (data: SetAbaAtivaPedidoParams) => action(AtendimentosActions.SET_ABA_ATIVA_PEDIDO, data)

export const setCampoValorPedidoEmAndamento =
  (data: SetCampoValorPedidoEmAndamentoParams) => action(AtendimentosActions.SET_CAMPO_VALOR_PEDIDO_EM_ANDAMENTO, data)

export const setCampoValorPedidoEmAberto =
  (data: SetCampoValorPedidoEmAbertoParams) => action(AtendimentosActions.SET_CAMPO_VALOR_PEDIDO_EM_ABERTO, data)

export const setErroCampoValorPedidoEmAndamento =
  (data: SetErrorCampoValorPedidoEmAndamentoParams) => action(AtendimentosActions.SET_ERRO_CAMPO_VALOR_PEDIDO_EM_ANDAMENTO, data)

export const setErrosPedidoEmAndamento =
  (data: SetErrosPedidoEmAndamentoParams) => action(AtendimentosActions.SET_ERROS_PEDIDO_EM_ANDAMENTO, data)

export const setPedidoEmFoco = (data: SetPedidoEmFocoParams) => action(AtendimentosActions.SET_PEDIDO_EM_FOCO, data)

export const removerItemPedidoEmAndamento =
  (data: RemoverItemPedidoEmAndamentoProps) => action(AtendimentosActions.REMOVER_ITEM_PEDIDO_EM_ANDAMENTO, data)

export const addItemPedidoEmAndamento =
  (data: AddItemPedidoEmAndamentoParams) => action(AtendimentosActions.ADD_ITEM_PEDIDO_EM_ANDAMENTO, data)

export const setPedidoEmAberto = (data: SetPedidoEmAbertoParams) => action(AtendimentosActions.SET_PEDIDO_EM_ABERTO, data)
export const fecharPedido = (data: FecharPedidoParams) => action(AtendimentosActions.FECHAR_PEDIDO, data)
export const abrirPedido = (data: AbrirPedidoParams) => action(AtendimentosActions.ABRIR_PEDIDO, data)
export const setAbaAtivaPedidoAberto = (data: SetAbaAtivaPedidoAbertoParams) => action(AtendimentosActions.SET_ABA_ATIVA_PEDIDO_ABERTO, data)
export const addItemPedidoEmAberto = (data: AddItemPedidoEmAbertoParams) => action(AtendimentosActions.ADD_ITEM_PEDIDO_EM_ABERTO, data)
export const removerItemPedidoEmAberto = (data: RemoverItemPedidoEmAbertoParams) => action(AtendimentosActions.REMOVER_ITEM_PEDIDO_EM_ABERTO, data)
export const setErroCampoValorPedidoEmAberto = (data: SetErrorCampoValorPedidoEmAbertoParams) => action(AtendimentosActions.SET_ERRO_CAMPO_VALOR_PEDIDO_EM_ABERTO, data)
export const setErrosPedidoEmAberto = (data: SetErrosPedidoEmAbertoParams) => action(AtendimentosActions.SET_ERROS_PEDIDO_EM_ABERTO, data)
export const alterarContatoAtendimento = (data: AlterarContatoAtendimentoParams) => action(AtendimentosActions.ALTERAR_CONTATO_ATENDIMENTO, data)
export const restaturarItemPedidoEmAndamento = (data: RestaurarItemPedido) => action(AtendimentosActions.RESTATURAR_ITEM_PEDIDO_EM_ANDAMENTO, data)
export const setPedidoRecalculado = (data: RecalcularPedidoParams) => action(AtendimentosActions.SET_PEDIDO_RECALCULADO, data)
export const setPedidoAtualizado = (data: RecalcularPedidoParams) => action(AtendimentosActions.SET_PEDIDO_ATUALIZADO, data)
export const setFiltroUltimasSaidas = (data: SetFiltroUltimasSaidasParams) => action(AtendimentosActions.SET_FILTRO_ULTIMAS_SAIDAS, data)
