/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaList, FaShoppingCart, FaCashRegister, FaUserTie, FaArchive, FaSignInAlt, FaMoneyCheckAlt, FaHistory } from 'react-icons/fa'
import { AppState } from '../../store'
import { AtendimentosState, BotaoAtendimentoAtivo } from '../../store/atendimentos/interfaces'
import { setarBotaoAtivoCliente } from '../../store/atendimentos/actions'
import { Modal } from '../Modal'
import { HistoricoCobranca } from '../HistoricoCobranca'
import { Cliente } from '../../../modules/clientes/models'
import { Container, Button } from './styles'
import { useHistoricoCobranca } from '../../hooks/historicoCobranca/useHistoricoCobranca'
import Swal from 'sweetalert2'

interface MenuAtendimentoProps {
  cliente: Cliente
}

export const MenuAtendimento = ({ cliente }: MenuAtendimentoProps) => {
  const dispatch = useDispatch()
  const atendimentosState = useSelector<AppState>(state => state.atendimentos) as AtendimentosState
  const { fetcher, cobrancas } = useHistoricoCobranca()

  const [showModalHistoricoCobranca, setShowModalHistoricoCobranca] = useState(false)

  const handleButtonOnClick = (botao: BotaoAtendimentoAtivo) => {
    dispatch(setarBotaoAtivoCliente({
      clienteId: cliente.id as number,
      botao
    }))
  }

  useEffect(() => {
    if (cliente.id) {
      fetcher({ clienteId: Number(cliente.id) })
    }
  }, [cliente.id])

  const handleOpenModalHistoricoCobranca = () => {
    if (cobrancas && cobrancas.length === 0) {
      Swal.fire({
        icon: 'info',
        title: 'Opss!',
        text: 'Esse cliente não possui histórico de cobrança',
        confirmButtonText: 'OK'
      })

      return
    }
    setShowModalHistoricoCobranca(true)
  }

  const handleCloseModalHistoricoCobranca = () => {
    setShowModalHistoricoCobranca(false)
  }

  return (
    <>
      <Container >
        <Button
          data-cy='buttonClienteGeral'
          disabled={!atendimentosState.data[Number(cliente.id)].contato.id}
          selected={atendimentosState.data[cliente.id as number].botaoAtendimentoAtivo === 'geral'}
          type="button"
          onClick={() => handleButtonOnClick('geral')}>
          <FaList /><span>Geral</span>
        </Button>
        <Button
          data-cy='buttonClientePedidos'
          disabled={!atendimentosState.data[Number(cliente.id)].contato.id}
          selected={atendimentosState.data[cliente.id as number].botaoAtendimentoAtivo === 'pedidos'}
          type="button"
          onClick={() => handleButtonOnClick('pedidos')}>
          <FaShoppingCart /><span>Pedidos</span>
        </Button>
        <Button
          data-cy='buttonCliente'
          disabled={!atendimentosState.data[Number(cliente.id)].contato.id}
          selected={atendimentosState.data[cliente.id as number].botaoAtendimentoAtivo === 'cliente'}
          type="button"
          onClick={() => handleButtonOnClick('cliente')}>
          <FaUserTie /><span>Cliente</span>
        </Button>
        <Button
          data-cy='buttonClienteCr'
          disabled={!atendimentosState.data[Number(cliente.id)].contato.id}
          selected={atendimentosState.data[cliente.id as number].botaoAtendimentoAtivo === 'contas_receber'}
          type="button"
          onClick={() => handleButtonOnClick('contas_receber')}>
          <FaCashRegister /><span>Contas a Receber</span>
        </Button>
        <Button
          data-cy='buttonClienteOcorrencias'
          disabled={!atendimentosState.data[Number(cliente.id)].contato.id}
          selected={atendimentosState.data[cliente.id as number].botaoAtendimentoAtivo === 'ocorrencias'}
          type="button"
          onClick={() => handleButtonOnClick('ocorrencias')}>
          <FaArchive /><span>Ocorrências</span>
        </Button>
        <Button
          data-cy='buttonClienteUltimasSaidas'
          disabled={!atendimentosState.data[Number(cliente.id)].contato.id}
          selected={atendimentosState.data[cliente.id as number].botaoAtendimentoAtivo === 'ultimas'}
          type="button"
          onClick={() => handleButtonOnClick('ultimas')}>
          <FaSignInAlt /><span>Ultimas Saidas</span>
        </Button>
        <Button
          data-cy='buttonClienteUltimasCompras'
          disabled={!atendimentosState.data[Number(cliente.id)].contato.id}
          selected={atendimentosState.data[cliente.id as number].botaoAtendimentoAtivo === 'ultimas_compras'}
          type="button"
          onClick={() => handleButtonOnClick('ultimas_compras')}>
          <FaMoneyCheckAlt /><span>Mais Comprados</span>
        </Button>
        <Button
          data-cy='buttonClienteHistoricoCobranca'
          disabled={!atendimentosState.data[Number(cliente.id)].contato.id}
          type="button"
          onClick={handleOpenModalHistoricoCobranca}>
          <FaHistory /><span>Histórico de Cobranças</span>
        </Button>
      </Container>
      {showModalHistoricoCobranca && (
        <Modal title='Histórico de Cobranças' buttonCancelText="Fechar" close={handleCloseModalHistoricoCobranca}>
          <HistoricoCobranca clienteId={Number(cliente.id)} />
        </Modal>
      )}
    </>
  )
}
