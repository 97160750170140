import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const HeaderNegociacoes = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 10px 0px;
`

export const CardNegocicao = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border: 1px solid ${props => props.theme.colors.border};
  margin-bottom: 10px;
`

export const HeaderCard = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  justify-content: space-between;
`
export const IdHeaderDiv = styled.div`
  display: flex;
  width: 45%;
  justify-content: space-between;
  >div {
    display: flex;
    width: 40%;
  }
  >div:first-of-type{
    width: 17%;
  }
`

export const DataHeaderDiv = styled.div`
  display: flex;
  width:20%;
  justify-content: flex-end;
  >div{
    display: flex;
    width: 50%;
  }
`

export const DadoHeaderDiv = styled.div`
  display: flex;
  width: 100%;
  >span:first-of-type{
    font-weight: bold;
    margin-right: 5px;
  }
`

export const ObservacaoCard = styled.div`
  display: flex;
  width: 100%;
`

export const BodyItem = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;
  >span:first-of-type{
    font-weight: bold;
  }
  >p:first-of-type{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    inline-size: 100%;
    line-break: normal;
    line-height: 16px;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
`

export const NoData = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  >span{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
  }
`
