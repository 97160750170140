import { useCallback, useEffect, useState } from 'react'
import { useUsuario } from '..'
import { TrazerCrsClienteParams } from '../../../modules/clientes/contracts'
import { makeTrazerCrsCliente } from '../../../modules/clientes/factories'
import { GetCrsClienteResponse } from '../../../modules/clientes/models'
import { FiltersContasProps } from '../../../modules/_interfaces/filtersProps'
import { SortTableProps } from '../../../modules/_interfaces/sortTable'
import { useHandleErrors } from '../app/useHandleErrors'

export function useCrsCliente (cliente_id: number, perPage = 20, currentPage: number, sortTable?: SortTableProps, filters?: FiltersContasProps) {
  const { data: usuarioData } = useUsuario()
  const [loading, setLoading] = useState(false)
  const [isReloading, setIsReloading] = useState(false)
  const [data, setData] = useState<GetCrsClienteResponse>()
  const { handleError, executing } = useHandleErrors()

  useEffect(() => {
    setLoading(true)
    async function fetcher () {
      const trazerCrsCliente = makeTrazerCrsCliente()
      let params = {
        cliente_id,
        token: usuarioData?.token as string,
        filterOptions: {
          limit: perPage,
          skip: (currentPage - 1) * perPage
        }
      } as TrazerCrsClienteParams

      if (sortTable && sortTable.id) {
        if (sortTable.desc) {
          params.filterOptions = { ...params.filterOptions, order: '-crs.' + sortTable.id + ' crs.id' }
        } else {
          params.filterOptions = { ...params.filterOptions, order: 'crs.' + sortTable.id + ' crs.id' }
        }
      }

      if (filters) {
        if (filters.status) {
          params.filterObject = { ...params.filterObject, 'crs.status': filters.status }
        }
      }

      const response = await trazerCrsCliente.execute(params)
      setData(response)
      setLoading(false)
      if (response.error && !executing) {
        handleError(response.error)
      }
    }

    fetcher()
  }, [cliente_id, currentPage, executing, filters, handleError, perPage, sortTable, usuarioData])

  const reload = useCallback(() => {
    setIsReloading(true)
  }, [])

  return { data, reload, loading, isReloading }
}
