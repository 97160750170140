import { GetParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { TrazerFuncionarioPorIdService } from '../contracts/trazer-funcionario-por-id-service'
import { GetFuncionario } from '../models/getFuncionario'
import { TrazerFuncionarioApiResp } from './_interfaces'

export class TrazerFuncionarioPorIdServiceRest implements TrazerFuncionarioPorIdService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async trazerFuncionarioPorId (params: GetParams): Promise<GetFuncionario> {
    let { filter: id, token } = params

    const response = await this.httpRequest.get<TrazerFuncionarioApiResp>({
      path: `funcionarios/${id}`,
      token
    })

    const retorno = {
      data: response.res?.data,
      metadata: response.res?.metadata,
      error: response.error
    } as GetFuncionario

    return retorno
  }
}
