import { useEffect, useState } from 'react'
import { makeTrazerDadosClienteReceita } from '../../../modules/clientes/factories'
import { Endereco } from '../../../modules/clientes/models'

const trazerDadosClienteReceita = makeTrazerDadosClienteReceita()

export function useDadosCNPJ (cnpj: string) {
  const [data, setData] = useState<Endereco>()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (cnpj.length !== 14) {
      return
    }

    setLoading(true)

    async function fetcher () {
      try {
        const res = await trazerDadosClienteReceita.execute(process.env.REACT_APP_API_CNPJ_TOKEN as string, cnpj)
        setData(res.data as unknown as Endereco)
        setLoading(false)
      } catch (error) {
        setError(error)
        setLoading(false)
      }
    }

    fetcher()
  }, [cnpj])

  return { data, error, loading }
}
