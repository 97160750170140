import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { TrazerClientePorIdServiceRest } from '../services'
import { TrazerClientePorId } from '../useCases'

export function makeTrazerClientePorId (): TrazerClientePorId {
  const httpRequest = new HttpRequestImpl()
  const trazerClientePorIdService = new TrazerClientePorIdServiceRest(httpRequest)

  return new TrazerClientePorId(trazerClientePorIdService)
}
