import * as yup from 'yup'

export const updateSchema = yup.object().shape({
  funcionario_id: yup.number().required('id do funcionário é um campo obrigatório'),
  funcionario2_id: yup.number(),
  transportadora_id: yup.number().required('id da transportadora é um campo obrigatório'),
  condicao_pagamento: yup.string().required('condição de pagamento não pode estar vazia'),
  itens: yup.array().min(1, 'Deve ter ao menos um produto')
    .of(
      yup.object().shape({
        produto_id: yup.number().required('id do produto é um campo obrigatório'),
        quantidade: yup.number().required('quantidade do produto é um campo obrigatório'),
        valor: yup.number().required('valor do produto é um campo obrigatório')
      })
    )
})
