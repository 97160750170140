import React, { createContext, useCallback, useState } from 'react'
import jwt_decode from 'jwt-decode'
import { Usuario } from '../../modules/usuarios/models/usuario'
import { makeAutenticarUsuario } from '../../modules/usuarios/factories'
import { makeTrazerFuncionarioPorId } from '../../modules/funcionarios/factories'

interface LoginParams {
  email: string,
  password: string,
  manterConectado: boolean
}

export interface UsuarioContextProps {
  data?: Usuario
  setData (token: string): void
  login (params: LoginParams): Promise<Usuario>
  logout (): void
}

export const UsuarioContext = createContext<UsuarioContextProps>({} as UsuarioContextProps)

export const UsuarioProvider: React.FC = ({ children }) => {
  const [usuario, setUsuario] = useState<Usuario>(() => {
    const token = localStorage.getItem(`@${process.env.REACT_APP_NAME}:token`)

    if (token) {
      const dataUsuario = jwt_decode(token) as Usuario
      dataUsuario.token = token
      return dataUsuario
    }

    return {} as Usuario
  })

  const setData = useCallback((token: string) => {
    try {
      const dataUsuario = jwt_decode(token) as Usuario
      setUsuario(dataUsuario)
    } catch (error) {
      setUsuario({} as Usuario)
    }
  }, [])

  const login = async (params: LoginParams): Promise<Usuario> => {
    const autenticar = makeAutenticarUsuario()
    const trazerFuncionarioPorId = makeTrazerFuncionarioPorId()
    const loginData = { email: params.email, password: params.password }
    const token = await autenticar.execute(loginData)
    if (!token) {
      return {} as Usuario
    }

    const tokenData: Usuario = jwt_decode(token)

    let usuario: Usuario = {
      ...jwt_decode(token),
      token
    }

    const responseFunc = await trazerFuncionarioPorId.execute({
      filter: tokenData.funcionario_id as string,
      token
    })

    if (responseFunc) {
      if (responseFunc.data && responseFunc.data.empresa_id) {
        usuario = {
          ...usuario,
          empresa_id: responseFunc.data.empresa_id.toString()
        }
      }
    }

    setUsuario(usuario)

    if (params.manterConectado) {
      localStorage.setItem(`@${process.env.REACT_APP_NAME}:token`, token)
    }
    return usuario
  }

  const logout = () => {
    localStorage.removeItem(`@${process.env.REACT_APP_NAME}:token`)
    setUsuario({} as Usuario)
  }

  return (
    <UsuarioContext.Provider value={{ data: usuario, login, setData, logout }} >
      {children}
    </UsuarioContext.Provider>
  )
}
