import { PostParams, Validator } from '../../_interfaces'
import { CadastrarOcorrenciaService } from '../contracts'
import { Ocorrencia } from '../models'

export class CadastrarOcorrencia {
  private readonly cadastrarOcorrenciaService: CadastrarOcorrenciaService
  private readonly validator: Validator

  constructor (cadastrarOcorrenciaService: CadastrarOcorrenciaService, validator: Validator) {
    this.cadastrarOcorrenciaService = cadastrarOcorrenciaService
    this.validator = validator
  }

  async execute (params: PostParams): Promise<Ocorrencia> {
    await this.validator.validate(params.body)
    const response = await this.cadastrarOcorrenciaService.cadastrarOcorrencia(params)
    return response
  }
}
