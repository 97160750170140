import styled from "styled-components";
import { fadein } from "../../styles/animations";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 50px 10px;
  width: 100%;
  animation: ${fadein} 0.6s forwards;
`;

export const HeaderDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 15px 0px;

  > button {
    margin: 36px 0px 10px 0px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const SearchDiv = styled.div`
  display: flex;
  flex: 1;
  > div:first-of-type {
    flex: 0 0 30% !important;
    margin-right: 15px;
  }
  > button {
    width: 200px;
    margin: 37px 0px 10px 0px;
  }

  @media (max-width: 800px) {
    & {
      > div:first-of-type {
        flex: 0 0 50% !important;
      }
      > button {
        width: 50%;
      }
    }
  }

  @media (max-width: 450px) {
    & {
      flex-direction: column;
      > button {
        width: 100%;
      }
    }
  }
`;

export const ContainerModal = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1130px) {
    & {
      flex-direction: column;
    }
  }
`;

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
  > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  @media (max-width: 1130px) {
    & {
      width: 100%;
    }
  }
`;

export const ContainerAtendimentos = styled.div`
  display: flex;
  flex-direction: column;
  height: 220px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 5px;
  padding: 7px 7px 7px 7px !important;

  > div {
    margin-bottom: 5px;
  }

  > div:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  > div:last-of-type {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 0px;
  }
`;

export const WrapperAtendimento = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > span:first-of-type {
    display: flex;
    color: #505050 !important;
    opacity: 1;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
`;

export const CardAtendimento = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.border};
  padding: 5px;
`;

export const CardHeader = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  > div {
    width: 10%;
    margin-right: 1%;
    > span:first-of-type {
      font-weight: bold;
    }
  }
  > div:nth-of-type(2) {
    width: 30%;
  }
`;
export const CardObservacao = styled.div`
  display: flex;
  width: 100%;
  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    > span:first-of-type {
      font-weight: bold;
    }
  }
`;
