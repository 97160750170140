import { HttpRequestImpl } from '../../../infra/http'
import { SchemaValidator } from '../../../infra/validator/schemaValidator'
import { RegistrarUsuarioServiceRest } from '../services'
import { RegistrarUsuario } from '../useCases/registrar-usuario'
import { registrarUsuarioSchema } from '../validators/registrarUsuarioValidator'

export function makeRegistrarUsuario (): RegistrarUsuario {
  const httpRequest = new HttpRequestImpl()
  const schemaValidator = new SchemaValidator(registrarUsuarioSchema)
  const registrarUsuarioService = new RegistrarUsuarioServiceRest(httpRequest, schemaValidator)
  return new RegistrarUsuario(registrarUsuarioService)
}
