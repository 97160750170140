import { handleErrors } from '../../../helpers/handleErrors'
import { PostParams, Validator } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { RegistrarUsuarioService } from '../contracts'
import { RegistroResp } from '../models/usuario'

export class RegistrarUsuarioServiceRest implements RegistrarUsuarioService {
  private readonly validator: Validator
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest, validator: Validator) {
    this.validator = validator
    this.httpRequest = httpRequest
  }

  async registrarUsuario (params: PostParams): Promise<RegistroResp> {
    await this.validator?.validate(params.body)

    const response = await this.httpRequest.post<RegistroResp>({
      body: params.body,
      path: 'usuarios'
    })

    if (response.error) {
      handleErrors(response.error)
    }
    const resposta = { status: response.status } as RegistroResp
    return resposta
  }
}
