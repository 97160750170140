import { formatStringDate } from '../../../helpers'
import { GetParams, UseCase } from '../../_interfaces'
import { TrazerChamadoPorIdService } from '../contracts'
import { Chamado } from '../models'

export class TrazerChamadoPorId implements UseCase<GetParams, Chamado> {
  private readonly trazerChamadoPorIdService: TrazerChamadoPorIdService

  constructor (trazerChamadoPorIdService: TrazerChamadoPorIdService) {
    this.trazerChamadoPorIdService = trazerChamadoPorIdService
  }

  async execute (params: GetParams): Promise<Chamado> {
    if (!params.filter) {
      return {} as Chamado
    }
    let response = await this.trazerChamadoPorIdService.trazerChamadoPorId(params)

    if (response && response.atendimentos) {
      if (response.atendimentos.length > 0) {
        const atendimentos = response.atendimentos.map(atendimento => {
          return {
            ...atendimento,
            data: formatStringDate(atendimento.data, 'xxxxxxxx', 'completo')
          }
        })
        response.atendimentos = atendimentos
      }
    }

    return response
  }
}
