import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { SchemaValidator } from '../../../infra/validator/schemaValidator'
import { AtualizarChamadoServiceRest } from '../services'
import { AtualizarChamado } from '../useCases'
import { schema } from '../validators'

export function makeAtualizarChamado (): AtualizarChamado {
  const httpRequest = new HttpRequestImpl()
  const validator = new SchemaValidator(schema)
  const atualizarChamadoService = new AtualizarChamadoServiceRest(httpRequest)

  return new AtualizarChamado(atualizarChamadoService, validator)
}
