/* eslint-disable no-useless-return */
/* eslint-disable no-case-declarations */
import { Reducer } from 'redux'
import { Usuario } from '../../../modules/usuarios/models/usuario'
import { UsuariosActions } from './actions'

import { UsuariosState } from './interfaces'

const INITIAL_STATE: UsuariosState = {
  user_id: '',
  id: 0
}

const reducer: Reducer<UsuariosState | undefined> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UsuariosActions.SET_USUARIO_ID:
      const user_id = action.payload as string

      state = {
        ...state,
        user_id
      }
      return state
    case UsuariosActions.SET_USUARIO:
      const user = action.payload as Usuario
      state = {
        ...state,
        ...user
      }
      return state

    default:
      return state
  }
}

export default reducer
