import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 4px;
  margin-top: 15px;
`

export const Label = styled.div`
  display: flex;
  padding: 15px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.4px;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  margin-bottom: 10px;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 15px;
  >div:first-of-type{
    width: 100%;
  }
`
