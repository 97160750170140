import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { TrazerListaPrecoServiceRest } from '../services'
import { TrazerListaPreco } from '../useCases'

export function makeTrazerListaPreco (): TrazerListaPreco {
  const httpRequest = new HttpRequestImpl()
  const trazerPrecoService = new TrazerListaPrecoServiceRest(httpRequest)

  return new TrazerListaPreco(trazerPrecoService)
}
