type tipoData = 'dia' | 'mes' | 'ano' | 'completo'

type formatos = 'xxxxxxxx' | 'xx-xx-xxxx' | 'xxxx-xx-xx'

export const formatStringDate = (txt: string, formato: formatos, tipo: tipoData) => {
  let ano = ''
  let mes = ''
  let dia = ''

  switch (formato) {
    case 'xxxxxxxx':
      for (let index = 0; index < 8; index++) {
        if (!txt[index]) {
          return ''
        }

        if (index <= 3) {
          ano = ano + txt[index]
        } else if (index > 3 && index <= 5) {
          mes = mes + txt[index]
        } else if (index > 5) {
          dia = dia + txt[index]
        }
      }
      break
    case 'xxxx-xx-xx':
      for (let index = 0; index <= 9; index++) {
        if (!txt[index]) {
          return ''
        }

        if (index <= 3) {
          ano = ano + txt[index]
        } else if (index > 4 && index <= 6) {
          mes = mes + txt[index]
        } else if (index > 7) {
          dia = dia + txt[index]
        }
      }
      break
    case 'xx-xx-xxxx':
      for (let index = 0; index <= 9; index++) {
        if (!txt[index]) {
          return ''
        }

        if (index < 2) {
          dia = dia + txt[index]
        } else if (index > 2 && index < 5) {
          mes = mes + txt[index]
        } else if (index > 5) {
          ano = ano + txt[index]
        }
      }
      break
  }
  let retorno = ''
  switch (tipo) {
    case 'dia':
      retorno = dia
      break
    case 'mes':
      retorno = mes
      break
    case 'ano':
      retorno = ano
      break
    case 'completo':
      retorno = dia + '/' + mes + '/' + ano
  }

  return retorno
}
