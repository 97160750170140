import { darken } from 'polished'
import styled, { css } from 'styled-components'
import { ModeButton } from '.'

interface ContainerProps {
  mode: ModeButton
  active?: boolean,
  showSpinner?: boolean
  iconOnly?: boolean
  startIcon: boolean
}

export const Container = styled.button<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid ${props => props.theme.colors.border};
  min-width: ${props => props.iconOnly ? '30px' : '117px'};
  height: 36px;
  border-radius: 4px;
  padding: 0px 15px;
  user-select: none;

  &.btnClientes {
    width: 100%;
  }

  > span {
    display: block;

    ${props => props.startIcon && css`
      margin-left: 7px;
    `}
  }

  > svg {
    margin-right: 510px;
  }

  ${props => props.iconOnly && css`
    > svg {
      margin-right: 0px;
    }
  `}

  background-color: ${props => {
    if (props.mode === 'primary') {
      return props.theme.colors.buttonPrimary
    } else if (props.mode === 'secondary') {
      return props.theme.colors.buttonSecondary
    } else if (props.mode === 'confirm') {
      return props.theme.colors.primary
    } else if (props.mode === 'danger') {
      return props.theme.colors.danger
    } else if (props.mode === 'success') {
      return props.theme.colors.success
    } else {
      return 'transparent'
    }
  }};

  color: ${props => {
    if (props.mode === 'secondary') {
      return props.theme.colors.buttonSecondaryText
    } else {
      return props.theme.colors.buttonPrimaryText
    }
  }} !important;

  :hover {
    background-color: ${props => {
    if (props.mode === 'primary') {
      return darken(0.05, props.theme.colors.buttonPrimary)
    } else if (props.mode === 'secondary') {
      return darken(0.05, props.theme.colors.buttonSecondary)
    } else if (props.mode === 'confirm') {
      return darken(0.05, props.theme.colors.primary)
    } else if (props.mode === 'danger') {
      return darken(0.05, props.theme.colors.danger)
    } else if (props.mode === 'success') {
      return darken(0.05, props.theme.colors.success)
    }
  }};
  }

  ${props => props.active && css`
    background-color: ${props.theme.colors.buttonPrimary};
    color: #FFF !important;
    :hover {
      background-color: ${props => darken(0.05, props.theme.colors.buttonPrimary)};
    }
  `}

  &:disabled {
    border: 1px solid ${props => props.theme.colors.border};
    background-color: ${props => props.theme.colors.buttonSecondary};
    color: ${props => props.theme.colors.primaryText};
    cursor: not-allowed;
  }

  ${props => props.showSpinner && css`
    cursor: wait;
  `}
`
