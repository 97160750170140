import { handleErrors } from '../../../helpers/handleErrors'
import { PostParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { CadastrarNegociacaoService } from '../contracts'
import { Negociacao } from '../models'
import { NegociacaoApi } from './_interfaces'

export class CadastrarNegociacaoServiceRest implements CadastrarNegociacaoService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async cadastrarNegociacao (params: PostParams): Promise<Negociacao> {
    let { body, token } = params

    const response = await this.httpRequest.post<NegociacaoApi>({
      path: 'negociacoes',
      body,
      token
    })

    handleErrors(response?.error)
    return response?.data as Negociacao
  }
}
