import { handleErrors } from '../../../helpers/handleErrors'
import { HttpRequestImpl } from '../../../infra/http'
import { PostParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { AtualizarUsuarioService } from '../contracts'
import { Usuario } from '../models/usuario'
import { UsuarioApi } from './_interfaces'

export class AtualizarUsuarioServiceRest implements AtualizarUsuarioService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequestImpl) {
    this.httpRequest = httpRequest
  }

  async atualizarUsuario (params: PostParams): Promise<Usuario> {
    const response = await this.httpRequest.put<UsuarioApi>({
      path: `usuarios/${params?.body?.id}`,
      body: params.body,
      token: params.token
    }, true)

    handleErrors(response?.error)
    return response?.data as Usuario
  }
}
