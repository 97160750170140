import React from 'react'
import { Switch } from 'react-router-dom'
import { Pedidos, Login, Clientes, Dashboard, /* NotFound , */ Perfil, Registro, Notificacoes, Produtos, Chamados } from '../pages'

import { Route } from './route'
import { CadastroCliente, GerarPdf } from '../components'
import { Vendas } from '../pages/Vendas'

export const Routes: React.FC = () => (
  <Switch>
    <Route path="/login" component={Login} />
    <Route exact path="/" component={Dashboard} isPrivate />
    <Route path="/clientes" component={Clientes} isPrivate />
    <Route path="/pedidos" component={Pedidos} isPrivate />
    <Route path="/cadastro-cliente" component={CadastroCliente} isPrivate />
    <Route path="/perfil" component={Perfil} isPrivate />
    <Route path="/registro" component={Registro} />
    <Route path='/gerar-pdf/:pId/:cId' component={GerarPdf} isPrivate />
    <Route path="/notificacoes" component={Notificacoes} isPrivate />
    <Route path="/produtos" component={Produtos} isPrivate />
    <Route path="/chamados" component={Chamados} isPrivate />
    <Route path="/vendas" component={Vendas} isPrivate />

    {/*     <Route path="*" component={NotFound} /> */}

  </Switch>
)
