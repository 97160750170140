import { HttpRequest } from '../../_interfaces/httpRequest'
import { MarcarNotificacaoLidaService } from '../contracts'

export interface MarcarNotificacaLidaParams {
  token: string
  id: number
}

export class MarcarNotificacaoLidaServiceRest implements MarcarNotificacaoLidaService {
  private readonly http: HttpRequest

  constructor (http: HttpRequest) {
    this.http = http
  }

  async marcarNotificacaoLida (notificacao_id: number, token: string): Promise<boolean> {
    const response = await this.http.get({
      path: `notificacoes/${notificacao_id}/marcar_lida`,
      token
    })

    return response?.status === 204
  }
}
