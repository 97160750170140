import capitalize from 'capitalize-pt-br'
import React, { useCallback, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { Input, InputF2, Loading, Table, TableColumnProps } from '..'
import { Empresa } from '../../../modules/empresas/models/empresa'
import { useTransportadoras } from '../../hooks'
import { TableText } from '../../styles/global'
import { Container, Header } from './styles'

const perPage = 10

interface ListaTransportadorasProps extends InputF2 {
}

export const ListaTransportadoras = ({ close, callBack }: ListaTransportadorasProps) => {
  const [searchInput, setSearchInput] = useState('')
  const [currentPage, setCurrentPage] = useState(0)

  const { fetcher: getListaTransportadora, data: transportadoras, isLoading } = useTransportadoras()

  const columns: TableColumnProps[] = [
    {
      Header: '#',
      accessor: 'id',
      minWidth: 20,
      Cell: ({ value }) => <TableText position='right'>{value}</TableText>
    },
    {
      Header: 'Fantasia',
      accessor: 'nome_fantasia',
      Cell: ({ value }) => <TableText position='left'>{value}</TableText>
    },
    {
      Header: 'CNPJ/CPF',
      accessor: 'cnpj',
      Cell: ({ value }) => <TableText position='right'>{value}</TableText>,
      minWidth: 20
    },
    {
      Header: 'E-mail',
      accessor: 'email',
      Cell: ({ value }) => <TableText position='left'>{value.toString().toLowerCase()}</TableText>
    },
    {
      Header: 'Cidade',
      accessor: 'cidade',
      Cell: ({ value }) => <TableText position='right'>{value}</TableText>,
      minWidth: 20
    },
    {
      Header: 'UF',
      accessor: 'uf',
      minWidth: 15,
      Cell: ({ value }) => <TableText position='center'>{value.toString().toUpperCase()}</TableText>
    },
    {
      Header: 'Status',
      accessor: 'status',
      minWidth: 20,
      // eslint-disable-next-line react/display-name
      Cell: ({ value }) => <TableText position='center'>{capitalize(value as string)}</TableText>
    }
  ]

  const handleTableCallback = (empresa: Empresa) => {
    callBack && callBack(empresa)
    close && close()
  }

  const handleOnPageChange = useCallback((page: number) => {
    if (!transportadoras?.metadata?.count) {
      return
    }

    setCurrentPage(page)
  }, [transportadoras])

  const handleEnterFitler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      getListaTransportadora({
        perPage,
        currentPage: currentPage + 1,
        search: searchInput
      })
    }
  }

  const handleFilterOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(0)
    setSearchInput(event.target.value)
  }, [])

  return <Container>
    {isLoading && <Loading/>}
    <Header>
      <div>
        <Input
          margin='0'
          type='text'
          startIcon={FaSearch}
          onChange={handleFilterOnChange}
          onKeyPress={handleEnterFitler}
          autoFocus
          value={searchInput}
        />
      </div>

    </Header>
    <Table
      tabIndex={0}
      columns={columns}
      showPageJump={false}
      data={transportadoras?.data}
      pageSize={perPage}
      page={currentPage}
      pages={transportadoras?.metadata?.count && Math.ceil(transportadoras?.metadata?.count / perPage)}
      onPageChange={handleOnPageChange}
      manual
      loading={isLoading}
      sortable={false}
      showPageSizeOptions={false}
      noDataText="Nenhuma transportadora encontrada"
      nextText='Próximo'
      ofText='de'
      previousText='Anterior'
      showPagination={transportadoras?.metadata?.count as number > perPage}
      pageText='Página'
      dbClickCallback={handleTableCallback}
      selectRow
    />

  </Container>
}
