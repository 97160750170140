import { generateSearchQuery } from '../../../helpers'
import { GetParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { ListarFuncionariosService } from '../contracts'
import { GetListFuncionarioResponse } from '../models'
import { ListaClientesFuncionarioApiResp } from './_interfaces'

export class ListarFuncionariosServiceRest implements ListarFuncionariosService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async listarFuncionarios (params: GetParams): Promise<GetListFuncionarioResponse> {
    let { filter, filterOptions, token, filterObject } = params
    const query = generateSearchQuery(filter, filterOptions, filterObject)

    const response = await this.httpRequest.get<ListaClientesFuncionarioApiResp>({
      path: 'funcionarios',
      token,
      query
    })

    const retorno = {
      data: response.res?.data,
      metadata: response.res?.metadata,
      error: response.error
    } as GetListFuncionarioResponse

    return retorno
  }
}
