import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { TrazerFuncionarioPorIdServiceRest } from '../services'
import { TrazerFuncionarioPorId } from '../useCases'

export function makeTrazerFuncionarioPorId (): TrazerFuncionarioPorId {
  const httpRequest = new HttpRequestImpl()
  const trazerFuncionarioPorIdService = new TrazerFuncionarioPorIdServiceRest(httpRequest)

  return new TrazerFuncionarioPorId(trazerFuncionarioPorIdService)
}
