import { PostParams } from '../../_interfaces'
import { CancelarLiberarEstoquePedidoService } from '../contracts'

export class CancelarLiberarEstoquePedido {
  private readonly cancelarLiberarEstoquePedidoService: CancelarLiberarEstoquePedidoService

  constructor (cancelarLiberarEstoquePedidoService: CancelarLiberarEstoquePedidoService) {
    this.cancelarLiberarEstoquePedidoService = cancelarLiberarEstoquePedidoService
  }

  async execute (params: PostParams): Promise<boolean> {
    return await this.cancelarLiberarEstoquePedidoService.cancelarLiberarEstoquePedido(params)
  }
}
