import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 20px 10px 0px !important;
  padding: 7px 0px;

  >input{
    width: 20px;
    height: 20px;
  }

  >span {
    font-size: 14px;
    margin-left: 10px;
  }
`
