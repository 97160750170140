import React from 'react'
import { IconType } from 'react-icons'
import { Container, Content, Icon } from './styles'

type CorType = 'danger' | 'success' | 'warning' | 'info'

interface CardProps {
  title: string
  text: string | number
  icon: IconType
  cor?: CorType
  width: string
}

export const Card = ({ title, text, icon: CardIcon, cor, width }: CardProps) => {
  return (
    <Container cor={cor} width={width}>
      <Content>
        <h4>{title}</h4>
        <span>{text}</span>
      </Content>
      <Icon cor={cor}>
        <CardIcon size={25} />
      </Icon>
    </Container >
  )
}
