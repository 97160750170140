import React, { useEffect, useState } from 'react'
import { Document, Page, Text, View, PDFViewer, Image } from '@react-pdf/renderer'
import { useParams } from 'react-router-dom'
import { Container, styles } from './styles'
import Logo from '../../assets/imgs/logo_brasao.png'
import { format } from 'date-fns'
import capitalize from 'capitalize-pt-br'
import { formatFloatToCurrency, formatStringTelefone } from '../../../helpers'
import { useCliente, useEmpresa, usePedido } from '../../hooks'
import { Cliente } from '../../../modules/clientes/models'
import { Item } from '../../../modules/pedidos/models'

export const GerarPdf = () => {
  const { pId: pedidoId } = useParams() as any
  const { fetcher: getPedido, data: dataPedido } = usePedido()

  useEffect(() => {
    getPedido(pedidoId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pedido = dataPedido && dataPedido.data ? dataPedido.data : undefined

  const { fetcher: getEmpresaPorId, data: dataEmpresa } = useEmpresa()
  const empresa = dataEmpresa && dataEmpresa ? dataEmpresa : undefined

  const { fetcher: getClientePorId, data: clienteData } = useCliente() // pedido?.cliente?.id as number
  const [cliente, setCliente] = useState<Cliente>()

  const { fetcher: getRevendaPorId, data: revendaData } = useCliente() // pedido?.revenda?.id as number
  const [revenda, setRevenda] = useState<Cliente>()

  useEffect(() => {
    getClientePorId({
      clienteId: pedido?.cliente?.id as number,
      revenda: false
    })

    getRevendaPorId({
      clienteId: pedido?.revenda?.id as number,
      revenda: false
    })

    getEmpresaPorId(pedido?.deposito?.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pedido])

  /* const calcItemComSt = (item: Item) => {
    let valor = 0
    if (item.valor) {
      valor = item.valor
      if (item.stTotal) {
        valor += item.stTotal
      }
    }
    return formatFloatToCurrency(valor as number)
  } */

  const difal = () => {
    let valor = 0

    if (pedido?.itens) {
      pedido.itens.forEach(p => {
        valor += p.quantidade * (p.difal || 0)
      })
    }

    return formatFloatToCurrency(valor as number)
  }

  const calcItemComSt = (item: Item) => {
    let valor = 0
    if (item.valor) {
      valor = item.valor
      if (item.st) {
        valor += item.st
      }
    }
    return formatFloatToCurrency(valor as number)
  }

  // const

  useEffect(() => {
    if (clienteData && clienteData.id && Number(clienteData.id) > 0) {
      if (revendaData && revendaData.id && Number(revendaData.id) > 0) {
        setRevenda(clienteData)
        setCliente(revendaData)
      } else {
        setCliente(clienteData)
      }
    }
  }, [clienteData, revendaData])

  const dataAtual = format(new Date(), 'dd/MM/yyyy')

  return (
    <Container>
      {pedido && <PDFViewer style={styles.viewer}>
        <Document>
          <Page size="A4" style={styles.page} wrap>
            <View>
              <View style={styles.headerPdf}>
                <View style={styles.headerEmpresaPdf}>
                  <Image source={Logo} style={styles.logoPdf}></Image>
                  <Text style={styles.headerTextPdf}>{empresa?.razao_social?.toUpperCase()}</Text>
                </ View>
                <View style={styles.dadosEmpresaLinePdf}>
                  <View style={styles.dadosEmpresaLinePdf}>
                    <Text style={styles.dadosTextPdf}>{capitalize(`${empresa?.endereco}, ${empresa?.numero}`)}</Text>
                  </View>
                  <View style={styles.dadosEmpresaLinePdf}>
                    <Text style={styles.dadosTextPdf}>
                      {`${capitalize(empresa?.cidade as string)} - ${empresa?.uf?.toUpperCase()}, ${empresa?.cep}`}
                    </Text>
                  </View>
                  <View style={styles.dadosEmpresaDivPdf}>
                    <Text style={styles.dadosTextPdf}>{formatStringTelefone(empresa?.telefone as string, 'telefone')}</Text>
                  </View>
                  <View style={styles.dadosEmpresaDivPdf}>
                    <Text style={styles.dadosTextPdf}>route66@route66.com.br</Text>
                  </View>
                </View>
              </View>
              <View style={styles.sectionPdf}>
                <View style={styles.sectionOrcamento}>
                  <Text style={styles.headerTextPdf}>
                    { pedido.tipo === 'pedido' ? 'Pedido' : 'Orçamento' } {pedido.id}
                  </Text>
                </View>
              </View>
              <View style={styles.sectionPdf}>
                <View style={styles.sectionPdfChildren}>
                  <View style={styles.dadosEmpresaLinePdf}>
                    <View style={styles.dadosEmpresaDivPdf}>
                      <Text style={styles.dadosTextPdf}>{pedido.funcionario?.id + ' - ' + capitalize(pedido.funcionario?.nome as string)}</Text>
                    </View>
                  </View>
                  <View style={styles.dadosEmpresaLinePdf}>
                    <View style={styles.dadosDivCondicaoPdf}>
                      <Text style={styles.dadosCondicaoLabelPdf}>Cond. Pag.:</Text>
                      <Text style={styles.dadosCondicaoTextPdf}>{pedido.condicao_pagamento}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.sectionPdfChildren}>
                  <View style={styles.dadosEmpresaLinePdf}>
                    <View style={styles.dadosDivPdf}>
                      <Text style={styles.dadosLabelPdf}>Data:</Text>
                      <Text style={styles.dadosTextPdf}>{dataAtual}</Text>
                    </View>
                  </View>
                  <View style={styles.dadosEmpresaLinePdf}>
                    <View style={styles.dadosDivPdf}>
                      <Text style={styles.dadosLabelPdf}>Valido Em:</Text>
                      <Text style={styles.dadosTextPdf}>{dataAtual}</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.sectionCliente}>
                <View style={styles.dadosCliente}>
                  <Text style={styles.clienteHeaderTextPdf}>Cliente</Text>
                  <Text style={styles.dadosTextPdf}>
                    {cliente?.nome_fantasia
                      ? `${cliente.id} - ${capitalize(cliente?.nome_fantasia)}`
                      : ''
                    }
                  </Text>
                  <Text style={styles.dadosTextPdf}>
                    {cliente?.cidade && cliente?.uf
                      ? `${capitalize(cliente?.endereco as string)}, ${cliente?.numero}`
                      : ''
                    }
                  </Text>
                  <Text style={styles.dadosTextPdf}>
                    {cliente?.cidade && cliente?.uf
                      ? `${capitalize(cliente?.cidade)} - ${cliente?.uf.toUpperCase()}`
                      : ''
                    }
                  </Text>
                  <Text style={styles.dadosTextPdf}>
                    {cliente?.complemento
                      ? `${capitalize(cliente?.complemento as string)}`
                      : ''
                    }
                  </Text>
                  <Text style={styles.dadosTextPdf}>{cliente?.cnpj && `CNPJ:  ${cliente?.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}`}</Text>
                  <Text style={styles.dadosTextPdf}>{`I.E.:  ${cliente?.ie}`}</Text>
                  <Text style={styles.dadosTextPdf}>{`E-mail: ${cliente?.email}`}</Text>
                </View>
                {pedido.observacao_nota_fiscal !== '' && (
                  <View style={styles.sectionObservacao}>
                    <Text style={styles.clienteHeaderTextPdf}>Observação</Text>
                    <Text style={styles.dadosTextPdf}>{pedido.observacao_nota_fiscal}</Text>
                  </View>
                )}
              </View>
              {revenda && <View style={styles.sectionRevenda}>
                <Text style={styles.clienteHeaderTextPdf}>Revenda</Text>
                <Text style={styles.dadosTextPdf}>
                  {revenda?.nome_fantasia
                    ? `${revenda.id} - ${capitalize(revenda?.nome_fantasia)}`
                    : ''
                  }
                </Text>
                <Text style={styles.dadosTextPdf}>
                  {revenda?.cidade && revenda?.uf
                    ? `${capitalize(revenda?.cidade)} - ${revenda?.uf.toUpperCase()}`
                    : ''
                  }
                </Text>
                <Text style={styles.dadosTextPdf}>{revenda?.cnpj && `Cnpj:  ${revenda?.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}`}</Text>
                <Text style={styles.dadosTextPdf}>{revenda?.email}</Text>
              </View>}
              <View style={styles.sectionProdutos}>
                <View style={styles.headerListaProdutosPdf}>
                  <View style={styles.colunaLista7CPdf}>
                    <Text style={styles.textHeaderListaPdf}>Código</Text>
                  </View>
                  <View style={pedido.solar === 's' ? styles.colunaLista80Pdf : styles.colunaLista45Pdf}>
                    <Text style={styles.textHeaderListaPdf}>Produto</Text>
                  </View>
                  <View style={styles.colunaLista7CPdf}>
                    <Text style={styles.textHeaderListaPdf}>Qtde.</Text>
                  </View>

                  {pedido.solar !== 's' && (
                    <>
                      <View style={styles.colunaLista7RPdf}>
                        <Text style={styles.textHeaderListaPdf}>Base</Text>
                      </View>
                      <View style={styles.colunaLista7RPdf}>
                        <Text style={styles.textHeaderListaPdf}>ICMS</Text>
                      </View>
                      <View style={styles.colunaLista7RPdf}>
                        <Text style={styles.textHeaderListaPdf}>IPI/ISS(%)</Text>
                      </View>
                      <View style={styles.colunaLista7RPdf}>
                        <Text style={styles.textHeaderListaPdf}>Preço</Text>
                      </View>
                      <View style={styles.colunaLista7RPdf}>
                        <Text style={styles.textHeaderListaPdf}>ST</Text>
                      </View>
                      <View style={styles.colunaLista7RPdf}>
                        <Text style={styles.textHeaderListaPdf}>Pr+ST</Text>
                      </View>
                      <View style={styles.colunaLista7RPdf}>
                        <Text style={styles.textHeaderListaPdf}>Total</Text>
                      </View>
                    </>
                  )
                  }

                </View>
                {pedido.itens && pedido.itens.map((item: Item, index: number) =>
                  <>
                    <View key={index} style={styles.itemProdutoListaPdf}>
                      <View style={styles.linhaItemProdutoListaPdf}>
                        <View style={styles.colunaLista7CPdf}>
                          <Text style={styles.itemListaTextPdf}>{item.produto?.id}</Text>
                        </View>
                        <View style={pedido.solar === 's' ? styles.colunaLista80Pdf : styles.colunaLista45Pdf}>
                          <Text style={styles.itemListaTextPdf}>
                            {item.produto?.nome_tecnico ? capitalize(item.produto?.nome_tecnico) : ''}
                          </Text>
                        </View>
                        <View style={styles.colunaLista7CPdf}>
                          <Text style={styles.itemListaTextPdf}>{item.quantidade || 0}</Text>
                        </View>

                        {pedido.solar !== 's' && (
                          <>
                            <View style={styles.colunaLista7RPdf}>
                              <Text style={styles.itemListaTextPdf}>{ item.base_icms ? item.base_icms.toFixed(2) : 0 }</Text>
                            </View>
                            <View style={styles.colunaLista7RPdf}>
                              {/* <Text style={styles.itemListaTextPdf}>{ item.icms ? Math.floor(((item.base_icms || 0) * item.icms) / 100) : 0 }</Text> */}
                              <Text style={styles.itemListaTextPdf}>{ item.icms } %</Text>
                            </View>
                            <View style={styles.colunaLista7RPdf}>
                              <Text style={styles.itemListaTextPdf}>{item.ipiTotal || item.iss || 0}</Text>
                            </View>
                            <View style={styles.colunaLista7RPdf}>
                              <Text style={styles.itemListaTextPdf}>
                                {item.valor ? formatFloatToCurrency(item.valor as number) : 0}
                              </Text>
                            </View>
                            <View style={styles.colunaLista7RPdf}>
                              <Text style={styles.itemListaTextPdf}>
                                {item.st ? formatFloatToCurrency(item.st as number) : 0}
                              </Text>
                            </View>
                            <View style={styles.colunaLista7RPdf}>
                              <Text style={styles.itemListaTextPdf}>
                                {calcItemComSt(item)}
                              </Text>
                            </View>
                            <View style={styles.colunaLista7RPdf}>
                              <Text style={styles.itemListaTextPdf}>
                                {item.total ? formatFloatToCurrency(item.total as number - item.frete_st! as number) : 0}
                              </Text>
                            </View>
                          </>
                        )}
                      </View>
                      <View style={styles.linha2ItemProdutoListaPdf}>
                        <View style={styles.divItemProdutoListaPdf}>
                          <Text style={styles.itemListaLabelPdf}>Cód. Fábrica:</Text>
                          <Text style={styles.itemListaText2Pdf}>{item.produto?.codigo_fabrica}</Text>
                        </View>
                        <View style={styles.divItemProdutoListaPdf}>
                          <Text style={styles.itemListaLabelPdf}>NCM:</Text>
                          <Text style={styles.itemListaText2Pdf}>{item.produto?.classificacao_fiscal}</Text>
                        </View>
                        <View style={styles.divItemProdutoListaPdf}>
                          <Text style={styles.itemListaLabelPdf}>Marca:</Text>
                          <Text style={styles.itemListaText2Pdf}>{item.produto?.marca}</Text>
                        </View>
                      </View>
                    </View>
                    { (index === 14) && (
                      <View break />
                    ) }
                  </>
                )}
              </View>
            </View>
            <View style={styles.sectionFooterPdf}>
              <View style={styles.condicaoViewHeaderPdf}>
                <Text style={styles.assinaturaHeaderPdf}>Favor aprovar esse pedido através do e-mail:</Text>
                <Text style={styles.assinaturaHeaderPdf}>esse pedido assinado e carimbado.</Text>
                <Text style={styles.linhaAssinaturaPdf}>Autorizado por:__________________________________________em:___/___/____</Text>
                <Text style={styles.assinaturaFooterPdf}>Nome por Extenso</Text>
              </View>
              <View style={styles.totaisViewHeaderPdf}>
                <View style={styles.totaisDivPdf}>
                  <Text style={styles.totaisLabelPdf}>Frete:</Text>
                  <Text style={styles.totaisTextPdf}>{pedido.frete_valor ? formatFloatToCurrency(pedido.frete_valor) : 0}</Text>
                </View>
                <View style={styles.totaisDivPdf}>
                  <Text style={styles.totaisLabelPdf}>St:</Text>
                  <Text style={styles.totaisTextPdf}>{pedido.st ? formatFloatToCurrency(pedido.st) : 0}</Text>
                </View>
                <View style={styles.totaisDivPdf}>
                  <Text style={styles.totaisLabelPdf}>IPI/ISS:</Text>
                  <Text style={styles.totaisTextPdf}>{pedido.ipi ? formatFloatToCurrency(pedido.ipi) : 0}</Text>
                </View>
                <View style={styles.totaisDivPdf}>
                  <Text style={styles.totaisLabelPdf}>Juros:</Text>
                  <Text style={styles.totaisTextPdf}>{pedido.juros_valor ? formatFloatToCurrency(pedido.juros_valor) : 0}</Text>
                </View>
                <View style={styles.totalDivPdf}>
                  <Text style={styles.totaisLabelPdf}>Total:</Text>
                  <Text style={styles.totaisTextPdf}>{pedido.total ? formatFloatToCurrency(pedido.total) : 0}</Text>
                </View>
                <View style={styles.difalDivPdf}>
                  <Text style={styles.totaisLabelPdf}>Difal:</Text>
                  <Text style={styles.totaisTextPdf}>{difal()}</Text>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>}
    </Container>
  )
}
