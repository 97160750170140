import { useState } from 'react'
import { useUsuario } from '..'
import { makeTrazerEmpresaPorId } from '../../../modules/empresas/factories'
import { Empresa } from '../../../modules/empresas/models'
import { useHandleErrors } from '../app/useHandleErrors'

const trazerEmpresaPorId = makeTrazerEmpresaPorId()

export function useEmpresa () {
  const { data: usuarioData } = useUsuario()
  const [data, setData] = useState<Empresa>()
  const [loading, setLoading] = useState(false)
  const { handleError, executing } = useHandleErrors()

  async function fetcher (empresaId?: number) {
    setLoading(true)

    const empresaParams = {
      token: usuarioData?.token,
      filter: empresaId as unknown as string
    }

    const response = await trazerEmpresaPorId.execute(empresaParams)
    if (response.data) {
      setData(response.data)
      setLoading(false)
    }
    if (response.error && !executing) {
      handleError(response.error)
    }
    setLoading(false)
    return response.data
  }

  return { fetcher, data, loading }
}
