import { formatStringDate } from '../../../helpers'
import { TrazerCrsClienteParams, TrazerCrsClienteService } from '../contracts'
import { GetCrsClienteResponse } from '../models'

export class TrazerCrsCliente {
  private readonly trazerCrsClienteService: TrazerCrsClienteService

  constructor (trazerCrsClienteService: TrazerCrsClienteService) {
    this.trazerCrsClienteService = trazerCrsClienteService
  }

  async execute (params: TrazerCrsClienteParams): Promise<GetCrsClienteResponse> {
    let response = await this.trazerCrsClienteService.trazerCrsCliente(params)
    if (response.data) {
      response.data = response.data.map(item => {
        const newState = {
          ...item,
          vencimento: item.vencimento ? formatStringDate(item.vencimento, 'xxxxxxxx', 'completo') : '',
          data_baixa: item.data_baixa ? formatStringDate(item.data_baixa, 'xxxxxxxx', 'completo') : '',
          emissao: item.emissao ? formatStringDate(item.emissao, 'xxxxxxxx', 'completo') : ''
        }
        return newState
      })
    }

    return response
  }
}
