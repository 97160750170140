import { HttpRequest } from '../../_interfaces/httpRequest'
import { ListarNotificacoesFuncionarioService } from '../contracts'
import { GetListaNotificacaoResponse } from '../models'
import { ListarNotificacoesFuncionarioParams } from '../_interfaces'
import { ListaClientesFuncionarioApiResp } from './_interfaces'
import { generateSearchQuery } from '../../../helpers'

export class ListarNotificacoesFuncionarioServiceRest implements ListarNotificacoesFuncionarioService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async listarNotificacoes (params: ListarNotificacoesFuncionarioParams): Promise<GetListaNotificacaoResponse> {
    let { token, funcionarioId, lidas, filter, filterOptions, filterObject } = params

    if (lidas !== undefined) {
      console.log(lidas, filterObject)
      filterObject = {
        ...filterObject,
        lida: lidas
      }
    }

    let query = generateSearchQuery(filter, filterOptions, filterObject)

    const response = await this.httpRequest.get<ListaClientesFuncionarioApiResp>({
      path: `funcionarios/${funcionarioId}/notificacoes`,
      token,
      query
    })

    const retorno = {
      data: response.res?.data,
      metadata: response.res?.metadata,
      error: response.error
    } as GetListaNotificacaoResponse

    return retorno
  }
}
