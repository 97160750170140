import styled from 'styled-components'
import { fadein } from '../../styles/animations'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 50px 10px;
  width: 100%;
  animation: ${fadein} 0.6s forwards;
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 65px;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 0px;

  > span {
    color: ${(props) => props.theme.colors.primaryText} !important;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
  }

  > button {
    margin-left: 10px;
  }

  @media (max-width: 900px) {
    & {
      width: 100%;
      flex-direction: column;
      align-items: center;
      height: auto;

      > button {
        width: 100%;
        margin-left: 0;
      }
    }
  }
`

export const FirstSection = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;

  @media (max-width: 900px) {
    & {
      flex-direction: column;
    }
  }
`

export const MobileContent = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 1rem;

  @media (max-width: 600px) {
    & {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
`

export const BoxImg = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 7px;
  z-index: 100;
`

export const ImageDiv = styled.div`
  display: flex;
  width: 300px;
  height: 300px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 7px;

  > img {
    width: 90%;
    max-height: 250px;
  }

  @media (max-width: 900px) {
    & {
      width: 100%;
      margin: 0 auto;

      > img {
        width: 250px;
      }
    }
  }
`

export const InfosDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 2%;

  @media (max-width: 900px) {
    & {
      margin-left: 0;
    }
  }
`

export const SecondSection = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  margin-top: 10px;
  flex-direction: column;
  > span:first-of-type {
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    padding: 10px 0px;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
  }
`

export const ThirdSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  height: auto;

  > div {
    display: flex;
    width: 49%;
    flex-direction: column;
    > span:first-of-type {
      font-size: 14px;
      font-weight: bold;
      width: 100%;
      padding: 10px 0px;
      border-bottom: 1px solid ${(props) => props.theme.colors.border};
    }
  }

  @media (max-width: 900px) {
    & {
      flex-direction: column;

      > div {
        width: 100%;
      }
    }
  }
`
