import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`

interface Content {
  isVisible?: boolean
}

export const Content = styled.div<Content>`
  display: block;
  flex: 1;
  margin-left: ${props => props.isVisible ? '70px' : '0px'};
  height: (100vh - 50px);
  transition: all 300ms;

  > div {
    padding: 15px;
  }

  @media screen and (max-width: 720px) {
    margin-left: 0;

    ${props => props.isVisible && css`
      &:before{
        content: "" ;
        position: absolute;
        background-color: rgba(0,0,0,0.3);
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    `}
    > div {
      padding: 5px;
    }
  }

`
