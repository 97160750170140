import { replaceLineBreakToApi } from '../../../../helpers/helperLineBreak'
import { ItemPedidoApi, PedidoApiAtualizar } from '../_interfaces'

export function sanetizarPedidoAtualizacao (pedido: any): PedidoApiAtualizar {
  const itens = pedido?.itens?.map((item: any) => {
    let itemOrcamento: ItemPedidoApi = {
      produto_id: item?.produto?.id as number,
      quantidade: item?.quantidade,
      valor: item?.valor,
      data: item?.data as string,
      promocao_id: item?.promocao_id,
      item_promocao_id: item?.item_promocao_id,
      st: item.stTotal,
      ipi: item.ipiTotal
    }

    if (item.id !== undefined) {
      itemOrcamento.id = item.id
    }

    if (item.lote) {
      itemOrcamento.lote = item?.lote
    }

    return itemOrcamento
  })

  let data = {} as PedidoApiAtualizar

  if (pedido?.funcionario?.id) {
    data = {
      ...data,
      funcionario_id: pedido.funcionario.id
    }
  }

  if (pedido?.funcionario2?.id) {
    data = {
      ...data,
      funcionario2_id: pedido.funcionario2.id
    }
  }

  if (pedido?.transportadora?.id) {
    data = {
      ...data,
      transportadora_id: pedido.transportadora.id
    }
  }

  if (pedido?.id) {
    data.pedido_id = pedido.id
  } else if (pedido.empresa) {
    data.pedido_id = 12312
  }

  if (pedido?.funcionario?.empresa_id) {
    data.empresa_id = pedido.funcionario.empresa_id
  } else if (pedido.empresa) {
    data.empresa_id = pedido.empresa.id
  }

  if (pedido?.deposito?.id) {
    data.deposito_id = pedido.deposito.id as number
  }

  if (pedido?.contato?.id) {
    data.contato_id = pedido.contato.id as number
  }

  if (pedido?.tipo_faturamento === 'direto') {
    if (pedido?.cliente?.id && pedido.revenda) {
      data.revenda_id = pedido.cliente.id as number
      data.cliente_id = pedido.revenda.id as number
    }
  } else {
    if (pedido?.cliente?.id) {
      data.cliente_id = pedido.cliente.id as number
    }
  }

  if (pedido.liberado) {
    data.liberado = pedido.liberado
  }

  if (pedido.condicao_pagamento) {
    data.condicao_pagamento = pedido.condicao_pagamento
  }

  if (pedido?.pagamento_tipo) {
    if (pedido.pagamento_tipo === 'cartao') {
      data.pagamento_tipo = 'carteira'
    } else {
      data.pagamento_tipo = pedido.pagamento_tipo
    }
  }

  if (pedido.frete_por_conta) {
    data.frete_por_conta = pedido.frete_por_conta
  }

  if (pedido.frete_valor !== undefined) {
    data.frete_valor = pedido.frete_valor
  }

  if (pedido.frete_cotacao !== undefined) {
    data.frete_cotacao = pedido.frete_cotacao
  }

  // data.data_edicao = new Date().toISOString()
  data.data_edicao = pedido.updated_at

  if (pedido.bndes) {
    data.bndes = pedido.bndes
  }

  if (pedido.bndes_codigo !== undefined) {
    data.bndes_codigo = pedido.bndes_codigo
  }

  if (pedido.possibilidades) {
    data.possibilidades = pedido.possibilidades
  }

  if (pedido.observacao !== undefined) {
    data.observacao = pedido.observacao
  }

  if (pedido.observacao_nota_fiscal !== undefined) {
    data.observacao_nota_fiscal = pedido.observacao_nota_fiscal
  }

  if (pedido.status) {
    data.status = pedido.status
  }

  if (pedido.solicitacao_vendedor_status) {
    data.solicitacao_vendedor_status = pedido.solicitacao_vendedor_status
  }

  if (pedido.juros) {
    data.juros = pedido.juros
  }

  if (pedido.solicitacao_vendedor_observacao) {
    data.solicitacao_vendedor_observacao = replaceLineBreakToApi(pedido.solicitacao_vendedor_observacao)
  }

  data.usoeconsumo = pedido.usoeconsumo ? pedido.usoeconsumo : 'n'
  data.pagamento_gerente = pedido.pagamento_gerente ? pedido.pagamento_gerente : 'n'
  data.juros_forcado = pedido.juros_forcado ? pedido.juros_forcado : 'n'
  data.pedido_desbloqueado = pedido.pedido_desbloqueado ? pedido.pedido_desbloqueado : 'n'

  data.solar = pedido.solar ? pedido.solar : 'n'

  data.id = pedido.id

  // Os itens que não possuem data de edição devem ser removidos para evitar conflitos na api
  // mas devem ser mantidos no estado do redux pois é necessarios exibilos na tela durante a edição

  if (itens) {
    data.itens = itens
  }
  return data
}
