import capitalize from 'capitalize-pt-br'
import { differenceInDays, format, startOfMonth, subMonths } from 'date-fns'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { BiFilter } from 'react-icons/bi'
import { FaSearch } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { calcTotalListaPedidos, checkPedidoStatus, convertStringToDate, canUpdatePedido, formatStringDate } from '../../../helpers'
import { Cliente, Contato } from '../../../modules/clientes/models'
import { Empresa } from '../../../modules/empresas/models/empresa'
import { Funcionario } from '../../../modules/funcionarios/models/funcionario'
import { makeAtualizarPedido, makeBloquearDesconto, makeBloquearEstoqueZerado, makeCancelarPedido, makeDesmembrarPedido, makeLiberarEstoquePedido, makeNovaSolicitacao, makeProcessarFretePedido, makeTransformarPedido } from '../../../modules/pedidos/factories'
import { makeCancelarLiberarEstoquePedido } from '../../../modules/pedidos/factories/cancelar-liberar-estoque-pedido'
import { GetPedido, GetPedidoCotacoesFretes } from '../../../modules/pedidos/models'
import { PostParams } from '../../../modules/_interfaces'
import { FilterPedidoProps } from '../../../modules/_interfaces/filtersProps'
import { SortTableProps } from '../../../modules/_interfaces/sortTable'
import { Button, FiltroPedidos, Input, ListaContatos, ListaFretes, Loading, Modal, Select, Table, TableColumnProps } from '../../components'
import { AcoesPedidos } from '../../components/AcoesPedidos'
import { useSideBar } from '../../contexts'

import { useCliente, useFuncionario, useUsuario, usePedido, useListaPedidos, useExecUseCase } from '../../hooks'
import { usePedidoProcessarFrete } from '../../hooks/pedido/usePedidoProcessarFrete'

import { MainLayout } from '../../layouts/MainLayout'
import { AppState } from '../../store'
import { iniciarAtendimento, abrirPedido } from '../../store/atendimentos/actions'
import { AtendimentosState } from '../../store/atendimentos/interfaces'
import { TableActions, TablePossibilidades, TableText, TextStatus } from '../../styles/global'
import { Container, TextStatusPedido } from './styles'
import { ListaFretesManual } from '../../components/ListaFretesManual'
import { makeTrazerEquipePorId } from '../../../modules/equipes/factories'
import { makeTrazerEmpresaPorId } from '../../../modules/empresas/factories'
import { DesmembrarPedido } from '../../components/DesmembrarPedido'
import { NovaSolicitacao } from '../../components/NovaSolicitacao'

const perPage = 20

export const Pedidos = () => {
  const [pedidoIdSelecionado, setPedidoIdSelecionado] = useState(0)

  const dispatch = useDispatch()
  const { isMobile } = useSideBar()
  const [currentPage, setCurrentPage] = useState<number>(0)
  const { data: usuario } = useUsuario()
  const { data: funcionario } = useFuncionario(usuario?.funcionario_id as unknown as number)
  const { fetcher: getClientePorId, data: clienteComContatos, loading: loadingCliente } = useCliente()
  const { fetcher: getPedido, data: pedidoSelecionado, loading: loadingGetPedido } = usePedido()
  const { fetcher: getPedidoFretes, data: pedidoFretes, loading: loadingPedidoFretes } = usePedidoProcessarFrete()
  const { fetcher: getPedidoEstoque, data: dataPedidoEstoque, loading: loadingPedidoEstoque } = usePedido()
  const { fetcher: getListaPedidos, data: pedidosFuncionario, loading: loadingListaPedido } = useListaPedidos()
  const [showListaContatos, setShowListaContatos] = useState(false)
  const [showListaFretes, setShowListaFretes] = useState(false)
  const [showDesmembrarPedido, setShowDesmembrarPedido] = useState(false)
  const [showListaFretesManual, setShowListaFretesManual] = useState(false)
  const [showSolicitacoes, setShowSolicitacoes] = useState(false)
  const atendimentosState = useSelector<AppState>(state => state.atendimentos) as AtendimentosState
  const [filterIsOpen, setFilterIsOpen] = useState(false)
  const [pedidoEdicao, setPedidoEdicao] = useState<GetPedido>({} as GetPedido)
  const [solicitacaoEdicao, setSolicitacaoEdicao] = useState<any>()
  const [pedidoIdEmEdicao, setPedidoIdEmEdicao] = useState(0)

  const [filters, setFilters] = useState<FilterPedidoProps>({
    search: '',
    tipo: '',
    tipoBusca: 'cod_pedido',
    liberadoEstoque: '',
    estoque: '',
    liberacao: '',
    dataInicial: '',
    dataFinal: '',
    exibir: 'funcionario'
  } as FilterPedidoProps)

  console.log(filters)

  const [sortTable, setSortTable] = useState<SortTableProps>({
    id: 'updated_at',
    desc: true
  } as SortTableProps)

  const { executeUseCase, executing: executingAtualizaPedido } = useExecUseCase()

  const erroDiffDays = () => {
    Swal.fire({
      title: 'Periodo muito extenso!',
      text: 'O periodo escolhido é maior que 100 dias, tente novamente com uma data menor',
      icon: 'warning'
    })
  }

  const handleSearch = useCallback(() => {
    const dataInicial = convertStringToDate(filters.dataInicial)
    const dataFinal = convertStringToDate(filters.dataFinal)
    const diffDate = differenceInDays(dataFinal, dataInicial)

    if (diffDate > 100) {
      erroDiffDays()
    } else {
      if (filters.tipoBusca !== '' && filters.exibir !== 'funcionario') {
        if (!filterIsOpen) {
          filters.exibir = ''
        }
      }

      setCurrentPage(0)
      getListaPedidos(
        {
          perPage,
          currentPage: 1,
          filters,
          sortTable
        }
      )
    }
  }, [filterIsOpen, filters, getListaPedidos, sortTable])

  const handleOnChangeSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((oldState: FilterPedidoProps) => {
      const newState = { ...oldState, search: event.target.value }
      return newState
    })
  }, [setFilters])

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const canChangePedido = useCallback(async (pedido: GetPedido) => {
    if (pedido) {
      const canUpdate = await canUpdatePedido(pedido.id, pedido?.updated_at, usuario?.token)
      if (!canUpdate) {
        Swal.fire({
          icon: 'warning',
          title: 'Não foi possivel concluir a ação',
          text: 'Esse pedido possui dados atualizados, recarregue a lista e tente novamente!'
        })
      }
      return canUpdate
    } else {
      return false
    }
  }, [usuario])

  useEffect(() => {
    getClientePorId({
      clienteId: pedidoSelecionado?.data?.cliente?.id as unknown as number,
      revenda: false
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pedidoSelecionado])

  useEffect(() => {
    if (!pedidoIdSelecionado) {
      return
    }

    if (pedidoSelecionado?.data?.id && clienteComContatos && clienteComContatos) {
      if (!atendimentosState?.data[pedidoSelecionado?.data?.cliente?.id as number]?.contato?.id) {
        setShowListaContatos(true)
        return
      }

      dispatch(iniciarAtendimento({
        botaoAtendimentoAtivo: 'pedidos',
        cliente: clienteComContatos as Cliente,
        contato: pedidoSelecionado?.data?.contato as Contato,
        funcionario: funcionario?.data as Funcionario
      }))

      dispatch(abrirPedido({
        pedido: pedidoSelecionado?.data
      }))

      setPedidoIdSelecionado(0)
    }
  }, [atendimentosState, clienteComContatos, dispatch, funcionario, pedidoIdSelecionado, pedidoSelecionado])

  const reloadList = useCallback(() => {
    setCurrentPage(0)

    getListaPedidos(
      {
        perPage,
        currentPage: 1,
        filters,
        sortTable
      }
    )
  }, [filters, getListaPedidos, sortTable])

  const handleTableOnDbClick = useCallback((pedido: GetPedido) => {
    if (pedido) {
      setPedidoIdSelecionado(pedido.id as number)
      getPedido(pedido.id)
    }
  }, [getPedido])

  const handleProcessarFrete = useCallback(async (pedido: GetPedido) => {
    const podeSalvarPedidoStatus = checkPedidoStatus(pedido.status as string)
    const podeSalvarPedidoData = canChangePedido(pedido)

    if (!podeSalvarPedidoStatus || !podeSalvarPedidoData) {
      reloadList()
      return
    }

    if (!pedido.id) {
      return null
    }

    const equipe = await executeUseCase<any>(
      makeTrazerEquipePorId(),
      {
        filter: String(pedido?.equipe?.id)
      },
      '',
      '',
      undefined,
      false
    )

    const empresa = await executeUseCase<any>(
      makeTrazerEmpresaPorId(),
      {
        filter: String(pedido?.deposito?.id)
      },
      '',
      '',
      undefined,
      false
    )

    const frete_equipe = equipe?.frete_automatico ?? 'n'
    const frete_empresa = empresa?.data?.frete_automatico ?? 'n'

    if (frete_equipe === 's' && frete_empresa === 's') {
      getPedidoFretes(pedido.id)
      setShowListaFretes(true)
      return
    }

    setPedidoIdEmEdicao(pedido.id)
    setShowListaFretesManual(true)
  }, [canChangePedido, executeUseCase, getPedidoFretes, reloadList])

  const handleProcessarFreteEstacionamento = useCallback(async (pedido: GetPedido) => {
    const podeSalvarPedidoStatus = checkPedidoStatus(pedido.status as string)
    const podeSalvarPedidoData = canChangePedido(pedido)

    if (!pedido.id || !podeSalvarPedidoStatus || !podeSalvarPedidoData) {
      reloadList()
      return null
    }

    const useCase = makeProcessarFretePedido()
    executeUseCase<PostParams>(
      useCase,
      {
        body: {
          pedido_id: pedido?.id,
          tipo: 'estacionamento'
        }
      },
      'Pedido atualizado com sucesso!',
      `Essa ação irá processar o frete do pedido ${pedido?.id} para estacionamento, deseja prosseguir?`,
      reloadList
    )
  }, [canChangePedido, executeUseCase, reloadList])

  const handleDesmembrarPedido = useCallback(async (pedido: GetPedido) => {
    const podeSalvarPedidoStatus = checkPedidoStatus(pedido.status as string)
    const podeSalvarPedidoData = canChangePedido(pedido)

    if (!podeSalvarPedidoStatus || !podeSalvarPedidoData) {
      reloadList()
      return
    }

    if (!pedido.id) {
      return null
    }

    setPedidoIdEmEdicao(pedido.id)
    setShowDesmembrarPedido(true)
  }, [canChangePedido, reloadList])

  const handleSolicitacoes = useCallback(async (pedido: GetPedido) => {
    if (!pedido.id) {
      return null
    }

    setPedidoIdEmEdicao(pedido.id)
    setShowSolicitacoes(true)
  }, [])

  const handleLiberarEstoque = useCallback(async (pedido: GetPedido, valor: string) => {
    const podeSalvarPedidoStatus = checkPedidoStatus(pedido.status as string)
    const podeSalvarPedidoData = canChangePedido(pedido)
    if (!podeSalvarPedidoStatus || !podeSalvarPedidoData) {
      reloadList()
      return
    }

    let params: any = {
      body: {
        pedidoId: pedido?.id
      },
      token: usuario ? usuario.token : ''
    }

    if (valor === 's') {
      let estoqueInsuficiente = false
      let gerarFilhote = false

      const dataPedido = await getPedidoEstoque(pedido.id)

      if (dataPedido?.data) {
        dataPedido.data.itens?.forEach(element => {
          if (element.estoque_atual && element.quantidade > element.estoque_atual) {
            estoqueInsuficiente = true
          }
        })
      }

      if (estoqueInsuficiente) {
        const { isConfirmed } = await Swal.fire({
          icon: 'warning',
          title: 'Estoque insuficiente!',
          text: 'O estoque atual não pode suprir o pedido, como deseja prosseguir?',
          showConfirmButton: true,
          confirmButtonText: 'Liberar, Faturar e Gerar Filhote',
          showCancelButton: true,
          cancelButtonText: 'Liberar e aguardar',
          cancelButtonColor: '#007bff'
        })
        if (isConfirmed) {
          gerarFilhote = true
        }
        params.body = {
          ...params.body,
          gerar_filhote: gerarFilhote
        }
      }

      const useCase = makeLiberarEstoquePedido()
      executeUseCase<PostParams>(
        useCase,
        params,
        'Pedido atualizado com sucesso!',
        `Essa ação irá liberar o pedido ${pedido?.id} para o estoque, realmente deseja prosseguir ?`,
        reloadList
      )
    } else {
      const useCase = makeCancelarLiberarEstoquePedido()
      executeUseCase<PostParams>(
        useCase,
        params,
        'Pedido atualizado com sucesso!',
        `Essa ação irá cancelar a liberacao do pedido ${pedido?.id} para o estoque, realmente deseja prosseguir ?`,
        reloadList
      )
    }
  }, [canChangePedido, executeUseCase, getPedidoEstoque, reloadList, usuario])

  const handleGerarPdf = useCallback((pedido: GetPedido) => {
    const podeSalvarPedidoData = canChangePedido(pedido)
    if (!podeSalvarPedidoData) {
      reloadList()
      return
    }
    if (pedido) {
      const url = '/gerar-pdf/' + pedido.id + '/' + pedido.cliente?.id
      window.open(url, '_blank')
    }
  }, [canChangePedido, reloadList])

  const handleTransformarPedido = useCallback((pedido: GetPedido) => {
    const podeSalvarPedidoData = canChangePedido(pedido)
    if (!podeSalvarPedidoData) {
      reloadList()
      return
    }
    if (pedido.tipo === 'pedido') {
      return
    }

    const params = {
      id: pedido?.id
    }

    const useCase = makeTransformarPedido()
    executeUseCase<any>(
      useCase,
      params,
      'Orçamento transformado em pedido!',
      `Essa ação irá transformar o orçamento ${pedido?.id} em pedido, realmente deseja prosseguir?`,
      reloadList,
      true
    )
  }, [canChangePedido, executeUseCase, reloadList])

  const handleCancelarPedido = useCallback((pedido: GetPedido) => {
    const podeSalvarPedidoStatus = checkPedidoStatus(pedido?.status as string)
    const podeSalvarPedidoData = canChangePedido(pedido)
    if (!podeSalvarPedidoStatus || !podeSalvarPedidoData) {
      reloadList()
      return
    }

    const params = {
      id: pedido?.id
    }

    const useCase = makeCancelarPedido()
    executeUseCase<any>(
      useCase,
      params,
      'Pedido cancelado com sucesso!',
      `Essa ação irá cancelar o pedido ${pedido?.id}, realmente deseja prosseguir ?`,
      reloadList,
      true
    )
  }, [canChangePedido, executeUseCase, reloadList])

  const bloquearDesconto = useCallback((pedido: GetPedido, mensagem: string) => {
    const params = {
      id: pedido?.id
    }

    const useCase = makeBloquearDesconto()

    executeUseCase<any>(
      useCase,
      params,
      'Pedido cancelado com sucesso!',
      mensagem,
      reloadList,
      true
    )
  }, [executeUseCase, reloadList])

  const bloquearEstoqueZerado = useCallback((pedido: GetPedido, mensagem: string) => {
    const params = {
      id: pedido?.id
    }

    const useCase = makeBloquearEstoqueZerado()

    executeUseCase<any>(
      useCase,
      params,
      'Pedido cancelado com sucesso!',
      mensagem,
      reloadList,
      true
    )
  }, [executeUseCase, reloadList])

  const handleBloquearPedido = useCallback((pedido: GetPedido, modo: string) => {
    const podeSalvarPedidoStatus = checkPedidoStatus(pedido?.status as string)
    const podeSalvarPedidoData = canChangePedido(pedido)
    if (!podeSalvarPedidoStatus || !podeSalvarPedidoData) {
      reloadList()
      return
    }

    let mensagem = ''

    if (modo === 'estoque_zerado') {
      mensagem = `Essa ação irá bloquear o pedido ${pedido?.id}, referente ao estoque, realmente deseja prosseguir ?`
      bloquearEstoqueZerado(pedido, mensagem)
    } else if (modo === 'desconto') {
      mensagem = `Essa ação irá bloquear o pedido ${pedido?.id}, referente ao desconto, realmente deseja prosseguir ?`
      bloquearDesconto(pedido, mensagem)
    }
  }, [bloquearDesconto, bloquearEstoqueZerado, canChangePedido, reloadList])

  const checkAguardAnalise = (pedido: GetPedido) => {
    let checked = false
    if (pedido.status === 'bloqueado' && pedido.bloqueio_cliente_desativado === 'n') {
      checked = true
    } else if (pedido && pedido.cliente && pedido.cliente.limite) {
      const crs_aberto = pedido.crs_aberto || 0
      const pedidos_credito_liberado = pedido.pedidos_credito_liberado || 0
      const cheques = pedido.cheques || 0
      const soma = crs_aberto + pedidos_credito_liberado + cheques
      if (soma > pedido.cliente.limite) {
        checked = true
      }
    }
    return checked
  }

  const columns: TableColumnProps[] = useMemo(() => {
    if (isMobile) {
      return [
        {
          Header: '#',
          accessor: 'id',
          minWidth: 7,
          Cell: ({ value }) => {
            return <TableText position='right'>{value}</TableText>
          }
        },
        {
          Header: 'Tipo',
          accessor: 'tipo',
          minWidth: 7,
          Cell: ({ value }) => {
            return <TableText position='center'>{value === 'pedido' ? 'P' : 'O'}</TableText>
          }
        },
        {
          Header: 'Cliente',
          accessor: 'cliente',
          minWidth: 25,
          Cell: (cell) => {
            const cliente: Cliente = cell.value as Cliente
            return `${cliente.id} - ${capitalize(cliente.razao_social || cliente.nome_fantasia as string)}`
          }
        },
        {
          Header: 'Lib. Est.',
          accessor: 'liberado',
          minWidth: 8,
          Cell: ({ value }) => {
            const status = value === 'n' ? 'Não' : 'Sim'
            return <TextStatus status={value}>{status}</TextStatus>
          }
        },
        {
          Header: 'Analise',
          accessor: 'analise_credito',
          minWidth: 8,
          Cell: ({ value }) => {
            let color = ''
            if (value === 'aprovado') {
              color = 'success'
            } else if (value === 'rejeitado') {
              color = 'danger'
            }
            return <TableText position='center' color={color}>{capitalize(value)}</TableText>
          }
        },
        {
          Header: 'Situação',
          accessor: 'status',
          Cell: ({ value }) => <TextStatusPedido status={value}>{capitalize(value)}</TextStatusPedido>,
          minWidth: 10
        },
        {
          Header: 'Data',
          accessor: 'data',
          minWidth: 10,
          Cell: ({ value }) => <TableText position='center'>{value}</TableText>
        },
        {
          Header: 'Ações',
          minWidth: 5,
          Cell: ({ row }) => {
            const pedido = row._original as GetPedido
            return (
              <TableActions>
                <AcoesPedidos
                  pedido={pedido}
                  callbackOpen={handleTableOnDbClick}
                  handleProcessarFrete={handleProcessarFrete}
                  handleProcessarFreteEstacionamento={handleProcessarFreteEstacionamento}
                  handleLiberarEstoque={handleLiberarEstoque}
                  // callbackMultChange={handleMultChangePedido}
                  handleGerarPdf={handleGerarPdf}
                  handleTransformarPedido={handleTransformarPedido}
                  handleDesmembrarPedido={handleDesmembrarPedido}
                  handleSolicitacoes={handleSolicitacoes}
                  handleCancelarPedido={handleCancelarPedido}
                  handleBloquearPedido={handleBloquearPedido}
                />
              </TableActions>
            )
          }
        }
      ]
    } else {
      return [
        {
          Header: '#',
          accessor: 'id',
          minWidth: 7,
          Cell: ({ value }) => {
            return <TableText position='right'>{value}</TableText>
          }
        },
        {
          Header: 'Tipo',
          accessor: 'tipo',
          minWidth: 7,
          Cell: ({ value }) => {
            return <TableText position='left'>{capitalize(value)}</TableText>
          }
        },
        {
          Header: 'Cliente',
          accessor: 'cliente',
          minWidth: 25,
          Cell: (cell) => {
            const cliente: Cliente = cell.value as Cliente
            return `${cliente.id} - ${capitalize(cliente.razao_social || cliente.nome_fantasia as string)}`
          }
        },
        {
          Header: 'Depósito',
          accessor: 'deposito',
          minWidth: 13,
          Cell: (cell) => {
            const deposito: Empresa = cell.value as Empresa
            return `${capitalize(deposito.nome as string)}/${deposito.uf?.toUpperCase()}`
          }
        },
        {
          Header: 'Transportadora',
          accessor: 'transportadora',
          minWidth: 25,
          Cell: (cell) => {
            const transportadora: Cliente = cell.value as Cliente
            return `${transportadora.id} - ${capitalize(transportadora.nome_fantasia as string)}`
          }
        },
        {
          Header: 'Lib. Estoque',
          accessor: 'liberado',
          minWidth: 8,
          Cell: ({ value }) => {
            const status = value === 'n' ? 'Não' : 'Sim'
            return <TextStatus status={value}>{status}</TextStatus>
          }
        },
        {
          Header: 'Analise Cred.',
          accessor: 'analise_credito',
          minWidth: 8,
          Cell: ({ value }) => {
            let color = ''
            if (value === 'aprovado') {
              color = 'success'
            } else if (value === 'rejeitado') {
              color = 'danger'
            }
            return <TableText position='center' color={color}>{capitalize(value)}</TableText>
          }
        },
        {
          Header: 'Aguard. Analise',
          minWidth: 8,
          Cell: ({ row }) => {
            const pedido = row._original as GetPedido
            return <TableText position='center'><input type='checkbox' defaultChecked={checkAguardAnalise(pedido)} readOnly={true}></input></TableText>
          }
        },
        {
          Header: 'Modo Pgto.',
          minWidth: 13,
          Cell: ({ row }) => {
            return <TableText position='center'>{capitalize(row._original.pagamento_tipo + ' (' + row._original.condicao_pagamento + ')')}</TableText>
          }
        },
        {
          Header: 'Situação',
          accessor: 'status',
          Cell: ({ value }) => <TextStatusPedido status={value}>{capitalize(value)}</TextStatusPedido>,
          minWidth: 10
        },
        {
          Header: 'Possib. Fechamento',
          accessor: 'possibilidades',
          Cell: ({ value }) => {
            const possibilidade = value === 'baixa' ? 'baixa' : value === 'media' ? 'media' : 'alta'
            return <TablePossibilidades position='center' background={possibilidade}>{capitalize(value)}</TablePossibilidades>
          },
          minWidth: 10
        },
        {
          Header: 'Frete Pago',
          accessor: 'frete_por_conta',
          Cell: ({ row }) => {
            const frete = row._original.frete_por_conta === 'emitente' && row._original.frete_cotacao! > 0
            return <TableText position='center'><input type='checkbox' defaultChecked={frete} readOnly={true} ></input></TableText>
          },
          minWidth: 8
        },
        {
          Header: 'Data',
          accessor: 'data',
          minWidth: 10,
          Cell: ({ value }) => <TableText position='center'>{value}</TableText>
        },
        {
          Header: 'Data Faturamento',
          accessor: 'data_faturamento',
          minWidth: 10,
          Cell: ({ value }) => {
            return (
              <TableText position='center'>{value !== null && value !== undefined ? formatStringDate(value, 'xxxxxxxx', 'completo') : '-'}</TableText>
            )
          }
        },
        {
          Header: 'Doc. Fiscal',
          accessor: 'documento_fiscal',
          minWidth: 8,
          Cell: ({ value }) => {
            return <TableText position='center'>{capitalize(value)}</TableText>
          }
        },
        {
          Header: 'Total (R$)',
          minWidth: 10,
          Cell: ({ row }) => {
            return <TableText position='right'>{calcTotalListaPedidos(row._original)}</TableText>
          }
        },
        {
          Header: 'Ações',
          minWidth: 5,
          Cell: ({ row }) => {
            const pedido = row._original as GetPedido
            return (
              <TableActions>
                <AcoesPedidos
                  pedido={pedido}
                  callbackOpen={handleTableOnDbClick}
                  handleProcessarFrete={handleProcessarFrete}
                  handleProcessarFreteEstacionamento={handleProcessarFreteEstacionamento}
                  handleLiberarEstoque={handleLiberarEstoque}
                  // callbackMultChange={handleMultChangePedido}
                  handleGerarPdf={handleGerarPdf}
                  handleDesmembrarPedido={handleDesmembrarPedido}
                  handleSolicitacoes={handleSolicitacoes}
                  handleTransformarPedido={handleTransformarPedido}
                  handleCancelarPedido={handleCancelarPedido}
                  handleBloquearPedido={handleBloquearPedido}
                />
              </TableActions>
            )
          }
        }
      ]
    }
  }, [isMobile, handleTableOnDbClick, handleProcessarFrete, handleProcessarFreteEstacionamento, handleLiberarEstoque, handleGerarPdf, handleTransformarPedido, handleDesmembrarPedido, handleCancelarPedido, handleBloquearPedido, handleSolicitacoes])

  const handleFilterOpen = () => {
    setFilterIsOpen(!filterIsOpen)

    if (!filterIsOpen) {
      setFilters({
        ...filters,
        dataInicial: format(startOfMonth(subMonths(new Date(), 1)), 'yyyyMMdd'),
        dataFinal: format(new Date(), 'yyyyMMdd'),
        exibir: 'funcionario'
      })
    } else {
      setFilters({
        ...filters,
        exibir: 'funcionario',
        dataFinal: '',
        dataInicial: '',
        estoque: '',
        liberacao: '',
        liberadoEstoque: '',
        tipo: ''
      })
    }
  }

  console.log(filters)

  const handleFilterBuscaChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value

    setFilters({
      ...filters,
      tipoBusca: value
    })
  }, [filters])

  const handleOnPageChange = useCallback((page: number) => {
    if (!pedidosFuncionario?.metadata?.count) {
      return
    }

    setCurrentPage(page)
    getListaPedidos(
      {
        perPage,
        currentPage: page + 1,
        filters,
        sortTable
      }
    )
  }, [filters, getListaPedidos, pedidosFuncionario, sortTable])

  const handleCallbackListaContatos = useCallback((contato: Contato) => {
    if (!pedidoSelecionado) {
      return
    }
    dispatch(iniciarAtendimento({
      botaoAtendimentoAtivo: 'pedidos',
      cliente: clienteComContatos as Cliente,
      contato,
      funcionario: funcionario?.data as Funcionario
    }))

    dispatch(abrirPedido({
      pedido: pedidoSelecionado?.data as GetPedido
    }))

    setPedidoIdSelecionado(0)
    setShowListaContatos(false)
  }, [clienteComContatos, dispatch, funcionario, pedidoSelecionado])

  const handleCallbackDesmembrarPedido = useCallback((pedido: GetPedido) => {
    if (!pedido) {
      return null
    }

    setPedidoEdicao(pedido)
  }, [])

  const handleCallbackListaFretes = useCallback((frete: GetPedidoCotacoesFretes) => {
    if (!frete) {
      return null
    }
    reloadList()
    setShowListaFretes(false)
  }, [reloadList])

  const handleOnSaveDesmembrarPedido = async () => {
    if (!pedidoEdicao) {
      return null
    }

    let total = 0

    pedidoEdicao.itens?.forEach(p => {
      total += p.quantidade
    })

    if (total < 1) {
      await Swal.fire({
        icon: 'warning',
        title: 'Atenção',
        text: 'Não é possível desmembrar todos os produtos.',
        showConfirmButton: true,
        showCancelButton: false
      })

      return
    }

    const refresh = () => {
      reloadList()
      setShowDesmembrarPedido(false)
    }

    const params = {
      body: {
        ...pedidoEdicao,
        id: pedidoEdicao.id
      }
    }

    const desmembrarPedido = makeDesmembrarPedido()
    const response = await executeUseCase<PostParams>(
      desmembrarPedido,
      params,
      '',
      'Deseja desmembrar o pedido?',
      () => { refresh() },
      false)

    if (response) {
      await Swal.fire({
        icon: 'success',
        title: 'Novo pedido',
        text: `Novo pedido salvo com o id ${response.novo_id}`,
        showConfirmButton: true,
        showCancelButton: false
      })
    }
  }

  const handleCallbackListaFretesManual = useCallback((pedido: GetPedido) => {
    if (!pedido) {
      return null
    }

    setPedidoEdicao(pedido)
  }, [])

  const handleCallbackSolicitacao = useCallback((solicitacao: any) => {
    if (!solicitacao) {
      return null
    }

    setSolicitacaoEdicao(solicitacao)
  }, [])

  const handleOnSaveFretesManual = async () => {
    if (!pedidoEdicao) {
      return null
    }

    const refresh = () => {
      reloadList()
      setShowListaFretesManual(false)
    }

    const params = {
      body: {
        ...pedidoEdicao,
        id: pedidoEdicao.id
      }
    }

    const atualizarpedido = makeAtualizarPedido()
    await executeUseCase<PostParams>(
      atualizarpedido,
      params,
      'Pedido Alterardo',
      'Salvar frete?',
      () => { refresh() })
  }

  const handleOnSaveSolicitacao = async () => {
    if (!solicitacaoEdicao) {
      return null
    }

    const refresh = () => {
      reloadList()
      setShowSolicitacoes(false)
    }

    const params = {
      body: {
        ...solicitacaoEdicao,
        id: pedidoIdEmEdicao
      }
    }

    await executeUseCase<PostParams>(
      makeNovaSolicitacao(),
      params,
      'Solicitação adicionada',
      'Deseja adicionar esta solicitação?',
      () => { refresh() })
  }

  const handleCloseListaContatos = useCallback(() => {
    setPedidoIdSelecionado(0)
    setShowListaContatos(false)
  }, [])

  const handleOnSortedChange = (header: any) => {
    const sort = {
      id: header[0].id,
      desc: header[0].desc
    }
    if (sort.id) {
      switch (sort.id) {
        case 'cliente':
          sort.id = 'clientes.nome_fantasia'
          break
        case 'transportadora':
          sort.id = 'transportadora_nome'
          break
        case 'empresa':
          sort.id = 'empresas.nome'
          break
        case 'deposito':
          sort.id = 'dep.nome'
          break
        case 'prazo':
          sort.id = 'pedidos.prazo_dias'
          break
        case 'liberado':
          sort.id = 'pedidos.liberado'
          break
        default:
          sort.id = 'pedidos.' + sort.id
          break
      }

      setSortTable(sort)
    }
  }

  return (
    <MainLayout title="Pedidos">
      {(executingAtualizaPedido || loadingGetPedido) && <Loading />}
      <h1>Pedidos</h1>
      <Container>
        <header>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Select
                width={'50px'}
                name='tipo'
                onChange={(e) => handleFilterBuscaChange(e)}
                margin='0'
                marginRight='15px'
                value={filters.tipoBusca}
              >
                <option value=''>Todos</option>
                <option value='cod_pedido'>Código do Pedido</option>
                <option value='cod_cliente'>Código do Cliente</option>
              </Select>
              <Input
                data-cy='inputSearchFiltroPedidos'
                type='text'
                name='search'
                startIcon={FaSearch}
                value={filters.search}
                onChange={handleOnChangeSearch}
                onKeyPress={handleKeyPress}
                placeholder=''
                // label='Pesquisa'
                width={'50px'}
                margin={'0'}
                marginRight={'15px'}
                maxLength={9}
              />
              <Button
                data-cy='buttonPesquisarPedidos'
                mode='primary'
                startIcon={FaSearch}
                onClick={handleSearch}
              > Pesquisar</Button>
            </div>
            <div>
              <Button
                data-cy='buttonFilterPedidos'
                mode='secondary'
                startIcon={BiFilter}
                onClick={handleFilterOpen}
              > Filtros</Button>
            </div>
          </div>
          {filterIsOpen && <div>
            <FiltroPedidos
              setFilters={setFilters}
              filters={filters}
              widthTotal='100%'
              handleEnterInput={handleSearch}
            />
          </div>}
        </header>
        <Table
          data-cy='tablePagePedidos'
          columns={columns}
          showPageJump={false}
          data={pedidosFuncionario?.data}
          pageSize={perPage}
          page={currentPage}
          pages={pedidosFuncionario?.metadata?.count && Math.ceil(pedidosFuncionario?.metadata?.count / perPage)}
          onPageChange={handleOnPageChange}
          manual
          loading={loadingListaPedido}
          sortable={true}
          onSortedChange={handleOnSortedChange}
          nextText="Próximo"
          previousText="Anterior"
          pageText="Página"
          ofText="de"
          showPageSizeOptions={false}
          noDataText="Nenhum pedido encontrado"
          showPagination={pedidosFuncionario?.metadata && pedidosFuncionario?.metadata?.count >= perPage}
          dbClickCallback={handleTableOnDbClick}
          selectRow
        />
      </Container>

      {showListaContatos && <Modal title="Selecione um contato para continuar" close={handleCloseListaContatos} >
        {loadingGetPedido || loadingCliente ? <Loading /> : ''}
        <ListaContatos cliente={clienteComContatos as Cliente} loadingCliente={false} callBack={handleCallbackListaContatos} />
      </Modal>}

      {showDesmembrarPedido && <Modal title="Desmembrar Pedido"
        close={() => setShowDesmembrarPedido(false)}
        buttonSaveText='Salvar'
        showButtonSave={true}
        closeOnSave={false}
        onSave={handleOnSaveDesmembrarPedido} >
        <DesmembrarPedido pedidoId={pedidoIdEmEdicao} callBack={handleCallbackDesmembrarPedido} />
      </Modal>}

      {showListaFretes && <Modal title="Fretes" close={() => setShowListaFretes(false)} >
        {loadingPedidoFretes ? <Loading /> : ''}
        <ListaFretes pedido={pedidoFretes} callBack={handleCallbackListaFretes} />
      </Modal>}

      {showListaFretesManual && <Modal
        title="Frete Manual"
        close={() => setShowListaFretesManual(false)}
        buttonSaveText='Salvar'
        showButtonSave={true}
        closeOnSave={false}
        onSave={handleOnSaveFretesManual}
      >
        <ListaFretesManual pedidoId={pedidoIdEmEdicao} callBack={handleCallbackListaFretesManual} />
      </Modal>}

      {showSolicitacoes && <Modal title="Solicitação"
        close={() => setShowSolicitacoes(false)}
        buttonSaveText='Salvar'
        showButtonSave={true}
        closeOnSave={false}
        onSave={handleOnSaveSolicitacao} >
        <NovaSolicitacao pedidoId={pedidoIdEmEdicao} callBack={handleCallbackSolicitacao} />
      </Modal>}

    </MainLayout>

  )
}
