import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { HttpRequest, GetHttpRequest, HttpRequestError, PostHttpRequest, GetHttpRequestResp } from '../../modules/_interfaces/httpRequest'

export class HttpRequestImpl implements HttpRequest {
  private readonly httpRequest: AxiosInstance

  constructor () {
    this.httpRequest = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL_API_VENDAS
    })
  }

  async get<T> (getHttpRequest: GetHttpRequest): Promise<GetHttpRequestResp<T>> {
    try {
      const { path, token, url, query } = getHttpRequest
      let config: AxiosRequestConfig = {} as AxiosRequestConfig

      if (token) {
        config = {
          headers: { Authorization: `Bearer ${token}` },
          timeout: 60000
        }
      }

      const response = await this.httpRequest.get(`${url || ''}${path}${query || ''}`, config)

      const result = {
        res: response?.data as T || response as unknown as T,
        status: response.status
      }

      return result
    } catch (error) {
      let result = {
        error: {
          message: '',
          status: 500
        }
      }
      if (error.response) {
        result = {
          error: {
            message: error.response?.data?.erros?.toString() || error.response.data.message || error.response.data.error || error.message,
            status: error.response?.status
          }
        }
      }
      return result
    }
  }

  async post<T> (postHttpRequest: PostHttpRequest): Promise<{
    data?: T
    status?: number
    error?: HttpRequestError
  }> {
    try {
      const { url, path, body, token } = postHttpRequest
      let config: AxiosRequestConfig = {} as AxiosRequestConfig

      if (token) {
        config = {
          headers: { Authorization: `Bearer ${token}` }
        }
      }

      const response = await this.httpRequest.post(`${url || ''}${path}`, body, config)
      const result = {
        data: response.data as T,
        status: response.status
      }

      return result
    } catch (error) {
      const message = (erro: any) => {
        let retorno = ''
        if (erro.errors) {
          retorno = erro.errors[0]
        } else if (erro.erros) {
          retorno = erro.erros[0]
        }
        return retorno
      }
      const result = {
        error: {
          data: error.response.data,
          type: error.response.data?.type,
          message: message(error.response.data),
          status: error.response?.status
        }
      }
      return result
    }
  }

  async put<T> (postHttpRequest: PostHttpRequest, isMultipart = false): Promise<{
    data?: T
    status?: number
    error?: HttpRequestError
  }> {
    try {
      let { url, path, body, token } = postHttpRequest
      let config: AxiosRequestConfig = {} as AxiosRequestConfig

      if (token) {
        config = {
          headers: { Authorization: `Bearer ${token}` }
        }
      }

      if (isMultipart) {
        let formData = new FormData()

        body && Object.keys(body).forEach((key) => {
          formData.append(key, body[key])
        })

        body = formData
      }
      const response = await this.httpRequest.put(`${url || ''}${path}`, body, config)

      const result = {
        data: response.data as T,
        status: response.status
      }

      return result
    } catch (error) {
      const message = (erro: any) => {
        let retorno = ''
        if (erro.errors) {
          retorno = erro.errors.toString()
        } else if (erro.erros) {
          retorno = erro.erros
        }
        return retorno
      }

      const result = {
        error: {
          data: error.response.data.dataError,
          type: error.response.data.type,
          message: message(error.response.data) || error.response.data.error,
          status: error.response?.status
        }
      }
      return result
    }
  }
}
