import { handleGetPedido } from '../../../helpers/handleGetPedido'
import { RecalcularParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { RecalcularPedidoService } from '../contracts'
import { GetPedidoResponse } from '../models'
import { GetPedidoApiResponse } from './_interfaces'

export class RecalcularPedidoServiceRest implements RecalcularPedidoService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async recalcularPedido (params: RecalcularParams): Promise<GetPedidoResponse> {
    let response = await this.httpRequest.post<GetPedidoApiResponse>({
      path: 'pedidos/recalcular',
      body: params.body,
      token: params.token
    })

    if (response.data) {
      response.data = handleGetPedido(params.mode, response.data)
    }
    return response as GetPedidoResponse
  }
}
