import { useState } from 'react'
import { makeTrazerPreco } from '../../../modules/produtos/factories'
import { GetPrecoParams, Preco } from '../../../modules/produtos/models'
import { useHandleErrors } from '../app/useHandleErrors'
import { useUsuario } from '../usuario/useUsuario'

const trazerPreco = makeTrazerPreco()

interface UsePrecoProdutoParams {
  produtoId: number
  clienteId: number
  revendaId?: number
  empresaId: number
  valor?: number
  usoeconsumo?: string
  solar: string
}

export function usePrecoProduto () {
  const { data: usuarioData } = useUsuario()
  const [data, setData] = useState<Preco>()
  const [loading, setLoading] = useState(false)
  const { handleError, executing } = useHandleErrors()

  async function fetcher (params: UsePrecoProdutoParams) {
    setLoading(true)

    const trazerPrecoParams = () => {
      let usePrecoProdutoParams: GetPrecoParams = {
        clienteId: params.clienteId,
        empresaId: params.empresaId,
        produtoId: params.produtoId,
        solar: params.solar,
        token: String(usuarioData?.token)
      }
      if (params.valor) {
        usePrecoProdutoParams.valor = params.valor
      }
      if (params.usoeconsumo) {
        usePrecoProdutoParams.usoeconsumo = params.usoeconsumo
      }
      if (params.revendaId) {
        usePrecoProdutoParams.revendaId = params.revendaId
      }

      return usePrecoProdutoParams
    }

    const p = trazerPrecoParams()
    const response = await trazerPreco.execute(p)

    if (response) {
      if (response.data) {
        setData(response.data)
        setLoading(false)
      }
      if (response.error && !executing) {
        handleError(response.error)
      }
    } else {
      handleError({ status: 400, message: 'Ocorreu um erro ao trazer o preço, por favor tente novamente.' })
    }

    setLoading(false)
  }

  return { fetcher, data, loading }
}
