import React from 'react'
import { SideBarProvider, UsuarioProvider, MainTabsProvider, ThemeNameProvider } from '.'
import { ModalProvider } from './modalContext'
import { TabCadastroClienteProvider } from './tabCadastroClienteContext'

export const Providers: React.FC = ({ children }) => {
  return (
    <ThemeNameProvider>
      <ModalProvider>
        <UsuarioProvider>
          <TabCadastroClienteProvider>
            <SideBarProvider>
              <MainTabsProvider>
                {children}
              </MainTabsProvider>
            </SideBarProvider>
          </TabCadastroClienteProvider>
        </UsuarioProvider>
      </ModalProvider>
    </ThemeNameProvider>
  )
}
