import { formatStringDate } from '../../../helpers'
import { GetParams, UseCase } from '../../_interfaces'
import { ListarChamadosService } from '../contracts'
import { GetChamadosListResponse } from '../models'

export class ListarChamados implements UseCase<GetParams, GetChamadosListResponse> {
  private readonly listarChamadosService: ListarChamadosService

  constructor (listarChamadosService: ListarChamadosService) {
    this.listarChamadosService = listarChamadosService
  }

  async execute (params: GetParams): Promise<GetChamadosListResponse> {
    let retorno = await this.listarChamadosService.listarChamados(params)

    retorno.data.map(item => {
      item.data = formatStringDate(item.data as string, 'xxxxxxxx', 'completo')
      return item
    })

    return retorno
  }
}
