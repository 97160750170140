import { PostParams, UseCase, Validator } from '../../_interfaces'
import { CadastrarPedidoService } from '../contracts'
import { GetPedido, Orcamento } from '../models'
import { sanetizarPedidoCadastro } from '../services/helpers'

export class CadastrarPedido implements UseCase<PostParams, Orcamento> {
  private readonly cadastrarPedidoService: CadastrarPedidoService
  private readonly validator: Validator

  constructor (cadastrarPedidoService: CadastrarPedidoService, validator: Validator) {
    this.cadastrarPedidoService = cadastrarPedidoService
    this.validator = validator
  }

  async execute (params: PostParams): Promise<Orcamento> {
    const sanetized = sanetizarPedidoCadastro(params.body as GetPedido)
    await this.validator.validate(sanetized)

    return await this.cadastrarPedidoService.cadastrarPedido({
      body: sanetized,
      token: params.token
    })
  }
}
