import React from 'react'
import { MainLayout } from '../../layouts/MainLayout'
import { DadosCliente } from '../DadosCliente'

export const CadastroCliente = () => {
  return (
    <MainLayout title={'Cadastro Cliente'}>
      <h1>Cadastro Cliente</h1>
      <DadosCliente mode='registro'/>
    </MainLayout>
  )
}
