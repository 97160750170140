import { TrazerClientePorIdParams, TrazerClientePorIdService } from '../contracts'
import { GetOneClienteResponse } from '../models'
import { format } from 'date-fns'

export class TrazerClientePorId {
  private readonly trazerClientePorIdService: TrazerClientePorIdService

  constructor (TrazerClientePorIdService: TrazerClientePorIdService) {
    this.trazerClientePorIdService = TrazerClientePorIdService
  }

  async execute (params: TrazerClientePorIdParams): Promise<GetOneClienteResponse> {
    if (!params?.id) {
      return {} as GetOneClienteResponse
    }

    let response = await this.trazerClientePorIdService.trazerClientePorId(params)

    if (response?.data) {
      // ordena os fechamentos e pega os 13 primeiros registros
      if (response?.data.fechamentos && response?.data.fechamentos?.length > 0) {
        let fechamentos = response.data.fechamentos.reverse()
        if (fechamentos) {
          const mesAtual = parseInt(format(new Date(), 'MM'))
          const anoAtual = parseInt(format(new Date(), 'yyyy'))

          let totalMesAtual = 0
          if (fechamentos[0]?.mes === mesAtual && fechamentos[0]?.ano === anoAtual) {
            totalMesAtual = fechamentos[0].total
          }

          response.data.vendaMesAtual = totalMesAtual
          fechamentos = fechamentos.slice(0, 5)
          response.data.fechamentos = fechamentos.reverse()
        }
      }
      // verifica se é pj ou pf - variavel pj e de uso interno do front
      if (response?.data.cnpj && !response.data.pj) {
        if (response.data.cnpj.length >= 14) {
          response.data.pj = true
        } else {
          response.data.pj = false
        }
      }
      if (response.data.limite && response.data.limite > 0) {
        let limite_utilizado = 0
        if (response.data.crs_em_aberto && response.data.crs_em_aberto > 0) {
          limite_utilizado = limite_utilizado + response.data.crs_em_aberto
        }
        if (response.data.cheques_em_abertos && response.data.cheques_em_abertos > 0) {
          limite_utilizado = limite_utilizado + response.data.cheques_em_abertos
        }
        if (response.data.total_pedidos_limite_desbloqueado && response.data.total_pedidos_limite_desbloqueado > 0) {
          limite_utilizado = limite_utilizado + response.data.total_pedidos_limite_desbloqueado
        }
        response.data.limite_utilizado = limite_utilizado
        response.data.saldo_limite = limite_utilizado > 0 ? response.data.limite - limite_utilizado : response.data.limite
      }
    }
    return response
  }
}
