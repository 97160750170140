import styled from 'styled-components'
import { ModalMode } from '.'
import { fadein } from '../../styles/animations'

interface ContainerProps {
  showBackground: boolean
}

export const Container = styled.div<ContainerProps>`
  top: 0;
  left: 0;
  position: fixed;
  z-index: 999;
  background-color: ${props => props.showBackground ? 'rgba(0,0,0,.4)' : 'none'};
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadein} 0.3s forwards;
`
interface BoxModalProps {
  mode?: ModalMode
}

export const BoxModal = styled.div<BoxModalProps>`
  border-radius: 4px;
  width: ${props => {
    switch (props.mode) {
      case 'fullscreen':
        return '70%'
      case 'wide':
        return '70%'
      case 'mini':
        return '350px'
      case 'normal':
        return '720px'
      default:
        return '720px'
    }
  }} ;
  min-height: 400px;
  height: ${props => {
    if (props.mode === 'fullscreen') {
      return '95%'
    } else if (props.mode === 'wide') {
      return '80%'
    } else if (props.mode === 'mini') {
      return 'fit-content'
    }
  }} ;
  max-height: 95%;
  position: relative;
  background-color: ${props => props.theme.colors.backgroundLight};
  position: relative;
  padding: 0px 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  & > div:nth-of-type(3) {
    overflow: hidden;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
`

export const Header = styled.div<BoxModalProps>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: ${props => {
    if (props.mode === 'mini') {
      return '10%'
    } else if (props.mode === 'fullscreen') {
      return '5%'
    } else {
      return '7%'
    }
  }};
  border-bottom: 1px solid ${props => props.theme.colors.borderLight};
  padding-bottom: 10px;
  margin-bottom: 10px;
  >h1 {
    font-size: 18px;
  }
  >button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    >svg {
      width: 24px;
      height: auto;
      color: ${props => props.theme.colors.primaryText} !important;
      :hover {
        transform: scale(1.2);
      }
    }
  }
`

export const Content = styled.div`
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  padding: 15px 7px 15px 0px;
  color: ${props => props.theme.colors.primaryText};
  background: ${props => props.theme.colors.backgroundLight};
`

export const BodyModal = styled.div<BoxModalProps>`
  height: ${props => {
    if (props.mode === 'mini') {
      return '75%'
    } else if (props.mode === 'fullscreen') {
      return '85%'
    } else {
      return '80%'
    }
  }};
  overflow: auto;
`

export const ContainerFooter = styled.div<BoxModalProps>`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  height: ${props => {
    if (props.mode === 'mini') {
      return '15%'
    } else if (props.mode === 'fullscreen') {
      return '7%'
    } else {
      return '10%'
    }
  }};
  width: 100%;
  padding-top: 10px;
  margin-top: 5px;
  border-top: 1px solid ${props => props.theme.colors.borderLight};
  > button + button {
    margin-left: 10px;

  }
`
