import { GetParams, UseCase } from '../../_interfaces'
import { TrazerFuncionarioPorIdService } from '../contracts'
import { GetFuncionario } from '../models/getFuncionario'

export class TrazerFuncionarioPorId implements UseCase<GetParams, GetFuncionario> {
  private readonly trazerFuncionarioPorIdService: TrazerFuncionarioPorIdService

  constructor (trazerFuncionarioPorIdService: TrazerFuncionarioPorIdService) {
    this.trazerFuncionarioPorIdService = trazerFuncionarioPorIdService
  }

  async execute (params: GetParams): Promise<GetFuncionario> {
    const response = await this.trazerFuncionarioPorIdService.trazerFuncionarioPorId(params)
    return response
  }
}
