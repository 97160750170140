import { useCallback, useEffect, useState } from 'react'
import { useUsuario } from '..'
import { makeTrazerProdutoPorId } from '../../../modules/produtos/factories'
import { GetProdutosPorIdResponse } from '../../../modules/produtos/models'
import { useHandleErrors } from '../app/useHandleErrors'

export function useProduto (produtoId?: number) {
  const { data: usuarioData } = useUsuario()
  const [loading, setLoading] = useState(false)
  const [isReloading, setIsReloading] = useState(false)
  const [data, setData] = useState<GetProdutosPorIdResponse>()
  const { handleError, executing } = useHandleErrors()

  useEffect(() => {
    async function fetcher () {
      setLoading(true)
      const trazerProdutoPorId = makeTrazerProdutoPorId()

      const response = await trazerProdutoPorId.execute({
        produtoId: produtoId as number,
        token: usuarioData?.token as string
      })

      setData(response)
      setLoading(false)
      if (response.error && !executing) {
        handleError(response.error)
      }
    }

    if (produtoId) {
      fetcher()
    }
  }, [executing, handleError, produtoId, usuarioData])

  const reload = useCallback(() => {
    setIsReloading(true)
  }, [])

  return { data, reload, loading, isReloading }
}
