import { GetParams } from '../../_interfaces'
import { TrazerResumoClienteService } from '../contracts'
import { GetResumoResponse } from '../models'

export class TrazerResumoCliente {
  private readonly trazerResumoClienteService: TrazerResumoClienteService

  constructor (trazerResumoClienteService: TrazerResumoClienteService) {
    this.trazerResumoClienteService = trazerResumoClienteService
  }

  async execute (params: GetParams): Promise<GetResumoResponse> {
    const response = await this.trazerResumoClienteService.trazerResumoCliente(params)

    return response
  }
}
