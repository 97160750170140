import { HttpRequest } from '../../_interfaces/httpRequest'
import { TrazerPrecoService } from '../contracts/trazer-preco'
import { GetPrecoParams, GetPrecoProdutoResponse } from '../models'
import { TrazerPrecoRespApi } from './_interfaces'

export class TrazerPrecoServiceRest implements TrazerPrecoService {
  private readonly http: HttpRequest

  constructor (http: HttpRequest) {
    this.http = http
  }

  async trazerPreco (params: GetPrecoParams): Promise<GetPrecoProdutoResponse> {
    let query = `?cliente_id=${params.clienteId}&empresa_id=${params.empresaId}`

    if (params.valor) {
      query += `&valor=${params.valor}`
    }

    if (params.usoeconsumo) {
      query += `&usoeconsumo=${params.usoeconsumo}`
    }

    if (params.solar) {
      query += `&solar=${params.solar}`
    }

    // if (params.revendaId) {
    //   query += `&cliente_revenda_id=${params.revendaId}`
    // }

    const response = await this.http.get<TrazerPrecoRespApi>({
      token: params.token,
      path: `produtos/${params.produtoId}/preco`,
      query
    })

    return response?.res as GetPrecoProdutoResponse
  }
}
