import { HttpRequestImpl } from '../../../infra/http'
import { SchemaValidator } from '../../../infra/validator/schemaValidator'
import { DesmembrarPedidoServiceRest } from '../services'
import { DesmembrarPedido } from '../useCases'
import { PedidoEmEdicaoValidator, updateSchema } from '../validators'

export function makeDesmembrarPedido (): DesmembrarPedido {
  const httpRequest = new HttpRequestImpl()
  const schemaValidator = new SchemaValidator(updateSchema)
  const validator = new PedidoEmEdicaoValidator(schemaValidator)
  const desmembrarPedidoService = new DesmembrarPedidoServiceRest(httpRequest)

  return new DesmembrarPedido(desmembrarPedidoService, validator)
}
