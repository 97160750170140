import { useContext } from 'react'
import { ThemeNameContextProps, ThemeNameContext } from '../../contexts'

export const useThemeName = (): ThemeNameContextProps => {
  const context = useContext(ThemeNameContext)

  if (!context) {
    throw new Error('useThemeName deve ser usado com AtendimentoTabsProvider')
  }

  return context
}
