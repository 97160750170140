
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaUser, FaClipboardList, FaClipboardCheck, FaClipboard, FaInfo, FaDollarSign } from 'react-icons/fa'
import { Input, ListaContatos, MainChart, Card, ProgressBar, ListaGrupoCliente } from '..'
import { Cliente, Contato } from '../../../modules/clientes/models'
import { formatFloatToCurrency } from '../../../helpers'
import { useCliente, useResumoCliente } from '../../hooks'
import { Container, CardGroups, HeaderAtendimento, Wrapper, HeaderAtendimentoDiv } from './styles'
import { AtendimentosState } from '../../store/atendimentos/interfaces'
import { AppState } from '../../store'
import capitalize from 'capitalize-pt-br'
import { alterarContatoAtendimento } from '../../store/atendimentos/actions'

interface GeralProps {
  cliente: Cliente
}

interface ChartValue {
  valor1?: number
  valor2?: number
  subtitle: string
}

interface ChartsProps {
  legendas: {
    valor1: number
    valor2?: number
  }
  valores: ChartValue[]
}

export const Geral = ({ cliente }: GeralProps) => {
  const atendimentosState = useSelector<AppState>(state => state.atendimentos) as AtendimentosState
  const [chartValues, setChartValues] = useState({} as ChartsProps)
  const dispatch = useDispatch()
  const { fetcher: getClientePorId, data: clienteData, loading: loadingCliente } = useCliente()
  const { data: resumoCliente } = useResumoCliente(cliente.id as number)

  const handleContatoF2CallBack = useCallback((contato: Contato) => {
    dispatch(alterarContatoAtendimento({ clienteId: Number(cliente.id), contato }))
  }, [cliente.id, dispatch])

  const handleContatoIdOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let [contato] = clienteData?.contatos?.filter(cont => cont.id === parseInt(e.target.value)) as Contato[]

    if (!contato?.id) {
      contato = {
        nome: ''
      }
    }

    dispatch(alterarContatoAtendimento({ clienteId: Number(cliente.id), contato }))
  }, [cliente.id, clienteData, dispatch])

  useEffect(() => {
    getClientePorId({
      clienteId: cliente?.id as number,
      revenda: false
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (clienteData) {
      const fechamentos = clienteData.fechamentos

      if (fechamentos) {
        const legendas = [...new Set(fechamentos.map(item => item.ano))]
        let chartValores = [] as ChartValue[]
        fechamentos.forEach(element => {
          chartValores.push({
            valor1: element.total,
            subtitle: element.mes + '/' + element.ano
          })
        })
        if (legendas.length > 0 && chartValores.length > 0) {
          setChartValues((oldState: ChartsProps) => {
            const newState = {
              ...oldState,
              legendas: {
                valor1: legendas[0] ? legendas[0] : 0
                // valor2: legendas[1] ? legendas[1] : 0
              },
              valores: chartValores
            }
            return newState
          })
        }
      }
    }
  }, [clienteData])

  return (
    <Wrapper>
      <HeaderAtendimento>
        <HeaderAtendimentoDiv>
          <span>Contato</span>
          <div>
            <Input
              width='1'
              name="contato.id"
              title="Digite o código do contato ou F2 para buscar"
              label="Código"
              value={atendimentosState.data[cliente?.id as number]?.contato?.id}
              placeholder='Cod. contato'
              type="text"
              f2Title="Lista de contatos"
              f2Content={
                <ListaContatos cliente={clienteData as Cliente} loadingCliente={loadingCliente} />
              }

              f2CallBack={handleContatoF2CallBack}
              onChange={handleContatoIdOnChange}
            />
            <Input
              width='4'
              name="contato.nome"
              label="Nome"
              title="Nome Contato"
              value={capitalize(String(atendimentosState.data[cliente?.id as number]?.contato?.nome))}
              placeholder='Nome do Contato'
              disabled
            />
          </div>
        </HeaderAtendimentoDiv>
        <HeaderAtendimentoDiv>
          <span>Fidelização</span>
          <div>
            <Input
              width='1'
              name="fidelizado.empresa"
              label="Empresa"
              title="Empresa"
              value={capitalize(cliente?.empresa_fidelizada_nome as string)}
              placeholder='Empresa fidelizada'
              disabled
            />
            <Input
              width='1'
              name="fidelizado.equipe"
              label="Equipe"
              title="Equipe"
              value={`${cliente?.equipe_fidelizada} - ${capitalize(cliente?.equipe_fidelizada_nome as string)}`}
              placeholder='Equipe fidelizada'
              disabled
            />
            <Input
              width='1'
              name="fidelizado.funcionario"
              label="Funcionario"
              title="Funcionario"
              value={`${cliente?.funcionario_fidelizado} - ${capitalize(cliente?.funcionario_fidelizado_nome as string)}`}
              placeholder='Funcionario fidelizada'
              disabled
            />
          </div>
        </HeaderAtendimentoDiv>
      </HeaderAtendimento>
      <Container>
        <CardGroups>
          <Card
            width='100%'
            title="Status"
            icon={FaUser}
            text={clienteData?.status ? capitalize(clienteData?.status) : '--'}
            cor="info"
          />
          <Card
            width='100%'
            title="Classe"
            icon={FaInfo}
            text={clienteData?.classe ? capitalize(clienteData?.classe) : '--'}
            cor="warning"
          />
          <Card
            width='100%'
            title="Vendas do mês"
            icon={FaDollarSign}
            text={clienteData?.vendaMesAtual
              ? `R$ ${formatFloatToCurrency(clienteData?.vendaMesAtual)}`
              : 'R$ 0,00'
            }
            cor="success"
          />

          <Card
            width='100%'
            title="PEDIDOS"
            icon={FaClipboard}
            text={resumoCliente?.data?.pedidos.total ? resumoCliente?.data?.pedidos.total : 0}
            cor="info"
          />

          <Card
            width='100%'
            title="PEDIDOS EM ABERTO"
            icon={FaClipboardList}
            text={resumoCliente?.data?.pedidos.emAberto ? resumoCliente?.data?.pedidos.emAberto : 0}
            cor="warning"
          />

          <Card
            width='100%'
            title="PEDIDOS FATURADOS"
            icon={FaClipboardCheck}
            text={resumoCliente?.data?.pedidos.faturado ? resumoCliente?.data?.pedidos.faturado : 0}
            cor="success"
          />
        </CardGroups>
        <section>
          <ProgressBar
            label='Limite'
            current={clienteData?.limite_utilizado as number}
            total={clienteData?.limite as number}
          />
        </section>
        <section>
          <MainChart subtitles={chartValues.legendas} symbol="R$" valores={chartValues.valores} />
          <ListaGrupoCliente cliente={clienteData as Cliente} />
        </section>
      </Container>
    </Wrapper>
  )
}
