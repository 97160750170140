import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
`

export const Header = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  justify-content: flex-end;

  & > div {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
`
