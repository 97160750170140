import { GetParams } from '../../_interfaces'
import { ListarVendasFuncionariosService } from '../contracts'
import { GetListarVendasFuncionarios } from '../models/getListarVendasFuncionariosResponse'

export interface UseCase<P = any, R = any> {
  execute: (funcionario_id: number, params: P) => Promise<R>
}

export class ListarVendasFuncionario implements UseCase<GetParams, GetListarVendasFuncionarios> {
  private readonly listarVendasFuncionarioService: ListarVendasFuncionariosService

  constructor (listarVendasFuncionarioService: ListarVendasFuncionariosService) {
    this.listarVendasFuncionarioService = listarVendasFuncionarioService
  }

  async execute (funcionario_id: number, params: GetParams): Promise<GetListarVendasFuncionarios> {
    const response = await this.listarVendasFuncionarioService.listarVendasFuncionarios(funcionario_id, params)
    return response
  }
}
