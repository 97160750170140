
import { HttpRequest, PostParams } from '../../_interfaces'
import { ProcessarFreteService } from '../contracts'
import { GetPedido, GetPedidoResponse } from '../models'

export class ProcessarFreteServiceRest implements ProcessarFreteService {
  private readonly http: HttpRequest

  constructor (http: HttpRequest) {
    this.http = http
  }

  async processarFretePedido (params: PostParams<any>): Promise<GetPedido> {
    const { pedido_id } = params.body

    const response = await this.http.post<GetPedidoResponse>({
      path: `pedidos/${pedido_id}/processar_frete`,
      token: params.token,
      body: params.body
    })

    return response as GetPedido
  }
}
