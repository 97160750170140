import { HttpRequest } from '../../_interfaces/httpRequest'
import { TrazerClientePorIdParams, TrazerClientePorIdService } from '../contracts'
import { GetOneClienteResponse } from '../models'
import { TrazerClienteRespApi } from './_interfaces'

export class TrazerClientePorIdServiceRest implements TrazerClientePorIdService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async trazerClientePorId (params: TrazerClientePorIdParams): Promise<GetOneClienteResponse> {
    let { id, token } = params

    let response = await this.httpRequest.get<TrazerClienteRespApi>({
      path: `clientes/${id}`,
      token
    })

    const retorno = {
      data: response.res?.data,
      metadata: response.res?.metadata,
      error: response.error
    } as GetOneClienteResponse

    return retorno
  }
}
