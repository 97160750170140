/* eslint-disable no-useless-return */
/* eslint-disable no-case-declarations */
import { Reducer } from 'redux'
import { ProdutosActions } from './actions'

import { ProdutosState } from './interfaces'

const INITIAL_STATE: ProdutosState = {
  pesquisa: ''
}

const reducer: Reducer<ProdutosState | undefined> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProdutosActions.SET_PESQUISA_PRODUTO:
      const pesquisa = action.payload as string

      state = {
        ...state,
        pesquisa
      }
      return state

    default:
      return state
  }
}

export default reducer
