import { HttpRequestImpl } from '../../../infra/http'
import { SchemaValidator } from '../../../infra/validator/schemaValidator'
import { RecalcularPedidoServiceRest } from '../services'
import { RecalcularPedido } from '../useCases'
import { recalcSchema } from '../validators'

export function makeRecalcularPedido (): RecalcularPedido {
  const httpRequest = new HttpRequestImpl()
  const schemaValidator = new SchemaValidator(recalcSchema)
  const cadastrarPedidoService = new RecalcularPedidoServiceRest(httpRequest)

  return new RecalcularPedido(cadastrarPedidoService, schemaValidator)
}
