import { useState } from 'react'
import { makeUltimasSaidas } from '../../../modules/clientes/factories'
import { useUsuario } from '../usuario/useUsuario'
import { GetUltimasSaidasResponse } from '../../../modules/clientes/models'
import { useHandleErrors } from '../app/useHandleErrors'
import { SortTableProps } from '../../../modules/_interfaces/sortTable'
import { FilterUltimasSaidas } from '../../../modules/_interfaces/filtersProps'
import { GetUltimasSaidasParams } from '../../../modules/clientes/models'

interface FetcherProps {
  filters: FilterUltimasSaidas,
  sortTable?: SortTableProps,
  cliente_id: number
}

export function useUltimasSaidas () {
  const [data, setData] = useState<GetUltimasSaidasResponse>()
  /* const [error, setError] = useState<any>({}) */
  const { data: usuarioData } = useUsuario()
  const [loading, setLoading] = useState(false)
  const { executing, handleError } = useHandleErrors()

  async function fetcher ({ sortTable, cliente_id, filters }: FetcherProps) {
    setLoading(true)
    const ultimasSaidas = makeUltimasSaidas()
    let params: GetUltimasSaidasParams = {
      token: usuarioData?.token as string,
      filterOptions: {
        skip: 0,
        order: '-data_faturamento'
      },
      cliente_id
    }

    // if (sortTable && sortTable.id) {
    //   params.filterOptions = { ...params.filterOptions, order: sortTable.desc ? `-${sortTable.id}` : sortTable.id }
    // }

    if (filters) {
      if (filters.search) {
        params.filter = filters.search
      }

      if (filters.dataInicial && filters.dataFinal) {
        params.filterObject = { ...params.filterObject, 'data_faturamento[between]': `${filters.dataInicial}|${filters.dataFinal}` }
      }
    }
    const response = await ultimasSaidas.execute(params)
    setData(response)

    if (response.error && !executing) {
      handleError(response.error)
    }

    setLoading(false)
  }

  return { fetcher, data, loading }
}
