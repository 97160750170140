import { GetParams, UseCase } from '../../_interfaces'
import { TrazerEmpresaPorIdService } from '../contracts'
import { GetOneEmpresaResponse } from '../models'

export class TrazerEmpresaPorId implements UseCase<GetParams, GetOneEmpresaResponse> {
  private readonly trazerEmpresaPorIdService: TrazerEmpresaPorIdService

  constructor (trazerEmpresaPorIdService: TrazerEmpresaPorIdService) {
    this.trazerEmpresaPorIdService = trazerEmpresaPorIdService
  }

  async execute (params: GetParams): Promise<GetOneEmpresaResponse> {
    if (!params.filter) {
      return {} as GetOneEmpresaResponse
    }
    const response = await this.trazerEmpresaPorIdService.trazerEmpresaPorId(params)
    return response
  }
}
