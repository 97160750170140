import { useHistory } from 'react-router-dom'
import useSWR from 'swr'
import { useUsuario } from '..'
import { makeListarContatosCliente } from '../../../modules/clientes/factories'

const listarContatosCliente = makeListarContatosCliente()

export function useListaContatos (clienteId: number) {
  const { data: usuarioData } = useUsuario()
  const history = useHistory()

  const { data, error } = useSWR(JSON.stringify({
    useCase: 'useContato',
    clienteId
  }), () => listarContatosCliente.execute(
    {
      filter: clienteId as unknown as string,
      token: usuarioData?.token
    }
  ))

  if (error?.type === 'auth') {
    history.push('/login')
  }

  return { data, error }
}
