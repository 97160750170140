import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import Swal from 'sweetalert2'
import { FaEnvelope, FaLock } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { Input, Button } from '../../components'
import Logo from '../../assets/imgs/logo_brasao.png'
import { Container, Inputs, RememberMe, Wrapper } from './styles'
import { useThemeName, useUsuario } from '../../hooks'
import { useDispatch, useSelector } from 'react-redux'
import { setarInitialState } from '../../store/atendimentos/actions'
import { setarPesquisaProduto } from '../../store/produtos/actions'
import { Usuario } from '../../../modules/usuarios/models/usuario'
import { AppState } from '../../store'
import { UsuariosState } from '../../store/usuarios/interfaces'
import { setarUser, setarUserId } from '../../store/usuarios/actions'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '../../styles/global'

export const Login = () => {
  const dispatch = useDispatch()
  const usuarioState = useSelector<AppState>(state => state.usuarios) as UsuariosState
  const [email, setEmail] = useState('')
  const [error, setError] = useState<any>({})
  const [password, setPassword] = useState('')
  const [autoLogin, setAutoLogin] = useState<boolean>(true)

  const [requesting, setRequesting] = useState(false)
  const routerHistory = useHistory()
  const { login } = useUsuario()

  const onSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      setError({})
      setRequesting(true)

      try {
        const loginParams = {
          email: email,
          password: password,
          manterConectado: autoLogin
        }
        const response: Usuario = await login(loginParams)
        if (response.id !== usuarioState.user_id) {
          dispatch(setarInitialState())
          dispatch(setarPesquisaProduto(''))
        }
        dispatch(setarUser(response))
        dispatch(setarUserId(response.id))
        routerHistory.push('/')
      } catch (error) {
        if (error.type === 'validate') {
          setError(error?.data)
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: error.message || 'Erro desconhecido',
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true
          })
        }
      } finally {
        setRequesting(false)
      }
    }, [autoLogin, dispatch, email, login, password, routerHistory, usuarioState]
  )

  const handleCheckOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    /* Cookies.set(`${process.env.REACT_APP_NAME as string}-autoLogin`, JSON.stringify(event.target.checked), jsCookieConfig()) */
    setAutoLogin(event.target.checked)
  }, [])

  const handleInputOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setError({})

    if (event.target.name === 'email') {
      setEmail(event.target.value)
    } else if (event.target.name === 'password') {
      setPassword(event.target.value)
    }
  }, [])

  const { theme } = useThemeName()

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Wrapper>
        <Container>
          <form action="submit" onSubmit={onSubmit}>
            <div>
              <img src={Logo} alt="" />
              <span>Digite seu e-mail e senha</span>
            </div>
            <Inputs>
              <Input
                data-cy="loginEmailInput"
                name="email"
                value={email}
                onChange={handleInputOnChange}
                startIcon={FaEnvelope}
                label="E-mail"
                placeholder="E-mail"
                error={error?.email}
                type="email"
              />
              <Input
                data-cy="loginPasswordInput"
                name="password"
                type="password"
                value={password}
                onChange={handleInputOnChange}
                startIcon={FaLock}
                label="Senha"
                placeholder="Senha"
                error={error?.password}
              />
              <RememberMe>
                <input
                  type="checkbox"
                  checked={autoLogin}
                  onChange={handleCheckOnChange}
                />
                <span>Manter conectado</span>
              </RememberMe>
              {/*  <SenhaRegistroDiv>
              <Link to="/esqueceu-senha">Esqueceu a senha? </Link>
              <Link to="/registro">Registre-se </Link>
            </SenhaRegistroDiv> */}
            </Inputs>
            <Button type="submit" showSpinner={requesting} mode="confirm">
              Entrar
            </Button>
          </form>
        </Container>
      </Wrapper>
    </ThemeProvider>
  )
}
