import { generateSearchQuery } from '../../../helpers'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { ListarClientesService } from '../contracts/listar-clientes-service'
import { GetClientesFuncionarioResponse } from '../models'
import { ListarClientesFuncionarioParams } from '../_interfaces/listClienteFuncParams'
import { ListarClientesApiResp } from './_interfaces'

export class ListarClientesServiceRest implements ListarClientesService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async listarClientes (params: ListarClientesFuncionarioParams): Promise<GetClientesFuncionarioResponse> {
    const query = generateSearchQuery(params.filter, params.filterOptions, params.filterObject)

    const response = await this.httpRequest.get<ListarClientesApiResp>({
      path: `funcionarios/${params.funcionarioId}/clientes`,
      query,
      token: params.token
    })

    const retorno = {
      data: response?.res?.data,
      metadata: response.res?.metadata,
      error: response.error
    } as GetClientesFuncionarioResponse

    return retorno
  }
}
