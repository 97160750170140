import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`
export const Container = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  padding: 20px 20px 28px;
  background-color: ${props => props.theme.colors.backgroundLight};
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.borderLight};
`

export const DivHeader = styled.div`
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  margin-bottom: 10px;
  >img{
    width: 70px;
    height: auto;
  }
  >span{
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: 0.1em;
    color: ${props => props.theme.colors.primaryText};
  }
`

export const DivSubmit = styled.div`
  display:flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 10px;
`
