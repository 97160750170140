import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

interface WrapperProps {
  width?: string
  marginRight?: string
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex: ${props => props.width ? props.width : '1'};
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0px;
  margin-right: ${props => props.marginRight ? props.marginRight : '0'};

  @media (max-width: 720px) {
    & {
      width: 100%;
    }
  }
`

interface InputContainerProps {
  isActive: boolean
  error?: string
  disabled?: boolean
  height?: string
}

export const Container = styled.div<InputContainerProps>`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: ${props => props.height ? props.height : '55px'};
  background: ${props => props.theme.colors.backgroundLight};
  border-radius: 4px;
  ${props => {
    let color
    let borderColor = 'transparent'

    if (props.error) {
      color = props.theme.colors.danger
      borderColor = transparentize(0.9, props.theme.colors.danger)
    } else if (props.isActive) {
      color = props.theme.colors.info
      borderColor = transparentize(0.7, props.theme.colors.info)
    } else {
      color = props.theme.colors.border
    }

    return css`
      border: 1px solid ${color};
      box-shadow: inset 0px 0px 3px ${borderColor};
    `
  }};

  >textarea {
    display: flex;
    flex: 1;
    height: 100%;
    border: none;
    background-color: ${props => props.disabled ? props.theme.colors.background : props.theme.colors.backgroundLight};
    border-radius: 4px;
    padding: 10px;
    resize: none;
    overflow: auto;
    font-family: inherit;
    font-size: inherit;
    white-space: pre-wrap;
    color: ${props => props.theme.colors.primaryText};
  }

`
interface LabelProps {
  isActive: boolean
}

export const Label = styled.span<LabelProps>`
  display: flex;
  color: ${props => props.theme.colors.primaryText} !important;
  opacity: ${props => props.isActive ? '1' : '0.5'};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
`
