import styled from 'styled-components'
import { fadein } from '../../styles/animations'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 500px;
  width: 100%;
  margin-right: 15px;
  animation: ${fadein} 0.6s forwards;

  header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    height: 45px;

    > div {
      display: flex;
      width: 300px;
    }

    button + button {
      margin-left: 7px;
    }
  }

  .ReactTable{
    margin-top: -1px;
  }
`

export const Content = styled.div`
  margin-top: -1px;
  border: 1px solid ${props => props.theme.colors.border};
  padding: 10px;
  border-radius: 4px;
  border-top-left-radius: 0px;
`

export const TabFixa = styled.div`
  margin-right: 30px;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
`

export const ResumoPedido = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`

export const HeaderTableCrs = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;

  >div{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-top: 15px;
  }
`
export const Filtros = styled.div`
  display: flex;
  width: 100%;
`
