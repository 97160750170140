import styled from 'styled-components'

interface WrapperProps {
  width?: string
  marginRight?: string
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex: ${props => props.width ? props.width : '1'};
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0px;
  margin-right: ${props => props.marginRight ? props.marginRight : '0'};
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: ${props => props.theme.colors.backgroundLight};
  border-radius: 4px;
  padding: 7px 0px;
  >input{
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  >span{
    font-size: 14px;
  }
`

export const Label = styled.span`
  display: flex;
  color: ${props => props.theme.colors.primaryText} !important;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
`
