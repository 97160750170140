import { TrazerDadosClienteReceitaService } from '../contracts'
import { GetDadosClienteCNPJResponse } from '../models'

export class TrazerDadosClienteReceita {
  private readonly trazerDadosReceita: TrazerDadosClienteReceitaService

  constructor (trazerDadosReceita: TrazerDadosClienteReceitaService) {
    this.trazerDadosReceita = trazerDadosReceita
  }

  async execute (token: string, cnpj: string): Promise<GetDadosClienteCNPJResponse> {
    const response = await this.trazerDadosReceita.trazerDadosReceitaCliente({
      filter: cnpj,
      token
    })
    return response
  }
}
