import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { ListarVendasFuncionariosRest } from '../services/listar-vendas-funcionarios'
import { ListarVendasFuncionario } from '../useCases'

export function makeListarVendasFuncionario (): ListarVendasFuncionario {
  const httpRequest = new HttpRequestImpl()
  const listarVendasFuncionarios = new ListarVendasFuncionariosRest(httpRequest)

  return new ListarVendasFuncionario(listarVendasFuncionarios)
}
