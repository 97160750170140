import { handleErrors } from '../../../helpers/handleErrors'
import { GetParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { ListarContatosClienteService } from '../contracts'
import { GetContatosResponse } from '../models'
import { ListarContatosRespApi } from './_interfaces'

export class ListarContatosClienteServiceRest implements ListarContatosClienteService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async listarContatosCliente (params: GetParams): Promise<GetContatosResponse> {
    let { filter: id, token } = params

    const response = await this.httpRequest.get<ListarContatosRespApi>({
      path: `clientes/${id}/contatos`,
      token
    })

    handleErrors(response?.error)
    return response?.res as GetContatosResponse
  }
}
