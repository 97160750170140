import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { TrazerCrsClienteServiceRest } from '../services'
import { TrazerCrsCliente } from '../useCases'

export function makeTrazerCrsCliente (): TrazerCrsCliente {
  const httpRequest = new HttpRequestImpl()
  const trazerCrsClienteService = new TrazerCrsClienteServiceRest(httpRequest)

  return new TrazerCrsCliente(trazerCrsClienteService)
}
