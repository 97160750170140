import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

interface DivButtonProps {
  width?: string
  marginLeft?: string
}

export const DivButton = styled.div<DivButtonProps>`
  display: flex;
  width: ${props => props.width ? props.width : '100%'};
  justify-content: center;
  margin: 10px 0px 10px 7px;
  padding-top: 26px;

  @media (max-width: 900px) {
    & {
      width: 100% !important;
      justify-content: flex-start;
      margin-top: 10px;
      margin-left: 0;
      padding: 0;
    }
  }
`

export const ButtonDiv = styled.div<DivButtonProps>`
  display: flex;
  margin: 10px 0px 10px 7px;
  margin-left: ${props => props.marginLeft ? props.marginLeft : '0'};
  padding-top: 16px;
  z-index: 10;
`
