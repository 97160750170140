import React, { useState } from 'react'
import capitalize from 'capitalize-pt-br'
import { FaList, FaPlus, FaTag, FaCopy, FaShoppingCart } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { ListaComprasProdutos, ListaEstoques, Modal, ModePedido } from '..'
import { formatFloatToCurrency } from '../../../helpers'
import { Cliente } from '../../../modules/clientes/models'
import { Produto } from '../../../modules/produtos/models'
import noImage from '../../assets/imgs/noImage.jpg'
import { useProduto } from '../../hooks'
import { AppState } from '../../store'
import { AtendimentosState } from '../../store/atendimentos/interfaces'
import { Button } from '../Button'

import { Container, Content, ActionsDiv, ImgDiv, InfoDiv, InfosDiv, InfoGroup, NameDiv } from './styles'

interface CardProdutosColumnsProps {
  data: Produto
  handleSelectProduto?: (produto: Produto) => void
  handleSelectPromocao?: (produto: Produto) => void
  handleGetProduto?: (id: number) => void
  mode: ModePedido
  pedidoIdEmEdicao?: number
  cardMode: string
  cliente?: Cliente
}

export const CardProdutosColumns = (props: CardProdutosColumnsProps) => {
  const atendimentosState = useSelector<AppState>(state => state.atendimentos) as AtendimentosState
  const [produtoIdGet, setProdutoIdGet] = useState<number>()
  const [showEstoque, setShowEstoque] = useState(false)
  const [showCompra, setShowCompra] = useState(false)
  const data = props?.data
  const usoeconsumo = props.cliente?.uso_consumo
  const preco = () => {
    let valor = 0
    if (data.preco) {
      if (data.preco.valor && data.preco.valor > 0) {
        valor = data.preco.valor
      }
      if (data.preco.st && usoeconsumo === 'n' && data.preco.st > 0) {
        valor = valor + data.preco.st
      }
      if (data.preco.ipi && data.preco.ipi > 0) {
        valor = valor + data.preco.ipi
      }
    }
    return formatFloatToCurrency(valor)
  }

  const { data: produtoIdGetData } = useProduto(produtoIdGet)

  const clienteIdEmFoco = atendimentosState.info.atendimentoClienteIdEmFoco
  let depositoId = 0

  if (clienteIdEmFoco) {
    if (props.mode === 'register') {
      depositoId = atendimentosState.data[clienteIdEmFoco].pedidoEmAndamento.pedido.deposito?.id as number
    } else {
      if (props.pedidoIdEmEdicao) {
        depositoId = atendimentosState.data[clienteIdEmFoco].pedidosEmAberto.data[props.pedidoIdEmEdicao].original.deposito?.id as number
      }
    }
  }

  const estoque = data.estoques?.find(item => {
    if (item.empresa.id === depositoId) {
      return true
    }
  })

  const estoqueDeposito = (estoque) ? estoque.fisico - estoque.retido : 0

  const estoqueGeral = (data.estoques && data.estoques?.length > 0) ? data.estoques.map(item => (item.fisico - item.retido)).reduce((prev, next) => prev + next) : 0

  const handleCopiarProduto = async () => {
    /* const trazerPreco = makeTrazerPreco()

    const params = {
      produtoId: data.id as number,
      clienteId: clienteIdEmFoco as number,
      empresaId: depositoId as number,
      token: usuarioData?.token as string
    }
    const preco = await trazerPreco.execute(params) */

    let text: string

    if (data.preco) {
      if (data && data.preco.st > 0 && usoeconsumo === 'n') {
        const total = data.preco.valor + data.preco.st
        text = `${data.id} - ${capitalize(data.nome_tecnico)} - R$: ${formatFloatToCurrency(total)}`
      } else {
        text = `${data.id} - ${capitalize(data.nome_tecnico)} - R$: ${formatFloatToCurrency(data.preco.valor)}`
      }

      navigator.clipboard.writeText(text)

      Swal.fire({
        icon: 'success',
        title: 'Copiado!',
        position: 'center'
      })
    }
  }

  const handlePromocao = (data: Produto) => {
    if (props.handleSelectPromocao) {
      props.handleSelectPromocao(data)
    }
  }

  const handleProduto = (data: Produto) => {
    if (props.handleSelectProduto) {
      props.handleSelectProduto(data)
    }
  }

  const handleProdutoCompras = () => {
    setProdutoIdGet(data.id)
    setShowCompra(true)
  }
  const handleCloseCompra = () => {
    setShowCompra(false)
    setProdutoIdGet(undefined)
  }

  return (
    <Container>
      {/* <BoxImg>
        <ImageZoom>
          <ImgDiv>
            <img src={data.urlImagem} onError={(e) => { e.currentTarget.src = noImage }} alt={data.nome_popular}></img>
          </ImgDiv>
        </ImageZoom>
      </BoxImg> */}
      <ImgDiv>
        <img src={data.urlImagem} onError={(e) => { e.currentTarget.src = noImage }} alt={data.nome_popular}></img>
      </ImgDiv>
      <Content>
        <NameDiv>
          <InfoDiv>
            <span title='Código'>Código: </span>
            <span>{data.id}</span>
          </InfoDiv>
          <InfoDiv>
            <span title='Nome Popular'>Nome Pop.: </span>
            <span>{capitalize(data.nome_popular)}</span>
          </InfoDiv>
          <InfoDiv>
            <span title='Nome Técnico'>Nome Téc.: </span>
            <span>{capitalize(data.nome_tecnico)}</span>
          </InfoDiv>
        </NameDiv>
        <InfosDiv>
          <InfoGroup>
            <InfoDiv width='30%'>
              <span>Marca: </span>
              <span>{capitalize(data.marca)}</span>
            </InfoDiv>
            <InfoDiv width='30%'>
              <span >Volume: </span>
              <span>{data.volume}</span>
            </InfoDiv>
          </InfoGroup>
          <InfoGroup>
            <InfoDiv width='30%'>
              <span title='Quantidade por Volume'>Qtde. Vol.: </span>
              <span>{data.qtde_volume}</span>
            </InfoDiv>
            <InfoDiv width='30%'>
              <span title='Unidade'>Unid.: </span>
              <span>{capitalize(data.unidade)}</span>
            </InfoDiv>
          </InfoGroup>
          <InfoGroup>
            <InfoDiv width='30%'>
              <span title='Unidade'>Preco: </span>
              <span>{preco()}</span>
            </InfoDiv>
            {props.cardMode === 'adicionar' && <InfoDiv width='40%'>
              <span title='Valor do Estoque selecionado / Estoque Geral'>Estoque:</span>
              <span>{`${estoqueDeposito} / ${estoqueGeral}`}</span>
            </InfoDiv>}
          </InfoGroup>
        </InfosDiv>
        {props.cardMode === 'adicionar'
          ? <ActionsDiv>
            <Button
              mode='success'
              type='button'
              startIcon={FaCopy}
              onClick={handleCopiarProduto}
            >Copiar</Button>
            {props.data.promocao && props.data.promocao.length > 0 &&
              <Button
                mode='danger'
                type='button'
                startIcon={FaTag}
                onClick={() => handlePromocao(data)}
              >Promoção</Button>
            }
            <Button
              mode='confirm'
              type='button'
              startIcon={FaPlus}
              onClick={() => handleProduto(data)}
            >Adicionar</Button>
            <Button
              mode='secondary'
              type='button'
              startIcon={FaShoppingCart}
              onClick={() => handleProdutoCompras()}
            >Compras</Button>
            <Button
              mode='secondary'
              type='button'
              startIcon={FaList}
              onClick={() => setShowEstoque(state => !state)}
            >Estoques</Button>
          </ActionsDiv>
          : <ActionsDiv>
            <Button
              mode='confirm'
              type='button'
              startIcon={FaPlus}
              onClick={() => handleProduto(data)}
            >Detalhes</Button>
          </ActionsDiv>
        }
        {showEstoque && <Modal mode='normal' close={() => setShowEstoque(state => !state)} title='Lista de Estoques'>
          <ListaEstoques estoques={data?.estoques} />
        </Modal>}
        {showCompra && (produtoIdGetData && produtoIdGetData.data) &&
          <Modal mode='normal' close={handleCloseCompra} title='Lista de Compras'>
            <ListaComprasProdutos compras={produtoIdGetData.data.compras} />
          </Modal>
        }
      </Content>
    </Container>
  )
}
