import React, { useEffect, useState } from 'react'
import { formatFloatToCurrency } from '../../../helpers'

import { Container, Bar, Charts, Content, CartesianPlane, Sections, Info, SubTitles, SquareSubtitle, SemFechamentos, Wrapper, ChartsHover } from './styles'

type Value = {
  valor1?: number,
  valor2?: number
  subtitle?: string
}

interface MainChartProps {
  valores?: Value[]
  symbol: string
  subtitles?: {
    valor1?: string | number
    valor2?: string | number
  }
}

export const MainChart = ({ valores, symbol, subtitles }: MainChartProps) => {
  const [indexYBase, setIndexYBase] = useState(0)
  const [difIndex, setDifIndex] = useState(0)

  useEffect(() => {
    let allValues = [] as number[]

    if (valores) {
      valores.forEach(({ valor1 }) => {
        if (valor1) {
          allValues.push(valor1)
        }
      })
    }

    allValues.sort((a, b) => a - b)
    let [max] = allValues.reverse()

    const calcIndexBase = () => {
      max = Math.round(max)
      while ((max % 6) > 0) {
        max += 1
      }
      return max
    }

    const indexBase = calcIndexBase()
    const dif = indexBase / 6

    setDifIndex(dif)
    setIndexYBase(indexBase)
  }, [valores])

  const handleHaveValues = () => {
    if (valores && valores?.length > 0) {
      return true
    } else {
      return false
    }
  }

  function numberToK (numero: number) {
    if (Math.abs(numero) > 999999) {
      const l = parseFloat(Math.abs(numero / 1000000).toFixed(1))
      return Math.sign(numero) * l + 'm'
    } else if (Math.abs(numero) <= 999999) {
      const l = parseFloat(Math.abs(numero / 1000).toFixed(1))
      return Math.sign(numero) * l + 'k'
    }
  }

  return (
    <Wrapper>
      <span>Histórico de Vendas</span>
      {handleHaveValues() ? <Container>
        {/* {subtitles && <SubTitles>
          <div>
            <SquareSubtitle color="chartOne" />
            <span>{subtitles.valor1}</span>
          </div>
          <div>
            <SquareSubtitle color="chartTwo" />
            <span>{subtitles.valor2}</span>
          </div>
        </SubTitles>} */}
        <Content>
          <CartesianPlane>
            <div>
              <h2>
                {symbol}
              </h2>
            </div>
            {indexYBase > 0 && <div><span>{numberToK(indexYBase)}</span></div>}
            {indexYBase > 0 && <div><span>{numberToK(indexYBase - difIndex)}</span></div>}
            {indexYBase > 0 && <div><span>{numberToK(indexYBase - (2 * difIndex))}</span></div>}
            {indexYBase > 0 && <div><span>{numberToK(indexYBase - (3 * difIndex))}</span></div>}
            {indexYBase > 0 && <div><span>{numberToK(indexYBase - (4 * difIndex))}</span></div>}
            {indexYBase > 0 && <div><span>{numberToK(indexYBase - (5 * difIndex))}</span></div>}
            <div><span>0</span></div>
          </CartesianPlane>
          <Sections>
            {valores?.map(({ valor1, subtitle }, index) => (
              <section key={index}>
                <Charts onMouseLeave={() => console.log('abriu')}>
                  {valor1 && <><Bar color={valor1 > 0 ? 'chartOne' : 'danger'} percent={(Math.abs(valor1) * 100) / indexYBase}>
                    <Info>{numberToK(valor1)}</Info>
                  </Bar>
                  <ChartsHover percent={(Math.abs(valor1) * 100) / indexYBase}>R$ {formatFloatToCurrency(valor1)}</ChartsHover>
                  </>}
                </Charts>
                <span>{subtitle}</span>
              </section>
            ))}
          </Sections>
        </Content>
      </Container>
        : <SemFechamentos><span>Não há fechamentos desse cliente nos últimos 13 meses</span></SemFechamentos>
      }
    </Wrapper>
  )
}
