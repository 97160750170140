import { generateSearchQuery } from '../../../helpers'
import { GetParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { ListarProdutosService } from '../contracts'
import { GetProdutosListResponse } from '../models'
import { ListarProdutosResApi } from './_interfaces'
export class ListarProdutosServiceRest implements ListarProdutosService {
  private readonly http: HttpRequest

  constructor (http: HttpRequest) {
    this.http = http
  }

  async listarProdutos (params: GetParams): Promise<GetProdutosListResponse> {
    let { filter, filterOptions, filterObject, token } = params
    const query = generateSearchQuery(filter, filterOptions, filterObject)

    const response = await this.http.get<ListarProdutosResApi>({
      path: 'produtos',
      token,
      query
    })

    const urlBase = process.env.REACT_APP_BASE_PRODUTO_IMG_URL as string
    const data = response.res?.data
    let retorno = {} as GetProdutosListResponse

    if (data) {
      const produtos = data?.map(produto => {
        let lotes = produto?.lotes?.map(lote => {
          const newState = {
            id: lote.id as number,
            produto_id: lote.produto_id,
            empresa_id: lote.empresa_id,
            lote: lote.lote,
            quantidade: lote.quantidade,
            created_at: '',
            updated_at: ''
          }
          return newState
        })
        lotes = lotes?.sort(function (a, b) {
          let x = a.id
          let y = b.id
          return ((x < y) ? -1 : ((x > y) ? 1 : 0))
        })
        return {
          ...produto,
          urlImagem: urlBase + produto?.id + 'A.jpg',
          lotes
        }
      })
      if (produtos) {
        retorno.data = produtos
      }
    }
    retorno.metadata = {
      count: response.res?.metadata?.count as number,
      skip: response.res?.metadata?.skip as number,
      limit: response.res?.metadata?.limit as number
    }

    return retorno as GetProdutosListResponse
  }
}
