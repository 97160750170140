import { useCallback, useState } from 'react'
import Swal from 'sweetalert2'
import { useUsuario } from '..'
import { AppErrorData } from '../../../helpers'
/* import Cookies from 'js-cookie' */

interface ValidateError {
  [key: string]: string
}

export function useExecUseCase () {
  const { data: usuarioData, login } = useUsuario()
  const [error, setError] = useState<AppErrorData>({} as AppErrorData)
  const [executing, setExecuting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [autoLogin] = useState<boolean>(true)

  const executeUseCase = useCallback(async<T> (
    useCase: any,
    paramsUseCase: T,
    sucessMessage: string,
    questionMessage: string,
    afterAll?: () => void,
    toAsk = true
  ) => {
    let questionResponse

    setSuccess(false)

    if (toAsk) {
      questionResponse = await Swal.fire({
        icon: 'question',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        text: questionMessage,
        showCloseButton: true,
        showLoaderOnConfirm: true,
        showCancelButton: true
      })
    }

    if (questionResponse?.isConfirmed || !toAsk) {
      try {
        setExecuting(true)
        const response = await useCase.execute({ ...paramsUseCase, token: usuarioData?.token as string })
        setExecuting(false)
        setSuccess(true)
        if (sucessMessage !== '') {
          Swal.fire({
            title: 'Sucesso!',
            text: sucessMessage || response.message,
            icon: 'success',
            timer: 2500,
            timerProgressBar: true
          })
        }
        afterAll && afterAll()

        return response
      } catch (error) {
        error.detail = useCase.constructor.name
        setExecuting(false)

        if (error.type === 'auth') {
          Swal.queue([{
            title: usuarioData?.email,
            confirmButtonText: 'Autenticar',
            cancelButtonText: 'Cancelar',
            text: 'Seu acesso ao sistema expirou! Por favor, informe sua senha novamente.',
            input: 'password',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showLoaderOnConfirm: true,
            showCancelButton: true,

            preConfirm: async (result) => {
              try {
                setExecuting(true)

                const token = await login({
                  email: usuarioData?.email as string,
                  password: result as string,
                  manterConectado: autoLogin
                })

                setExecuting(false)

                if (token) {
                  setExecuting(true)

                  const response = await executeUseCase(
                    useCase,
                    paramsUseCase,
                    sucessMessage,
                    questionMessage,
                    afterAll,
                    false
                  )

                  setExecuting(false)
                  return response
                }
              } catch (error) {
                setExecuting(false)

                Swal.insertQueueStep({
                  icon: 'error',
                  title: error.message || 'Erro desconhecido',
                  showConfirmButton: false,
                  timer: 3500,
                  timerProgressBar: true
                })
              }
            }
          }])
        } else if (error.type === 'validate') {
          setError(error)
          let message = ''
          if (error.data) {
            const arrayErrors = error.data as ValidateError
            Object.keys(arrayErrors).forEach((element: any) => {
              message = message === '' ? arrayErrors[element] : `<br> ${arrayErrors[element]}`
            })
          }
          Swal.fire({
            icon: 'error',
            title: 'Erro de validação',
            html: message,
            showConfirmButton: false
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: error.message || 'Erro desconhecido',
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true
          })
        }
      }
    }
  }, [autoLogin, usuarioData, login])

  return { executeUseCase, error, executing, success }
}
