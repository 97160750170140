import React, { createContext, useState } from 'react'
import { Telefone } from '../../modules/clientes/models'

type ModeTypes = 'create' | 'edit'
export interface CadastroTelefoneContextProps {
  data?: Telefone
  setData (telefone: Telefone): void
  resetData (): void
  dataMode: ModeTypes
  editing: boolean
  setEditing: (editing: boolean) => void
  setDataMode: (mode: ModeTypes) => void
}

const initialValues: Telefone = {
  ddd: '',
  telefone: '',
  ramal: '',
  whatsapp: 'n'
}

export const CadastroTelefoneContext = createContext<CadastroTelefoneContextProps>({} as CadastroTelefoneContextProps)

interface CadastroTelefoneProviderProps {
  children: React.ReactNode
}

export const CadastroTelefoneProvider = ({ children }: CadastroTelefoneProviderProps) => {
  const [telefone, setTelefone] = useState<Telefone>(initialValues)
  const [mode, setMode] = useState<ModeTypes>('create')
  const [editing, setEditing] = useState(false)

  const resetData = () => {
    setTelefone(initialValues)
  }

  return (
    <CadastroTelefoneContext.Provider value={{
      data: telefone,
      setData: setTelefone,
      resetData,
      dataMode: mode,
      editing,
      setEditing,
      setDataMode: setMode
    }} >
      {children}
    </CadastroTelefoneContext.Provider>
  )
}
