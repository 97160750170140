import { formatFloatToCurrency } from '.'
import { GetPedido } from '../modules/pedidos/models'

export const calcTotalListaPedidos = (pedido: GetPedido): string => {
  let retorno = 0
  if (pedido) {
    if (pedido.subtotal && pedido.subtotal > 0) {
      // if (pedido.acrescimos && pedido.acrescimos >= 0) {
      //   retorno += pedido.acrescimos
      // }
      // if (pedido.descontos && pedido.descontos >= 0) {
      //   retorno = retorno - pedido.descontos
      // }
      // if (pedido.ipi && pedido.ipi >= 0) {
      //   retorno = retorno + pedido.ipi
      // }

      if (pedido.st && pedido.st > 0) {
        retorno = retorno + pedido.st
      }

      retorno = retorno + pedido.subtotal
    }
  }
  return formatFloatToCurrency(retorno)
}
