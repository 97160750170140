import { PostParams, UseCase } from '../../_interfaces'
import { AutenticarUsuarioService } from '../contracts'

interface LogarUseCaseParams {
  email: string
  password: string
}

export class AutenticarUsuario implements UseCase<LogarUseCaseParams, string> {
  private readonly autenticarUsuario: AutenticarUsuarioService

  constructor (autenticarUsuario: AutenticarUsuarioService) {
    this.autenticarUsuario = autenticarUsuario
  }

  async execute (logarUseCaseParams: LogarUseCaseParams): Promise<string> {
    const params: PostParams = {
      body: logarUseCaseParams
    }

    const login = await this.autenticarUsuario.logar(params)
    return login?.token as string
  }
}
