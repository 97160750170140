import React, { useCallback } from 'react'
import { FaShoppingCart, FaClipboardList, FaClipboardCheck } from 'react-icons/fa'
import { formatFloatToCurrency } from '../../../helpers'
import { Loading, Card, MainChart, ProgressBar } from '../../components'
import { useResumoFuncionario } from '../../hooks'
import { MainLayout } from '../../layouts/MainLayout'
import { Container } from './styles'

export const Dashboard = () => {
  const { valoresGrafico, resumoFuncionario, metaFuncionario, loading } = useResumoFuncionario()

  const getValorVendas = useCallback(() => {
    let retorno = 0
    if (valoresGrafico?.valores) {
      retorno = valoresGrafico?.valores[5].valor1 as number || valoresGrafico?.valores[5].valor2 as number
    }
    return retorno
  }, [valoresGrafico])

  return (
    <MainLayout title="Dashboard">
      {loading && <Loading />}
      <h1>Dashboard</h1>
      <Container id='dashContainer'>
        <section>
          <Card
            width='22%'
            cor='success'
            title="VENDAS"
            icon={FaShoppingCart}
            text={formatFloatToCurrency(getValorVendas())}
          />
          {/* <Card
            width='22%'
            cor='info'
            title="COMISSÃO"
            icon={FaDollarSign}
            text={resumoFuncionario.comissao_mes_atual
              ? `R$ ${formatFloatToCurrency(resumoFuncionario.comissao_mes_atual)}`
              : 'R$ 0,00'
            }
          /> */}
          <Card
            width='22%'
            cor='warning'
            title="PEDIDOS EM ABERTO"
            icon={FaClipboardList}
            text={resumoFuncionario.qtde_pedidos_em_aberto
              ? resumoFuncionario.qtde_pedidos_em_aberto
              : 0
            }
          />
          <Card
            width='22%'
            cor='success'
            title="PEDIDOS FATURADOS"
            icon={FaClipboardCheck}
            text={resumoFuncionario.qtde_pedidos_faturados
              ? resumoFuncionario.qtde_pedidos_faturados
              : 0
            }
          />
        </section>
        <section>
          <ProgressBar
            label='Meta'
            current={getValorVendas()}
            total={metaFuncionario.valor}
            mensagem={metaFuncionario.status === 'ativo' ? 'Dados da meta atual não encontrados' : 'Meta do funcionário está inativa'}
          />
        </section>
        <section>
          <MainChart subtitles={valoresGrafico.legendas} symbol="R$" valores={valoresGrafico?.valores} />
        </section>
      </Container>

    </MainLayout>
  )
}
