import { GetParams, UseCase } from '../../_interfaces'
import { ListarEmpresasService } from '../contracts'
import { GetListEmpresaResponse } from '../models'

export class ListarEmpresas implements UseCase<GetParams, GetListEmpresaResponse> {
  private readonly listarEmpresasService: ListarEmpresasService

  constructor (listarEmpresasService: ListarEmpresasService) {
    this.listarEmpresasService = listarEmpresasService
  }
  /*
    token: string, limit: number, skip: number, search: string) */

  async execute (params: GetParams): Promise<GetListEmpresaResponse> {
    const response = await this.listarEmpresasService.listarEmpresas(params)

    return response
  }
}
