import { MarcarNotificacaoLidaService } from '../contracts'
import { MarcarNotificacaLidaParams } from '../services'

export class MarcarNotificacaoLida {
  private readonly marcarNotificaoLidaService: MarcarNotificacaoLidaService

  constructor (marcarNotificaoLidaService: MarcarNotificacaoLidaService) {
    this.marcarNotificaoLidaService = marcarNotificaoLidaService
  }

  async execute (params: MarcarNotificacaLidaParams): Promise<boolean> {
    if (!params?.id) {
      return false
    }

    let response = await this.marcarNotificaoLidaService.marcarNotificacaoLida(params.id, params.token)

    if (response) {
      return true
    } else {
      return false
    }
  }
}
