import { useCallback, useState } from 'react'
import { useUsuario } from '..'
import { makeListarClientes, makeListarClientesEquipe } from '../../../modules/clientes/factories'
import { makeListarClientesFuncionario } from '../../../modules/funcionarios/factories'
import { GetClientesFuncionarioResponse } from '../../../modules/funcionarios/models'
import { ListarClientesFuncionarioParams } from '../../../modules/funcionarios/_interfaces'
import { GetParams } from '../../../modules/_interfaces'
import { useHandleErrors } from '../app/useHandleErrors'

export function useClientes () {
  const { data: usuarioData } = useUsuario()
  const [loading, setLoading] = useState(false)
  const [isReloading, setIsReloading] = useState(false)
  const { executing, handleError } = useHandleErrors()
  const [data, setData] = useState<GetClientesFuncionarioResponse>()

  // useEffect(() => {
  //   fetcher()
  // }, [handleError, executing, isReloading, params, funcionarioId, modo])

  async function fetcher (params: GetParams, modo?: string, funcionarioId?: number) {
    setLoading(true)
    if (modo === 'fidelizados') {
      const listarClientesFuncionario = makeListarClientesFuncionario()
      let paramsFidelizados = {
        ...params,
        funcionarioId: funcionarioId
      } as ListarClientesFuncionarioParams

      const response = await listarClientesFuncionario.execute(paramsFidelizados)
      if (response.error && !executing) {
        handleError(response.error)
      }
      setData(response)
    } else if (modo === 'todos') {
      const listarClientes = makeListarClientes()
      const response = await listarClientes.execute(params)
      if (response.error && !executing) {
        handleError(response.error)
      }
      setData(response)
    } else if (modo === 'equipe') {
      const listarClientes = makeListarClientesEquipe()
      // const filterObject = { ...params.filterObject }
      const paramsEquipe = {
        ...params,
        equipe_id: parseInt(usuarioData?.equipe_id as string)
      }
      const response = await listarClientes.execute(paramsEquipe)
      if (response.error && !executing) {
        handleError(response.error)
      }
      setData(response)
    } else if (modo === 'revendas') {
      const listarClientes = makeListarClientes()
      const filterObject = { ...params.filterObject }
      const paramsRevenda = {
        ...params,
        filterObject: { ...filterObject, 'clientes.is_revenda_cliente': 's' }
      }
      const response = await listarClientes.execute(paramsRevenda)
      if (response.error && !executing) {
        handleError(response.error)
      }
      setData(response)
    }
    setLoading(false)
  }

  const reload = useCallback(() => {
    setIsReloading(true)
  }, [])

  return { fetcher, data, reload, loading, isReloading }
}
