import { removeCreatedAndUpdatedeAt } from '../../../helpers'
import { Cliente } from '../models'

export function sanetizeClienteToUpdate (cliente: Cliente): Cliente {
  const { contatos, email } = cliente

  const contatosWithoutCreatedAndUpdatedAt = contatos?.map(contato => {
    const { observacao, data_verificacao, ...rest } = contato
    return removeCreatedAndUpdatedeAt(rest)
  })

  return {
    contatos: contatosWithoutCreatedAndUpdatedAt,
    email
  } as Cliente
}

export function sanetizeClienteToCreate (cliente: Cliente): Cliente {
  let retorno = { ...cliente }
  if (cliente.cep) {
    retorno.cep = cliente.cep.replace(/[^0-9]/g, '')
  }

  if (cliente.cnpj) {
    retorno.cnpj = cliente.cnpj.replace(/[^0-9]/g, '')
  }

  if (cliente.ie) {
    retorno.ie = cliente.ie.replace(/[^0-9]/g, '')
  }

  // if (retorno.pj) {
  //   delete retorno.pj
  // }

  return retorno
}
