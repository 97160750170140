import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Cliente } from '../../../modules/clientes/models'
import { Produto } from '../../../modules/produtos/models'
import { CardProdutos } from '../CardProdutos'
import { CardProdutosColumns } from '../CardProdutosColumns'
import { ModePedido } from '../Pedido'
import { Container, ContainerColumns, CardProdutosSkeleton } from './styles'

interface ListaProps {
  data?: Produto[]
  mode: ModePedido
  cliente?: Cliente
  handleSelectProduto?: (produto: Produto) => void
  handleSelectPromocao?: (produto: Produto) => void
  isLoading: boolean
  pedidoIdEmEdicao?: number
  cardMode: string
  isCardRow?: boolean
  isCardColumn?: boolean
}

export const ListaCardProdutos = ({ data = [], mode, handleSelectProduto, handleSelectPromocao, isLoading, pedidoIdEmEdicao, cardMode, cliente, isCardRow, isCardColumn }: ListaProps) => {
  return (
    <>
      {isCardRow && (
        <Container>
          {!!data?.length && data.map((produto) => (
            <CardProdutos
              key={produto.id}
              data={produto}
              handleSelectProduto={handleSelectProduto}
              handleSelectPromocao={handleSelectPromocao}
              mode={mode}
              pedidoIdEmEdicao={pedidoIdEmEdicao}
              cardMode={cardMode}
              cliente={cliente}
            />
          ))}
          {!!isLoading && Array.from({ length: 5 }).map((_, index) =>
            <Skeleton key={index} wrapper={CardProdutosSkeleton} />
          )}
        </Container>
      )}
      {isCardColumn && (
        <ContainerColumns>
          {!!data?.length && data.map((produto) => (
            <CardProdutosColumns
              key={produto.id}
              data={produto}
              handleSelectProduto={handleSelectProduto}
              handleSelectPromocao={handleSelectPromocao}
              mode={mode}
              pedidoIdEmEdicao={pedidoIdEmEdicao}
              cardMode={cardMode}
              cliente={cliente}
            />
          ))}
          {!!isLoading && Array.from({ length: 5 }).map((_, index) =>
            <Skeleton key={index} wrapper={CardProdutosSkeleton} />
          )}
        </ContainerColumns>
      )}
    </>
  )
}
