import { HttpRequest } from '../../_interfaces/httpRequest'
import { TrazerProdutoPorIdService } from '../contracts'
import { GetProdutosPorIdResponse } from '../models'
import { TrazerProdutoPorIdResApi } from './_interfaces'

export class TrazerProdutoPorIdServiceRest implements TrazerProdutoPorIdService {
  private readonly http: HttpRequest

  constructor (http: HttpRequest) {
    this.http = http
  }

  async trazerProdutoPorId (produtoId: number, token: string): Promise<GetProdutosPorIdResponse> {
    const response = await this.http.get<TrazerProdutoPorIdResApi>({
      path: `produtos/${produtoId}`,
      token
    })

    return response?.res as GetProdutosPorIdResponse
  }
}
