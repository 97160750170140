import React from 'react'
import { useSelector } from 'react-redux'
import { ContasReceber, Geral, MenuAtendimento, DadosCliente, UltimasSaidas } from '..'
import { Cliente } from '../../../modules/clientes/models'
import { AppState } from '../../store'
import { AtendimentosState } from '../../store/atendimentos/interfaces'
import Ocorrencias from '../Ocorrencias'
import { Pedidos } from '../Pedidos'
import { UltimasCompras } from '../UltimasCompras'
import { Container, Main } from './styles'

interface AtendimentoProps {
  cliente: Cliente
}

export const Atendimento = ({ cliente }: AtendimentoProps) => {
  const atendimentosState = useSelector<AppState>(state => state.atendimentos) as AtendimentosState

  return (
    <Container>
      <Main>
        {atendimentosState.data[cliente.id as number].botaoAtendimentoAtivo === 'geral' && <Geral cliente={cliente} />}
        {atendimentosState.data[cliente.id as number].botaoAtendimentoAtivo === 'pedidos' && <Pedidos cliente={cliente} />}
        {atendimentosState.data[cliente.id as number].botaoAtendimentoAtivo === 'contas_receber' && <ContasReceber cliente={cliente} />}
        {atendimentosState.data[cliente.id as number].botaoAtendimentoAtivo === 'cliente' && <DadosCliente cliente={cliente} mode={'edit'} />}
        {atendimentosState.data[cliente.id as number].botaoAtendimentoAtivo === 'ocorrencias' && <Ocorrencias cliente={cliente}/>}
        {atendimentosState.data[cliente.id as number].botaoAtendimentoAtivo === 'ultimas' && <UltimasSaidas cliente={cliente}/>}
        {atendimentosState.data[cliente.id as number].botaoAtendimentoAtivo === 'ultimas_compras' && <UltimasCompras cliente={cliente} />}
      </Main>

      <MenuAtendimento cliente={cliente} />
    </Container>
  )
}
