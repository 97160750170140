import capitalize from 'capitalize-pt-br'
import React from 'react'
import { Textarea, Input } from '..'
import { FormRow } from '../../styles/global'
import { Container, ListaBody } from './styles'

interface ListaProps {
  descricao?: string
  status?: string
}

export const ListaAnaliseCredito = ({ descricao, status }: ListaProps) => {
  const analiseColor = (text?: string) => {
    if (text) {
      if (text === 'aprovado') {
        return 'success'
      } else if (text === 'rejeitado') {
        return 'danger'
      }
    }
  }

  return (
    <Container>
      <FormRow>
        <Input
          label='Status'
          value={capitalize(status as string)}
          color={analiseColor(status)}
          width='0 0 25%'
        />
      </FormRow>
      <ListaBody>
        <Textarea
          title='Descrição'
          label='Descrição'
          enableAutoHeight
          disabled
        >{descricao || 'Não há descrição analise de crédito desse pedido'}</Textarea>
      </ListaBody>
    </Container>
  )
}
