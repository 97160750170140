import { format, subMonths, subYears } from 'date-fns'

export const getMesPassado = () => {
  let dataGte = ''
  const dataAtual = new Date()
  const mesAtual = format(dataAtual, 'MM')
  let mesPassado = format(dataAtual, 'MM')
  const anoAtual = format(dataAtual, 'yyyy')
  let anoPassado = format(dataAtual, 'yyyy')
  if (mesAtual === '01') {
    anoPassado = format(subYears(dataAtual, 1), 'yyyy')
    dataGte = anoPassado + '1201'
  } else {
    mesPassado = format(subMonths(dataAtual, 1), 'MM')
    dataGte = anoAtual + mesPassado + '01'
  }

  return dataGte
}

export const getPrimeiroDiaMes = () => {
  let dataGte = ''
  const dataAtual = new Date()
  const mesAtual = format(dataAtual, 'MM')
  const anoAtual = format(dataAtual, 'yyyy')
  dataGte = anoAtual + mesAtual + '01'

  return dataGte
}
