import capitalize from 'capitalize-pt-br'
import React, { ChangeEvent, useCallback } from 'react'
import { Input, Textarea } from '../'
import { Cliente } from '../../../modules/clientes/models'
import { IEType } from '../../../helpers/getIEMask'
import { removerAcento } from '../../../helpers/removerAcentos'
import { FormRow } from '../../styles/global'
import { CheckBox } from '../CheckBox'
import { RadioButton } from '../RadioButton'
import EstadosMunicipios from '../../assets/jsons/estados_municipios.json'
import { Container, RadioButtons, Divider, DivForm } from './styles'
import { useCadastroCliente } from '../../hooks'
import { makeTrazerDadosClienteReceita } from '../../../modules/clientes/factories'

const trazerDadosClienteReceita = makeTrazerDadosClienteReceita()

export const Dados = () => {
  const {
    data: cliente,
    setData: setCliente,
    dataError: clienteError,
    setDataError: setClienteError,
    setEditing: setEditingCliente,
    dataMode
  } = useCadastroCliente()

  // const [controlFormPessoa, setControlFormPessoa] = useState<'pj' | 'pf'>('pj')

  const handlePfChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === 'pj'

    const newCliente = {
      ...cliente,
      pj: value
    }

    setCliente(newCliente)
  }, [cliente, setCliente])

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value

    if (e.target.type === 'checkbox') {
      value = e.target.checked ? 's' : 'n'
    }

    const newCliente = {
      ...cliente,
      [e.target.name]: value
    }

    // remove o erro do campo que está sendo editado
    const newError = {
      ...clienteError,
      [e.target.name]: ''
    }

    setEditingCliente(true)
    setCliente(newCliente)
    setClienteError(newError)
  }, [cliente, clienteError, setCliente, setClienteError, setEditingCliente])

  const handleInputCNPJ = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(e)
    let cidade = ''
    const cnpj = e.currentTarget.value.replace(/[^\w\s]/gi, '').replace(/_/g, '')

    if (cnpj.length === 14) {
      try {
        const response = await trazerDadosClienteReceita.execute(
          'bf2cc265e3073aab06df3484f56f603e7c409b55e01cddc0bfde6781624c8494'
          , cnpj
        )

        if (response?.data) {
          const [estado] = EstadosMunicipios.estados.filter(estado => estado.sigla === response.data.uf)

          if (estado) {
            const filtered: string[] = estado.cidades.filter(cidade => {
              return removerAcento(cidade).toLowerCase() === removerAcento(response.data.municipio).toLowerCase()
            })

            if (filtered[0]) {
              cidade = filtered[0]
            }
          }

          const newCliente: Cliente = {
            ...cliente,
            endereco: capitalize(response.data.logradouro),
            uf: response.data.uf,
            cidade,
            bairro: capitalize(response.data.bairro),
            cep: response.data.cep,
            cnpj,
            razao_social: capitalize(response.data.nome),
            nome_fantasia: capitalize(response.data.fantasia),
            email: response.data.email,
            complemento: capitalize(response.data.complemento),
            numero: response.data.numero
          }

          setCliente(newCliente)
        } else {
          const newCliente: Cliente = {
            ...cliente,
            cnpj
          }

          setCliente(newCliente)
        }
      } catch (error) {
        const newCliente: Cliente = {
          ...cliente,
          cnpj
        }

        setCliente(newCliente)
      }
    }
  }, [cliente, handleInputChange, setCliente])

  const handleIsIsentoOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newClienteState: Cliente = {
      ...cliente,
      ie: e.target.checked ? 'isento' : ''
    }

    setCliente(newClienteState)
  }, [cliente, setCliente])

  return (
    <Container>
      <section>
        <FormRow>
          <Input
            name="nome_fantasia"
            label={!cliente.pj ? 'Nome' : 'Nome Fantasia'}
            value={cliente?.nome_fantasia}
            placeholder={!cliente.pj ? 'Nome' : 'Nome fantasia'}
            onChange={handleInputChange}
            error={clienteError?.nome_fantasia}
            disabled={dataMode === 'edit'}
            data-cy='cadastroClienteNome'
          />
          <Input
            name="razao_social"
            label="Razão Social"
            value={cliente?.razao_social}
            placeholder='Razão social'
            onChange={handleInputChange}
            error={clienteError?.razao_social}
            disabled={dataMode === 'edit' || !cliente.pj}
            data-cy='cadastroClienteRazao'
          />
        </FormRow>
        <DivForm>
          <RadioButtons>
            <span>
              Tipo
            </span>
            <Divider>
              <RadioButton
                name="pj"
                checked={cliente.pj}
                value="pj"
                onChange={handlePfChange}
                title="Pessoa Jurídica"
                disabled={dataMode === 'edit'}
                data-cy='cadastroClienteRbPj'
              />
              <RadioButton
                name="pj"
                checked={!cliente.pj}
                value="pf"
                onChange={handlePfChange}
                title="Pessoa Física"
                disabled={dataMode === 'edit'}
                data-cy='cadastroClienteRbPf'
              />
            </Divider>
          </RadioButtons>
          <RadioButtons>
            <span>
              Grupo
            </span>
            <Divider>
              <CheckBox
                name="is_cliente_final"
                onChange={handleInputChange}
                checked={cliente?.is_cliente_final === 's'}
                title="Cliente Final"
                disabled={dataMode === 'edit'}
                data-cy='cadastroClienteCbClienteFinal'
              />
              <CheckBox
                name="is_isento"
                checked={cliente?.ie === 'isento'}
                onChange={handleIsIsentoOnChange}
                title="Isento"
                disabled={dataMode === 'edit'}
                data-cy='cadastroClienteCbIsento'
              />
              <CheckBox
                name="is_orgao_estadual"
                checked={cliente?.is_orgao_estadual === 's'}
                onChange={handleInputChange}
                title="Órgão Estadual"
                disabled={dataMode === 'edit'}
                data-cy='cadastroClienteCbOrgaoEstadual'
              />
              <CheckBox
                name="is_revenda"
                checked={cliente?.is_revenda === 's'}
                onChange={handleInputChange}
                title="Revenda"
                disabled={dataMode === 'edit'}
                data-cy='cadastroClienteCbRevenda'
              />
            </Divider>
          </RadioButtons>
        </DivForm>
        <FormRow>
          <Input
            name="cnpj"
            label={!cliente.pj ? 'CPF' : 'CNPJ'}
            value={cliente?.cnpj}
            placeholder={!cliente.pj ? 'CPF' : 'CNPJ'}
            onChange={handleInputCNPJ}
            error={clienteError?.cnpj}
            type={!cliente.pj ? 'cpf' : 'cnpj'}
            disabled={dataMode === 'edit'}
            data-cy='cadastroClienteCnpj'
          />
          <Input
            name="ie"
            label="Inscrição Estadual"
            value={cliente?.ie}
            placeholder='Inscrição estadual'
            onChange={handleInputChange}
            error={clienteError?.ie}
            type={cliente?.uf ? `ie-${cliente?.uf}` as IEType : 'text'}
            disabled={cliente?.ie === 'isento' || dataMode === 'edit' || !cliente.pj}
            data-cy='cadastroClienteIe'
          />
          <Input
            name="ramo_atividade"
            label="Ramo de Atividade"
            value={cliente?.ramo_atividade}
            placeholder='Ramo de Atividade'
            type='text'
            disabled
          />
        </FormRow>
        <FormRow>
          <Input
            name="email"
            label="E-mail Principal"
            value={cliente?.email}
            placeholder='E-mail principal'
            onChange={handleInputChange}
            error={clienteError?.email}
            data-cy='cadastroClienteEmail'
          />
          <Input
            name="email_nfe"
            label="Email Nota Fiscal Eletrônica"
            value={cliente?.email_nfe}
            placeholder='E-mail nota fiscal eletrônica'
            onChange={handleInputChange}
            error={clienteError?.email_nfe}
            disabled={dataMode === 'edit'}
            data-cy='cadastroClienteEmailNfe'
          />
          <Input
            name="email_nfe2"
            label="Email Nota Fiscal Eletrônica 2"
            value={cliente?.email_nfe2}
            placeholder='E-mail nota fiscal eletrônica'
            onChange={handleInputChange}
            error={clienteError?.email_nfe2}
            disabled={dataMode === 'edit'}
            data-cy='cadastroClienteEmailNfe2'
          />
        </FormRow>
        <FormRow>
          <Textarea
            label='Obeservação'
            width='100%'
            height='100px'
            value={cliente.observacao}
          />
          <Textarea
            label='Obeservação Tela'
            width='100%'
            height='100px'
            value={cliente.observacao_tela}
          />
        </FormRow>
      </section>
    </Container>
  )
}
