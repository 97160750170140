import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { ListarClientesEquipeServiceRest } from '../services'
import { ListarClientesEquipe } from '../useCases'

export function makeListarClientesEquipe (): ListarClientesEquipe {
  const httpRequest = new HttpRequestImpl()
  const listarClienteService = new ListarClientesEquipeServiceRest(httpRequest)

  return new ListarClientesEquipe(listarClienteService)
}
