import styled from 'styled-components'

interface DivButtonProps {
  width?: string
  marginLeft?: string
}

export const Container = styled.div`
`

export const Header = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  justify-content: flex-end;

  & > div {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
`

export const DivButton = styled.div<DivButtonProps>`
  display: flex;
  width: ${props => props.width ? props.width : '100%'};
  justify-content: center;
  margin: 10px 0px 10px 7px;
  padding-top: 26px;

  @media (max-width: 900px) {
    & {
      width: 100% !important;
      justify-content: flex-start;
      margin-top: 10px;
      margin-left: 0;
      padding: 0;
    }
  }
`
export const FreteModalDivs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
