import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const FieldData = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  width: 100%;
  >span{
    display: flex;
    color: ${props => props.theme.colors.primaryText} !important;
    opacity: 0.5;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .react-datepicker__tab-loop{
    z-index: 1000;
  }
  .react-datepicker__input-container{
    >input{
      width: 100%;
      height: 36px;
      padding: 0px 10px;
      border-radius: 4px;
      border: 1px solid ${props => props.theme.colors.border}
    }
  }
`
