import capitalize from 'capitalize-pt-br'
import React, { ChangeEvent, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { InputF2, Loading, Table, TableColumnProps } from '..'
import { Item } from '../../../modules/pedidos/models'
import { ItemPedidoApi } from '../../../modules/pedidos/services/_interfaces'
import { usePedido } from '../../hooks'
import { TableText } from '../../styles/global'
import { Container, Input } from './styles'

interface ItemDesmembrar extends Item {
  quantidade_desmembrar: number
  quantidade_original: number
}

interface DesmembrarProps extends InputF2 {
  pedidoId?: number,
}
export const DesmembrarPedido = ({ pedidoId, close, callBack }: DesmembrarProps) => {
  const { fetcher: fetcherPedido, data: dataPedido, loading: loadingPedido } = usePedido()

  const [itens, setItens] = useState<any[]>([])

  const inputManterRef = useRef(0)
  const inputDesmembrarRef = useRef(0)

  useEffect(() => {
    fetcherPedido(pedidoId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const pedido = dataPedido?.data

    if (itens.length > 0 && pedido) {
      const pedidoItens = itens?.map((i: ItemPedidoApi) => {
        return {
          produto: i.produto,
          quantidade: i.quantidade,
          valor: i.valor,
          promocao_id: i.promocao_id,
          item_promocao_id: i.promocao_id,
          id: i.id,
          data: pedido.updated_at
        }
      }) || []

      const pedidoNovo: any = {
        ...pedido,
        itens: pedidoItens
      }

      callBack && callBack(pedidoNovo)
    }
  }, [callBack, dataPedido, itens])

  useEffect(() => {
    if (dataPedido?.data?.itens) {
      const itens: ItemDesmembrar[] = dataPedido?.data?.itens.map(i => {
        return {
          ...i,
          quantidade_original: i.quantidade,
          quantidade_desmembrar: 0
        }
      })

      setItens(itens)
    }
  }, [dataPedido])

  const handleChangeManter = useCallback((e: number | null, item: ItemDesmembrar, index: number) => {
    let value = Number(e)

    if (value > item.quantidade_original || item.quantidade_original < 0) {
      value = item.quantidade
    }

    const newItens = [...itens]

    newItens[index] = {
      ...newItens[index],
      quantidade: value,
      quantidade_desmembrar: newItens[index].quantidade_original - value
    }

    setItens(newItens)

    inputDesmembrarRef.current = Number(item.id)
  }, [itens])

  const handleChangeDesmembrar = useCallback((e: number | null, item: ItemDesmembrar, index: number) => {
    let value = Number(e)

    if (value > item.quantidade_original || item.quantidade_original < 0) {
      value = item.quantidade_desmembrar
    }

    const newItens = [...itens]

    newItens[index] = {
      ...newItens[index],
      quantidade: newItens[index].quantidade_original - value,
      quantidade_desmembrar: value
    }
    setItens(newItens)

    inputDesmembrarRef.current = -Number(item.id)
  }, [itens])

  const columns = useMemo(():TableColumnProps[] => {
    return [
      {
        Header: '#',
        minWidth: 15,
        Cell: ({ row }) => <TableText position='right'>{row._original.produto.id}</TableText>
      },
      {
        Header: 'Descrição',
        Cell: ({ row }) => <TableText position='left'>
          {row._original.produto.nome_popular === ''
            ? capitalize(row._original.produto.nome_popular)
            : capitalize(row._original.produto.nome_tecnico)
          }
          {Number(row._original.quantidade_liberada) > 0 && (<>(Liberado: {row._original.quantidade_liberada})</>)}
        </TableText>
      },
      {
        Header: 'Estoque',
        minWidth: 15,
        Cell: ({ row }) => <TableText position='center'>{row._original.estoque_atual}</TableText>
      },
      {
        Header: 'Manter',
        accessor: 'quantidade',
        minWidth: 20,
        Cell: ({ row, index }) => <>
          <Input
            key={row._original.id}
            mobile={true}
            value={row._original.quantidade ?? '0'}
            onChange={(e) => handleChangeManter(e, row._original, index)}
            min={row._original.quantidade_liberada || 0}
            max={row._original.quantidade_original}
            autoFocus={inputDesmembrarRef.current === row._original.id}
          />
        </>
      },
      {
        Header: 'Desmembrar',
        accessor: 'quantidade_desmembrar',
        minWidth: 20,
        Cell: ({ row, index }) => <>
          <Input
            key={-row._original.id}
            mobile={true}
            value={row._original.quantidade_desmembrar ?? '0'}
            onChange={(e) => handleChangeDesmembrar(e, row._original, index)}
            min={0}
            max={row._original.quantidade_original - row._original.quantidade_liberada}
            autoFocus={inputDesmembrarRef.current === -row._original.id}
          />
        </>
      }
    ]
  }, [handleChangeDesmembrar, handleChangeManter])

  return (
    <Container>
      {loadingPedido ? <Loading /> : ''}
      <Table
        className='tableDesmembrar'
        tabIndex={0}
        columns={columns}
        showPageJump={false}
        data={itens}
        pageSize={itens.length < 10 ? 10 : itens.length}
        sortable={false}
        showPageSizeOptions={false}
        noDataText="Nenhum frete localizdo"
        nextText='Próximo'
        ofText='de'
        previousText='Anterior'
        pageText='Página'
      />

    </Container>
  )
}
