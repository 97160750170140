import { GetParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { TrazerResumoFuncionarioService } from '../contracts'
import { GetResumoFuncionario } from '../models/getResumoFuncionarios'
import { TrazerFuncionarioApiResp } from './_interfaces'

export class TrazerResumoFuncionarioServiceRest implements TrazerResumoFuncionarioService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async trazerResumoFuncionario (params: GetParams): Promise<GetResumoFuncionario> {
    let { filter: id, token } = params

    const response = await this.httpRequest.get<TrazerFuncionarioApiResp>({
      path: `funcionarios/${id}/resumo`,
      token
    })

    const retorno = {
      data: response.res?.data,
      metadata: response.res?.metadata,
      error: response.error
    } as GetResumoFuncionario

    return retorno
  }
}
