import React from 'react'

import { Cliente } from '../../../modules/clientes/models'
import { FormRow } from '../../styles/global'
import { Container } from './styles'
import { Input } from '../'
import { toCnpj } from '../../../helpers'

interface Props {
  data?: Cliente
}

export const DadosResumoCliente = ({ data } : Props) => {
  const getValues = (name: keyof Cliente) => {
    if (data) {
      return data[name]
    }
  }

  return <Container>
    {/* dados */}
    <section>
      <FormRow className="mobile">
        <Input
          width="1"
          label="Cód. Cliente"
          value={getValues('id') as string}
        />
        <Input
          width="4"
          label="Razão Social"
          value={getValues('razao_social') as string}
        />
        <Input
          width="2"
          label={!getValues('pj') ? 'CPF' : 'CNPJ'}
          value={toCnpj(getValues('cnpj') as string)}
        />
      </FormRow>
    </section>

    {/* endereco */}
    <section>
      <FormRow className="mobile">
        <Input
          width="2"
          label="CEP"
          value={getValues('cep') as string}
        />
        <Input
          width="1"
          label="UF"
          value={getValues('uf') as string}
        />
        <Input
          width="2"
          label="Cidade"
          value={getValues('cidade') as string}
        />

        <Input
          width="2"
          label="Bairro"
          value={getValues('bairro') as string}
        />
      </FormRow>
      <FormRow className="mobile">
        <Input
          width="3"
          label="Endereço"
          value={getValues('endereco') as string}
        />
        <Input
          width="1"
          label="Número"
          value={getValues('numero') as string}
        />
      </FormRow>
      <FormRow className="mobile">
        <Input
          width="2"
          label="Complemento"
          value={getValues('complemento') as string}
        />
        <Input
          width="1"
          label="Região"
          value={getValues('regiao') as string}
        />
      </FormRow>
    </section>

  </Container>
}
