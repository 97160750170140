/* eslint-disable react-hooks/exhaustive-deps */
import capitalize from 'capitalize-pt-br'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  ButtonTable,
  CadastroContato,
  CadastroTelefone,
  Input,
  ListaContatos,
  Loading,
  Modal,
  Select,
  Table
} from '../'
import {
  Cliente,
  Contato,
  GrupoCliente,
  Telefone
} from '../../../modules/clientes/models'
import { IEType } from '../../../helpers/getIEMask'
import {
  FormRow,
  TableActions,
  TableText,
  TextStatus
} from '../../styles/global'
import { CheckBox } from '../CheckBox'
import { RadioButton } from '../RadioButton'
import {
  Container,
  RadioButtons,
  Divider,
  DivForm,
  SectionHeader,
  Telefones
} from './styles'
import { Textarea } from '../Textarea'
import { FaCheck, FaSave, FaUserPlus } from 'react-icons/fa'
import { Column } from 'react-table-6'
import { MdDelete } from 'react-icons/md'
import { RiPencilFill } from 'react-icons/ri'
import { BsPlus } from 'react-icons/bs'
import Swal from 'sweetalert2'
import { iniciarAtendimento, setarAtualizarCliente } from '../../store/atendimentos/actions'
import {
  makeAtualizarCliente,
  makeCadastrarCliente,
  // makeTrazerDadosClienteReceita,
  makeTrazerEnderecoCliente
} from '../../../modules/clientes/factories'
import { useCliente, useClientes, useExecUseCase, useFuncionario, useUsuario } from '../../hooks'
import { PostParams, UpdateParams } from '../../../modules/_interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { setarBotaoAtivoCliente } from '../../store/atendimentos/actions'
import {
  sanetizeClienteToCreate,
  sanetizeClienteToUpdate
} from '../../../modules/clientes/helpers/sanetize'
import EstadosMunicipios from '../../assets/jsons/estados_municipios.json'
import { Fechamento } from '../../../modules/clientes/models/fechamento'
import { deleteEmptyPropsFromObject } from '../../../helpers'
import { useSideBar } from '../../contexts'
import { AppState } from '../../store'
import { AtendimentosState } from '../../store/atendimentos/interfaces'

interface DadosClienteProps {
  cliente?: Cliente;
  mode: 'registro' | 'edit';
}

const trazerEndereco = makeTrazerEnderecoCliente()
// const trazerDadosClienteReceita = makeTrazerDadosClienteReceita()
const ataulizarCliente = makeAtualizarCliente()
const cadastrarCliente = makeCadastrarCliente()

export const DadosCliente = ({ cliente, mode }: DadosClienteProps) => {
  const history = useHistory()
  const [showListaContatos, setShowListaContatos] = useState(false)
  const { fetcher: getClientePorId, data: clienteComContatos, loading: loadingCliente } = useCliente()
  const { fetcher: getClientes, data: dataclientes, loading: loadingClientes } = useClientes()
  const { data: usuario } = useUsuario()
  const { data: funcionario } = useFuncionario(usuario?.funcionario_id as unknown as number)
  const [newCliente, setNewCliente] = useState<Cliente>()
  const [showModalContato, setShowModalContato] = useState(false)
  const [contatoEmEdicao, setContatoEmEdicao] = useState(false)
  const [contatoIndex, setContatoIndex] = useState(-1)
  const newFormContato: Contato = {
    cliente_id: cliente ? cliente.id : 0,
    nome: '',
    email: '',
    comercial: 'n',
    fiscal: 'n',
    financeiro: 'n',
    status: 'ativo',
    telefones: [] as Telefone[],
    observacao: ''
  }
  const [formContato, setFormContato] = useState<Contato>(newFormContato)

  const [showModalTelefone, setShowModalTelefone] = useState(false)
  const [telefoneEmEdicao, setTelefoneEmEdicao] = useState(false)
  const [telefoneIndex, setTelefoneIndex] = useState(-1)
  const newFormTelefone: Telefone = {
    contato_id: 0,
    ddd: '',
    ramal: '',
    telefone: '',
    whatsapp: 'n'
  }
  const [formTelefone, setFormTelefone] = useState<Telefone>(newFormTelefone)

  const [cepError, setCepError] = useState<string>('')
  const [controlCidades, setControlCidades] = useState([] as string[])

  const {
    executeUseCase,
    error: errorUseCaseController,
    executing
  } = useExecUseCase()
  const dispatch = useDispatch()

  const [clientePj, setClientePj] = useState(true)
  const { isMobile } = useSideBar()
  const atendimentosState = useSelector<AppState>(state => state.atendimentos) as AtendimentosState

  const ClienteInitialState = {
    id: '',
    cnpj: '',
    pj: true,
    ie: '',
    razao_social: '',
    nome_fantasia: '',
    nome: '',
    email: '',
    email_nfe: '',
    email_nfe2: '',
    endereco: '',
    complemento: '',
    numero: '',
    bairro: '',
    regiao: '',
    cidade: '',
    cep: '',
    uf: '',
    exibir_internet: 'n',
    is_orgao_estadual: 'n',
    is_cliente_final: 'n',
    is_revenda: 'n',
    is_contribuinte: 'n',
    is_nao_contribuinte: 'n',
    is_simples_nacional: 'n',
    is_fornecedor: 'n',
    is_transportadora: 'n',
    is_revenda_cliente: 'n',
    is_representante_cliente: 'n',
    status: 'ativo',
    contatos: [] as Contato[],
    fechamentos: [] as Fechamento[],
    uso_consumo: 'n',
    frete_gratis: '',
    ultima_ocorrencia: '',
    grupo: [] as GrupoCliente[],
    ramo_atividade: '',
    observacao: '',
    observacao_tela: ''
  } as Cliente

  const temAlteracoes = useMemo(() => {
    let retorno = false
    if (newCliente !== undefined && Object.keys(newCliente).length > 0) {
      retorno = true
    }
    return retorno
  }, [newCliente])

  useEffect(() => {
    if (mode === 'registro') {
      setNewCliente(ClienteInitialState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  useEffect(() => {
    const total = dataclientes?.metadata?.count || 0
    if (total > 0) {
      const cliente_id = dataclientes?.data[0].id || '-'
      Swal.fire({
        title: 'Atenção',
        text: `O CNPJ/CPF informado já existe com o código ${cliente_id}`,
        icon: 'warning'
      })

      setNewCliente({ ...newCliente, cnpj: '' })
    }
  }, [dataclientes])

  useEffect(() => {
    if (cliente?.cnpj?.length === 11) {
      setClientePj(false)
    }

    if (!cliente?.uf || !newCliente?.uf) {
      return
    }

    const sigla = temAlteracoes ? newCliente.uf : cliente.uf

    const [estado] = EstadosMunicipios.estados.filter(
      (estado) => estado.sigla === sigla.toUpperCase()
    )

    setControlCidades(estado?.cidades)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cliente, newCliente])

  const getValues = (name: keyof Cliente) => {
    if (mode === 'registro') {
      if (newCliente) {
        return newCliente[name]
      }
    } else {
      if (temAlteracoes) {
        if (newCliente) {
          return newCliente[name]
        }
      } else {
        if (cliente) {
          return cliente[name]
        }
      }
    }
  }

  const handleOnChangeFields = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value
      const key = e.target.name as keyof Cliente

      if (e.target.type === 'checkbox') {
        value = e.target.checked ? 's' : 'n'
      }

      let clienteComCampoNovo = {} as Cliente

      if (mode === 'edit') {
        if (temAlteracoes) {
          clienteComCampoNovo = {
            ...newCliente,
            [key]: value
          }
        } else {
          clienteComCampoNovo = {
            ...cliente,
            [key]: value
          }
        }
      } else {
        clienteComCampoNovo = {
          ...newCliente,
          [key]: value
        }
      }

      setNewCliente(clienteComCampoNovo)
    },
    [cliente, mode, newCliente, temAlteracoes]
  )

  const handleCepInputChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      handleOnChangeFields(e)
      const cep = e.currentTarget.value
        .replace(/[^\w\s]/gi, '')
        .replace(/_/g, '')
      setCepError('')
      if (cep.length === 8) {
        try {
          const response = await trazerEndereco.execute(cep)
          if (!response?.data?.erro) {
            const [estado] = EstadosMunicipios.estados.filter(
              (estado) => estado.sigla === response.data?.uf
            )

            if (estado?.cidades) {
              setControlCidades(estado.cidades)
            }

            let newValues: Cliente = {}

            if (mode === 'edit') {
              if (temAlteracoes) {
                newValues = {
                  ...newCliente,
                  ...{
                    endereco: response.data.logradouro,
                    uf: response.data.uf,
                    cidade: response.data.localidade,
                    bairro: response.data.bairro,
                    cep
                  }
                }
              } else {
                newValues = {
                  ...cliente,
                  ...{
                    endereco: response.data.logradouro,
                    uf: response.data.uf,
                    cidade: response.data.localidade,
                    bairro: response.data.bairro,
                    cep
                  }
                }
              }
            } else {
              newValues = {
                ...newCliente,
                ...{
                  endereco: response.data.logradouro,
                  uf: response.data.uf,
                  cidade: response.data.localidade,
                  bairro: response.data.bairro,
                  cep
                }
              }
            }

            setNewCliente(newValues)
          }
        } catch (error) {
          setCepError('Cep não encontrado ou incorreto')
        }
      }
    },
    [cliente, handleOnChangeFields, mode, newCliente, temAlteracoes]
  )

  const handleOnChangeTextAreas = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    let value = e.target.value
    const key = e.target.name as keyof Cliente

    let clienteComCampoNovo = {} as Cliente

    if (mode === 'edit') {
      if (temAlteracoes) {
        clienteComCampoNovo = {
          ...newCliente,
          [key]: value
        }
      } else {
        clienteComCampoNovo = {
          ...cliente,
          [key]: value
        }
      }
    } else {
      clienteComCampoNovo = {
        ...newCliente,
        [key]: value
      }
    }

    setNewCliente(clienteComCampoNovo)
  }

  const handleSelectChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      let value = e.target.value
      const key = e.target.name as keyof Cliente

      if (key === 'uf') {
        const [estado] = EstadosMunicipios.estados.filter(
          (estado) => estado.sigla === value
        )

        if (estado?.cidades) {
          setControlCidades(estado.cidades)
        }
      }

      let clienteComCampoNovo = {} as Cliente

      if (mode === 'edit') {
        if (temAlteracoes) {
          clienteComCampoNovo = {
            ...newCliente,
            [key]: value
          }
        } else {
          clienteComCampoNovo = {
            ...cliente,
            [key]: value
          }
        }
      } else {
        clienteComCampoNovo = {
          ...newCliente,
          [key]: value
        }
      }

      setNewCliente(clienteComCampoNovo)
    },
    [cliente, mode, newCliente, temAlteracoes]
  )

  const busaClienteCnpj = useCallback(
    async (cnpj: number) => {
      if (usuario) {
        getClientes({
          token: usuario.token as string,
          filterObject: {
            'clientes.cnpj': cnpj
          }
        }, 'todos')
      }
    }, [getClientes, usuario]
  )

  const handleInputCNPJ = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      handleOnChangeFields(e)
      let cidade = ''
      const cnpj = e.currentTarget.value
        .replace(/[^\w\s]/gi, '')
        .replace(/_/g, '')

      if (newCliente?.pj) {
        if (cnpj.length === 14) {
          await busaClienteCnpj(Number(cnpj))
        }
      } else {
        if (cnpj.length === 11) {
          await busaClienteCnpj(Number(cnpj))
        }
      }

      // if (cnpj.length === 14) {
      //   try {
      //     const response = await trazerDadosClienteReceita.execute(
      //       'bf2cc265e3073aab06df3484f56f603e7c409b55e01cddc0bfde6781624c8494',
      //       cnpj
      //     )

      //     if (response?.data) {
      //       const [estado] = EstadosMunicipios.estados.filter(
      //         (estado) => estado.sigla === response.data.uf
      //       )

      //       if (estado) {
      //         const filtered: string[] = estado.cidades.filter((cidade) => {
      //           return (
      //             removerAcento(cidade).toLowerCase() ===
      //             removerAcento(response.data.municipio).toLowerCase()
      //           )
      //         })

      //         if (filtered[0]) {
      //           cidade = filtered[0]
      //         }
      //       }

      //       const clienteCnpj: Cliente = {
      //         ...newCliente,
      //         endereco: capitalize(response.data.logradouro),
      //         uf: response.data.uf,
      //         cidade,
      //         bairro: capitalize(response.data.bairro),
      //         cep: response.data.cep,
      //         cnpj,
      //         razao_social: capitalize(response.data.nome),
      //         nome_fantasia: capitalize(response.data.fantasia),
      //         email: response.data.email,
      //         complemento: capitalize(response.data.complemento),
      //         numero: response.data.numero
      //       }

      //       setNewCliente(clienteCnpj)
      //     } else {
      //       const clienteCnpj: Cliente = {
      //         ...newCliente,
      //         cnpj
      //       }

      //       setNewCliente(clienteCnpj)
      //     }
      //   } catch (error) {
      //     const clienteCnpj: Cliente = {
      //       ...newCliente,
      //       cnpj
      //     }

      //     setNewCliente(clienteCnpj)
      //   }
      // }
    },
    [busaClienteCnpj, handleOnChangeFields, newCliente]
  )

  const handleAfterAll = async () => {
    setNewCliente(undefined)
    if (mode === 'edit' && cliente) {
      dispatch(
        setarBotaoAtivoCliente({
          clienteId: cliente.id as number,
          botao: 'geral'
        })
      )
    }
  }

  useEffect(() => {
    if (clienteComContatos !== undefined) {
      setShowListaContatos(true)
    }
  }, [clienteComContatos])

  const handleSalvarCliente = async () => {
    if (mode === 'edit') {
      if (newCliente) {
        const response = await executeUseCase<UpdateParams>(
          ataulizarCliente,
          {
            id: cliente?.id as number,
            body: deleteEmptyPropsFromObject(
              sanetizeClienteToUpdate(newCliente)
            )
          },
          `Os dados do cliente - ${cliente?.id} foram atualizados`,
          `Atualizar os dados do cliente - ${cliente?.id}`,
          handleAfterAll
        )

        if (response) {
          dispatch(
            setarAtualizarCliente({
              clienteId: cliente?.id as number,
              cliente: response?.id
            })
          )
        }
      }
    } else {
      if (newCliente) {
        const response = await executeUseCase<PostParams>(
          cadastrarCliente,
          {
            body: deleteEmptyPropsFromObject(
              sanetizeClienteToCreate(newCliente)
            )
          },
          '',
          'Salvar novo cliente?',
          handleAfterAll
        )

        if (response) {
          let questionResponse = await Swal.fire({
            icon: 'question',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar',
            text: 'Deseja abrir um atendimento?',
            showCloseButton: true,
            showLoaderOnConfirm: true,
            showCancelButton: true
          })

          if (questionResponse.isConfirmed) {
            getClientePorId({ clienteId: response.id as number, revenda: false })
          } else {
            Swal.fire(
              'Sucesso',
              `Cliente: cód. - ${response.id}`,
              'success'
            )
            setNewCliente(ClienteInitialState)
            history.push('/clientes')
          }
        }
      }
    }
  }

  const handleModalClose = useCallback(() => {
    getClientePorId({})
    setShowListaContatos(false)
  }, [getClientePorId])

  const handleCallbackListaContatos = useCallback(async (contato: Contato) => {
    setShowListaContatos(false)

    if (!clienteComContatos || !contato || !funcionario?.data) {
      return
    }

    dispatch(iniciarAtendimento({
      botaoAtendimentoAtivo: 'geral',
      cliente: clienteComContatos,
      contato,
      funcionario: funcionario?.data
    }))
  }, [clienteComContatos, dispatch, funcionario])

  const handleNovoContato = () => {
    setContatoEmEdicao(false)
    setFormContato(newFormContato)
    setShowModalContato(true)
  }

  const handleOnSaveContato = () => {
    setShowModalContato(false)
    if (contatoIndex >= 0) {
      if (mode === 'edit') {
        if (temAlteracoes) {
          if (newCliente) {
            let newContatos = [...(newCliente.contatos as Contato[])]
            newContatos[contatoIndex] = formContato
            setNewCliente({
              ...newCliente,
              contatos: newContatos
            })
          }
        } else {
          let newContatos = [...(cliente?.contatos as Contato[])]
          newContatos[contatoIndex] = formContato
          setNewCliente({
            ...cliente,
            contatos: newContatos
          })
        }
      } else {
        if (newCliente) {
          let newContatos = [...(newCliente.contatos as Contato[])]
          newContatos[contatoIndex] = formContato
          setNewCliente({
            ...newCliente,
            contatos: newContatos
          })
        }
      }
    } else {
      if (mode === 'edit') {
        if (temAlteracoes) {
          if (newCliente) {
            let newContatos = newCliente.contatos
              ? [...(newCliente.contatos as Contato[])]
              : ([] as Contato[])
            newContatos.push(formContato)
            setNewCliente({
              ...newCliente,
              contatos: newContatos
            })
          }
        } else {
          let newContatos = cliente?.contatos
            ? [...(cliente?.contatos as Contato[])]
            : ([] as Contato[])
          newContatos.push(formContato)
          setNewCliente({
            ...cliente,
            contatos: newContatos
          })
        }
      } else {
        if (newCliente) {
          let newContatos = newCliente.contatos
            ? [...(newCliente.contatos as Contato[])]
            : ([] as Contato[])
          newContatos.push(formContato)
          setNewCliente({
            ...newCliente,
            contatos: newContatos
          })
        }
      }
    }
    setContatoIndex(-1)
    setFormContato(newFormContato)
  }

  const handleCloseContato = () => {
    setContatoEmEdicao(false)
    setContatoIndex(-1)
    setShowModalContato(false)
  }

  const handleAltContato = (index: number) => {
    setContatoEmEdicao(true)
    setContatoIndex(index)
    if (mode === 'edit') {
      if (temAlteracoes) {
        if (newCliente && newCliente.contatos) {
          setFormContato(newCliente.contatos[index])
        }
      } else {
        if (cliente && cliente.contatos) {
          setFormContato(cliente.contatos[index])
        }
      }
    } else {
      if (newCliente && newCliente.contatos) {
        setFormContato(newCliente.contatos[index])
      }
    }
    setShowModalContato(true)
  }

  const handleButtonAddTelefone = (index: number) => {
    setShowModalTelefone(true)
    setFormContato(newFormContato)
    setContatoIndex(index)
  }

  const handleOnSaveTelefone = () => {
    setShowModalTelefone(false)
    if (telefoneIndex >= 0) {
      if (mode === 'edit') {
        if (temAlteracoes) {
          if (newCliente && newCliente.contatos) {
            let newContatos = [...(newCliente.contatos as Contato[])]
            let newTelefones = [
              ...(newContatos[contatoIndex].telefones as Telefone[])
            ]
            newTelefones[telefoneIndex] = formTelefone

            newContatos[contatoIndex] = {
              ...newContatos[contatoIndex],
              telefones: newTelefones
            }

            setNewCliente({
              ...newCliente,
              contatos: newContatos
            })
          }
        } else {
          if (cliente?.contatos) {
            let newContatos = [...(cliente?.contatos as Contato[])]
            let newTelefones = [
              ...(newContatos[contatoIndex].telefones as Telefone[])
            ]

            newTelefones = newTelefones.map((t) => {
              if (formTelefone.id === t.id) {
                return formTelefone
              } else {
                return t
              }
            })

            newContatos[contatoIndex] = {
              ...newContatos[contatoIndex],
              telefones: newTelefones
            }
            setNewCliente({
              ...cliente,
              contatos: newContatos
            })
          }
        }
      } else {
        if (newCliente && newCliente.contatos) {
          let newContatos = [...(newCliente.contatos as Contato[])]
          let newTelefones = [
            ...(newContatos[contatoIndex].telefones as Telefone[])
          ]

          newTelefones[telefoneIndex] = formTelefone

          newContatos[contatoIndex] = {
            ...newContatos[contatoIndex],
            telefones: newTelefones
          }
          setNewCliente({
            ...newCliente,
            contatos: newContatos
          })
        }
      }
    } else {
      if (mode === 'edit') {
        if (temAlteracoes) {
          if (newCliente && newCliente.contatos) {
            let newContatos = [...(newCliente.contatos as Contato[])]
            let newTelefones = [
              ...(newContatos[contatoIndex].telefones as Telefone[])
            ]
            newTelefones.push(formTelefone)

            newContatos[contatoIndex] = {
              ...newContatos[contatoIndex],
              telefones: newTelefones
            }
            setNewCliente({
              ...newCliente,
              contatos: newContatos
            })
          }
        } else {
          if (cliente?.contatos) {
            let newContatos = [...(cliente?.contatos as Contato[])]
            let newTelefones = [
              ...(newContatos[contatoIndex].telefones as Telefone[])
            ]
            newTelefones.push(formTelefone)

            newContatos[contatoIndex] = {
              ...newContatos[contatoIndex],
              telefones: newTelefones
            }

            setNewCliente({
              ...cliente,
              contatos: newContatos
            })
          }
        }
      } else {
        if (newCliente && newCliente.contatos) {
          let newContatos = [...(newCliente.contatos as Contato[])]
          let newTelefones = [
            ...(newContatos[contatoIndex].telefones as Telefone[])
          ]
          newTelefones.push(formTelefone)

          newContatos[contatoIndex] = {
            ...newContatos[contatoIndex],
            telefones: newTelefones
          }

          setNewCliente({
            ...newCliente,
            contatos: newContatos
          })
        }
      }
    }
    setFormTelefone(newFormTelefone)
    setContatoIndex(-1)
    setTelefoneIndex(-1)
  }

  const handleCloseTelefone = () => {
    setTelefoneEmEdicao(false)
    setShowModalTelefone(false)
  }

  const handleButtonAltTelefone = (
    indexContato: number,
    indexTelefone: number
  ) => {
    setTelefoneEmEdicao(true)
    setContatoIndex(indexContato)
    setTelefoneIndex(indexTelefone)
    let telefone = {} as Telefone
    if (mode === 'edit') {
      if (temAlteracoes) {
        if (newCliente && newCliente.contatos) {
          const contato = newCliente.contatos[indexContato]
          if (contato && contato.telefones) {
            telefone = contato.telefones[indexTelefone]
          }
        }
      } else {
        if (cliente && cliente.contatos) {
          const contato = cliente.contatos[indexContato]
          if (contato && contato.telefones) {
            telefone = contato.telefones[indexTelefone]
          }
        }
      }
    } else {
      if (newCliente && newCliente.contatos) {
        const contato = newCliente.contatos[indexContato]
        if (contato && contato.telefones) {
          telefone = contato.telefones[indexTelefone]
        }
      }
    }
    setFormTelefone(telefone)
    setShowModalTelefone(true)
  }

  const handleButtonDelTelefone = (
    indexContato: number,
    indexTelefone: number
  ) => {
    if (mode === 'edit') {
      if (temAlteracoes) {
        if (newCliente && newCliente.contatos) {
          let newContatos = [...(newCliente.contatos as Contato[])]
          let contato = { ...newContatos[indexContato] }
          let newTelefones = [...(contato.telefones as Telefone[])]
          contato.telefones = newTelefones.filter(
            (item, index) => index !== indexTelefone
          )
          newContatos[indexContato] = contato
          setNewCliente({
            ...newCliente,
            contatos: newContatos
          })
        }
      } else {
        if (cliente?.contatos) {
          let newContatos = [...(cliente?.contatos as Contato[])]
          let contato = { ...newContatos[indexContato] }
          let newTelefones = [...(contato.telefones as Telefone[])]
          contato.telefones = newTelefones.filter(
            (item, index) => index !== indexTelefone
          )
          newContatos[indexContato] = contato
          setNewCliente({
            ...cliente,
            contatos: newContatos
          })
        }
      }
    } else {
      if (newCliente && newCliente.contatos) {
        let newContatos = [...(newCliente.contatos as Contato[])]
        let contato = { ...newContatos[indexContato] }
        let newTelefones = [...(contato.telefones as Telefone[])]
        contato.telefones = newTelefones.filter(
          (item, index) => index !== indexTelefone
        )
        newContatos[indexContato] = contato
        setNewCliente({
          ...newCliente,
          contatos: newContatos
        })
      }
    }
  }

  const columns: Column[] = useMemo(() => {
    if (isMobile) {
      return [
        {
          Header: 'Nome',
          accessor: 'nome',
          Cell: ({ value }) => <span>{capitalize(value)}</span>
        },
        {
          Header: 'Status',
          accessor: 'status',
          minWidth: 20,
          // eslint-disable-next-line react/display-name
          Cell: ({ value }) => (
            <TextStatus status={value?.toLowerCase()}>
              {capitalize(value)}
            </TextStatus>
          )
        },
        {
          Header: 'Ações',
          minWidth: 25,
          // eslint-disable-next-line react/display-name
          Cell: ({ row, index }) => {
            return (
              <TableActions>
                <ButtonTable
                  title="Editar contato"
                  type="button"
                  typeButton="secondary"
                  onClick={() => handleAltContato(index)}
                >
                  <RiPencilFill size="15px" />
                </ButtonTable>
              </TableActions>
            )
          }
        }
      ]
    } else {
      return [
        {
          Header: 'Nome',
          accessor: 'nome',
          Cell: ({ value }) => <span>{capitalize(value)}</span>
        },
        {
          Header: 'E-mail',
          accessor: 'email',
          Cell: ({ value }) => <span>{value?.toString().toLowerCase()}</span>
        },
        {
          Header: 'Status',
          accessor: 'status',
          minWidth: 20,
          // eslint-disable-next-line react/display-name
          Cell: ({ value }) => (
            <TextStatus status={value?.toLowerCase()}>
              {capitalize(value)}
            </TextStatus>
          )
        },
        {
          Header: 'Fiscal',
          accessor: 'fiscal',
          minWidth: 20,
          Cell: ({ value }) => {
            if (value === 's') {
              return (
                <TableText position="center">
                  <FaCheck />
                </TableText>
              )
            } else {
              return ''
            }
          }
        },
        {
          Header: 'Comercial',
          accessor: 'comercial',
          minWidth: 20,
          Cell: ({ value }) => {
            if (value === 's') {
              return (
                <TableText position="center">
                  <FaCheck />
                </TableText>
              )
            } else {
              return ''
            }
          }
        },
        {
          Header: 'Financeiro',
          accessor: 'financeiro',
          minWidth: 20,
          Cell: ({ value }) => {
            if (value === 's') {
              return (
                <TableText position="center">
                  <FaCheck />
                </TableText>
              )
            } else {
              return ''
            }
          }
        },
        {
          Header: 'Telefones',
          minWidth: 25,
          // eslint-disable-next-line react/display-name
          Cell: ({ row, index }) => {
            return (
              <TableActions>
                <ButtonTable
                  title="Adicionar telefone"
                  type="button"
                  typeButton="primary"
                  onClick={() => handleButtonAddTelefone(index)}
                >
                  <BsPlus size="15px" />
                </ButtonTable>
              </TableActions>
            )
          }
        },
        {
          Header: 'Ações',
          minWidth: 25,
          // eslint-disable-next-line react/display-name
          Cell: ({ row, index }) => {
            return (
              <TableActions>
                <ButtonTable
                  title="Editar contato"
                  type="button"
                  typeButton="secondary"
                  onClick={() => handleAltContato(index)}
                >
                  <RiPencilFill size="15px" />
                </ButtonTable>
              </TableActions>
            )
          }
        }
      ]
    }
  }, [handleAltContato, handleButtonAddTelefone, isMobile])

  const getPages = () => {
    let pages = 0
    if (mode === 'registro') {
      if (newCliente) {
        pages = newCliente.contatos?.length
          ? Math.ceil(newCliente.contatos?.length / 10)
          : 0
      }
    } else {
      if (temAlteracoes) {
        if (newCliente) {
          pages = newCliente.contatos?.length
            ? Math.ceil(newCliente.contatos?.length / 10)
            : 0
        }
      } else {
        if (cliente) {
          pages = cliente.contatos?.length
            ? Math.ceil(cliente.contatos?.length / 10)
            : 0
        }
      }
    }
    return pages
  }

  const handlePfChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value === 'pj'
      setClientePj(value)
      let newClientePj = {} as Cliente

      if (mode === 'edit') {
        if (temAlteracoes) {
          newClientePj = {
            ...newCliente,
            pj: value
          }
        } else {
          newClientePj = {
            ...cliente,
            pj: value
          }
        }
      } else {
        newClientePj = {
          ...newCliente,
          pj: value
        }
      }

      setNewCliente(newClientePj)
    },
    [cliente, mode, newCliente, temAlteracoes]
  )

  const upperCaseString = (text: string) => {
    if (!text) {
      return ''
    } else {
      return text.toUpperCase()
    }
  }

  return (
    <Container>
      {executing || loadingClientes ? <Loading /> : ''}
      {/* cliente */}
      <section>
        <SectionHeader>
          <span>Cliente</span>
          <Button
            mode="success"
            type="button"
            onClick={handleSalvarCliente}
            disabled={!temAlteracoes}
            startIcon={FaSave}
            data-cy="cadastroClienteSaveButton"
          >
            Salvar
          </Button>
        </SectionHeader>
        {mode === 'edit' && (
          <FormRow className="mobile">
            <Input
              name="codigo_cliente"
              label="Código Cliente"
              value={getValues('id') as string}
              placeholder="Código Cliente"
              width="1"
              copyValue
              disabled
            />
            {/* TODO add cor no status */}
            <Input
              name="status"
              label="Status"
              value={capitalize(getValues('status') as string)}
              placeholder="Status"
              width="1"
              disabled
            />
            <Input
              name="classe_cliente"
              label="Classe"
              value={capitalize(getValues('classe') as string)}
              placeholder="Classe Cliente"
              width="1"
              disabled
            />
            <Input
              name="ramo_cliente"
              label="Ramo Atividade"
              value={capitalize(getValues('ramo_atividade') as string)}
              placeholder="Ramo Atividade"
              width="1"
              disabled
            />
          </FormRow>
        )}
        <FormRow className="mobile">
          <Input
            name="cnpj"
            label={!clientePj ? 'CPF' : 'CNPJ'}
            value={getValues('cnpj') as string}
            placeholder={!clientePj ? 'CPF' : 'CNPJ'}
            onChange={handleInputCNPJ}
            type={!clientePj ? 'cpf' : 'cnpj'}
            copyValue
            disabled={mode === 'edit'}
            data-cy="cadastroClienteCnpj"
          />
          <Input
            name="ie"
            label="Inscrição Estadual"
            value={getValues('ie') as string}
            placeholder="Inscrição estadual"
            onChange={handleOnChangeFields}
            type={getValues('ie') ? (`ie-${cliente?.uf}` as IEType) : 'text'}
            copyValue
            disabled={mode === 'edit'}
            data-cy="cadastroClienteIe"
            maxLength={20}
          />
        </FormRow>
        <FormRow className="mobile">
          <Input
            name="nome_fantasia"
            label={!clientePj ? 'Nome' : 'Nome Fantasia'}
            value={
              mode === 'edit'
                ? capitalize(getValues('nome_fantasia') as string)
                : (getValues('nome_fantasia') as string)
            }
            placeholder={!clientePj ? 'Nome' : 'Nome fantasia'}
            onChange={handleOnChangeFields}
            disabled={mode === 'edit'}
            data-cy="cadastroClienteNome"
            maxLength={125}
          />
          <Input
            name="razao_social"
            label="Razão Social"
            type="text"
            value={
              mode === 'edit'
                ? capitalize(getValues('razao_social') as string)
                : (getValues('razao_social') as string)
            }
            onChange={handleOnChangeFields}
            placeholder="Razão social"
            disabled={mode === 'edit'}
            data-cy="cadastroClienteRazao"
            maxLength={150}
          />
        </FormRow>
        <DivForm>
          <RadioButtons>
            <span>Tipo</span>
            <Divider>
              <RadioButton
                name="pj"
                checked={clientePj}
                onChange={handlePfChange}
                value="pj"
                title="Pessoa Jurídica"
                disabled={mode === 'edit'}
              />
              <RadioButton
                name="pj"
                checked={!clientePj}
                onChange={handlePfChange}
                value="pf"
                title="Pessoa Física"
                disabled={mode === 'edit'}
              />
            </Divider>
            <RadioButtons>
              <span>Internet</span>
              <Divider>
                <CheckBox
                  name="exibir_internet"
                  checked={getValues('exibir_internet') === 's'}
                  onChange={handleOnChangeFields}
                  title="Exibir Internet"
                  disabled
                />
              </Divider>
            </RadioButtons>
          </RadioButtons>
          <RadioButtons>
            <span>Grupo</span>
            <Divider>
              <CheckBox
                name="is_cliente_final"
                checked={getValues('is_cliente_final') === 's'}
                onChange={handleOnChangeFields}
                title="Cliente Final"
                disabled={mode === 'edit'}
              />
              <CheckBox
                name="is_revenda"
                checked={getValues('is_revenda') === 's'}
                onChange={handleOnChangeFields}
                title="Revenda"
                disabled={mode === 'edit'}
              />
            </Divider>
            <Divider>
              <CheckBox
                name="is_isento"
                checked={getValues('ie') === 'isento'}
                onChange={handleOnChangeFields}
                title="Isento"
                disabled
              />
              <CheckBox
                name="is_orgao_estadual"
                checked={getValues('is_orgao_estadual') === 's'}
                onChange={handleOnChangeFields}
                title="Órgão Estadual"
                disabled={mode === 'edit'}
              />
            </Divider>
          </RadioButtons>
        </DivForm>
        <FormRow className="mobile">
          <Input
            name="email"
            className="email"
            label="E-mail Principal"
            value={getValues('email') as string}
            placeholder="E-mail principal"
            onChange={handleOnChangeFields}
            copyValue
            data-cy="cadastroClienteEmail"
            maxLength={200}
          />
          <Input
            name="email_nfe"
            className="email"
            label="Email Nota Fiscal Eletrônica"
            value={getValues('email_nfe') as string}
            placeholder="E-mail nota fiscal eletrônica"
            onChange={handleOnChangeFields}
            copyValue
            disabled={mode === 'edit'}
            data-cy="cadastroClienteEmailNfe"
            maxLength={200}
          />
          <Input
            name="email_nfe2"
            className="email"
            label="Email Nota Fiscal Eletrônica 2"
            value={getValues('email_nfe2') as string}
            placeholder="E-mail nota fiscal eletrônica"
            onChange={handleOnChangeFields}
            copyValue
            disabled={mode === 'edit'}
            data-cy="cadastroClienteEmailNfe2"
            maxLength={200}
          />
        </FormRow>
        <FormRow className="mobile">
          <Textarea
            label="Observação"
            name="observacao"
            value={getValues('observacao') as string}
            onChange={handleOnChangeTextAreas}
            disabled={mode === 'edit'}
            height="90px"
          />
          <Textarea
            label="Observação Tela"
            name="observacao_tela"
            value={getValues('observacao_tela') as string}
            onChange={handleOnChangeTextAreas}
            disabled={mode === 'edit'}
            height="90px"
          />
        </FormRow>
      </section>
      {/* endereco */}
      <section>
        <SectionHeader>
          <span>Endereco</span>
        </SectionHeader>
        <FormRow className="mobile">
          <Input
            width="1"
            name="cep"
            label="CEP"
            value={getValues('cep') as string}
            placeholder="CEP"
            onChange={handleCepInputChange}
            error={cepError}
            type="cep"
            disabled={mode === 'edit'}
            data-cy="cadastroClienteCep"
            // maxLength={10}
          />

          <Input
            width="3"
            name="endereco"
            label="Endereço"
            value={getValues('endereco') as string}
            placeholder="Endereço"
            onChange={handleOnChangeFields}
            disabled={mode === 'edit'}
            data-cy="cadastroClienteEnderecoInput"
            maxLength={100}
          />
          <Input
            width="1"
            name="numero"
            label="Número"
            value={getValues('numero') as string}
            placeholder="Número"
            onChange={handleOnChangeFields}
            disabled={mode === 'edit'}
            data-cy="cadastroClienteNumero"
            maxLength={10}
          />
          <Input
            width="2"
            name="bairro"
            label="Bairro"
            value={getValues('bairro') as string}
            placeholder="Bairro"
            onChange={handleOnChangeFields}
            disabled={mode === 'edit'}
            data-cy="cadastroClienteBairro"
            maxLength={200}
          />
        </FormRow>
        <FormRow className="mobile">
          <Select
            width="1"
            name="uf"
            onChange={handleSelectChange}
            value={upperCaseString(getValues('uf') as string)}
            title="Estado"
            disabled={mode === 'edit'}
            data-cy="cadastroClienteEstados"
          >
            <option value="" disabled selected>
              Estado
            </option>
            {EstadosMunicipios?.estados?.map((estado) => (
              <option key={estado.sigla} value={estado.sigla}>
                {estado.nome}
              </option>
            ))}
          </Select>
          <Select
            name="cidade"
            width="1"
            onChange={handleSelectChange}
            value={getValues('cidade') as string}
            title="Cidade"
            disabled={mode === 'edit'}
            data-cy="cadastroClienteCidade"
          >
            <option value="" disabled={mode === 'edit'} selected>
              {mode === 'registro'
                ? 'Cidade'
                : capitalize(getValues('cidade') as string)}
            </option>
            {controlCidades?.map((cidade) => (
              <option key={cidade} value={cidade}>
                {cidade}
              </option>
            ))}
          </Select>
          <Input
            width="2"
            name="complemento"
            label="Complemento"
            value={getValues('complemento') as string}
            placeholder="Complemento do endereço"
            onChange={handleOnChangeFields}
            disabled={mode === 'edit'}
            data-cy="cadastroClienteComplemento"
            maxLength={100}
          />
          <Input
            width="1"
            name="regiao"
            label="Região"
            value={getValues('regiao') as string}
            placeholder="Região"
            onChange={handleOnChangeFields}
            disabled={mode === 'edit'}
            data-cy="cadastroClienteRegiao"
            maxLength={20}
          />
        </FormRow>
      </section>
      {/* contato */}
      <section>
        <SectionHeader>
          <span>Contato</span>
          <div>
            <Button
              mode="confirm"
              type="button"
              onClick={() => handleNovoContato()}
              startIcon={FaUserPlus}
              data-cy="cadastroClienteNovoContato"
            >
              Novo Contato
            </Button>
          </div>
        </SectionHeader>
        <Table
          columns={columns}
          showPageJump={false}
          data={getValues('contatos') as Contato[]}
          pageSize={10}
          page={0}
          pages={getPages()}
          manual
          loading={false}
          sortable={false}
          showPageSizeOptions={false}
          noDataText="Nenhum contato encontrado"
          showPagination={false}
          selectRow
          SubComponent={(mainRow) => {
            const columnsTelefone: Column[] = [
              {
                Header: 'DDD',
                accessor: 'ddd',
                minWidth: 20
              },
              {
                Header: 'Telefone',
                accessor: 'telefone'
              },
              {
                Header: 'Ramal',
                accessor: 'ramal',
                minWidth: 25
              },
              {
                Header: 'WhatsApp',
                accessor: 'whatsapp',
                minWidth: 30,
                Cell: ({ value }) => {
                  return (
                    <TextStatus status={value === 's' ? 'sim' : 'nao'}>
                      {value === 's' ? 'Sim' : 'Não'}
                    </TextStatus>
                  )
                }
              },
              {
                Header: 'Ações',
                minWidth: 25,
                Cell: ({ row: subRow, index: indexTelefone }) => {
                  return (
                    <TableActions>
                      <ButtonTable
                        type="button"
                        typeButton="danger"
                        onClick={() =>
                          handleButtonDelTelefone(mainRow.index, indexTelefone)
                        }
                      >
                        <MdDelete size="15px" />
                      </ButtonTable>
                      <ButtonTable
                        type="button"
                        typeButton="secondary"
                        onClick={() =>
                          handleButtonAltTelefone(mainRow.index, indexTelefone)
                        }
                      >
                        <RiPencilFill size="15px" />
                      </ButtonTable>
                    </TableActions>
                  )
                }
              }
            ]

            return (
              <Telefones>
                <Table
                  columns={columnsTelefone}
                  data={mainRow?.original?.telefones}
                  showPagination={false}
                  pageSize={mainRow?.original?.telefones?.length}
                  minRows={2}
                  noDataText="Sem telefones cadastrados"
                />
              </Telefones>
            )
          }}
        />
      </section>
      {showModalContato && (
        <Modal
          title={!contatoEmEdicao ? 'Cadastro de contato' : 'Edição de contato'}
          close={handleCloseContato}
          onSave={handleOnSaveContato}
          showButtonSave
          buttonSaveText={!contatoEmEdicao ? 'Adicionar' : 'Salvar'}
        >
          <CadastroContato
            contato={formContato}
            setFormContato={setFormContato}
          />
        </Modal>
      )}
      {showModalTelefone && (
        <Modal
          title={
            !telefoneEmEdicao ? 'Cadastro de telefone' : 'Edição de telefone'
          }
          close={handleCloseTelefone}
          onSave={handleOnSaveTelefone}
          showButtonSave
          buttonSaveText={!telefoneEmEdicao ? 'Adicionar' : 'Salvar'}
        >
          <CadastroTelefone
            formTelefone={formTelefone}
            setFormTelefone={setFormTelefone}
          />
        </Modal>
      )}
      {showListaContatos && (
        <Modal title="Selecione um contato para continuar" close={handleModalClose}>
          <ListaContatos
            cliente={clienteComContatos as Cliente}
            callBack={handleCallbackListaContatos}
            loadingCliente={loadingCliente}
          />
        </Modal>
      )}
    </Container>
  )
}
