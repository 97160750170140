import { useContext } from 'react'
import { MainTabsContext, MainTabsContextProps } from '../../contexts'

export const useMainTabs = (): MainTabsContextProps => {
  const context = useContext(MainTabsContext)

  if (!context) {
    console.error('useMainTabs deve ser utilizado com MainTabsProvider')
  }

  return context
}
