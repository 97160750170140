import { GetParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { TrazerDadosClienteReceitaService } from '../contracts'
import { GetDadosClienteCNPJResponse } from '../models'
import { TrazerDadosReceitaRespApi } from './_interfaces'

export class TrazerDadosReceitaClienteServiceRest implements TrazerDadosClienteReceitaService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async trazerDadosReceitaCliente (params: GetParams): Promise<GetDadosClienteCNPJResponse> {
    if (!process.env.REACT_APP_API_CNPJ_TOKEN) {
      return {} as GetDadosClienteCNPJResponse
    }

    const response = await this.httpRequest.get<TrazerDadosReceitaRespApi>({
      url: 'https://www.receitaws.com.br/v1/',
      path: `cnpj/${params.filter}/days/10`,
      token: process.env.REACT_APP_API_CNPJ_TOKEN
    })

    return response as GetDadosClienteCNPJResponse
  }
}
