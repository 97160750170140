import { UseCase } from '../../_interfaces'
import { ListarNotificacoesFuncionarioService } from '../contracts'
import { GetListaNotificacaoResponse } from '../models'
import { ListarNotificacoesFuncionarioParams } from '../_interfaces'
export class ListarNotificacoesFuncionario implements UseCase<ListarNotificacoesFuncionarioParams, GetListaNotificacaoResponse> {
  private readonly listarNotificacoesFuncionarioFuncionarioService: ListarNotificacoesFuncionarioService

  constructor (listarNotificacoesFuncionarioFuncionarioService: ListarNotificacoesFuncionarioService) {
    this.listarNotificacoesFuncionarioFuncionarioService = listarNotificacoesFuncionarioFuncionarioService
  }

  async execute (params: ListarNotificacoesFuncionarioParams): Promise<GetListaNotificacaoResponse> {
    const response = await this.listarNotificacoesFuncionarioFuncionarioService.listarNotificacoes(params)
    return response
  }
}
