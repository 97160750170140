import { handleErrors } from '../../../helpers/handleErrors'
import { PostParams, Validator } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { AutenticarUsuarioService } from '../contracts'
import { LogarResp } from '../models/usuario'
import { LoginRespApi } from './_interfaces'
export class AutenticarUsuarioServiceRest implements AutenticarUsuarioService {
  private readonly validator: Validator
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest, validator: Validator) {
    this.validator = validator
    this.httpRequest = httpRequest
  }

  async logar (params: PostParams): Promise<LogarResp> {
    await this.validator?.validate(params.body)

    const response = await this.httpRequest.post<LoginRespApi>({
      body: params.body,
      path: 'usuarios/login'
    })

    handleErrors(response.error)
    return response.data as LogarResp
  }
}
