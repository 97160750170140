import capitalize from 'capitalize-pt-br'
import React, { useCallback, useEffect, useState } from 'react'
import { FaCheck, FaSearch } from 'react-icons/fa'
import { ButtonTable, Input, InputF2, Table, TableColumnProps } from '..'
import { Cliente, Contato } from '../../../modules/clientes/models'
import { TableActions } from '../../styles/global'
import { TableCenterContent } from '../Contatos/styles'

import { Container, Header } from './styles'

const rowsPerPage = 10

interface ListaContatosProps extends InputF2 {
  cliente: Cliente,
  loadingCliente: boolean
}

export const ListaContatos = ({ cliente, close, callBack, loadingCliente }: ListaContatosProps) => {
  const [filtredContatos, setFiltredContatos] = useState<Contato[]>()

  useEffect(() => {
    if (cliente && cliente.contatos) {
      setFiltredContatos(cliente?.contatos.filter((contato) => contato.status === 'ativo' || contato.nome === 'padrao'))
    }
  }, [cliente])

  const [searchText, setSearchText] = useState('')

  const columns: TableColumnProps[] = [
    {
      Header: 'Nome',
      accessor: 'nome',
      minWidth: 50,
      Cell: ({ value }) => capitalize(value)
    },
    {
      Header: 'E-mail',
      accessor: 'email',
      minWidth: 50,
      Cell: ({ value }) => value?.toString().toLowerCase()
    },
    {
      Header: 'Fiscal',
      accessor: 'fiscal',
      minWidth: 30,
      Cell: ({ value }) => {
        if (value === 's') {
          return <TableCenterContent><FaCheck /></TableCenterContent>
        } else {
          return ''
        }
      }
    },
    {
      Header: 'Comercial',
      accessor: 'comercial',
      minWidth: 30,
      Cell: ({ value }) => {
        if (value === 's') {
          return <TableCenterContent><FaCheck /></TableCenterContent>
        } else {
          return ''
        }
      }
    },
    {
      Header: 'Financeiro',
      accessor: 'financeiro',
      minWidth: 30,
      Cell: ({ value }) => {
        if (value === 's') {
          return <TableCenterContent><FaCheck /></TableCenterContent>
        } else {
          return ''
        }
      }
    },
    {
      Header: 'Ações',
      minWidth: 25,
      Cell: ({ row, index }) => {
        const contato = row._original as Contato
        return (
          <TableActions>
            <ButtonTable
              data-cy={'atenderCliente-' + index}
              typeButton='primary'
              title="Atender cliente"
              onClick={() => dbClickTableRowOnclick(contato)}
            >
              <FaCheck size='15px' />
            </ButtonTable>
          </TableActions>
        )
      }
    }
  ]

  const dbClickTableRowOnclick = useCallback((contato: Contato) => {
    callBack && callBack(contato)
    close && close()
  }, [callBack, close])

  const debouncedFilter = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const contatos = filtredContatos?.filter(contato => {
        let result = false

        if (contato.email?.toLowerCase().includes(searchText.toLowerCase())) {
          result = true
        }

        if (contato.nome?.toLowerCase().includes(searchText.toLowerCase())) {
          result = true
        }

        return result
      })

      contatos?.length && setFiltredContatos(contatos)
    }
  }, [filtredContatos, searchText])

  const handleFilterOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }, [])

  return (
    <Container>
      <Header>
        <div>
          <Input type='text' startIcon={FaSearch} onChange={handleFilterOnChange} value={searchText} autoFocus onKeyPress={debouncedFilter}/>
        </div>

      </Header>
      <Table
        className='tableContatosCliente'
        tabIndex={0}
        columns={columns}
        showPageJump={false}
        data={filtredContatos && filtredContatos.length > 0 ? filtredContatos : cliente?.contatos}
        pageSize={rowsPerPage}
        sortable={true}
        showPageSizeOptions={false}
        loading={loadingCliente}
        noDataText="Nenhum contato cadastrado"
        nextText='Próximo'
        ofText='de'
        previousText='Anterior'
        showPagination={cliente && cliente.contatos && cliente.contatos?.length >= rowsPerPage}
        pageText='Página'
        dbClickCallback={dbClickTableRowOnclick}
        selectRow
      />
    </Container>
  )
}
