import styled from 'styled-components'

export const Container = styled.div`
  header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    height: auto;
    top: 0px;
    left: 15px;
    z-index: 10;
  }
`

export const TabContent = styled.div`
  background-color:  ${props => props.theme.colors.backgroundLight};
  padding: 15px 15px 30px;
  border: solid 1px ${props => props.theme.colors.border};
  margin-top: -1px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
`
