import { useState } from 'react'
import { useUsuario } from '..'
import { Cliente } from '../../../modules/clientes/models'
import { makeRecalcularPedido } from '../../../modules/pedidos/factories'
import { GetPedidoResponse, Item, GetPedido } from '../../../modules/pedidos/models'
import { makeTrazerListaPreco, makeTrazerProdutoPorId } from '../../../modules/produtos/factories'
import { Kit, Preco } from '../../../modules/produtos/models'
import { ModeHandleGet } from '../../../modules/_interfaces'
import { ModePedido } from '../../components'
import { PedidoEmAberto, PedidoEmAndamento } from '../../store/atendimentos/interfaces'
import { useHandleErrors } from '../app/useHandleErrors'

const recalcularPedidoService = makeRecalcularPedido()

interface CalcPedidoProps {
  pedido: GetPedido
  mode: ModeHandleGet
}

interface BuildPedidoNewItem {
  mode: ModePedido
  pedido: PedidoEmAndamento | PedidoEmAberto
  item: Item
  zerarFrete: boolean
}

interface BuildPedidoNewArray {
  mode: ModePedido
  pedido: PedidoEmAndamento | PedidoEmAberto
  items: Item[]
  zerarFrete: boolean
}

interface BuildPedidoRemoveItemProps {
  mode: ModePedido
  pedido: PedidoEmAndamento | PedidoEmAberto
  itemId?: number
  itemIndex: number
  zerarFrete: boolean
}

interface BuildPedidoNewItemKit {
  mode: ModePedido
  pedido: PedidoEmAndamento | PedidoEmAberto
  item: Item
  zerarFrete: boolean
}

export function useCalcPedido () {
  const [loading, setLoading] = useState(false)
  // const [error, setError] = useState<any>()
  const { executing, handleError } = useHandleErrors()
  const { data: usuarioData } = useUsuario()

  async function recalcularPedido ({ pedido, mode }: CalcPedidoProps): Promise<GetPedidoResponse | undefined> {
    const params = {
      body: pedido,
      token: usuarioData?.token,
      mode
    }

    try {
      setLoading(true)
      const resp = await recalcularPedidoService.execute(params)
      setLoading(false)

      return resp
    } catch (error) {
      const err: any = error
      if (error && !executing) {
        const erro = {
          status: err.status || 400,
          message: err.message
        }
        console.log(err, erro)
        handleError(erro)
      }

      setLoading(false)
    }
  }

  function buildPedidoNewItem ({ mode, pedido, item, zerarFrete }: BuildPedidoNewItem) {
    let newItem = { ...item, data: new Date().toISOString() }
    let itensPedido: Item[] = [] as Item[]
    let frete_cotacao = 0
    let frete_valor = 0
    let newPedido = {} as GetPedido
    if (mode === 'register') {
      const pedidoEmAndamento = pedido as PedidoEmAndamento
      if (pedidoEmAndamento.pedido.itens) {
        itensPedido = [...pedidoEmAndamento.pedido.itens]
        let newIndex = -1
        itensPedido.forEach((element, index) => {
          if (newItem.produto?.controla_lote === 'n') {
            if (element.produto?.id === newItem.produto.id && newItem.valor === element.valor) {
              newIndex = index
            }
          }
        })

        if (newIndex >= 0) {
          itensPedido[newIndex] = {
            ...itensPedido[newIndex],
            quantidade: itensPedido[newIndex].quantidade + newItem.quantidade
          }
        } else {
          itensPedido.push(newItem)
        }
      } else {
        itensPedido = [newItem] as Item[]
      }

      if (!zerarFrete) {
        if (pedidoEmAndamento.pedido.frete_cotacao) {
          frete_cotacao = pedidoEmAndamento.pedido.frete_cotacao
        }
        if (pedidoEmAndamento.pedido.frete_valor) {
          frete_valor = pedidoEmAndamento.pedido.frete_valor
        }
      }

      newPedido = {
        ...pedidoEmAndamento.pedido,
        frete_cotacao,
        frete_valor,
        itens: itensPedido
      }
    } else {
      const pedidoEmAberto = pedido as PedidoEmAberto
      if (pedidoEmAberto.emEdicao && pedidoEmAberto.emEdicao.itens) {
        itensPedido = [...pedidoEmAberto.emEdicao.itens]
      } else if (pedidoEmAberto.original && pedidoEmAberto.original.itens) {
        itensPedido = [...pedidoEmAberto.original.itens as Item[]]
      }
      let newIndex = -1
      itensPedido.forEach((element, index) => {
        if (newItem.produto?.controla_lote === 'n') {
          if (element.produto?.id === newItem.produto.id &&
            newItem.valor === element.valor &&
            (element.quantidade_liberada ? element.quantidade_liberada === 0 : true)
          ) {
            newIndex = index
          }
        }
      })

      if (newIndex >= 0) {
        itensPedido[newIndex] = {
          ...itensPedido[newIndex],
          quantidade: itensPedido[newIndex].quantidade + newItem.quantidade
        }
      } else {
        itensPedido.push(newItem)
      }

      if (!zerarFrete) {
        if (pedidoEmAberto.emEdicao.frete_cotacao) {
          frete_cotacao = pedidoEmAberto.emEdicao.frete_cotacao
        } else if (pedidoEmAberto.original.frete_cotacao) {
          frete_cotacao = pedidoEmAberto.original.frete_cotacao
        }

        if (pedidoEmAberto.emEdicao.frete_valor) {
          frete_valor = pedidoEmAberto.emEdicao.frete_valor
        } else if (pedidoEmAberto.original.frete_valor) {
          frete_valor = pedidoEmAberto.original.frete_valor
        }
      }

      newPedido = {
        ...pedidoEmAberto.original,
        ...pedidoEmAberto.emEdicao,
        frete_cotacao,
        frete_valor,
        itens: itensPedido
      }
    }

    return newPedido
  }

  function buildPedidoNewArray ({ mode, pedido, items, zerarFrete }: BuildPedidoNewArray) {
    let newItems = items.map((item: Item) => {
      return { ...item, data: new Date().toISOString() }
    })
    let itensPedido: Item[] = [] as Item[]
    let frete_cotacao = 0
    let frete_valor = 0
    let newPedido = {} as GetPedido
    if (mode === 'register') {
      const pedidoEmAndamento = pedido as PedidoEmAndamento
      if (pedidoEmAndamento.pedido.itens?.length) {
        itensPedido = [...pedidoEmAndamento.pedido.itens]
        itensPedido = itensPedido.concat(newItems)
      } else {
        itensPedido = newItems as Item[]
      }

      if (!zerarFrete) {
        if (pedidoEmAndamento.pedido.frete_cotacao) {
          frete_cotacao = pedidoEmAndamento.pedido.frete_cotacao
        }
        if (pedidoEmAndamento.pedido.frete_valor) {
          frete_valor = pedidoEmAndamento.pedido.frete_valor
        }
      }

      newPedido = {
        ...pedidoEmAndamento.pedido,
        frete_cotacao,
        frete_valor,
        itens: itensPedido
      }
    } else {
      const pedidoEmAberto = pedido as PedidoEmAberto
      if (pedidoEmAberto.emEdicao && pedidoEmAberto.emEdicao.itens) {
        itensPedido = [...pedidoEmAberto.emEdicao.itens as Item[]]
        itensPedido = itensPedido.concat(newItems)
      } else if (pedidoEmAberto.original && pedidoEmAberto.original.itens) {
        itensPedido = [...pedidoEmAberto.original.itens as Item[]]
        itensPedido = itensPedido.concat(newItems)
      }

      if (!zerarFrete) {
        if (pedidoEmAberto.emEdicao.frete_cotacao) {
          frete_cotacao = pedidoEmAberto.emEdicao.frete_cotacao
        } else if (pedidoEmAberto.original.frete_cotacao) {
          frete_cotacao = pedidoEmAberto.original.frete_cotacao
        }

        if (pedidoEmAberto.emEdicao.frete_valor) {
          frete_valor = pedidoEmAberto.emEdicao.frete_valor
        } else if (pedidoEmAberto.original.frete_valor) {
          frete_valor = pedidoEmAberto.original.frete_valor
        }
      }

      newPedido = {
        ...pedidoEmAberto.original,
        ...pedidoEmAberto.emEdicao,
        frete_cotacao,
        frete_valor,
        itens: itensPedido
      }
    }
    return newPedido
  }

  function buildPedidoRemoveItem ({ mode, pedido, itemId, itemIndex, zerarFrete }: BuildPedidoRemoveItemProps) {
    let itensPedido: Item[] = [] as Item[]
    let frete_cotacao = 0
    let frete_valor = 0
    let newPedido = {} as GetPedido
    if (mode === 'register') {
      const pedidoEmAndamento = pedido as PedidoEmAndamento
      if (pedidoEmAndamento.pedido.itens) {
        const selectedItem = pedidoEmAndamento.pedido.itens[itemIndex]
        if (selectedItem.item_promocao_id) {
          let promocao_id = selectedItem.item_promocao_id
          itensPedido = pedidoEmAndamento.pedido.itens.filter(function (_item) {
            if (_item.item_promocao_id !== promocao_id && _item.id !== promocao_id) {
              return true
            } else { return false }
          })
        } else {
          itensPedido = pedidoEmAndamento.pedido.itens.filter(function (_item, index) {
            if (index !== itemIndex && _item.item_promocao_id !== selectedItem.id) {
              return true
            } else { return false }
          })
        }
      }

      if (!zerarFrete) {
        if (pedidoEmAndamento.pedido.frete_cotacao) {
          frete_cotacao = pedidoEmAndamento.pedido.frete_cotacao
        }
        if (pedidoEmAndamento.pedido.frete_valor) {
          frete_valor = pedidoEmAndamento.pedido.frete_valor
        }
      }
      newPedido = {
        ...pedidoEmAndamento.pedido,
        frete_cotacao,
        frete_valor,
        itens: itensPedido
      }
    } else {
      const pedidoEmAberto = pedido as PedidoEmAberto
      if (pedidoEmAberto) {
        const itensEmAberto = (pedidoEmAberto.emEdicao.itens && pedidoEmAberto.emEdicao.itens.length > 0) ? pedidoEmAberto.emEdicao.itens : pedidoEmAberto.original.itens as Item[]
        if (itensEmAberto && itensEmAberto.length) {
          const selectedItem = itensEmAberto[itemIndex]
          if (itemId) {
            if (selectedItem.item_promocao_id) {
              let promocao_id = selectedItem.item_promocao_id
              itensPedido = itensEmAberto.map((item: Item) => {
                if (item.item_promocao_id === promocao_id || item.id === promocao_id) {
                  return {
                    ...item,
                    quantidade: 0,
                    data: new Date().toISOString()
                  }
                } else {
                  return item
                }
              })
            } else {
              itensPedido = itensEmAberto.map((item, index) => {
                if (itemIndex === index || item.item_promocao_id === selectedItem.id) {
                  return {
                    ...item,
                    quantidade: 0,
                    data: new Date().toISOString()
                  }
                } else {
                  return item
                }
              })
            }
          } else {
            if (selectedItem.item_promocao_id) {
              let promocao_id = selectedItem.item_promocao_id
              itensPedido = itensEmAberto.filter(function (_item) {
                if (_item.item_promocao_id !== promocao_id && _item.id !== promocao_id) {
                  return true
                } else { return false }
              })
            } else {
              itensPedido = itensEmAberto.filter(function (_item, index) {
                if (index !== itemIndex && _item.item_promocao_id !== selectedItem.id) {
                  return true
                } else { return false }
              })
            }
          }
        }
      }

      if (!zerarFrete) {
        if (pedidoEmAberto.emEdicao.frete_cotacao) {
          frete_cotacao = pedidoEmAberto.emEdicao.frete_cotacao
        } else if (pedidoEmAberto.original.frete_cotacao) {
          frete_cotacao = pedidoEmAberto.original.frete_cotacao
        }

        if (pedidoEmAberto.emEdicao.frete_valor) {
          frete_valor = pedidoEmAberto.emEdicao.frete_valor
        } else if (pedidoEmAberto.original.frete_valor) {
          frete_valor = pedidoEmAberto.original.frete_valor
        }
      }

      newPedido = {
        ...pedidoEmAberto.original,
        ...pedidoEmAberto.emEdicao,
        frete_cotacao,
        frete_valor,
        itens: itensPedido
      }
    }
    return newPedido
  }

  async function buildPedidoNewItemKit ({ mode, pedido, item, zerarFrete }: BuildPedidoNewItemKit): Promise<GetPedido> {
    setLoading(true)
    let newPedido = {} as GetPedido
    let newItensPedido: Item[] = [] as Item[]
    let itensPedido: Item[] = [] as Item[]
    let produtosRepetidos = [] as number[]
    let frete_cotacao = 0
    let frete_valor = 0
    let fakeId = item.id ? item.id * -1 : 1
    const quantidadeDeKits = item.quantidade ? item.quantidade : 1
    const quantidadeDosProdutos = item.produto?.kit?.map(element => element.quantidade)

    // params das requests
    const produtosIds = item.produto?.kit?.map(element => element.produto_kit_id)
    let depositoId = 0
    let clientePreco = {} as Cliente
    let solar = 'n'
    if (mode === 'register') {
      const pedidoEmAndamento = pedido as PedidoEmAndamento
      clientePreco = pedidoEmAndamento.pedido.cliente as Cliente
      depositoId = pedidoEmAndamento.pedido.deposito?.id as number
      solar = pedidoEmAndamento.pedido.solar
    } else {
      const pedidoEmAberto = pedido as PedidoEmAberto
      clientePreco = pedidoEmAberto.emEdicao.cliente ? pedidoEmAberto.emEdicao.cliente as Cliente : pedidoEmAberto.original.cliente as Cliente
      depositoId = pedidoEmAberto.emEdicao.deposito?.id ? pedidoEmAberto.emEdicao.deposito?.id as number : pedidoEmAberto.original.deposito?.id as number
      solar = pedidoEmAberto.emEdicao.solar ? pedidoEmAberto.emEdicao.solar : pedidoEmAberto.original.solar
    }
    // request e montagem dos items
    if (produtosIds) {
      const trazerProdutoPorId = makeTrazerProdutoPorId()
      const trazerPrecosProdutos = makeTrazerListaPreco()
      let aumentoPorcentual = 0

      const responsePrecos = await trazerPrecosProdutos.execute({
        produtos: produtosIds as number[],
        empresas: [depositoId],
        clienteId: clientePreco?.id as number,
        token: usuarioData?.token as string,
        solar: solar
      })

      // calc a % do aumento do preco (multiplicado pelo valor real do produto resulta no valor do aumento, quando positivo, ou do desconto)
      if (responsePrecos.data) {
        let total = 0
        responsePrecos.data.forEach(element => {
          console.log('element', element)

          let itemKit: Kit = {}

          if (item?.produto?.kit !== undefined) {
            for (const i of item?.produto?.kit) {
              if (i.produto_kit_id === element.produto_id) {
                itemKit = i
              }
            }
          }
          console.log('itemKit', itemKit)
          if (itemKit.quantidade !== undefined) {
            total = total + (element.valor * itemKit.quantidade)
          } else {
            total = total + element.valor
          }
        })
        const valorItem = item.valor ? item.valor : 0
        aumentoPorcentual = (valorItem - total) / total
      }

      let precoItem = 0

      if (quantidadeDosProdutos) {
        responsePrecos.data.forEach((item, index) => {
          precoItem += (item.valor * quantidadeDosProdutos[index]! as number)
        })
      }

      let diferenca = item.valor ? ((item.valor - precoItem) / precoItem) * 100 : 0

      for (const element of produtosIds) {
        const index = produtosIds.indexOf(element)
        const responseProduto = await trazerProdutoPorId.execute({
          produtoId: element as number,
          token: usuarioData?.token as string
        })
        let valor = 0
        let acrescimo = 0
        let desconto = 0

        if (responsePrecos.data) {
          valor = responsePrecos.data[index] ? responsePrecos.data[index].valor : 0
        }

        const quantidadeDoItem = quantidadeDosProdutos ? quantidadeDosProdutos[index] as number * quantidadeDeKits : 0

        let acrescimoXdesconto = valor * aumentoPorcentual
        let acrescimoItem = valor * (diferenca / 100)
        const pedidoEmAndamento = pedido as PedidoEmAndamento
        const pedidoEmAberto = pedido as PedidoEmAberto

        if (pedidoEmAndamento?.pedido?.tipo_faturamento === 'normal' || pedidoEmAberto?.emEdicao?.tipo_faturamento === 'normal' || pedidoEmAberto?.original?.tipo_faturamento === 'normal') {
          acrescimo = acrescimoItem
          valor = valor + acrescimo
        } else {
          if (acrescimoXdesconto) {
            if (acrescimoXdesconto > 0) {
              acrescimo = acrescimoXdesconto
              valor = valor + acrescimo
            } else {
              acrescimoXdesconto = acrescimoXdesconto * -1
              desconto = acrescimoXdesconto
              valor = valor - desconto
            }
          }
        }

        let newItem: Item = {
          id: fakeId * -1,
          acrescimo,
          desconto,
          valorUnitario: valor || 0,
          valor,
          quantidade: quantidadeDoItem,
          stTotal: responsePrecos.data[index].st * quantidadeDoItem || 0,
          ipiTotal: responsePrecos.data[index].ipi * quantidadeDoItem || 0,
          total: valor * quantidadeDoItem || 0,
          produto: responseProduto?.data,
          preco: responsePrecos?.data[index] as unknown as Preco,
          falta_liberar: quantidadeDoItem,
          data: new Date().toISOString()
        }

        if (index && index > 0) {
          newItem.item_promocao_id = -1
        }

        fakeId++
        newItensPedido.push(newItem)
      }
    }
    // montagem do pedido - igual ao buildArray mas deve somar quantidades
    if (mode === 'register') {
      const pedidoEmAndamento = pedido as PedidoEmAndamento
      if (pedidoEmAndamento.pedido.itens?.length) {
        const oldItensPedido = [...pedidoEmAndamento.pedido.itens]
        itensPedido = oldItensPedido.map((y) => {
          if (produtosIds?.includes(y.produto?.id)) {
            const indexNewItem = produtosIds.indexOf(y.produto?.id)
            if (newItensPedido[indexNewItem].valor === y.valor) {
              produtosRepetidos.push(indexNewItem)
              return {
                ...y,
                quantidade: y.quantidade + newItensPedido[indexNewItem].quantidade
              }
            } else {
              return y
            }
          } else {
            return y
          }
        })

        const filtredItens = newItensPedido.filter((x, index) => !produtosRepetidos.includes(index))
        if (filtredItens) {
          itensPedido = [...itensPedido, ...filtredItens]
        } else {
          itensPedido = [...itensPedido, ...newItensPedido]
        }
      } else {
        itensPedido = newItensPedido
      }

      if (!zerarFrete) {
        if (pedidoEmAndamento.pedido.frete_cotacao) {
          frete_cotacao = pedidoEmAndamento.pedido.frete_cotacao
        }
        if (pedidoEmAndamento.pedido.frete_valor) {
          frete_valor = pedidoEmAndamento.pedido.frete_valor
        }
      }
      newPedido = {
        ...pedidoEmAndamento.pedido,
        frete_cotacao,
        frete_valor,
        itens: itensPedido
      }
    } else {
      const pedidoEmAberto = pedido as PedidoEmAberto
      let oldItensPedido: Item[] = [] as Item[]

      if (pedidoEmAberto.emEdicao && pedidoEmAberto.emEdicao.itens) {
        oldItensPedido = [...pedidoEmAberto.emEdicao.itens as Item[]]
      } else if (pedidoEmAberto.original && pedidoEmAberto.original.itens) {
        oldItensPedido = [...pedidoEmAberto.original.itens as Item[]]
      }

      if (oldItensPedido.length > 0) {
        let produtosRepetidos = [] as number[]
        itensPedido = oldItensPedido.map((y) => {
          if (produtosIds?.includes(y.produto?.id)) {
            const indexNewItem = produtosIds.indexOf(y.produto?.id)
            if (newItensPedido[indexNewItem].valor === y.valor) {
              produtosRepetidos.push(indexNewItem)
              return {
                ...y,
                quantidade: y.quantidade + newItensPedido[indexNewItem].quantidade
              }
            } else {
              return y
            }
          } else {
            return y
          }
        })

        const filtredItens = newItensPedido.filter((x, index) => !produtosRepetidos.includes(index))
        if (filtredItens) {
          itensPedido = [...itensPedido, ...filtredItens]
        } else {
          itensPedido = [...itensPedido, ...newItensPedido]
        }
      } else {
        itensPedido = newItensPedido
      }

      if (!zerarFrete) {
        if (pedidoEmAberto.emEdicao.frete_cotacao) {
          frete_cotacao = pedidoEmAberto.emEdicao.frete_cotacao
        } else if (pedidoEmAberto.original.frete_cotacao) {
          frete_cotacao = pedidoEmAberto.original.frete_cotacao
        }

        if (pedidoEmAberto.emEdicao.frete_valor) {
          frete_valor = pedidoEmAberto.emEdicao.frete_valor
        } else if (pedidoEmAberto.original.frete_valor) {
          frete_valor = pedidoEmAberto.original.frete_valor
        }
      }

      newPedido = {
        ...pedidoEmAberto.original,
        ...pedidoEmAberto.emEdicao,
        frete_cotacao,
        frete_valor,
        itens: itensPedido
      }
    }
    setLoading(false)
    return newPedido
  }

  return { recalcularPedido, loading, buildPedidoNewItem, buildPedidoNewArray, buildPedidoRemoveItem, buildPedidoNewItemKit }
}
