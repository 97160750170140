import React, { useMemo } from 'react'
import { useUltimasCompras } from '../../hooks/pedido/useUltimasCompras'
import { FaSearch } from 'react-icons/fa'
import { Table, TableColumnProps } from '../Table'
import { Button } from '..'
import { formatFloatToCurrency } from '../../../helpers/formatFloatToCurrency'
import { formatStringDate } from '../../../helpers/formatStringDate'
import { Cliente } from '../../../modules/clientes/models'
import { TableText } from '../../styles/global'
import { Container, DivButton } from './styles'

type UltimasComprasProps = {
  cliente: Cliente;
}

export const UltimasCompras = ({ cliente }: UltimasComprasProps) => {
  const { data, loading, getUltimasCompras } = useUltimasCompras(cliente)

  const columns: TableColumnProps[] = useMemo(() => [
    {
      Header: 'Cod. Produto',
      accessor: 'id',
      maxWidth: 120,
      Cell: ({ value }) => {
        return <TableText position='center'>{value}</TableText>
      }
    },
    {
      Header: 'Produto',
      accessor: 'nome_tecnico',
      minWidth: 10,
      Cell: ({ value }) => {
        return <TableText position='left'>{value}</TableText>
      }
    },
    {
      Header: 'Marca',
      accessor: 'marca',
      minWidth: 10,
      Cell: ({ value }) => {
        return <TableText position='left'>{value}</TableText>
      }
    },
    {
      Header: 'Total',
      accessor: 'total',
      maxWidth: 120,
      Cell: ({ value }) => {
        return <TableText position='center'>{formatFloatToCurrency(value)}</TableText>
      }
    },
    {
      Header: 'Data Última Compra',
      accessor: 'data_ultima_compra',
      maxWidth: 120,
      Cell: ({ value }) => {
        return <TableText position='center'>{formatStringDate(value, 'xxxxxxxx', 'completo')}</TableText>
      }
    }
  ], [])

  return (
    <Container>
      <h1>Últimas Compras</h1>
      <DivButton>
        <Button startIcon={FaSearch} mode='confirm' onClick={getUltimasCompras}>
          Buscar
        </Button>
      </DivButton>
      <Table
        columns={columns}
        showPageJump={false}
        data={data}
        manual
        loading={loading}
        sortable={false}
        showPageSizeOptions={false}
        noDataText="Nenhuma ocorrencia"
        nextText="Próximo"
        ofText="de"
        previousText="Anterior"
        showPagination={false}
        pageText="Página"
        /* dbClickCallback={handleAbrirPedido} */
        selectRow
        deleteRow
      />
    </Container>
  )
}
