import { formatStringDate, formatStringHora } from '../../../helpers'
import { TrazerOcorrenciasClienteService } from '../contracts'
import { GetOcorrenciasResponse, GetParamsOcorrencia } from '../models'

export class TrazerOcorrencias {
  private readonly trazerOcorrenciasClienteService: TrazerOcorrenciasClienteService

  constructor (trazerOcorrenciasClienteService: TrazerOcorrenciasClienteService) {
    this.trazerOcorrenciasClienteService = trazerOcorrenciasClienteService
  }

  async execute (params: GetParamsOcorrencia): Promise<GetOcorrenciasResponse> {
    let response = await this.trazerOcorrenciasClienteService.trazerOcorrenciasCliente(params)

    const formatResponse = response.data.map(ocorrencia => {
      const newState = {
        ...ocorrencia,
        tipo: ocorrencia.tipo === 'pessoal' ? 'observação' : ocorrencia.tipo,
        data: formatStringDate(ocorrencia.data, 'xxxxxxxx', 'completo'),
        hora: formatStringHora(ocorrencia.hora, 'resumo')
      }
      return newState
    })
    response.data = formatResponse
    return response
  }
}
