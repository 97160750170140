
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { formatStringDate, formatFloatToCurrency } from '../../../helpers'
import { FilterPedidoProps, FilterVendaProps } from '../../../modules/_interfaces/filtersProps'
import { Button, Loading, Select, Table, TableColumnProps } from '../../components'

import { useVendasFuncionario } from '../../hooks'

import { MainLayout } from '../../layouts/MainLayout'
import { TableText } from '../../styles/global'
import { Container, TotalVendas } from './styles'

export const Vendas = () => {
  const [filters, setFilters] = useState<FilterVendaProps>({
    dataInicial: format(startOfMonth(new Date()), 'yyyyMMdd'),
    dataFinal: format(new Date(), 'yyyyMMdd')
  } as FilterPedidoProps)

  const { fetcher: getListaVendas, data: dataListaVendas, loading: loadingListaVendas } = useVendasFuncionario()

  useEffect(() => {
    getListaVendas({
      filters
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSearch = useCallback(() => {
    getListaVendas({ filters })
  }, [filters, getListaVendas])

  const handleDate = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value

    let dataInicial = format(startOfMonth(new Date()), 'yyyyMMdd')
    let dataFinal = format(new Date(), 'yyyyMMdd')

    if (value === 'anterior') {
      dataInicial = format(startOfMonth(subMonths(new Date(), 1)), 'yyyyMMdd')
      dataFinal = format(endOfMonth(subMonths(new Date(), 1)), 'yyyyMMdd')
    }

    setFilters((oldState: FilterVendaProps) => {
      const newState = {
        ...oldState,
        dataInicial,
        dataFinal
      }
      return newState
    })
  }, [])

  const columns: TableColumnProps[] = useMemo(() => {
    return [
      {
        Header: 'Data',
        accessor: 'data_faturamento',
        minWidth: 15,
        Cell: ({ value }) => {
          return <TableText position='center'>{formatStringDate(value, 'xxxxxxxx', 'completo')}</TableText>
        }
      },
      {
        Header: 'Tipo',
        accessor: 'tipo',
        minWidth: 15,
        Cell: ({ value }) => {
          return <TableText position='center'>{value}</TableText>
        }
      },
      {
        Header: 'Venda/Devolução',
        accessor: 'venda_id',
        minWidth: 12,
        Cell: ({ value }) => {
          return <TableText position='left'>{value}</TableText>
        }
      },
      {
        Header: 'Cliente',
        accessor: 'cliente_id',
        minWidth: 10,
        Cell: ({ value }) => {
          return <TableText position='left'>{value}</TableText>
        }
      },
      {
        Header: 'Razão Social',
        accessor: 'cliente_nome',
        Cell: ({ value }) => {
          return <TableText position='left'>{value}</TableText>
        }
      },
      {
        Header: 'Total',
        accessor: 'total',
        minWidth: 20,
        Cell: ({ row }) => {
          return <TableText position='right'>{row.tipo !== 'venda' ? '- ' : ''}R$ {formatFloatToCurrency(row.total)}</TableText>
        }
      }

    ]
  }
  , [])

  return (
    <MainLayout title="Vendas">
      {(loadingListaVendas) && <Loading />}
      <h1>Vendas</h1>
      <Container>
        <header>
          <div>
            <div style={{ width: '200px' }}>
              <Select marginRight='15px' onChange={handleDate} >
                <option value='atual'>Mês Atual</option>
                <option value='anterior'>Mês Anterior</option>
              </Select>
            </div>
            <Button
              data-cy='buttonPesquisarPedidos'
              mode='primary'
              startIcon={FaSearch}
              onClick={handleSearch}
              style={{ marginTop: '10px' }}
            > Pesquisar</Button>
          </div>
          <TotalVendas style={{ marginTop: '12px' }}>
              Total em vendas: R$ {formatFloatToCurrency(dataListaVendas ? dataListaVendas?.data?.total : 0)}
          </TotalVendas>
        </header>
        <Table
          data-cy='tablePageVendas'
          showPagination={false}
          columns={columns}
          showPageJump={false}
          data={dataListaVendas?.data.vendas}
          manual
          loading={loadingListaVendas}
          sortable={false}
          showPageSizeOptions={false}
          noDataText="Nenhuma venda encontrada"
          selectRow
        />
      </Container>
    </MainLayout>

  )
}
