import styled from 'styled-components'
interface ContainerProps {
  width: string
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: ${props => props.width};
  margin-top: 15px;
`

interface FieldDataProps {
  width?: string
  isActive?: boolean
  marginRight?: string
}

export const FieldData = styled.div<FieldDataProps>`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  margin-right: ${props => props.marginRight ? props.marginRight : '0'};
  width: ${props => props.width ? props.width : '100%'};
  >label{
    display: flex;
    color: ${props => props.theme.colors.primaryText} !important;
    opacity: ${props => (props.isActive ? '1' : '0.5')};
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .react-datepicker__tab-loop{
    z-index: 1000;
  }
  .react-datepicker__input-container{
    >input{
      width: 100%;
      height: 36px;
      padding: 0px 10px;
      border-radius: 4px;
      border: 1px solid ${props => props.isActive ? props.theme.colors.primary : props.theme.colors.border}
    }
  }

  @media (max-width: 900px) {
    & {
      width: 100%;
      margin-left: 0 !important;
    }
  }
`

export const FieldCheck = styled.div<FieldDataProps>`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  margin-right: ${props => props.marginRight ? props.marginRight : '0'};
  width: ${props => props.width ? props.width : '100%'};
  >label{
    display: flex;
    color: ${props => props.theme.colors.primaryText} !important;
    opacity: ${props => (props.isActive ? '1' : '0.5')};
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
`
