import { GetParams, UseCase } from '../../_interfaces'
import { TrazerResumoFuncionarioService } from '../contracts'
import { GetResumoFuncionario } from '../models'

export class TrazerResumoFuncionario implements UseCase<GetParams, GetResumoFuncionario> {
  private readonly trazerResumoFuncionarioService: TrazerResumoFuncionarioService

  constructor (trazerResumoFuncionarioService: TrazerResumoFuncionarioService) {
    this.trazerResumoFuncionarioService = trazerResumoFuncionarioService
  }

  async execute (params: GetParams): Promise<GetResumoFuncionario> {
    const response = await this.trazerResumoFuncionarioService.trazerResumoFuncionario(params)
    return response
  }
}
