import { GetParams, UseCase } from '../../_interfaces'
import { TrazerEquipePorIdService } from '../contracts'
import { GetEquipe } from '../models/getEquipe'

export class TrazerEquipePorId implements UseCase<{ id: number, token:string}, GetEquipe> {
  private readonly trazerEquipePorIdService: TrazerEquipePorIdService

  constructor (trazerEquipePorIdService: TrazerEquipePorIdService) {
    this.trazerEquipePorIdService = trazerEquipePorIdService
  }

  async execute (params : { id: number, token:string}): Promise<GetEquipe> {
    const response = await this.trazerEquipePorIdService.trazerEquipePorId(params)
    return response
  }
}
