/* eslint-disable react-hooks/exhaustive-deps */
import capitalize from 'capitalize-pt-br'
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { Input, ListaClientes, ModePedido, Select, CheckBox, Textarea, Button, Modal } from '../..'
import { Cliente, Contato } from '../../../../modules/clientes/models'
import { Empresa } from '../../../../modules/empresas/models/empresa'
import { Funcionario } from '../../../../modules/funcionarios/models/funcionario'
import { useEmpresa, useCliente, useFuncionario, useCalcPedido, useExecUseCase } from '../../../hooks'
import { FormRow } from '../../../styles/global'
import { ListaContatos } from '../../ListaContatos'
import { ListaEmpresas } from '../../ListaEmpresas'
import { ListaFuncionarios } from '../../ListaFuncionarios'
import { Container, DivButton, ButtonDiv } from './styles'
import { AppState } from '../../../store'
import { setCampoValorPedidoEmAndamento, setErroCampoValorPedidoEmAndamento, setCampoValorPedidoEmAberto, setErroCampoValorPedidoEmAberto, setPedidoRecalculado } from '../../../store/atendimentos/actions'
import { AtendimentoType, ErrosPedido } from '../../../store/atendimentos/interfaces'
import { GetPedido } from '../../../../modules/pedidos/models'
import { useHandleErrors } from '../../../hooks/app/useHandleErrors'
import { FaExclamation, FaUser } from 'react-icons/fa'
import { toCnpj } from '../../../../helpers'
import { DadosResumoCliente } from '../../DadosResumoCliente'
import { Loading } from '../..'

interface DadosGeraisProps {
  cliente: Cliente
  mode: ModePedido
  orcamentoIdEmEdicao?: number
  solar: 's' | 'n'
}

const condicoes_pagamento = [
  {
    label: 'débito',
    value: 'd'
  },
  {
    label: 'crédito - 1x',
    value: 'c1'
  },
  {
    label: 'crédito - 2x',
    value: 'c2'
  },
  {
    label: 'crédito - 3x',
    value: 'c3'
  },
  {
    label: 'crédito - 4x',
    value: 'c4'
  },
  {
    label: 'crédito - 5x',
    value: 'c5'
  },
  {
    label: 'crédito - 6x',
    value: 'c6'
  },
  {
    label: 'crédito - 7x',
    value: 'c7'
  },
  {
    label: 'crédito - 8x',
    value: 'c8'
  },
  {
    label: 'crédito - 9x',
    value: 'c9'
  },
  {
    label: 'crédito - 10x',
    value: 'c10'
  },
  {
    label: 'crédito - 11x',
    value: 'c11'
  },
  {
    label: 'crédito - 12x',
    value: 'c12'
  },
  {
    label: 'crédito - 13x',
    value: 'c13'
  },
  {
    label: 'crédito - 14x',
    value: 'c14'
  },
  {
    label: 'crédito - 15x',
    value: 'c15'
  },
  {
    label: 'crédito - 16x',
    value: 'c16'
  },
  {
    label: 'crédito - 17x',
    value: 'c17'
  },
  {
    label: 'crédito - 18x',
    value: 'c18'
  },
  {
    label: 'crédito - 19x',
    value: 'c19'
  },
  {
    label: 'crédito - 20x',
    value: 'c20'
  },
  {
    label: 'crédito - 21x',
    value: 'c21'
  },
  {
    label: 'crédito - 22x',
    value: 'c22'
  },
  {
    label: 'crédito - 23x',
    value: 'c23'
  },
  {
    label: 'crédito - 24x',
    value: 'c24'
  }
]

const possibility_options = [
  {
    label: 'baixa',
    value: 'baixa'
  },
  {
    label: 'média',
    value: 'media'
  },
  {
    label: 'alta',
    value: 'alta'
  }
]

export const DadosGerais = ({ cliente, mode, orcamentoIdEmEdicao, solar }: DadosGeraisProps) => {
  const dispatch = useDispatch()
  const { handleError } = useHandleErrors()
  const atendimentoCliente = useSelector((state: AppState) => state.atendimentos.data[cliente.id as number]) as AtendimentoType
  const [empresaId, setEmpresaId] = useState<number>()
  const [transportadoraId, setTransportadoraId] = useState(0)
  const [clienteRevendaId, setClienteRevendaId] = useState(0)
  const [funcionarioId, setFuncionarioId] = useState(0)
  const [funcionario2Id, setFuncionario2Id] = useState(0)
  const [condicaoPagamento, setCondicaoPagamento] = useState<string>()
  const [observacao, setObservacao] = useState<string>()
  const [observacaoNotaFiscal, setObservacaoNotaFiscal] = useState<string>()
  const [bndesCodigo, setBndesCodigo] = useState<string>()
  const [showObsTela, setShowObsTela] = useState(false)
  const [showDeposito, setShowDeposito] = useState(false)
  const [showModalCliente, setShowModalCliente] = useState(false)
  const { recalcularPedido, loading: loadingRecacular } = useCalcPedido()
  const { executeUseCase } = useExecUseCase()
  const { fetcher: getEmpresaPorId, data: empresaData } = useEmpresa() // empresaId

  const { fetcher: getRevendaPorId, data: revendaData, loading: loadingRevenda } = useCliente()
  const { fetcher: fetcherCliente, data: dataCliente, loading: loadingCliente } = useCliente()
  const { data: funcionario_data } = useFuncionario(funcionarioId)
  const { data: funcionario2_data } = useFuncionario(funcionario2Id)

  const [validarFreteAutomatico, setValidarFreteAutomatico] = useState()

  const [modalCliente, setModalCliente] = useState<Cliente>()

  const changeDeposito = (empresa?: Empresa) => {
    if (!empresaData && !empresa) {
      return
    }

    if (mode === 'register') {
      dispatch(setCampoValorPedidoEmAndamento({
        clienteId: cliente.id as number,
        campo: 'deposito',
        valor: empresa || empresaData
      }))

      dispatch(setErroCampoValorPedidoEmAndamento({
        clienteId: cliente.id as number,
        campo: 'deposito_id',
        valor: null
      }))
    } else {
      dispatch(setCampoValorPedidoEmAberto({
        clienteId: cliente.id as number,
        pedidoId: orcamentoIdEmEdicao as number,
        campo: 'deposito',
        valor: empresa || empresaData
      }))
    }
  }

  const handleEnterDeposito = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let empresa = {} as Empresa
      if (empresaId) {
        empresa = await getEmpresaPorId(empresaId)
      }
      changeDeposito(empresa)
    }
  }

  const handleTabDeposito = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab') {
      let empresa = {} as Empresa
      if (empresaId) {
        empresa = await getEmpresaPorId(empresaId)
      }
      changeDeposito(empresa)
    }
  }

  const handleModalCliente = async (id: number) => {
    setModalCliente(undefined)
    setShowModalCliente(true)

    const cliente = await fetcherCliente({
      clienteId: id
      // revenda: true
    })

    if (cliente) {
      setModalCliente(cliente)
    }
  }

  const changeFuncionario = useCallback(() => {
    if (!funcionario_data?.data) {
      return
    }

    if (mode === 'register') {
      dispatch(setCampoValorPedidoEmAndamento({
        clienteId: cliente.id as number,
        campo: 'funcionario',
        valor: funcionario_data.data
      }))

      dispatch(setErroCampoValorPedidoEmAndamento({
        clienteId: cliente.id as number,
        campo: 'funcionario_id',
        valor: null
      }))
    } else {
      dispatch(setCampoValorPedidoEmAberto({
        clienteId: cliente.id as number,
        pedidoId: orcamentoIdEmEdicao as number,
        campo: 'funcionario',
        valor: funcionario_data.data
      }))
    }
  }, [funcionario_data, cliente, mode, dispatch, orcamentoIdEmEdicao])

  const handleEnterFuncionario = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      changeFuncionario()
    }
  }

  const changeFuncionario2 = useCallback(() => {
    if (!funcionario2_data?.data) {
      return
    }

    if (mode === 'register') {
      dispatch(setCampoValorPedidoEmAndamento({
        clienteId: cliente.id as number,
        campo: 'funcionario2',
        valor: funcionario2_data.data
      }))

      dispatch(setErroCampoValorPedidoEmAndamento({
        clienteId: cliente.id as number,
        campo: 'funcionario2_id',
        valor: null
      }))
    } else {
      dispatch(setCampoValorPedidoEmAberto({
        clienteId: cliente.id as number,
        pedidoId: orcamentoIdEmEdicao as number,
        campo: 'funcionario2',
        valor: funcionario2_data.data
      }))
    }
  }, [funcionario2_data, cliente, mode, dispatch, orcamentoIdEmEdicao])

  const handleEnterFuncionario2 = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      changeFuncionario2()
    }
  }

  const changeClienteRevenda = useCallback((revenda?: Cliente) => {
    if (!revenda) {
      if (mode === 'register') {
        dispatch(setCampoValorPedidoEmAndamento({
          clienteId: cliente.id as number,
          campo: 'revenda',
          valor: {
            id: '',
            nome_fantasia: ''
          } as Cliente
        }))
        dispatch(setErroCampoValorPedidoEmAndamento({
          clienteId: cliente.id as number,
          campo: 'revenda_id',
          valor: null
        }))

        setClienteRevendaId(0)

        return
      }
    }

    if (mode === 'register') {
      dispatch(setCampoValorPedidoEmAndamento({
        clienteId: cliente.id as number,
        campo: 'revenda',
        valor: revenda || revendaData
      }))

      dispatch(setErroCampoValorPedidoEmAndamento({
        clienteId: cliente.id as number,
        campo: 'revenda_id',
        valor: null
      }))
    }
  }, [cliente.id, dispatch, mode, revendaData])

  const handleEnterClienteRevenda = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const revenda = await getRevendaPorId({
        clienteId: clienteRevendaId,
        revenda: !(tipoFaturamentoPedido === 'direto' && solar === 's')
      })
      changeClienteRevenda(revenda)
    }
  }

  const temProduto = useMemo(() => {
    let retorno = false
    if (mode === 'register') {
      if (atendimentoCliente.pedidoEmAndamento?.pedido?.itens && atendimentoCliente.pedidoEmAndamento?.pedido?.itens.length > 0) {
        retorno = true
      }
    } else {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number]?.emEdicao?.itens
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number]?.original?.itens
      const itens = emEdicao || original
      if (itens && itens.length > 0) {
        retorno = true
      }
    }
    return retorno
  }, [atendimentoCliente, mode, orcamentoIdEmEdicao])

  const orcamentoIdInputValue = useMemo(() => {
    if (mode === 'register') {
      return ''
    } else {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.id
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.id

      return (emEdicao || original)
    }
  }, [mode, atendimentoCliente.pedidosEmAberto.data, orcamentoIdEmEdicao])

  const orcamentoStatusInputValue = useMemo(() => {
    if (mode === 'register') {
      return 'Normal'
    } else {
      const emEdicaoRO = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.registro_oportunidade
      const originalRO = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.registro_oportunidade

      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.status
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.status

      if (emEdicaoRO || originalRO === 'pendente') {
        return 'R.O Pendente'
      } else {
        return (emEdicao || original)
      }
    }
  }, [mode, atendimentoCliente.pedidosEmAberto.data, orcamentoIdEmEdicao])

  const orcamentoDataInputValue = useMemo(() => {
    if (mode === 'register') {
      return '--/--/-- - --:--'
    } else {
      let retorno = ''
      const data = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.data
      const hora = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.hora

      if (data && hora) {
        retorno = data + ' - ' + hora
      } else if (data && !hora) {
        retorno = data
      }

      return retorno
    }
  }, [mode, atendimentoCliente.pedidosEmAberto.data, orcamentoIdEmEdicao])

  const contatoIdInputValue = useMemo(() => {
    if (mode === 'register') {
      return atendimentoCliente.pedidoEmAndamento.pedido.contato?.id
    } else {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.contato?.id
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.contato?.id

      return (emEdicao || original)
    }
  }, [mode, atendimentoCliente.pedidoEmAndamento.pedido, atendimentoCliente.pedidosEmAberto.data, orcamentoIdEmEdicao])

  const depositoIdInputValue = useMemo(() => {
    if (empresaId !== undefined) {
      return empresaId
    } else if (mode === 'register') {
      return atendimentoCliente.pedidoEmAndamento.pedido.deposito?.id
    } else if (mode === 'edit') {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.deposito?.id
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.deposito?.id

      return (emEdicao || original)
    }
  }, [empresaId, mode, atendimentoCliente.pedidoEmAndamento.pedido, atendimentoCliente.pedidosEmAberto.data, orcamentoIdEmEdicao])

  const depositoUfValue = useMemo(() => {
    if (mode === 'register') {
      return atendimentoCliente.pedidoEmAndamento.pedido.deposito?.uf
    } else if (mode === 'edit') {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.deposito?.uf
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.deposito?.uf

      return (emEdicao || original)
    }
  }, [mode, atendimentoCliente, orcamentoIdEmEdicao])

  const depositoNomeInputValue = useMemo(() => {
    if (mode === 'register') {
      return capitalize(atendimentoCliente.pedidoEmAndamento.pedido.deposito?.nome as string)
    } else if (mode === 'edit') {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.deposito?.nome as string
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.deposito?.nome as string

      return capitalize(emEdicao || original)
    }
  }, [mode, atendimentoCliente.pedidoEmAndamento.pedido, atendimentoCliente.pedidosEmAberto.data, orcamentoIdEmEdicao])

  const funcionarioIdInputValue = useMemo(() => {
    if (funcionarioId) {
      return funcionarioId
    } else if (mode === 'register') {
      return atendimentoCliente.pedidoEmAndamento.pedido.funcionario?.id
    } else if (mode === 'edit') {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.funcionario?.id
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.funcionario?.id
      return (emEdicao || original)
    }
  }, [funcionarioId, mode, atendimentoCliente.pedidoEmAndamento.pedido, atendimentoCliente.pedidosEmAberto.data, orcamentoIdEmEdicao])

  const funcionarioNomeInputValue = useMemo(() => {
    if (mode === 'register') {
      return capitalize(atendimentoCliente.pedidoEmAndamento.pedido.funcionario?.nome as string)
    } else if (mode === 'edit') {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.funcionario?.nome as string
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.funcionario?.nome as string

      return capitalize(emEdicao || original)
    }
  }, [mode, atendimentoCliente.pedidoEmAndamento.pedido, atendimentoCliente.pedidosEmAberto.data, orcamentoIdEmEdicao])

  const funcionario2IdInputValue = () => {
    if (funcionario2Id) {
      return funcionario2Id
    } else {
      if (mode === 'register') {
        return atendimentoCliente.pedidoEmAndamento.pedido.funcionario2?.id
      } else if (mode === 'edit') {
        const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.funcionario2?.id
        const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.funcionario2?.id
        if (emEdicao === '' || (emEdicao && emEdicao > 0)) {
          return emEdicao
        } else {
          return original
        }
      }
    }
  }

  const funcionario2NomeInputValue = () => {
    if (mode === 'register') {
      return capitalize(atendimentoCliente.pedidoEmAndamento.pedido.funcionario2?.nome as string)
    } else if (mode === 'edit') {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.funcionario2?.nome as string
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.funcionario2?.nome as string
      if (emEdicao || emEdicao === '') {
        return capitalize(emEdicao)
      } else {
        return capitalize(original)
      }
    }
  }

  const contatoNomeInputValue = useMemo(() => {
    if (mode === 'register') {
      return capitalize(atendimentoCliente.pedidoEmAndamento.pedido.contato?.nome as string)
    } else if (mode === 'edit') {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.contato?.nome as string
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.contato?.nome as string

      return capitalize(emEdicao || original)
    }
  }, [atendimentoCliente.pedidoEmAndamento.pedido, atendimentoCliente.pedidosEmAberto.data, mode, orcamentoIdEmEdicao])

  const nomeFantasiaInputValue = useMemo(() => {
    if (mode === 'register') {
      return capitalize(atendimentoCliente.pedidoEmAndamento.pedido.cliente?.nome_fantasia as string)
    } else if (mode === 'edit') {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.cliente?.nome_fantasia as string
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.cliente?.nome_fantasia as string

      return capitalize(emEdicao || original)
    }
  }, [atendimentoCliente.pedidoEmAndamento.pedido, atendimentoCliente.pedidosEmAberto.data, mode, orcamentoIdEmEdicao])

  const condicaoPagamentoInputValue = useMemo(() => {
    if (condicaoPagamento !== undefined) {
      return condicaoPagamento
    } else {
      if (mode === 'register') {
        return atendimentoCliente.pedidoEmAndamento.pedido.condicao_pagamento as string
      } else if (mode === 'edit') {
        const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.condicao_pagamento as string
        const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.condicao_pagamento as string
        return emEdicao || original
      }
    }
  }, [condicaoPagamento, mode, atendimentoCliente, orcamentoIdEmEdicao])

  const pagamentoTipoSelectValue = useMemo(() => {
    if (mode === 'register') {
      return atendimentoCliente.pedidoEmAndamento.pedido.pagamento_tipo
    } else if (mode === 'edit') {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.pagamento_tipo
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.pagamento_tipo

      return emEdicao || original
    }
  }, [atendimentoCliente.pedidoEmAndamento.pedido, atendimentoCliente.pedidosEmAberto.data, mode, orcamentoIdEmEdicao])

  const tipoFaturamentoPedido = useMemo(() => {
    if (mode === 'register') {
      return atendimentoCliente.pedidoEmAndamento.pedido.tipo_faturamento
    } else if (mode === 'edit') {
      return atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.tipo_faturamento
    }
  }, [mode, atendimentoCliente.pedidoEmAndamento.pedido, atendimentoCliente.pedidosEmAberto.data, orcamentoIdEmEdicao])

  const clienteRevendaIdInputValue = useMemo(() => {
    if (clienteRevendaId) {
      return clienteRevendaId
    } else if (mode === 'register') {
      return atendimentoCliente.pedidoEmAndamento.pedido.revenda?.id
    } else if (mode === 'edit') {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.revenda?.id
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.revenda?.id

      return (emEdicao || original)
    }
  }, [clienteRevendaId, mode, atendimentoCliente.pedidoEmAndamento.pedido, atendimentoCliente.pedidosEmAberto.data, orcamentoIdEmEdicao])

  const clienteRevendaNomeInputValue = useMemo(() => {
    if (mode === 'register') {
      return capitalize(atendimentoCliente.pedidoEmAndamento.pedido.revenda?.nome_fantasia as string)
    } else if (mode === 'edit') {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.revenda?.nome_fantasia as string
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.revenda?.nome_fantasia as string

      return capitalize(emEdicao || original)
    }
  }, [mode, atendimentoCliente.pedidoEmAndamento.pedido, atendimentoCliente.pedidosEmAberto.data, orcamentoIdEmEdicao])

  const observacaoInputValue = () => {
    if (observacao !== undefined) {
      return observacao
    } else {
      if (mode === 'register') {
        return atendimentoCliente.pedidoEmAndamento.pedido.observacao
      } else if (mode === 'edit') {
        const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.observacao
        const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.observacao
        return emEdicao || original
      }
    }
  }

  const analiseCreditoInputValue = () => {
    if (observacao !== undefined) {
      return observacao
    } else {
      if (mode === 'register') {
        return atendimentoCliente.pedidoEmAndamento.pedido.analise_credito
      } else if (mode === 'edit') {
        const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.analise_credito
        const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.analise_credito
        return emEdicao || original
      }
    }
  }

  const analiseColor = (text?: string) => {
    if (text) {
      if (text === 'aprovado') {
        return 'success'
      } else if (text === 'rejeitado') {
        return 'danger'
      }
    }
  }

  const observacaoFiscalInputValue = () => {
    if (observacaoNotaFiscal !== undefined) {
      return observacaoNotaFiscal
    } else {
      if (mode === 'register') {
        return atendimentoCliente.pedidoEmAndamento.pedido.observacao_nota_fiscal
      } else if (mode === 'edit') {
        const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.observacao_nota_fiscal
        const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.observacao_nota_fiscal
        return emEdicao || original
      }
    }
  }

  const possibilidadeInputValue = () => {
    if (mode === 'register') {
      return atendimentoCliente.pedidoEmAndamento.pedido.possibilidades as string
    } else if (mode === 'edit') {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.possibilidades as string
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.possibilidades as string

      return emEdicao || original
    }
  }

  const bndesCheckValue = () => {
    if (mode === 'register') {
      return atendimentoCliente.pedidoEmAndamento.pedido.bndes
    } else if (mode === 'edit') {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.bndes
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.bndes

      return emEdicao || original
    }
  }

  const codigoBndesInputValue = () => {
    if (bndesCodigo) {
      return bndesCodigo
    } else {
      if (mode === 'register') {
        return atendimentoCliente.pedidoEmAndamento.pedido.bndes_codigo
      } else if (mode === 'edit') {
        const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.bndes_codigo
        const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.bndes_codigo

        return emEdicao || original
      }
    }
  }

  const uso_consumoValue = () => {
    if (mode === 'register') {
      return atendimentoCliente.pedidoEmAndamento.pedido.usoeconsumo === 's' ? 'Sim' : 'Não'
    } else if (mode === 'edit') {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.usoeconsumo
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.usoeconsumo
      if (emEdicao) {
        return emEdicao === 's' ? 'Sim' : 'Não'
      } else if (original) {
        return original === 's' ? 'Sim' : 'Não'
      } else {
        return 'Não'
      }
    }
  }

  const valorPagoAdiantamento = () => {
    let retorno = 0
    if (mode === 'register') {
      const andamento = atendimentoCliente.pedidoEmAndamento.pedido.valor_pago_adiantamento
      if (andamento) {
        retorno = andamento
      }
    } else {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.valor_pago_adiantamento
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.valor_pago_adiantamento
      if (emEdicao) {
        retorno = emEdicao
      } else if (original) {
        retorno = original
      }
    }
    return retorno
  }

  const valorAdiantamento = () => {
    let retorno = 0
    if (mode === 'register') {
      const andamento = atendimentoCliente.pedidoEmAndamento.pedido.valor_adiantamento
      if (andamento) {
        retorno = andamento
      }
    } else {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.valor_adiantamento
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.valor_adiantamento
      if (emEdicao) {
        retorno = emEdicao
      } else if (original) {
        retorno = original
      }
    }
    return retorno
  }

  const bloqueioClienteDesativado = () => {
    if (mode === 'register') {
      return atendimentoCliente.pedidoEmAndamento.pedido.bloqueio_cliente_desativado
    } else {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.bloqueio_cliente_desativado
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.bloqueio_cliente_desativado
      return emEdicao || original
    }
  }

  const documentoFiscal = () => {
    if (mode === 'register') {
      return atendimentoCliente.pedidoEmAndamento.pedido.documento_fiscal
    } else {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.documento_fiscal
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.documento_fiscal
      return emEdicao || original
    }
  }

  const observacaoTela = useMemo(() => {
    let retorno = {
      showCliente: false,
      showRevenda: false,
      message: ''
    }
    if (mode === 'register') {
      if (cliente.observacao_tela) {
        retorno.showCliente = true
        retorno.message = cliente.observacao_tela
      }
    } else {
      const tipo_faturamento = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.tipo_faturamento
      const revenda = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.revenda
      if (tipo_faturamento === 'direto' && revenda) {
        retorno.showRevenda = revenda.observacao_tela !== ''
        retorno.message = revenda.observacao_tela ? revenda.observacao_tela : ''
      } else if (tipo_faturamento === 'normal' && cliente) {
        retorno.showCliente = cliente.observacao_tela !== ''
        retorno.message = cliente.observacao_tela ? cliente.observacao_tela : ''
      }
    }
    return retorno
  }, [atendimentoCliente, cliente, mode, orcamentoIdEmEdicao])

  const bloquearAlteracoes = useMemo(() => {
    if (mode === 'register') {
      return atendimentoCliente.pedidoEmAndamento.pedido.bloquear_alteracoes
    } else if (mode === 'edit') {
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.bloquear_alteracoes
      return original
    }
  }, [atendimentoCliente, mode, orcamentoIdEmEdicao])

  const bloquearPagamento = useMemo(() => {
    if (mode === 'register') {
      return atendimentoCliente.pedidoEmAndamento.pedido.bloquear_pagamento
    } else if (mode === 'edit') {
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.bloquear_pagamento
      return original
    }
  }, [atendimentoCliente, mode, orcamentoIdEmEdicao])

  const bloqueios = useMemo(() => {
    if (mode === 'register') {
      return {
        status_pedido: atendimentoCliente.pedidoEmAndamento?.pedido?.status,
        pagamento_gerente: atendimentoCliente.pedidoEmAndamento?.pedido?.pagamento_gerente,
        juros_forcado: atendimentoCliente.pedidoEmAndamento?.pedido?.juros_forcado,
        estoque_zerado: atendimentoCliente.pedidoEmAndamento?.pedido?.estoque_zerado,
        limite_bloqueado: atendimentoCliente.pedidoEmAndamento?.pedido?.limite_bloqueado,
        pedido_desbloqueado: atendimentoCliente.pedidoEmAndamento?.pedido?.pedido_desbloqueado
      }
    } else if (mode === 'edit') {
      return {
        status_pedido: atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original?.status,
        pagamento_gerente: atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original?.pagamento_gerente,
        juros_forcado: atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original?.juros_forcado,
        estoque_zerado: atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original?.estoque_zerado,
        limite_bloqueado: atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original?.limite_bloqueado,
        pedido_desbloqueado: atendimentoCliente.pedidosEmAberto?.data[orcamentoIdEmEdicao as number]?.original?.pedido_desbloqueado
      }
    }
  }, [atendimentoCliente, mode, orcamentoIdEmEdicao])

  const clienteNameInput = () => {
    let name = capitalize(cliente.razao_social as string || cliente.nome_fantasia as string)
    if (cliente.cnpj) {
      name += cliente.cnpj.length === 14 ? ` - CNPJ: ${toCnpj(cliente.cnpj)}` : ` - CPF: ${toCnpj(cliente.cnpj)}`
      name += ` - ${capitalize(cliente.cidade as string)}/${capitalize(cliente.uf as string)}`
    }
    return name
  }

  const colorStatusCliente = () => {
    let retorno = {
      color: '',
      value: ''
    }
    if (cliente.status) {
      retorno.value = cliente.status
      if (cliente.status === 'liberado') {
        retorno.color = 'success'
      } else if (cliente.status === 'bloqueado') {
        if (bloqueioClienteDesativado() === 's' || (valorPagoAdiantamento() && valorPagoAdiantamento() > 0)) {
          retorno.color = 'warning'
          retorno.value = 'liberado'
        } else {
          retorno.color = 'danger'
        }
      }
    }
    return retorno
  }
  const handleRemoveErroInput = useCallback((nome: string) => {
    if (mode === 'register') {
      dispatch(setErroCampoValorPedidoEmAndamento({
        clienteId: cliente.id as number,
        campo: nome as keyof ErrosPedido,
        valor: null
      }))
    } else {
      dispatch(setErroCampoValorPedidoEmAberto({
        clienteId: cliente.id as number,
        pedidoId: orcamentoIdEmEdicao as number,
        campo: nome as keyof ErrosPedido,
        valor: null
      }))
    }
  }, [cliente.id, dispatch, mode, orcamentoIdEmEdicao])

  const handlerevendaF2CallBack = useCallback((clienteRevenda: Cliente) => {
    if (mode === 'edit') {
      return
    }

    dispatch(setCampoValorPedidoEmAndamento({
      clienteId: cliente.id as number,
      campo: 'revenda',
      valor: clienteRevenda
    }))

    handleRemoveErroInput('revenda_id')
  }, [mode, dispatch, cliente.id, handleRemoveErroInput])

  const handleclienteRevendaIdOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let id = 0

    if (!!e.target.value && e.target.value as unknown as number > 0) {
      id = e.target.value as unknown as number
    }

    if (!e.target.value) {
      const revenda = {
        id: '',
        nome_fantasia: ''
      } as Cliente

      if (mode === 'edit') {
        return
      } else {
        dispatch(setCampoValorPedidoEmAndamento({
          clienteId: cliente.id as number,
          campo: 'revenda',
          valor: revenda
        }))

        handleRemoveErroInput('revenda_id')
      }
    }

    setClienteRevendaId(id)
  }, [mode, dispatch, cliente.id, handleRemoveErroInput])

  const handleContatoF2CallBack = useCallback((contato: Contato) => {
    if (mode === 'edit') {
      return
    }

    dispatch(setCampoValorPedidoEmAndamento({
      clienteId: cliente.id as number,
      campo: 'contato',
      valor: contato
    }))

    handleRemoveErroInput('contato_id')
  }, [mode, dispatch, cliente.id, handleRemoveErroInput])

  const handleDepositosF2Callback = useCallback((empresa: Empresa) => {
    if (empresa) {
      setEmpresaId(empresa.id as number)
      if (mode === 'register') {
        dispatch(setCampoValorPedidoEmAndamento({
          clienteId: cliente.id as number,
          campo: 'deposito',
          valor: empresa
        }))

        dispatch(setErroCampoValorPedidoEmAndamento({
          clienteId: cliente.id as number,
          campo: 'deposito_id',
          valor: null
        }))
      } else {
        setShowDeposito(false)
        dispatch(setCampoValorPedidoEmAberto({
          clienteId: cliente.id as number,
          pedidoId: orcamentoIdEmEdicao as number,
          campo: 'deposito',
          valor: empresa
        }))
      }
    }
  }, [mode, dispatch, cliente.id, orcamentoIdEmEdicao])

  // const handleTransportadorasF2Callback = useCallback(async (transportadora: Cliente) => {
  //   const verifica = await handleVerificaFrete()

  //   if (verifica === true) {
  //     return
  //   }

  //   if (transportadora) {
  //     setTransportadoraId(transportadora.id as number)
  //     if (mode === 'register') {
  //       dispatch(setCampoValorPedidoEmAndamento({
  //         clienteId: cliente.id as number,
  //         campo: 'transportadora',
  //         valor: transportadora
  //       }))

  //       handleRemoveErroInput('transportadora_id')
  //     } else {
  //       dispatch(setCampoValorPedidoEmAberto({
  //         clienteId: cliente.id as number,
  //         pedidoId: orcamentoIdEmEdicao as number,
  //         campo: 'transportadora',
  //         valor: transportadora
  //       }))
  //     }
  //   }
  // }, [mode, dispatch, cliente.id, handleRemoveErroInput, orcamentoIdEmEdicao])

  const handleContatoIdOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    // não é permitida a troca de contato na edição de pedido
    if (mode === 'edit') {
      return
    }

    let [contato] = cliente?.contatos?.filter(cont => cont.id === parseInt(e.target.value)) as Contato[]

    if (!contato?.id) {
      contato = {
        nome: ''
      } as Contato
    }

    dispatch(setCampoValorPedidoEmAndamento({
      clienteId: cliente.id as number,
      campo: 'contato',
      valor: contato
    }))

    handleRemoveErroInput('contato_id')
  }, [mode, cliente.contatos, cliente.id, dispatch, handleRemoveErroInput])

  const handleEmpresaIdOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let id = 0

    if (!!e.target.value && e.target.value as unknown as number > 0) {
      id = e.target.value as unknown as number
    }

    if (!e.target.value) {
      const empresa = {
        id: '',
        nome: ''
      } as unknown as Empresa

      if (mode === 'register') {
        dispatch(setCampoValorPedidoEmAndamento({
          clienteId: cliente.id as number,
          campo: 'deposito',
          valor: empresa
        }))
      } else {
        dispatch(setCampoValorPedidoEmAberto({
          clienteId: cliente.id as number,
          pedidoId: orcamentoIdEmEdicao as number,
          campo: 'deposito',
          valor: empresa
        }))
      }
      handleRemoveErroInput('deposito_id')
    }

    setEmpresaId(id)
  }, [mode, handleRemoveErroInput, dispatch, cliente.id, orcamentoIdEmEdicao])

  const handleFuncionarioF2CallBack = useCallback((funcionario: Funcionario) => {
    if (funcionario) {
      setFuncionarioId(funcionario.id as number)
      if (mode === 'register') {
        dispatch(setCampoValorPedidoEmAndamento({
          clienteId: cliente.id as number,
          campo: 'funcionario',
          valor: funcionario
        }))

        dispatch(setErroCampoValorPedidoEmAndamento({
          clienteId: cliente.id as number,
          campo: 'funcionario_id',
          valor: null
        }))
      } else {
        dispatch(setCampoValorPedidoEmAberto({
          clienteId: cliente.id as number,
          pedidoId: orcamentoIdEmEdicao as number,
          campo: 'funcionario',
          valor: funcionario
        }))
      }
    }
  }, [cliente.id, dispatch, mode, orcamentoIdEmEdicao])

  const handleFuncionario2F2CallBack = useCallback((funcionario: Funcionario) => {
    if (funcionario) {
      setFuncionario2Id(funcionario.id as number)
      if (mode === 'register') {
        dispatch(setCampoValorPedidoEmAndamento({
          clienteId: cliente.id as number,
          campo: 'funcionario2',
          valor: funcionario
        }))

        dispatch(setErroCampoValorPedidoEmAndamento({
          clienteId: cliente.id as number,
          campo: 'funcionario2_id',
          valor: null
        }))
      } else {
        dispatch(setCampoValorPedidoEmAberto({
          clienteId: cliente.id as number,
          pedidoId: orcamentoIdEmEdicao as number,
          campo: 'funcionario2',
          valor: funcionario
        }))
      }
    }
  }, [mode, dispatch, cliente.id, orcamentoIdEmEdicao])

  const handleFuncionarioIdOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    // não é possível editar o funcionario principal na ediçao de pedido
    if (mode === 'edit') {
      return
    }

    let id = 0

    if (!!e.target.value && e.target.value as unknown as number > 0) {
      id = e.target.value as unknown as number
    }

    if (!e.target.value) {
      const funcionario = {
        id: '',
        nome: ''
      }

      dispatch(setCampoValorPedidoEmAndamento({
        clienteId: cliente.id as number,
        campo: 'funcionario',
        valor: funcionario
      }))

      dispatch(setErroCampoValorPedidoEmAndamento({
        clienteId: cliente.id as number,
        campo: 'funcionario_id',
        valor: null
      }))
    }

    setFuncionarioId(id)
  }

  const handleFuncionario2IdOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let id = 0

    if (!!e.target.value && e.target.value as unknown as number > 0) {
      id = e.target.value as unknown as number
    }

    if (!e.target.value) {
      const funcionario = {
        id: '',
        nome: ''
      } as Cliente

      if (mode === 'register') {
        dispatch(setCampoValorPedidoEmAndamento({
          clienteId: cliente.id as number,
          campo: 'funcionario2',
          valor: funcionario
        }))

        dispatch(setErroCampoValorPedidoEmAndamento({
          clienteId: cliente.id as number,
          campo: 'funcionario2_id',
          valor: null
        }))
      } else {
        dispatch(setCampoValorPedidoEmAberto({
          clienteId: cliente.id as number,
          pedidoId: orcamentoIdEmEdicao as number,
          campo: 'funcionario2',
          valor: funcionario
        }))

        dispatch(setErroCampoValorPedidoEmAberto({
          pedidoId: orcamentoIdEmEdicao as number,
          clienteId: cliente.id as number,
          campo: 'funcionario2_id',
          valor: null
        }))
      }
    }

    setFuncionario2Id(id)
  }, [cliente.id, dispatch, mode, orcamentoIdEmEdicao])

  const jurosInputValue = () => {
    let retorno = 0
    if (mode === 'register') {
      retorno = atendimentoCliente.pedidoEmAndamento.pedido.juros || 0
    } else if (mode === 'edit') {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.juros
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.juros
      retorno = emEdicao || original || 0
    }
    return retorno
  }

  const fretePagoInputValue = useMemo(() => {
    if (mode === 'register') {
      return atendimentoCliente.pedidoEmAndamento.pedido.frete_por_conta
    } else if (mode === 'edit') {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.frete_por_conta
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.frete_por_conta

      return emEdicao || original
    }
  }, [atendimentoCliente.pedidoEmAndamento.pedido, atendimentoCliente.pedidosEmAberto.data, mode, orcamentoIdEmEdicao])

  const transportadoraIdInputValue = () => {
    if (transportadoraId) {
      return transportadoraId
    } else {
      if (mode === 'register') {
        return atendimentoCliente.pedidoEmAndamento.pedido.transportadora?.id
      } else if (mode === 'edit') {
        const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.transportadora?.id
        const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.transportadora?.id
        if (emEdicao === '' || (emEdicao && emEdicao > 0)) {
          return emEdicao
        } else {
          return original
        }
      }
    }
  }

  const transportadoraNomeInputValue = () => {
    if (mode === 'register') {
      return capitalize(atendimentoCliente.pedidoEmAndamento.pedido.transportadora?.nome_fantasia as string)
    } else if (mode === 'edit') {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.transportadora?.nome_fantasia as string
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.transportadora?.nome_fantasia as string
      if (emEdicao || emEdicao === '') {
        return capitalize(emEdicao)
      } else {
        return capitalize(original)
      }
    }
  }

  const getFreteCotacao = useCallback(() => {
    let retorno = 0
    if (mode === 'register') {
      retorno = atendimentoCliente.pedidoEmAndamento.pedido.frete_cotacao || 0
    } else {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.frete_cotacao
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.frete_cotacao
      if (emEdicao !== undefined && emEdicao >= 0) {
        retorno = emEdicao
      } else if (original !== undefined && original >= 0) {
        retorno = original
      }
    }
    return retorno
  }, [atendimentoCliente, mode, orcamentoIdEmEdicao])

  const getFreteValor = useCallback(() => {
    let retorno = 0
    if (mode === 'register') {
      retorno = atendimentoCliente.pedidoEmAndamento.pedido.frete_valor || 0
    } else {
      const emEdicao = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao?.frete_valor
      const original = atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original?.frete_valor
      if (emEdicao !== undefined && emEdicao >= 0) {
        retorno = emEdicao
      } else if (original !== undefined && original >= 0) {
        retorno = original
      }
    }
    return retorno
  }, [atendimentoCliente, mode, orcamentoIdEmEdicao])

  const handleCondicaoPagamentoOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let condicao_pagamento = e.currentTarget.value

    if (!!e.currentTarget.value && e.currentTarget.value !== '') {
      condicao_pagamento = e.currentTarget.value.replace(/[^0-9,]/g, '')
    }
    handleRemoveErroInput('condicao_pagamento')
    setCondicaoPagamento(condicao_pagamento)
  }, [handleRemoveErroInput])

  const handleCondicaoPagamentoSelectOnChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    let condicao_pagamento = ''

    if (!!e.currentTarget.value && e.currentTarget.value !== '') {
      condicao_pagamento = e.currentTarget.value
    }
    handleRemoveErroInput('condicao_pagamento')
    setCondicaoPagamento(condicao_pagamento)
  }, [handleRemoveErroInput])

  const handlepagamento_tipoOnChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const pagamento_tipo = e.target.value

    if (pagamento_tipo !== 'cartao') {
      setCondicaoPagamento('')

      if (mode === 'register') {
        dispatch(setCampoValorPedidoEmAndamento({
          clienteId: cliente.id as number,
          campo: 'condicao_pagamento',
          valor: ''
        }))
      } else {
        dispatch(setCampoValorPedidoEmAberto({
          clienteId: cliente.id as number,
          pedidoId: orcamentoIdEmEdicao as number,
          campo: 'condicao_pagamento',
          valor: ''
        }))
      }
    }

    if (mode === 'register') {
      dispatch(setCampoValorPedidoEmAndamento({
        clienteId: cliente.id as number,
        campo: 'pagamento_tipo',
        valor: pagamento_tipo
      }))
    } else {
      dispatch(setCampoValorPedidoEmAberto({
        clienteId: cliente.id as number,
        pedidoId: orcamentoIdEmEdicao as number,
        campo: 'pagamento_tipo',
        valor: pagamento_tipo
      }))
    }
  }, [mode, dispatch, cliente.id, orcamentoIdEmEdicao])

  const handleBlurCondicaoPagamento = () => {
    let newPedido = {} as GetPedido
    if (mode === 'register') {
      const pedidoAtual = atendimentoCliente.pedidoEmAndamento.pedido
      newPedido = {
        ...pedidoAtual,
        condicao_pagamento: condicaoPagamentoInputValue
      }
    } else {
      newPedido = {
        ...atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original,
        ...atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao,
        condicao_pagamento: condicaoPagamentoInputValue
      } as GetPedido
    }

    callRecalc(newPedido, 'condicao_pagamento')
  }

  const handleObservacaoOnChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    let text = ''

    if (!!e.target.value && e.target.value as unknown as string !== '') {
      text = e.target.value as unknown as string
    }

    setObservacao(text)

    if (mode === 'register') {
      dispatch(setCampoValorPedidoEmAndamento({
        clienteId: cliente.id as number,
        campo: 'observacao',
        valor: text
      }))
    } else {
      dispatch(setCampoValorPedidoEmAberto({
        clienteId: cliente.id as number,
        pedidoId: orcamentoIdEmEdicao as number,
        campo: 'observacao',
        valor: text
      }))
    }
  }, [cliente.id, dispatch, mode, orcamentoIdEmEdicao])

  const handleObservacaoFiscalOnChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    let text = ''

    if (!!e.target.value && e.target.value as unknown as string !== '') {
      text = e.target.value as unknown as string
    }

    if (mode === 'register') {
      dispatch(setCampoValorPedidoEmAndamento({
        clienteId: cliente.id as number,
        campo: 'observacao_nota_fiscal',
        valor: text
      }))
    } else {
      dispatch(setCampoValorPedidoEmAberto({
        clienteId: cliente.id as number,
        pedidoId: orcamentoIdEmEdicao as number,
        campo: 'observacao_nota_fiscal',
        valor: text
      }))
    }

    setObservacaoNotaFiscal(text)
  }, [cliente.id, dispatch, mode, orcamentoIdEmEdicao])

  const handlePossibilidadeOnChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    let possibilidades = ''

    if (!!e.currentTarget.value && e.currentTarget.value !== '') {
      possibilidades = e.currentTarget.value
    }

    if (mode === 'register') {
      dispatch(setCampoValorPedidoEmAndamento({
        clienteId: cliente.id as number,
        campo: 'possibilidades',
        valor: possibilidades
      }))
    } else {
      dispatch(setCampoValorPedidoEmAberto({
        clienteId: cliente.id as number,
        pedidoId: orcamentoIdEmEdicao as number,
        campo: 'possibilidades',
        valor: possibilidades
      }))
    }
  }, [mode, dispatch, cliente.id, orcamentoIdEmEdicao])

  const handleBndesOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let bndes = ''
    if (e.target.checked) {
      bndes = 's'
    } else {
      bndes = 'n'
    }
    if (mode === 'register') {
      dispatch(setCampoValorPedidoEmAndamento({
        clienteId: cliente.id as number,
        campo: 'bndes',
        valor: bndes
      }))
    } else {
      dispatch(setCampoValorPedidoEmAberto({
        clienteId: cliente.id as number,
        pedidoId: orcamentoIdEmEdicao as number,
        campo: 'bndes',
        valor: bndes
      }))
    }
  }, [cliente.id, dispatch, mode, orcamentoIdEmEdicao])

  const handleCodigoBndesOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let bndes_codigo = ''

    if (!!e.currentTarget.value && e.currentTarget.value !== '') {
      bndes_codigo = e.currentTarget.value.replace(/[^0-9]/g, '')
    }

    if (mode === 'register') {
      dispatch(setCampoValorPedidoEmAndamento({
        clienteId: cliente.id as number,
        campo: 'bndes_codigo',
        valor: bndes_codigo
      }))
    } else {
      dispatch(setCampoValorPedidoEmAberto({
        clienteId: cliente.id as number,
        pedidoId: orcamentoIdEmEdicao as number,
        campo: 'bndes_codigo',
        valor: bndes_codigo
      }))
    }
    setBndesCodigo(bndes_codigo)
  }, [mode, dispatch, cliente.id, orcamentoIdEmEdicao])

  const handleCleanAndSetErroInput = useCallback((nome?: string, message?: string) => {
    if (mode === 'register') {
      switch (nome) {
        case 'condicao_pagamento':
          setCondicaoPagamento('0')
          dispatch(setErroCampoValorPedidoEmAndamento({
            clienteId: cliente.id as number,
            campo: nome as keyof ErrosPedido,
            valor: message
          }))
          break
      }
    } else {
      switch (nome) {
        case 'condicao_pagamento':
          setCondicaoPagamento('0')
          dispatch(setErroCampoValorPedidoEmAberto({
            clienteId: cliente.id as number,
            pedidoId: orcamentoIdEmEdicao as number,
            campo: nome as keyof ErrosPedido,
            valor: message
          }))
          break
      }
    }
  }, [cliente.id, dispatch, mode, orcamentoIdEmEdicao])

  const callRecalc = useCallback(async (pedido: GetPedido, nome?: string) => {
    if (!pedido) {
      return
    }
    if (mode === 'register') {
      const resp = await recalcularPedido({ pedido: pedido, mode })
      if (resp && resp.data) {
        dispatch(setPedidoRecalculado({
          cliente_id: cliente.id as number,
          data: resp.data.data as unknown as GetPedido
        }))
      } else if (resp && resp.error) {
        handleError(resp.error)
        handleCleanAndSetErroInput(nome, resp.error.message)
      }
    } else {
      const resp = await recalcularPedido({ pedido: pedido, mode })
      if (resp && resp.data) {
        dispatch(setPedidoRecalculado({
          cliente_id: cliente.id as number,
          pedido_id: orcamentoIdEmEdicao as number,
          data: resp.data.data as unknown as GetPedido
        }))
      } else if (resp && resp.error) {
        handleError(resp.error)
        handleCleanAndSetErroInput(nome, resp.error.message)
      }
    }
  }, [mode, recalcularPedido, dispatch, cliente.id, handleError, handleCleanAndSetErroInput, orcamentoIdEmEdicao])

  const handleChangeDepositos = async (empresa: Empresa) => {
    if (!empresa || mode !== 'edit') {
      return
    }
    const { isConfirmed } = await Swal.fire({
      icon: 'warning',
      title: 'Mudança de Estoque!',
      text: 'Mudar o estoque do pedido irá zerar os valores de frete e recalcular o pedido, deseja proceseguir?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    })
    if (isConfirmed) {
      const newPedido = {
        ...atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].original,
        ...atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number].emEdicao,
        frete_cotacao: 0,
        frete_valor: 0,
        frete_por_conta: 'destinatario',
        deposito: empresa
      } as GetPedido
      callRecalc(newPedido, '')

      setShowDeposito(false)
    }
  }

  const handleDisableFuncionario = () => {
    if (!contatoNomeInputValue || mode === 'edit' || bloquearAlteracoes?.status) {
      return true
    } else if (mode === 'register' && cliente.funcionario_fidelizado && cliente.funcionario_fidelizado > 0) {
      return true
    } else {
      return false
    }
  }

  const handleClickTrocaDeposito = () => {
    if (bloquearAlteracoes?.status) {
      Swal.fire({
        icon: 'warning',
        title: 'Alterações Bloqueadas!',
        html: bloquearAlteracoes?.message
      })
    } else {
      setShowDeposito(true)
    }
  }

  const erros = (mode === 'register'
    ? atendimentoCliente?.pedidoEmAndamento?.erros
    : atendimentoCliente?.pedidosEmAberto.data[orcamentoIdEmEdicao as number].erros
  ) as ErrosPedido

  return (
    <Container>
      {(loadingRecacular || loadingRevenda || loadingCliente) && <Loading />}
      {/* id, status e data */}
      {/* {atendimentoCliente.pedidosEmAberto.data[orcamentoIdEmEdicao as number]} */}
      {mode === 'edit' && <FormRow className='dadosGerais'>
        <Input
          width='2'
          name="orcamento.id"
          title="Código do orçamento / pedido"
          label="Código"
          value={orcamentoIdInputValue}
          type="text"
          copyValue={true}
          disabled
        />
        <Input
          width='2'
          name="orcamento.status"
          title="Status do orçamento / pedido"
          label="Status"
          value={capitalize(orcamentoStatusInputValue as string)}
          type="text"
          disabled
        />
        <CheckBox
          label='Pedido Desbl.'
          checked={bloqueios?.pedido_desbloqueado === 's'}
          disabled
        />
        <Input
          width='2'
          name="orcamento.data"
          title="Data do orçamento / pedido"
          label="Data"
          value={orcamentoDataInputValue}
          type="text"
          disabled
        />
      </FormRow>}

      {/* cliente */}
      <FormRow className='dadosGerais'>
        <Input
          width='1'
          name="cliente.id"
          title="Código do cliente"
          label="Código cliente"
          value={cliente.id}
          placeholder='Cod. cliente'
          type="text"
          disabled
        />
        <Input
          width='4'
          name="cliente.nome"
          label="Cliente"
          title="Cliente"
          value={clienteNameInput()}
          placeholder='Nome do Cliente'
          disabled
        />

        {clienteNameInput() !== '' && (
          <ButtonDiv marginLeft='-5px !important'>
            <Button
              mode='confirm'
              type='button'
              onClick={() => handleModalCliente(Number(cliente.id))}
              disabled={Number(cliente.id) < 0}
            ><FaUser /></Button>
          </ButtonDiv>
        )}

        {observacaoTela.showCliente && <ButtonDiv>
          <Button
            mode='danger'
            type='button'
            startIcon={FaExclamation}
            onClick={() => setShowObsTela(true)}
          >Atenção</Button>
        </ButtonDiv>
        }
        <Input
          width='1'
          name="cliente.status"
          title="Status do Cliente"
          label="Status do Cliente"
          value={capitalize(colorStatusCliente().value)}
          placeholder='Status do Cliente'
          type="text"
          color={colorStatusCliente().color}
          disabled
        />
      </FormRow>

      {/* uso e adiantamento */}
      <FormRow className='dadosGerais'>
        <CheckBox
          label='Uso e Consumo'
          width='1'
          name='uso e consumo'
          checked={uso_consumoValue() === 'Sim'}
          disabled
        />
        {/* <CheckBox
          label='Limite Bloq'
          width='1'
          checked={bloqueios?.limite_bloqueado === 'n'}
          disabled
        /> */}
        <Input
          label='Adiantamento'
          width='2'
          name='adiantamento'
          value={valorAdiantamento()}
          disabled
        />
        <Input
          label='Adiantamento Pago'
          width='2'
          name='adiantamento pago'
          value={valorPagoAdiantamento()}
          backgroundColor={valorPagoAdiantamento() > 0 ? 'success' : ''}
          disabled
        />
        <Input
          width='1'
          name="cliente.analise_credito"
          title="Analise de Crédito"
          label="Analise de Crédito"
          value={capitalize(analiseCreditoInputValue() as string)}
          placeholder='Analise de Crédito'
          color={analiseColor(analiseCreditoInputValue())}
          type="text"
          disabled
        />
      </FormRow>

      {/* bndes, possibilidaes */}
      <FormRow className='dadosGerais'>
        <CheckBox
          label='BNDES'
          width='1'
          onChange={handleBndesOnChange}
          checked={bndesCheckValue() === 's'}
          disabled={bloquearAlteracoes?.status}
        />
        <Input
          label="Código BNDES"
          width='1'
          onChange={handleCodigoBndesOnChange}
          value={codigoBndesInputValue()}
          placeholder=''
          disabled={bndesCheckValue() === 'n' || bloquearAlteracoes?.status}
        />
        <Input
          label="Doc. Fiscal"
          width='1'
          value={documentoFiscal()}
          placeholder='Doc. Fiscal'
          disabled
        />
        <Select
          title='Possibilidades'
          width='1'
          name='possibilidade'
          onChange={handlePossibilidadeOnChange}
          value={possibilidadeInputValue()}
        >
          <option value=''>Selecione uma Possibilidade</option>
          {possibility_options.map(tipo =>
            <option key={tipo.value} value={tipo.value}>{capitalize(tipo.label)}</option>
          )}
        </Select>
      </FormRow>

      {/* contato */}
      <FormRow className='dadosGerais'>
        <Input
          width='1'
          name="contato.id"
          title="Código do contato"
          label="Código contato"
          value={contatoIdInputValue}
          placeholder='Cod. contato'
          type="text"
          f2Title="Lista de contatos"
          f2Content={
            <ListaContatos cliente={cliente} loadingCliente={!!cliente.contatos} />
          }
          f2CallBack={handleContatoF2CallBack}
          onChange={handleContatoIdOnChange}
          disabled
          error={erros.contato_id}
        />

        <Input
          width='5'
          name="contato.nome"
          label="Contato"
          title="Contato"
          value={contatoNomeInputValue}
          placeholder='Nome do Contato'
          disabled
        />
      </FormRow>

      {/* revenda */}
      {(tipoFaturamentoPedido && tipoFaturamentoPedido === 'direto') && <FormRow className='dadosGerais'>
        <Input
          width='1'
          name="revenda.id"
          title="Código do cliente revenda ou F2 para buscar"
          label="Código cliente da revenda"
          value={clienteRevendaIdInputValue}
          placeholder='Cod. cliente da revenda'
          type="text"
          f2Title="Lista do cliente da revenda"
          f2Content={
            <ListaClientes isRevenda={!(tipoFaturamentoPedido === 'direto' && solar === 's')} modo={(tipoFaturamentoPedido === 'direto' && solar === 's') ? 'todos' : 'revendas'} />
          }
          f2CallBack={handlerevendaF2CallBack}
          f2ModalMode='wide'
          onChange={handleclienteRevendaIdOnChange}
          onKeyPress={handleEnterClienteRevenda}
          // onBlur={changeClienteRevenda}
          disabled={mode === 'edit'}
          error={erros.cliente_id}
          data-cy='inputRevendaIdDadosGerais'
        />

        <Input
          width='5'
          name="revenda.nome"
          label="Cliente da revenda"
          title="Cliente da revenda"
          value={clienteRevendaNomeInputValue}
          placeholder='Nome do cliente da revenda'
          disabled
        />

        {(clienteRevendaNomeInputValue !== '' && clienteRevendaNomeInputValue !== undefined) ? (
          <ButtonDiv marginLeft='-5px !important'>
            <Button
              mode='confirm'
              type='button'
              onClick={() => handleModalCliente(Number(clienteRevendaIdInputValue))}
            ><FaUser /></Button>
          </ButtonDiv>
        ) : ''}

        {observacaoTela.showRevenda && <ButtonDiv>
          <Button
            mode='danger'
            type='button'
            startIcon={FaExclamation}
            onClick={() => setShowObsTela(true)}
          >Atenção</Button>
        </ButtonDiv>
        }
      </FormRow>}

      {/* deposito */}
      <FormRow className='dadosGerais'>
        <Input
          width='1'
          name="empresa.id"
          title={!contatoNomeInputValue ? 'Primeiro selecione um contato' : 'Código depósito ou F2 para buscar'}
          label='Código depósito'
          placeholder='Cod. depósito'
          type="text"
          f2Title="Lista de depósitos"
          f2Content={
            <ListaEmpresas />
          }
          f2CallBack={handleDepositosF2Callback}
          onChange={handleEmpresaIdOnChange}
          onKeyPress={handleEnterDeposito}
          onKeyDownCapture={handleTabDeposito}
          onBlur={changeDeposito}
          value={depositoIdInputValue}
          disabled={mode === 'edit' && temProduto}
          error={erros.deposito_id}
          data-cy='inputDepositoDadosGerais'
        />

        <Input
          width={mode === 'edit' ? '4' : '5'}
          name="empresa.nome"
          label="Depósito"
          value={depositoNomeInputValue}
          placeholder='Depósito'
          disabled
        />

        {mode === 'edit' && <DivButton width='10%'>
          <Button
            mode='confirm'
            type='button'
            onClick={handleClickTrocaDeposito}
          >Trocar Deposito</Button>
        </DivButton>}
      </FormRow>

      {/* funcionario */}
      <FormRow className='dadosGerais'>
        <Input
          width='1'
          name="funcionario.id"
          title={!contatoNomeInputValue ? 'Primeiro selecione um contato' : 'Código funcionário ou F2 para buscar'}
          label="Código funcionário"
          placeholder='Cod. funcionário'
          value={funcionarioIdInputValue}
          onChange={handleFuncionarioIdOnChange}
          onKeyPress={handleEnterFuncionario}
          onBlur={changeFuncionario}
          type="text"
          f2Title="Lista de funcionários"
          f2Content={
            <ListaFuncionarios />
          }
          f2ModalMode="normal"
          f2CallBack={handleFuncionarioF2CallBack}
          disabled={handleDisableFuncionario()}
          error={erros.funcionario_id}
        />

        <Input
          width='5'
          name="funcionario.nome"
          label="Funcionário"
          value={funcionarioNomeInputValue}
          placeholder='Funcionário'
          disabled
        />
      </FormRow>

      {/* funcionario 2 */}
      <FormRow className='dadosGerais'>
        <Input
          width='1'
          name="funcionario2.id"
          label="Código Funcionário 2"
          title={!contatoNomeInputValue ? 'Primeiro selecione um contato' : 'Código funcionário ou F2 para buscar'}
          placeholder='Cod. funcionario 2'
          value={funcionario2IdInputValue()}
          onChange={handleFuncionario2IdOnChange}
          onKeyPress={handleEnterFuncionario2}
          onBlur={changeFuncionario2}
          type="text"
          f2Title="Lista de funcionários"
          f2Content={
            <ListaFuncionarios />
          }
          f2ModalMode="normal"
          f2CallBack={handleFuncionario2F2CallBack}
          error={erros.funcionario2_id}
          disabled={bloquearAlteracoes?.status}
        />

        <Input
          width='5'
          name="funcionario2.nome"
          label="Funcionário 2"
          value={funcionario2NomeInputValue()}
          placeholder='Funcionário 2'
          disabled
        />
      </FormRow>

      {/* modo pagmt, condicao, juros */}
      <FormRow className='dadosGerais'>
        <Select
          width='3'
          name="modo_pagamento"
          onChange={handlepagamento_tipoOnChange}
          value={pagamentoTipoSelectValue}
          title='Modo de pagamento'
          defaultValue=""
          disabled={bloquearAlteracoes?.status || bloquearPagamento?.status}
          data-cy='selectModoPagamento'
        >
          {pagamentoTipoSelectValue !== '' ? (
            <option value='' disabled>Selecione um Tipo</option>
          ) : (
            <option value=''>Selecione um Tipo</option>
          )}
          <option key={'cobranca'} value={'cobranca'}>Cobrança</option>
          <option key={'carteira'} value={'carteira'}>Carteira</option>
          <option key={'cartao'} value={'cartao'}>Cartão</option>
        </Select>

        {pagamentoTipoSelectValue === 'cartao' && <Select
          width='3'
          name='condicao_pagamento'
          title='Condição de pagamento'
          onChange={handleCondicaoPagamentoSelectOnChange}
          value={condicaoPagamentoInputValue}
          onBlur={handleBlurCondicaoPagamento}
          disabled={bloquearAlteracoes?.status || bloquearPagamento?.status}
          data-cy='selectCondicaoPagamento'
        >
          <option value=''>Selecione uma condição</option>
          {condicoes_pagamento.map(condicao =>
            <option key={condicao.value} value={condicao.value}>{capitalize(condicao.label)}</option>
          )}
        </Select>}

        {pagamentoTipoSelectValue !== 'cartao' && <Input
          width='3'
          name="condicao_pagamento"
          label="Condição de pagamento"
          title={'Condição de pagamento'}
          placeholder='Condição de pagamento(ex: 0,30,60,90)'
          value={condicaoPagamentoInputValue}
          onChange={handleCondicaoPagamentoOnChange}
          error={erros.condicao_pagamento}
          type="text"
          onBlur={handleBlurCondicaoPagamento}
          disabled={bloquearAlteracoes?.status || bloquearPagamento?.status || (pagamentoTipoSelectValue === '')}
        />}
        <CheckBox
          label='Pgto. Gerente'
          // width='0 0 14%'
          checked={bloqueios?.pagamento_gerente === 's'}
          disabled
        />
        <Input
          width='3'
          name="juros"
          label="Juros"
          value={jurosInputValue() + '%'}
          placeholder='Juros'
          disabled
          data-cy='inputJurosDadosGerais'
        />
        <CheckBox
          label='Juros Forçado'
          // width='0 0 14%'
          checked={bloqueios?.juros_forcado === 's'}
          disabled
        />
      </FormRow>

      {/* frete */}
      <FormRow className='dadosGerais'>
        <Input
          width='1'
          name="transportadora.id"
          data-cy='inputTransportadoraDadosGerais'
          title={'Código transportadora ou F2 para buscar'}
          label="Cod. Transp."
          placeholder='Cod. transportadora'
          value={transportadoraIdInputValue()}
          disabled={true}
          type="text"
        />
        <Input
          width='6'
          name="transportadora.nome"
          label="Transportadora"
          value={transportadoraNomeInputValue()}
          placeholder='Transportadora'
          disabled
        />

        <CheckBox
          label='Frete Pago'
          title='Sim'
          width='0 0 10%'
          checked={fretePagoInputValue === 'emitente' && getFreteCotacao()! > 0}
          disabled={true}
        />

        <Input
          label='Cotação do Frete'
          width='0 0 20%'
          name='Cotação Frete'
          type='currency'
          value={getFreteCotacao()}
          disabled
        />

        <Input
          label='Valor do Frete'
          width='0 0 20%'
          name='frete_valor'
          type='currency'
          value={getFreteValor()}
          disabled
        />
      </FormRow>

      {/* obs */}
      <FormRow className='dadosGerais'>
        <Textarea
          label='Observação'
          value={observacaoInputValue()}
          onChange={handleObservacaoOnChange}
          error={erros.observacao}
          height='75px'
          disabled={bloqueios?.status_pedido === 'faturado'}
        />
        <Textarea
          label='Observação Fiscal'
          value={observacaoFiscalInputValue()}
          onChange={handleObservacaoFiscalOnChange}
          error={erros.observacao_nota_fiscal}
          height='75px'
          disabled={bloqueios?.status_pedido === 'faturado'}
        />
      </FormRow>

      {showObsTela && <Modal
        title='Atenção'
        mode='normal'
        close={() => setShowObsTela(false)}
      >
        <Textarea
          value={observacaoTela.message}
          height='250px'
          disabled
        />
      </Modal>
      }

      {showDeposito && <Modal
        title='Trocar Deposito'
        mode='normal'
        close={() => setShowDeposito(false)}
      >
        <ListaEmpresas callBack={handleChangeDepositos} uf={depositoUfValue} />
      </Modal>
      }

      {showModalCliente && <Modal
        title='Cliente'
        mode='normal'
        showButtonSave={false}
        buttonCancelText={'Fechar'}
        close={() => setShowModalCliente(false)}
      >
        <DadosResumoCliente data={modalCliente} />
      </Modal>
      }

    </Container>
  )
}
