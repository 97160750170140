import React, { createContext, useState } from 'react'
import { Contato } from '../../modules/clientes/models'

type ModeTypes = 'create' | 'edit'

export interface CadastroContatoContextProps {
  data?: Contato
  setData (contato: Contato): void
  resetData (): void
  dataMode: ModeTypes
  editing: boolean
  setEditing: (editing: boolean) => void
  setDataMode: (mode: ModeTypes) => void
}

const initialState: Contato = {
  comercial: 'n',
  financeiro: 'n',
  fiscal: 'n',
  email: '',
  nome: '',
  status: 'ativo',
  telefones: []
}

export const CadastroContatoContext = createContext<CadastroContatoContextProps>({} as CadastroContatoContextProps)

export const CadastroContatoProvider: React.FC = ({ children }) => {
  const [contato, setContato] = useState<Contato>(initialState)
  const [mode, setMode] = useState<ModeTypes>('create')
  const [editing, setEditing] = useState(false)

  const resetData = () => {
    setContato(initialState)
  }

  return (
    <CadastroContatoContext.Provider value={{
      data: contato, setData: setContato, resetData, dataMode: mode, setDataMode: setMode, setEditing, editing
    }} >
      {children}
    </CadastroContatoContext.Provider>
  )
}
