/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react'
import axios from 'axios'
import useSWR from 'swr'
import { useUsuario } from '..'

export function useVersaoFrontend () {
  const { data: usuarioData } = useUsuario()
  const url = `${process.env.REACT_APP_BASE_URL_API_VENDAS}/versoes/front_vendas`

  const fetcher = (url: string) => axios.get(url, {
    headers: {
      Authorization: `Bearer ${usuarioData?.token}`
    }
  }).then(resp => resp.data)

  const { data } = useSWR(url, () => fetcher(url), { refreshInterval: 300000 })

  const atualizarVersaoFrontend = useCallback(async (token) => {
    // const response = await axios.post(`${process.env.REACT_APP_BASE_URL_API_VENDAS}/versoes`, {
    //   tipo: 'front_vendas',
    //   versao: `${packageJson.version}`
    // }, {
    //   headers: {
    //     Authorization: `Bearer ${token}`
    //   }
    // })

    // if (response.status === 204) {
    window.location.reload()
    // }
  }, [])

  return {
    atualizarVersaoFrontend,
    data
  }
}
