/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, ChangeEvent, useCallback, KeyboardEvent } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../../../store'
import { Button, Input, ListaComprasProdutos, ListaLotes, Modal, ModePedido, Textarea } from '../../..'
import { useListaPrecosProdutos, usePrecoProduto, useUsuario } from '../../../../hooks'
import { useProduto } from '../../../../hooks/produto/useProduto'
import { usePromocoes } from '../../../../hooks/promocoes'
import Skeleton from 'react-loading-skeleton'
import { FaShoppingCart, FaTag } from 'react-icons/fa'
import Swal from 'sweetalert2'
import debounce from 'lodash.debounce'
import { clienteParamsPreco } from '../../../../../helpers'
import { AtendimentosState } from '../../../../store/atendimentos/interfaces'
import { GetPromocoesParams } from '../../../../../modules/promocoes/models'
import { Lote, Preco, Produto } from '../../../../../modules/produtos/models'
import { Item } from '../../../../../modules/pedidos/models'
import { Cliente } from '../../../../../modules/clientes/models'
import {
  Container,
  DadosDiv,
  DivSkeleton,
  DadosDivButton,
  ContainerDiv,
  ContainerColumn,
  DivButton,
  DivButtonSale
} from './styles'

interface AddProdutoProps {
  cliente?: Cliente
  revenda?: Cliente
  mode?: ModePedido
  pedidoIdEmEdicao?: number
  produtoId?: number
  setProdutoSelected: React.Dispatch<React.SetStateAction<Item>>
  handleIsPrecoDefinido: (isPrecoDefinido: boolean) => void
  handleAddItem: () => void
  handleClickPromocao: () => void
  fakeItemId: number
  setFakeItemId: React.Dispatch<React.SetStateAction<number>>
  close?: () => void
  loading?: React.Dispatch<React.SetStateAction<boolean>>
}

export const AdicionarProduto = ({
  cliente,
  revenda,
  mode,
  pedidoIdEmEdicao,
  produtoId = 0,
  setProdutoSelected,
  handleIsPrecoDefinido,
  handleAddItem,
  handleClickPromocao,
  close,
  fakeItemId,
  setFakeItemId,
  loading
}: AddProdutoProps) => {
  const atendimentosState = useSelector<AppState>((state) => state.atendimentos) as AtendimentosState
  const [quantidade, setQuantidade] = useState(1)
  const [lote, setLote] = useState<string>()
  const { data: produto, loading: loadingProduto } = useProduto(produtoId)
  const [st, setSt] = useState(0)
  const [ipi, setIpi] = useState(0)
  const [valor, setValor] = useState<number>()
  const [novoValor, setNovoValor] = useState(0)
  const [novoValorEmEdicao, setNovoValorEmEdicao] = useState(false)
  const [showCompras, setShowCompras] = useState(false)
  const [errorLote, setErrorLote] = useState<string>()
  const [desconto, setDesconto] = useState<string>()
  const [acrescimo, setAcrescimo] = useState(0)

  const { data: usuario } = useUsuario()

  const { fetcher: getPrecoProduto, data: preco, loading: loadingPreco } = usePrecoProduto()
  const { fetcher: getListaPrecoProdutos, data: listaPrecos, loading: loadingListaPrecos } = useListaPrecosProdutos()

  const usoeconsumo = useMemo(() => {
    if (mode === 'register') {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento
        ?.pedido?.usoeconsumo || 'n'
    } else {
      const depositoEdicao =
        atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[
          pedidoIdEmEdicao as number
        ].emEdicao?.usoeconsumo
      const depositoOriginal =
        atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[
          pedidoIdEmEdicao as number
        ].original?.usoeconsumo

      return depositoEdicao || depositoOriginal || 'n'
    }
  }, [atendimentosState])

  const solar = useMemo(() => {
    if (mode === 'register') {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.solar
    } else {
      const depositoEdicao =
        atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number].emEdicao?.solar
      const depositoOriginal =
        atendimentosState.data[cliente?.id as number]?.pedidosEmAberto?.data[pedidoIdEmEdicao as number].original?.solar
      return depositoEdicao || depositoOriginal || 'n'
    }
  }, [atendimentosState])

  const deposito = useMemo(() => {
    if (mode === 'register') {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.deposito
    } else {
      const depositoEdicao = atendimentosState.data[cliente?.id as number]
        ?.pedidosEmAberto?.data[pedidoIdEmEdicao as number].emEdicao?.deposito
      const depositoOriginal = atendimentosState.data[cliente?.id as number]
        ?.pedidosEmAberto?.data[pedidoIdEmEdicao as number].original?.deposito
      return depositoEdicao || depositoOriginal
    }
  }, [atendimentosState, cliente, mode, pedidoIdEmEdicao])

  const [promocaoParams, setPromocaoParams] = useState(() => {
    let params: GetPromocoesParams = {
      body: {
        produtos: []
      },
      filterObject: {
        funcionario_id: usuario?.funcionario_id,
        uf: deposito?.uf,
        status: 'em aberto',
        uf_cliente: cliente?.uf,
        cliente_id: cliente?.id,
        solar: solar
      }
    }
    if (usuario?.token) {
      params.token = usuario.token
    }
    return params
  })

  const { promocoesList } = usePromocoes(promocaoParams)

  const [produtoKit, setProdutoKit] = useState(false)

  const clientePreco = clienteParamsPreco({
    cliente,
    revenda,
    mode,
    atendimentosState,
    pedidoIdEmEdicao
  })

  const precoConsumidor = () => {
    if (revenda && preco?.valor_consumidor && preco?.valor_consumidor > 0 && !novoValorEmEdicao) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (produto && produto.data) {
      if (produto.data.status === 'inativo') {
        close && close()
        Swal.fire({
          icon: 'warning',
          title: 'Produto Inativo',
          text: `Esse produto se encontra inavito, falar com o responsavel (${produto.data.comprador_nome})`,
          showConfirmButton: true,
          confirmButtonText: 'Ok'
        })
      }
      if (produto.data.descontinuado === 's' && produto.data.produto_filho !== undefined && produto.data.produto_filho > 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Atenção!',
          html: `Esse produto foi descontinuado <br>(Não será mais comprado). <br>Será substituido pelo produto ${produto.data.produto_filho}`
        })
      }

      const params = {
        produtoId: produto?.data?.id as number,
        clienteId: clientePreco?.id as number,
        empresaId: deposito?.id as number,
        valor,
        usoeconsumo,
        solar
      }
      getPrecoProduto(params)

      if (produto?.data.kit && produto?.data.kit.length > 0) {
        setProdutoKit(true)
        const listaProdutoid = produto?.data?.kit.map((item) => item.produto_kit_id)
        getListaPrecoProdutos({
          produtos: listaProdutoid as number[],
          empresas: [deposito?.id as number],
          clienteId: clientePreco?.id as number,
          solar,
          usoeconsumo
        })
      }
      setPromocaoParams({
        ...promocaoParams,
        body: {
          produtos: [produto.data.id as number]
        }
      })
    }
  }, [produto])

  useEffect(() => {
    if (produtoKit) {
      return
    }
    if (preco) {
      if (preco.st) {
        setSt((preco.st as number) || 0)
      }
      if (preco.ipi) {
        setIpi((preco.ipi * preco.valor / 100 as number) || 0)
      }
      if (precoConsumidor()) {
        setValor(preco.valor_consumidor ? preco.valor_consumidor : 0)
        setNovoValor((oldState) => oldState || preco.valor_consumidor || 0)
      } else if (preco.valor !== undefined) {
        setValor(preco.valor)
        setNovoValor(preco.valor)
      }
    }
  }, [preco])

  const debounceBuscaPreco = useCallback(
    debounce((value) => {
      getPrecoProduto({
        produtoId: produto?.data?.id as number,
        clienteId: clientePreco?.id as number,
        empresaId: deposito?.id as number,
        valor: value,
        usoeconsumo,
        solar
      })

      loading && loading(false)
    }, 100),
    [produto])

  useEffect(() => {
    if (produto && produto.data && novoValor !== undefined || novoValor > 0) {
      debounceBuscaPreco(novoValor)
    }
  }, [produto])

  const total = () => {
    let total = 0
    if (produtoKit) {
      if (!novoValorEmEdicao) {
        if (listaPrecos) {
          listaPrecos.forEach((element, index) => {
            let produtoIndex: number = 0

            if (produto) {
              produtoIndex = Number(produto.data.kit![index].quantidade)
            }

            total = total + (element.valor * produtoIndex)
          })
        }
      } else {
        total = valor || 0
      }

      const diferenca = total - novoValor

      total = total - diferenca
    } else {
      if (preco && preco?.st > 0 && atendimentosState.data[cliente?.id as number].pedidoEmAndamento.pedido?.tipo_faturamento === 'normal') {
        total = preco?.valor + preco?.st || 0
      } else {
        total = preco?.valor || 0
      }
    }
    return total * quantidade
  }

  useEffect(() => {
    let totais = {
      st: 0,
      ipi: 0,
      valor: 0
    }
    if (produtoKit) {
      if (listaPrecos && produto) {
        listaPrecos.forEach((element, index) => {
          let produtoIndex: number = 0

          if (produto) {
            produtoIndex = Number(produto.data.kit![index].quantidade)
          }
          totais.ipi += element.ipi || 0
          totais.st += element.st || 0
          totais.valor += element.valor * produtoIndex || 0
        })
        setSt(totais.st)
        setIpi(totais.ipi)
        setValor(totais.valor)
        if (!novoValorEmEdicao) {
          setNovoValor(totais.valor)
        }
      }
    } else {
      if (listaPrecos) {
        listaPrecos.forEach((element) => {
          totais.ipi += element.ipi || 0
          totais.st += element.st || 0
          totais.valor += element.valor || 0
        })
        setSt(totais.st)
        setIpi(totais.ipi)
        setValor(totais.valor)
        if (!novoValorEmEdicao) {
          setNovoValor(totais.valor)
        }
      }
    }
  }, [listaPrecos])

  useEffect(() => {
    handleIsPrecoDefinido(false)
    let desconto: number = 0
    let acrescimo: number = 0

    if (preco && preco.valor_original && valor) {
      if (!produtoKit) {
        const diferenca = preco.valor_original - valor
        if (diferenca < 0) {
          acrescimo = diferenca * -1 * quantidade
          desconto = 0
        } else if (diferenca > 0) {
          acrescimo = 0
          desconto = diferenca * quantidade
        } else {
          acrescimo = 0
          desconto = 0
        }
      }
    }

    let produtoItem = {} as Produto
    if (produto && produto.data) {
      produtoItem = {
        ...produto.data
      }
      if (promocoesList && promocoesList.length > 0) {
        produtoItem = {
          ...produto.data,
          promocao: promocoesList
        }
      }
    }

    let item: Item = {
      id: fakeItemId * -1,
      acrescimo,
      desconto: Number(desconto),
      valorUnitario: total() / quantidade || 0,
      valor: novoValor,
      quantidade: quantidade,
      stTotal: st * quantidade || 0,
      ipiTotal: ipi * quantidade || 0,
      total: total() || 0,
      produto: produtoItem || produto?.data,
      preco: (preco as unknown) as Preco,
      falta_liberar: quantidade,
      tem_kit: produtoKit
    }

    if (lote !== undefined) {
      item.lote = lote
    }

    if (item.valorUnitario && item.valorUnitario > 0 && !loadingPreco && !loadingListaPrecos) {
      handleIsPrecoDefinido(true)
      setProdutoSelected(item)
    }
  }, [preco, produto, quantidade, st, valor, lote, loadingPreco, loadingListaPrecos])

  const handleChangeQuantidade = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value)

    if (value < 1 || isNaN(value)) {
      setQuantidade(0)
      return null
    }
    setQuantidade(value)
  }

  const handleChangeDesconto = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    const valor_consumidor = preco?.valor_consumidor || preco?.valor_original || 0
    const valor_original = produtoKit ? valor || 0 : preco?.valor_original || 0

    let resultado = 0

    const diferenca = valor_original * (Number(value) / 100)

    // if (usoeconsumo === 's') {
    // resultado = valor_consumidor - diferenca
    // } else {
    resultado = valor_original - diferenca
    // }

    setNovoValor(resultado)
    setDesconto(value)
    loading && loading(true)
  }

  const handleEnterDescontoAcrescimo = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      // setValor(novoValor)
      if (preco) {
        setIpi(preco?.ipi * novoValor / 100)
      }

      debounceBuscaPreco(novoValor)
    }
  }

  const handleChangeAcrescimo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value) || 0

    const valor_consumidor = preco?.valor_consumidor || preco?.valor_original || 0
    const valor_original = produtoKit ? valor || 0 : preco?.valor_original || 0

    let resultado = 0

    const diferenca = valor_original * (Number(value) / 100)

    // if (usoeconsumo === 's') {
    //   resultado = valor_consumidor + diferenca
    // } else {
    resultado = valor_original + diferenca
    // }

    setNovoValor(resultado)
    setAcrescimo(value)
    loading && loading(true)
  }

  const handleOnBlurNovoValor = () => {
    setNovoValor(novoValor)
    if (preco) {
      setIpi(preco?.ipi * novoValor / 100)
    }

    debounceBuscaPreco(novoValor)
  }

  const novoValorOnKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    event.preventDefault()
    if (event.key === 'Enter') {
      setValor(novoValor)
      if (preco) {
        setIpi(preco?.ipi * novoValor / 100)
      }

      debounceBuscaPreco(novoValor)
    }
  }

  const onInputCurrencyChance = (
    e: ChangeEvent<HTMLInputElement>,
    value: number,
    maskedValue: string
  ): void => {
    setNovoValorEmEdicao(true)
    setNovoValor(value)
  }

  const handleEnterKey = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'Enter' &&
      !loadingProduto &&
      (preco || listaPrecos)
    ) {
      if (valor && valor > 0) {
        if (controlaLotes) {
          if (lote && lote !== '') {
            handleAddItem()
          } else {
            setErrorLote('Adicione um lote a esse produto')
          }
        } else {
          handleAddItem()
        }
      }
    }
  }

  const handleLoteF2CallBack = (f2Lote: Lote) => {
    if (f2Lote) {
      setErrorLote('')
      setLote(f2Lote.lote)
    }
  }

  const estoque = () => {
    let response = 0
    if (produto && produto.data.estoques && produto.data.estoques?.length > 0) {
      const estoqueAtual = produto.data.estoques.find(
        (estoque) => estoque.empresa.id === deposito?.id
      )
      if (estoqueAtual) response = estoqueAtual.saldo as number
    }
    return response
  }

  const controlaLotes = useMemo(() => {
    if (produto && produto.data?.controla_lote === 's') {
      return true
    } else {
      return false
    }
  }, [produto])

  return (
    <Container>
      {produto ? (
        <DadosDiv>
          <Input
            type="text"
            label="Codigo"
            value={produto?.data?.id}
            width="0 0 15%"
            disabled
          />
          <Input
            type="text"
            label="Nome"
            value={
              produto?.data?.nome_tecnico
                ? produto?.data?.nome_tecnico
                : 'Produto não Encontrado!'
            }
            width="0 0 58%"
            error={
              produto?.data?.id
                ? ''
                : 'Id do Produto pesquisado está errado ou não foi encontrado'
            }
            disabled
          />
          <Input
            type="text"
            label="Estoque"
            value={estoque()}
            width="0 0 15%"
            disabled
          />
          <DivButton>
            <Button
              type='button'
              mode='primary'
              startIcon={FaShoppingCart}
              onClick={() => setShowCompras(true)}
            >Compras</Button>
          </DivButton>
        </DadosDiv>
      ) : (
        <DadosDiv>
          <DivSkeleton width="15%">
            <Skeleton width="50%" height={15} className="itemSkeleton" />
            <Skeleton width="100%" height={37} className="itemSkeleton" />
          </DivSkeleton>
          <DivSkeleton width="58%">
            <Skeleton width="50%" height={15} className="itemSkeleton" />
            <Skeleton width="100%" height={37} className="itemSkeleton" />
          </DivSkeleton>
          <DivSkeleton width="15%">
            <Skeleton width="50%" height={15} className="itemSkeleton" />
            <Skeleton width="100%" height={37} className="itemSkeleton" />
          </DivSkeleton>
          <DivSkeleton width="5%">
            <Skeleton width="100%" height={37} className="itemSkeleton" />
          </DivSkeleton>
        </DadosDiv>
      )}
      <ContainerDiv>
        <ContainerColumn>
          {preco ? (
            <DadosDivButton>
              <Input
                type="number"
                label="Quantidade"
                value={quantidade === 0 ? '' : quantidade}
                onChange={handleChangeQuantidade}
                onKeyPress={handleEnterKey}
                width="0 0 32%"
                autoFocus
                tabIndex={1}
              />
              <Input
                type="number"
                label="Desconto (%)"
                placeholder='0'
                value={desconto}
                onChange={handleChangeDesconto}
                onKeyPress={handleEnterDescontoAcrescimo}
                width="0 0 32%"
                tabIndex={2}
                disabled={Number(acrescimo) > 0}
              />
              <Input
                type="number"
                label="Acréscimo (%)"
                placeholder='0'
                value={acrescimo}
                onChange={handleChangeAcrescimo}
                onKeyPress={handleEnterDescontoAcrescimo}
                width="0 0 32%"
                tabIndex={3}
                disabled={Number(desconto) > 0}
              />
            </DadosDivButton>
          ) : (
            <DadosDivButton>
              <DivSkeleton width="32%">
                <Skeleton width="50%" height={15} className="itemSkeleton" />
                <Skeleton width="100%" height={37} className="itemSkeleton" />
              </DivSkeleton>
              <DivSkeleton width="32%">
                <Skeleton width="50%" height={15} className="itemSkeleton" />
                <Skeleton width="100%" height={37} className="itemSkeleton" />
              </DivSkeleton>
              <DivSkeleton width="32%">
                <Skeleton width="50%" height={15} className="itemSkeleton" />
                <Skeleton width="100%" height={37} className="itemSkeleton" />
              </DivSkeleton>
            </DadosDivButton>
          )}
          {preco ? (
            <DadosDiv>
              <Input
                value={st * quantidade}
                label="ST"
                width="0 0 49%"
                type="currency"
                disabled
              />
              <Input
                value={ipi * quantidade}
                label="IPI"
                width="0 0 49%"
                type="currency"
                disabled
              />
            </DadosDiv>
          ) : (
            <DadosDiv>
              <DivSkeleton width="49%">
                <Skeleton width="100%" height={15} className="itemSkeleton" />
                <Skeleton width="100%" height={37} className="itemSkeleton" />
              </DivSkeleton>
              <DivSkeleton width="49%">
                <Skeleton width="50%" height={15} className="itemSkeleton" />
                <Skeleton width="100%" height={37} className="itemSkeleton" />
              </DivSkeleton>
            </DadosDiv>
          )}
        </ContainerColumn>
        <ContainerColumn>
          {preco ? (
            <DadosDiv>
              <Input
                value={novoValor}
                label="Novo valor"
                onInputCurrencyChance={onInputCurrencyChance}
                type="currency"
                width="0 0 49%"
                onKeyPress={novoValorOnKeyPress}
                onBlur={handleOnBlurNovoValor}
                data-cy='inputNovoValorAddProduto'
              />
              <Input
                type="currency"
                label="Valor original"
                value={produtoKit ? valor : preco?.valor_original}
                width="0 0 49%"
                error={
                  preco?.valor_original === 0
                    ? 'Valor do Produto não encontrado ou indisponível no momento'
                    : ''
                }
                disabled
              />
            </DadosDiv>
          ) : (
            <DadosDiv>
              :{' '}
              <DivSkeleton width="49%">
                <Skeleton width="50%" height={15} className="itemSkeleton" />
                <Skeleton width="100%" height={37} className="itemSkeleton" />
              </DivSkeleton>
              :{' '}
              <DivSkeleton width="49%">
                <Skeleton width="50%" height={15} className="itemSkeleton" />
                <Skeleton width="100%" height={37} className="itemSkeleton" />
              </DivSkeleton>
            </DadosDiv>
          )}
          {preco ? (
            <DadosDiv>
              <Input
                type="currency"
                value={total()}
                label="Total"
                disabled
              />
              {promocoesList && promocoesList.length > 0 &&

                <Button
                  type='button'
                  mode='danger'
                  startIcon={FaTag}
                  onClick={handleClickPromocao}
                  style={{ marginTop: '35px' }}
                >Ver Promoção</Button>}
            </DadosDiv>
          ) : (
            <DadosDiv>
              <DivSkeleton width="100%">
                <Skeleton width="50%" height={15} className="itemSkeleton" />
                <Skeleton width="100%" height={37} className="itemSkeleton" />
              </DivSkeleton>
            </DadosDiv>
          )}

        </ContainerColumn>
      </ContainerDiv>
      {produto?.data
        ? <Textarea
          label='Observação:'
          value={produto?.data.observacao_tela}
          disabled
        />
        : <DivSkeleton width="100%">
          <Skeleton width="20%" height={15} className="itemSkeleton" />
          <Skeleton width="100%" height={50} className="itemSkeleton" />
        </DivSkeleton>
      }
      {showCompras && produto &&
        <Modal mode='normal' close={() => setShowCompras(false)} title='Lista de Compras' showBackground={false}>
          <ListaComprasProdutos compras={produto.data.compras} />
        </Modal>
      }
      {controlaLotes && (
        <DadosDiv>
          <Input
            width="1"
            name="lotes"
            title="F2 para buscar os lotes"
            label="Lote do produto"
            value={lote}
            placeholder="F2 para buscar Lotes"
            type="text"
            f2Title="Lista de lotes"
            f2Content={
              <ListaLotes
                lotes={produto?.data?.lotes}
                depositoId={deposito?.id}
              />
            }
            f2CallBack={handleLoteF2CallBack}
            f2ModalMode="normal"
            error={errorLote}
          />
        </DadosDiv>
      )}
    </Container>
  )
}
