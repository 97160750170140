/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { GetPromocoesParams, Promocao } from '../../../../../modules/promocoes/models'
import { Input, ModePedido, Select } from '../../..'
import { Container, HeaderPromocao, BodyPromocao, Row, ErroQuantidade, Column } from './styles'
import debounce from 'lodash.debounce'
import capitalize from 'capitalize-pt-br'
import { FaExclamation } from 'react-icons/fa'
import { usePrecoProduto, useUsuario } from '../../../../hooks'
import { Item } from '../../../../../modules/pedidos/models'
import { Preco, Produto } from '../../../../../modules/produtos/models'
import { Cliente } from '../../../../../modules/clientes/models'
import { clienteParamsPreco, formatFloatToCurrency } from '../../../../../helpers'
import { Textarea } from '../../../Textarea'
import { AtendimentosState } from '../../../../store/atendimentos/interfaces'
import { AppState } from '../../../../store'
import { useSelector } from 'react-redux'
import { usePromocoes } from '../../../../hooks/promocoes'

interface PromocaoProps {
  produto?: Produto
  cliente?: Cliente
  revenda?: Cliente
  mode?: ModePedido
  pedidoIdEmEdicao?: number
  depositoId?: number
  isPrecoDefininido: (isPrecoDefinido: boolean) => void
  fakeItemId: number
  setPromocaoSelected: React.Dispatch<React.SetStateAction<Item[]>>
  setQuantidadeInvalida?: React.Dispatch<React.SetStateAction<boolean>>
  solar: 's' | 'n'
}

export const DetalhePromocao = ({ produto, cliente, revenda, solar, mode, pedidoIdEmEdicao, depositoId, isPrecoDefininido, fakeItemId, setPromocaoSelected, setQuantidadeInvalida }: PromocaoProps) => {
  const [promocao, setPromocao] = useState<Promocao>({} as Promocao)
  const atendimentosState = useSelector<AppState>(state => state.atendimentos) as AtendimentosState
  // const { setData: setModalData } = useModal()
  const [erroQuantidade, setErroQuantidade] = useState('')
  const [quantidade, setQuantidade] = useState(0)
  const [qtdInput, setQtdInput] = useState(0)
  const [promocaoIndex, setPromocaoIndex] = useState(0)
  const { data: usuario } = useUsuario()

  const clientePreco = clienteParamsPreco({ cliente, revenda, mode, atendimentosState, pedidoIdEmEdicao })

  const { fetcher: getPrecoProduto, data: preco } = usePrecoProduto()

  useEffect(() => {
    getPrecoProduto({
      produtoId: Number(produto?.id),
      empresaId: depositoId as number,
      clienteId: clientePreco?.id as number,
      solar
    })
  }, [])

  const debouncedFilter = useCallback(
    debounce((value) => {
      setErroQuantidade('')
      setQuantidade(value)
      if (promocoesList) {
        if (setQuantidadeInvalida) {
          if (promocoesList[promocaoIndex]?.multiplos === 's' && value % promocoesList[promocaoIndex].quantidade !== 0) {
            setErroQuantidade('A quantidade de produtos deve ser multiplo de ' + promocoesList[promocaoIndex].quantidade + '!')
            setQuantidadeInvalida(true)
          } else {
            setQuantidadeInvalida(false)
          }
        }
        if (promocoesList[promocaoIndex]?.multiplos === 'n' && value < promocoesList[promocaoIndex]?.quantidade) {
          setErroQuantidade('A quantidade de produtos deve ser maior que ' + promocoesList[promocaoIndex].quantidade + '!')
        }
      }
    }, 500),
    [promocaoIndex, qtdInput])

  const handleChangeQuantidade = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    setQtdInput(value)
    debouncedFilter(value)
  }, [promocaoIndex, qtdInput])

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(event.target.value)
    if (promocoesList && promocoesList[value]) {
      setPromocao(promocoesList[value])
      setQtdInput(promocoesList[value].quantidade)
      setQuantidade(promocoesList[value].quantidade)
      isPrecoDefininido(true)
      setPromocaoIndex(value)
    } else {
      setPromocao({
        status: '',
        descricao: ''
      } as Promocao)
      setQtdInput(0)
      setQuantidade(0)
      isPrecoDefininido(false)
    }
    setErroQuantidade('')
  }

  const dataInputValue = () => {
    let data = ''
    if (promocao) {
      if (promocao.data_inicial && promocao.data_final) {
        data = promocao.data_inicial + ' - ' + promocao.data_final
      }
    }
    return data
  }

  const valoresCalculados = useMemo(() => {
    let valores = {
      valorUnitario: 0,
      valor: 0,
      total: 0,
      ipi: 0,
      st: 0,
      desconto: 0
    }
    if (preco) {
      if (preco.valor) {
        valores.valorUnitario = preco.valor as number
      }
      if (preco.ipi) {
        valores.ipi = preco.ipi as number
      }
      if (preco.st) {
        valores.st = preco.st as number
      }
    }
    if (promocao.desconto && quantidade) {
      valores.desconto = ((promocao.desconto / 100) * valores.valorUnitario)
    }
    if (preco && quantidade && promocao) {
      valores.valor = valores.valorUnitario - valores.desconto
      valores.total = (valores.valor * quantidade)
    }

    return valores
  }, [preco, promocao, quantidade])

  const textDescricao = () => {
    let text = ''
    if (promocao.descricao) {
      text = promocao.descricao
    }
    if (promocao.descricao2) {
      text += '\n' + promocao.descricao2
    }

    if (promocao.brindes?.length > 0) {
      text += '\n' + 'BRINDES:' + '\n' + promocao.brindes.map(({ produto }) => {
        return produto.nome_tecnico + '\n'
      })
    }
    return text
  }

  const deposito = useMemo(() => {
    if (mode === 'register') {
      return atendimentosState.data[cliente?.id as number]?.pedidoEmAndamento?.pedido?.deposito
    } else {
      const depositoEdicao = atendimentosState.data[cliente?.id as number]
        ?.pedidosEmAberto?.data[pedidoIdEmEdicao as number].emEdicao?.deposito
      const depositoOriginal = atendimentosState.data[cliente?.id as number]
        ?.pedidosEmAberto?.data[pedidoIdEmEdicao as number].original?.deposito
      return depositoEdicao || depositoOriginal
    }
  }, [atendimentosState, cliente, mode, pedidoIdEmEdicao])

  const [promocaoParams, setPromocaoParams] = useState(() => {
    let params: GetPromocoesParams = {
      body: {
        produtos: []
      },
      filterObject: {
        funcionario_id: usuario?.funcionario_id,
        uf: deposito?.uf,
        status: 'em aberto',
        uf_cliente: cliente?.uf,
        cliente_id: cliente?.id,
        solar: solar
      }
    }
    if (usuario?.token) {
      params.token = usuario.token
    }
    return params
  })

  const { promocoesList } = usePromocoes(promocaoParams)

  useEffect(() => {
    let acrescimo: number = 0
    let item_promocao_id = fakeItemId * -1
    let itemOrcamento: Item[] = []
    let newItem: Item = {
      id: item_promocao_id,
      acrescimo,
      desconto: valoresCalculados.desconto * quantidade,
      valor: valoresCalculados.valor,
      valorUnitario: (valoresCalculados.total / quantidade) || 0,
      quantidade: quantidade,
      ipi: valoresCalculados.ipi || 0,
      ipiTotal: valoresCalculados.ipi * quantidade || 0,
      st: valoresCalculados.st || 0,
      stTotal: valoresCalculados.st * quantidade || 0,
      total: valoresCalculados.total || 0,
      produto: produto,
      preco: preco as unknown as Preco,
      falta_liberar: quantidade,
      promocao_id: promocao.id
    }

    if (promocao.brindes && promocao.brindes.length > 0) {
      itemOrcamento.push(newItem)
      promocao.brindes.forEach((element, index) => {
        const fakeId = fakeItemId + (index + 1)

        let promocaoQuantidade = Math.trunc(itemOrcamento[0].quantidade / promocao.quantidade) * element.quantidade

        if (element.tipo === 'variavel') {
          const valorDescontado = element.valor - ((element.desconto / 100) * element.valor)
          itemOrcamento.push({
            id: fakeId * -1,
            acrescimo: 0,
            desconto: element.desconto,
            valor: valorDescontado || 0,
            valorUnitario: valorDescontado || 0,
            quantidade: promocaoQuantidade,
            ipi: 0,
            ipiTotal: 0,
            st: 0,
            stTotal: 0,
            total: valorDescontado * element.quantidade || 0,
            falta_liberar: promocaoQuantidade,
            promocao_id: element.promocao_id,
            produto: element.produto,
            item_promocao_id
          })
        } else if (element.tipo === 'fixo') {
          itemOrcamento.push({
            id: fakeId * -1,
            acrescimo: 0,
            desconto: 0,
            valorUnitario: element.valor,
            valor: element.valor,
            quantidade: promocaoQuantidade,
            ipi: 0,
            ipiTotal: 0,
            st: 0,
            stTotal: 0,
            total: 0.01,
            falta_liberar: promocaoQuantidade,
            promocao_id: element.promocao_id,
            produto: element.produto,
            item_promocao_id
          })
        }
      })
    } else {
      itemOrcamento.push(newItem)
    }
    setPromocaoSelected(itemOrcamento)
    setPromocaoParams({
      ...promocaoParams,
      body: {
        produtos: [produto?.id as number]
      }
    })
  }, [preco, promocao, produto, quantidade, valoresCalculados])

  return (
    <Container>
      <HeaderPromocao>
        <Select title='Promoção' onChange={handleSelectChange}>
          <option value="">Selecione uma promoção</option>
          {promocoesList && promocoesList.map(
            (item, index) => <option key={index} value={index}>{item.descricao}</option>)
          }
        </Select>
      </HeaderPromocao>
      <BodyPromocao>
        <Column>
          <Row>
            <Input
              label='Status'
              value={capitalize(promocao.status)}
              disabled
            />
          </Row>
          <Row>
            <Input
              label='Data'
              value={dataInputValue()}
              disabled
            />
          </Row>
          <Row>
            <Textarea
              label='Descrição'
              value={textDescricao()}
              height='90px'
              enableAutoHeight
              disabled
            />
          </Row>
        </Column>
        <Column>
          <Row>
            <Input
              label='Quantidade'
              value={qtdInput}
              onChange={handleChangeQuantidade}
              width='0 0 48%'
              error={erroQuantidade}
            />
            {erroQuantidade !== '' &&
              <ErroQuantidade>
                <span><FaExclamation size='20px' /></span>
                <span>{erroQuantidade}</span>
              </ErroQuantidade>
            }
          </Row>
          <Row>
            <Input
              label='Valor (R$)'
              value={formatFloatToCurrency(valoresCalculados.valorUnitario)}
              width='0 0 48%'
              disabled
            />
            <Input
              label='Desconto (R$)'
              value={formatFloatToCurrency(valoresCalculados.desconto * quantidade)}
              width='0 0 48%'
              disabled
            />
          </Row>
          <Row>
            <Input
              label='ST (R$)'
              value={formatFloatToCurrency(valoresCalculados.st * quantidade)}
              width='0 0 48%'
              disabled
            />
            <Input
              label='Valor Total (R$)'
              value={formatFloatToCurrency(valoresCalculados.total + (valoresCalculados.st * quantidade))}
              width='0 0 48%'
              disabled
            />
          </Row>
        </Column>
      </BodyPromocao>
    </Container>
  )
}
