import { useState } from 'react'
import { makeListarPedidos } from '../../../modules/pedidos/factories'
import { GetParams } from '../../../modules/_interfaces'
import { useUsuario } from '../usuario/useUsuario'
import { GetPedidoListResponse } from '../../../modules/pedidos/models'
import { useHandleErrors } from '../app/useHandleErrors'
import { SortTableProps } from '../../../modules/_interfaces/sortTable'
import { FilterPedidoProps } from '../../../modules/_interfaces/filtersProps'

interface FetcherProps {
  perPage: number,
  currentPage: number,
  filters: FilterPedidoProps,
  sortTable: SortTableProps,
  cliente_id?: number
  revenda_id?: number
}

export function useListaPedidos () {
  const [data, setData] = useState<GetPedidoListResponse>()
  /* const [error, setError] = useState<any>({}) */
  const { data: usuarioData } = useUsuario()
  const [loading, setLoading] = useState(false)
  const { executing, handleError } = useHandleErrors()

  async function fetcher ({ perPage, currentPage, sortTable, cliente_id, revenda_id, filters }: FetcherProps) {
    setLoading(true)
    const listarPedidos = makeListarPedidos()
    let params: GetParams = {
      token: usuarioData?.token as string,
      filterOptions: {
        limit: perPage,
        skip: (currentPage - 1) * perPage
      }
    }

    if (sortTable && sortTable.id) {
      params.filterOptions = { ...params.filterOptions, order: sortTable.desc ? `-${sortTable.id}` : sortTable.id }
    }

    if (cliente_id) {
      params.filterObject = { ...params.filterObject, cliente_id }
    }
    if (revenda_id) {
      params.filterObject = { ...params.filterObject, revenda_id }
    }

    if (filters) {
      if (filters.search) {
        params.filter = filters.search
      }

      if (filters.exibir === 'funcionario') {
        params.filterObject = { ...params.filterObject, 'pedidos.funcionario_id': usuarioData?.funcionario_id }
      } else if (filters.exibir === 'equipe') {
        params.filterObject = { ...params.filterObject, 'pedidos.equipe_id': usuarioData?.equipe_id }
      }

      if (filters.estoque === 'faturado') {
        params.filterObject = { ...params.filterObject, 'pedidos.status': 'faturado' }
      } else if (filters.estoque === 'cancelado') {
        params.filterObject = { ...params.filterObject, 'pedidos.status': 'cancelado' }
      } else if (filters.estoque === 'todos') {
        params.filterObject = { ...params.filterObject }
      } else {
        params.filterObject = { ...params.filterObject, 'pedidos.status[not]': ['cancelado', 'faturado'] }
      }

      if (filters.liberacao) {
        params.filterObject = { ...params.filterObject, status_liberacao: filters.liberacao }
      }
      if (filters.liberadoEstoque) {
        params.filterObject = { ...params.filterObject, liberado: filters.liberadoEstoque }
      }

      if (filters.tipo) {
        params.filterObject = { ...params.filterObject, tipo: filters.tipo }
      }

      if (filters.dataInicial && filters.dataFinal && filters.estoque !== 'faturado') {
        params.filterObject = { ...params.filterObject, 'data[between]': `${filters.dataInicial}|${filters.dataFinal}` }
      }

      if (filters.dataInicial && filters.dataFinal && filters.estoque === 'faturado') {
        params.filterObject = { ...params.filterObject, 'data_faturamento[between]': `${filters.dataInicial}|${filters.dataFinal}` }
      }

      if (filters.tipoBusca) {
        if (filters.tipoBusca === 'cod_pedido') {
          params.filterObject = { 'pedidos.id': filters.search || 0 }
        } else if (filters.tipoBusca === 'cod_cliente') {
          params.filterObject = { ...params.filterObject, 'pedidos.cliente_id': filters.search || 0 }
        }
      }

      /* if (filters.dataFinal) {
        params.filterObject = { ...params.filterObject, 'data[lte]': filters.dataFinal }
      } */
    }

    const response = await listarPedidos.execute(params)
    setData(response)

    if (response.error && !executing) {
      handleError(response.error)
    }

    setLoading(false)
  }

  return { fetcher, data, loading }
}
