/* import FocusTrap from 'focus-trap-react' */
import React, { ReactNode, useCallback, useEffect } from 'react'
import { FiX } from 'react-icons/fi'
import { Button } from '..'
import { BoxModal, Container, Header, ContainerFooter, Content, BodyModal } from './styles'

export type ModalMode = 'fullscreen' | 'normal' | 'mini' | 'wide'

interface ModalProps {
  title?: string
  children?: ReactNode
  buttonSaveText?: string
  buttonCancelText?: string
  showButtonSave?: boolean
  mode?: ModalMode
  close: () => void
  onSave?: () => void
  showBackground?: boolean
  closeOnSave?: boolean
  disableButtonSave?: boolean
}

export const Modal = ({ title, children, buttonSaveText, buttonCancelText, showButtonSave, mode, close, closeOnSave = true, onSave, showBackground = true, disableButtonSave = false }: ModalProps) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => { document.body.style.overflow = 'unset' }
  }, [])

  const handleSaveButtonClick = useCallback(() => {
    onSave && onSave()

    if (closeOnSave) {
      close()
    }
  }, [close, closeOnSave, onSave])

  const handleOnKeyDown = useCallback(async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      close()
    }
  }, [close])

  return (
    <Container onKeyDown={handleOnKeyDown} showBackground={showBackground}>
      <BoxModal mode={mode}>
        <Content>
          <Header mode={mode}>
            <h1>{title && title}</h1>
            <button onClick={close}><FiX tabIndex={12} /></button>
          </Header>
          <BodyModal id="modalContent" mode={mode}>
            {children}
          </BodyModal>
          <ContainerFooter mode={mode}>
            <Button tabIndex={10} mode="secondary" onClick={close}>{buttonCancelText || 'Cancelar'}</Button>
            {showButtonSave && <Button
              tabIndex={11}
              mode="confirm"
              onClick={handleSaveButtonClick}
              data-cy='modalSaveButton'
              disabled={disableButtonSave}
            >{buttonSaveText || 'Salvar'}
            </Button>}
          </ContainerFooter>
        </Content>
      </BoxModal>
    </Container>
  )
}
