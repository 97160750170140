import { UseCase } from '../../_interfaces'
import { TrazerListaPrecoService } from '../contracts'
import { GetPrecosParams, GetPrecosProdutosResponse } from '../models'

export class TrazerListaPreco implements UseCase<GetPrecosParams, GetPrecosProdutosResponse> {
  private readonly trazerPrecoService: TrazerListaPrecoService

  constructor (trazerPrecoService: TrazerListaPrecoService) {
    this.trazerPrecoService = trazerPrecoService
  }

  async execute (params: GetPrecosParams): Promise<GetPrecosProdutosResponse> {
    return await this.trazerPrecoService.trazerListaPrecos(params)
  }
}
