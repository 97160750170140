import { formatStringDate } from '../../../helpers'
import { UseCase } from '../../_interfaces'
import { ListarPromocoesService } from '../contracts'
import { GetPromocoesListResponse } from '../models'
import { GetPromocoesParams } from '../models/getPromocoes'

export class ListarPromocoes implements UseCase<GetPromocoesParams, GetPromocoesListResponse> {
  private readonly listarPromocoesService: ListarPromocoesService

  constructor (listarPromocoesService: ListarPromocoesService) {
    this.listarPromocoesService = listarPromocoesService
  }

  async execute (params: GetPromocoesParams): Promise<GetPromocoesListResponse> {
    let retorno = await this.listarPromocoesService.listarPromocoes(params)

    retorno.data.map(item => {
      item.data_final = formatStringDate(item.data_final, 'xxxx-xx-xx', 'completo')
      item.data_inicial = formatStringDate(item.data_inicial, 'xxxx-xx-xx', 'completo')
      return item
    })

    return retorno
  }
}
