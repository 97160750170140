/* eslint-disable no-useless-return */
/* eslint-disable no-case-declarations */
import { Reducer } from 'redux'
import { produce } from 'immer'
import { AtendimentosActions } from './actions'

import {
  AtendimentosState,
  AtendimentosType,
  IniciarAtendimentoParams,
  PedidoEmAndamento,
  PedidosEmAberto,
  SetAtendimentoEmFoco,
  FecharAtendimentoParams,
  SetarBotaoAtivoCliente,
  IniciarPedidoParams,
  ErrosPedido,
  SetAbaAtivaPedidoParams,
  SetCampoValorPedidoEmAndamentoParams,
  SetCampoValorPedidoEmAbertoParams,
  SetErrorCampoValorPedidoEmAndamentoParams,
  SetErrosPedidoEmAndamentoParams,
  SetPedidoEmFocoParams,
  RemoverItemPedidoEmAndamentoProps,
  AddItemPedidoEmAndamentoParams,
  FecharPedidoParams,
  AbrirPedidoParams,
  SetAbaAtivaPedidoAbertoParams,
  AddItemPedidoEmAbertoParams,
  RemoverItemPedidoEmAbertoParams,
  SetErrorCampoValorPedidoEmAbertoParams,
  SetErrosPedidoEmAbertoParams,
  AlterarContatoAtendimentoParams,
  RestaurarItemPedido,
  RecalcularPedidoParams,
  SetFiltroUltimasSaidasParams,
  AtualizarPedidoParams,
  AtualizarClienteParams
} from './interfaces'
import { Funcionario } from '../../../modules/funcionarios/models/funcionario'
import { GetPedido, Item, ItemOrcamento } from '../../../modules/pedidos/models'
import { FilterUltimasSaidas } from '../../../modules/_interfaces/filtersProps'
import Swal from 'sweetalert2'
// import { Produto } from '../../../modules/produtos/models'

const INITIAL_STATE: AtendimentosState = {
  info: {
    atendimentoClienteIdEmFoco: null,
    funcionario: {} as Funcionario
  },
  data: {} as AtendimentosType
}

interface CalcularPedidoResponse {
  peso_total: number, // campo somente para exibicao do peso total do pedido para cotar frete
  acrescimos: number
  descontos: number
  icms: number
  st: number
  ipi: number
  qtdeItens: number
  subtotal: number
  total: number
  // frete_valor: number
}

const calcularPedido = (itens: ItemOrcamento[], frete_valor?: number, frete_gratis?: string): CalcularPedidoResponse => {
  // funcao apenas soma os totais e retorna para os cards do resumopedido
  let peso_total = itens.reduce((prevVal, elem) => prevVal + (elem.produto?.peso_bruto || 0) * (elem.quantidade), 0)
  let acrescimos = itens.reduce((prevVal, elem) => prevVal + (elem.acrescimo || 0), 0)
  let descontos = itens.reduce((prevVal, elem) => prevVal + (elem.desconto || 0), 0)
  let icms = itens.reduce((prevVal, elem) => prevVal + (elem.icms || 0), 0)
  let st = itens.reduce((prevVal, elem) => prevVal + (elem.stTotal || 0), 0)
  let ipi = itens.reduce((prevVal, elem) => prevVal + (elem.ipiTotal || 0), 0)
  let qtdeItens = itens.reduce((prevVal, elem) => prevVal + (elem.quantidade || 0), 0)
  let subtotal = itens.reduce((prevVal, elem) => prevVal + ((elem.total || 0) - (elem.stTotal || 0)), 0)
  let total = itens.reduce((prevVal, elem) => prevVal + (elem.total || 0), 0)
  // quando alterar para o frete automatico, não recebera mais o valor frete e somara o valor fixo
  // if (total > x) total += frete
  if (frete_valor && frete_gratis === 'n') {
    total += frete_valor
  }
  return {
    peso_total,
    acrescimos,
    descontos,
    icms,
    st,
    ipi,
    qtdeItens,
    subtotal,
    total
  }
}

// const setCampoRecalcCondition = (campo: string, pagamento?: string) => {
//   if (campo === 'condicao_pagamento' && pagamento === 'cartao') {
//     return true
//   } else if (campo === 'frete_valor') {
//     return true
//   } else {
//     return false
//   }
// }

const reducer: Reducer<AtendimentosState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AtendimentosActions.SET_INITIAL_STATE:
      state = {
        info: {
          atendimentoClienteIdEmFoco: null,
          funcionario: {} as Funcionario
        },
        data: {} as AtendimentosType
      }

      return state

    case AtendimentosActions.INICIAR_ATENDIMENTO:
      const { botaoAtendimentoAtivo, cliente, contato, funcionario } = action.payload as IniciarAtendimentoParams

      if (cliente.is_revenda_cliente === 's') {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...!',
          text: 'Não é possível abrir um pedido com cliente revenda.',
          position: 'center'
        })

        return state
      }

      return produce(state, (draftState) => {
        if (state.data[cliente.id as number]) {
          draftState.info.atendimentoClienteIdEmFoco = cliente.id as number
          return
        }

        draftState.info.atendimentoClienteIdEmFoco = cliente.id as number
        draftState.info.funcionario = funcionario

        draftState.data[cliente.id as number] = {
          botaoAtendimentoAtivo,
          cliente,
          contato,
          pedidoEmAndamento: {} as PedidoEmAndamento,
          pedidosEmAberto: {
            data: {} as PedidosEmAberto
          },
          pedidoEmFoco: null,
          filtroUltimasSaidas: {} as FilterUltimasSaidas
        }
      })

    case AtendimentosActions.SET_ATENDIMENTO_EM_FOCO:
      const payloadAlterarFocoAtendimento = action.payload as SetAtendimentoEmFoco
      return produce(state, (draftState) => {
        draftState.info.atendimentoClienteIdEmFoco = payloadAlterarFocoAtendimento.clienteId
      })

    case AtendimentosActions.FECHAR_ATENDIMENTO:
      const payloadClienteIdEncerrarAtendimento = action.payload as FecharAtendimentoParams
      return produce(state, (draftState) => {
        delete (draftState.data[payloadClienteIdEncerrarAtendimento.clienteId])
        draftState.info.atendimentoClienteIdEmFoco = null
      })

    case AtendimentosActions.SETAR_BOTAO_ATIVO_CLIENTE:
      const payloadSetarBotaoAtivoCliente = action.payload as SetarBotaoAtivoCliente
      const { clienteId, botao } = payloadSetarBotaoAtivoCliente

      return produce(state, (draftState) => {
        if (!state?.data[clienteId as number]) {
          return
        }

        draftState.data[clienteId as number].botaoAtendimentoAtivo = botao
      })

    case AtendimentosActions.INICIAR_PEDIDO:
      const payloadIniciarPedidoParams = action.payload as IniciarPedidoParams

      return produce(state, (draftState) => {
        if (!state?.data[payloadIniciarPedidoParams.cliente.id as number as number]) {
          return
        }

        draftState.data[payloadIniciarPedidoParams.cliente.id as number].pedidoEmFoco = 'em_andamento'

        draftState.data[payloadIniciarPedidoParams.cliente.id as number].pedidoEmAndamento = {
          abaAtiva: payloadIniciarPedidoParams.abaAtiva,
          pedido: {
            tipo: 'orçamento',
            tipo_faturamento: payloadIniciarPedidoParams.tipo_faturamento,
            acrescimos: 0,
            cliente: state?.data[payloadIniciarPedidoParams.cliente.id as number].cliente,
            transportadora: payloadIniciarPedidoParams.transportadora,
            condicao_pagamento: '0',
            contato: state?.data[payloadIniciarPedidoParams.cliente.id as number].contato,
            descontos: 0,
            itens: [],
            juros: 0,
            st: 0,
            qtdeItens: 0,
            subtotal: 0,
            total: 0,
            funcionario: payloadIniciarPedidoParams.funcionario ? payloadIniciarPedidoParams.funcionario : state.info.funcionario,
            pagamento_tipo: '',
            frete_por_conta: 'destinatario',
            bndes: 'n',
            possibilidades: 'baixa',
            usoeconsumo: payloadIniciarPedidoParams.tipo_faturamento === 'direto' ? 's' : payloadIniciarPedidoParams.cliente.uso_consumo,
            solar: payloadIniciarPedidoParams.solar
          },
          erros: {} as ErrosPedido
        }
      })

    case AtendimentosActions.SET_ABA_ATIVA_PEDIDO:
      const payloadAbaAtivaPedido = action.payload as SetAbaAtivaPedidoParams

      return produce(state, (draftState) => {
        if (!state?.data?.[payloadAbaAtivaPedido.clienteId]?.pedidoEmAndamento) {
          return
        }

        draftState.data[payloadAbaAtivaPedido.clienteId].pedidoEmAndamento.abaAtiva = payloadAbaAtivaPedido.abaAtiva
      })

    case AtendimentosActions.ATUALIZAR_CLIENTE:
      const payloadCampoAtualizarCliente = action.payload as AtualizarClienteParams

      return produce(state, (draftState) => {
        draftState.data[payloadCampoAtualizarCliente.clienteId as number].cliente = payloadCampoAtualizarCliente.cliente
      })

    case AtendimentosActions.SET_CAMPO_VALOR_PEDIDO_EM_ANDAMENTO:
      const payloadCampoValorPedido = action.payload as SetCampoValorPedidoEmAndamentoParams

      return produce(state, (draftState) => {
        const pedidoEmAndamento = state.data[payloadCampoValorPedido.clienteId]?.pedidoEmAndamento?.pedido
        // let valoresPedido: CalcularPedidoResponse = {} as CalcularPedidoResponse
        // const frete_valor = payloadCampoValorPedido.campo === 'frete_valor' ? payloadCampoValorPedido.valor : pedidoEmAndamento.frete_valor
        // if (setCampoRecalcCondition(payloadCampoValorPedido.campo, pedidoEmAndamento.pagamento_tipo)) {
        //   if (pedidoEmAndamento.itens && pedidoEmAndamento.itens?.length > 0) {
        //     valoresPedido = calcularPedido(pedidoEmAndamento.itens, frete_valor, pedidoEmAndamento.cliente?.frete_gratis)
        //   }
        // }

        const newPedido = {
          ...pedidoEmAndamento,
          [payloadCampoValorPedido.campo]: payloadCampoValorPedido.valor
        }
        draftState.data[payloadCampoValorPedido.clienteId].pedidoEmAndamento.pedido = newPedido
      })

    case AtendimentosActions.SET_CAMPO_VALOR_PEDIDO_EM_ABERTO:
      const payloadCampoValorPedidoAberto = action.payload as SetCampoValorPedidoEmAbertoParams

      return produce(state, (draftState) => {
        const pedidoEmEdicao = state.data[payloadCampoValorPedidoAberto.clienteId].pedidosEmAberto.data[payloadCampoValorPedidoAberto.pedidoId].emEdicao
        const pedidoOriginal = state.data[payloadCampoValorPedidoAberto.clienteId].pedidosEmAberto.data[payloadCampoValorPedidoAberto.pedidoId].original

        // let valoresPedido: CalcularPedidoResponse = {} as CalcularPedidoResponse
        // if (setCampoRecalcCondition(payloadCampoValorPedidoAberto.campo, pedidoEmEdicao.pagamento_tipo || pedidoOriginal.pagamento_tipo)) {
        //   if (pedidoEmEdicao.itens && pedidoEmEdicao.itens?.length > 0) {
        //     valoresPedido = calcularPedido(pedidoEmEdicao.itens, frete_valor, state.data[payloadCampoValorPedidoAberto.clienteId]?.cliente?.frete_gratis)
        //   } else if (pedidoOriginal.itens) {
        //     valoresPedido = calcularPedido(pedidoOriginal.itens, frete_valor, state.data[payloadCampoValorPedidoAberto.clienteId]?.cliente?.frete_gratis)
        //   }
        // }

        const newPedido = {
          ...pedidoOriginal,
          ...pedidoEmEdicao,
          [payloadCampoValorPedidoAberto.campo]: payloadCampoValorPedidoAberto.valor
        }

        draftState.data[payloadCampoValorPedidoAberto.clienteId].pedidosEmAberto.data[payloadCampoValorPedidoAberto.pedidoId].emEdicao = newPedido
      })

    case AtendimentosActions.SET_ERRO_CAMPO_VALOR_PEDIDO_EM_ANDAMENTO:
      const payloadErroCampo = action.payload as SetErrorCampoValorPedidoEmAndamentoParams

      return produce(state, (draftState) => {
        if (!payloadErroCampo.valor || !payloadErroCampo.valor) {
          delete (draftState.data[payloadErroCampo.clienteId]?.pedidoEmAndamento?.erros[payloadErroCampo.campo])
          return
        }

        draftState.data[payloadErroCampo.clienteId].pedidoEmAndamento.erros[payloadErroCampo.campo] = payloadErroCampo.valor
      })

    case AtendimentosActions.SET_ERROS_PEDIDO_EM_ANDAMENTO:
      const payloadErros = action.payload as SetErrosPedidoEmAndamentoParams

      return produce(state, (draftState) => {
        if (!state.data[payloadErros.clienteId].pedidoEmAndamento) {
          return
        }

        draftState.data[payloadErros.clienteId].pedidoEmAndamento.erros = payloadErros.erros
      })

    case AtendimentosActions.SET_PEDIDO_EM_FOCO:
      const payloadPedidoEmFoco = action.payload as SetPedidoEmFocoParams

      return produce(state, (draftState) => {
        draftState.data[payloadPedidoEmFoco.clienteId].pedidoEmFoco = payloadPedidoEmFoco.pedidoEmFoco
      })

    case AtendimentosActions.REMOVER_ITEM_PEDIDO_EM_ANDAMENTO:
      const payloadRemoverItemPedidoEmAndamento = action.payload as RemoverItemPedidoEmAndamentoProps

      return produce(state, (draftState) => {
        const pedidoEmAndamento = state.data[payloadRemoverItemPedidoEmAndamento.clienteId].pedidoEmAndamento.pedido
        let oldItens = pedidoEmAndamento.itens || []
        let newItens: Item[] = []
        let promocao_id = 0
        if (oldItens[payloadRemoverItemPedidoEmAndamento.index].item_promocao_id) {
          promocao_id = oldItens[payloadRemoverItemPedidoEmAndamento.index].item_promocao_id as number
          oldItens.forEach(element => {
            if (element.item_promocao_id !== promocao_id || !element.item_promocao_id) {
              newItens.push(element)
            }
          })
        } else {
          newItens = oldItens.filter((_item: any, index: number) => index !== payloadRemoverItemPedidoEmAndamento.index)
        }
        let valoresPedido = calcularPedido(newItens, pedidoEmAndamento.frete_valor, pedidoEmAndamento.cliente?.frete_gratis)

        const newPedido = {
          ...pedidoEmAndamento,
          ...valoresPedido,
          itens: newItens
        }

        draftState.data[payloadRemoverItemPedidoEmAndamento.clienteId].pedidoEmAndamento.pedido = newPedido
      })

    case AtendimentosActions.ADD_ITEM_PEDIDO_EM_ANDAMENTO:
      let payloadItemAtendimento = action.payload as AddItemPedidoEmAndamentoParams
      const pedidoEmAndamento = state.data[payloadItemAtendimento.clienteId]?.pedidoEmAndamento?.pedido
      let itens = [] as ItemOrcamento[]

      if (pedidoEmAndamento?.itens?.length) {
        itens = state.data[payloadItemAtendimento.clienteId]?.pedidoEmAndamento?.pedido?.itens as ItemOrcamento[]
      }

      payloadItemAtendimento.item.data = new Date().toISOString()

      itens = [...itens, payloadItemAtendimento.item]

      let dadosPedido = calcularPedido(itens, pedidoEmAndamento.frete_valor, pedidoEmAndamento.cliente?.frete_gratis)

      return produce(state, (draftState) => {
        draftState.data[payloadItemAtendimento.clienteId].pedidoEmAndamento.pedido = { ...pedidoEmAndamento, ...dadosPedido, itens }
      })

    case AtendimentosActions.FECHAR_PEDIDO:
      const payloadFecharPedido = action.payload as FecharPedidoParams

      return produce(state, (draftState) => {
        if (payloadFecharPedido.pedido) {
          draftState.data[payloadFecharPedido.clienteId].pedidoEmFoco = null

          if (payloadFecharPedido.pedido === 'em_andamento') {
            draftState.data[payloadFecharPedido.clienteId].pedidoEmAndamento = {} as PedidoEmAndamento
          } else {
            delete (draftState.data[payloadFecharPedido.clienteId].pedidosEmAberto.data[payloadFecharPedido.pedido])
          }
        }
      })

    case AtendimentosActions.ABRIR_PEDIDO:
      const payloadAbrirPedido = action.payload as AbrirPedidoParams

      return produce(state, (draftState) => {
        if (!draftState.data[payloadAbrirPedido.pedido.cliente?.id as number].pedidosEmAberto.data[payloadAbrirPedido.pedido.id as number]) {
          draftState.data[payloadAbrirPedido.pedido.cliente?.id as number].pedidosEmAberto.data[payloadAbrirPedido.pedido.id as number] = {
            original: payloadAbrirPedido.pedido,
            emEdicao: {} as GetPedido,
            abaAtiva: 'dadosgerais',
            erros: {} as ErrosPedido
          }
        }

        draftState.data[payloadAbrirPedido.pedido.cliente?.id as number].pedidoEmFoco = payloadAbrirPedido.pedido.id as number
      })

    case AtendimentosActions.SET_ABA_ATIVA_PEDIDO_ABERTO:
      const payloadSetAbaPedidoAberto = action.payload as SetAbaAtivaPedidoAbertoParams

      return produce(state, (draftState) => {
        draftState.data[payloadSetAbaPedidoAberto.clienteId].pedidosEmAberto.data[payloadSetAbaPedidoAberto.pedidoId].abaAtiva = payloadSetAbaPedidoAberto.abaAtiva
      })

    case AtendimentosActions.ADD_ITEM_PEDIDO_EM_ABERTO:
      let payloadAddItemEmAberto = action.payload as AddItemPedidoEmAbertoParams

      return produce(state, (draftState) => {
        let itensEmEdicao = []
        const pedidoOriginal = state?.data[payloadAddItemEmAberto.clienteId]?.pedidosEmAberto?.data[payloadAddItemEmAberto.pedidoId]?.original
        const pedidoEmEdicao = state?.data[payloadAddItemEmAberto.clienteId]?.pedidosEmAberto?.data[payloadAddItemEmAberto.pedidoId]?.emEdicao
        const oldItemsState = pedidoEmEdicao?.itens as ItemOrcamento[]
        const originalItens = pedidoOriginal?.itens as ItemOrcamento[]
        payloadAddItemEmAberto.item.data = new Date().toISOString()

        if (oldItemsState?.length) {
          itensEmEdicao = [...oldItemsState, payloadAddItemEmAberto.item]
        } else {
          itensEmEdicao = [...originalItens, payloadAddItemEmAberto.item]
        }

        let frete_valor = pedidoEmEdicao.frete_valor ? pedidoEmEdicao.frete_valor : pedidoOriginal.frete_valor

        let valoresPedido = calcularPedido(itensEmEdicao, frete_valor, pedidoOriginal.cliente?.frete_gratis)

        draftState.data[payloadAddItemEmAberto.clienteId].pedidosEmAberto.data[payloadAddItemEmAberto.pedidoId].emEdicao = {
          ...pedidoEmEdicao,
          ...valoresPedido,
          itens: itensEmEdicao
        }
      })

    case AtendimentosActions.REMOVER_ITEM_PEDIDO_EM_ABERTO:
      const payloadRemoverItemEmAberto = action.payload as RemoverItemPedidoEmAbertoParams

      return produce(state, (draftState) => {
        let itens: ItemOrcamento[] = []
        let calcItens: Item[] = []
        const pedidoOriginal = state?.data[payloadRemoverItemEmAberto.clienteId]?.pedidosEmAberto?.data[payloadRemoverItemEmAberto.pedidoId]?.original
        const pedidoEmEdicao = state?.data[payloadRemoverItemEmAberto.clienteId]?.pedidosEmAberto?.data[payloadRemoverItemEmAberto.pedidoId]?.emEdicao
        const itensEmEdicao = pedidoEmEdicao?.itens
        const itensOriginais = pedidoOriginal?.itens
        const itensState = itensEmEdicao || itensOriginais

        if (!itensState) {
          return
        }

        if (payloadRemoverItemEmAberto.item_id) {
          if (itensState[payloadRemoverItemEmAberto.index].item_promocao_id) {
            let uuid = itensState[payloadRemoverItemEmAberto.index].item_promocao_id
            itens = itensState?.map((item: Item) => {
              if (item.item_promocao_id === uuid) {
                return {
                  ...item,
                  quantidade: 0,
                  data: new Date().toISOString()
                }
              } else {
                return item
              }
            }) as ItemOrcamento[]
          } else {
            itens = itensState?.map((item: any, index: number) => {
              if (index === payloadRemoverItemEmAberto.index) {
                return {
                  ...item,
                  quantidade: 0,
                  data: new Date().toISOString()
                }
              } else {
                return item
              }
            }) as ItemOrcamento[]
            calcItens = itens.filter((_item: Item, index: number) => index !== payloadRemoverItemEmAberto.index || _item.quantidade > 0)
          }
        } else {
          if (itensState[payloadRemoverItemEmAberto.index].item_promocao_id) {
            let uuid = itensState[payloadRemoverItemEmAberto.index].item_promocao_id
            itens = itensState.filter((_item, index) => _item.item_promocao_id !== uuid)
          } else {
            itens = itensState.filter((_, index) => index !== payloadRemoverItemEmAberto.index)
          }
          calcItens = itens
        }
        let frete_valor = pedidoEmEdicao.frete_valor ? pedidoEmEdicao.frete_valor : pedidoOriginal.frete_valor
        let valoresPedido = calcularPedido(calcItens, frete_valor, pedidoOriginal.cliente?.frete_gratis)

        const newPedido = {
          ...pedidoEmEdicao,
          ...valoresPedido,
          itens: itens
        }

        draftState.data[payloadRemoverItemEmAberto.clienteId].pedidosEmAberto.data[payloadRemoverItemEmAberto.pedidoId].emEdicao = newPedido
      })

    case AtendimentosActions.SET_ERRO_CAMPO_VALOR_PEDIDO_EM_ABERTO:
      const payloadSetErroCampoValorPedidoEmAberto = action.payload as SetErrorCampoValorPedidoEmAbertoParams
      return produce(state, (draftState) => {
        draftState.data[payloadSetErroCampoValorPedidoEmAberto.clienteId].pedidosEmAberto.data[payloadSetErroCampoValorPedidoEmAberto.pedidoId].erros[payloadSetErroCampoValorPedidoEmAberto.campo] = payloadSetErroCampoValorPedidoEmAberto.valor
      })

    case AtendimentosActions.SET_ERROS_PEDIDO_EM_ABERTO:
      const payloadSetErrosPedidoAberto = action.payload as SetErrosPedidoEmAbertoParams

      return produce(state, (draftState) => {
        draftState.data[payloadSetErrosPedidoAberto.clienteId].pedidosEmAberto.data[payloadSetErrosPedidoAberto.pedidoId].erros = payloadSetErrosPedidoAberto.erros
      })

    case AtendimentosActions.ALTERAR_CONTATO_ATENDIMENTO:
      const payloadAlterarContatoAtendimento = action.payload as AlterarContatoAtendimentoParams

      return produce(state, (draftState) => {
        if (!state?.data[payloadAlterarContatoAtendimento.clienteId]) {
          return
        }

        draftState.data[payloadAlterarContatoAtendimento.clienteId].contato = payloadAlterarContatoAtendimento.contato
      })

    case AtendimentosActions.RESTATURAR_ITEM_PEDIDO_EM_ANDAMENTO:
      const payloadRestauraItemPedido = action.payload as RestaurarItemPedido

      return produce(state, (draftState) => {
        const pedidoOriginal = state?.data[payloadRestauraItemPedido.cliente_id].pedidosEmAberto.data[payloadRestauraItemPedido.pedido_id].original
        const pedidoEmEdicao = state.data[payloadRestauraItemPedido.cliente_id].pedidosEmAberto.data[payloadRestauraItemPedido.pedido_id].emEdicao
        const itens_originais = pedidoOriginal?.itens as Item[]
        const item_original = itens_originais[payloadRestauraItemPedido.item_index]
        const items_edicao = pedidoEmEdicao.itens
        let novos_itens: Item[] = []
        if (items_edicao) {
          if (item_original.item_promocao_id) {
            novos_itens = items_edicao.map((item, index) => {
              if (item.item_promocao_id === item_original.item_promocao_id) {
                return itens_originais[index]
              } else {
                return item
              }
            })
          } else {
            novos_itens = items_edicao.map((item, index) => {
              if (index === payloadRestauraItemPedido.item_index) {
                return item_original
              } else {
                return item
              }
            })
          }
        }

        if (novos_itens) {
          const calcParams = novos_itens.filter((_item: Item, index: number) => _item.quantidade > 0)
          let frete_valor = pedidoEmEdicao.frete_valor ? pedidoEmEdicao.frete_valor : pedidoOriginal.frete_valor
          let valoresPedido = calcularPedido(calcParams, frete_valor, pedidoOriginal.cliente?.frete_gratis)

          const newPedido = {
            ...pedidoEmEdicao,
            ...valoresPedido,
            itens: novos_itens
          }
          draftState.data[payloadRestauraItemPedido.cliente_id].pedidosEmAberto.data[payloadRestauraItemPedido.pedido_id].emEdicao = newPedido
        }
      })

    case AtendimentosActions.SET_PEDIDO_RECALCULADO:
      const payloadRecalcularPedido = action.payload as RecalcularPedidoParams

      return produce(state, (draftState) => {
        const newPedido = payloadRecalcularPedido.data

        if (payloadRecalcularPedido.pedido_id) {
          draftState.data[payloadRecalcularPedido.cliente_id].pedidosEmAberto.data[payloadRecalcularPedido.pedido_id].emEdicao = newPedido
        } else {
          // const pedidoEmAndamento = state.data[payloadRecalcularPedido.cliente_id]?.pedidoEmAndamento?.pedido
          draftState.data[payloadRecalcularPedido.cliente_id].pedidoEmAndamento.pedido = newPedido
        }
      })

    case AtendimentosActions.SET_PEDIDO_ATUALIZADO:
      const payloadAtualizarPedido = action.payload as AtualizarPedidoParams

      return produce(state, (draftState) => {
        const newPedido = payloadAtualizarPedido.data
        if (payloadAtualizarPedido.pedido_id) {
          draftState.data[payloadAtualizarPedido.cliente_id].pedidosEmAberto.data[payloadAtualizarPedido.pedido_id].original = newPedido
        } else {
          // const pedidoEmAndamento = state.data[payloadAtualizarPedido.cliente_id]?.pedidoEmAndamento?.pedido
          draftState.data[payloadAtualizarPedido.cliente_id].pedidoEmAndamento.pedido = newPedido
        }
      })

    case AtendimentosActions.SET_FILTRO_ULTIMAS_SAIDAS:
      const payloadSetFilterUltimasSaidas = action.payload as SetFiltroUltimasSaidasParams

      return produce(state, (draftState) => {
        draftState.data[payloadSetFilterUltimasSaidas.cliente_id].filtroUltimasSaidas = payloadSetFilterUltimasSaidas.filter
      })

    default:
      return state
  }
}

export default reducer
