import { formatStringDate } from '../../../helpers'
import { UseCase } from '../../_interfaces'
import { TrazerProdutoPorIdService } from '../contracts'
import { GetProdutosPorIdResponse } from '../models'

export type TrazerProdutoPorIdParams = {
  produtoId: number
  token: string
}

export class TrazerProdutoPorId implements UseCase<TrazerProdutoPorIdParams, GetProdutosPorIdResponse> {
  private readonly trazerPrecoService: TrazerProdutoPorIdService

  constructor (trazerPrecoService: TrazerProdutoPorIdService) {
    this.trazerPrecoService = trazerPrecoService
  }

  async execute (params: TrazerProdutoPorIdParams): Promise<GetProdutosPorIdResponse> {
    const { produtoId, token } = params
    let response = await this.trazerPrecoService.trazerProdutoPorId(produtoId, token)
    if (response && response.data) {
      if (response.data.compras && response.data.compras.length > 0) {
        response.data.compras = response.data.compras.map(compra => {
          return {
            ...compra,
            previsao: formatStringDate(compra.previsao, 'xxxxxxxx', 'completo')
          }
        })
      }
    }
    return response
  }
}
