import { generateSearchQuery } from '../../../helpers'
import { GetParams } from '../../_interfaces'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { ListarVendasFuncionariosService } from '../contracts/listar-vendas-funcionarios-service'
import { GetListarVendasFuncionarios } from '../models/getListarVendasFuncionariosResponse'

export class ListarVendasFuncionariosRest implements ListarVendasFuncionariosService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async listarVendasFuncionarios (funcionario_id: number, params: GetParams): Promise<GetListarVendasFuncionarios> {
    let { filter, filterOptions, filterObject, token } = params
    const query = generateSearchQuery(filter, filterOptions, filterObject)

    console.log(params, query)

    const response = await this.httpRequest.get<GetListarVendasFuncionarios>({
      path: `funcionarios/${funcionario_id}/vendas_valor_liquido`,
      token,
      query
    })

    const retorno = {
      data: response.res?.data
    } as GetListarVendasFuncionarios

    return retorno
  }
}
