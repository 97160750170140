import { HttpRequestImpl } from '../../../infra/http/httpRequest'
import { TrazerResumoFuncionarioServiceRest } from '../services'
import { TrazerResumoFuncionario } from '../useCases'

export function makeTrazerResumoFuncionario (): TrazerResumoFuncionario {
  const httpRequest = new HttpRequestImpl()
  const trazerResumoFuncionarioService = new TrazerResumoFuncionarioServiceRest(httpRequest)

  return new TrazerResumoFuncionario(trazerResumoFuncionarioService)
}
