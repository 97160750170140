import * as yup from 'yup'

export const recalcSchema = yup.object().shape({
  deposito_id: yup.number().required('codigo do depósito é um campo obrigatório'),
  cliente_id: yup.number().required('codigo do cliente é um campo obrigatório'),
  funcionario_id: yup.number().required('codigo do funcionário é um campo obrigatório'),
  funcionario2_id: yup.number(),
  transportadora_id: yup.number().required('codigo da transportadora é um campo obrigatório'),
  contato_id: yup.number().required('codigo do contato é um campo obrigatório'),
  condicao_pagamento: yup.string().required('condição de pagamento não pode estar vazia')
})
