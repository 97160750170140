import { PostParams, UseCase, Validator } from '../../_interfaces'
import { NovaSolicitacaoService } from '../contracts'

export class NovaSolicitacao implements UseCase<PostParams, {id: string}> {
  private readonly novaSolicitacaoService: NovaSolicitacaoService
    private readonly validator: Validator

    constructor (novaSolicitacaoService: NovaSolicitacaoService, validator: Validator) {
      this.novaSolicitacaoService = novaSolicitacaoService
      this.validator = validator
    }

    async execute (params: PostParams): Promise<{id: string}> {
      await this.validator.validate(params.body)

      return await this.novaSolicitacaoService.novaSolicitacao({
        token: params.token,
        body: params.body
      })
    }
}
