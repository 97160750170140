import { generateSearchQuery } from '../../../helpers'
import { HttpRequest } from '../../_interfaces/httpRequest'
import { TrazerCrsClienteParams, TrazerCrsClienteService } from '../contracts'
import { GetCrsClienteResponse } from '../models'
import { TrazerCrsClienteRespApi } from './_interfaces'

export class TrazerCrsClienteServiceRest implements TrazerCrsClienteService {
  private readonly httpRequest: HttpRequest

  constructor (httpRequest: HttpRequest) {
    this.httpRequest = httpRequest
  }

  async trazerCrsCliente (params: TrazerCrsClienteParams): Promise<GetCrsClienteResponse> {
    let { cliente_id, token, filter, filterOptions, filterObject } = params
    const query = generateSearchQuery(filter, filterOptions, filterObject)

    let response = await this.httpRequest.get<TrazerCrsClienteRespApi>({
      path: `clientes/${cliente_id}/crs`,
      token,
      query
    })

    /* let response = {
      data: [
        {
          id: 2455189,
          tipo_baixa: 'carteira',
          status_baixa: 'normal',
          documento: '',
          emissao: '20210617000000',
          vencimento: '20210618000000',
          vencimento_original: '20210618000000',
          data_baixa: '20210617000000',
          valor: '223',
          valor_baixado: '223',
          status: 'baixado',
          digitavel: '',
          historico: 'ADIANTAMENTO DO PEDIDO DE VENDA (3859248)',
          pedido_id: 3859248,
          cliente_id: 286434,
          empresa_id: 2,
          equipe_id: 4,
          funcionario_id: 1002
        }
      ],
      metadata: {
        skip: 0,
        count: 10,
        limit: 10
      },
      error: {

      }
    } as GetCrsClienteResponse */

    const retorno = {
      data: response.res?.data,
      metadata: response.res?.metadata,
      error: response.error
    } as GetCrsClienteResponse

    /* const retorno = {
      data: response.data,
      metadata: response.metadata,
      error: response.error
    } as GetCrsClienteResponse */

    return retorno
  }
}
